import { Box, Chip, Container, CSSObject, Divider, Grid, Stack, Theme, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { AxiosGet, ResultAPI } from "service/CommonFunction/TS_function";
import { DialogActionCreators } from "store/redux/DialogAlert";
import MenuIcon from '@mui/icons-material/Menu';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import { styled } from '@mui/material/styles';
import themes from "themes";
import { i18n } from "i18n";
import secureLocalStorage from "react-secure-storage";
import { Helmet } from 'react-helmet';

const drawerWidth = 240;

interface OpenProps extends MuiAppBarProps {
  open?: boolean;
}

const div = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<OpenProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    marginTop: "10%",
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Navigate_Layout_BPS = ({ handleDrawerClose, handleDrawerOpen, open, children }) => {
  const i18nField = 'navbar';
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const theme = useTheme();
  const width = window.innerWidth || document.documentElement.clientWidth ||
    document.body.clientWidth;
  const [lstMenuNav, setlstMenuNav] = useState([]);
  // const [lstMenuNav, setlstMenuNav] = useState(window.localStorage.getItem("IsBack") ? JSON.parse(window.localStorage.getItem("IsBack")) : []);
  const [selectIndex, setSelectIndex] = useState(lstMenuNav.length > 0
    ? lstMenuNav.findIndex(f => location.pathname.includes(f.sUrl))
    : 0);
  const [sName, setName] = useState("");

  useEffect(() => {
    // let localLang = (window.localStorage.getItem("th") || "th").toLocaleUpperCase();
    let localLang = "th".toLocaleUpperCase();
    if (lstMenuNav.length == 0) {
      AxiosGet("MenuBPS/SearchData_BackMenuBPS", {}, (result) => {
        ////console.log("SearchData_BackMenuBPS", result);

        ResultAPI(result, "", () => {
          setlstMenuNav(result.lstMenuNav);
          secureLocalStorage.setItem("IsBack", JSON.stringify(result.lstMenuNav))

          let indexSelected = result.lstMenuNav.findIndex(f => location.pathname.includes(f.sUrl))
          if (indexSelected != null) {
            setSelectIndex(indexSelected)
            let objMenu = result.lstMenuNav[indexSelected];
            if (objMenu != null) setName(objMenu[`sName${localLang}`]);
          } else {
            setSelectIndex(0)
          }
        });
      });
    } else {

      let indexSelected = lstMenuNav.findIndex(f => location.pathname == (f.sUrl))
      // if (indexSelected == -1) {
      //     indexSelected = lstMenuNav.findIndex(f => location.pathname.includes(f.sUrl))
      // }
      if (indexSelected != null) {
        setSelectIndex(indexSelected)
        let objMenu = lstMenuNav[indexSelected];

        if (objMenu != null)
          setName(objMenu[`sName${localLang}`]);
      } else {
        setSelectIndex(0)
      }

    }
  }, [location.pathname]);

  const DrawerStyles = (theme: Theme, isOpen: boolean): CSSObject => ({
    " > button ": {
      opacity: isOpen ? 1 : 0
    },
    " > div ": {
      opacity: isOpen ? 1 : 0
    },
    justifyContent: 'center', color: "black", cursor: "pointer"
  });

  let sNameTH = lstMenuNav.length > 0 && lstMenuNav[selectIndex] ? lstMenuNav[selectIndex].sNameTH : "";

  return (
    <Grid container>

      <Box sx={{ display: "none !important", visibility: "hidden" }}>
        <Helmet>
          <title> {sName} </title>
        </Helmet>
      </Box>


      <Grid item xs={12}>
        <Box sx={{
          p: 2, display: "flex", flex: 2, minHeight: "100%", borderRadius: 5, mx: "2%", boxShadow: 3,

          bgcolor: (theme) => (theme.palette.mode === 'dark' ? '#101010' : '#fff')
        }}>
          <Stack direction="row" alignItems="center" spacing={1} sx={{ color: "black" }}  >
            {/* {lstMenuNav.length > 0 && iconMenu.some(f => f.sID == lstMenuNav[selectIndex].sID) ? iconMenu.find(f => f.sID == lstMenuNav[selectIndex].sID).icon : null} */}

            <Typography component="h5" variant="h5" sx={{ fontWeight: 600, color: "inherit" }} >
              {sName}
            </Typography>
          </Stack>
        </Box>
      </Grid>

      <Grid item xs={12} sx={{ marginTop: "2%" }}>
        {children}
      </Grid>
    </Grid>
  );
}

export default Navigate_Layout_BPS