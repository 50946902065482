import JobPost from "router/Front/Employer/JobPost";
import OrderEmployer from "router/Front/Employer/Order";
import SeekerProfile from "./SeekerProfile";
import SetupTemplate from "router/Front/Employer/SetupTemplate";
import MyPageEmployer from "router/Front/Employer/MyPage";
import JobDetail from "router/Front/JobDetail";
import SettingSeeker from "router/Front/SettingSeeker";
import RegisterEmployer from "router/Front/RegisterEmployer";
import SearchJobRoute from "./SearchJob";
import Company from "router/Front/Company";
import MyJobRoute from "./MyJob";

const FrontRoute = [
  ...JobPost,
  ...SeekerProfile,
  ...OrderEmployer,
  ...SetupTemplate,
  ...MyPageEmployer,
  ...JobDetail,
  ...RegisterEmployer,
  ...SettingSeeker,
  ...SearchJobRoute,
  ...Company,
  ...MyJobRoute,
];

export default FrontRoute;
