import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

import React, { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form';

import yupFormSchemas from "components/Common/yup/yupFormSchemas";

import { useLocation, useNavigate } from "react-router-dom";
import { Box, FormControlLabel, Grid, IconButton, Stack, Typography } from '@mui/material';
import Input from "components/Common/ElementInForm/InputCustomLabelFormItem";
import InputNumber from 'components/Common/ElementInForm/InputNumber';
import DatePickerFormItem from 'components/Common/ElementInForm/DatePickerFormItem';
import moment from 'moment';
import IOSSwitch from 'components/Common/ElementInForm/IosSwitch';
import { BtnBack, BtnSave } from "components/Common/Button/index";
import AutoCompleteSelect from 'components/Common/ElementInForm/AutoCompleteSelect';
import { useDispatch } from 'react-redux';

import { FaPlus, FaMinus } from "react-icons/fa";
import { AxiosPost, GetPermission, ResultAPI } from 'service/CommonFunction/TS_function';
import { DialogActionCreators } from 'store/redux/DialogAlert';

const ManagePromotionCoin = () => {
  const dispatch = useDispatch();
  const history = useNavigate();
  const location = useLocation();
  const searchParams = location.search;
  const sID = new URLSearchParams(searchParams).get("sID");

  const [isDisable, setIsDisable] = useState(false);
  const [permission, setPermission] = useState(0);
  const [startDate, setStartDate] = useState(moment());
  const [startDatePromotion, setStartDatePromotion] = useState(moment());
  const [endDatePromotion, setEndDatePromotion] = useState(moment());

  const [isActive, setIsActive] = useState(true);
  const [dropdownPromotion, setDropdownPromotion] = useState([]);
  const [isShowOther, setIsShowOther] = useState(false);
  const [dropdownPackage, setDropdownPackage] = useState([]);

  const [isCoin, setIsCoin] = useState(true);
  const [isCoinFree, setIsCoinFree] = useState(true);
  const [isPeriod, setIsPeriod] = useState(true);
  const [isDiscount, setIsDiscount] = useState(true);
  const [isGrandTotal, setIsGrandTotal] = useState(true);
  const [isProDateStart, setIsProDateStart] = useState(false);
  const [isProDateEnd, setIsProDateEnd] = useState(false);
  const [isBenefit, setIsBenefit] = useState(false);

  const [benefitList, setBenefitList] = useState<Benefit[]>([]);
  const [schemaWork, setSchemaWork] = useState({});

  const objBenefit = {
    nID: 1,
    nOrder: 1,
    sBenefit: "",
    IsDelete: false,
  };

  const [formSchema, setFormSchema] = useState<any>({
    sPromotion: yupFormSchemas.object("ชื่อโปรโมชั่น", { required: true }),
    Package: yupFormSchemas.object("เลือกแพ็กเกจ", { required: true }),
    dStartDatePromotion: yupFormSchemas.string("วันที่เริ่มต้นโปรโมชั่น", { required: true }),
    dEndDatePromotion: yupFormSchemas.string("วันที่สิ้นสุดโปรโมชั่น", { required: true }),
    ...schemaWork,
  });

  const schema = yup.object().shape(formSchema);
  const form = useForm({
    resolver: yupResolver(schema),
    shouldUnregister: false,
    shouldFocusError: true,
    mode: "all",
  });


  const loopSetSchemaWork = () => {
    const schemaTemp = {};
    benefitList.forEach((item) => {
      schemaTemp[`sBenefit${item.nID}`] = yupFormSchemas.string("สิทธิประโยชน์", { required: isBenefit });
    });
    schemaTemp["sOther"] = yupFormSchemas.string("ชื่อโปรโมชั่น", { required: isShowOther });
    setSchemaWork(schemaTemp);
  };

  const arrSchemaNameWorkExp = [
    "sBenefit",
  ];

  useEffect(() => {
    const FormSchema = { ...formSchema, ...schemaWork };
    setFormSchema(FormSchema);
  }, [schemaWork]);

  useEffect(() => {
    loopSetSchemaWork()
  }, [isShowOther, isBenefit]);


  useEffect(() => {
    if (sID === "0") {
      onGetInitial();
      AddComponent(0);
    }
    else {
      onGetDataByID();
    }
    GetPermission(64, setPermission);
  }, []);


  const onGetInitial = () => {
    const param = {
      nPackageType: 2
    }
    AxiosPost("ManagePromotion/GetInitial", param, (res) => {
      setDropdownPromotion(res.lstPromotionName);
    })
  }

  const onGetListPackage = (value) => {
    const param = {
      nPromotionCategoryID: value,
      nPackageType: 2,
    }
    AxiosPost("ManagePromotion/GetListPackage", param, (res) => {
      ////console.log("GetListPackage", res);
      setDropdownPackage(res.lstPackage);
    })
  }

  const onGetPackage = (value) => {
    const param = {
      nPackageID: value
    }
    AxiosPost("ManagePromotion/GetCoinPackage", param, (res) => {
      ////console.log("GetCoinPackage", res);
      onSetData(res.coinPackage);
    })
  }

  const onGetDataByID = () => {
    const param = {
      nPromotionID: sID,
    }
    AxiosPost("ManagePromotion/GetCoinPackageByID", param, (res) => {
      ///console.log("GetCoinPackageByID", res);

      form.setValue("sPromotion", res.objPromotionName);
      form.setValue("Package", res.coinPackage);

      setIsDisable(true);
      onSetData(res.coinPackage);
      onSetData2(res.lstPromotionField, res.lstBenefit)
    })
  }

  const onSetData = (resData) => {
    if (resData) {
      form.setValue("nCoinAmoun", resData.nCoinPackage_Coin_Amount);
      form.setValue("nCoinFree", resData.nCoinFree);
      form.setValue("nTotalCoin", resData.nTotalCoin);
      form.setValue("nExDurationAmoun", resData.nCoinPackage_Expiry_Duration);
      form.setValue("nTotalPrice", resData.nCoinPackage_Price);
      form.setValue("nDiscount", resData.nCoinPackage_Discount);
      form.setValue("nNetPrice", resData.nCoinPackage_NetPrice);
      form.setValue("nVat", resData.nCoinPackage_Vat);
      form.setValue("nPriceAfterVat", resData.nCoinPackage_AfterVat);
      form.setValue("dStartDate", resData.dCoinPackage_StartDate);

      ////วันที่เริ่มต้นPackage
      const dStartPackage = moment(resData.dCoinPackage_StartDate);
      const dStart = moment(resData.dDateNow);
      ////วันที่เริ่มต้นPackageต้องไม่น้อยกว่าวันปัจจุบัน
      ////แต่ถ้าวันที่เริ่มต้นมากกว่าวันบัจจุบันให้อิงตามวันที่ของPackage
      if (dStartPackage > dStart) {
        setStartDate(dStartPackage);
        setStartDatePromotion(dStartPackage);
      }
      else {
        setStartDate(dStart);
        setStartDatePromotion(dStart);
      }


      const dDateMax = moment(resData.dDateMax);
      setEndDatePromotion(dDateMax);

      ///วันที่ของPromotion
      if (resData.dStartPromotion != null && resData.dEndPromotion != null) {
        form.setValue("dStartDatePromotion", resData.dStartPromotion);
        form.setValue("dEndDatePromotion", resData.dEndPromotion);
        setIsActive(resData.IsActive);
      }

    }
  }

  const onSetData2 = (lstPromotionField, lstBenefit) => {
    for (const item of lstPromotionField) {
      if (item.nFieldID === 9) {
        setIsCoin(item.isShow);
      }
      if (item.nFieldID === 10) {
        setIsCoinFree(item.isShow);
      }
      if (item.nFieldID === 11) {
        setIsPeriod(item.isShow);
      }
      if (item.nFieldID === 12) {
        setIsDiscount(item.isShow);
      }
      if (item.nFieldID === 13) {
        setIsGrandTotal(item.isShow);
      }
      if (item.nFieldID === 14) {
        setIsProDateStart(item.isShow);
      }
      if (item.nFieldID === 15) {
        setIsProDateEnd(item.isShow);
      }
      if (item.nFieldID === 16) {
        setIsBenefit(item.isShow);
        if (lstBenefit.length > 0) {
          ////setค่าสิทธิประโยชน์อื่นๆ
          setBenefitList(lstBenefit);
          const schemaTemp = {};
          for (const item of lstBenefit) {
            form.setValue("sBenefit" + item.nID, item.sBenefit);
            schemaTemp[`sBenefit${item.nID}`] = yupFormSchemas.string("สิทธิประโยชน์", { required: item.isShow });
          }
          setSchemaWork(schemaTemp);
        }
      }

    }
  }

  const onBack = () => {
    history("/ManagePromotion")
  }

  const onSave = (e) => {
    ///console.log("benefitList", benefitList);
    const lstBenefit = [];
    for (const item of benefitList) {
      const obj = {
        nOrder: item.nOrder,
        sBenefit: form.getValues("sBenefit" + item.nID) || "",
      }
      lstBenefit.push(obj);
    }

    ////console.log("objSchema", objSchema);
    const arrBenifitFiled = arrFiled();

    let sPromotionName;
    if (form.getValues("sPromotion")) {
      sPromotionName = form.getValues("sPromotion").label;
    }

    let nCoinPackageID;
    if (form.getValues("Package")) {
      nCoinPackageID = form.getValues("Package").value;
    }

    const param = {
      nPromotionID: sID,
      nPackageType: 2,
      sPromotionName: sPromotionName,
      sPromotionOther: form.getValues("sOther") || "",
      nJobPackageID: 0,
      nCoinPackageID: nCoinPackageID,
      nStartDate: moment(form.getValues("dStartDatePromotion")).valueOf(),
      nEndDate: moment(form.getValues("dEndDatePromotion")).valueOf(),
      isActive: isActive,

      dStartDate: moment(form.getValues("dStartDatePromotion")).format("YYYY-MM-DD"),
      dEndDate: moment(form.getValues("dEndDatePromotion")).format("YYYY-MM-DD"),
      lstField: arrBenifitFiled,
      lstBenefit: lstBenefit,
    }
    ////console.log("param", param);

    dispatch(DialogActionCreators.OpenDialogSubmit("คุณต้องการบันทึกข้อมูลหรือไม่ ?", () => {
      dispatch(DialogActionCreators.CloseDialogSubmit() as any);
      AxiosPost("ManagePromotion/SavePromotion", param, (result) => {
        ResultAPI(result, "คุณต้องการบันทึกข้อมูลหรือไม่ ?", () => {
          dispatch(DialogActionCreators.OpenDialogSuccess("บันทึกข้อมูลสำเร็จ", () => onBack()) as any);
        });
      }, (err) => {
        if (err.Status === 409) {
          dispatch(DialogActionCreators.OpenDialogWarning(err.Message, () => { }) as any)
        }
      });
    }) as any);

  }

  const arrFiled = () => {
    let arrFild = {};
    const arrBenifitFiled = [];

    arrFild = {
      nFieldID: 9,
      isShow: isCoin,
    }
    arrBenifitFiled.push(arrFild);
    arrFild = {
      nFieldID: 10,
      isShow: isCoinFree,
    }
    arrBenifitFiled.push(arrFild);
    arrFild = {
      nFieldID: 11,
      isShow: isPeriod,
    }
    arrBenifitFiled.push(arrFild);
    arrFild = {
      nFieldID: 12,
      isShow: isDiscount,
    }
    arrBenifitFiled.push(arrFild);
    arrFild = {
      nFieldID: 13,
      isShow: isGrandTotal,
    }
    arrBenifitFiled.push(arrFild);
    arrFild = {
      nFieldID: 14,
      isShow: isProDateStart,
    }
    arrBenifitFiled.push(arrFild);
    arrFild = {
      nFieldID: 15,
      isShow: isProDateEnd,
    }
    arrBenifitFiled.push(arrFild);
    arrFild = {
      nFieldID: 16,
      isShow: isBenefit,
    }
    arrBenifitFiled.push(arrFild);

    return arrBenifitFiled;
  }

  const AddComponent = (nOrder) => {

    if (nOrder === 0) {
      let newObject = { ...objBenefit };
      let maxID = Math.max(...benefitList.map((item) => item.nOrder));
      newObject.nID = maxID < 1 ? 1 : maxID + 1;
      newObject.nOrder = maxID < 1 ? 1 : maxID + 1;
      let newData = [...benefitList, { ...newObject }];
      ///setBenefitList([...benefitList, { ...newData }]);

      setBenefitList(newData);
    }
    else {

      let newObject = { ...objBenefit };
      let maxID = benefitList.reduce((max, item) => item.nID > max ? item.nID : max, benefitList[0].nID);
      let orderID = Math.max(...benefitList.map((item) => item.nOrder));
      newObject.nID = maxID < 1 ? 1 : maxID + 1;
      newObject.nOrder = orderID < 1 ? 1 : orderID + 1;

      benefitList.splice(nOrder, 0, newObject);
      let newData = [...benefitList];

      newData.forEach((element, index) => {
        element.nOrder = index + 1;
      });
      setBenefitList(newData);

      const lstBenefit = [];
      for (const item of benefitList) {
        const obj = {
          nID: item.nID,
          nOrder: item.nOrder,
          sBenefit: form.getValues("sBenefit" + item.nID) || "",
        }
        lstBenefit.push(obj);
      }

      for (const item of lstBenefit) {
        form.setValue("sBenefit" + item.nID, item.sBenefit);
      }

    }

    loopSetSchemaWork();
  };

  const onDelete = (nID) => {
    let arrClone: any[] = benefitList;
    form.resetField("sBenefit" + nID);
    let _arrClone = arrClone.filter((f) => f.nID !== nID);
    if (_arrClone.length >= 1) {
      _arrClone.forEach((element, index) => {
        element.nOrder = index + 1;
      });
      setBenefitList(_arrClone);

      const key = "sBenefit" + nID;
      delete formSchema[key];
      const FormSchema = { ...formSchema };
      setFormSchema(FormSchema);
    }
  };

  const nSumnCoin = () => {
    let nCoin = form.getValues("nCoinAmoun");
    let nCoinFree = form.getValues("nCoinFree");
    let nTotalCoin = nCoin + nCoinFree
    form.setValue("nTotalCoin", nTotalCoin);
  }

  const nSumnNetPrice = () => {
    let nNetPrice = form.getValues("nNetPrice");
    let nVat = ((nNetPrice * 7) / 100);
    let nPriceAfterVat = nNetPrice + nVat;
    form.setValue("nVat", nVat);
    form.setValue("nPriceAfterVat", nPriceAfterVat);
  }


  return (
    <FormProvider {...form}>
      <Stack sx={{ mx: "2%" }}>
        <Grid container>

          <Grid item md={2.5} xs={12}
            sx={{
              width: "100%",
              backgroundColor: "#ffc738",
              borderRadius: "26px",
              p: "8px"
            }}>
            <Typography variant="h6" color="#FFF" component="div" align='center' sx={{ fontSize: "1rem " }}>
              โปรโมชั่น Coin
            </Typography>
          </Grid>

          <Grid item xs={12}>

            <Stack spacing={3} width={"100%"} direction="column"
              sx={{
                border: "2px #eeeeee solid",
                backgroundColor: "rgba(255,255,255,0.75)",
                borderRadius: 5, p: 2, my: 2
              }}>


              <Grid container sx={{ justifyContent: "space-between", rowGap: "1rem" }}>
                <Grid container item lg={7} xs={12}>
                  <AutoCompleteSelect
                    required
                    name={"sPromotion"}
                    label={"ชื่อโปรโมชั่น (Promotion Name)"}
                    options={dropdownPromotion}
                    onChange={(e) => {
                      onGetListPackage(e.value);
                      /// if (e.value === "1" || e.value === "2") {
                      ///   setIsShowOther(true);
                      /// }

                      ///DefaultID เพื่อให้รู้ว่าคือ อื่นๆ
                      if (e.value === "-888") {
                        setIsShowOther(true);
                      }
                      else {
                        setIsShowOther(false);
                        form.resetField("sOther");
                      }
                    }}
                    disabled={isDisable}
                  />
                </Grid>
                <Grid container item lg={4.4} xs={12}>
                  {isShowOther ?
                    <Input
                      name={"sOther"}
                      required={true}
                      ///label={"อื่นๆ (Other)"}
                      disabled={permission !== 2}
                      fullWidth
                      maxLength={150}
                      label={
                        <Typography sx={{ fontWeight: 400 }} component="label">
                          {"อื่นๆ (Other)"}
                        </Typography>
                      }
                    />
                    :
                    <></>
                  }

                </Grid>
              </Grid>


              <Grid container>
                <Grid item xs={12}>
                  <AutoCompleteSelect
                    required
                    name={"Package"}
                    label={"เลือกแพ็กเกจ"}
                    options={dropdownPackage}
                    onChange={(e) => {
                      form.resetField("dStartDatePromotion");
                      form.resetField("dEndDatePromotion");
                      onGetPackage(e.value);
                    }}
                    disabled={isDisable}
                  />
                </Grid>
              </Grid>


              <Grid container sx={{ justifyContent: "space-between", rowGap: "1rem" }}>
                <Grid container item lg={5.5} xs={12}>
                  <Typography>
                    <FormControlLabel
                      control={
                        <IOSSwitch
                          sx={{ m: 1 }}
                          onChange={() => setIsCoin(!isCoin)}
                          checked={isCoin}
                          disabled={permission !== 2}
                        />
                      }
                      label={""}
                    />
                  </Typography>
                  <Box sx={{ width: "80%" }}>
                    <InputNumber
                      name={"nCoinAmoun"}
                      required={false}
                      label={"จำนวน Coin (ตามแพ็กเกจ)"}
                      disabled={true}
                    />
                  </Box>
                </Grid>

                <Grid container item lg={5.5} xs={12} sx={{ justifyContent: "end" }}>
                  <Typography>
                    <FormControlLabel
                      control={
                        <IOSSwitch
                          sx={{ m: 1 }}
                          onChange={() => setIsCoinFree(!isCoinFree)}
                          checked={isCoinFree}
                          disabled={permission !== 2}
                        />
                      }
                      label={""}
                    />
                  </Typography>
                  <Box sx={{ width: "80%" }}>
                    <InputNumber
                      name={"nCoinFree"}
                      required={false}
                      label={"จำนวน Coin (Free)"}
                      disabled={true}
                      onBlur={nSumnCoin}
                    />
                  </Box>
                </Grid>
              </Grid>


              <Grid container sx={{ justifyContent: "space-between", rowGap: "1rem" }}>
                <Grid container item lg={5.5} xs={12}>
                  <Box sx={{ width: "63px", height: "42px" }}></Box>
                  <Box sx={{ width: "80%" }}>
                    <InputNumber
                      name={"nTotalCoin"}
                      required={false}
                      label={"จำนวน Coin (Total)"}
                      disabled={true}
                    />
                  </Box>
                </Grid>

                <Grid container item lg={5.5} xs={12} sx={{ justifyContent: "end" }}>
                  <Typography>
                    <FormControlLabel
                      control={
                        <IOSSwitch
                          sx={{ m: 1 }}
                          onChange={() => setIsPeriod(!isPeriod)}
                          checked={isPeriod}
                          disabled={permission !== 2}
                        />
                      }
                      label={""}
                    />
                  </Typography>
                  <Box sx={{ width: "80%" }}>
                    <InputNumber
                      name={"nExDurationAmoun"}
                      required={false}
                      label={"ระยะเวลาหมดอายุ (เดือน) (Period)"}
                      disabled={true}
                    />
                  </Box>
                </Grid>
              </Grid>

              <Grid container sx={{ justifyContent: "space-between", rowGap: "1rem" }}>
                <Grid container item lg={5.5} xs={12}>
                  <Box sx={{ width: "63px", height: "42px" }}></Box>
                  <Box sx={{ width: "80%" }}>
                    <InputNumber
                      name={"nTotalPrice"}
                      required={false}
                      label={"ราคาสุทธิ (Special Price)"}
                      disabled={true}
                      decimalPoint={2}
                    />
                  </Box>
                </Grid>

                <Grid container item lg={5.5} xs={12} sx={{ justifyContent: "end" }}>
                  <Typography>
                    <FormControlLabel
                      control={
                        <IOSSwitch
                          sx={{ m: 1 }}
                          onChange={() => setIsDiscount(!isDiscount)}
                          checked={isDiscount}
                          disabled={permission !== 2}
                        />
                      }
                      label={""}
                    />
                  </Typography>
                  <Box sx={{ width: "80%" }}>
                    <InputNumber
                      name={"nDiscount"}
                      required={false}
                      label={"ส่วนลด (% save)"}
                      disabled={true}
                      decimalPoint={2}
                    />
                  </Box>
                </Grid>
              </Grid>

              <Grid container sx={{ justifyContent: "space-between", rowGap: "1rem" }}>
                <Grid container item lg={5.5} xs={12}>
                  <Box sx={{ width: "63px", height: "42px" }}></Box>
                  <Box sx={{ width: "80%" }}>
                    <InputNumber
                      name={"nNetPrice"}
                      required={false}
                      label={"รวมเป็นเงิน"}
                      onBlur={nSumnNetPrice}
                      disabled={true}
                      decimalPoint={2}
                    />
                  </Box>
                </Grid>

                <Grid container item lg={5.5} xs={12} sx={{ justifyContent: "end" }}>
                  <Box sx={{ width: "63px", height: "42px" }}></Box>
                  <Box sx={{ width: "80%" }}>
                    <InputNumber
                      name={"nVat"}
                      required={false}
                      label={"ภาษีมูลค่าเพิ่ม 7% (Vat 7%)"}
                      disabled={true}
                      fullWidth
                      decimalPoint={2}
                    />
                  </Box>
                </Grid>
              </Grid>

              <Grid container sx={{ justifyContent: "space-between", rowGap: "1rem" }}>
                <Grid container item lg={5.5} xs={12}>
                  <Typography>
                    <FormControlLabel
                      control={
                        <IOSSwitch
                          sx={{ m: 1 }}
                          onChange={() => setIsGrandTotal(!isGrandTotal)}
                          checked={isGrandTotal}
                          disabled={permission !== 2}
                        />
                      }
                      label={""}
                    />
                  </Typography>
                  <Box sx={{ width: "80%" }}>
                    <InputNumber
                      name={"nPriceAfterVat"}
                      required={false}
                      label={"ราคารวมทั้งสิ้น (Grand Total)"}
                      decimalPoint={2}
                      disabled={true}
                      fullWidth
                    />
                    <Typography style={{ color: "red", fontSize: "13px" }}>{"มีผลกับ Guest เท่านั้น"}</Typography>
                  </Box>
                </Grid>

                <Grid container item lg={5.5} xs={12} sx={{ justifyContent: "end" }}>
                  <Box sx={{ width: "63px", height: "42px" }}></Box>
                  <Box sx={{ width: "80%" }}>
                    <DatePickerFormItem
                      name={"dStartDate"}
                      label={"วันที่เริ่ม (Start Date)"}
                      required={false}
                      disabled={true}
                      minDate={startDate}
                      fullWidth
                    />
                  </Box>
                </Grid>
              </Grid>

              {/*==== Date Promotion ====*/}
              <Grid container sx={{ justifyContent: "space-between", rowGap: "1rem" }}>
                <Grid container item lg={5.5} xs={12}>
                  <Typography>
                    <FormControlLabel
                      control={
                        <IOSSwitch
                          sx={{ m: 1 }}
                          onChange={() => setIsProDateStart(!isProDateStart)}
                          checked={isProDateStart}
                          disabled={permission !== 2}
                        />
                      }
                      label={""}
                    />
                  </Typography>
                  <DatePickerFormItem
                    name={"dStartDatePromotion"}
                    label={"วันที่เริ่มต้นโปรโมชั่น"}
                    required
                    disabled={permission !== 2}
                    minDate={startDate}
                    maxDate={endDatePromotion}
                    fullWidth
                    onChange={(e) => {
                      const dStartDatePromotion = e;
                      setStartDatePromotion(dStartDatePromotion);
                      const dEndDatePromotion = moment(form.getValues("dEndDatePromotion"));

                      ///ถ้าวันที่เริ่มต้นโปรโมชั่นมากกว่าวันที่สิ้นสุด จะเครียค่าวันที่สิ้นสุด
                      if (dStartDatePromotion > dEndDatePromotion) {
                        form.resetField("dEndDatePromotion");
                      }

                    }}
                  />
                </Grid>

                <Grid container item lg={5.5} xs={12} sx={{ justifyContent: "end" }}>
                  <Typography>
                    <FormControlLabel
                      control={
                        <IOSSwitch
                          sx={{ m: 1 }}
                          onChange={() => setIsProDateEnd(!isProDateEnd)}
                          checked={isProDateEnd}
                          disabled={permission !== 2}
                        />
                      }
                      label={""}
                    />
                  </Typography>
                  <Box sx={{ width: "80%" }}>
                    <DatePickerFormItem
                      name={"dEndDatePromotion"}
                      label={"วันที่สิ้นสุดโปรโมชั่น"}
                      required
                      disabled={permission !== 2}
                      minDate={startDatePromotion}
                      fullWidth
                      onChange={(e) => {
                        setEndDatePromotion(e);
                        ////console.log("dStartDatePromotion", e);
                      }}
                    />
                  </Box>
                </Grid>
              </Grid>

              {/*==== Benefit ====*/}
              <Grid container>
                <Grid item xs={12}>
                  <Typography>
                    <FormControlLabel
                      control={
                        <IOSSwitch
                          sx={{ m: 1 }}
                          checked={isBenefit}
                          disabled={permission !== 2}
                          onChange={() => {
                            if (isBenefit) {
                              for (const item of benefitList) {
                                form.clearErrors("sBenefit" + item.nID);
                              }
                            }
                            setIsBenefit(!isBenefit)
                          }}
                        />
                      }
                      label={"สิทธิประโยชน์อื่นๆ"}
                    />
                  </Typography>
                </Grid>
              </Grid>

              {/*====Text Benefit ====*/}
              {benefitList.map((item) => {
                ////console.log("item", item);
                return (
                  <Grid key={item.nOrder} container sx={{ justifyContent: "space-between", rowGap: "1rem" }}>
                    <Grid item xs={9} sx={{}}>
                      <Input
                        id={item.nOrder + ""}
                        name={"sBenefit" + item.nID}
                        required
                        fullWidth
                        maxLength={300}
                        maxRow={1}
                        disabled={permission === 1 ? true : !isBenefit}
                        label={
                          <Typography sx={{ fontWeight: 400 }} component="label">
                            {"สิทธิประโยชน์"}
                          </Typography>
                        }
                      />
                    </Grid>

                    <Grid item container xs={2.5} sx={{ gap: "1rem" }}>
                      {permission === 2 ?
                        <>
                          <IconButton size="small" sx={{
                            borderRadius: "25px",
                            width: "40px",
                            height: "40px",
                            backgroundColor: "#d62c2c",
                            ':hover': {
                              backgroundColor: '#d62c2c',
                            },
                          }}
                            onClick={(e) => onDelete(item.nID)}
                            name={"btnMinus" + item.nOrder}
                          >
                            <FaMinus style={{ color: "#fff" }} />
                          </IconButton>
                          <IconButton size="small" sx={{
                            borderRadius: "25px",
                            width: "40px",
                            height: "40px",
                            backgroundColor: "#2499da",
                            ':hover': {
                              backgroundColor: '#2499da',
                            },
                          }}
                            onClick={() => AddComponent(item.nOrder)}
                            name={"btnPlush" + item.nOrder}
                          >
                            <FaPlus style={{ color: "#fff" }} />
                          </IconButton>
                        </>
                        :
                        <></>
                      }
                    </Grid>
                  </Grid>
                )
              })}


              <Grid container>
                <Grid item xs={12}>
                  <Typography>
                    <span style={{ fontWeight: 600 }}> สถานะ </span>
                    <span style={{ color: "red" }}> *</span>
                  </Typography>
                  <Typography>
                    <FormControlLabel
                      control={
                        <IOSSwitch
                          sx={{ m: 1 }}
                          onChange={() => setIsActive(!isActive)}
                          checked={isActive}
                          disabled={permission !== 2}
                        />
                      }
                      label={isActive ? "ใช้งาน" : "ไม่ใช้งาน"}
                    />
                  </Typography>
                </Grid>
              </Grid>


              <Grid container>
                <Grid item xs={12}>
                  <Stack direction="row" >
                    <Box sx={{ flex: 1 }}>
                      <BtnBack txt='ย้อนกลับ' onClick={() => onBack()} />
                    </Box>
                    {permission !== 2 ?
                      <></>
                      :
                      <BtnSave
                        txt='บันทึก'
                        IsDisabled={permission !== 2}
                        onClick={form.handleSubmit(e => {
                          onSave(e)
                        },
                          (err) => {
                            ////console.log("err", err)
                          })}
                      />
                    }
                  </Stack>
                </Grid>
              </Grid>


              <Grid container sx={{ marginTop: "1%", marginBottom: "1%", width: "100%" }}>
              </Grid>

            </Stack>

          </Grid>

        </Grid>
      </Stack>
    </FormProvider>
  )
}

export default ManagePromotionCoin

export interface Benefit {
  nID: number;
  sBenefit: string;
  nOrder: number;
  IsDelete: boolean;
}