import { Grid, Stack, Typography } from "@mui/material";
import { AccordionCustom, AccordionDetailsCustom, AccordionSummaryCustom } from "components/SeekerProfile/ElementCustom"
import { useState, useContext, useEffect } from "react";
import { i18n } from "i18n";
import UploadFile from "components/Common/ElementInForm/UploadFile/UploadFile";
import { ProfileContext } from "components/SeekerProfile/Context/ProfileContext";
import { BtnSubmit, BtnSubmitRegister } from "components/Common/Button";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { DialogActionCreators } from "store/redux/DialogAlert";
import { FnBlock_UI } from "service/SystemFunction/SystemFunction";
import { AxiosGet, AxiosPost, AxiosPostCreateProfile, ResultAPI, Extension } from "service/CommonFunction/TS_function";
import secureLocalStorage from "react-secure-storage";
import { HubConnectionState, HubConnectionBuilder } from '@microsoft/signalr';
interface IPorpsProfileInformation {
  children?: React.ReactNode;
  onSuccessCreate: Function;
}
const ProfileWorkInformation = (props: IPorpsProfileInformation) => {
  const { onSuccessCreate } = props;
  const [expandedFile, setExpandedFile] = useState(true);
  const [expandedTranscript, setExpandedTranscript] = useState(true);
  const i18nField = "entities.SeekerProfile";
  const i18nCommon = 'common';
  const {
    fileUploadResumeContext,
    setFileUploadResume,
    fileUploadTranscriptContext,
    setFileUploadTranscript,
    myProfileContext
  } = useContext(ProfileContext);
  const { BlockUI, UnBlockUI } = FnBlock_UI();
  const dispatch = useDispatch();
  const history = useNavigate();

  const [formSchema, setFormSchema] = useState<any>({});
  const schema = yup.object().shape(formSchema);

  const form = useForm({
    resolver: yupResolver(schema),
    shouldUnregister: false,
    shouldFocusError: true,
    mode: "all",
  });

  const onUploadFile = () => {
    if (fileUploadResumeContext.length > 5) {
      dispatch(
        DialogActionCreators.OpenDialogWarning(i18n(`${i18nField}.fileCountLimit`))
      );
    }
  }

  const onSubmit = () => {

    if ((fileUploadResumeContext.length === 0)) {
      dispatch(DialogActionCreators.OpenDialogWarning(i18n(`${i18nField}.MsgResume`), () => {
        dispatch(DialogActionCreators.CloseDialogWarning() as any);
      }) as any);
    }

    else {
      const dataProfile = {
        sName: myProfileContext.Name,
        sSurname: myProfileContext.Surname,
        sNameEN: myProfileContext.NameEN,
        sSurnameEN: myProfileContext.SurnameEN,
        sEmail: myProfileContext.Email,
        sPWD: myProfileContext.PWD,
        IsGetNews: myProfileContext.IsGetNews,
        IsUnderage: myProfileContext.IsUnderage,
        sTel: myProfileContext.Tel,
        listFileResume: fileUploadResumeContext,
        listFileTranscript: fileUploadTranscriptContext,
      };

      dispatch(DialogActionCreators.OpenDialogSubmit(i18n(`${i18nField}.ConfirmMsgCreate`), () => {
        dispatch(DialogActionCreators.CloseDialogSubmit() as any);
        dispatch(DialogActionCreators.LoadSubmit("Waiting...") as any);
        BlockUI();

        // AxiosGet("Authen/GetTokenRegister", {}, (res) => {
        //   AxiosPostCreateProfile(`Guest/CreateProfile`, res, dataProfile, (result) => {
        //     const res = result.Data;
        //     if (res.IsSuccess) {
        //       UnBlockUI();
        //       ResultAPI(result, i18n(`${i18nField}.MsgCreated`), () => { //Saved successfully 
        //         dispatch(DialogActionCreators.OpenDialogSuccess(
        //           i18n(`${i18nField}.MsgCreated`),//Saved successfully     
        //           () => onLogin(myProfileContext.Email, myProfileContext.PWD)
        //         ) as any);
        //       });
        //     }
        //     UnBlockUI();
        //   },
        //     (err) => {
        //       if (err && err.response !== "") {
        //         dispatch(DialogActionCreators.OpenDialogWarning(err.Message));
        //       }
        //     }
        //   );
        // });


        AxiosPost("Guest/CreateProfile", dataProfile, (result) => {

          const res = result.Data;
          if (res.IsSuccess) {
            UnBlockUI();
            ResultAPI(result, i18n(`${i18nField}.MsgCreated`), () => { //Saved successfully 
              dispatch(DialogActionCreators.OpenDialogSuccess(
                i18n(`${i18nField}.MsgCreated`),//Saved successfully     
                () => onLogin(myProfileContext.Email, myProfileContext.PWD)
              ) as any);
            });
          }
          UnBlockUI();

        }, (err) => {
          if (err && err.response !== "") {
            dispatch(DialogActionCreators.OpenDialogWarning(err.Message));
          }
        })


      }) as any);
    }
  };

  const connection = new HubConnectionBuilder()
    .withUrl(process.env.REACT_APP_API_URL + "hubs/auth")
    .withAutomaticReconnect()
    .build();

  const onLogin = (sEmail, sUserPW) => {
    BlockUI();
    let userlogin = {
      sUserName: sEmail,
      sUserCode: sUserPW,
      isRemember: false,
      nType: 1,
    }

    AxiosPost("Authen/NormalAuthen", userlogin, (res) => {
      secureLocalStorage.setItem("isSignOut_bps", "1");
      secureLocalStorage.setItem("sUserId", res.sUserKeys)
      secureLocalStorage.setItem("sUserFullNameEN", res.sFullNameEN)
      secureLocalStorage.setItem("sUserFullNameTH", res.sFullNameTH)
      secureLocalStorage.setItem("nGroupType", res.nGroupType)
      secureLocalStorage.setItem(process.env.REACT_APP_JWT_KEY as string, res.sToken);

      let sConnectionId = secureLocalStorage.getItem("connectionID_bps");
      if (connection.state != HubConnectionState.Connected) {
        connection.start().then(() => {
          if (!sConnectionId) {
            secureLocalStorage.setItem("connectionID_bps", connection.connectionId);
          }
          connection.send("OnSignOutSignal", sConnectionId ?? connection.connectionId, true)
        }).catch((e) => {
          console.log("Connection failed");
        });
      } else if (res.Status === 404) {
        dispatch(DialogActionCreators.OpenDialogWarning(
          i18n(`${i18nCommon}.msgAlertIncorrect`), () => { //Login Type is Wrong
          }) as any)
      }
      else {
        connection.send("OnSignOutSignal", sConnectionId ?? connection.connectionId, false)
      }
      history("/MyProfile")
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }, (err) => {
      if (err.Status === 409) {
        dispatch(DialogActionCreators.OpenDialogWarning(
          i18n(`${i18nCommon}.msgAlertUsernameOrSecureCodeWrong`), () => {
          }) as any)
      } else {
        dispatch(DialogActionCreators.OpenDialogWarning(err.Message) as any)
      }
    }, () => UnBlockUI())
  }
  return (
    <FormProvider {...form}>
      <AccordionCustom
        sx={{ border: "10px", p: 1, flex: 1, m: "2%" }}
        expanded={expandedFile}
        onChange={() => setExpandedFile((prev) => !prev)}
      >
        <AccordionSummaryCustom>
          <Typography sx={{ width: "80%", flexShrink: 0 }}>
            {i18n(`${i18nField}.Resume`)} <span style={{ color: "red" }}> *</span>
          </Typography>
        </AccordionSummaryCustom>
        <AccordionDetailsCustom>
          <Grid sx={{ border: "10px", p: 1, flex: 1, m: "2%" }} item xs={12}>
            <UploadFile
              id="myFileResume"
              keyID={1}
              arrFile={fileUploadResumeContext || []}
              setarrFile={setFileUploadResume}
              nLimitFile={2} //ขนาดไฟล์ไม่เกิน 2 Mb
              nLimitCountFile={5} //Upload ได้ 5 file
              IsFolder={false}
              IsFile={true}
              IsMultiple={true}
              Extension={["docx", "pptx", "doc", "ppt", "pdf", ...Extension.Image]}
              IsDrag={false}
              isSetupEmployer={false}
            />
          </Grid>
        </AccordionDetailsCustom>
      </AccordionCustom>

      <AccordionCustom
        sx={{ border: "10px", p: 1, flex: 1, m: "2%" }}
        expanded={expandedTranscript}
        onChange={() => setExpandedTranscript((prev) => !prev)}
      >
        <AccordionSummaryCustom>
          <Typography sx={{ width: "80%", flexShrink: 0 }}>
            {i18n(`${i18nField}.Transcript`)}
          </Typography>
        </AccordionSummaryCustom>
        <AccordionDetailsCustom>
          <Grid sx={{ border: "10px", p: 1, flex: 1, m: "2%" }} item xs={12}>
            <UploadFile
              id="myFileTranscript"
              keyID={2}
              arrFile={fileUploadTranscriptContext || []}
              setarrFile={setFileUploadTranscript}
              nLimitFile={2} //ขนาดไฟล์ไม่เกิน 2 Mb
              nLimitCountFile={5} //Upload ได้ 5 file
              IsFolder={false}
              IsFile={true}
              IsMultiple={true}
              Extension={["docx", "pptx", "doc", "ppt", "pdf", ...Extension.Image]}
              IsDrag={false}
              isSetupEmployer={false}
            />
          </Grid>
        </AccordionDetailsCustom>
      </AccordionCustom>

      <Stack
        spacing={1}
        sx={{ border: "10px", p: 1, flex: 1, m: "2%" }}
        direction="row"
        justifyContent="center"
        alignItems="center"
      >

        <BtnSubmitRegister
          onClick={form.handleSubmit(onSubmit, (error) => console.log("err", error))}
          txt={i18n(`${i18nField}.BtnCreateProfile`)}
        />
      </Stack>
    </FormProvider>
  )
}
export default ProfileWorkInformation