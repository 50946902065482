import { Grid, Stack } from "@mui/material";
import AdminBannerMasterTable from "./AdminBannerMasterTable";

export default function FromMasterTable() {
  return (
    <Stack sx={{ mx: "1%" }}>
      <Grid item >
        <AdminBannerMasterTable />
      </Grid>
    </Stack>
  );
}