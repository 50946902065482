import React, { useState, useEffect } from 'react';
import {
    Box, Stack, Typography,
    SxProps, CardMedia, Container,
    ImageList, ImageListItem
} from "@mui/material"
// import ArticleGallery from 'components/Article/ArticleGallery';
import { BtnBack } from "components/Common/Button";
import { useNavigate } from "react-router-dom";
import ModalViewImage from "components/Common/ModalViewImage/ModalViewImage"
import ParseHTML from "html-react-parser";

const objStyle = {
    w_600: {
        fontWeight: 600,
    } as SxProps,
    w_500: {
        fontWeight: 500,
    } as SxProps,
    fs_12: {
        marginTop: "1em",
        fontWeight: 400,
        // fontSize: "12px"
    } as SxProps,
    text_date: {
        color: "#1ca5fc",
        fontSize: "0.8em",
    } as SxProps,
    image_cover: {
        display: "flex", flex: 1, minHeight: 400, backgroundSize: "cover"
    } as SxProps,
    container: {
        // marginTop: "10vh",
        " > div": {
            marginTop: "5px"
        }
    } as SxProps,
    youtube_style: {
        width: "60vw;",
        height: "calc((60vw)*9/16);",
        maxHeight: "100vw",
        maxWidth: "100%",
        " @media (max-width:767px) ": {
            width: "80vw;",
            height: "calc((80vw)*9/16);",
        }
    } as SxProps,
    row_tag: {
        padding: "0px",
        marginTop: "1px"
    } as SxProps,
    img_list: {
        minWidth: "20vw",
        maxWidth: "30vw",
        minHeight: "85%",
        maxHeight: "25vh",
        padding: "5px 10px",
        paddingTop: "10px",
        textAlign: "center",
        display: 'flex',
        justifyContent: "center",
        " @media (max-width:1100px) ": {
            minWidth: "15vw",
            minHeight: "90%",
            maxWidth: "25vw",
        },
        " @media (max-width:767px) ": {
            minWidth: "30vw",
            minHeight: "90%",
            maxWidth: "50vw",
        },
    } as SxProps
}

export default function BPS_Content({
    sHeadline, sImg, sUpdateDate, sDesc, lstContent, lstTag,
    lstSource, lstGallery, isCanBack = false, imgAlt = "", isDashboard = false }) {
    let history = useNavigate();
    const [isViewImage, setIsViewImage] = useState(false);
    const [imageView, setImageView] = useState("");
    return (
        <>
            <ModalViewImage
                image={imageView}
                isOpen={isViewImage}
                handleClose={() => setIsViewImage(false)}
            />
            <Container
                sx={objStyle.container}>
                {!isDashboard && (
                    <>
                        <Box >
                            <Typography variant="h5" sx={objStyle.w_600} >{sHeadline}</Typography>
                            <Stack direction="row" justifyContent="start">
                                <Typography variant="body1" sx={objStyle.text_date} >{sUpdateDate}</Typography>
                            </Stack>
                        </Box>
                        {/* {objStyle.image_cover ? 
                        <Box sx={{ background: `url(${sImg}) no-repeat center`, ...objStyle.image_cover }} />
                        : null} */}
                        {/* <Stack direction="row" >
                            <Typography sx={objStyle.w_500} variant="body1" >{sDesc}</Typography>
                        </Stack> */}
                    </>
                )}
                {lstContent.map((item, key) => {
                    switch (item.nTypeId) {
                        case 1:
                            return (
                                <Stack key={`type_1_${key}`} direction="row" >
                                    <div>{ParseHTML(item.sContent)}</div>
                                </Stack>
                            )
                        case 2:
                            return (
                                <Stack key={`type_2_${key}`} direction="row" >
                                    <Stack direction="column" alignItems="center" flex={1}>
                                        <CardMedia onClick={() => {
                                            // setImageView(item.sImageOrVideo)
                                            setImageView(item.sFileNameSys)
                                            setIsViewImage(true)
                                        }} component={"img"} sx={{
                                            objectFit: "contain",
                                            width: "auto",
                                            maxWidth: "100%",
                                            maxHeight: "calc(90vh - 45px)",
                                            cursor: "pointer"
                                            // }} src={item.sImageOrVideo} />
                                        }} src={item.sFileNameSys} />
                                        <Typography sx={objStyle.fs_12} variant="body1" >{item.sContent}</Typography>
                                    </Stack>
                                </Stack>
                            )
                        case 3:
                            return (
                                <Stack key={`type_3_${key}`} direction="row" >
                                    <Stack direction="column" alignItems="center" flex={1}>
                                        {/* <CardMedia component="video" controls height="400px" src={item.sImageOrVideo} /> */}
                                        <CardMedia component="video" controls height="400px" src={item.sFileNameSys} />
                                        <Typography sx={objStyle.fs_12} variant="body1" >{item.sContent}</Typography>
                                    </Stack>
                                </Stack>
                            )
                        case 4:
                            const splitPath = item.sUrl.split('/');
                            const endPath = splitPath[splitPath.length - 1];
                            let codeYoutubeSave = endPath;
                            if (endPath.indexOf("watch") != -1) {
                                codeYoutubeSave = endPath.split('=', endPath.length)[1];
                            }
                            return (
                                <Stack key={`type_4_${key}`} direction="row" >
                                    <Stack direction="column" alignItems="center" flex={1}>
                                        <CardMedia sx={objStyle.youtube_style} component="iframe" allow={"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"}
                                            allowFullScreen frameBorder={0} src={`https://www.youtube.com/embed/${codeYoutubeSave}`} />
                                        <Typography sx={objStyle.fs_12} variant="body1" >{item.sContent}</Typography>
                                    </Stack>
                                </Stack>
                            )
                    }
                })}
            </Container >
            {
                isCanBack === true && <Stack direction="row" sx={{ marginTop: "0px", marginLeft: "10%", paddingBottom: "10px" }}>
                    <BtnBack onClick={() => history(-1)} />
                </Stack>
            }
        </>
    );
}