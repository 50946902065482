import { Box } from "@mui/material";
import MyJobInvite from "components/MyJob/MyJobInvitation";
import { Helmet } from 'react-helmet';

const MyJobInvitation = () => {
  return (
    <>
      <Box sx={{ display: "none !important", visibility: "hidden" }}>
        <Helmet>
          <title> คำเชิญจากบริษัท </title>
        </Helmet>
      </Box>


      <MyJobInvite />
    </>
  );
};

export default MyJobInvitation;
