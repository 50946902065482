import ResgisterSeeker from "view/font/SeekerRegister/RegisterSeeker";
import BoxContentProfile from "view/font/SeekerProfile/BoxContentProfile";
import Layout_Font from "layout/CommonLayout/Layout_Font";
import MyProfileEdit from "components/SeekerProfile/MyProfileEdit";
import BoxContentProfileEdit from "view/font/SeekerProfile/BoxContentProfileEdit";

const SeekerProfile =
    [{
        path: "/Guest/Register",
        component: ResgisterSeeker,
        layout: Layout_Font,
        exact: true,
        // propsData: {
        //     imgBGType: 3,
        //     isPaddingTop: true,
        //   }
    },
    {
        path: "/MyProfile",
        component: BoxContentProfile,
        layout: Layout_Font,
        exact: true,
        // propsData: {
        //     imgBGType: 3,
        //     isPaddingTop: true,
        //   }
    },
    {
        path: "/MyProfileEdit",
        component: BoxContentProfileEdit,
        layout: Layout_Font,
        exact: true,
        // propsData: {
        //     imgBGType: 3,
        //     isPaddingTop: true,
        //   }
    },
    ]

export default SeekerProfile;