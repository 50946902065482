import React, { useEffect, useState } from "react";
import {
  DataGridMui as Table,
  initRows,
  PaginationInterface,
  FilterFieldInterface,
} from "components/Common/Table/DataGridMui";
import { GridColumns } from "@mui/x-data-grid";
import { BtnEditOnTable } from "components/Common/Button";
import { useDispatch } from "react-redux";
import { FormControlLabel, Grid } from "@mui/material";
import {
  AxiosPost,
  GetPermission,
  ResultAPI,
} from "service/CommonFunction/TS_function";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { i18n } from "i18n";
import secureLocalStorage from 'react-secure-storage';


export default function AdminCondidateViewed() {
  const dispatch = useDispatch();
  const history = useNavigate();
  const i18nField = 'entities.Candidate';
  const location = useLocation();
  const searchParams = location.search;
  const sID = new URLSearchParams(searchParams).get("sID");

  const [nPermission, setPermission] = useState(0);
  const [loadingTable, setLoadingTable] = useState(true);


  let arrrow = {
    ...initRows,
    sSortExpression: "dSend_Date",
    sSortDirection: "desc"
  }
  const sPathName = window.location.pathname;
  const sStore = secureLocalStorage.getItem(sPathName + "3");
  if (sStore && sStore !== "") {
    const objRowTemp = JSON.parse(sStore + "");
    if (objRowTemp != null) {
      arrrow = objRowTemp;
      arrrow.arrRows = [];
    }
  }
  const [dataRow, setDataRow] = useState<PaginationInterface>(arrrow);
  // const [dataRow, setDataRow] = useState<PaginationInterface>({
  //   ...initRows,
  //   sSortExpression: "dSend_Date",
  //   sSortDirection: "desc",
  // });



  const onBack = () => {
    history(-1);
  };

  const form = useForm({
    shouldUnregister: false,
    shouldFocusError: true,
    mode: "all",
  });

  useEffect(() => {

    loadData(dataRow);
  }, []);

  const loadData = (p: PaginationInterface) => {
    setLoadingTable(true);
    AxiosPost(
      "AdminCandidate/SearchDataJobViewed",
      { ...p, sID: sID },
      (result) => {
        setLoadingTable(false);

        setDataRow({
          ...p,
          arrRows: result.lstJobViewed || [],
          nDataLength: result.nDataLength,
          nPageIndex: result.nPageIndex,
        });
      }
    );
  };

  // filter
  const filter: FilterFieldInterface[] = [

    { sTypeFilterMode: "input", sFieldName: "sCompany_Name" },
    { sTypeFilterMode: "input", sFieldName: "sIndustryType_Name" },
    { sTypeFilterMode: "input", sFieldName: "sCountry_Name" },
    { sTypeFilterMode: "input", sFieldName: "sRegion_Name" },
    { sTypeFilterMode: "input", sFieldName: "sProvince_Name" },
    { sTypeFilterMode: "daterange", sFieldName: "sSend_Date" },
  ];

  //สร้างตาราง
  const dataColumn: GridColumns = [

    {
      field: "sCompany_Name",
      headerName: "ชื่อผู้ประกอบการ",
      headerAlign: "center",
      align: "left",
      resizable: true,
      flex: 1,
      minWidth: 200,
    },
    {
      field: "sIndustryType_Name",
      headerName: "ประเภทอุตสาหกรรม",
      headerAlign: "center",
      align: "left",
      resizable: true,
      flex: 1,
      minWidth: 200,
    },
    {
      field: "sCountry_Name",
      headerName: "ประเทศ",
      headerAlign: "center",
      align: "left",
      resizable: true,
      minWidth: 150,
    },

    {
      field: "sRegion_Name",
      headerName: "ภูมิภาค/กรุงเทพมหานคร",
      headerAlign: "center",
      align: "left",
      resizable: true,
      minWidth: 200,
    },
    {
      field: "sProvince_Name",
      headerName: "จังหวัด/เขต",
      headerAlign: "center",
      align: "left",
      resizable: true,
      minWidth: 150,
    },
    // {
    //   field: "sView_Date",
    //   headerName: i18n(`${i18nField}.CreateDate`),
    //   headerAlign: "center",
    //   align: "center",
    //   resizable: true,
    //   minWidth: 130,
    // },
    {
      field: "sSend_Date",
      headerName: "วันที่ซื้อโปรไฟล์",
      headerAlign: "center",
      align: "center",
      resizable: true,
      //   flex: 1,
      minWidth: 150,
    },
  ];

  return (
    <Table
      id="3"
      isLoading={loadingTable}
      filterField={filter}
      Rows={dataRow}
      Columns={dataColumn}
      OnLoadData={(p) => loadData(p)}
    //isShowCheckBox={nPermission == 2}
    //onDelete={OnDelete}
    />
  );
}
