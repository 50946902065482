import {
  DataGridMui as Table,
  initRows,
  PaginationInterface,
  FilterFieldInterface,
} from "components/Common/Table/DataGridMui";
import { GridColumns } from "@mui/x-data-grid";
import { BtnAddOnTable, BtnEditOnTable, BtnViewOnTable } from "components/Common/Button";
import { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  AxiosPost, GetPermission,
} from "service/CommonFunction/TS_function";
import { DialogActionCreators } from "store/redux/DialogAlert";
import { Typography } from "@mui/material";
import { Grid } from "@mui/material";
import secureLocalStorage from 'react-secure-storage';

let sController = "AdminOrder";
let sSearch = sController + "/SearchData";
let sDetail = "AdminSpecialDetail";

export default function AdminOrder() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loadingTable, setLoadingTable] = useState(true);
  const [arrEmployer_ID, setarrEmployer_ID] = useState([]);
  const [arrType_ID, setarrType_ID] = useState([]);
  const [arrPackage_ID, setarrPackage_ID] = useState([]);
  const [nPermission, setPermission] = useState(0);
  const [arrOrder_ID, setarrOrder_ID] = useState([]);


  let arrrow = {
    ...initRows,
    sSortExpression: "sUpdateDate",
    sSortDirection: "desc",
  }
  const sPathName = window.location.pathname;
  const sStore = secureLocalStorage.getItem(sPathName);
  if (sStore && sStore !== "") {
    const objRowTemp = JSON.parse(sStore + "");
    if (objRowTemp != null) {
      arrrow = objRowTemp;
      arrrow.arrRows = [];
    }
  }
  const [dataRow, setDataRow] = useState<PaginationInterface>(arrrow);
  // const [dataRow, setDataRow] = useState<PaginationInterface>({
  //   ...initRows,
  //   sSortExpression: "sUpdateDate",
  //   sSortDirection: "desc",
  // });


  const filter: FilterFieldInterface[] = [
    { sTypeFilterMode: "input", sFieldName: "sOrder_Number" },
    {
      sTypeFilterMode: "select", sFieldName: "sSpecial_Name", lstDataSelect:
        [...arrType_ID]
    },
    { sTypeFilterMode: "input", sFieldName: "sEmployer_Name" },
    {
      sTypeFilterMode: "select", sFieldName: "sPackage_Name", lstDataSelect:
        [...arrPackage_ID]
    },
  
    { sTypeFilterMode: "input", sFieldName: "sCreator" },
    { sTypeFilterMode: "daterange", sFieldName: "sDate_Buy" },
  ];
  useEffect(() => {
    GetPermission(34, setPermission);
    loadData(dataRow);
  }, []);

  const loadData = (p: PaginationInterface) => {
    setLoadingTable(true);
    const objFilter = { ...p, nType_ID: 1 };
   
    
    AxiosPost(sSearch,
      objFilter,
      (result) => {
        setLoadingTable(false);
        setarrEmployer_ID(result.arrEmployer);
        setarrType_ID(result.arrType);
        setarrPackage_ID(result.arrPackage);
        setarrOrder_ID(result.arrOrder);
        setDataRow({
          ...p,
          arrRows: result.arrData,
          nDataLength: result.nDataLength,
          nPageIndex: result.nPageIndex,
        });
      },
      (err) => {
        if (err.response && err.response.status === 401) {
          dispatch(
            DialogActionCreators.OpenDialogWarning("Token expired.", () => {
              navigate("/");
            }) as any
          );
        }
      }
    );
  };

  const onEdit = (sID: string) => {
    // window.localStorage.removeItem("sSubRouteName");
    navigate(`/${sDetail}?sID=${sID}`);
  };

  const dataColumn: GridColumns = [
    {

      headerName: "",
      field: "ปุ่ม (เพิ่ม/แก้ไข)",
      type: "actions",
      width: 70,
      resizable: false,
      sortable: false,
      getActions: (item) => {
        return [
          (
            <BtnEditOnTable
              IsDisabled={nPermission !== 2}
              txt="แก้ไข" //Edit
              onClick={() => onEdit(item.row.sID)}
            />
          )
        ];
      },
    },
    {
      field: "sOrder_Number",
      headerName: "หมายเลขคำสั่งซื้อ",
      headerAlign: "center",
      align: "center",
      resizable: false,
      sortable: true,
      flex: 1,
      minWidth: 180,
    },
    {
      // field: "sStatus_Name",
      field: "sSpecial_Name",
      headerName: "ประเภท",
      headerAlign: "center",
      align: "center",
      resizable: false,
      sortable: true,
      flex: 1,
      minWidth: 200,
    },
    {
      field: "sEmployer_Name",
      headerName: "ชื่อผู้ประกอบการ",
      headerAlign: "center",
      align: "left",
      resizable: false,
      sortable: true,
      flex: 2,
      minWidth: 350,
    },
    {
      headerName: "แพ็กเกจ",
      field: "sPackage_Name",
      type: "actions",
      flex: 3,
      minWidth: 500,
      resizable: false,
      sortable: true,
      align: "left",
      getActions: (item) => {
        return [
          (
            <Fragment>
              {
                item.row.isCheckedConsent ?

                  <Typography style={{ color: "red" }}>[Special]</Typography>
                  : null
              }
              <Typography>{item.row.sPackage_Name}</Typography>
            </Fragment>
          )
        ];
      },
    },
    {
      field: "sCreator",
      headerName: "ผู้สร้าง",
      headerAlign: "center",
      align: "left",
      resizable: false,
      sortable: true,
      flex: 1,
      minWidth: 300,
    },
    {
      field: "sDate_Buy",
      headerName: "วันที่สั่งซื้อ",
      headerAlign: "center",
      align: "center",
      resizable: false,
      sortable: true,
      flex: 1,
      minWidth: 120,
    },
  ];

  return (
    <Grid container>
      <Grid item className="table" sx={{ mx: "2%", width: "100%" }}>
        <Table
          isLoading={loadingTable}
          filterField={filter}
          Rows={dataRow}
          Columns={dataColumn}
          OnLoadData={(p) => loadData(p)}
        />
      </Grid>
    </Grid>
  );
}
