import ShowTotalPackage from "./ShowTotalPackage";
import { Grid, MenuItem, Select, SelectChangeEvent, Stack } from "@mui/material";
import { useState } from "react";
import FromCreateJobPackage from "../Job/FormCreateJobPackage";
import FromCreateCoinPackage from "../Coin/FormCreateCoinPackage";
import FormCreateBannerPackage from "../Banner/FormCreateBannerPackage";
import { i18n } from 'i18n';

const CreatePackage = () => {
    const [sMode, setsMode] = useState('');
    const i18nField = 'entities.Package';
    const handleChange = (event: SelectChangeEvent) => {
        setsMode(event.target.value as string);
    };

    return (
        <Stack sx={{ mx: "2%" }}  >
            <Grid container spacing={3}>
                <Grid item lg={12} xs={12}>
                    <ShowTotalPackage />
                </Grid>
                <Grid item lg={12} xs={12} >
                    <Stack spacing={3} direction="column" sx={{ border: "1px #eeeeee solid", backgroundColor: "rgba(255,255,255,0.75)", borderRadius: 5, p: 2}}>
                        <Grid item  lg={12} xs={12} >
                            <h3> {"เลือกแพ็กเกจ"}</h3>
                            <Select
                                labelId="Select Package"
                                value={sMode}
                                fullWidth
                                label="Select Package"
                                onChange={handleChange}
                            >
                                <MenuItem value={"1"}>{"แพ็กเกจ JOB POST"}</MenuItem>
                                <MenuItem value={"2"}>{"แพ็กเกจ COIN"}</MenuItem>
                            </Select>
                        </Grid>
                        <Grid item >
                            {sMode === "1" ? (
                                <FromCreateJobPackage />
                            ) : sMode === "2" ? (
                                <FromCreateCoinPackage />
                            ) : null}
                        </Grid>
                    </Stack>
                </Grid>
            </Grid>
        </Stack>
    );
}
export default CreatePackage

