import {Grid, Typography } from "@mui/material"
import { i18n } from "i18n";
import "./ServicesBPS.css";
import { PiSealPercentFill } from "react-icons/pi";
import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";



const CardTitleServiceBPS = () => {
    const location = useLocation();
    const searchParams = location.search;
    const sID = new URLSearchParams(searchParams).get('sID');
    const i18nField = "OrderEmployer";
    const myRef = useRef(null)

    useEffect(() => {
        setTimeout(() => {
            document.getElementById("scroll_Top") && document.getElementById("scroll_Top").scrollIntoView();
          }, 200);
      }, [])

    return (
        <>
            <Grid container justifyContent={"center"} alignItems={"center"} style={{ width: "100%" }}>
                <div className="card shadow">
                    <Grid item xs={12}>
                        <Grid container justifyContent={"center"} alignItems={"center"} className="card-grid">
                            <Typography className='card-title-Service'>{i18n(`${i18nField}.BPSService`)}</Typography >
                        </Grid>
                        <div className="ribbonNew" >
                            <div style={{ justifyContent: "center", display: "flex" }} >
                                <PiSealPercentFill className="Icon-card" />
                            </div>
                        </div>
                    </Grid >
                </div >
            </Grid >
        </>
    )

}
export default CardTitleServiceBPS