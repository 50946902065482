import Layout_Back_BPS from "layout/CommonLayout/Layout_Back_BPS/index_BPS";
import AdminOrder from "view/back/AdminOrder/AdminOrder";
import AdminOrdeDetail from "view/back/AdminOrder/AdminOrderDetail";
import AdminSpecialDetail from "view/back/AdminSpecial/AdminSpecialDetail";
import AdminFreePackage from "view/back/AdminFreePackage/AdminFreePackage";
import AdminFreePackageDetail from "view/back/AdminFreePackage/AdminFreePackageDetail";
import AdminFreePackageApproveDetail from "view/back/AdminFreePackage/AdminFreePackageApproveDetail";
import AdminFreePackageApprove from "view/back/AdminFreePackage/AdminFreePackageApprove";

import AdminSpecial from "view/back/AdminSpecial/AdminSpecial";
const AdminWorkflowRoute = [
    {
      path: "/AdminOrder",
      component: AdminOrder,
      layout: Layout_Back_BPS,
      exact: true,
    },
    {
      path: "/AdminOrderDetail",
      component: AdminOrdeDetail,
      layout: Layout_Back_BPS,
      exact: true,
    },
    {
      path: "/AdminSpecialDetail",
      component: AdminSpecialDetail,
      layout: Layout_Back_BPS,
      exact: true,
    },
    
    {
      path: "/AdminFree",
      component: AdminFreePackage,
      layout: Layout_Back_BPS,
      exact: true,
    },
    {
      path: "/AdminFreeSpecial",
      component: AdminFreePackage,
      layout: Layout_Back_BPS,
      exact: true,
    },
      {
      path: "/AdminFreePackageDetail",
      component: AdminFreePackageDetail,
      layout: Layout_Back_BPS,
      exact: true,
    },
    {
      path: "/AdminFreePackageApproveDetail",
      component: AdminFreePackageApproveDetail,
      layout: Layout_Back_BPS,
      exact: true,
    },
    
    {
      path: "/AdminFreePackageApprove",
      component: AdminFreePackageApprove,
      layout: Layout_Back_BPS,
      exact: true,
    },
    
    {
      path: "/AdminSpecial",
      component: AdminSpecial,
      layout: Layout_Back_BPS,
      exact: true,
    },

    

]
export default AdminWorkflowRoute;
