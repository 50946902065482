import {
  DataGridMui as Table,
  initRows,
  PaginationInterface,
  FilterFieldInterface,
} from "components/Common/Table/DataGridMui";
import { GridColumns } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import {
  AxiosPost,
} from "service/CommonFunction/TS_function";
import { Grid, IconButton, Tooltip } from "@mui/material";
import AdminCompanyTabPanel from "./AdminCompanyTabPanel";
import { i18n } from "i18n";
import secureLocalStorage from 'react-secure-storage';


export default function AdminCompanyMember() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = location.search;
  const sID = new URLSearchParams(searchParams).get("sID");
  const i18nField = 'CompanyEmployee'
  const [loadingTable, setLoadingTable] = useState(true);


  let arrrow = {
    ...initRows,
    sSortExpression: "isEmployee",
    sSortDirection: "asc",
  }
  const sPathName = window.location.pathname;
  const sStore = secureLocalStorage.getItem(sPathName);
  if (sStore && sStore !== "") {
    const objRowTemp = JSON.parse(sStore + "");
    if (objRowTemp != null) {
      arrrow = objRowTemp;
      arrrow.arrRows = [];
    }
  }
  const [dataRow, setDataRow] = useState<PaginationInterface>(arrrow);
  // const [dataRow, setDataRow] = useState<PaginationInterface>({
  //   ...initRows,
  //   sSortExpression: "isEmployee",
  //   sSortDirection: "asc",
  // });



  const filter: FilterFieldInterface[] = [
    { sTypeFilterMode: "input", sFieldName: "sEmpName" },
    { sTypeFilterMode: "input", sFieldName: "sEmpEmail" },
    { sTypeFilterMode: "input", sFieldName: "sTel" },
    {
      sTypeFilterMode: "select", sFieldName: "sRole", lstDataSelect: [
        { value: "1", keyId: 0, label: i18n(`${i18nField}.admin`) }, //admin
        { value: "2", keyId: 1, label: i18n(`${i18nField}.user`) }, //user
      ],
    },
    // { sTypeFilterMode: "input", sFieldName: "sStatus" },
    {
      sTypeFilterMode: "select", sFieldName: "sStatus", lstDataSelect: [
        { value: "0", keyId: 0, label: i18n(`${i18nField}.Inactive`) },
        { value: "1", keyId: 1, label: i18n(`${i18nField}.Active`) },
        { value: "2", keyId: 2, label: i18n(`${i18nField}.SendRequest`) },
      ],
    },
    { sTypeFilterMode: "daterange", sFieldName: "sCreateDate" },
    { sTypeFilterMode: "daterange", sFieldName: "sLastedUse" }
  ];

  useEffect(() => {
    loadData(dataRow);
  }, []);

  const loadData = (p: PaginationInterface) => {
    // BlockUI();
    setLoadingTable(true);
    const objFilter = { ...p, sID: sID };
    AxiosPost("CompanyEmployee/SearchData_CompanyEmployeeFromCompany", objFilter, (result) => {
      setLoadingTable(false)
      setDataRow({
        ...p,
        arrRows: result.lstEmployee,
        nDataLength: result.nDataLength,
        nPageIndex: result.nPageIndex,
      });

      // UnBlockUI();
    })
  };

  const dataColumn: GridColumns = [
    // {
    //   headerName: "",
    //   field: "ปุ่ม (เพิ่ม/แก้ไข)",
    //   type: "actions",
    //   width: 100,
    //   resizable: false,
    //   sortable: false,
    //   getActions: (item) => {
    //     return [
    //       (
    //         <BtnEditOnTable
    //           IsDisabled={nPermission !== 2}
    //           txt={i18n(`${i18nCommon}.edit`)} //Edit
    //           onClick={() => goToEdit(item.row.sID, item.row)}
    //         />
    //       )
    //     ];
    //   },
    // },
    {
      field: "sEmpName",
      headerName: i18n(`${i18nField}.Name`),
      headerAlign: "center",
      align: "left",
      flex: 3,
      resizable: false,
      minWidth: 200
    },
    {
      field: "sEmpEmail",
      headerName: i18n(`${i18nField}.Email`),
      headerAlign: "center",
      align: "center",
      flex: 3,
      resizable: false,
      minWidth: 200,
      renderCell: (item) => {
        return [
          (
            <Tooltip placement="top" title={item.row.sEmpEmail}>
              <div id={item.row.sID}
                style={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  width: '300px',
                }}>
                {item.row.sEmpEmail}
              </div>

            </Tooltip>
          )
        ];
      }
    },
    {
      field: "sTel",
      headerName: i18n(`${i18nField}.phonenumber`),
      headerAlign: "center",
      align: "center",
      flex: 3,
      resizable: false,
      minWidth: 180,
    },
    {
      field: "sRole",
      headerName: i18n(`${i18nField}.Role`),
      headerAlign: "center",
      align: "center",
      flex: 3,
      resizable: false,
      minWidth: 180,
    },
    {
      field: "sStatus",
      headerName: i18n(`${i18nField}.Status`),
      headerAlign: "center",
      align: "center",
      flex: 3,
      resizable: false,
      minWidth: 180,
    },
    {
      field: "sCreateDate",
      headerName: i18n(`${i18nField}.InviteDate`),
      headerAlign: "center",
      align: "center",
      flex: 3,
      resizable: false,
      minWidth: 180,
    },
    {
      field: "sLastedUse",
      headerName: i18n(`${i18nField}.LastUse`),
      headerAlign: "center",
      align: "center",
      flex: 3,
      resizable: false,
      minWidth: 180,
      renderCell: (item) => {
        let objEmp = dataRow.arrRows.filter(f => f.sStatus == item.row.sStatus)[0];
        return (
          <>
            {objEmp.nStatus == 2 ? //status 2 = ส่งคำเชิญ
              null : objEmp.sLastedUse}
          </>
        );
      }
    }
  ];

  return (
    <Grid container>
      <Grid item xs={12} sx={{ mx: "2%" }}>
        <AdminCompanyTabPanel tabNo={5} />
        <Grid
          sx={{
            marginTop: "2%",
          }}
        ></Grid>
        <Table
          isLoading={loadingTable}
          filterField={filter}
          Rows={dataRow}
          Columns={dataColumn}
          OnLoadData={(p) => loadData(p)}
        />
      </Grid>
    </Grid>
  );
}