import React, { useEffect, useState } from 'react'
import { RemoveCircleOutline, AddCircleOutline } from "@mui/icons-material"
import { AxiosPost } from 'service/CommonFunction/TS_function'
import { Button, CircularProgress, Stack, Typography } from '@mui/material'
import { i18n } from 'i18n'
import moment from 'moment'
import Link from "@mui/material/Link";

import { FaFileWord, FaFilePowerpoint, FaFilePdf, FaFileImage } from "react-icons/fa6";

import secureLocalStorage from 'react-secure-storage'
export const th = {
  Experience: "ประสบการณ์",
  Education: "การศึกษา",
  Certificate: "ใบอนุญาต หรือประกาศนียบัตร",
  Skills: "ทักษะความสามารถ",
  Languages: "ความสามารถทางภาษา",
  Personal_details: "ประวัติส่วนตัว",
  Additinal_document: "เอกสาร Resume และเอกสารแนบอื่นๆ",
  Major: "สาขา",
  Industry: "ประเภทอุตสาหกรรม",
  JobFunction: "ประเภทงาน",
  JobDetail: "ชื่องาน",
  Age: "อายุ",
  Sex: "เพศ",
  Status: "สถานะการสมรส",
  Speak: "พูด",
  Read: "อ่าน",
  Write: "เขียน",
  Present: "ปัจจุบัน",
  NotSpecific: "ไม่ระบุ",
  Single: "โสด",
  Married: "สมรส",
  AgeYear: "ปี",
  Tag: "คีย์เวิร์ด",
  Faculty: "คณะ",
  DetailRemark: "รายละเอียด"
}

export const en = {
  Experience: "Experience",
  Education: "Education",
  Certificate: "Certificate",
  Skills: "Skills",
  Languages: "Languages",
  Personal_details: "Personal details",
  Additinal_document: "Resume and Other Documents",
  Major: "Major",
  Industry: "Industry",
  JobFunction: "Job Function",
  JobDetail: "Job Detail",
  Age: "Age",
  Sex: "Sex",
  Status: "Status",
  Speak: "Speak",
  Read: "Read",
  Write: "Write",
  Present: "Present",
  NotSpecific: "Not Specific",
  Single: "Single",
  Married: "Married",
  AgeYear: "Year",
  Tag: "Keyword",
  Faculty: "Faculty",
  DetailRemark: "Role and Responsibility"
}

export default function InfomationJobSeeker({ isOpenInformation, sId, handleSelectCard, isBuyProfile = false }) {

  const sPathName = window.location.pathname;
  const isEmployerSearch = sPathName.includes("/EmployerSearch");

  const ControllerApi = "EmployerSearch"
  const i18nField = "EmployerSearch.Information."
  enum DataType {
    ALL = 0,
    Experience = 1,
    Education = 2,
    Certificate = 3,
    Skills = 4,
    Languages = 5,
    Personal_details = 6,
    Additinal_document = 7,
    Tag = 8,
  }
  interface DataState {
    data?: any,
    isLoaded: boolean,
    isOpen: boolean,
    dataTypeId: number,
    isLoading: boolean,
    dataTypeName: string

  }

  const [isLoadAll, setLoadAll] = useState(false);

  const [arrStateData, setArrStateData] = useState<DataState[]>([
    {
      data: null, dataTypeName: i18n(`${i18nField}Experience`),
      dataTypeId: DataType.Experience,
      isLoaded: false,
      isOpen: false, isLoading: false
    },
    {
      data: null, dataTypeName: i18n(`${i18nField}Education`),
      dataTypeId: DataType.Education,
      isLoaded: false,
      isOpen: false, isLoading: false
    },
    {
      data: null, dataTypeName: i18n(`${i18nField}Certificate`),
      dataTypeId: DataType.Certificate,
      isLoaded: false,
      isOpen: false, isLoading: false
    },
    {
      data: null, dataTypeName: i18n(`${i18nField}Skills`),
      dataTypeId: DataType.Skills,
      isLoaded: false,
      isOpen: false, isLoading: false
    },
    {
      data: null, dataTypeName: i18n(`${i18nField}Languages`),
      dataTypeId: DataType.Languages,
      isLoaded: false,
      isOpen: false, isLoading: false
    },
    {
      data: null, dataTypeName: i18n(`${i18nField}Personal_details`),
      dataTypeId: DataType.Personal_details,
      isLoaded: false,
      isOpen: false, isLoading: false
    },
    {
      data: null, dataTypeName: i18n(`${i18nField}Additinal_document`),
      dataTypeId: DataType.Additinal_document,
      isLoaded: false,
      isOpen: false, isLoading: false
    },
    {
      data: null, dataTypeName: i18n(`${i18nField}Tag`),
      dataTypeId: DataType.Tag,
      isLoaded: false,
      isOpen: false, isLoading: false
    },
  ])

  const getDataWithLangCode = (objField) => {
    const getLangCode = secureLocalStorage.getItem("language").toString();
    if (!objField) return "";
    if (getLangCode.toLocaleLowerCase() == "th") {
      return objField["sTh"];
    } else {
      return objField["sEn"];
    }
  }

  const _onClickShowData = (objStateData) => {
    handleSelectCard()
    if (objStateData.isOpen) {
      objStateData.isOpen = false
      setArrStateData([...arrStateData])
    } else {
      objStateData.isOpen = true
      setArrStateData([...arrStateData])
      if (!objStateData.isLoaded) {
        objStateData.isLoading = true
        AxiosPost(`${ControllerApi}/LoadInformation`, { sId: sId, nDataType: objStateData.dataTypeId }, (res) => {
          switch (objStateData.dataTypeId) {
            case DataType.Experience: {
              objStateData.data = res.Data.lstExperienceData
              break;
            }
            case DataType.Education: {
              objStateData.data = res.Data.lstEducationData
              break;
            }
            case DataType.Certificate: {
              objStateData.data = res.Data.lstCertificate
              break;
            }
            case DataType.Skills: {
              objStateData.data = res.Data.lstSkill
              break;
            }
            case DataType.Languages: {
              objStateData.data = res.Data.objLang
              break;
            }
            case DataType.Personal_details: {
              objStateData.data = res.Data.objPersonalData
              break;
            }
            case DataType.Additinal_document: {
              objStateData.data = res.Data.lstFile
              break;
            }
            case DataType.Tag: {
              objStateData.data = res.Data.lstTag
              break;
            }
            default:
              break;
          }
          objStateData.isLoaded = true;
        }, err => console.log(err), () => {
          objStateData.isLoading = false;
          setArrStateData([...arrStateData])
        })
      }
    }
  }

  const _fetchAll = () => {
    arrStateData.forEach(f => f.isOpen = true);
    setArrStateData([...arrStateData])
    // if (!objStateData.isLoaded) {
    arrStateData.forEach(f => f.isLoading = true);
    AxiosPost(`${ControllerApi}/LoadInformation`, { sId: sId, nDataType: DataType.ALL }, (res) => {
      arrStateData[DataType.Experience - 1].data = res.Data.lstExperienceData
      arrStateData[DataType.Education - 1].data = res.Data.lstEducationData
      arrStateData[DataType.Certificate - 1].data = res.Data.lstCertificate
      arrStateData[DataType.Skills - 1].data = res.Data.lstSkill
      arrStateData[DataType.Languages - 1].data = res.Data.objLang
      arrStateData[DataType.Personal_details - 1].data = res.Data.objPersonalData
      arrStateData[DataType.Additinal_document - 1].data = res.Data.lstFile
      arrStateData[DataType.Tag - 1].data = res.Data.lstTag
      arrStateData.forEach(f => f.isLoaded = true);
      setLoadAll(true)

    }, err => console.log(err), () => {
      arrStateData.forEach(f => f.isLoading = false);
      setArrStateData([...arrStateData])
    })
    // }
  }
  const handleCalAge = (sBirthDate) => {
    const age = moment().diff(sBirthDate, 'years', false);
    return age + " " + i18n(`${i18nField}AgeYear`);
  }

  useEffect(() => {
    if (!isLoadAll && isOpenInformation) {
      _fetchAll()
    }
  }, [isOpenInformation])

  const mapSex = (nSex) => {
    const getLangCode = secureLocalStorage.getItem("language").toString();
    switch (nSex) {
      case 0:
        return getLangCode.toLocaleLowerCase() == "th" ? "ชาย" : "Male"
      case 1:
        return getLangCode.toLocaleLowerCase() == "th" ? "หญิง" : "Female"
      case 2:
        return getLangCode.toLocaleLowerCase() == "th" ? "ไม่ระบุ" : "Not Specific"
      default:
        return "-"
    }
  }


  const RenderItem = ({ itemDataType }) => {
    ////console.log("itemDataType", itemDataType);

    return (<div className='flex flex-col'>
      <div onClick={() => _onClickShowData(itemDataType)} className={`flex  items-center  gap-[5px] text-[#1976d2] sm:px-[1em] flex-row ${itemDataType.isOpen && "mb-[10px]"}`}>
        {itemDataType.isOpen ? <RemoveCircleOutline className='cursor-pointer' /> : <AddCircleOutline className='cursor-pointer' />}<label className='cursor-pointer text-[1.1em]  font-[500]'>{itemDataType.dataTypeName}</label>
      </div>
      <div className={`flex text-[black] gap-[5px] flex-col ease-linear   duration-[200ms] ${itemDataType.isOpen && "sx:px-[1em] border-b border-b-[rgba(25,118,210,0.5)] md:mx-[2em] mb-[10px] pb-[5px]  "}`}>
        {/* <div className={`flex text-[black] gap-[5px] flex-col ease-linear   duration-[200ms] overflow-hidden max-h-[0px]  ${itemDataType.isOpen && "!max-h-[1000px]  sx:px-[1em] border-b border-b-[rgba(25,118,210,0.5)] md:mx-[2em] mb-[10px] pb-[5px]  "}`}> */}
        {itemDataType.isLoading ?
          (<CircularProgress />)
          :
          (
            <>
              {itemDataType.isOpen ?
                <>
                  {
                    itemDataType.dataTypeId == DataType.Experience && (itemDataType.data && itemDataType.data.map((itemData, indexData) => {
                      return (
                        <div key={`item_data_type_info_${indexData}_${itemDataType.dataTypeId}`} className='flex gap-[5px] md:gap-[1em] pl-[10px] md:pl-[1.7em] flex-col md:flex-row '>
                          <div className='flex flex-col w-[100%] md:w-[230px] lg:max-h-[500px] md:max-h-[500px]'>
                            <label className='text-[1em] font-[500]'>{`${moment(itemData.dStartDate).format('MMMM')} ${moment(itemData.dStartDate).format('YYYY')} - 
                                                            ${itemData.isCurrent ? i18n(`${i18nField}Present`) : `${moment(itemData.dEndDate).format('MMMM')} ${moment(itemData.dEndDate).format('YYYY')}`} `}
                            </label>
                          </div>
                          <div className='flex flex-col  '>
                            <label className='text-[1em] font-[500]' >{itemData.sPositionName}</label>
                            <label className='text-[0.8em]'>{itemData.sCompanyName}</label>
                            <div className='flex flex-col  md:flex-row'>
                              <label className='md:w-[120px] text-[0.8em]'>{i18n(`${i18nField}Industry`)}</label>
                              <label className='flex flex-1 pl-[10px] md:pl-[0px] text-[0.8em]'> {getDataWithLangCode(itemData.objIndustry)}</label>
                            </div>
                            <div className='h-[10px] w-full' />
                            <div className='flex  flex-col  md:flex-row'>
                              <label className='md:w-[120px] text-[0.8em]'>{i18n(`${i18nField}JobFunction`)}</label>
                              <label className='flex flex-1  pl-[10px] md:pl-[0px]  text-[0.8em]'>{getDataWithLangCode(itemData.objJobFunction)}</label>
                            </div>
                            <div className='flex  flex-col  md:flex-row'>
                              <label className='md:w-[120px] text-[0.8em]'>{i18n(`${i18nField}JobDetail`)}</label>
                              <label className='flex flex-1  pl-[10px] md:pl-[0px]  text-[0.8em]'>{getDataWithLangCode(itemData.objJobFunctionDetail)}</label>
                            </div>

                            <div className='flex  flex-col  md:flex-row'>
                              <label className='md:w-[120px] text-[0.8em]'>{i18n(`${i18nField}DetailRemark`)}</label>
                              <label className='flex flex-1 md:max-w-[20vw] lg:max-w-[30vw]  pl-[10px] md:pl-[0px]  text-[0.8em] whitespace-pre-wrap break-all'>{itemData.sRemark}</label>
                            </div>

                          </div>
                        </div>
                      )
                    }))
                  }
                </>
                : null
              }
              {itemDataType.isOpen ?
                <>
                  {itemDataType.dataTypeId == DataType.Education && (itemDataType.data && itemDataType.data.map((itemData, indexData) => {
                    return (<div key={`item_data_type_info_${indexData}_${itemDataType.dataTypeId}`} className='flex gap-[5px] md:gap-[1em] pl-[10px] md:pl-[1.7em] flex-col md:flex-row '>
                      <div className='flex flex-col w-[100%] md:w-[230px]'>
                        <label className='text-[1em] font-[500]'>{itemData.nEducationalYear}</label>
                      </div>
                      <div className='flex flex-col'>
                        <label className='text-[1em] font-[500]' >{getDataWithLangCode(itemData.sEducationalName)}</label>
                        <label className='text-[0.8em] '>{getDataWithLangCode(itemData.objEducationalLevel)}</label>

                        <div className='flex flex-col  md:flex-row'>
                          <label className='md:w-[120px] text-[0.8em]'>{i18n(`${i18nField}Faculty`)}</label>
                          <label className='text-[0.8em] pl-[10px] md:pl-[0px] flex-1  flex'>
                            {itemData.sEducationalFaculty === "" ? "-" : itemData.sEducationalFaculty}
                          </label>
                        </div>
                        <div className='flex flex-col  md:flex-row'>
                          <label className='md:w-[120px] text-[0.8em]'>{i18n(`${i18nField}Major`)}</label>
                          <label className='text-[0.8em] pl-[10px] md:pl-[0px] flex-1  flex'>{itemData.sEducationalBranch}</label>
                        </div>
                      </div>
                    </div>)
                  }))}
                </> : null
              }
              {itemDataType.isOpen ?
                <>
                  {itemDataType.dataTypeId == DataType.Certificate && (itemDataType.data && itemDataType.data.map((itemData, indexData) => {
                    return (<div key={`item_data_type_info_${indexData}_${itemDataType.dataTypeId}`} className='flex gap-[5px] md:gap-[1em] pl-[10px] md:pl-[1.7em] flex-col md:flex-row '>
                      <div className='flex flex-col w-[100%] md:w-[230px]'>
                        <label className='text-[1em] font-[500]'>{`${itemData.dStartDate != null ? moment(itemData.dStartDate).format('YYYY') : ""}`}</label>
                      </div>
                      <div className='flex flex-col'>
                        <label className='text-[1em] font-[500]' >{itemData.sCertificateName}</label>
                        <label className='text-[0.8em]'>{itemData.sInstitutionName}</label>
                      </div>
                    </div>)
                  }))}
                </> : null
              }
              {itemDataType.isOpen ?
                <>
                  {itemDataType.dataTypeId == DataType.Skills && (itemDataType.data && (<div className='flex flex-row  flex-wrap	 gap-[10px] pl-[10px] md:pl-[1.7em]'>
                    {itemDataType.data.map((itemData, indexData) => {
                      return (
                        <div key={`item_data_type_info_${indexData}_${itemDataType.dataTypeId}`} className='border  border-[#1976d2] rounded-[1em] min-w-[55px] px-[5px] py-[1] border-w-[2px] flex justify-center items-center '>
                          <label className='text-[0.65em] font-[500]' >{itemData}</label>
                        </div>
                      )
                    })}
                  </div>))}
                </> : null
              }
              {itemDataType.isOpen ?
                <>
                  {itemDataType.dataTypeId == DataType.Languages && (itemDataType.data && itemDataType.data.map((itemData, indexData) => {
                    return (<div key={`item_data_type_info_${indexData}_${itemDataType.dataTypeId}`} className='flex gap-[5px] md:gap-[1em] pl-[10px] md:pl-[1.7em] flex-col md:flex-row '>
                      <div className='flex flex-col w-[100%] md:w-[230px]'>
                        <label className='text-[1em] font-[500]'>{getDataWithLangCode(itemData.objLangeuage)}</label>
                      </div>
                      <div className='flex flex-col'>
                        <div className='flex flex-row gap-[5px] md:gap-[0px]'>
                          <label className=' md:w-[120px] text-[0.8em] font-[500]'>{i18n(`${i18nField}Speak`)}</label>
                          <label className='flex flex-1 text-[0.8em]'>{getDataWithLangCode(itemData.objLangeuageSpeek)}</label>
                        </div>
                        <div className='flex flex-row gap-[5px] md:gap-[0px]'>
                          <label className=' md:w-[120px] text-[0.8em] font-[500]'>{i18n(`${i18nField}Read`)}</label>
                          <label className='flex flex-1 text-[0.8em]'>{getDataWithLangCode(itemData.objLangeuageRead)}</label>
                        </div>
                        <div className='flex flex-row gap-[5px] md:gap-[0px]'>
                          <label className=' md:w-[120px] text-[0.8em] font-[500]'>{i18n(`${i18nField}Write`)}</label>
                          <label className='flex flex-1 text-[0.8em]'>{getDataWithLangCode(itemData.objLangeuageWrite)}</label>
                        </div>
                      </div>
                    </div>)
                  }))}
                </> : null
              }
              {itemDataType.isOpen ?
                <>
                  {itemDataType.dataTypeId == DataType.Personal_details && itemDataType.data && (
                    <>
                      <div className='flex gap-[5px] md:gap-[1em] pl-[10px] md:pl-[1.7em] flex-row '>
                        <div className='flex flex-col md:w-[230px]'>
                          <label className='text-[0.8em] font-[500]'>{i18n(`${i18nField}Age`)}</label>
                        </div>
                        <div className='flex flex-col '>
                          <label className='text-[0.8em] '>{itemDataType.data.dBirthDate != null ? handleCalAge(itemDataType.data.dBirthDate) : "-"}</label>
                        </div>
                      </div>
                      <div className='flex gap-[5px] md:gap-[1em] pl-[10px] md:pl-[1.7em] flex-row '>
                        <div className='flex flex-col md:w-[230px]'>
                          <label className='text-[0.8em] font-[500]'>{i18n(`${i18nField}Sex`)}</label>
                        </div>
                        <div className='flex flex-col '>
                          <label className='text-[0.8em] '>{mapSex(itemDataType.data.nSex)}</label>
                        </div>
                      </div>
                      <div className='flex gap-[5px] md:gap-[1em] pl-[10px] md:pl-[1.7em] flex-row '>
                        <div className='flex flex-col md:w-[230px]'>
                          <label className='text-[0.8em] font-[500]'>{i18n(`${i18nField}Status`)}</label>
                        </div>
                        <div className='flex flex-col '>
                          <label className='text-[0.8em] '>{itemDataType.data.isMarried ? i18n(`${i18nField}Married`) : i18n(`${i18nField}Single`)}</label>
                        </div>
                      </div>
                    </>
                  )}
                </> : null
              }



              {/* เอกสารแนบ */}
              {itemDataType.isOpen && itemDataType.data && isBuyProfile && itemDataType.dataTypeId === DataType.Additinal_document ?
                <>
                  <div className='flex flex-row  flex-wrap	 gap-[10px] pl-[10px] md:pl-[1.7em]'>
                    {itemDataType.data.map((item, index) => {

                      ////target = { itemData.sCheckFile == (".png" || ".gif" || ".jpeg" || ".jpg" || ".pdf") ? "_blank" : "" }
                      let ValueTarget = "";
                      const FileExtension = [".png", ".gif", ".jpeg", ".jpg", ".pdf"];
                      if (FileExtension.includes(item.sCheckFile)) {
                        ValueTarget = "_blank";
                      }
                      let ResumeFile = <></>
                      if (!isEmployerSearch) {

                        if (item.nFileTypeID === 2) {
                          let icon = <FaFileImage style={{ fontSize: "20px" }} />

                          if (item.sCheckFile === ".pdf") {
                            icon = <FaFilePdf style={{ fontSize: "20px" }} />
                          }
                          else if (item.sCheckFile === ".docx" || item.sCheckFile === ".doc") {
                            icon = <FaFileWord style={{ fontSize: "20px" }} />
                          }
                          else if (item.sCheckFile === ".pptx" || item.sCheckFile === ".ppt") {
                            icon = <FaFilePowerpoint style={{ fontSize: "20px" }} />
                          }

                          ResumeFile =
                            <Link href={item.sFilePath} target={ValueTarget} key={index}
                              className={`border gap-[5px] ease-linear duration-[200ms] 
                        ${FileExtension.includes(item.sCheckFile) ?
                                  `bg-[#ff7028] border-[#ff7028] hover:bg-[#d64700]`
                                  : `bg-[#515edb] border-[#515edb] hover:bg-[#2330a9]`} 
                        !text-[white] border-[#1976d2] rounded-[1em] min-w-[120px] h-[35px] px-[5px] py-[1] border-w-[2px] flex justify-center items-center `}
                            >
                              {icon}
                              <label className='text-[0.65em] cursor-[pointer] font-[500]'>
                                {item.sFileName}
                              </label>
                            </Link>
                        }
                      }
                      return (ResumeFile)
                    })
                    }
                  </div>

                  <div className='flex flex-row  flex-wrap	 gap-[10px] pl-[10px] md:pl-[1.7em]'>
                    {itemDataType.data.map((item, index) => {
                      ////target = { itemData.sCheckFile == (".png" || ".gif" || ".jpeg" || ".jpg" || ".pdf") ? "_blank" : "" }
                      let ValueTarget = "";
                      const FileExtension = [".png", ".gif", ".jpeg", ".jpg", ".pdf"];
                      if (FileExtension.includes(item.sCheckFile)) {
                        ValueTarget = "_blank";
                      }

                      let OtherFile = <></>
                      if (!isEmployerSearch) {
                        if (item.nFileTypeID === 3) {
                          let icon = <FaFileImage style={{ fontSize: "20px" }} />

                          if (item.sCheckFile === ".pdf") {
                            icon = <FaFilePdf style={{ fontSize: "20px" }} />
                          }
                          else if (item.sCheckFile === ".docx" || item.sCheckFile === ".doc") {
                            icon = <FaFileWord style={{ fontSize: "20px" }} />
                          }
                          else if (item.sCheckFile === ".pptx" || item.sCheckFile === ".ppt") {
                            icon = <FaFilePowerpoint style={{ fontSize: "20px" }} />
                          }

                          OtherFile = <Link href={item.sFilePath} target={ValueTarget} key={index}
                            className={`border gap-[5px] ease-linear duration-[200ms] 
                        ${FileExtension.includes(item.sCheckFile) ?
                                `bg-[#ff7028] border-[#ff7028] hover:bg-[#d64700]`
                                : `bg-[#515edb] border-[#515edb] hover:bg-[#2330a9]`} 
                        !text-[white] border-[#1976d2] rounded-[1em] min-w-[120px] h-[35px] px-[5px] py-[1] border-w-[2px] flex justify-center items-center `}
                          >
                            {icon}
                            <label className='text-[0.65em] cursor-[pointer] font-[500]'>
                              {item.sFileName}
                            </label>
                          </Link>
                        }
                      }
                      return (OtherFile)
                    })
                    }
                  </div>
                </>
                :
                <></>
              }




              {itemDataType.isOpen ?
                <>
                  {itemDataType.dataTypeId == DataType.Tag && (itemDataType.data && (<div className='flex flex-row  flex-wrap	 gap-[10px] pl-[10px] md:pl-[1.7em]'>
                    {itemDataType.data && itemDataType.data.map((itemData, indexData) => {
                      return (
                        <div key={`item_data_type_info_${indexData}_${itemDataType.dataTypeId}`} className='border  border-[#1976d2] rounded-[1em] min-w-[55px] px-[5px] py-[1] border-w-[2px] flex justify-center items-center '>
                          <label className='text-[0.65em]  font-[500]' >{itemData}</label>
                        </div>
                      )
                    })}
                  </div>))}
                </> : null
              }

            </>
          )}
      </div>
    </div>)
  }

  return (
    <div className={`max-h-[0] overflow-hidden  ease-linear duration-[200ms] p-[0] border-[0]  rounded-[0] ${isOpenInformation && "max-h-[100vh] !overflow-auto !p-[5px] md:!p-[1em] !border !rounded !border-[#f1f1f1]"}`}>
      {arrStateData.map((item, index) => <RenderItem itemDataType={item} key={`item_render_${index}`} />)}
    </div>
  )
}