
import React, { useState, useEffect } from 'react'
import {
    FormControl, InputLabel, Select,
    OutlinedInput, MenuItem, Checkbox,
    ListItemText, Box, Chip, FormHelperText
} from "@mui/material"
import PropTypes, { InferProps } from "prop-types";
import { useFormContext } from "react-hook-form"
import { i18n } from 'i18n';
import secureLocalStorage from 'react-secure-storage';
import { red } from '@mui/material/colors';

export default function MultiSelectWithChip(
    {
        valueData,
        name,
        options,
        required,
        disabled,
        label,
        fnOnChange,
        small, maxRow,
        setWidth,
        setHeight,
        setBorderRadius,
        notSetMargin,
        fullWidth,
        maxChip,
        onDeleteChip,
        isOptionTwoLanguage,
      
    }
) {
    const {
        register,
        setValue,
        getValues,
        watch,
        formState: { errors },
    } = useFormContext();

    const [open, setOpen] = useState(false)
    const [isFirstTime, setIsFirstTime] = useState(true)

    const langCode = secureLocalStorage.getItem("language") || "th";
    useEffect(() => {
        setIsFirstTime(false)
    }, [])

    return (
        <FormControl
            size={small ? "small" : "medium"}
            fullWidth={fullWidth}
            
            // required={required}
            sx={{
                width: setWidth ? setWidth : "100%",
                top: small ? "-8px" : "0",
                " > label": {
                    "::after": {
                        content: required ? '" * "' : '""',
                        color: "#ff1c24",
                        fontWeight: 500,
                    },
                },
                color: errors && errors[name] ? "red !important" : ""
            }}
        // error={errors && errors[name]}
        // sx={{
        //     width: setWidth ? setWidth : "100%",
        // }}
        >
            <InputLabel
                // required={required}
                sx={{
                    color: errors && errors[name] ? "red!important" : "",
                }}
            >
                {label}
            </InputLabel>
            <Select
                fullWidth={fullWidth}
                open={open}
                disabled={disabled}
                inputRef={register(name).ref}
                {...register(name)}
                multiple
                name={name}
                value={valueData}
                size={small ? "small" : "medium"}
                style={setBorderRadius && { borderRadius: setBorderRadius }}
                onClick={(e) => {
                    if (!open && !disabled) {
                        setOpen(true);
                    }
                }}
                onChange={(e) => {
                    setValue(name, e.target.value as any[])
                    fnOnChange && fnOnChange(e.target.value)
                }}
                required={required}
                input={<OutlinedInput label={label} sx={setHeight && { height: setHeight }} />}
                maxRows={maxRow}
                renderValue={(selected) => (
                    <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: 0.5, maxWidth: "300px" }}>

                        {selected.length <= maxChip ? selected.map((values, i) => (
                            <Chip size={small ? "small" : "medium"} key={`${values}_${i}`}
                                onDelete={(e) => {
                                    setValue(name, valueData.filter(f => f != values));
                                    onDeleteChip && onDeleteChip(values)
                                }}
                                variant="outlined"
                                clickable
                                sx={{
                                    paddingRight: "5px",
                                    position: "relative",
                                }}
                                label={options?.length > 0 ? isOptionTwoLanguage ? options.find(f => f.value == values)[`label_${langCode}`] : options.find(f => f.value == values).label : ""} />
                        )) : <Chip key={"select"}
                            onDelete={(e) => {
                                setValue(name, []);
                            }}
                            variant="outlined"
                            clickable
                            size={small ? "small" : "medium"}
                            sx={{
                                paddingRight: "5px",
                                position: "relative",
                            }}
                            label={`${selected.length} ${i18n('common.selected')}`} />}
                    </Box>
                )}
                MenuProps={
                    {
                        MenuListProps: { onMouseLeave: () => setOpen(false) },
                        onBackdropClick: (e) => setOpen(false),
                    }
                }
            >
                {options.length > 0 && (options || []).map((item, i) => (
                    <MenuItem
                        key={item.keyId || `${item.value}_${i}`} value={item.value}>
                        <Checkbox checked={(valueData || []).length > 0 && valueData.some(s => s == item.value)} />
                        <ListItemText primary={isOptionTwoLanguage ? item[`label_${langCode}`] : item.label} />
                    </MenuItem>
                )
                )}
            </Select>
            {
                errors && errors[name] ?
                    <FormHelperText sx={{ color: "red!important" }} >{errors[name].message}</FormHelperText>
                    :
                    null
            }
        </FormControl >
    )
}


MultiSelectWithChip.propTypes = {
    options: PropTypes.array,
    required: PropTypes.bool,
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    valueData: PropTypes.array.isRequired,
    fnOnChange: PropTypes.func,
    small: PropTypes.bool,
    maxRow: PropTypes.number,
    setWidth: PropTypes.string,
    setHeight: PropTypes.string,
    setBorderRadius: PropTypes.string,
    notSetMargin: PropTypes.bool,
    fullWidth: PropTypes.bool,
    maxChip: PropTypes.number,
    onDeleteChip: PropTypes.func,
    isOptionTwoLanguage: PropTypes.bool,
    disabled:PropTypes.bool
    
};

MultiSelectWithChip.defaultProps = {
    options: [],
    small: true,
    maxRow: 1,
    maxChip: 2,
    isOptionTwoLanguage: false,
    disabled:false
};