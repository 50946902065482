import { useEffect, useContext, useState, useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form'
import { Grid, Box, IconButton, Paper, Typography, Stack } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { i18n } from 'i18n';
import { ProfileContext } from 'components/SeekerProfile/Context/ProfileContext';
import AutoCompleteSelect from 'components/Common/ElementInForm/AutoCompleteSelect';
import { ILanguageSkill, IPropsLanguageSkill } from './Interface';
import { DialogActionCreators } from "store/redux/DialogAlert";
import { useDispatch } from "react-redux";
import { AccordionCustom, AccordionDetailsCustom, AccordionSummaryCustom } from './ElementCustom';
import { useNavigate } from 'react-router-dom';
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { BtnBack, BtnEditProfile, BtnRadiusProfile, BtnSubmit } from 'components/Common/Button';
import { AxiosGet, AxiosPost } from 'service/CommonFunction/TS_function';
import { FnBlock_UI } from 'service/SystemFunction/SystemFunction';
import yupFormSchemas from 'components/Common/yup/yupFormSchemas';
import CardComplete from './CardComplete';
import CardInComplete from './CardInComplete';
import secureLocalStorage from 'react-secure-storage';

const i18nField = 'entities.SeekerProfile';
const i18nCommon = 'common';
const LanguageSkillNew = ({ Edit, isExpanded, isJobApply, sIDNew ,onBackEdit}) => {
    const history = useNavigate();
    const { BlockUI, UnBlockUI } = FnBlock_UI();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [expandedLangSkill, setExpandedLangSkill] = useState(false);
    const [arrlanguageSkill, setArrlanguageSkill] = useState([]);
    const {
        languageSkillContext,
        setLanguageSkill,
        optionLangSkill,
        optionLanguage,
        profileContext,
        isLoading,
        isLanguageSkillEdit,
        setIsLanguageSkillEdit,
    } = useContext(ProfileContext);

    // Set up Schema Skill
    const [schemaLanguageSkill, setSchemaLanguageSkill] = useState({});
    const loopSetSchemaLanguageSkill = () => {
        const schemaTemp = {};
        (languageSkillContext || []).forEach((item) => {
            schemaTemp[`Language${item.Index}`] = yupFormSchemas.object(
                i18n(`${i18nField}.Language`),
                { required: true }
            );
            schemaTemp[`ConvsSkill${item.Index}`] = yupFormSchemas.object(
                i18n(`${i18nField}.ConvsSkill`),
                { required: true }
            );
            schemaTemp[`ReadSkill${item.Index}`] = yupFormSchemas.object(
                i18n(`${i18nField}.ReadSkill`),
                { required: true }
            );
            schemaTemp[`WriteSkill${item.Index}`] = yupFormSchemas.object(
                i18n(`${i18nField}.WriteSkill`),
                { required: true }
            );
        });
        setSchemaLanguageSkill(schemaTemp);
    }

    const [formSchema, setFormSchema] = useState<any>({});
    const schema = yup.object().shape(formSchema);
    const form = useForm({
        resolver: yupResolver(schema),
        shouldUnregister: false,
        shouldFocusError: true,
        mode: "all",
    });



    const [isComPlete, setisComPlete] = useState(false);

    const GetMyProfileStatus = () => {
        let param ={
            nPanel:10
           }
           AxiosPost("JobSeeker/GetMyProfileStatus", param, (res) => {
          setisComPlete(res.IsCompleted);
    
        });
      };
    
      useEffect(() => {
        GetMyProfileStatus();
    }, [])
    
    useEffect(() => {
        loopSetSchemaLanguageSkill();
    }, [languageSkillContext]);

    // -> Language Skill
    const arrSchemaNameLanguageSkill = [
        "ConvsSkill",
        "ReadSkill",
        "Language",
        "WriteSkill",
    ];

    useEffect(() => {
        for (const [key, value] of Object.entries(formSchema)) {
            if (arrSchemaNameLanguageSkill.some((a) => key.includes(a))) {
                delete formSchema[key];
            }
        }
        setFormSchema({ ...formSchema, ...schemaLanguageSkill });
    }, [schemaLanguageSkill]);


    const handleChangeValue = (form, index, value) => {
        let newData = languageSkillContext.map((item: ILanguageSkill) => {
            if (item.Index === index) {
                item.Language = form.getValues("Language" + index);
                item.ConvsSkill = form.getValues("ConvsSkill" + index);
                item.ReadSkill = form.getValues("ReadSkill" + index);
                item.WriteSkill = form.getValues("WriteSkill" + index);
                item.sOther = value ? form.getValues("Language" + index)["label"] : "";
            }
            return item;
        });

        setLanguageSkill(newData);
    }

    const handleRemoveLanguageSkill = (itemIndex: number) => {
        dispatch(DialogActionCreators.OpenDialogSubmit(i18n(`${i18nCommon}.AlertConfrimDel`), () => {
            dispatch(DialogActionCreators.CloseDialogSubmit() as any);
            dispatch(DialogActionCreators.LoadSubmit("Waiting...") as any);
            let newData = languageSkillContext.filter((data) => { return data.Index !== itemIndex });
            setLanguageSkill(newData);
            form.unregister("Language" + itemIndex);
            form.unregister("ConvsSkill" + itemIndex);
            form.unregister("ReadSkill" + itemIndex);
            form.unregister("WriteSkill" + itemIndex);
        }));

    }

    const handleAddLanguageSkill = () => {
        let nGenIndex = (Math.max.apply(Math, languageSkillContext.map((item: ILanguageSkill, index) => { return item.Index; })) || 0) + 1;
        let newData = [
            ...languageSkillContext,
            {
                Index: nGenIndex,
                Language: null,
                ConvsSkill: null,
                ReadSkill: null,
                WriteSkill: null,
            },
        ] as Array<ILanguageSkill>;
        setLanguageSkill(newData);
        setFocusNewField(`Language${nGenIndex}`);
    };

    const setFocusNewField = (sName) => {
        setTimeout(() => {
            try {
                form.setFocus(sName);
            } catch (error) { }
        }, 500);
    };

    const setDataMyProfile = () => {
        const data = profileContext as any;
        setArrlanguageSkill(data.listLanguage || []);
        let listLanguage = [];
        if ((data.listLanguage || []).length > 0) {
            listLanguage = data.listLanguage.map((item) => {
                const selectLang =
                    optionLanguage.find((data: any) => {
                        return data.value === item.nLanguageId;
                    }) || [];
                const selectSkillCons =
                    optionLangSkill.find((data: any) => {
                        return data.value === item.nSpeekingId;
                    }) || [];
                const selectSkillRead =
                    optionLangSkill.find((data: any) => {
                        return data.value === item.nReadingId;
                    }) || [];
                const selectSkillWrite =
                    optionLangSkill.find((data: any) => {
                        return data.value === item.nWritingId;
                    }) || [];
                return {
                    Index: item.nLanguageSkillId,
                    Language:
                        item.nLanguageId === 99
                            ? { label: item.sOther, value: item.nLanguageId }
                            : selectLang,
                    ConvsSkill: selectSkillCons,
                    ReadSkill: selectSkillRead,
                    WriteSkill: selectSkillWrite,
                    sOther: item.sOther,
                } as ILanguageSkill;
            }) as ILanguageSkill[];
        } else {
            listLanguage = [
                {
                    Index: 0,
                    Language: null,
                    ConvsSkill: null,
                    ReadSkill: null,
                    WriteSkill: null,
                    sOther: "",
                },
            ] as ILanguageSkill[];
        }
        setLanguageSkill(listLanguage);
        listLanguage.forEach((data) => {
            form.setValue("Language" + data.Index, data.Language || '');
            form.setValue("ConvsSkill" + data.Index, data.ConvsSkill || '');
            form.setValue("ReadSkill" + data.Index, data.ReadSkill || '');
            form.setValue("WriteSkill" + data.Index, data.WriteSkill || '');
        });
    };

    useEffect(() => {
        if (!isLoading) {
            setDataMyProfile();
        }
    }, [isLoading]);

    const handleEditWorkUnit = () => {
        let sJobApply = "";
        let sID = "";
        if (isJobApply) {
            sJobApply = "sJobApply";
            sID = sIDNew;
        }
        history(`/MyProfileEdit?sMode=LanguageSkill&sJobApply=${sJobApply}&sIDNew=${sID}`);
    }


    const onBack = () => {
        setIsLanguageSkillEdit(false)
        if (isJobApply) {
            let objProfile = {
                sMode: "LanguageSkill",
            };
            let objJobDetail = {
                sID: sIDNew || "",
                isApply: true,
                sFromPage: "JobDetail",
            };
            onBackEdit(objProfile,objJobDetail);

        } else {
            history(`/MyProfile?sMode=LanguageSkill`);
        }
    }
    const onGoPage = () => {
        if (isJobApply) {
            let objProfile = {
                sMode: "LanguageSkill",
            };
            let objJobDetail = {
                sID: sIDNew || "",
                isApply: true,
                sFromPage: "JobDetail",
            };
            onBackEdit(objProfile,objJobDetail);
        } else {
            history(`/MyProfile?sMode=LanguageSkill`);
        }
    }

    useEffect(() => {
        setIsLanguageSkillEdit(Edit);
        setExpandedLangSkill(isExpanded);
    }, [])

    const onSubmit = (values) => {
        const arrLangSkill = languageSkillContext.map((item) => {
            return {
                nLanguageSkillId: item.Index,
                nLanguageId:
                    item.Language["value"] === undefined ? 99 : item.Language["value"],
                nSpeekingId: item.ConvsSkill["value"],
                nReadingId: item.ReadSkill["value"],
                nWritingId: item.WriteSkill["value"],

                sOther: item.sOther,
            };
        });
        const dataProfile = {
            listLanguage: arrLangSkill,
            nMode: 10,
        };

        dispatch(
            DialogActionCreators.OpenDialogSubmit(i18n(`${i18nField}.ConfirmMsgUpdate`), () => {
                dispatch(DialogActionCreators.CloseDialogSubmit() as any);
                dispatch(DialogActionCreators.LoadSubmit("Waiting...") as any);
                BlockUI();
                AxiosPost(`JobSeeker/UpdateProfile`, dataProfile, (result) => {
                    UnBlockUI();
                    let ProfileNoti = secureLocalStorage.getItem("ProfileNoti");
                    secureLocalStorage.setItem("ProfileNoti", ProfileNoti === "true" ? "false" : "true");
                    const res = result.Data;
                    if (res.IsSuccess) {
                        dispatch(DialogActionCreators.OpenDialogSuccess(i18n(`${i18nField}.MsgPanelLangPro`), () => { onGoPage(); })
                        );
                    }
                },
                    (err) => {
                        if (!err.response) {
                            history("/error");
                            return;
                        }
                    }
                );
            }
            ) as any
        );
    };

    const LanguageSkill = useCallback(() => {
        return (
            <FormProvider {...form} >

                {(languageSkillContext || []).map((item: ILanguageSkill, index) => {
                    return (
                        <Box component={Paper} sx={{ border: "10px", p: 1, flex: 1, m: "2%" }} key={index}>
                            {isLanguageSkillEdit && (
                                <>
                                    {index === 0 ? null :
                                        <IconButton aria-label="delete" color="error" onClick={() => handleRemoveLanguageSkill(item.Index)}>
                                            <CloseIcon />
                                        </IconButton>}
                                </>)}
                            <Grid container justifyContent="center" alignItems="center" spacing={1} >
                                <Grid item container spacing={2} xs={12} >
                                    <Grid item md={3} xs={12} >
                                        <AutoCompleteSelect
                                            name={"Language" + item.Index}
                                            label={i18n(`${i18nField}.Language`)}
                                            options={optionLanguage}
                                            onChange={(value) => {
                                                handleChangeValue(form, item.Index, value);
                                            }}
                                            freeSolo
                                            required
                                            disabled={!isLanguageSkillEdit}
                                        />
                                    </Grid>
                                    <Grid item md={3} xs={12} >
                                        <AutoCompleteSelect
                                            name={"ConvsSkill" + item.Index}
                                            label={i18n(`${i18nField}.ConvsSkill`)}
                                            options={optionLangSkill}
                                            onChange={(value) => {
                                                handleChangeValue(form, item.Index, value);
                                            }}
                                            required
                                            disabled={!isLanguageSkillEdit}
                                        />
                                    </Grid>
                                    <Grid item md={3} xs={12} >
                                        <AutoCompleteSelect
                                            name={"ReadSkill" + item.Index}
                                            label={i18n(`${i18nField}.ReadSkill`)}
                                            options={optionLangSkill}
                                            onChange={(value) => {
                                                handleChangeValue(form, item.Index, value);
                                            }}
                                            required
                                            disabled={!isLanguageSkillEdit}
                                        />
                                    </Grid>
                                    <Grid item md={3} xs={12} >
                                        <AutoCompleteSelect
                                            name={"WriteSkill" + item.Index}
                                            label={i18n(`${i18nField}.WriteSkill`)}
                                            options={optionLangSkill}
                                            onChange={(value) => {
                                                handleChangeValue(form, item.Index, value);
                                            }}
                                            required
                                            disabled={!isLanguageSkillEdit}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Box>
                    );
                })}
            </FormProvider>
        )
    }, [languageSkillContext]);

    return (
        <>
            <FormProvider {...form} >
                <AccordionCustom
                    sx={{ border: "10px", p: 1, flex: 1 }}
                    expanded={expandedLangSkill}
                    onChange={() => setExpandedLangSkill((prev) => !prev)}
                >
                    <AccordionSummaryCustom>
                        <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                        >
                            <Grid item xs={6} sm={8} md={9}>
                                <Typography >
                                    {i18n(`${i18nField}.PanelLangPro`)}<span style={{ color: "red" }}> *</span>
                                </Typography>
                            </Grid>
                            <Grid item container
                                justifyContent="flex-end" xs={6} sm={4} md={3}>
                                      <CardComplete isComPlete ={isComPlete} />
                                {/* {isComPlete ? (
                                    <CardComplete />
                                ) : (
                                    <CardInComplete />
                                )} */}
                            </Grid>
                        </Grid>
                    </AccordionSummaryCustom>
                    <AccordionDetailsCustom>
                        <LanguageSkill />
                        {isLanguageSkillEdit && (
                            <Stack
                                sx={{ border: "10px", p: 1, flex: 1, m: "2%" }}
                                direction="row"
                                justifyContent="center"
                            >
                                <BtnRadiusProfile
                                    txt={i18n(`${i18nField}.AddWorkDetail`)}
                                    onClick={handleAddLanguageSkill}
                                />
                            </Stack>
                        )}

                        {!isLanguageSkillEdit && (
                            <Stack
                                sx={{ border: "10px", p: 1, flex: 1, m: "2%" }}
                                direction="row"
                            >
                                <BtnEditProfile
                                    txt={i18n(`${i18nField}.Edit`)}
                                    onClick={handleEditWorkUnit}
                                />
                            </Stack>
                        )}

                    </AccordionDetailsCustom>
                </AccordionCustom>

                {isLanguageSkillEdit && (
                    <Stack
                        spacing={1}
                        sx={{ border: "10px", p: 1, flex: 1, mt: "2%" }}
                        direction="row"
                        justifyContent="space-between"
                        alignItems="flex-start"
                    >
                        <BtnBack
                            onClick={() => { onBack(); }}
                            txt={i18n(`${i18nCommon}.back`)}
                        />
                        <BtnSubmit
                            onClick={form.handleSubmit(onSubmit, (err) => console.log("error", err))}
                            txt={i18n(`${i18nCommon}.save`)}
                        />
                    </Stack>
                )}
            </FormProvider>
        </>
    )
}

export default LanguageSkillNew;