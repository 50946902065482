import { Card, Divider, Grid, Typography } from '@mui/material';
import CardContent from '@mui/material/CardContent';
import { BtnRadius } from 'components/Common/Button';
import { useNavigate } from "react-router-dom";
import { i18n } from 'i18n';
import { useState, } from 'react';
import { GetPermission } from 'service/CommonFunction/TS_function';

const AdminCard = (item) => {
    const i18nField = 'entities.Banner'
    const [nPermission, setPermission] = useState(0);
    const history = useNavigate();

    const goToAdd = (sID: string) => {
        history(`/AdminBannerAdd?sPositionID=${encodeURI(sID)}`);
    };
    
    return (
        <>

            <BtnRadius
                txt={item.sNameBox.sName}
                sBackgroundColor={item.sNameBox.sColor}
                onClick={() => goToAdd(item.sNameBox.nPosition_ID.toString())}              
            />
            < Card sx={{ borderRadius: 5, boxShadow: "0px 0px 7px 2px #C9C9C9", }} >
                <CardContent sx={{ width: 250 }} >
                    <Grid container  >
                        <Grid item xs={12}>
                            <Grid container  >
                                <Grid item xs={6}>
                                    <Typography fontSize={13} >
                                        {"สถานะ"}
                                        <Divider />
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography fontSize={13} align="right" color="#1c54b2" >
                                        {item.sNameBox.sStatus}
                                        <Divider />
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={6}>
                                    <Typography fontSize={13}>
                                        {"ใช้งาน"}
                                        <Divider />
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography fontSize={13} align="right" color="#1c54b2">
                                        {item.sNameBox.nActive}
                                        <Divider />
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={6}>
                                    <Typography fontSize={13}>
                                        {"หมดอายุล่าสุด"}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography fontSize={13} align="right" color="#1c54b2" >
                                        {item.sNameBox.sLast_Expire}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card >
        </>

    )

}
export default AdminCard
