import { useState } from "react";
import yupFormSchemas from "components/Common/yup/yupFormSchemas";
import * as yup from "yup";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import DatePickerFormItem from "components/Common/ElementInForm/DatePickerFormItem";
import DateRangeSigleBox from "components/Common/ElementInForm/DateRangeSigleBox";
import { BtnSave } from "components/Common/Button";
import { Grid, Typography, Box, Tooltip } from "@mui/material";
import AutoCompleteRenderItem from "components/Common/ElementInForm/AutoCompleteRenderItem";
import MultiSelectGroupForm from "components/Common/ElementInForm/MultiSelectGroupForm";
import SelectGroupForm from "components/Common/ElementInForm/SelectGroupForm";
import { useNavigate } from "react-router-dom";
import { FnBlock_UI } from "service/SystemFunction/SystemFunction";
import { AxiosPost } from "service/CommonFunction/TS_function";
import { AutoCompleteSelectWithSlider } from "./Common/ElementInForm/AutoCompleteWithSlider";
import Input from "components/Common/ElementInForm/InputCustomLabelFormItem";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import JobDetail from "view/font/JobDetail/JobDetail";
import { i18n } from "i18n";
const i18nForthisPage = "home";

const arrSalary = [
  {
    label: "10,000",
    value: "1",
  },
  {
    label: "15,000",
    value: "2",
  },
  {
    label: "20,000",
    value: "3",
  },
  {
    label: "40,000",
    value: "4",
  },
  {
    label: "50,000",
    value: "5",
  },
  {
    label: "70,000",
    value: "6",
  },
  {
    label: "100,000",
    value: "7",
  },
  {
    label: "130,000",
    value: "8",
  },
];

const arrLastedJobs = [
  {
    sURLImage:
      "https://image-service-cdn.seek.com.au/3d139d0eaf9fee7f75d91116d0483f130cb1db3a/ee4dce1061f3f616224767ad58cb2fc751b8d2dc",
    sJobPostID: "1",
    sPosition: "Engineering Consultant Supervisor (SCG Logistics)",
    sCompany: "The Siam Cement Public Company Limited (SCG)",
    sLocation: "กรุงเทพ",
    sSalary: "THB 16,000 - THB 30,000 /เดือน",
    sCompanyID: "1",
    sID: "1",
    lstPoint: [
      "EE/IE who have logistics and warehouse background.",
      "Design /consult /Excellent in electrical machinery",
      "Analyze and Solve technical problems",
    ],
    sAnnounced: "12 ชั่วโมงที่ผ่านมา",
  },
  {
    sURLImage:
      "https://image-service-cdn.seek.com.au/a52724d336c89d2e2a3bdb6339325435621a560c/ee4dce1061f3f616224767ad58cb2fc751b8d2dc",
    sJobPostID: "2",
    sPosition: "Production Engineer/วิศวกรฝ่ายผลิต",
    sCompany: "Charoen Pokphand Foods Public Company Limited",
    sLocation: "นนทบุรี",
    sSalary: "THB 15,000 - THB 40,000 /เดือน",
    sCompanyID: "1",
    lstPoint: [
      "ปริญญาตรี สาขาวิศวกรรมอุตสาหการ",
      "Production Engineering, industrial engineering",
      "วิศวกรฝ่ายผลิต",
    ],
    sAnnounced: "12 ชั่วโมงที่ผ่านมา",
  },
  {
    sURLImage:
      "https://image-service-cdn.seek.com.au/3d139d0eaf9fee7f75d91116d0483f130cb1db3a/ee4dce1061f3f616224767ad58cb2fc751b8d2dc",
    sJobPostID: "3",
    sPosition: "Engineering Consultant Supervisor (SCG Logistics)",
    sCompany: "The Siam Cement Public Company Limited (SCG)",
    sLocation: "กรุงเทพ",
    sSalary: "THB 16,000 - THB 30,000 /เดือน",
    sCompanyID: "1",
    sID: "1",
    lstPoint: [
      "EE/IE who have logistics and warehouse background.",
      "Design /consult /Excellent in electrical machinery",
      "Analyze and Solve technical problems",
    ],
    sAnnounced: "12 ชั่วโมงที่ผ่านมา",
  },
  {
    sURLImage:
      "https://image-service-cdn.seek.com.au/a52724d336c89d2e2a3bdb6339325435621a560c/ee4dce1061f3f616224767ad58cb2fc751b8d2dc",
    sJobPostID: "4",
    sPosition: "Production Engineer/วิศวกรฝ่ายผลิต",
    sCompany: "Charoen Pokphand Foods Public Company Limited",
    sLocation: "นนทบุรี",
    sSalary: "THB 15,000 - THB 40,000 /เดือน",
    sCompanyID: "1",
    lstPoint: [
      "ปริญญาตรี สาขาวิศวกรรมอุตสาหการ",
      "Production Engineering, industrial engineering",
      "วิศวกรฝ่ายผลิต",
    ],
    sAnnounced: "12 ชั่วโมงที่ผ่านมา",
  },
  {
    sURLImage:
      "https://image-service-cdn.seek.com.au/3d139d0eaf9fee7f75d91116d0483f130cb1db3a/ee4dce1061f3f616224767ad58cb2fc751b8d2dc",
    sJobPostID: "5",
    sPosition: "Engineering Consultant Supervisor (SCG Logistics)",
    sCompany: "The Siam Cement Public Company Limited (SCG)",
    sLocation: "กรุงเทพ",
    sSalary: "THB 16,000 - THB 30,000 /เดือน",
    sCompanyID: "1",
    sID: "1",
    lstPoint: [
      "EE/IE who have logistics and warehouse background.",
      "Design /consult /Excellent in electrical machinery",
      "Analyze and Solve technical problems",
    ],
    sAnnounced: "12 ชั่วโมงที่ผ่านมา",
  },
  {
    sURLImage:
      "https://image-service-cdn.seek.com.au/a52724d336c89d2e2a3bdb6339325435621a560c/ee4dce1061f3f616224767ad58cb2fc751b8d2dc",
    sJobPostID: "6",
    sPosition: "Production Engineer/วิศวกรฝ่ายผลิต",
    sCompany: "Charoen Pokphand Foods Public Company Limited",
    sLocation: "นนทบุรี",
    sSalary: "THB 15,000 - THB 40,000 /เดือน",
    sCompanyID: "1",
    lstPoint: [
      "ปริญญาตรี สาขาวิศวกรรมอุตสาหการ",
      "Production Engineering, industrial engineering",
      "วิศวกรฝ่ายผลิต",
    ],
    sAnnounced: "12 ชั่วโมงที่ผ่านมา",
  },
  {
    sURLImage:
      "https://image-service-cdn.seek.com.au/3d139d0eaf9fee7f75d91116d0483f130cb1db3a/ee4dce1061f3f616224767ad58cb2fc751b8d2dc",
    sJobPostID: "7",
    sPosition: "Engineering Consultant Supervisor (SCG Logistics)",
    sCompany: "The Siam Cement Public Company Limited (SCG)",
    sLocation: "กรุงเทพ",
    sSalary: "THB 16,000 - THB 30,000 /เดือน",
    sCompanyID: "1",
    sID: "1",
    lstPoint: [
      "EE/IE who have logistics and warehouse background.",
      "Design /consult /Excellent in electrical machinery",
      "Analyze and Solve technical problems",
    ],
    sAnnounced: "12 ชั่วโมงที่ผ่านมา",
  },
  {
    sURLImage:
      "https://image-service-cdn.seek.com.au/a52724d336c89d2e2a3bdb6339325435621a560c/ee4dce1061f3f616224767ad58cb2fc751b8d2dc",
    sJobPostID: "8",
    sPosition: "Production Engineer/วิศวกรฝ่ายผลิต",
    sCompany: "Charoen Pokphand Foods Public Company Limited",
    sLocation: "นนทบุรี",
    sSalary: "THB 15,000 - THB 40,000 /เดือน",
    sCompanyID: "1",
    lstPoint: [
      "ปริญญาตรี สาขาวิศวกรรมอุตสาหการ",
      "Production Engineering, industrial engineering",
      "วิศวกรฝ่ายผลิต",
    ],
    sAnnounced: "12 ชั่วโมงที่ผ่านมา",
  },
];
export default function ExampleComponent() {
  const navigate = useNavigate();
  const { BlockUI, UnBlockUI } = FnBlock_UI();

  const [arrProvince, setArrProvince] = useState([]);

  const [sActiveID, setActiveID] = useState("");

  const initialValues = {
    lstTag: [],
    lstProvince: [],
    sProvince: "",
  };

  const objSchema = {
    dStartDate: yupFormSchemas.date("Start Date", {
      required: true,
    }),
    dDateRangePicker: yupFormSchemas.dateRange("Date Range Picker", {
      required: true,
    }),
    lstTag: yupFormSchemas.stringArray("Tag", {
      required: true,
    }),
    lstProvince: yupFormSchemas.stringArray("จังหวัด", { required: true }),
    sProvince: yupFormSchemas.object("จังหวัด", { required: true }),
    sSalary: yupFormSchemas.object("เงินเดือน", { required: true }),
  };

  const schema = yup.object().shape(objSchema);

  const form = useForm({
    resolver: yupResolver(schema),
    shouldUnregister: false,
    shouldFocusError: true,
    mode: "all",
    defaultValues: initialValues as any,
  });

  const OnGetData = () => {
    BlockUI();
    AxiosPost(
      "Example/GetProvince",
      {},
      (result) => {
        UnBlockUI();
        setArrProvince(result.lstProvince);
      },
      (err) => {
        if (!err.response) {
          navigate("/error", { replace: true });
          return;
        }
      }
    );
  };

  return (
    <FormProvider {...form}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <DatePickerFormItem
            disabled={false}
            name={"dStartDate"}
            label={"Date Picker"}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <DateRangeSigleBox
            label="Start"
            lableEnd="End"
            name="dDateRangePicker"
            required
          />
        </Grid>
        <Grid item xs={12}>
          <AutoCompleteRenderItem
            isFreeCreate
            name={"lstTag"}
            options={[]}
            label={"Tags"}
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={12}>
          <MultiSelectGroupForm
            name="lstProvince"
            options={arrProvince || []}
            label="จังหวัด (Multi)"
            limitTag={3}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <SelectGroupForm
            name="sProvince"
            options={arrProvince || []}
            label="จังหวัด (Single)"
            required
          />
        </Grid>
        <Grid item xs={12}>
          <AutoCompleteSelectWithSlider
            name="sSalary"
            options={arrSalary}
            label="เงินเดือน (บาท)"
            required
            maxValueSlider={130000}
          />
        </Grid>
        <Grid item xs={12}>
          <Input
            name={"sSalaryInput"}
            label={
              <Typography sx={{ fontWeight: 400 }} component="label">
                Salary
              </Typography>
            }
            required
            fullWidth
            small
            type="number"
          />
        </Grid>
        <Grid item xs={12}>
          <BtnSave onClick={form.handleSubmit((e) => {
            ////console.log(e);
          })}
          />
        </Grid>

        {/* Tab Search Job */}
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={4} container>
              <Grid
                item
                xs={12}
                sx={{
                  overflow: "scroll",
                  overflowX: "hidden",
                  height: "90vh",
                  borderRadius: "10px",
                  pb: "10px",
                }}
                className="custom-scroll-bar-search-job"
              >
                {arrLastedJobs.map((item, index) => (
                  <Grid key={index} item xs={12} sx={{ mt: 1, ml: 1, mr: 1 }}>
                    <Box
                      sx={{
                        borderRadius: "10px",
                        boxShadow:
                          sActiveID === item.sJobPostID
                            ? "0px 0px 7px 2px #4F9BC3 !important"
                            : "0px 0px 7px 2px #C9C9C9",
                        p: "15px",
                        cursor: "pointer",
                        ":hover": {
                          boxShadow: "0px 0px 7px 2px #A4A4A4",
                        },
                      }}
                      onClick={() => {
                        setActiveID(item.sJobPostID || "");
                      }}
                    >
                      {item.sURLImage && (
                        <Tooltip
                          title={i18n(`${i18nForthisPage}.btnViewCompany`)}
                        >
                          <img
                            src={item.sURLImage}
                            alt="company"
                            style={{
                              height: "30px",
                            }}
                            onClick={(e) => {
                              navigate(
                                `/Guest/Company?sID=${item.sCompanyID}`
                              );
                              e.stopPropagation();
                            }}
                          />
                        </Tooltip>
                      )}
                      <Typography
                        className="ellipsis-label"
                        sx={{
                          fontWeight: 400,
                          color: "#295697",
                          pt: "16px",
                          ":hover": {
                            textDecoration: "underline",
                            color: "#295697",
                          },
                        }}
                        onClick={() => {
                          setActiveID(item.sJobPostID || "");
                        }}
                      >
                        {item.sPosition}
                      </Typography>
                      <Typography
                        className="ellipsis-label"
                        sx={{
                          fontSize: 14,
                          fontWeight: 400,
                          color: "#000",
                          pb: "16px",
                        }}
                      >
                        {item.sCompany}
                      </Typography>

                      <Typography
                        className="ellipsis-label"
                        sx={{
                          fontSize: 14,
                          fontWeight: 400,
                          color: "#000",
                        }}
                      >
                        {item.sLocation}
                      </Typography>
                      <Typography
                        className="ellipsis-label"
                        sx={{
                          fontSize: 13,
                          fontWeight: 500,
                          color: "#C91643",
                        }}
                      >
                        {item.sSalary}
                      </Typography>
                      <Box sx={{ pt: "16px", pb: "16px" }}>
                        {item.lstPoint.length > 0 &&
                          item.lstPoint.map((iP, i) => (
                            <Box key={i} sx={{ display: "flex" }}>
                              <FiberManualRecordIcon
                                sx={{ fontSize: "6px", mt: "7px" }}
                              />
                              <Typography
                                className="ellipsis-label"
                                sx={{
                                  fontSize: 14,
                                  fontWeight: 400,
                                  color: "#000",
                                  marginLeft: "5px",
                                }}
                              >
                                {iP}
                              </Typography>
                            </Box>
                          ))}
                      </Box>
                      <Typography
                        className="ellipsis-label"
                        sx={{
                          fontSize: 12,
                          fontWeight: 400,
                          color: "#000"
                        }}
                      >
                        {item.sAnnounced}
                      </Typography>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Grid>
            <Grid item xs={8}>
              <Box sx={{ height: "90vh", width: "100%" }}>
                <JobDetail sJobPostID={sActiveID} />
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </FormProvider>
  );
}