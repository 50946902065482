import { Grid, Stack } from "@mui/material";
import AdminCandidateMasterTable from "./AdminCandidateMasterTable";


export default function FromMasterTable() {
  return (
    <Stack sx={{ mx: "1%" }}>
      <Grid item>
        <AdminCandidateMasterTable />
      </Grid>
    </Stack>
  );
}