import React, { useEffect, useState } from "react";
import { Accordion, AccordionDetails, AccordionSummary, Card, Grid, Hidden, Skeleton, Typography } from "@mui/material";
import Content from "./Content";
import { useNavigate } from "react-router-dom";
import { AccordionCustom, AccordionDetailsCustom, AccordionSummaryCustom } from "components/SeekerProfile/ElementCustom";
import { i18n } from "i18n";
import secureLocalStorage from "react-secure-storage";

const DetailCompany = (props) => {
  const { lstCompanyContent, lstCompanyJob, isViewJob, isExpireDate } = props;
  const navigate = useNavigate();
  const isLoading = props.isLoading;
  const [lang, setLang] = useState("");
  const [expandedOtherJob, setExpandedOtherJob] = useState(false);
  const i18nCommon = "common";

  useEffect(() => {
    setLang(secureLocalStorage.getItem("language").toString());
  }, []);

  return (
    <>
      {isLoading ? (
        <Skeleton variant="rectangular" width="100%" >
          <div style={{ paddingTop: "15%" }} />
        </Skeleton>
      ) : (
        <Grid item xs={12} marginTop={3}>
          {lstCompanyContent != null && lstCompanyContent.length > 0 ? (
            <Content lstContent={lstCompanyContent} lstJobPost={lstCompanyJob} />
          ) : (
            ""
          )}

          {/* other job */}
          {isViewJob && !isExpireDate ? lstCompanyJob != null && lstCompanyJob.length > 0 && !lstCompanyJob.IsExpired ? (
            <AccordionCustom
              sx={{ border: "10px", p: 1, flex: 1, m: "2%" }}
              expanded={expandedOtherJob}
              onChange={() => setExpandedOtherJob((prev) => !prev)}
            >
              <AccordionSummaryCustom>
                <Typography sx={{ width: "33%", flexShrink: 0, whiteSpace: "nowrap" }}>
                  {/* other job */}
                  {i18n(`${i18nCommon}.Expand`)}
                </Typography>
              </AccordionSummaryCustom>
              <AccordionDetailsCustom>
                <Grid item xs={12} marginTop={3}>
                  {lstCompanyJob != null && lstCompanyJob.length > 0 ? lstCompanyJob.map((item) => {
                    return (
                      <Card
                        sx={{ my: "2%", borderRadius: "20px", cursor: "pointer", }}
                        onClick={() => {
                          navigate(`/JobDetail?sID=${(item.sJobPostID)}`)
                        }}
                      >
                        <Grid container sx={{ my: "2%", mx: "2%" }}>
                          {/* phone size */}
                          <Hidden smUp>
                            <Grid item md={6} xs={12} sx={{ mx: "4%", mt: "4%" }}>
                              <Grid sx={{ marginBottom: "4%", fontWeight: 600, fontSize: "14px" }}>
                                {lang == "th" ? item.sJob_TH : item.sJob_EN}
                              </Grid>

                              <Grid sx={{ fontSize: "14px" }}>
                                {lang == "th" ? item.sRegionNameTH : item.sRegionNameEN}
                              </Grid>
                            </Grid>

                            <Grid item md={6} xs={12} sx={{ mx: "4%", mb: "4%" }}>
                              <Grid sx={{ marginBottom: "9%" }}>
                              </Grid>
                              <Grid sx={{ color: "#cb3556", fontWeight: 400, fontSize: "14px" }}>
                                THB {item.nSalaryStart} - THB {item.nSalaryEnd}
                              </Grid>
                            </Grid>
                          </Hidden>

                          {/* normal size */}
                          <Hidden smDown>
                            <Grid item md={6} xs={12}>
                              <Grid sx={{ marginBottom: "4%", fontWeight: 600, fontSize: "14px" }}>
                                {lang == "th" ? item.sJob_TH : item.sJob_EN}
                              </Grid>

                              <Grid sx={{ fontSize: "14px" }}>
                                {lang == "th" ? item.sRegionNameTH : item.sRegionNameEN}
                              </Grid>
                            </Grid>

                            <Grid item md={6} xs={12}>
                              <Grid sx={{ marginBottom: "9%" }}>
                              </Grid>
                              <Grid sx={{ color: "#cb3556", fontWeight: 400, fontSize: "14px" }}>
                                THB {item.nSalaryStart} - THB {item.nSalaryEnd}
                              </Grid>
                            </Grid>
                          </Hidden>
                        </Grid>
                      </Card>
                    )
                  }) : null}
                </Grid>
              </AccordionDetailsCustom>
            </AccordionCustom>
          ) : null : null}
          {/* other job */}
        </Grid>
      )}
    </>
  );
};
export default DetailCompany;
