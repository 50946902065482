import React, { useEffect, useState } from 'react'
import { Modal, Box, Tooltip, CircularProgress, Typography, Grid, Button, Fade, Hidden } from "@mui/material"
import { Close } from '@mui/icons-material'
import AutoCompleteRenderItem from 'components/Common/ElementInForm/AutoCompleteRenderItem'
import { AxiosGet, AxiosPostFilePDF } from 'service/CommonFunction/TS_function'
import { DialogActionCreators } from 'store/redux/DialogAlert'
import secureLocalStorage from 'react-secure-storage'
import { useLocation } from 'react-router'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom';
import ConfirmBtnIcon from "@mui/icons-material/Done";
import { Theme, useTheme } from "@mui/material/styles";
import { makeStyles, withStyles } from "@mui/styles";
import { i18n } from "i18n";
import CloseIcon from "@mui/icons-material/Close";

const ModalDownloandProfile = ({ isOpen, setOpen, sJsId, setJsId, lstData, setLstData }) => {
    const dispatch = useDispatch();
    // const isEmployer = window.location.pathname.includes("/EmployerSearchViewJobseeker");
    const langeCode = secureLocalStorage.getItem("language").toString() === "th" ? "TH" : "EN";
    //  const langeCode = isEmployer ? (secureLocalStorage.getItem("language").toString() || "EN").toLocaleUpperCase() : "TH"
    const history = useNavigate();


    const i18nDialog = 'dialog';
    const i18nField = 'entities.Candidate'
    const useStyles = makeStyles((theme: Theme) => ({
        paper: {
            width: "400px !important",
            height: "auto",
            minHeight: "55% !important",
            borderRadius: "20px !important",

        },
        DialogPaperTemp: {
            height: "70%",
            width: "400px",
            borderRadius: "20px",
        },
        DialogTitle: {
            height: "40%",
            backgroundColor: "#28a745",
            color: "#fff",
            textAlign: "center",
        },

        DialogTitleError: {
            height: "40%",
            backgroundColor: "#ed3847",
            color: "#fff",
            textAlign: "center",
        },
        DialogTitleWarning: {
            height: "40%",
            backgroundColor: "#ffce3d",
            color: "#fff",
            textAlign: "center",
        },
        DialogTitleSubmit: {
            height: "40%",
            backgroundColor: "#163172",
            color: "#333333",
            textAlign: "center",
        },

        DialogContent: {
            height: "50%",
            backgroundColor: "#fff",
            color: "#000",
            padding: "10px 15px",
        },
        DialogAction: {
            // height: "15%",
            backgroundColor: "#fff",
            color: "#000",
            justifyContent: "center !important",
        },
        DialogActionConfirm: {
            // height: "15%",
            backgroundColor: "#fff",
            color: "#000",
            justifyContent: "center !important",
            height: "70px",
        },
        SuccessColor: {
            color: "#fff",
            backgroundColor: "#28a745",
            borderColor: "#28a745",
            // margin: theme.spacing(1),
            "&:hover": {
                backgroundColor: "#28a745",
            },
        },
        ButtonDialog: {
            background: "rgb(250, 250, 250)",
            color: "#000",
            margin: "8px !important",
            width: "auto !important",
            fontSize: "1rem",
            borderRadius: "2em !important",
            padding: "4px 15px !important",
            textTransform: "none",
        },
    }));
    const classes = useStyles();
    const styles = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 300,

        bgcolor: 'background.paper',
        borderRadius: "20px",
        p: 2,
        "@media screen and (min-width:640px)": {
            width: 500,
        }
    };
    const onBack = () => {
        history("/");
        window.location.reload();
    };

    const ExportPDF = () => {

        let lstfileName = lstData.find(f => f.sId === sJsId);
        let fileName = "";
        if (lstfileName && langeCode === "TH") {
            fileName = lstfileName.sSeekerNameTH_pdf + ".pdf"
        } else {
            fileName = lstfileName.sSeekerNameEN_pdf + ".pdf"
        }
        AxiosGet("EmployerSearch/GetDataFile", { sID: sJsId }, (result) => {
            (result.lstJobSeekerFile || []).map((m) => {
                var a = document.createElement("a"); //Create <a>
                a.href = m.sUrl + ""; //Image Goes here
                a.download = m.sFileName; //File name Here
                a.click(); //Downloaded file

            });

            AxiosPostFilePDF(fileName, "AdminCandidate/GetReportCandidateProfile", { sID: sJsId, sLang: langeCode }, (result) => {
                const url = window.URL.createObjectURL(new Blob([result]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", fileName);
                document.body.appendChild(link);
                link.click();
                link.remove();

                setOpen(false)
                window.location.reload();
            },
                (err) => {
                    if (err.Message != "") {
                        dispatch(
                            DialogActionCreators.OpenDialogWarning(
                                err.Message
                            ) as any
                        );
                    }
                }
            );
        })
    };

    return (
        <div>

            {/* phone size */}
            <Hidden smUp>
                <Modal
                    open={isOpen}
                    onClose={() => {
                        setOpen(false)
                        setJsId();
                    }}
                    closeAfterTransition
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    {/* <Fade in={open}> */}
                    <Box sx={{ ...styles, width: 350, overflow: "hidden" }} className={" flex flex-col"}>
                        <div className="flex flex-row ">
                            <div className="flex">
                                <Typography variant='h6'> {i18n(`${i18nField}.Downloadprofile`)}</Typography>
                            </div>
                            <div className="flex flex-[1] justify-end  ">
                                <Tooltip title="ปิด">
                                    <div onClick={() => {
                                        setOpen(false)
                                        window.location.reload();
                                    }} className='rounded-[50%] bg-[#d1d1d1] w-[1.5em] h-[1.5em] flex cursor-pointer ease-linear duration-[100ms]  hover:bg-[#8c8c8c]'>
                                        <Close fontSize='small' className='m-[auto] text-[white]' />
                                    </div>
                                </Tooltip>
                            </div>
                        </div>

                        <Grid container direction={"row"} justifyContent="center">
                            <Grid item container xs={5} justifyContent="center">
                                <Button
                                    variant="contained"
                                    className={classes.ButtonDialog}
                                    style={{
                                        background: "#28a745",
                                        color: "white",
                                        borderRadius: "20px",
                                        marginLeft: 50
                                    }}
                                    size="small"
                                    startIcon={
                                        <ConfirmBtnIcon
                                            style={{
                                                fontSize: "1.5rem",
                                            }}
                                        />
                                    }
                                    onClick={ExportPDF}
                                >
                                    {i18n(`${i18nDialog}.DialogConfirm`)}
                                </Button>
                            </Grid>

                            <Grid item container xs={5} justifyContent="center">
                                <Button
                                    variant="contained"
                                    className={classes.ButtonDialog}
                                    style={{
                                        color: "#fff",
                                        backgroundColor: "#B6B6B6",
                                        borderRadius: "20px",
                                        marginLeft: 30
                                    }}
                                    size="small"
                                    startIcon={
                                        <CloseIcon
                                            style={{
                                                fontSize: "1.5rem",
                                            }}
                                        />
                                    }
                                    onClick={() => {
                                        setOpen(false)
                                        window.location.reload();
                                    }}

                                >
                                    {i18n(`${i18nDialog}.CloseButt`)}
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                    {/* </Fade> */}
                </Modal>
            </Hidden>

            {/* normal size */}
            <Hidden smDown>
                <Modal
                    open={isOpen}
                    onClose={() => {
                        setOpen(false)
                        setJsId();
                    }}
                    closeAfterTransition
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    {/* <Fade in={open}> */}
                    <Box sx={{ ...styles, width: 200, maxHeight: "200vh", height: "20vh", overflow: "hidden" }} className={" flex flex-col"}>
                        <div className="flex flex-row ">
                            <div className="flex">
                                <Typography variant='h6'> {i18n(`${i18nField}.Downloadprofile`)}</Typography>
                            </div>
                            <div className="flex flex-[1] justify-end  ">
                                <Tooltip title="ปิด">
                                    <div onClick={() => {
                                        setOpen(false)
                                        window.location.reload();
                                    }} className='rounded-[50%] bg-[#d1d1d1] w-[1.5em] h-[1.5em] flex cursor-pointer ease-linear duration-[100ms]  hover:bg-[#8c8c8c]'>
                                        <Close fontSize='small' className='m-[auto] text-[white]' />
                                    </div>
                                </Tooltip>
                            </div>
                        </div>

                        <Grid
                            container
                            direction="row"
                            justifyContent="center"

                        >
                            <Grid item container xs={12} sx={{ marginTop: "5%" }} justifyContent="center">
                                <Grid item container xs={4} justifyContent="center">
                                    <Button
                                        variant="contained"
                                        className={classes.ButtonDialog}
                                        style={{
                                            background: "#28a745",
                                            color: "white",
                                            borderRadius: "20px"
                                        }}
                                        size="small"
                                        startIcon={
                                            <ConfirmBtnIcon
                                                style={{
                                                    fontSize: "1.5rem",
                                                }}
                                            />
                                        }
                                        onClick={ExportPDF}
                                    >
                                        {i18n(`${i18nDialog}.DialogConfirm`)}
                                    </Button>
                                </Grid>
                                <Grid item container xs={4} justifyContent="center">
                                    <Button
                                        variant="contained"
                                        className={classes.ButtonDialog}
                                        style={{
                                            color: "#fff",
                                            backgroundColor: "#B6B6B6",
                                            borderRadius: "20px"
                                        }}
                                        size="small"
                                        startIcon={
                                            <CloseIcon
                                                style={{
                                                    fontSize: "1.5rem",
                                                }}
                                            />
                                        }
                                        onClick={() => {
                                            setOpen(false)
                                            window.location.reload();
                                        }}

                                    >
                                        {i18n(`${i18nDialog}.CloseButt`)}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                    {/* </Fade> */}
                </Modal>
            </Hidden>
        </div >
    )
}
export default ModalDownloandProfile

