import React, { useEffect, useState } from 'react'
import BackgroundLogin from "assets/images/bg-login.jpg"
import InputCustomLabel from 'components/Common/ElementInForm/InputCustomLabelFormItem'
import { useForm, FormProvider } from "react-hook-form"
import { Box, Button, Divider, Grid, Input, Modal, Stack, Typography } from "@mui/material"
import { BtnLogin, BtnAzureLogin, BtnBack, BtnSubmitWithLoading, BtnLoginWithLoading } from "components/Common/Button"
import yupFormSchemas from 'components/Common/yup/yupFormSchemas'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from "yup";
import { useDispatch } from "react-redux"
import { DialogActionCreators } from 'store/redux/DialogAlert'
import { FormGroup, FormControlLabel, Checkbox } from "@mui/material"
import { useNavigate } from 'react-router-dom'
import { AxiosPost } from 'service/CommonFunction/TS_function'
import { FnBlock_UI } from 'service/SystemFunction/SystemFunction'
import { AuthenticationState } from "react-aad-msal"
import { HubConnectionBuilder } from '@microsoft/signalr';
import DialogPreview from 'components/DialogPreview/DialogPreview'
import { i18n } from 'i18n'
// import Login from './index'

export default function LoginForm({ _onSubmit, onCancel, isLoadingAuth, modalLoginOpen,
    lstDataSelect = [], isAuth = false, sManualCode = "", valueLoginTab = 0,
    sUrlQrCode = "", onVerify, onCloseAuth,
    onOpenReset, setOpenForgot
}) {
    const navigate = useNavigate();
    const i18nField = 'entities.LoginForm';
    const [isAd, SetIsAd] = useState(false);
    // const form = formLogin;
    const objSchema = {
        f_username: yupFormSchemas.string(i18n(`${i18nField}.Username`), { required: true }),
        f_usercode: yupFormSchemas.string(i18n(`${i18nField}.sSecureCode`), { required: true }),
        b_remember: yupFormSchemas.string(i18n(`${i18nField}.RememberMe`))
    }

    const initValue: any = {
        f_username: "",
        f_usercode: "",
        b_remember: "0",
    };
    const schema = yup.object().shape(objSchema);
    const formResolver = yupResolver(schema)
    const form = useForm({
        resolver: formResolver,
        shouldUnregister: false,
        shouldFocusError: true,
        mode: "all",
        defaultValues: initValue,
    });

    const handleChangeCheckboxBanner = (e) => {
        let val = form.getValues("b_remember")
        form.setValue("b_remember", val === "1" ? "0" : "1") // 0 = ไม่จำรหัส 1 = จำรหัส
    }

    const handleClickRegis = () => {
        onCancel()
        if (valueLoginTab === 1) {
            navigate('/Guest/Register');
        } else if (valueLoginTab === 2) {
            navigate('/RegisterEmployer');
        }
    }

    const handleClickForgot = () => {
        onCancel()
        setOpenForgot();
    }

    useEffect(() => {
        form.reset();
    }, [modalLoginOpen])

    return (
        <FormProvider {...form}>
            <Stack spacing={1}>
                <Box sx={{ paddingX: 1, width: "100%" }}>
                    <Typography variant={'caption'} sx={{ paddingLeft: "1em" }} >{i18n(`${i18nField}.Username`)}</Typography>
                    <InputCustomLabel
                        rounded
                        name="f_username"
                        onKeyPress={(e) => {
                            if (e.key === "Enter") {
                                form.setFocus("f_usercode")
                            }
                        }}
                    // label={<label>{(i18n(`${i18nField}.Username`))}</label>}
                    />
                </Box>
                <Box sx={{ paddingX: 1, width: "100%" }}>
                    <Typography variant={'caption'} sx={{ paddingLeft: "1em" }} >{i18n(`${i18nField}.sSecureCode`)}
                        <span style={{ paddingLeft: "4px", color: '#1976d2', cursor: "pointer" }}
                            onClick={() => { handleClickForgot() }}
                        >{i18n(`${i18nField}.FotgetSecureCode`)}</span>
                    </Typography>
                    <InputCustomLabel
                        rounded
                        name="f_usercode"
                        type="Password"
                        onKeyPress={(e) => {
                            if (e.key === "Enter") {
                                const objForm = form.getValues();
                                if (!objForm.f_username) {
                                    form.setValue("f_username", '', { shouldValidate: true });
                                } else if (!objForm.f_usercode) {
                                    form.handleSubmit(e => {
                                        _onSubmit(objForm);
                                    })
                                } else {
                                    _onSubmit(objForm);
                                }
                            }
                        }}
                    // label={<label>{(i18n(`${i18nField}.sSecureCode`))}</label>}
                    />
                </Box>
                <Box sx={{ paddingX: 1, width: "100%", paddingLeft: "1em" }}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                size='small'
                                value={2}
                                onChange={(e) => handleChangeCheckboxBanner(e)}
                                sx={{ color: "#dbdbdb", '&.Mui-checked': { color: "rgb(17, 82, 147)" } }}
                            />
                        }
                        // label="ผู้ประกอบการเข้าสู่ระบบ"
                        label={
                            <Typography variant={'caption'} >
                                {i18n(`${i18nField}.RememberMe`)}
                            </Typography>}
                    />
                </Box>

            </Stack>

            <Stack spacing={1}
                //  sx={{ width: "100%", px: 1 }}
                sx={(theme) => ({
                    paddingLeft: "1em",
                    // ...theme.typography.body,

                    [theme.breakpoints.down('lg')]: {
                        // backgroundColor: theme.palette.primary.dark,
                    },
                    [theme.breakpoints.up('lg')]: {
                        // backgroundColor: theme.palette.primary.main,
                    },
                })}
            >
                {/* <BtnBack onClick={onCancel} txt={(i18n(`${i18nField}.BackButt`))} /> */}
                {/* <Button size="large" variant="contained" >
                    <Typography variant='button'>{i18n(`${i18nField}.LoginButt`)}</Typography>
                </Button> */}

                <BtnLoginWithLoading
                    txt={(i18n(`${i18nField}.LoginButt`))}
                    isLoading={isLoadingAuth}
                    onClick={!isAd ? form.handleSubmit(e => {
                        _onSubmit(e);
                    }) : () => _onSubmit(null)}
                />
                <Typography variant={'caption'}  >
                    {i18n(`${i18nField}.Or`)}
                    <span style={{ paddingLeft: "2px", paddingRight: "2px", color: '#1976d2', cursor: "pointer" }}
                        onClick={() => { handleClickRegis() }}
                    >{i18n(`${i18nField}.SignUp`)}</span>
                    {i18n(`${i18nField}.AnotherWay`)}
                </Typography>
            </Stack>
        </FormProvider >
    )
}