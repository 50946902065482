import React, { useState, useEffect } from 'react';
import { Grid, Card, Button, Dialog, AppBar, Toolbar, IconButton } from "@mui/material"
import CookieConsent from "react-cookie-consent";
import ContentPage from './ContentPage';
import CloseIcon from "@mui/icons-material/Close";
import { objContent } from '../SetupTemplate/ManageContent';
import { AxiosPost, ResultAPI } from 'service/CommonFunction/TS_function';
import { i18n } from 'i18n';

const CookieCondition = () => {
    const [lstContent, setLstPreviewContent] = useState<objContent[]>([]);
    const [isShowPreview, setIsShowPreview] = useState<boolean>(false);
    const [lstContentTerm, setlstContentTerm] = useState([]);

    const onPreview = async (nID) => {
        let param = {
            nID,
        };

        let lstPreviewContent = [];
        let show = [];
        await AxiosPost(
            `HomeFront/getData_ContentDetail`,
            param,
            (res) => {
                ResultAPI(res, "", () => {
                    setlstContentTerm(res.contentShow);
                    show = res.contentShow;
                });
            },
            (err) => {
                if (!err.response) {
                    return;
                }
            }
        );
        show.forEach((f, i) => {
            const nType = f.nType;
            let sContent = f.sContent;
            let fileContent = f.sFilePath;
            let objViewContent = {
                nType: nType,
                sContent: sContent,
                sFileNameSys: fileContent,
            };
            lstPreviewContent.push(objViewContent);
        });
        setLstPreviewContent(lstPreviewContent);
        setIsShowPreview(true);
    };
    return (
        <>
            <CookieConsent
                location="bottom"
                buttonText={i18n(`cookie.cookieButton`)}
                cookieName="CookieConsentAccepted"
                style={{ background: "#0663a1" }}
                buttonStyle={{ color: "#4e503b", fontSize: "14px", borderRadius: "20px" }}
                expires={150}
            >
                {i18n(`cookie.text`)}
                <span style={{ color: "#ffd42d", textDecoration: "underline", cursor: "pointer" }} onClick={() => onPreview(3)}>{i18n(`cookie.policy`)}</span>
            </CookieConsent>

            <Dialog
                fullScreen
                open={isShowPreview}
                onClose={() => setIsShowPreview(false)}
            >
                <AppBar sx={{ position: "relative", marginBottom: "3rem" }}>
                    <Toolbar>
                        <IconButton
                            edge="end"
                            color="inherit"
                            onClick={() => setIsShowPreview(false)}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <Grid container>
                    {/* <Grid item sx={{ mx: "25%" }}> */}
                    <Grid xs={12} item sx={{ mx: "11%" }}>
                        <ContentPage lstContent={lstContent} OrderEmp={false} sContendHead={undefined}/>
                    </Grid>
                </Grid>
            </Dialog>
        </>
    );
}
export default CookieCondition;