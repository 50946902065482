// import React from 'react'
import { Button, Hidden, Typography, Box, Avatar, Stack, Menu, Modal, Tooltip, Grid } from "@mui/material"
import { Settings, ArrowDropUp, ArrowDropDown, RestartAlt } from "@mui/icons-material"
import { styleX, BtnLogout, BtnSetting, BtnCustomIcon } from "components/Common/Button"
import { useNavigate } from 'react-router-dom'
import { FormProvider } from "react-hook-form";
import secureLocalStorage from "react-secure-storage";
export default function Profile_Layout(props: { refAuthBox, handleClick, anchorEl, openMenu, handleClose, widthAccount, logOut, lang }) {
    const navigate = useNavigate();
    const { refAuthBox, handleClick, anchorEl, openMenu, handleClose, widthAccount, logOut, lang } = props;
    return (
        <Box ref={refAuthBox}>
            {/* phone size */}
            <Hidden smUp>
                <Stack direction={"row"}>
                    <Stack justifyContent="center" alignItems="flex-start">
                        <Avatar sx={{ bgcolor: "#304FFE" }}>{lang == "th" ? secureLocalStorage.getItem("sUserFullNameTH").toString().substring(0, 1).toLocaleUpperCase() : secureLocalStorage.getItem("sUserFullNameEN").toString().substring(0, 1).toLocaleUpperCase()}</Avatar>
                    </Stack>
                    {/* <Stack direction="column" justifyContent="center" alignItems="flex-start">
                        <Typography id="MenuFont" sx={{ color: "black", fontSize: 18, lineHeight: 1, textTransform: "none", mx: 1, wordBreak: "break-word" }} variant="body1" component="b">
                            {lang == "th" ? secureLocalStorage.getItem("sUserFullNameTH") : secureLocalStorage.getItem("sUserFullNameEN")}
                        </Typography>
                    </Stack> */}
                </Stack>
            </Hidden>

            {/* normal size */}
            <Hidden smDown>
                <Tooltip title={lang == "th" ? secureLocalStorage.getItem("sUserFullNameTH") : secureLocalStorage.getItem("sUserFullNameEN")}>
                    <Stack direction={"row"} sx={{ minWidth: "100px", maxWidth: "350px", ml: "3%", whiteSpace: 'nowrap', }}>
                        <Stack justifyContent="center" alignItems="flex-start">
                            <Avatar sx={{ bgcolor: "#304FFE" }}>{lang == "th" ? secureLocalStorage.getItem("sUserFullNameTH").toString().substring(0, 1).toLocaleUpperCase() : secureLocalStorage.getItem("sUserFullNameEN").toString().substring(0, 1).toLocaleUpperCase()}</Avatar>
                        </Stack>
                        <Stack direction="column" justifyContent="center" alignItems="flex-start">
                            <Typography id="MenuFont" sx={{ color: "black", fontSize: 18, lineHeight: 1, textTransform: "none", mr: 2, ml: 1, overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: "170px" }} variant="body1" component="b">
                                {lang == "th" ? secureLocalStorage.getItem("sUserFullNameTH") : secureLocalStorage.getItem("sUserFullNameEN")}
                            </Typography>
                        </Stack>
                    </Stack>
                </Tooltip>
            </Hidden>

            <Menu
                anchorEl={anchorEl}
                open={openMenu}
                onClose={handleClose}
                sx={{
                    "> .MuiPaper-root": {
                        backgroundColor: "transparent"
                        , boxShadow: "none", border: "none", width: widthAccount
                    }
                }}
            >
                <Stack direction="column" alignItems="flex-end" spacing={2} sx={{ p: 2 }}>
                    <BtnCustomIcon
                        tooltipPlacement="left"
                        sxCustom={styleX.violetColor} sIconComponent={<RestartAlt />} isCircleWithOutText txt={"Reset Password"} onClick={() => {
                        }} />
                    <BtnSetting isCircleWithOutText
                        tooltipPlacement="left"
                        onClick={() => {
                            navigate("/AdminTable_BPS");
                        }} />
                    <BtnLogout
                        tooltipPlacement="left"
                        isCircleWithOutText onClick={() => logOut()}
                    />
                </Stack>
            </Menu>
        </Box >
    )
}
