import Layout_Font from "layout/CommonLayout/Layout_Font";
import SearchJob from "view/font/SearchJob";
import BoxJobItem from "components/SearchJob/BoxJobItem";
import BuyServicesBPS from "view/font/ServicesBPS/BuyServicesBPS";
import ServicesBPS from "view/font/ServicesBPS/ServicesBPS";
const SearchJobRoute = [
    {
      path: "/SearchJob",
      component: SearchJob,
      layout: Layout_Font,
      exact: true,
    },
    {
      path: "/BoxSearchJobEx",
      component: BoxJobItem,
      layout: Layout_Font,
      exact: true,
    },
    {
      path: "/ServicesBPS",
      component: ServicesBPS,
      layout: Layout_Font,
      exact: true,
    },
    {
      path: "/BuyServicesBPS",
      component: BuyServicesBPS,
      layout: Layout_Font,
      exact: true,
    },
  ];
  
  export default SearchJobRoute;