import { Box } from '@mui/material';
import ProfileProvider from 'components/SeekerProfile/Context/ProfileContext';
import MyProfile from 'components/SeekerProfile/MyProfile';
import MyProfileEdit from 'components/SeekerProfile/MyProfileEdit';
import React from 'react';
import { Helmet } from 'react-helmet';

export default function BoxContentProfileEdit() {
  return (
    <>
      <Box style={{ display: "none !important", visibility: "hidden" }}>
        <Helmet>
          <title> ปรับปรุงประวัติ </title>
        </Helmet>
      </Box>
      <ProfileProvider>
        <Box sx={{ width: '100%' }}>
          <MyProfileEdit />
        </Box>
      </ProfileProvider>
    </>
  )
}