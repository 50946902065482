import { useEffect, useState } from "react";
import * as yup from "yup";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Grid,
  Typography,
  FormControlLabel,
  Checkbox,
  FormControl,
  InputAdornment,
  FormGroup,
  FormHelperText,
  Dialog,
  Toolbar,
  AppBar,
  IconButton,
  Divider,
  Button,
  ButtonProps,
  TextField,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { i18n } from "i18n";
import yupFormSchemas, { RegexpPassword } from "components/Common/yup/yupFormSchemas";
import Input from "components/Common/ElementInForm/InputCustomLabelFormItem";
import { AxiosPost, ResultAPI } from "service/CommonFunction/TS_function";
import { DialogActionCreators } from "store/redux/DialogAlert";
import ContentPage from "view/font/Home/ContentPage";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { objContent } from "components/Banner/AdminContent/ManageContent";
import { blue } from "@mui/material/colors";
import CloseIcon from "@mui/icons-material/Close";
import moment, { now } from "moment";

interface saveData {
  sEmpName: string;
  sPW: string;
  sTel: string;
  sEmpEmail: string;
  isGetNews: boolean;
  sCompanyID: string;
}

const MemberRegister = () => {
  const i18nField = "RegisterEmployer";
  const i18nFieldCommon = "common";
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const searchParams = location.search;
  const sID = new URLSearchParams(searchParams).get('sID');
  const sEmpEmail = new URLSearchParams(searchParams).get('sEmpEmail');

  const [showPassword, setShowPassword] = useState(false);
  const [showCPassword, setShowCPassword] = useState(false);

  const [isCheckedConsent1, SetIsCheckedConsent1] = useState<boolean>(true);
  const [isCheckedConsent2, SetIsCheckedConsent2] = useState<boolean>(true);
  const [isShowPreview, setIsShowPreview] = useState<boolean>(false);
  const [errorConsent, setErrorConsent] = useState(false);
  const [lstContent, setLstPreviewContent] = useState<objContent[]>([]);
  const [lstContentTerm, setlstContentTerm] = useState([]);
  const [sCompanyName, setCompanyName] = useState("");
  const [sEmail, setEmail] = useState("");


  const ButtonBlueRadius1 = styled(Button)<ButtonProps>(({ theme }) => ({
    color: theme.palette.getContrastText(blue[700]),
    backgroundColor: blue['A400'],
    width: "250px",
    height: "45px",
    fontSize: "18px",
    borderRadius: "60px",
    '&:hover': {
      backgroundColor: blue['A700'],
    },
  }));

  //Yup validate
  const objSchema = {
    sEmpName: yupFormSchemas.string(i18n(`${i18nField}.RegisterName`), {
      required: true,
    }),
    sTel: yupFormSchemas.string(i18n(`${i18nField}.phonenumber`), {
      required: false,
    }),
    sPW: yupFormSchemas.string(i18n(`${i18nField}.UserSecureCode`), {
      required: true,
      matches: RegexpPassword(),
      labelmatches: i18n("validation.string.sSecureCode"),
    }),
    sConfirmPW: yupFormSchemas
      .string(i18n(`${i18nField}.ConfirmSecureCode`), {
        required: true,
      })
      .oneOf([yup.ref("sPW")], i18n(`${i18nField}.UserSecureCodeIncorrect`)),
  };

  //Yup object
  const schema = yup.object().shape(objSchema);

  //schema to From
  const form = useForm({
    resolver: yupResolver(schema),
    shouldUnregister: false,
    shouldFocusError: true,
    mode: "all",
  });

  useEffect(() => {
    OnGetData();
  }, [])

  const OnGetData = () => {
    let obj = {
      sID: sID,
      sEmpEmail: sEmpEmail
    }

    // AxiosPost("CompanyEmployee/Getdata_CompanyEmployee", obj, (res) => {
    AxiosPost("CompanyEmployee/Getdata_EmployeeforRegister", { ...obj }, (res) => {
      // if (isEditMode) {
      let resData = res.objDefaultData;

      setCompanyName(resData.sCompanyName);
      setEmail(resData.sEmpEmail)
      //   setPostJob(resData.isPostJob);
      //   setJobSeeker(resData.isJobSeeker);
      //   setSearchJobSeeker(resData.isSearchJobSeeker);
      //   setOrder(resData.isOrder);
      //   setSelectRole(resData.nRole)
      // }
    },
      (err) => {
        // if (!err.response) {
        //   navigate("/error", null);
        //   return;
        // }
      }
    );
  };

  const onPreview = async (nID) => {
    let param = {
      nID,
    };

    let lstPreviewContent = [];
    let show = [];
    await AxiosPost(
      `HomeFront/getData_ContentDetail`,
      param,
      (res) => {
        ResultAPI(res, "", () => {
          setlstContentTerm(res.contentShow);
          show = res.contentShow;
        });
      },
      (err) => {
        if (!err.response) {
          return;
        }
      }
    );
    show.forEach((f) => {
      const nType = f.nType;
      let sContent = f.sContent;
      let fileContent = f.sFilePath;
      let objViewContent = {
        nType: nType,
        sContent: sContent,
        sFileNameSys: fileContent,
      };
      lstPreviewContent.push(objViewContent);
    });
    setLstPreviewContent(lstPreviewContent);
    setIsShowPreview(true);
  };

  //Submit data
  const onSubmit = (e) => {
    if (isCheckedConsent2) {
      dispatch(
        DialogActionCreators.OpenDialogSubmit(i18n(`${i18nFieldCommon}.AlertConfrimSave`), () => { //Do you want to save
          let objData: saveData = {
            sEmpName: e["sEmpName"],
            sCompanyID: sID,
            sTel: e["sTel"],
            // sCompany_Name: e["sCompany_Name"],
            sPW: e["sPW"],
            sEmpEmail: sEmail,//"testRegister@email.com",
            isGetNews: isCheckedConsent1
          };

          AxiosPost(
            "CompanyEmployee/SaveData_EmployeeforRegister",
            { ...objData },
            (result) => {
              ResultAPI(result, i18n(`${i18nFieldCommon}.msgAlertSuccess`), () => { //Saved successfully 
                dispatch(
                  DialogActionCreators.OpenDialogSuccess(
                    i18n(`${i18nFieldCommon}.msgAlertSuccess`) //Saved successfully 
                  ) as any
                );
                onBack();
              });
            },
            (err) => {
              if (err.Status === 409) {
                dispatch(DialogActionCreators.OpenDialogWarning(
                  i18n(`${i18nFieldCommon}.msAlerlRegister`), () => {
                  }) as any)
                // (err) => {
                //   if (err.Message != "") {
                //     dispatch(
                //       DialogActionCreators.OpenDialogWarning(
                //         err.Message
                //       ) as any
                //   );
              }
            }
          );
        }) as any
      );
    } else {
      setErrorConsent(true);
    }
  };

  const onBack = () => {
    navigate("/");
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  return (
    <FormProvider {...form}>
      <Grid container spacing={2}>
        <Grid item container xs={12}>
          <Grid item md={12}>
            <Typography variant="subtitle1">
              {i18n(`${i18nField}.RegisterNewEmployee`)}: {sCompanyName}{/*+  company name */}
              <Divider />
            </Typography>
          </Grid>
        </Grid>

        <Grid item md={12} xs={12}>
          <TextField
            name={"sEmpEmail"}
            // labelString={i18n(`${i18nField}.Email`)}
            // required
            fullWidth
            disabled
            label={
              <>
                <Typography sx={{ fontWeight: 400 }} component="label">
                  {i18n(`${i18nField}.Email`)}
                </Typography>
              </>
            }
            value={sEmail}
            sx={{
              top: "0",
              " > label": {
                "::after": {
                  color: "red",
                  fontWeight: 500,
                },
              },
              color: "",
              height: "5%"
            }}
          />
        </Grid>

        <Grid item md={12} xs={12}>
          <Input
            name={"sEmpName"}
            labelString={i18n(`${i18nField}.RegisterName`)}
            required
            fullWidth
            label={
              <>
                <Typography sx={{ fontWeight: 400 }} component="label">
                  {i18n(`${i18nField}.RegisterName`)}
                </Typography>
              </>
            }
          />
        </Grid>

        <Grid item md={12} xs={12}>
          <Input
            name={"sTel"}
            labelString={i18n(`${i18nField}.phonenumber`)}
            fullWidth
            label={
              <>
                <Typography sx={{ fontWeight: 400 }} component="label">
                  {i18n(`${i18nField}.phonenumber`)}
                </Typography>
              </>
            }
          />
        </Grid>

        <Grid item md={12} xs={12}>
          <Input
            name={"sPW"}
            labelString={i18n(`${i18nField}.UserSecureCode`)}
            required
            fullWidth
            type={showPassword ? "text" : "password"}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  edge="end"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            label={
              <>
                <Typography sx={{ fontWeight: 400 }} component="label">
                  {i18n(`${i18nField}.UserSecureCode`)}
                </Typography>
              </>
            }
          />
        </Grid>

        <Grid item md={12} xs={12}>
          <Input
            name={"sConfirmPW"}
            labelString={i18n(`${i18nField}.ConfirmSecureCode`)}
            required
            fullWidth
            type={showCPassword ? "text" : "password"}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle p0assword visibility"
                  edge="end"
                  onClick={() => setShowCPassword(!showCPassword)}
                >
                  {showCPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            label={
              <>
                <Typography sx={{ fontWeight: 400 }} component="label">
                  {i18n(`${i18nField}.ConfirmSecureCode`)}
                </Typography>
              </>
            }
          />
        </Grid>

        <Grid item md={12} xs={12}>
          <FormControl required error={errorConsent} component="fieldset" variant="standard" >
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isCheckedConsent1}
                    onChange={() => {
                      SetIsCheckedConsent1((prev) => !prev);
                      if (isCheckedConsent2) {
                        setErrorConsent(false)
                      }
                    }}
                  />
                }
                label={i18n(`${i18nField}.ConfirmRegisDoc`)}//"ต้องการรับข่าวสาร และข้อมูลที่เกี่ยวข้องต่างๆ"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isCheckedConsent2}
                    onChange={() => {
                      SetIsCheckedConsent2((prev) => !prev);
                      if (isCheckedConsent2) {
                        setErrorConsent(false)
                      }
                    }}
                  />
                }
                label={i18n(`${i18nField}.ConfirmRegis`)}//"ข้าพเจ้าขอยอมรับทุกข้อกำหนดและเงื่อนไขต่างๆที่ BPS กำหนด"
              />
            </FormGroup>
            <Typography sx={{ color: "#b2b2b2", fontSize: "14px", pt: "15px" }}>
              {i18n(`${i18nField}.Policy`)}{" "}

              <span
                style={{ color: "#2979ff", cursor: "pointer" }}
                onClick={() => onPreview(3)}
              >
                {i18n(`${i18nField}.Policy2`)}{" "}
              </span>

              {i18n(`${i18nField}.Policy3`)}{" "}

              <span
                style={{ color: "#2979ff", cursor: "pointer" }}
                onClick={() => onPreview(1)}
              >
                {i18n(`${i18nField}.Policy4`)}{" "}
              </span>

              {i18n(`${i18nField}.Policy5`)}
            </Typography>
            {errorConsent ? (<FormHelperText>{i18n(`${i18nField}.ErrorConsent`)}</FormHelperText>) : null}
          </FormControl>
        </Grid>

        {/* Button */}
        <Grid item xs={12}>
          <Grid container spacing={1} sx={{ justifyContent: "center" }}>
            <Grid item>
              <ButtonBlueRadius1 onClick={form.handleSubmit((e) => {
                onSubmit(e);
              })}>{i18n(`${i18nField}.BtnRegister`)}</ButtonBlueRadius1>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Dialog
        fullScreen
        open={isShowPreview}
        onClose={() => setIsShowPreview(false)}
      >
        <AppBar sx={{ position: "relative", marginBottom: "3rem" }}>
          <Toolbar>
            <IconButton
              edge="end"
              color="inherit"
              onClick={() => setIsShowPreview(false)}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Grid container>
          <Grid xs={12} item sx={{ mx: "11%" }}>
            <ContentPage lstContent={lstContent} OrderEmp={false} sContendHead={undefined} />
          </Grid>
        </Grid>
      </Dialog>
    </FormProvider>
  );
};

export default MemberRegister;