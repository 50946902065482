import { useState, useEffect } from 'react';
import { Autocomplete, FormControlLabel, Grid, TextField, Typography } from '@mui/material';
import { useNavigate, useLocation } from "react-router-dom";
import { BtnBack, BtnSave } from 'components/Common/Button';
import { AxiosGet, AxiosPost, GetPermission, ResultAPI } from 'service/CommonFunction/TS_function';
import { DialogActionCreators } from 'store/redux/DialogAlert';
import { useDispatch } from "react-redux"
import { FormProvider, useForm } from 'react-hook-form';
import Input from "components/Common/ElementInForm/InputCustomLabelFormItem";
import InputCustomeLabel from "components/Common/ElementInForm/InputCustomLabelFormItem";
import yupFormSchemas from 'components/Common/yup/yupFormSchemas';
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import IOSSwitch from 'components/Common/ElementInForm/IosSwitch';
import { i18n } from 'i18n';
import AutoCompleteSelect from "components/Common/ElementInForm/AutoCompleteSelect";

interface saveAdminData {
    sID?: string,
    nDataType_ID: number,
    sNameTH: string,
    sNameENG: string,
    sDescription: string,
    isActive: boolean,
    nMenuID: number,
    nHeader: number
}

export default function Admin_MasterDataAdd() {
    const i18nField = 'entities.AdminMaster';
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const [arrDataType, setarrDataType] = useState([]);
    const [arrDataTypeChild, setarrDataTypeChild] = useState([]);
    const searchParams = location.search;
    const sID = new URLSearchParams(searchParams).get('sID');
    const isEditMode = sID ? true : false;
    const [isActive, setIsActive] = useState(true);
    const [nDataType_ID, setnDataType_ID] = useState(0);
    const [nPermission, setPermission] = useState(0);

    const [objSchema, setObjSchema] = useState({
        sNameTH: yupFormSchemas.string("ชื่อ (TH)", { required: true, isAdmin: true }),
        sNameENG: yupFormSchemas.string("ชื่อ (ENG)", { required: true, isAdmin: true }),
        // sDataTypeName: yupFormSchemas.object(i18n(`${i18nField}.Datatype`), { required: true })
        sDataTypeName: yupFormSchemas.object("ประเภทข้อมูล", { required: true, isAdmin: true })
    });
    const schema = yup.object().shape(objSchema);
    const form = useForm({
        resolver: yupResolver(schema),
        shouldUnregister: false,
        shouldFocusError: true,
        mode: "all"
    });

    useEffect(() => {
        GetPermission(12, setPermission);
        onGetDropdown();
    }, [])

    const onGetDropdown = () => {
        AxiosGet(`AdminBPS/SetFilter_SelectOption`, {}, (res) => {
            setarrDataType(res.lstOptionAdmin || []);

            onGetDropdownChild();


        }
        );
    };

    const onGetDropdownChild = () => {
        AxiosGet(`AdminBPS/SetFilter_SelectOptionChild`, {}, (res) => {
            setarrDataTypeChild(res.lstOptionAdmin || []);
            onGetDetail();
        }
        );
    };

    const onGetDetail = () => {
        AxiosPost("AdminBPS/GetDetail_AdminDataType", { sID: sID }, (res) => {
            ResultAPI(res, "", () => {
                if (isEditMode) {
                    let resData = res.objData;

                    form.setValue("sNameTH", resData.sNameTH);
                    form.setValue("sNameENG", resData.sNameENG);
                    form.setValue("sDescription", resData.sDescription);
                    setIsActive(resData.isActive || false);
                    form.setValue("sDataTypeName", resData.objDataTypeOption);// dropdown 1
                    form.setValue("sDataTypeNameChild", resData.objDataTypeOptionChild); // dropdown 2
                    setnDataType_ID(resData.objDataTypeOption.value);
                }
            });
        });
    }

    const onSubmit = (e) => {
        dispatch(DialogActionCreators.OpenDialogSubmit("คุณต้องการบันทึกข้อมูลหรือไม่ ?", () => {
            dispatch(DialogActionCreators.CloseDialogSubmit() as any);

            let objSaveData: saveAdminData = {
                sNameTH: e['sNameTH'],
                sNameENG: e['sNameENG'],
                sDescription: e['sDescription'],
                sID: isEditMode ? sID : "",
                nDataType_ID: +(form.getValues("sDataTypeName").value),
                // nHeader: [6, 8].includes(nDataType_ID) ? +(form.getValues("sDataTypeNameChild").value) : 0,
                nHeader: nDataType_ID == 6 || nDataType_ID == 8 ? +(form.getValues("sDataTypeNameChild").value) : 0,
                isActive: isActive,
                nMenuID: 1
            }
            AxiosPost("AdminBPS/SaveData_AdminDataType", objSaveData, (result) => {
                ResultAPI(result, "บันทึกข้อมูลสำเร็จ", () => {
                    dispatch(DialogActionCreators.OpenDialogSuccess("บันทึกข้อมูลสำเร็จ",
                        () => Back()
                    ) as any
                    );
                });
            }, (err) => {
                if (err.Status === 409) {
                    dispatch(DialogActionCreators.OpenDialogWarning("ข้อมูลนี้มีในระบบแล้วไม่สามารบันทึกได้", () => {
                    }) as any)
                }
            });
        }) as any);
    }
    const onBack = () => {

        navigate("/AdminTable_BPS");
    }
    const Back = () => {

        navigate("/AdminTable_BPS?sID=" + form.getValues("sDataTypeName").value);
    }

    return (
        <FormProvider {...form}>
            <Grid container spacing={2} sx={{ border: "1px #eeeeee solid", backgroundColor: "rgba(255,255,255,0.75)", borderRadius: 5, p: 2, flex: 1 }}>
                {/* dropdown 1 (งาน)*/}
                <Grid item xs={12} sx={{ m: 1 }}>
                    <AutoCompleteSelect
                        required
                        name={"sDataTypeName"}
                        label={"ประเภทข้อมูล (TH)"}
                        options={arrDataType}
                        onChange={(v) => {
                            setnDataType_ID(v.value)
                            form.setValue('sDataTypeName', v ? v : null)
                        }}
                        disabled={nPermission !== 2}
                    />
                </Grid>

                {/* dropdown 2 (กลุ่มงาน)*/}
                {nDataType_ID == 6 || nDataType_ID == 8 ?

                    <Grid item xs={12} sx={{ m: 1 }}>
                        <AutoCompleteSelect
                            required
                            name={'sDataTypeNameChild'}
                            label={"ประเภทข้อมูล (TH)"}
                            options={arrDataTypeChild.filter(f => f.sParent == nDataType_ID)}
                            onChange={(v) => {
                                form.setValue('sDataTypeNameChild', v ? v : null)
                            }}
                            disabled={nPermission !== 2}
                        />
                    </Grid>
                    : null
                }

                <Grid item xs={12} sx={{ m: 1 }}>
                    <Input
                        name={"sNameTH"}
                        fullWidth
                        required
                        maxLength={1000}
                        label={
                            <>
                                <Typography component="label">ชื่อ (TH)</Typography>
                            </>
                        }
                        disabled={nPermission !== 2}
                    />
                </Grid>

                <Grid item xs={12} sx={{ m: 1 }}>
                    <Input
                        name={"sNameENG"}
                        fullWidth
                        required
                        maxLength={1000}
                        label={
                            <>
                                <Typography component="label"> ชื่อ (ENG)</Typography>
                            </>
                        }
                        disabled={nPermission !== 2}
                    />
                </Grid>

                <Grid item xs={12} sx={{ m: 1 }}>
                    <InputCustomeLabel
                        name={"sDescription"}
                        labelString={"Description"}
                        fullWidth
                        maxLength={300}
                        multiline={true}
                        rows={3}
                        label={"หมายเหตุ"}
                        disabled={nPermission !== 2}
                    />
                </Grid>

                {/* <Grid item xs={12} sx={{ m: 1 }}>
                    <Divider />
                </Grid> */}

                {/* status */}
                <Grid item xs={12} sx={{ m: 1 }}>
                    <Typography>
                        <span style={{ fontWeight: 600 }}> {"สถานะ"} </span>
                        <span style={{ color: "red" }}> *</span>
                    </Typography>
                    <Typography>
                        <FormControlLabel
                            control={
                                <IOSSwitch
                                    sx={{ m: 1 }}
                                    onChange={() => setIsActive(!isActive)}
                                    checked={isActive}
                                    disabled={nPermission !== 2}
                                />
                            }
                            label={isActive ? "ใช้งาน" : "ไม่ใช้งาน"}
                        />
                    </Typography>
                </Grid>

                {/* button */}
                <Grid item xs={6} justifyContent={"start"}>
                    <BtnBack txt={'ย้อนกลับ'} onClick={() => onBack()} />
                </Grid>
                <Grid item container xs={6} justifyContent={"end"}>
                    <BtnSave txt={'บันทึก'} IsDisabled={nPermission !== 2}
                        onClick={form.handleSubmit((e) => { //form.hamdleSubmit => check data
                            onSubmit(e)
                        })}
                    />
                </Grid>
            </Grid>
        </FormProvider>
    );
}