import React, { useState, useEffect } from 'react';
import {
    Box, Stack, Typography,
    SxProps, CardMedia, Container,
    ImageList, ImageListItem, Grid
} from "@mui/material"
import { BtnBack } from "components/Common/Button";
import { useNavigate } from "react-router-dom";
import ModalViewImage from "components/Common/ModalViewImage/ModalViewImage"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookSquare, faLine } from '@fortawesome/free-brands-svg-icons';
import MailOutlinedIcon from '@mui/icons-material/Mail';
import { AxiosPost, ResultAPI } from 'service/CommonFunction/TS_function';
import { objContent } from '../SetupTemplate/ManageContent';

const objStyle = {
    w_600: {
        fontWeight: 600,
    } as SxProps,
    w_500: {
        fontWeight: 500,
    } as SxProps,
    fs_12: {
        marginTop: "1em",
        fontWeight: 400,
    } as SxProps,
    text_date: {
        color: "#1ca5fc",
        fontSize: "0.8em",
    } as SxProps,
    image_cover: {
        display: "flex", flex: 1, minHeight: 400, backgroundSize: "cover"
    } as SxProps,
    container: {
        " > div": {
            marginTop: "2rem",
        }
    } as SxProps,
    youtube_style: {
        width: "60vw;",
        height: "calc((60vw)*9/16);",
        maxHeight: "100vw",
        maxWidth: "100%",
        " @media (max-width:767px) ": {
            width: "80vw;",
            height: "calc((80vw)*9/16);",
        }
    } as SxProps,
    row_tag: {
        padding: "0px 1rem",
        marginTop: "5px"
    } as SxProps,
    img_list: {
        minWidth: "20vw",
        maxWidth: "30vw",
        minHeight: "85%",
        maxHeight: "25vh",
        padding: "5px 10px",
        paddingTop: "10px",
        textAlign: "center",
        display: 'flex',
        justifyContent: "center",
        " @media (max-width:1100px) ": {
            minWidth: "15vw",
            minHeight: "90%",
            maxWidth: "25vw",
        },
        " @media (max-width:767px) ": {
            minWidth: "30vw",
            minHeight: "90%",
            maxWidth: "50vw",
        },
    } as SxProps
}

const ConditionOrder = ({ isCanBack = false }) => {
    let history = useNavigate();
    const [isViewImage, setIsViewImage] = useState(false);
    const [imageView, setImageView] = useState("");
    const [isShowPreview, setIsShowPreview] = useState<boolean>(false);
    const [lstContentTerm, setlstContentTerm] = useState([]);

    return (
        <>
            <ModalViewImage
                image={imageView}
                isOpen={isViewImage}
                handleClose={() => setIsViewImage(false)}
            />

            <Grid container direction="column" justifyContent="space-between" alignItems="center">
                <Grid item md={12} sm={12} xs={12}>
                    <Grid container
                    // sx={{
                    //     padding: "2em", 
                    //     position: "relative",

                    // }}
                    >

                    </Grid>
                    <Grid container direction="column" justifyContent="space-between"
                        sx={{
                            padding: "3em",
                            position: "relative",

                        }}
                    >
                        {/* <Container sx={objStyle.container}>
                            {lstContent.map((item, key) => {
                                switch (item.nType) {
                                    case 1:
                                        return (
                                            <Stack key={`type_1_${key}`} direction="row">
                                                <div dangerouslySetInnerHTML={{ __html: item.sContent }} />
                                            </Stack>
                                        )
                                    case 2:
                                        return (
                                            <Stack key={`type_2_${key}`} direction="row" >
                                                <Stack direction="column" alignItems="center" flex={1}>
                                                    <CardMedia onClick={() => {
                                                        setImageView(item.sFileNameSys)
                                                        setIsViewImage(true)
                                                    }} component={"img"} sx={{
                                                        objectFit: "contain",
                                                        width: "auto",
                                                        maxWidth: "100%",
                                                        maxHeight: "calc(90vh - 45px)",
                                                        cursor: "pointer"
                                                    }} src={item.sFileNameSys} />
                                                    <Typography sx={objStyle.fs_12} variant="body1" >{item.sContent}</Typography>
                                                </Stack>
                                            </Stack>
                                        )
                                }
                            })}
                        </Container> */}

                        <Grid item md={10} sm={10} xs={10}>
                            <h1 style={{ textDecoration: "underline", textAlign: "left" }}>Terms & Conditions</h1>
                        </Grid>
                        <Grid item md={10} sm={10} xs={10}>
                            <p style={{ marginBottom: "1%" }}>
                                a. Each job posting will be advertised on BPS Website for 30 days.If job advertisements / packages will not be activated by clients after purchasing within 3 months, the period of package will be begun after overdue date 3 months from the date of purchasing.
                                (แต่ละตำแหน่งที่ประกาศงานจะโฆษณาออนไลน์บนเว็บไซต์ของ BPS 30 วัน หากลูกค้าไม่ดำเนินการประกาศโฆษณางานหลังจากที่ซื้อบริการตามแพ็กเกจไปภายใน 3 เดือน แพ็กเกจนั้นจะเริ่มนับเวลาหลังจาก3 เดือนนับถัดจากวันที่ทำการซื้อ)
                            </p>

                            <p style={{ marginBottom: "1%" }}>
                                b. The advertisement may be withdrawn or eliminated from BPS Website due to the breach of conditions and agreement or incompletion of any payment. (การประกาศโฆษณาอาจจะได้รับการถอดถอนจากเว็บไซต์ BPS หากว่ามีการละเมิดเงื่อนไขและข้อตกลง หรือยังไม่ชำระเงินค่าบริการให้ครบถ้วน)
                            </p>

                            <p style={{ marginBottom: "1%" }}>
                                c. BPS takes no responsibility for the accuracy of information provided by the applicants on the website. (BPS ไม่อาจรับผิดชอบต่อความถูกต้องของข้อมูลที่ผู้สมัครงานได้บันทึกไว้บนเว็บไซต์ของ BPS)
                            </p>

                            <p style={{ marginBottom: "1%" }}>
                                d. Deactivated, deleted  and unimplemented Job Ads and/or resumes not retrieved is/are not entitled to any refund or any extended period. (การยกเลิก การลบออก และการไม่ประกาศงาน และ/หรือไม่ประกาศประวัติการทำงานไม่ได้นำมาซึ่งการคืนเงินค่าบริการ หรือการต่ออายุการบริการใดๆ)
                            </p>

                            <p style={{ marginBottom: "1%" }}>
                                e. Should more than one plan have been simultaneously purchased, utilization will be deducted on the first end first out basis. (หากลูกค้าซื้อมากกว่า 1 แพ็กเกจในครั้งเดียว การนับการใช้งานแพ็กเกจจะหักจากแพ็กเกจแรกที่ใช้ก่อน)
                            </p>

                            <p style={{ marginBottom: "1%" }}>
                                f. BPS has the right to terminate any services and remove, modify or suspend any advertisements posted on the website without any notice and compensation or recourse in the event if the clients in breach of any provisions of the Privacy Statement and Terms and Conditions or incompletion of any payment. (BPS มีสิทธิ์ที่จะยกเลิกและนำออกในการบริการใดๆ ปรับเปลี่ยน ระงับการประกาศโฆษณาใดๆ บนเว็บไซต์โดยไม่จำเป็นต้องแจ้งให้ทราบ และไม่จำเป็นต้องชดใช้ค่าเสียหายใดๆ หากว่าลูกค้าละเมิดข้อความในการประกาศความเป็นส่วนบุคคล และเงื่อนไขและข้อกำหนด หรือชำระเงินค่าบริการไม่ครบถ้วน)
                            </p>

                            <p>
                                G. BPS hereby has been confirmed by clients that the clients have already read,understood, and accepted the Privacy Statement and Terms and Conditions appeared on BPS Website. (BPS ได้รับการยืนยันจากลูกค้าว่าท่านนั้นได้อ่าน เข้าใจ และยอมรับกับ ข้อกำหนดและเงื่อนไข รวมทั้งประกาศความเป็นส่วนบุคคลที่ปรากฏอยู่บนเว็บไซต์ BPS เป็นที่เรียบร้อยแล้ว)
                            </p>
                        </Grid>
                    </Grid>
                </Grid>


                <Grid container sx={{ backgroundColor: "#019dd4", marginTop: "7%" }}>
                    <Grid item md={12} sm={12} xs={12} sx={{ textAlign: "center", color: "white" }}>
                        Business Professional Solutions Recruitment Co.,Ltd. (BPS) 555/1 Energy Complex, Building A,10 th floor,Vibhavadi Rangsit Road,Chatuchak,Bangkok 10900 /Tel : +662 140 3158-60 or +662 140 3191-92
                    </Grid>

                    {/* Social Media */}
                    <Grid item md={12} sm={12} xs={12} sx={{ textAlign: "center", marginTop: "10px" }}>
                        <a
                            href="https://page.line.me/279yxnan?openQrModal=true/"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <FontAwesomeIcon
                                icon={faLine}
                                size="2x"
                                style={{ color: "white" }}
                            />
                            &nbsp;&nbsp;&nbsp;&nbsp;
                        </a>
                        <a
                            href="https://www.facebook.com/job.pttbps/"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <FontAwesomeIcon
                                icon={faFacebookSquare}
                                size="2x"
                                style={{ color: "white" }}
                            />
                            &nbsp;&nbsp;&nbsp;&nbsp;
                        </a>
                        <a
                            href="mailto:center_bps@bpscorporate.com"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <MailOutlinedIcon
                                style={{ color: "white", fontSize: 40, marginTop: "-15px" }}
                            />
                            &nbsp;&nbsp;&nbsp;&nbsp;
                        </a>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}
export default ConditionOrder;