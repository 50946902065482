export interface cDDL {
  value: string;
  label: string;
  nID: number;
}
export interface cButton {
  sTxt: string;
  sColor: string;
  sBackgroundColot: string;
  nEvent_ID: number;
  sClassName: string;
  sWidth: string;
}
export interface cT_Form {
  sBox_Name: string;
  sPatient_Name: string;
  sStartDate: string;
  sFinishDate: string;
  sStatus_Name: string;
  sEncrypt: string;
  nBox_ID: number;
  nForm_ID: number;
  nPatient_ID: number;
  dStartDate: Date;
  dFinishDate: Date;
  nCaseStatus_ID: number;
}
export interface cFile {
  nFile_ID: number;
  webkitRelativePath: string;
  sFolderName: string;
  sFileName: string;
  name: string;
  sSysFileName: string;
  nFileType_ID: number;
  size: number;
  nSize: number;
  sSizeName: string;
  type: string;
  dUploadBy: number;
  dUploadDate: Date;
  dSysncDate: Date;
  sPath: string;
  IsCompleted: boolean;
  IsProgress: boolean;
  lastModified: Date;
  lastModifiedDate: Date;
  IsNew: boolean;
  sInput1: string;
  sInput2: string;
  IsNewTab: boolean;
}

export enum JobPostSentGroupStatus {
  WaitingProcess = 0,
  Shortlist = 1,
  NotSuitable = 2,
}

export enum TabFilterJobseekerCandidate {
  Waiting_Apply = 0,
  Interested = 1,
  Buy_Profile = 2,
  Invited = 3,
  Allow_group = 4,
  Not_pass = 5
}

export enum JobPostSentFormTable {
  Invited = 1,
  JobApply = 2,
}