import { Grid, Box, Hidden, Skeleton } from "@mui/material";

export default function SkeletonJobTab() {
  return (
    <>
      {/* Desktop */}
      <Grid item md={4} sm={4} xs={12} sx={{ pb: 1 }}>
        <Box sx={{ height: "85vh", width: "100%" }}>
          <Skeleton  height={"100%"} width={"100%"} />
        </Box>
      </Grid>

      <Hidden smDown>
        <Grid item md={8} sm={8} xs={12} sx={{ pb: 1 }}>
          <Box sx={{ height: "85vh", width: "100%" }}>
            <Skeleton height={"100%"} width={"100%"} />
          </Box>
        </Grid>
      </Hidden>
    </>
  );
}
