import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Button, Grid, Stack, Typography } from '@mui/material';
import { i18n } from 'i18n';
import secureLocalStorage from 'react-secure-storage';
import { useNavigate } from 'react-router-dom';
import WorkIcon from '@mui/icons-material/Work';
import { useState, useEffect } from 'react';
import ModalServiceLogin from './ModalServiceLogin';
import ParseHTML from "html-react-parser";
import { AxiosPost } from 'service/CommonFunction/TS_function';
import MoldalConditions from './MoldalConditions';
import { FaCoins } from "react-icons/fa6";
import BoxAddress from './BoxAddress';


const ServiceTable = ({ nPackageTypeID }) => {
  const [isOpenModal, setOpenModal] = useState(false);
  const navigate = useNavigate();
  const i18nField = "OrderEmployer";
  const [arrPackageJopPost, setArrPackageJopPost] = useState([]);
  const [arrPackageButton, setArrPackageButton] = useState([]);
  const [isSelectAll, setisSelectAll] = useState(false);
  const [isShowPreview, setIsShowPreview] = useState(false);
  const [isEmployee, setisEmployee] = useState(false);

  const GetTablePackage = (isSelectAll) => {
    let param = {
      nTypeID: isSelectAll ? 0 : 1,
      nPackageTypeID: nPackageTypeID
    }
    AxiosPost(`EmployerServiceBPS/GetTablePackage`, param, (result) => {
      setArrPackageJopPost(result.lstPackage || []);
      setArrPackageButton(result.lstPackageButton || []);
      setisEmployee(result.isEmployee);
    });
  }

  const onBuy = (sID, nMode) => {
    navigate(`/ServicesBPS?sID=${sID}&nMode=${nMode}&nTypePackage=${nPackageTypeID}`);
  }

  useEffect(() => {
    GetTablePackage(isSelectAll);
  }, [isSelectAll])
  return (
    <>
      <div className="plan-Table" >
        <div className="inner-DetailPack ">
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            {nPackageTypeID == EnumPackageType.Jobpost ? (
              <Typography className='Table-fontsize' fontWeight={600} color={"#277cd8"}>{i18n(`${i18nField}.JobPostPackage`)} <WorkIcon className='Table-IconJopost' /></Typography>
            ) : (
              <Grid container spacing={3}>
                <Grid item >
                  <Typography className='Table-fontsize' fontWeight={600} color={"#277cd8"}>{i18n(`${i18nField}.CoinPackageService`)}</Typography>
                </Grid>
                <Grid item  >
                  <FaCoins  className='Table-iconfonsize' />
                </Grid>
              </Grid >
            )}
          </Grid>

          <TableContainer >
            <Table sx={{ minWidth: isEmployee ? 700 : 670 }} aria-label="table variants" >
              <TableHead >
                <TableRow   >
                  <TableCell style={{ color: "#dcdcdc", borderBottom: "5px solid" }}></TableCell>
                  <TableCell align="right" style={{ color: "#dcdcdc", borderLeft: "1px solid", borderRight: "1px solid", borderBottom: "5px solid" }} >
                    {nPackageTypeID == EnumPackageType.Jobpost ? (
                      <Typography color={"#1976db"}> {ParseHTML(i18n(`${i18nField}.JobPostingTable`))}</Typography>
                    ) : (
                      <Typography color={"#1976db"}> {ParseHTML(i18n(`${i18nField}.CoinTable`))}</Typography>
                    )}
                  </TableCell>
                  <TableCell align="right" style={{ color: "#dcdcdc", borderRight: "1px solid", borderBottom: "5px solid" }}> <Typography color={"#1976db"}>{ParseHTML(i18n(`${i18nField}.CoinsFreeTable`))}</Typography></TableCell>
                  <TableCell align="right" style={{ color: "#dcdcdc", borderRight: "1px solid", borderBottom: "5px solid" }}><Typography color={"#1976db"}>{ParseHTML(i18n(`${i18nField}.PeriodPackageTable`))}</Typography></TableCell>
                  {isEmployee && (<TableCell align="right" style={{ color: "#dcdcdc", borderRight: "1px solid", borderBottom: "5px solid" }}><Typography color={"#1976db"}>{ParseHTML(i18n(`${i18nField}.GrandTotalTable`))}</Typography></TableCell>)}
                  <TableCell style={{ color: "#dcdcdc", borderBottom: "5px solid" }}></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {arrPackageJopPost.map((row) => (
                  <TableRow key={row.sID}>
                    <TableCell component="th" scope="row" style={{ width: "200px", color: "#dcdcdc", borderBottom: "1px solid" }} >
                      <Typography color={"black"} >{row.sPackage_Name}</Typography>
                    </TableCell>
                    <TableCell align="center" style={{ width: "80px", color: "#dcdcdc", borderRight: "1px solid", borderLeft: "1px solid", borderBottom: "1px solid" }}><Typography color={"black"} >{row.sAmountPackage}</Typography></TableCell>
                    <TableCell align="center" style={{ width: "80px", color: "#dcdcdc", borderRight: "1px solid", borderBottom: "1px solid" }}><Typography color={"black"} >{row.sCoinsFree}</Typography></TableCell>
                    <TableCell align="center" style={{ width: "80px", color: "#dcdcdc", borderRight: "1px solid", borderBottom: "1px solid" }}><Typography color={"black"} >{row.nPeriodPackage}</Typography></TableCell>
                    {isEmployee && (<TableCell align="center" style={{ width: "80px", color: "#dcdcdc", borderRight: "1px solid", borderBottom: "1px solid" }}><Typography color={"black"} >{row.sTotalPrice}</Typography></TableCell>)}
                    <TableCell align="center" style={{ width: "120px", color: "#dcdcdc", borderBottom: "1px solid", borderRadius: "0px" }}> <div className="action-Table">
                      {isEmployee ? (
                        <Button variant="contained"
                          className={"button-Table"}
                          onClick={() => onBuy(row.sID, 2)}>
                          {i18n(`${i18nField}.BuyNow`)}
                        </Button>
                      ) : (
                        <Button variant="contained"
                          className={"button-Table"}
                          onClick={() => setOpenModal(true)}>
                          {i18n(`${i18nField}.Viewdetails`)}
                        </Button>
                      )}
                    </div></TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
            <Grid item xs={12}>
              <Stack
                sx={{ flex: 1, mt: "2%" }}
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start"
              >
                {nPackageTypeID == 1 ? (
                  <Typography className='Table-daysper' color={"#a5a6a6"}>{i18n(`${i18nField}.DaysPer`)}</Typography>
                ) : (
                  <Typography  className='Table-daysper' color={"#a5a6a6"}>{i18n(`${i18nField}.CoinDetailTable`)}</Typography>
                )}
                <Typography  className='Table-daysper' color={"#a5a6a6"}>{i18n(`${i18nField}.Remark`)}</Typography>
              </Stack>
            </Grid>
          

          <Grid item xs={12}>
            <Typography className='Table-cus' color={"#a5a6a6"}>{i18n(`${i18nField}.DetailTerms`)}   <a onClick={() => setIsShowPreview(true)} style={{ cursor: "pointer", textDecoration: "underline", color: "#249cdc" }} >{i18n(`${i18nField}.TermsConditions`)}</a> {i18n(`${i18nField}.website`)}</Typography>
          </Grid>

          {arrPackageButton.length > 5 && (
            <Grid container justifyContent={"center"} >
              <Grid item xs={12} md={5}>
                {isSelectAll ? (
                  <Button variant="contained" className="button-Table-All" onClick={() => { GetTablePackage(false); setisSelectAll(false) }}>{i18n(`${i18nField}.Seeless`)}</Button>
                ) :
                  (
                    <>
                      <Button variant="contained" className="button-Table-All" onClick={() => { GetTablePackage(true); setisSelectAll(true) }}>{i18n(`${i18nField}.Seemore`)}</Button>
                    </>
                  )
                }
              </Grid>
            </Grid>
          )}
        </div>
      </div >


      <BoxAddress />

      <ModalServiceLogin isOpenModal={isOpenModal} setOpenModal={setOpenModal} />
      <MoldalConditions nID={nPackageTypeID == EnumPackageType.Jobpost ? EnumPackageType.JobpostID : EnumPackageType.CoinID} isShowPreview={isShowPreview} setIsShowPreview={setIsShowPreview} />

    </>

  );
}
export default ServiceTable

export const enum EnumPackageType {
  Jobpost = 1,
  Coin = 2,
  Banner = 3,
  JobpostID = 4,
  CoinID = 5,
  BannerID = 6
}