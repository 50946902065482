import { Stack } from "@mui/material";
import AdminFreePackageTable from "../../../components/AdminWorkflow/AdminFreePackage/AdminFreePackage";

export default function AdminFreePackage() {
  return (

    <Stack sx={{ mx: "1%" }}>
      <AdminFreePackageTable />
    </Stack>

  );
}