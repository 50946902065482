/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-pascal-case */
import React, { useContext, useEffect, useRef, useState } from 'react';
import Profile_Layout from './Profile_Layout'
import Logo from "assets/images/BPS-Logo.png"
import { useLocation, useNavigate } from 'react-router-dom'
import { useDispatch } from "react-redux"
import { DialogActionCreators } from 'store/redux/DialogAlert'
import Menu_Layout from './Menu_Layout'
import { Box, Button, Divider, Popover, Stack, Typography, Tab, Tabs, Hidden, Grid, Modal, Tooltip } from '@mui/material';
import { AxiosPost, AzureFunction } from 'service/CommonFunction/TS_function';
import { FnBlock_UI } from 'service/SystemFunction/SystemFunction';
import LoginForm from 'view/font/Login/LoginForm';
import { i18n, setLanguageCode, getLanguage } from 'i18n';
import "./Layout_Front.css"
import { HubConnectionState } from '@microsoft/signalr';
import { ArrowBackIos } from '@mui/icons-material';
import MenuIcon from "@mui/icons-material/Menu";
import { BtnLogout } from "components/Common/Button"
import secureLocalStorage from 'react-secure-storage';
import Close from '@mui/icons-material/Close';
import Cookies from 'js-cookie';
import { ProfileContext } from 'components/SeekerProfile/Context/ProfileContext';

const styles = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  borderRadius: "10px",
  p: 2,
  minHeight: 150
};

export default function Header_Layout(props: { lstMenu, FocusMenu, setFocusMenu, connection, SetisLoadMenu, setOpenForgot, setIsFormJobSeeker, isFromJobSeeker, setIsMemberCompany, isMemberCompany }) {
  const i18nField = 'entities.LayoutFront';
  const i18nForthisPage = "home";
  const i18nCommon = 'common';
  const i18nLabel = "SetupTemplate.label";

  const { lstMenu, FocusMenu, setFocusMenu, connection, SetisLoadMenu, setOpenForgot, setIsFormJobSeeker, isFromJobSeeker, setIsMemberCompany, isMemberCompany } = props;

  const navigate = useNavigate();
  const dispatchRedux = useDispatch();
  const location = useLocation();
  const refAuthBox = useRef();
  const [isLanguageChanges, setLanguage] = useState<boolean>(false);
  const { BlockUI, UnBlockUI } = FnBlock_UI();
  const [isLogout, setLogout] = useState<boolean>(false);
  const [sUser, setUser] = useState<any>();
  const [isLoadingAuth, setIsLoadingAuth] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [widthAccount, setWidthAccount] = useState<number>(0);
  const [GroupUser, setGroupUser] = useState<number>(0);
  const [valueLoginTab, setValueLoginTab] = React.useState<number>(1);
  const [nLoginType, setLoginType] = React.useState<number>(0);
  const [valueRegistTab, setValueRegistTab] = React.useState<number>(1);
  const [nFocusIndex, setFocusIndex] = useState(null);
  const [isPathEqualRoute, setIsPathEqualRoute] = useState(false);
  const [lang, setLang] = useState("");
  const { LogoutAzure, LoginAzure, GetStateAzure, GetAccountAzure, SetConfigAzure } = AzureFunction();
  const [isOpenMenu, setOpenMenu] = useState(false);
  const [isOpenModal, setOpenModal] = useState(false); //modal buy package

  // const Logo = "https://www.jobsbps.com/static/media/BPS-Logo.png"; //logo bps

  const openMenu = Boolean(anchorEl);

  const AuthenController = "Authen";

  const [modalLoginOpen, setModalLoginOpen] = useState(false);
  const handleLoginOpen = () => setModalLoginOpen(true);
  const handleLoginClose = () => {
    setModalLoginOpen(false);
  };

  const [modalRegistOpen, setModalRegistOpen] = useState(false);
  const handleRegistOpen = () => setModalRegistOpen(true);
  const handleRegistClose = () => {
    setModalRegistOpen(false);
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setWidthAccount(event.currentTarget.clientWidth);
  };

  const checkTokenExpire = () => {
    let lang = secureLocalStorage.getItem("language");
    setLang(lang ? lang + "" : "");
    let sUserId = secureLocalStorage.getItem("sUserId");
    let GroupType = +(secureLocalStorage.getItem("nGroupType"));

    if (!sUserId) {
      setLogout(true);
      setFocusIndex(null);
    } else {
      AxiosPost(`${AuthenController}/CheckTokenExp`, {}, (res) => { //check token
        setUser(sUserId);
        setGroupUser(GroupType);
      },
        (err) => {
          // dispatchRedux(DialogActionCreators.OpenDialogWarning(
          //     "line 107 /"
          //     + " connectionID_bps: " + secureLocalStorage.getItem("connectionID_bps")
          //     + " / token: " + secureLocalStorage.getItem(process.env.REACT_APP_JWT_KEY as string)
          //     + " / isRemember: " + secureLocalStorage.getItem("isRemember")
          //     + " / sUserName: " + secureLocalStorage.getItem("sUserName")
          // ));

          dispatchRedux(DialogActionCreators.OpenDialogWarning(i18n(`common.SessionExpired`), () => { //Session expired, Please log in again

            navigate({ pathname: "/" }, { replace: true });
            _clearLocalStorageWithoutConnectId();
            setLogout(true);
            setFocusIndex(null);
            _sentSignOutHub();
          }) as any)
        }
      )
    }
  }

  useEffect(() => {
    let IsRemember = secureLocalStorage.getItem("isRemember");
    let IsRememberCookies = Cookies.get("isRemember");

    if (IsRemember == "1") {
      setLogout(false);

      let userlogin = {
        sUserName: secureLocalStorage.getItem("sUserName"),// || Cookies.get("sUserName"),
        sUserCode: "remember",
        nType: secureLocalStorage.getItem("nGroupType"),// || Cookies.get("nGroupType"),
      }

      let sUserName = secureLocalStorage.getItem("sUserName");
      let nType = secureLocalStorage.getItem("nGroupType");

      if (sUserName != null && nType != 0) {
        AxiosPost("Authen/NormalAuthen", userlogin, (res) => {
          // if (Cookies.get("isRemember") == "1") { secureLocalStorage.setItem("isRemember", "1"); Cookies.set("isRemember", "1"); }
          if (secureLocalStorage.getItem("isRemember") == "1") { secureLocalStorage.setItem("isRemember", "1"); }
          secureLocalStorage.setItem("sUserId", res.sUserKeys)
          secureLocalStorage.setItem("sUserFullNameEN", res.sFullNameEN)
          secureLocalStorage.setItem("sUserFullNameTH", res.sFullNameTH)
          secureLocalStorage.setItem("nGroupType", res.nGroupType)
          secureLocalStorage.setItem("sUserName", res.sUserName)
          secureLocalStorage.setItem(
            process.env.REACT_APP_JWT_KEY as string,
            res.sToken
          );
          secureLocalStorage.setItem("lstMenuAll", lstMenu);

          let sConnectionId = secureLocalStorage.getItem("connectionID_bps");
          if (connection.state != HubConnectionState.Connected) {
            connection
              .start()
              .then(() => {
                // if cannot find connection id
                if (!sConnectionId) {
                  secureLocalStorage.setItem("connectionID_bps", connection.connectionId);
                }
                connection.send("OnSignOutSignal", sConnectionId ?? connection.connectionId, true)
                // window.location.reload();
              })
              .catch((e) => {
                ////console.log("Connection failed");
              });
          }
          else {
            connection.send("OnSignOutSignal", sConnectionId ?? connection.connectionId, false)
          }

          if (secureLocalStorage.getItem("nGroupType") == 1) {
            // SetisLoadMenu(val => !val);
            SetisLoadMenu(true);
            // navigate("/");
          } else if (secureLocalStorage.getItem("nGroupType") == 2) {
            // SetisLoadMenu(val => !val);
            SetisLoadMenu(true);
            // navigate("/MyPageEmployer");
          } else {
            if (res.sPath) {
              // SetisLoadMenu(val => !val);
              SetisLoadMenu(true);
              navigate(res.sPath);
            } else {
              window.location.reload();
            }
          }
          checkTokenExpire();
        }, (err) => {
          if (err.Status === 409) {
            dispatchRedux(DialogActionCreators.OpenDialogWarning(
              i18n(`${i18nCommon}.msgAlertUsernameOrSecureCodeWrong`), () => { //This user was not found in the system
              }) as any)
          } else if (err.Status === 405) {
            dispatchRedux(DialogActionCreators.OpenDialogWarning(
              i18n(`${i18nCommon}.msAlertStatus`), () => { //Username or Password is Wrong
              }) as any)
          } else {
            // dispatchRedux(DialogActionCreators.OpenDialogWarning(
            //     "line 210 /"
            //     + " connectionID_bps: " + secureLocalStorage.getItem("connectionID_bps")
            //     + " / token: " + secureLocalStorage.getItem(process.env.REACT_APP_JWT_KEY as string)
            //     + " / isRemember: " + secureLocalStorage.getItem("isRemember")
            //     + " / sUserName: " + secureLocalStorage.getItem("sUserName")
            // ) as any)

            dispatchRedux(DialogActionCreators.OpenDialogWarning(err.Message) as any)
          }
        });
      }
    } else if (IsRememberCookies == "1") {
      setLogout(false);

      let userlogin = {
        sUserName: Cookies.get("sUserName"),
        sUserCode: "remember",
        nType: Cookies.get("nGroupType"),
      }

      let sUserName = Cookies.get("sUserName");
      let nType = Cookies.get("nGroupType");

      if (sUserName != null && nType != 0) {
        AxiosPost("Authen/NormalAuthen", userlogin, (res) => {
          if (Cookies.get("isRemember") == "1") { Cookies.set("isRemember", "1"); }
          Cookies.set("sUserId", res.sUserKeys);
          Cookies.set("sUserFullNameEN", res.sFullNameEN);
          Cookies.set("sUserFullNameTH", res.sFullNameTH);
          Cookies.set("nGroupType", res.nGroupType);
          Cookies.set("sUserName", res.sUserName);
          Cookies.set(
            process.env.REACT_APP_JWT_KEY as string,
            res.sToken
          );

          let sConnectionId = Cookies.get("connectionID_bps");

          if (connection.state != HubConnectionState.Connected) {
            connection
              .start()
              .then(() => {
                // if cannot find connection id
                if (!sConnectionId) {
                  Cookies.set("connectionID_bps", connection.connectionId);
                }
                connection.send("OnSignOutSignal", sConnectionId ?? connection.connectionId, true)
                // window.location.reload();
              })
              .catch((e) => {
                ////console.log("Connection failed");
              });
          }
          else {
            connection.send("OnSignOutSignal", sConnectionId ?? connection.connectionId, false)
          }

          if (Cookies.get("nGroupType") == 1) {
            // if (secureLocalStorage.getItem("nGroupType") == 1) {
            // SetisLoadMenu(val => !val);
            SetisLoadMenu(true);
            //  navigate("/");
          } else if (Cookies.get("nGroupType") == 2) {
            // } else if (secureLocalStorage.getItem("nGroupType") == 2) {
            // SetisLoadMenu(val => !val);
            SetisLoadMenu(true);
            // navigate("/MyPageEmployer");
          } else {
            if (res.sPath) {
              // SetisLoadMenu(val => !val);
              SetisLoadMenu(true);
              navigate(res.sPath);
            } else {
              window.location.reload();
            }
          }
          checkTokenExpire();
        }, (err) => {
          if (err.Status === 409) {
            dispatchRedux(DialogActionCreators.OpenDialogWarning(
              i18n(`${i18nCommon}.msgAlertUsernameOrSecureCodeWrong`), () => { //This user was not found in the system
              }) as any)
          } else if (err.Status === 405) {
            dispatchRedux(DialogActionCreators.OpenDialogWarning(
              i18n(`${i18nCommon}.msAlertStatus`), () => { //Username or Password is Wrong
              }) as any)
          } else {
            // dispatchRedux(DialogActionCreators.OpenDialogWarning(
            //     "line 302 /"
            //     + " connectionID_bps: " + secureLocalStorage.getItem("connectionID_bps")
            //     + " / token: " + secureLocalStorage.getItem(process.env.REACT_APP_JWT_KEY as string)
            //     + " / isRemember: " + secureLocalStorage.getItem("isRemember")
            //     + " / sUserName: " + secureLocalStorage.getItem("sUserName")
            // ) as any)

            dispatchRedux(DialogActionCreators.OpenDialogWarning(err.Message) as any)
          }
        });
      }
    } else {
      if (connection.state != HubConnectionState.Connected) {
        connection
          .start()
          .then((result) => {
            connection.on("ReceiveLogin", (sConnectionId, isLogin) => {
              _onReceiveLoginHub(sConnectionId, isLogin)
              //เคลีย sessionStorage เก่าออก
              // secureLocalStorage.clear();
              secureLocalStorage.removeItem('isSignOut_bps');
              secureLocalStorage.removeItem('sUrlQrCode');
              secureLocalStorage.removeItem('isVerify');
            });
          })
          .catch((e) => {
            ////console.log("Connection failed");
          });
      } else {
        connection.on("ReceiveLogin", (sConnectionId, isLogin) => {
          _onReceiveLoginHub(sConnectionId, isLogin)
          //เคลีย sessionStorage เก่าออก
          // secureLocalStorage.clear();
          secureLocalStorage.removeItem('isSignOut_bps');
          secureLocalStorage.removeItem('sUrlQrCode');
          secureLocalStorage.removeItem('isVerify');
        });
      }
      checkTokenExpire();
    }
  }, [])

  useEffect(() => {
    // checkTokenExpire();
    // secureLocalStorage.getItem('lstMenuAll');
  }, [lstMenu])

  const _onReceiveLoginHub = (sConId, isLoginAgain) => {
    let sUserId = secureLocalStorage.getItem("sUserId");
    let IsRemember = secureLocalStorage.getItem("isRemember");

    if (sConId == secureLocalStorage.getItem("connectionID_bps") && secureLocalStorage.getItem("isSignOut_bps") != "1" && sUserId && IsRemember == "1") {
      if (isLoginAgain) {
        dispatchRedux(DialogActionCreators.CloseDialogWarning() as any);
        window.location.reload();
      } else {
        dispatchRedux(DialogActionCreators.OpenDialogWarning("กรุณาเข้าสู่ระบบอีกครั้ง", () => {
          SetisLoadMenu(true);
          setLogout(true);
          setFocusIndex(null);
          navigate("/")
        }) as any)
      }
    }
  }

  const BuyPackage = (type) => {
    //type 1 = regist | type 2 = login
    if (secureLocalStorage.getItem("connectionID_bps")) {
      if (secureLocalStorage.getItem("isSignOut_bps") && type == 1) {
        // BuyPackageNoLogin();                
        navigate(`/RegisterEmployer?sType=1`);
        setOpenModal(false)
      } else {
        // BuyPackageHasLogin();
        navigate('/OrderEmployer');
        setOpenModal(false)
      }
    }
    else {
      if (secureLocalStorage.getItem("isSignOut_bps")) {
        if (type == 1) {
          // navigate('/RegisterEmployer');
          navigate(`/RegisterEmployer?sType=1`);
          setOpenModal(false)
        } else {
          navigate('/LoginEmployerNewForm');
          setOpenModal(false)
        }

      } else {
        if (type == 1) {
          // navigate('/RegisterEmployer');
          navigate(`/RegisterEmployer?sType=1`);
          setOpenModal(false)
        } else {
          navigate('/LoginEmployerNewForm');
          setOpenModal(false)
        }
      }
    }
  }

  const _clearLocalStorageWithoutConnectId = () => {
    secureLocalStorage.removeItem("isBack");
    secureLocalStorage.removeItem("sUserId");
    secureLocalStorage.removeItem("sUserFullNameTH");
    secureLocalStorage.removeItem("sUserFullNameEN");
    secureLocalStorage.removeItem("nGroupType");
    secureLocalStorage.removeItem(process.env.REACT_APP_JWT_KEY as string);
    secureLocalStorage.removeItem("sFocusIndex");
    secureLocalStorage.removeItem("connectionID_bps");
    secureLocalStorage.removeItem('isRemember');
    secureLocalStorage.removeItem('sUserName');

    Cookies.remove("isBack");
    Cookies.remove("sUserId");
    Cookies.remove("sUserFullNameTH");
    Cookies.remove("sUserFullNameEN");
    Cookies.remove("nGroupType");
    Cookies.remove(process.env.REACT_APP_JWT_KEY as string);
    Cookies.remove("sFocusIndex");
    Cookies.remove("connectionID_bps");
    Cookies.remove("isRemember");
    Cookies.remove("lstMenu");
    Cookies.remove("sUserName");


    secureLocalStorage.removeItem("/AdminOrder");
    secureLocalStorage.removeItem("/AdminCompany");
    secureLocalStorage.removeItem("/ManagePromotion1");
    secureLocalStorage.removeItem("/ManagePromotion2");
    secureLocalStorage.removeItem("/ShowListPackage1");
    secureLocalStorage.removeItem("/ShowListPackage2");
    secureLocalStorage.removeItem("/AdminCandidateTable");
    secureLocalStorage.removeItem("/AdminFree");
    secureLocalStorage.removeItem("/ContentTable");
    secureLocalStorage.removeItem("/AdminHoldPackage");
    secureLocalStorage.removeItem("/GroupTable_BPS");
    secureLocalStorage.removeItem("/AdminSpecial");
    secureLocalStorage.removeItem("/AdminUserTable");
    secureLocalStorage.removeItem("/AdminFreePackageApprove");
    secureLocalStorage.removeItem("/AdminPausePackage");
    secureLocalStorage.removeItem("/AdminCandidate");
    secureLocalStorage.removeItem("/AdminCandidate1");
    secureLocalStorage.removeItem("/AdminCandidate2");
    secureLocalStorage.removeItem("/AdminCandidate3");
    secureLocalStorage.removeItem("/AdminCandidate4");
    secureLocalStorage.removeItem("/AdminCompanyJob");
    secureLocalStorage.removeItem("/AdminCompanyBanner");
    secureLocalStorage.removeItem("/AdminCompanyOrder");
    secureLocalStorage.removeItem("/AdminCompanyMember");
    secureLocalStorage.removeItem("/JobPostList");
    secureLocalStorage.removeItem("/OrderEmployer1");
    secureLocalStorage.removeItem("/MemberEmployeeTable");
  }

  const onSubmit = (e) => {
    BlockUI();
    handleLoginClose();
    let userlogin = {
      sUserName: e.f_username,
      sUserCode: e.f_usercode,
      isRemember: e.b_remember,
      nType: valueLoginTab,
    }


    AxiosPost("Authen/NormalAuthen", userlogin, (res) => {
      // secureLocalStorage.setItem("isSignOut_bps", "1");
      secureLocalStorage.removeItem("isSignOut_bps");
      // if (res.sUserKeys) {
      if (e.b_remember == "1") { secureLocalStorage.setItem("isRemember", "1"); Cookies.set("isRemember", "1"); }
      secureLocalStorage.setItem("sUserId", res.sUserKeys);
      secureLocalStorage.setItem("sUserFullNameEN", res.sFullNameEN);
      secureLocalStorage.setItem("sUserFullNameTH", res.sFullNameTH);
      secureLocalStorage.setItem("nGroupType", res.nGroupType);
      secureLocalStorage.setItem("sUserName", res.sUserName)

      // sessionStorage.setItem("sUserName", res.sUserName);
      // secureLocalStorage.setItem("nGroupType", res.nGroupType)
      // secureLocalStorage.setItem("sUserNickName", res.sNickName)
      secureLocalStorage.setItem(
        process.env.REACT_APP_JWT_KEY as string,
        res.sToken
      );

      // Cookies.set("isSignOut_bps", "1");
      Cookies.set("sUserId", res.sUserKeys);
      Cookies.set("sUserFullNameEN", res.sFullNameEN);
      Cookies.set("sUserFullNameTH", res.sFullNameTH);
      // Cookies.set("nGroupType", res.nGroupType);
      Cookies.set("nGroupType", res.nGroupType);
      Cookies.set("sUserName", res.sUserName);
      Cookies.set(
        process.env.REACT_APP_JWT_KEY as string,
        res.sToken
      );

      let sConnectionId = secureLocalStorage.getItem("connectionID_bps");
      setLogout(false);
      // } else {
      if (connection.state != HubConnectionState.Connected) {
        connection
          .start()
          .then(() => {
            // if cannot find connection id
            if (!sConnectionId) {
              secureLocalStorage.setItem("connectionID_bps", connection.connectionId);
              Cookies.set("connectionID_bps", connection.connectionId);
            }
            connection.send("OnSignOutSignal", sConnectionId ?? connection.connectionId, true)
            window.location.reload();
          })
          .catch((e) => {
            ////console.log("Connection failed");
          });
      } else if (res.Status === 404) {
        dispatchRedux(DialogActionCreators.OpenDialogWarning(
          i18n(`${i18nCommon}.msgAlertIncorrect`), () => { //Login Type is Wrong
          }) as any)
      }
      else {
        connection.send("OnSignOutSignal", sConnectionId ?? connection.connectionId, false)
      }

      if (valueLoginTab == 1) {
        // SetisLoadMenu(val => !val);
        SetisLoadMenu(true);
        navigate("/");
      } else if (valueLoginTab == 2) {
        // SetisLoadMenu(val => !val);
        SetisLoadMenu(true);
        navigate("/MyPageEmployer");
      } else {
        if (res.sPath) {
          // SetisLoadMenu(val => !val);
          SetisLoadMenu(true);
          navigate(res.sPath);
        } else {
          window.location.reload();
        }
      }
    }, (err) => {
      if (err.Status === 409) {
        dispatchRedux(DialogActionCreators.OpenDialogWarning(
          i18n(`${i18nCommon}.msgAlertUsernameOrSecureCodeWrong`), () => { //This user was not found in the system
          }) as any)
      } else if (err.Status === 405) {
        dispatchRedux(DialogActionCreators.OpenDialogWarning(
          i18n(`${i18nCommon}.msAlertStatus`), () => { //Username or Password is Wrong
          }) as any)
      } else {
        dispatchRedux(DialogActionCreators.OpenDialogWarning(err.Message) as any)
      }
    }, () => UnBlockUI())
  }

  const _sentSignOutHub = () => {
    if (connection.state != HubConnectionState.Connected) {
      connection
        .start()
        .then(() => {
          if (secureLocalStorage.getItem("connectionID_bps")) {
            connection.send("OnSignOutSignal", secureLocalStorage.getItem("connectionID_bps"), false)
          }
        })
        .catch((e) => {
          ////console.log("Connection failed");
        });
    } else {
      if (secureLocalStorage.getItem("connectionID_bps")) {
        connection.send("OnSignOutSignal", secureLocalStorage.getItem("connectionID_bps"), false)
      }
    }
  }

  const logOut = () => {
    dispatchRedux(DialogActionCreators.OpenDialogSubmit(i18n(`common.msgAlertLogout`), () => {
      dispatchRedux(DialogActionCreators.CloseDialogSubmit() as any)
      // SetisLoadMenu(val => !val);
      SetisLoadMenu(true);
      setLogout(true);
      setFocusIndex(null);

      _sentSignOutHub();
      secureLocalStorage.setItem("isSignOut_bps", "1");
      // Cookies.set("isSignOut_bps", "1");
      // Cookies.remve("isRemember");
      // Cookies.remove("sUserId");
      // Cookies.remove("sUserFullNameTH");
      // Cookies.remove("sUserFullNameEN");
      // Cookies.remove("nGroupType");
      _clearLocalStorageWithoutConnectId();

      if (GetAccountAzure()) { LogoutAzure() }
      navigate('/')
      window.location.reload();
    }) as any)
  }

  const handleClose = () => {
    setAnchorEl(null);
  };

  const switchLanguage = (e: any) => {
    BlockUI();
    setLanguageCode(getLanguage().id === "th" ? "en" : "th");
    setLanguage(true);
    UnBlockUI();
  }

  const setActive = (url, index) => {
    if (location.pathname == url) {
      return true;
    }
    if (!isFromJobSeeker) {
      if (index == nFocusIndex && !isPathEqualRoute) {
        return true;
      }
      else {
        return false;
      }
    }

    if (location.pathname == "/JobPostModify") {
      if (url === "/JobPostList") {
        return true;
      }
    }
    if (location.pathname == "/OrderEmployerForm") {
      if (url === "/OrderEmployer") {
        return true;
      }
    }

    if (location.pathname == "/MyProfileEdit") {
      if (url === "/MyProfile") {
        return true;
      }
    }
  }

  useEffect(() => {
    let IsPathEqualRoute = false;
    if (location.pathname === "/") {
      IsPathEqualRoute = true;
    }
    else {
      lstMenu.forEach(item => {
        if (location.pathname == item.sUrl) {
          IsPathEqualRoute = true;
          secureLocalStorage.setItem("lstMenuAll", lstMenu);
        }
      });
    }
    setIsPathEqualRoute(IsPathEqualRoute);
  }, [location.pathname])



  return (
    <Stack
      direction="row"
      className="Prompt"
      sx={{ overflowX: "hidden", marginBottom: 9 }}
      spacing={2}
    >
      {/* drawer menu */}
      <Hidden lgUp>
        <Box
          sx={{
            position: "fixed",
            right: 0,
            backgroundColor: "rgba(0,0,0,0.7)",
            height: "100vh",
            zIndex: 999,
            transition: " all 0.5s",
            width: isOpenMenu ? "100vw" : 0,
            opacity: isOpenMenu ? "1" : "0",
            overflowX: "hidden",
          }}
        >
          <Box
            sx={{
              float: "right",
              backgroundColor: "white",
              height: "100vh",
            }}
          >
            <Stack direction="column">
              {/* arrow butt in drawer */}
              <Stack direction="row" sx={{ p: 2 }}>
                <Button
                  startIcon={<ArrowBackIos />}
                  sx={{ width: "100%", justifyContent: "start" }}
                  onClick={() => setOpenMenu(false)}
                />
              </Stack>
              {/* end arrow butt in drawer */}

              {/* menu when drawer */}
              <div className="column">
                {lstMenu.filter(f => f.nLevel != 2).map((m, i) => (
                  m.sUrl === "/" ?
                    <Menu_Layout
                      setFocus={() => {
                        setFocusIndex(i);
                      }}
                      isActive={setActive(m.sUrl, i)}
                      key={m.sID} item={m} lstAllMenu={lstMenu} index={i} FocusMenu={FocusMenu}
                    />
                    :
                    (
                      <Menu_Layout
                        setFocus={() => {
                          setFocusIndex(i);
                        }}
                        isActive={setActive(m.sUrl, i)}
                        key={m.sID} item={m} lstAllMenu={lstMenu} index={i} FocusMenu={FocusMenu}
                      />
                    )

                ))}
              </div>
              {isLogout ?
                <></> :
                <Button>
                  <BtnLogout
                    isCircleWithOutText
                    onClick={() => logOut()}
                  />
                </Button>
              }
            </Stack>
          </Box>
        </Box>
      </Hidden>
      {/* end drawer menu */}

      <Box sx={{ width: "100%", m: "0!important" }}>
        <Stack direction="column">
          <Stack
            direction="row"
            alignItems="center"
            sx={{
              backgroundColor: "white",
              minHeight: "70px",
              width: "100%",
              pl: 2,
              pr: 2,
              borderBottom: "1px #eeeeee solid",
              position: "fixed",
              zIndex: 9,
            }}
          >

            <Box className="flex mr-[2em]" >
              {GroupUser == 2 ? //groupuser 2 == employer, group user 1 == job seeker, group user 0 == admin
                <img alt="" className="object-contain w-[100px] h-[50px]" src={Logo} />
                :
                null
              }
            </Box>

            {/* navbar when drawer */}
            <Hidden lgUp>
              <>
                {/* login/logout and lang change */}
                <Popover
                  open={modalLoginOpen}
                  onClose={handleLoginClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                  PaperProps={{
                    style: {
                      width: '400px',
                      marginTop: '3%', borderRadius: '4%'
                    },
                  }}
                >
                  <Stack direction="row" justifyContent="left" sx={{ my: "2%", mx: "2%" }}>
                    <Typography
                      sx={{ color: "#1976d2", fontWeight: 600 }}
                      variant="body1"
                      component="b"
                    >
                      {(i18n(`${i18nField}.LoginText`))}
                    </Typography>
                  </Stack>
                  <Tabs value={valueLoginTab}
                    onChange={(e, v) => {
                      setValueLoginTab(v)
                      setIsFormJobSeeker(v == 1)
                      if (v == 2) {
                        setIsMemberCompany(true)
                      }
                    }}
                    variant="fullWidth"
                    aria-label="tabs login"
                    sx={(t) => ({
                      ".Mui-selected": {
                        backgroundColor: t.palette.primary.main,
                        color: t.palette.common.white + " !Important",
                      },
                      ".MuiTabs-indicator": {
                        backgroundColor: t.palette.primary.dark,
                        height: '4px',
                      },
                    })}
                  >
                    <Tab label={i18n(`${i18nField}.JobSeeker`)} value={1} />
                    <Tab label={i18n(`${i18nField}.Employer`)} value={2} />
                  </Tabs>
                  <Box sx={{ padding: "0.5em 1em" }}>
                    <LoginForm
                      modalLoginOpen={modalLoginOpen}
                      valueLoginTab={valueLoginTab}
                      onOpenReset={undefined}
                      lstDataSelect={undefined}
                      isAuth={undefined}
                      _onSubmit={(e) => onSubmit(e)}
                      isLoadingAuth={isLoadingAuth}
                      onCancel={handleLoginClose}
                      sManualCode={undefined}
                      sUrlQrCode={undefined}
                      onVerify={undefined}
                      onCloseAuth={undefined}
                      setOpenForgot={setOpenForgot}
                    />
                  </Box>
                </Popover >

                {/* register modal */}
                <Popover
                  open={modalRegistOpen}
                  onClose={handleRegistClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                  PaperProps={{
                    style: {
                      width: '300px',
                      marginTop: '3%', borderRadius: '4%'
                    },
                  }}
                >
                  <Tabs value={valueRegistTab}
                    onChange={(e, v) => {
                      setValueRegistTab(v)
                      setIsFormJobSeeker(v == 1)
                    }}
                    orientation="vertical"
                    aria-label="tabs login"
                    sx={(t) => ({
                      ".Mui-selected": {
                        backgroundColor: t.palette.primary.main,
                        color: t.palette.common.white + " !Important",
                      },
                      ".MuiTabs-indicator": {
                        backgroundColor: t.palette.primary.dark,
                        height: '4px',
                      },
                    })}
                  >
                    <Tab label={i18n(`${i18nField}.RegisterJobSeeker`)} value={1}
                      onClick={() => {
                        navigate('/Guest/Register');
                        setModalRegistOpen(false);
                      }}
                    />
                    <Tab label={i18n(`${i18nField}.RegisterEmployer`)} value={2}
                      onClick={() => {
                        navigate('/RegisterEmployer');
                        setModalRegistOpen(false);
                      }}
                    />
                  </Tabs>
                </Popover >

                {getLanguage().id === "en" ? (
                  <Grid xl={1} lg={1} md={1} sm={1} xs={4} className="button2" onClick={(e) => switchLanguage(e)}>
                    < p className="btnTextEng"> EN</p >
                    <Grid className="btnTwoEng">
                      <p className="btnText2Eng">TH</p>
                    </Grid>
                  </Grid >) : (
                  <Grid xl={1} lg={1} md={1} sm={1} xs={4} className="button" onClick={(e) => switchLanguage(e)}>
                    <p className="btnText">TH</p>
                    <Grid className="btnTwo">
                      <p className="btnText2">EN</p>
                    </Grid>
                  </Grid>
                )}

                {isLogout ? (
                  <Button
                    onClick={handleRegistOpen}
                    justify-content="right"
                    sx={{
                      mx: "10px",
                      whiteSpace: "nowrap"
                    }}
                    value={0}
                  >
                    {(i18n(`${i18nField}.Register`))}
                    {/* Register */}
                  </Button>
                ) : null}

                <Divider orientation="vertical" variant="middle" flexItem sx={{ mx: "5px" }} />

                {isLogout ?
                  <Button
                    onClick={handleLoginOpen}
                    justify-content="right"
                    sx={{
                      mx: "10px",
                      whiteSpace: "nowrap"
                    }}
                    value={0}
                  >
                    {(i18n(`${i18nField}.signIn`))}
                  </Button>
                  :
                  <>
                    {
                      secureLocalStorage.getItem("sUserFullNameTH") &&
                      (
                        <Profile_Layout refAuthBox={refAuthBox} handleClick={handleClick}
                          anchorEl={anchorEl} openMenu={openMenu} lang={lang}
                          handleClose={handleClose} widthAccount={widthAccount} logOut={logOut}
                        />
                      )
                    }
                  </>
                }
                {/* end login/logout and lang change */}

                {/* hamburger menu butt */}
                <Button onClick={() => setOpenMenu(true)}>
                  <MenuIcon />
                </Button>
                {/* end hamburger menu butt */}
              </>
            </Hidden>
            {/* end navbar when drewer */}

            {/* navbar menu */}
            <Hidden lgDown>
              <>
                <Box sx={{ px: 1 }} >
                  <div className="flex flex-row  flex-1">
                    {/* {location.pathname === "/MyProfileEdit" ? (
                      <>
                        {lstMenu.filter(f => f.nMenuID != 18).map((m, i) => (
                          m.sUrl === "/" ?
                            <Menu_Layout
                              setFocus={() => {
                                setFocusIndex(i);
                              }}
                              isActive={setActive(m.sUrl, i)}
                              key={m.sID} item={m} lstAllMenu={lstMenu} index={i} FocusMenu={FocusMenu}
                            />
                            :
                            <Menu_Layout
                              setFocus={() => {
                                setFocusIndex(i);
                              }}
                              isActive={setActive(m.sUrl, i)}
                              key={m.sID} item={m} lstAllMenu={lstMenu} index={i} FocusMenu={FocusMenu}
                            />

                        ))}
                      </>
                    ) : (
                      <> */}
                    {lstMenu.filter(f => f.nLevel != 2).map((m, i) => (
                      m.sUrl === "/" ?
                        <Menu_Layout
                          setFocus={() => {
                            setFocusIndex(i);
                            window.scrollTo(0, 0)
                          }}
                          isActive={setActive(m.sUrl, i)}
                          key={m.sID} item={m} lstAllMenu={lstMenu} index={i} FocusMenu={FocusMenu}
                        />
                        :
                        (
                          <Menu_Layout
                            setFocus={() => {
                              setFocusIndex(i);
                              window.scrollTo(0, 0)
                            }}
                            isActive={setActive(m.sUrl, i)}
                            key={m.sID} item={m} lstAllMenu={lstMenu} index={i} FocusMenu={FocusMenu}
                          />
                        )

                    ))}
                    {/* </>
                    )} */}
                  </div>
                </Box>

                {/* {secureLocalStorage.getItem("connectionID_bps") ? null
                                    : secureLocalStorage.getItem("nGroupType") == undefined ? <Button onClick={() => setOpenModal(true)} sx={{ fontSize: "13px" }}>{i18n(`${i18nCommon}.buypackage`)}</Button>
                                        : secureLocalStorage.getItem("isSignOut_bps") == 1 ? <Button onClick={() => setOpenModal(true)} sx={{ fontSize: "13px" }}>{i18n(`${i18nCommon}.buypackage`)}</Button>
                                            : null
                                } */}

                <Box sx={{ width: "5px" }} />

                {/* login/logout and lang change */}
                <Popover
                  open={modalLoginOpen}
                  onClose={handleLoginClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                  PaperProps={{
                    style: {
                      width: '25%', maxWidth: '35%',
                      marginTop: '3%', borderRadius: '4%'
                    },
                  }}
                >
                  <Stack direction="row" justifyContent="left" sx={{ my: "2%", mx: "2%" }}>
                    <Typography
                      sx={{ color: "#1976d2", fontWeight: 600 }}
                      variant="body1"
                      component="b"
                    >
                      {(i18n(`${i18nField}.LoginText`))}
                    </Typography>
                  </Stack>
                  <Tabs value={valueLoginTab}
                    onChange={(e, v) => {
                      setValueLoginTab(v)
                      setIsFormJobSeeker(v == 1)
                      if (v == 2) {
                        setIsMemberCompany(true)
                      }
                    }}
                    variant="fullWidth"
                    aria-label="tabs login"
                    sx={(t) => ({
                      ".Mui-selected": {
                        backgroundColor: t.palette.primary.main,
                        color: t.palette.common.white + " !Important",
                      },
                      ".MuiTabs-indicator": {
                        backgroundColor: t.palette.primary.dark,
                        height: '4px',
                      },
                    })}
                  >
                    <Tab label={i18n(`${i18nField}.JobSeeker`)} value={1} />
                    <Tab label={i18n(`${i18nField}.Employer`)} value={2} />
                  </Tabs>
                  <Box sx={{ padding: "0.5em 1em" }}>
                    <LoginForm
                      modalLoginOpen={modalLoginOpen}
                      valueLoginTab={valueLoginTab}
                      onOpenReset={undefined}
                      lstDataSelect={undefined}
                      isAuth={undefined}
                      _onSubmit={(e) => onSubmit(e)}
                      isLoadingAuth={isLoadingAuth}
                      onCancel={handleLoginClose}
                      sManualCode={undefined}
                      sUrlQrCode={undefined}
                      onVerify={undefined}
                      onCloseAuth={undefined}
                      setOpenForgot={setOpenForgot}
                    />
                  </Box>
                </Popover >

                {/* register modal */}
                <Popover
                  open={modalRegistOpen}
                  onClose={handleRegistClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                  PaperProps={{
                    style: {
                      width: '20%', maxWidth: '30%',
                      marginTop: '3%', borderRadius: '4%'
                    },
                  }}
                >
                  <Tabs value={valueRegistTab}
                    onChange={(e, v) => {
                      setValueRegistTab(v)
                      setIsFormJobSeeker(v == 1)
                    }}
                    orientation="vertical"
                    aria-label="tabs login"
                    sx={(t) => ({
                      ".Mui-selected": {
                        backgroundColor: t.palette.primary.main,
                        color: t.palette.common.white + " !Important",
                      },
                      ".MuiTabs-indicator": {
                        backgroundColor: t.palette.primary.dark,
                        height: '4px',
                      },
                    })}
                  >
                    <Tab label={i18n(`${i18nField}.RegisterJobSeeker`)} value={1}
                      onClick={() => {
                        navigate('/Guest/Register');
                        setModalRegistOpen(false);
                      }}
                    />
                    <Tab label={i18n(`${i18nField}.RegisterEmployer`)} value={2}
                      onClick={() => {
                        navigate('/RegisterEmployer');
                        setModalRegistOpen(false);
                      }}
                    />
                  </Tabs>
                </Popover >

                {getLanguage().id === "en" ? (
                  <Grid xl={1} lg={1} md={1} sm={1} xs={4} className="button2" onClick={(e) => switchLanguage(e)}>
                    < p className="btnTextEng" > EN</p >
                    <Grid className="btnTwoEng">
                      <p className="btnText2Eng">TH</p>
                    </Grid>
                  </Grid >) : (
                  <Grid xl={1} lg={1} md={1} sm={1} xs={4} className="button" onClick={(e) => switchLanguage(e)}>
                    <p className="btnText">TH</p>
                    <Grid className="btnTwo">
                      <p className="btnText2">EN</p>
                    </Grid>
                  </Grid>
                )}

                {isLogout ? (
                  <Button
                    onClick={handleRegistOpen}
                    justify-content="right"
                    sx={{ mx: "1%" }}
                    value={0}
                  // onClick={() => { handleClickRegis() }}
                  >
                    {(i18n(`${i18nField}.Register`))}
                    {/* Register */}
                  </Button>
                ) : null}

                <Divider orientation="vertical" variant="middle" flexItem />

                {isLogout ?
                  <>
                    <Button
                      onClick={handleLoginOpen}
                      justify-content="right"
                      sx={{
                        mx: "1%",
                      }}
                      value={0}
                    >
                      {(i18n(`${i18nField}.signIn`))}
                    </Button>
                  </> :
                  <>
                    {
                      secureLocalStorage.getItem("sUserFullNameTH") &&
                      (
                        <Profile_Layout refAuthBox={refAuthBox} handleClick={handleClick}
                          anchorEl={anchorEl} openMenu={openMenu} lang={lang}
                          handleClose={handleClose} widthAccount={widthAccount} logOut={logOut}
                        />
                      )
                    }
                    <BtnLogout
                      isCircleWithOutText
                      onClick={() => logOut()}
                      sx={{ marginRight: "1%" }}
                    />
                  </>
                }
                {/* end login/logout and lang change */}
              </>
            </Hidden>
            {/* end navbar menu */}
          </Stack>
        </Stack>
      </Box>

      {/* buy package modal  */}
      {/* Phone size */}
      <Hidden smUp>
        <Modal
          open={isOpenModal}
          closeAfterTransition
        >
          <Box sx={{ ...styles, width: 300 }} className={" flex flex-col"}>
            <div className="flex flex-row ">
              <div className="flex flex-[1] justify-end">
                <Tooltip title={i18n(`${i18nLabel}.close`)}>
                  <div onClick={() => {
                    setOpenModal(false)
                  }} className='rounded-[50%] bg-[#d1d1d1] w-[1.5em] h-[1.5em] flex cursor-pointer ease-linear duration-[100ms]  hover:bg-[#8c8c8c]'>
                    <Close fontSize='small' className='m-[auto] text-[white]' />
                  </div>
                </Tooltip>
              </div>
            </div>

            <Stack direction="row" justifyContent="center" sx={{ my: "2%", mx: "2%" }}>
              <Typography
                sx={{ fontWeight: 600 }}
                variant="body1"
                component="b"
              >
                {i18n(`${i18nCommon}.buypackageforemployer`)} {/*สั่งซื้อแพ็กเกจสำหรับผู้ประกอบการ*/}
              </Typography>
            </Stack>

            <Stack justifyContent={"center"} alignItems={"center"} direction="column" sx={{ marginTop: "20px", marginBottom: "20px" }}>
              <Grid>
                <div className='flex flex-row justify-center'>
                  <Button variant="contained" onClick={() => BuyPackage(1)} sx={{ borderRadius: "20px" }}>{i18n(`${i18nCommon}.register`)}</Button>
                </div>
              </Grid>
              <Typography sx={{ color: "#C2C2C2", marginTop: "3%", marginBottom: "3%" }}>
                {i18n(`${i18nLabel}.Or`)}
              </Typography>
              <Grid>
                <div className='flex flex-row justify-center'>
                  <Button variant="contained" onClick={() => BuyPackage(2)} sx={{ borderRadius: "20px" }}>{i18n(`${i18nCommon}.loginformember`)}</Button>
                </div>
              </Grid>
            </Stack>
          </Box>
        </Modal>
      </Hidden>

      {/* Desktop size */}
      <Hidden smDown>
        <Modal
          open={isOpenModal}
          // onClose={() => console.log('close')}
          closeAfterTransition
        >
          {/* <Box sx={{ ...styles, width: 460 }} className={" flex flex-col"}> 376*/}
          <Box sx={{ ...styles, width: 350 }} className={" flex flex-col"}>
            <div className="flex flex-row ">
              <div className="flex flex-[1] justify-end">
                <Tooltip title={i18n(`${i18nLabel}.close`)}>
                  <div onClick={() => {
                    setOpenModal(false)
                  }} className='rounded-[50%] bg-[#d1d1d1] w-[1.5em] h-[1.5em] flex cursor-pointer ease-linear duration-[100ms]  hover:bg-[#8c8c8c]'>
                    <Close fontSize='small' className='m-[auto] text-[white]' />
                  </div>
                </Tooltip>
              </div>
            </div>

            <Stack direction="row" justifyContent="center" sx={{ my: "2%", mx: "2%" }}>
              <Typography
                sx={{ fontWeight: 600 }}
                variant="body1"
                component="b"
              >
                {i18n(`${i18nCommon}.buypackageforemployer`)} {/*สั่งซื้อแพ็กเกจสำหรับผู้ประกอบการ*/}
              </Typography>
            </Stack>

            {/* <Stack justifyContent={"center"} direction="row" sx={{ marginTop: "40px", marginBottom: "40px", marginRight: "40px", marginLeft: "40px" }}> */}
            <Stack justifyContent={"center"} alignItems={"center"} direction="column" sx={{ marginTop: "20px", marginBottom: "20px" }}>
              {/* <Grid xs={1}></Grid> */}
              <Grid>
                {/* <div className='flex flex-row justify-center' style={{ marginTop: "40px", marginBottom: "40px" }}> */}
                <div className='flex flex-row justify-center'>
                  <Button variant="contained" onClick={() => BuyPackage(1)} sx={{ borderRadius: "20px" }}>{i18n(`${i18nCommon}.register`)}</Button>
                </div>
              </Grid>
              {/* <Grid xs={1}></Grid> */}
              {/* <Grid xs={1}> */}
              <Typography
                sx={{ color: "#C2C2C2", marginTop: "3%", marginBottom: "3%" }}
              // fontWeight: 600 
              // variant="body1"
              // component="b"
              >
                {i18n(`${i18nLabel}.Or`)}
              </Typography>
              {/* </Grid> */}
              {/* <Grid xs={1}></Grid> */}
              <Grid>
                {/* <div className='flex flex-row justify-center' style={{ marginTop: "40px", marginBottom: "40px", marginRight: "40px" }}> */}
                {/* <div className='flex flex-row justify-center' style={{ marginTop: "40px", marginBottom: "40px" }}> */}
                <div className='flex flex-row justify-center'>
                  <Button variant="contained" onClick={() => BuyPackage(2)} sx={{ borderRadius: "20px" }}>{i18n(`${i18nCommon}.loginformember`)}</Button>
                </div>
              </Grid>
              {/* <Grid xs={1}></Grid> */}
            </Stack>
          </Box>
        </Modal>
      </Hidden>
    </Stack>
  );
}