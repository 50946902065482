import ShowTotalPackage from "./ShowTotalPackage";
import { Grid, Stack } from "@mui/material";
import FormShowListPackage from "./FormShowListPackage";

const ShowListPackage = () => {
  return (
    <Stack sx={{ mx: "2%" }}  >
      <Grid className="ShowListPackage">
        <Grid item xs={12}>
          <ShowTotalPackage />
        </Grid>
        <Grid item xs={12}>
          <FormShowListPackage />
        </Grid>
      </Grid>
    </Stack>
  );
}
export default ShowListPackage