import React from "react";
import { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { i18n } from "i18n";
import { AxiosGet, AxiosPost, AxiosPostReport } from "service/CommonFunction/TS_function";
import {
  EmailShareButton,
  EmailIcon,
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  LinkedinShareButton,
  LinkedinIcon,
} from "react-share";
import {
  Button,
  Grid,
  Divider,
  Paper,
  InputBase,
  Snackbar,
  Alert,
  Dialog,
  DialogContent,
  DialogTitle,
  Skeleton,
  Card,
  Typography,
  Hidden,
  CardContent,
} from "@mui/material";
import ShareIcon from "@mui/icons-material/Share";
import PrintIcon from "@mui/icons-material/Print";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import ReactToPrint from "react-to-print";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { DialogActionCreators } from "store/redux/DialogAlert";
import DetailJob from "components/JobDetail/DetailJob";
import DetailCompany from "components/JobDetail/DetailCompany";
import { FnBlock_UI } from "service/SystemFunction/SystemFunction";
import secureLocalStorage from "react-secure-storage";

// const JobDetail = ({ sJobPostID = "", normalBanner, handleNotInteresed = () => { } }) => {
const JobDetail = (props) => {
  const dispatch = useDispatch();
  const history = useNavigate();
  const location = useLocation();
  const searchParams = location.search;
  const sID = new URLSearchParams(searchParams).get("sID") || props.sJobPostID;
  // const sID = sJobPostID;
  const { BlockUI, UnBlockUI } = FnBlock_UI();

  const i18nButton = "jobDetail.button";
  const i18nLabel = "jobDetail.label";
  const i18nCommon = "jobDetail.common";

  // const shareUrl = window.location.href;
  const [jobPost, setJobPost] = useState<{ [key: string]: any }>({});
  const shareUrl = process.env.REACT_APP_API_URL + "JobDetail?sID=" + jobPost.sID;

  // const shareUrl = process.env.REACT_APP_API_URL + `JobDetail?sID=${encodeURI(sID)}`;
  const [openShare, setOpenShare] = useState(false);
  const [openClipboard, setOpenClipboard] = useState(false);

  // const [jobPost, setJobPost] = useState<{ [key: string]: any }>({});
  const [lang, setLang] = useState("");
  const [isGuest, setIsGuest] = useState(true);
  const [favourite, setFavourite] = useState(false);
  const [isApply, setIsApply] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [company, setcompany] = useState(false);
  const [companyJob, setcompanyJob] = useState([]);
  const [sUrl, setUrl] = useState("");
  const navigate = useNavigate();
  const handleCloseClipboard = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenClipboard(false);
  };

  //react to print
  const componentRef = useRef();
  const reactToPrintTrigger = () => {
    return (
      <Button variant="outlined" startIcon={<PrintIcon />} sx={{ borderRadius: "20px" }}>
        {i18n(`${i18nButton}.btnPrint`)}
      </Button>
    );
  };

  const onGetDetail = () => {
    setLoading(true);
    AxiosGet("JobDetail/detail", { sID: sID }, (res) => {
      setJobPost(res);
      setUrl(res.sUrlLink);
      setFavourite(res.isFavourite);
      setIsApply(res.isApply);
      setLoading(false);
      setcompany(res.lstCompany);
      setcompanyJob(res.lstCompanyJob);
    });
  };

  const onApply = () => {
    if (isGuest) {
      dispatch(
        DialogActionCreators.OpenDialogWarning(
          i18n(`${i18nCommon}.login`)
        ) as any
      );
    } else {
      //เปลี่ยนไปหน้า Job Apply ก่อน
      let objJobDetail = {
        sID: sID || "",
        isApply: true,
        sFromPage: "JobDetail",
      };
      let objProfile = {
        sMode: "",
      };
     
      history(`/JobApply?sJobDetailID=${sID}`, {
        state: {
          objJobDetail: objJobDetail,
          objProfile:objProfile
        },
      });
    }
  };

  const onFavourite = () => {
    if (isGuest) {
      dispatch(
        DialogActionCreators.OpenDialogWarning(
          i18n(`${i18nCommon}.login`)
        ) as any
      );
    } else {
      BlockUI();
      AxiosPost(
        "JobDetail/favourite",
        { sID: sID, isFavourite: !favourite },
        (res) => {
          UnBlockUI();
          if (res.Status === 200) {
            setFavourite(res.isFavourite);
            props.handleNotInteresed();
          } else {
            dispatch(DialogActionCreators.OpenDialogError(res.Message) as any);
          }
        },
        (err) => {
          if (err.Status === 401) {
            UnBlockUI();
            dispatch(
              DialogActionCreators.OpenDialogWarning("Token expired.", () => {
                history("/");
              }) as any
            );
          } else {
            dispatch(DialogActionCreators.OpenDialogError(err.Message) as any);
          }
        }
      );
    }
  };

  const PrintDetail = () => {
    AxiosPostReport("JobDetail/export", { sID: sID, sLang: secureLocalStorage.getItem("language").toString() }, (data) => {
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `JobDetail_${new Date().getTime()}.pdf`);
      document.body.appendChild(link);
      link.click();
      link.remove();
    });
  }

  useEffect(() => {
    setLang(secureLocalStorage.getItem("language").toString());
    setIsGuest(secureLocalStorage.getItem("sUserId") == null);
    onGetDetail();
  }, [sID]);

  return (
    <Card sx={{
      padding: "1.5em",
      borderRadius: "1em",
      position: "relative"
    }}>
      <Grid sx={{ bgcolor: "white" }} container>
        <Grid item xs={12}>
          {/* phone size */}
          <Hidden smUp>
            {jobPost.sCompanyFile_Banner ? (
              <Grid item justifyContent="center" xs={12} marginBottom={2}>
                {isLoading ? (
                  <Skeleton height={200} variant="rectangular" />
                ) : (
                  <img width="100%" src={jobPost.sCompanyFile_Banner} alt="" style={{ width: "100%", height: "200px", objectFit: "cover", }} />
                )}
              </Grid>
            ) : null}
          </Hidden>

          {/* normal size */}
          <Hidden smDown>
            {jobPost.sCompanyFile_Banner ? (
              props.normalBanner ? (<Grid item justifyContent="center" xs={12} marginBottom={2}>
                {isLoading ? (
                  <Skeleton height={200} variant="rectangular" />
                ) : (


                  <img src={jobPost.sCompanyFile_Banner} alt="" style={{ width: "100%", objectFit: "cover" }} />
                )}
              </Grid>) : (
                <Grid item justifyContent="center" xs={12} marginBottom={2}>
                  {isLoading ? (
                    <Skeleton height={200} variant="rectangular" />
                  ) : (
                    <img src={jobPost.sCompanyFile_Banner} alt="" style={{ width: "100%", minHeight: "24vw", maxHeight: "37vw", objectFit: "cover" }} />
                  )}
                </Grid>
              )
            ) : null}
          </Hidden>
          {isLoading ? (
            ""
          ) : (
            <Grid container justifyContent={"end"} xs={12} spacing={1}>
              {jobPost.IsExpired ? (
                <React.Fragment>
                  <Grid item>
                    <div className="px-3 py-[0.4em] bg-[#868686] text-white font-[14px] flex justify-center border-[1px] rounded-[20px]">
                      <Typography component={"span"}>{i18n(`${i18nLabel}.expired`)}</Typography>
                    </div>
                  </Grid>
                  {/* phone size */}
                  <Hidden smUp>
                    <Grid xs={6} item >
                      <Button
                        variant="outlined" //งานที่สนใจ
                        disabled={company == true}
                        startIcon={
                          favourite ? <FavoriteIcon /> : <FavoriteBorderIcon />
                        }
                        onClick={onFavourite}
                        sx={{ borderRadius: "20px" }}
                      >
                        {favourite
                          ? i18n(`${i18nButton}.btnFavourited`)
                          : i18n(`${i18nButton}.btnFavourite`)}
                      </Button>
                    </Grid>
                  </Hidden>

                  {/* normal size */}
                  <Hidden smDown>
                    <Grid item >
                      <Button
                        variant="outlined" //งานที่สนใจ
                        disabled={company == true}
                        startIcon={
                          favourite ? <FavoriteIcon /> : <FavoriteBorderIcon />
                        }
                        onClick={onFavourite}
                        sx={{ borderRadius: "20px" }}
                      >
                        {favourite
                          ? i18n(`${i18nButton}.btnFavourited`)
                          : i18n(`${i18nButton}.btnFavourite`)}
                      </Button>
                    </Grid>
                  </Hidden>
                </React.Fragment>
              ) : (
                <React.Fragment>

                  {/* phone size */}
                  <Hidden smUp>
                    <Grid xs={6} item>
                      <Button
                        variant="contained" //สมัครงาน
                        disabled={company == true}
                        startIcon={<HowToRegIcon />}
                        onClick={onApply}
                        sx={{ borderRadius: "20px" }}
                      >
                        {i18n(`${i18nButton}.btnApply`)}
                      </Button>
                    </Grid>

                    <Grid xs={6} item>
                      <Button
                        variant="outlined"
                        disabled={company == true}
                        startIcon={
                          favourite ? <FavoriteIcon /> : <FavoriteBorderIcon />
                        }
                        onClick={onFavourite}
                        sx={{ borderRadius: "20px" }}
                      >
                        {favourite
                          ? i18n(`${i18nButton}.btnFavourited`)
                          : i18n(`${i18nButton}.btnFavourite`)}
                      </Button>
                    </Grid>

                    <Grid xs={6} item>
                      <Button onClick={() => PrintDetail()} variant="outlined" startIcon={<PrintIcon />} sx={{ borderRadius: "20px" }}>
                        {i18n(`${i18nButton}.btnPrint`)}
                      </Button>

                    </Grid>

                    <Grid xs={6} item>
                      <Button
                        variant="outlined" //แชร์
                        onClick={() => setOpenShare(true)}
                        startIcon={<ShareIcon />}
                        sx={{ borderRadius: "20px" }}
                      >
                        {i18n(`${i18nButton}.btnShare`)}
                      </Button>
                    </Grid>


                  </Hidden>

                  {/* normal size */}
                  <Hidden smDown>
                    <Grid item>
                      <Button
                        variant="contained" //สมัครงาน
                        disabled={company == true}
                        startIcon={<HowToRegIcon />}
                        onClick={onApply}
                        sx={{ borderRadius: "20px" }}
                      >
                        {i18n(`${i18nButton}.btnApply`)}
                      </Button>
                    </Grid>

                    <Grid item>
                      <Button
                        variant="outlined"
                        disabled={company == true}
                        startIcon={
                          favourite ? <FavoriteIcon /> : <FavoriteBorderIcon />
                        }
                        onClick={onFavourite}
                        sx={{ borderRadius: "20px" }}
                      >
                        {favourite
                          ? i18n(`${i18nButton}.btnFavourited`)
                          : i18n(`${i18nButton}.btnFavourite`)}
                      </Button>
                    </Grid>

                    <Grid item>
                      <Button onClick={() => PrintDetail()} variant="outlined" startIcon={<PrintIcon />} sx={{ borderRadius: "20px" }}>
                        {i18n(`${i18nButton}.btnPrint`)}
                      </Button>
                    </Grid>

                    <Grid item>
                      <Button
                        variant="outlined" //แชร์
                        onClick={() => setOpenShare(true)}
                        startIcon={<ShareIcon />}
                        sx={{ borderRadius: "20px" }}
                      >
                        {i18n(`${i18nButton}.btnShare`)}
                      </Button>
                    </Grid>
                  </Hidden>
                </React.Fragment>
              )}

            </Grid>
          )}
          <Divider style={{ marginTop: "20px", marginBottom: "20px" }} />
          <Grid item container xs={12} ref={componentRef}>
            <DetailJob jobPost={jobPost} isLoading={isLoading} sJobPostID={props.sJobPostID} />
            <DetailCompany
              lstCompanyContent={jobPost.lstCompanyContent}
              lstCompanyJob={jobPost.lstCompanyJob}
              isLoading={isLoading}
              isViewJob={true}
            />
          </Grid>
        </Grid>

        <Dialog open={openShare} onClose={() => setOpenShare(false)}>
          <DialogTitle>{i18n(`${i18nLabel}.share`)}</DialogTitle>
          <DialogContent>

            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Paper
                  component="form"
                  sx={{
                    p: "2px 4px",
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <InputBase sx={{ ml: 1, flex: 1 }} value={shareUrl} />
                  <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                  <CopyToClipboard text={shareUrl}>
                    <Button variant="text" onClick={() => setOpenClipboard(true)} sx={{ borderRadius: "20px" }}>
                      {i18n(`${i18nButton}.btnClipboard`)}
                    </Button>
                  </CopyToClipboard>
                </Paper>
              </Grid>
              <Grid item xs={12}>
                <EmailShareButton
                  url={shareUrl}
                  subject={`${i18n(`${i18nLabel}.messageShare`)} ${lang == "th" ? jobPost.sJob_TH : jobPost.sJob_EN}`}
                >
                  <EmailIcon size={40} round={true} />
                </EmailShareButton>

                <FacebookShareButton url={shareUrl}>
                  <FacebookIcon size={40} round={true} />
                </FacebookShareButton>

                <TwitterShareButton url={shareUrl}>
                  <TwitterIcon size={40} round={true} />
                </TwitterShareButton>

                <LinkedinShareButton url={shareUrl}>
                  <LinkedinIcon size={40} round={true} />
                </LinkedinShareButton>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>

        <Snackbar
          open={openClipboard}
          autoHideDuration={1000}
          onClose={handleCloseClipboard}
        >
          <Alert severity="success">
            {i18n(`${i18nLabel}.messageClipboard`)}
          </Alert>
          {/* Link copied */}
        </Snackbar>
      </Grid >
    </Card >
  );
};
export default JobDetail;