import { Card, CardContent, Divider, Grid, Typography } from "@mui/material";
import { i18n } from 'i18n';
const FormShowTotalPackage = (sNameBox,) => {
    const i18nField = 'entities.Package';
    return (
        <>
            <Card sx={{ borderRadius: 5 ,width:260 }} >
                <Typography align="center" color="#FFFFFF" variant="h6" sx={{ backgroundColor: sNameBox.sNameBox.sColor }}>
                    {sNameBox.sNameBox.sName}
                    <Divider />
                </Typography>
            </Card>
            < Card sx={{ borderRadius: 5, marginTop: "3%", boxShadow: "0px 0px 7px 2px #C9C9C9"  ,width:260}}>
                <CardContent  >
                    <Grid container >
                        <Grid item xs={12}>
                            <Grid container  >
                                <Grid item xs={6}>
                                    <Typography fontSize={15} >
                                        บริษัทว่าจ้าง :
                                        <Divider />
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography fontSize={15} align="right" color="#1c54b2" >
                                        {sNameBox.sNameBox.nEmployer}
                                        <Divider />
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid container>
                                <Grid item xs={6}>
                                    <Typography fontSize={15}>
                                        สถานะ :
                                        <Divider />
                                    </Typography>
                                </Grid>

                                <Grid item xs={6}>
                                    <Typography fontSize={15} align="right" color="#1c54b2">
                                        {sNameBox.sNameBox.nActive}
                                        <Divider />
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid container>
                                <Grid item xs={6}>
                                    <Typography fontSize={15}>
                                        {sNameBox.sNameBox.nAllPackage ? "แพ็กเกจ ทั้งหมด :" : ""}
                                    </Typography>
                                </Grid>

                                <Grid item xs={6}>
                                    <Typography fontSize={15} align="right" color="#1c54b2" >
                                        {sNameBox.sNameBox.nAllPackage ? sNameBox.sNameBox.nAllPackage : ""}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card >
        </>
    )
}
export default FormShowTotalPackage
