import { useEffect, useRef, useState } from "react";
import { Grid, Typography, Box, Tooltip, Hidden, Button, Autocomplete, TextField, Stack } from "@mui/material";
import { i18n } from "i18n";
import { useNavigate } from "react-router-dom";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import JobDetail from "view/font/JobDetail/JobDetail";
import moment from "moment";
import SkeletonJobTab from "components/Common/ElementInForm/SkeletonJobTab";
import NotSelectJob from "view/font/JobDetail/NotSelectJob";
import secureLocalStorage from "react-secure-storage";
import { Pagination_Custom } from "components/Pagination/Pagination";
import "./JobTab.css";
import AutoCompleteSelect from "components/Common/ElementInForm/AutoCompleteSelect";
import * as yup from "yup";
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

const i18nForthisPage = "home";
const i18nCommon = "common";

export default function JobTab({ lstJob = [], isLoading = false, setCompanyName, NewsPage, Page, setPage }) {
  const language = secureLocalStorage.getItem("language").toString();
  moment.locale(language);
  const navigate = useNavigate();
  const [sActiveID, setActiveID] = useState("");
  const [arrOptPage, setOptPage] = useState([]);
  const oFormRef = useRef() as any;
  const objSchema = {}

  const schema = yup.object().shape(objSchema);

  const form = useForm({
    resolver: yupResolver(schema),
    shouldUnregister: false,
    shouldFocusError: true,
    mode: "all",
  });

  const PER_PAGE = 10;
  const count = NewsPage;
  const _DATA = Pagination_Custom(NewsPage || [], PER_PAGE);

  useEffect(() => {
    let objPage = {};
    let arrPage = [];

    if (count > 0) {
      for (let i = 1; i <= count; i++) {
        arrPage.push(i);
      }

      arrPage.forEach((elem, i) => {
        objPage[i] = elem
      })

      const keys = Object.keys(objPage);
      const res = [];
      for (let i = 0; i < keys.length; i++) {
        res.push({
          'value': keys[i + 1],
          'label': objPage[keys[i]]
        });
      };

      setOptPage(res);
      form.setValue('nPage', res[0])
    }
  }, [count]);

  const NextPage = () => {
    if (Page < count) {
      setPage(Page + 1);
      form.setValue('nPage', arrOptPage[Page]);
    }
  }

  const PreviousPage = () => {
    if (Page > 1) {
      setPage(Page - 1);
      setTimeout(() => {
        form.setValue('nPage', arrOptPage[Page - 2]);
      }, 500);
    }
  }

  // pagination
  const handleChange = (e, p) => {
    if (e == null) {
      setPage(1);
      form.setValue('nPage', arrOptPage[0]);
    } else {
      setPage(e.label);
      _DATA.jump(p);
      if (oFormRef.current != null) {
        window.scrollTo({
          top: oFormRef.current.offsetTop - 100,
          left: 0,
          behavior: "smooth",
        });
      }
    }
  };

  useEffect(() => {
    if (lstJob.length > 0 && sActiveID != lstJob[0].sEncryptJobPostID) {
      setTimeout(() => {
        setActiveID(lstJob[0].sEncryptJobPostID || "");
      }, 500);
    }
    // setNormalBanner(true);
  }, [lstJob])

  return (
    <FormProvider {...form}>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          {isLoading ? (
            <SkeletonJobTab />
          ) : (
            lstJob.length > 0 && (
              <>
                {/* Desktop */}
                <Hidden smDown>
                  <Grid item md={4} sm={4} xs={12}>
                    <Grid
                      item
                      xs={12}
                      sx={{
                        overflow: "scroll",
                        overflowX: "hidden",
                        height: "85vh",
                        borderRadius: "10px",
                        pb: "10px",
                      }}
                      className="custom-scroll-bar-search-job"
                    >
                      {lstJob.map((item, index) => (
                        <Grid
                          key={index}
                          item
                          xs={12}
                          sx={{ mt: 1, ml: 1, mr: 1 }}
                        >
                          <Box
                            sx={{
                              borderRadius: "10px",
                              boxShadow:
                                sActiveID === item.sEncryptJobPostID
                                  ? "0px 0px 7px 2px #4F9BC3 !important"
                                  : "0px 0px 7px 2px #C9C9C9",
                              p: "15px",
                              cursor: "pointer",
                              ":hover": {
                                boxShadow: "0px 0px 7px 2px #A4A4A4",
                              },
                              backgroundColor: "white",
                            }}
                            onClick={() => {
                              setActiveID(item.sEncryptJobPostID || "");
                            }}
                          >
                            {item.sUrlComImg && (
                              <Tooltip
                                title={i18n(`${i18nForthisPage}.btnViewCompany`)}
                              >
                                <img
                                  src={item.sUrlComImg}
                                  alt="company"
                                  height="700"
                                  style={{
                                    // height: "55px",
                                    width: "55px",
                                  }}
                                  onClick={(e) => {
                                    navigate(
                                      `/Guest/Company?sID=${item.sEncryptJobPostID}`
                                    );
                                    e.stopPropagation();
                                  }}
                                />
                              </Tooltip>
                            )}
                            <Typography
                              className="ellipsis-label"
                              sx={{
                                fontWeight: 400,
                                color: "#295697",
                                pt: "16px",
                                ":hover": {
                                  textDecoration: "underline",
                                  color: "#295697",
                                },
                                whiteSpace: "normal",
                                wordBreak: "break-word",
                              }}
                              onClick={() => {
                                setActiveID(item.sEncryptJobPostID || "");
                              }}
                            >
                              {item[`sJob_${language}`]}
                            </Typography>
                            <Typography
                              className="ellipsis-label"
                              sx={{
                                fontSize: 14,
                                fontWeight: 400,
                                color: "#000",
                                pb: "16px",
                                ":hover": {
                                  textDecoration: "underline",
                                  color: "#000",
                                },
                              }}
                              onClick={(e) => {
                                setCompanyName(item.sCompanyName)
                              }}
                            >
                              {item.sCompanyName}
                            </Typography>

                            <Typography
                              className="ellipsis-label"
                              sx={{
                                fontSize: 14,
                                fontWeight: 400,
                                color: "#000",
                              }}
                            >
                              {item[`sLocation_${language}`] || ""}
                            </Typography>

                            <Typography
                              className="ellipsis-label"
                              sx={{
                                fontSize: 13,
                                fontWeight: 500,
                                color: "#C91643",
                              }}
                            >
                              {`${item.sSalaryStart} - ${item.sSalaryEnd} ${item[`sCurrency_${language}`]}`}
                            </Typography>
                            <Typography>
                              {(item.lstOtherInfo_th != null || item.lstOtherInfo_en != null) &&
                                (item.lstOtherInfo_th.length > 0 || item.lstOtherInfo_en.length > 0) ?
                                language == "th"
                                  ? item.lstOtherInfo_th.join(", ")
                                  : item.lstOtherInfo_en.join(", ")
                                : ""}
                            </Typography>
                            <Box sx={{ pt: "16px", pb: "16px" }}>
                              {item.lstPoint.length > 0 &&
                                item.lstPoint.map((iP, i) => (
                                  <Box key={i} sx={{ display: "flex" }}>
                                    <FiberManualRecordIcon
                                      sx={{ fontSize: "6px", mt: "7px", }}
                                    />
                                    <Typography
                                      className="ellipsis-label"
                                      sx={{
                                        fontSize: 14,
                                        fontWeight: 400,
                                        color: "#000",
                                        marginLeft: "5px",
                                        whiteSpace: "normal",
                                        wordBreak: "break-word",
                                      }}
                                    >
                                      {iP}
                                    </Typography>
                                  </Box>
                                ))}
                            </Box>
                            <Typography
                              className="ellipsis-label"
                              sx={{
                                fontSize: 12,
                                fontWeight: 400,
                                color: "#000",
                              }}
                            >

                              {
                                item.nUpdateDate > 24 ? item.nUpdateDate + i18n(`${i18nForthisPage}.postdateCount`)
                                  : moment(item.dUpdateDate).fromNow()
                              }

                            </Typography>
                          </Box>
                        </Grid>
                      ))}

                      {/* pagination */}
                      <Grid container justifyContent={"center"} sx={{ my: "2%" }}>
                        <Grid item >
                          <Button onClick={() => PreviousPage()} variant="outlined" sx={{ width: 'auto', borderColor: "#d2d2d2", color: "#848483" }}>{i18n(`${i18nCommon}.previous`)}</Button>
                        </Grid>
                        <Grid item xs={4}>
                          {/*  <Autocomplete
                            disablePortal
                            // options={arrOptPage.map(item => item)}
                            options={arrOptPage}
                            onChange={(e, p) => handleChange(e, p)}
                            sx={{ width: 'auto', p: "-100px", "&:hover": { borderColor: "#cbdff5 !important", backgroundColor: "#f6fafd !important", color: "red !important" } }}
                            renderInput={(params) =>
                              <TextField {...params} sx={{ p: "-200px" }} />
                            }
                          /> */}

                          <AutoCompleteSelect
                            name={'nPage'}
                            options={arrOptPage}
                            onChange={(e, p) => handleChange(e, p)}
                            isPagination
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <Button onClick={() => NextPage()} variant="outlined" sx={{ width: 'auto', borderColor: "#d2d2d2", color: "#848483" }}>{i18n(`${i18nCommon}.next`)}</Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item md={8} sm={8} xs={12}>
                    {sActiveID ? (
                      <Box
                        sx={{
                          overflow: "scroll",
                          overflowX: "hidden",
                          height: "85vh",
                        }}
                        className="custom-scroll-bar-search-job"
                      >
                        <JobDetail sJobPostID={sActiveID} normalBanner={true} />
                        {/* item.sEncryptJobPostID */}
                      </Box>
                    ) : (
                      <Box
                        sx={{
                          height: "85vh",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                        }}
                      >
                        <NotSelectJob />
                      </Box>
                    )}
                  </Grid>
                </Hidden>

                {/* Mobile */}
                <Hidden smUp>
                  <Grid container>
                    {lstJob.map((item, index) => (
                      <Grid key={index} item xs={12} sx={{ mt: 1, ml: 1, mr: 1 }}>
                        <Box
                          sx={{
                            borderRadius: "10px",
                            boxShadow: "0px 0px 7px 2px #C9C9C9",
                            p: "15px",
                            cursor: "pointer",
                            ":hover": {
                              boxShadow: "0px 0px 7px 2px #A4A4A4",
                            },
                            backgroundColor: "white",
                          }}
                          onClick={() => {
                            navigate("/JobDetail?sID=" + item.sEncryptJobPostID);
                          }}
                        >
                          {item.sUrlComImg && (
                            <Tooltip
                              title={i18n(`${i18nForthisPage}.btnViewCompany`)}
                            >
                              <img
                                src={item.sUrlComImg}
                                alt="company"
                                style={{
                                  height: "30px",
                                }}
                                onClick={(e) => {
                                  navigate(
                                    `/Guest/Company?sID=${item.sEncryptJobPostID}`
                                  );
                                  e.stopPropagation();
                                }}
                              />
                            </Tooltip>
                          )}
                          <Typography
                            sx={{
                              fontWeight: 400,
                              color: "#295697",
                              pt: "16px",
                              ":hover": {
                                textDecoration: "underline",
                                color: "#295697",
                              },
                            }}
                            onClick={() => {
                              navigate(
                                "/JobDetail?sID=" + item.sEncryptJobPostID
                              );
                            }}
                          >
                            {item[`sJob_${language}`]}
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: 14,
                              fontWeight: 400,
                              color: "#000",
                              pb: "16px",
                              ":hover": {
                                textDecoration: "underline",
                                color: "#000",
                              },
                            }}
                            onClick={(e) => {
                              setCompanyName(item.sCompanyName)
                            }}
                          >
                            {item.sCompanyName}
                          </Typography>

                          <Typography
                            className="ellipsis-label"
                            sx={{
                              fontSize: 14,
                              fontWeight: 400,
                              color: "#000",
                            }}
                          >
                            {item[`sLocation_${language}`] || ""}
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: 13,
                              fontWeight: 500,
                              color: "#C91643",
                            }}
                          >
                            {`${item.sSalaryStart} - ${item.sSalaryEnd} ${item[`sCurrency_${language}`]
                              }`}
                          </Typography>
                          <Typography>
                            {(item.lstOtherInfo_th != null || item.lstOtherInfo_en != null) &&
                              (item.lstOtherInfo_th.length > 0 || item.lstOtherInfo_en.length > 0) ?
                              language == "th"
                                ? item.lstOtherInfo_th.join(", ")
                                : item.lstOtherInfo_en.join(", ")
                              : ""}
                          </Typography>
                          <Box sx={{ pt: "16px", pb: "16px" }}>
                            {item.lstPoint.length > 0 &&
                              item.lstPoint.map((iP, i) => (
                                <Box key={i} sx={{ display: "flex" }}>
                                  <FiberManualRecordIcon
                                    sx={{ fontSize: "6px", mt: "7px" }}
                                  />
                                  <Typography
                                    sx={{
                                      fontSize: 14,
                                      fontWeight: 400,
                                      color: "#000",
                                      marginLeft: "5px",
                                    }}
                                  >
                                    {iP}
                                  </Typography>
                                </Box>
                              ))}
                          </Box>
                          <Typography
                            sx={{
                              fontSize: 12,
                              fontWeight: 400,
                              color: "#000",
                            }}
                          >
                            {/* {
                              item.nPostDate > 24 ? item.nPostDate + i18n(`${i18nForthisPage}.postdateCount`)
                                : moment(item.dPostDate).fromNow()
                            } */}
                            {
                              item.nUpdateDate > 24 ? item.nUpdateDate + i18n(`${i18nForthisPage}.postdateCount`)
                                : moment(item.dUpdateDate).fromNow()
                            }
                            {/* {moment(item.dPostDate).fromNow()} */}
                          </Typography>
                        </Box>
                      </Grid>
                    ))}

                    {/* pagination  */}
                    <Grid xs={12} justifyContent={"center"} sx={{ my: "2%" }}>
                      <Stack direction={"row"}>
                        <Grid xs={1}>  </Grid>
                        <Grid xs={3}>
                          <Button onClick={() => PreviousPage()} variant="outlined" sx={{ width: 'auto', borderColor: "#d2d2d2", color: "#848483" }}>{i18n(`${i18nCommon}.previous`)}</Button>
                        </Grid>

                        <Grid xs={4}>
                          {/* <Autocomplete
                            disablePortal
                            // id="combo-box-demo"
                            options={arrOptPage.map(item => item)}
                            onChange={(e, p) => handleChange(e, p)}
                            sx={{ width: 'auto', p: "-100px", "&:hover": { borderColor: "#cbdff5 !important", backgroundColor: "#f6fafd !important", color: "red !important" } }}
                            renderInput={(params) =>
                              <TextField {...params} label={Page} sx={{ p: "-200px" }} />
                            }
                          /> */}
                          <AutoCompleteSelect
                            name={'nPage'}
                            options={arrOptPage}
                            onChange={(e, p) => handleChange(e, p)}
                            isPagination
                          />
                        </Grid>
                        <Grid xs={3}>
                          <Button onClick={() => NextPage()} variant="outlined" sx={{ width: 'auto', borderColor: "#d2d2d2", color: "#848483" }}>{i18n(`${i18nCommon}.next`)}</Button>
                        </Grid>
                        <Grid xs={1}>  </Grid>
                      </Stack>
                    </Grid>
                  </Grid>
                </Hidden>
              </>
            )
          )}
        </Grid>
      </Grid>
    </FormProvider>
  );
}