import { useEffect, useRef, useState } from "react";
import {
  ButtonBase,
  Grid,
  Pagination,
  Paper,
  Skeleton,
  styled,
  Typography,
} from "@mui/material";
import LogoBPS from "assets/images/BPS-Logo.png";
// import LogoBPS from "ClientApp/public/wwwroot/UploadFile/images/BPS-Logo.png"
import { AxiosPost } from "service/CommonFunction/TS_function";
import { Link } from "react-router-dom";
import { i18n } from "i18n";
import CriteriaSearch from "components/SearchJob/CriteriaSearch";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import { EPageType } from "components/SearchJob/CriteriaSearch";
import FactoryIcon from "@mui/icons-material/Factory";
import secureLocalStorage from "react-secure-storage";
import usePagination from "@mui/material/usePagination/usePagination";
import { Pagination_Custom } from "components/Pagination/Pagination";

const ControllerAPI = "MyJob";
const i18nForthisPage = "myJobInvitation.";
const MyJobViewedCOMP = () => {
  const language = secureLocalStorage.getItem("language").toString() || "th";
  const navigate = useNavigate();
  const [lstJob, setJob] = useState([]);
  const [nCount, setCount] = useState(0);
  const [NewsPage, setNewsPage] = useState() as any;
  const [Page, setPage] = useState(1);
  const oFormRef = useRef() as any;
  const [objPaging, setPaging] = useState({
    nTotal: 0,
  });
  const [isLoading, setLoading] = useState(false);

  const GetJob = (param, nPageParam = null) => {
    setLoading(true);
    let nPageFocus = nPageParam ? nPageParam : Page
    if (nPageFocus != Page) {
      setPage(nPageFocus)
    } else {
      param = {
        nPage: nPageFocus,
        ...param,
      };
      AxiosPost(
        `${ControllerAPI}/SearchJobViewed`,
        param,
        (res) => {
          setJob([...res.lstJob]);

          setPaging({
            nTotal: res.nTotal,
          });

          let nCountPage = (res.nTotal) / 10;
          let nPageNews = Math.ceil(nCountPage);
          setNewsPage(nPageNews);
          setCount(nCountPage);
        },
        null,
        () => setLoading(false)
      );
    }
  };

  const PER_PAGE = 10;
  const count = NewsPage;
  const _DATA = Pagination_Custom(NewsPage || [], PER_PAGE);

  //pagination
  const handleChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);

    // if (oFormRef.current != null) {
    //   window.scrollTo({
    //     top: oFormRef.current.offsetTop - 100,
    //     left: 0,
    //     behavior: "smooth",
    //   });
    // }

  };

  const onScrollTop = () => {
    setTimeout(() => {
      document.getElementById("scroll_Top").scrollIntoView({ behavior: "auto" })
    }, 1000);
  }

  useEffect(() => {
    const setDataOnPageLoad = async () => {
      await GetJob({});
    };
    setDataOnPageLoad();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Typography variant="h5" sx={{ mb: "10px", mt: "10px", fontWeight: 500 }}>
        {i18n(`common.myJobViewed`)}
      </Typography>
      {/* <CriteriaSearch onSearch={GetJob} pageType={EPageType.Viewed} /> */}
      <CriteriaSearch
        onSearch={(val, nPage) => { GetJob(val, nPage) }}
        // onSearch={GetJob}
        pageType={EPageType.Viewed}
        page={Page}
      />
      <Typography variant="subtitle1" gutterBottom>
        {isLoading ? (
          <Skeleton width={"20%"} />
        ) : (
          i18n(`searchJob.foundData`, objPaging.nTotal)
        )}
      </Typography>
      <Grid container spacing={3}>
        {isLoading ? (
          <ComponentSkeletonSearch />
        ) : (
          lstJob &&
          lstJob.map((item) => {
            return (
              <Grid item xs={12} key={item.sEncryptJobPostID}>
                <Paper
                  sx={{
                    p: 2,
                    margin: "auto",
                    flexGrow: 1,
                    cursor: "pointer",
                    backgroundColor: (theme) =>
                      theme.palette.mode === "dark" ? "#1A2027" : "#fff",
                  }}
                  onClick={() => {
                    navigate(
                      `/Guest/Company?sID=${item.sEncryptCompanyID}&isFromCompanyID=1`
                    );
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item sx={{ flexGrow: 1 }} xs container>
                      <Grid item xs container direction="column" spacing={2}>
                        <Grid item xs>
                          <Link
                            to={`/Guest/Company?sID=${item.sEncryptCompanyID}`}
                            onClick={(e) => e.stopPropagation()}
                            className="txt-underline"
                          >
                            <Typography variant="subtitle1" component="span">
                              {item.sCompanyName}
                            </Typography>
                          </Link>
                          <Typography variant="body1">
                            <FactoryIcon />
                            {"  " + item[`sIndustry_${language}`]}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography gutterBottom variant="body2" color="red">
                            {i18n(
                              `${i18nForthisPage}announce`,
                              item.nCountPost
                            )}
                          </Typography>
                          <Typography variant="body2">
                            {item.dViewed ?
                              <>
                                {`${i18n(
                                  `${i18nForthisPage}viewAt`,
                                  moment(item.dViewed).format("DD/MM/yyyy")
                                )}`}
                              </> : <>
                                {`${i18n(
                                  `${i18nForthisPage}viewAt`,
                                  " -"
                                )}`}
                              </>
                            }
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item>
                      <Grid container direction="column" spacing={2}>
                        <Grid item textAlign={"right"}>
                          <ButtonBase
                            sx={{ width: 86, height: 86 }}
                            onClick={(e) => {
                              navigate(
                                `/Guest/Company?sID=${item.sEncryptCompanyID}&isFromCompanyID=1`
                              );
                              e.stopPropagation();
                            }}
                          >
                            {
                              item.sUrlComImg !== null &&
                                item.sUrlComImg !== ""
                                ? <Img
                                  alt="complex"
                                  src={item.sUrlComImg}
                                /> //item.sUrlComImg
                                : <></>
                            }
                          </ButtonBase>
                        </Grid>
                        <Grid item>
                          <Grid container spacing={2}>
                            <Grid item>
                              {item.dSendDate && (
                                <Typography variant="body2">
                                  <EventAvailableIcon htmlColor="#F59A23" />
                                  {`${i18n(`${i18nForthisPage}buyAt`)} ${moment(
                                    item.dSendDate
                                  ).format("DD/MM/yyyy")}`}
                                </Typography>
                              )}
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            );
          })
        )}
      </Grid>
      {/* {lstJob.length > objPaging.nPageSize && ( */}
      <Grid container justifyContent={"center"} sx={{ marginTop: "3rem" }}>
        <Grid item>
          <Pagination
            variant="outlined"
            shape="rounded"
            showFirstButton
            showLastButton
            count={count}
            page={Page}
            onChange={(e, p) => {
              handleChange(e, p);
              onScrollTop();
            }}
          />
        </Grid>
      </Grid>
      {/* )} */}
    </>
  );
};
const Img = styled("img")({
  margin: "auto",
  display: "block",
  maxWidth: "100%",
  maxHeight: "100%",
});
const ComponentSkeletonSearch = () => {
  return (
    <>
      {Array.from(Array(6).keys()).map((item) => (
        <Grid item xs={12} key={item}>
          <Paper
            sx={{
              p: 2,
              margin: "auto",
              flexGrow: 1,
              cursor: "pointer",
              backgroundColor: (theme) =>
                theme.palette.mode === "dark" ? "#1A2027" : "#fff",
            }}
          >
            <Grid container spacing={2}>
              <Grid item sx={{ flexGrow: 1 }} xs container>
                <Grid item xs container direction="column" spacing={2}>
                  <Grid item xs>
                    <Typography variant="subtitle1" component="span">
                      <Skeleton />
                    </Typography>
                    <Typography variant="body2">
                      <Skeleton />
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography gutterBottom variant="body2" color="red">
                      <Skeleton />
                    </Typography>
                    <Typography variant="body2">
                      <Skeleton />
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item>
                <Grid container direction="column" spacing={2}>
                  <Grid item textAlign={"right"}>
                    <Skeleton variant="circular" height={86} width={86} />
                  </Grid>
                  <Grid item textAlign={"right"}>
                    <Typography variant="body2">
                      <Skeleton />
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      ))}
    </>
  );
};
export default MyJobViewedCOMP;