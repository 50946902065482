import { Box, Button, Card, Divider, Grid, Modal, Stack, Tooltip, Typography } from "@mui/material"
import { i18n } from "i18n";
import "./ServicesBPS.css";
import VerifiedIcon from '@mui/icons-material/Verified';
import { AxiosPost } from "service/CommonFunction/TS_function";
import React, { useEffect, useState } from "react";
import secureLocalStorage from 'react-secure-storage';
import { useNavigate } from "react-router-dom";
import { Close } from "@mui/icons-material";
const styles = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    borderRadius: "10px",
    p: 2,
    minHeight: 150
};
const ModalServiceLogin = ({isOpenModal,setOpenModal}) => {
    const lang = secureLocalStorage.getItem("language") || "th";
    const navigate = useNavigate();
    const i18nField = "OrderEmployer";
    const i18nFieldlog = "dialog";
    const i18nLabel = "SetupTemplate.label";
    const i18nCommon = 'common';
    const [arrPomotion, setArrPomotion] = useState([])
    // const [isOpenModal, setOpenModal] = useState(false);

    const BuyPackage = (type) => {
        //type 1 = regist | type 2 = login
        if (secureLocalStorage.getItem("connectionID_bps")) {
            if (secureLocalStorage.getItem("isSignOut_bps") && type == 1) {
                navigate(`/RegisterEmployer?sType=1`);
                setOpenModal(false)
            } else {
                navigate('/OrderEmployer');
                setOpenModal(false)
            }
        }
        else {
            if (secureLocalStorage.getItem("isSignOut_bps")) {
                if (type == 1) {
                    navigate(`/RegisterEmployer?sType=1`);
                    setOpenModal(false)
                } else {
                    navigate('/LoginEmployerNewForm');
                    setOpenModal(false)
                }

            } else {
                if (type == 1) {
                    navigate(`/RegisterEmployer?sType=1`);
                    setOpenModal(false)
                } else {
                    navigate('/LoginEmployerNewForm');
                    setOpenModal(false)
                }
            }
        }
    }

    return (
        <>
            <Modal
                open={isOpenModal}
                closeAfterTransition
            >
                <Box sx={{ ...styles, width: 350 }} className={" flex flex-col"}>
                    <div className="flex flex-row ">
                        <div className="flex flex-[1] justify-end">
                            <Tooltip title={i18n(`${i18nLabel}.close`)}
                                onClick={() => {
                                    setOpenModal(false)
                                }}>
                                <div className='rounded-[50%] bg-[#d1d1d1] w-[1.5em] h-[1.5em] flex cursor-pointer ease-linear duration-[100ms]  hover:bg-[#8c8c8c]'>
                                    <Close fontSize='small' className='m-[auto] text-[white]' />
                                </div>
                            </Tooltip>
                        </div>
                    </div>

                    <Stack direction="row" justifyContent="center" sx={{ my: "2%", mx: "2%" }}>
                        <Typography
                            sx={{ fontWeight: 600 }}
                            variant="body1"
                            component="b"
                        >
                            {i18n(`${i18nCommon}.buypackageforemployer`)} {/*สั่งซื้อแพ็กเกจสำหรับผู้ประกอบการ*/}
                        </Typography>
                    </Stack>

                    <Stack justifyContent={"center"} alignItems={"center"} direction="column" sx={{ marginTop: "20px", marginBottom: "20px" }}>
                        <Grid>
                            <div className='flex flex-row justify-center'>
                                <Button variant="contained" onClick={() => BuyPackage(1)} sx={{ borderRadius: "20px" }}>{i18n(`${i18nCommon}.register`)}</Button>
                            </div>
                        </Grid>
                        <Typography sx={{ color: "#C2C2C2", marginTop: "3%", marginBottom: "3%" }}>{i18n(`${i18nLabel}.Or`)}</Typography>
                        <Grid>
                            <div className='flex flex-row justify-center'>
                                <Button variant="contained" onClick={() => BuyPackage(2)} sx={{ borderRadius: "20px" }}>{i18n(`${i18nCommon}.loginformember`)}</Button>
                            </div>
                        </Grid>
                    </Stack>
                </Box>
            </Modal>
        </>
    )

}
export default ModalServiceLogin