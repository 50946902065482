import {
  Box,
  Grid,
  Typography,
  Paper,
  ButtonGroup,
  Button,
} from "@mui/material";
import ProfileProvider from "components/SeekerProfile/Context/ProfileContext";
import React, { useEffect, useState } from "react";
import MyProfile from "components/SeekerProfile/MyProfile";
import { useNavigate, useLocation } from "react-router-dom";
import { FnBlock_UI } from "service/SystemFunction/SystemFunction";
import {  AxiosPost } from "service/CommonFunction/TS_function";
import { DialogActionCreators } from "store/redux/DialogAlert";
import { useDispatch } from "react-redux";
import { i18n } from "i18n";
import JobApplySalary from "view/font/JobApply/JobApplySalary";
import secureLocalStorage from "react-secure-storage";
import "./JobApply.css";
export default function JobApply() {
  const location = useLocation();
  const { BlockUI, UnBlockUI } = FnBlock_UI();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [lang, setLang] = useState("th");
  const i18nField = "entities.SeekerProfile";

  const objJobDetail = location?.state ? location.state["objJobDetail"] : {};

  const [objCompanyDetail, setCompanyDetail] = useState(null);
  const [isProfile, setIsProfile] = useState(true);

  const objMode = location?.state ? location.state["objProfile"] : {};
  const [sMode, setMode] = useState(objMode.sMode);
	const searchParams = location.search;
	const sJobDetailID = new URLSearchParams(searchParams).get('sJobDetailID');
  const [sPostjobID ,setsPostjobID] = useState("");
  const onGetDetail = () => {
    BlockUI();
    AxiosPost("JobDetail/GetCompanyDetail", { sID: sJobDetailID }, (res) => {
      UnBlockUI();
      if (res.Status === 200) {
        setCompanyDetail(res);
        setsPostjobID(res.sJobPostID);
      } else {
        dispatch(DialogActionCreators.OpenDialogError(res.Message) as any);
      }
    },
      (err) => {
        UnBlockUI();
        if (err.Status === 401) {
          dispatch(
            DialogActionCreators.OpenDialogWarning("Token expired.", () => {
              navigate("/");
            }) as any
          );
        } else {
          dispatch(DialogActionCreators.OpenDialogError(err.Message) as any);
        }
      }
    );
  };

  useEffect(() => {

    onGetDetail();
    setTimeout(() => {
      document.getElementById("scroll_Top") && document.getElementById("scroll_Top").scrollIntoView();
    }, 200);

  }, []);

  return (
    <ProfileProvider>
      <Grid container spacing={2} >

        <Grid item xs={12}>
          <Typography variant="h6">{i18n(`${i18nField}.jobApply`)}</Typography>
        </Grid>

        <Grid item xs={12}>
          <Grid container>
            <Grid item md={8} sm={12} xs={12}>
              <Paper elevation={3} sx={{ p: 2 }}>
                <Typography>
                  {objCompanyDetail && objCompanyDetail.sCompanyName}
                </Typography>
              </Paper>
            </Grid>
            <Grid
              item
              md={4}
              sm={12}
              xs={12}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              <Typography fontSize={"26px"}>
                {objCompanyDetail &&
                  (lang === "th"
                    ? objCompanyDetail.sPositionTH
                    : objCompanyDetail.sPositionEN)}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={12} xs={12}>
          <Button
          className="apply-button"
            style={{
              backgroundColor: isProfile ? "#1976D2" : "white",
              color: isProfile ? "white" : "#1976D2",
              borderRadius: "20px",
              border: "1px solid #1976D2",
              cursor: "pointer",
         
            }}
            onClick={() => {
              setIsProfile(true); setMode("");

            }}
          >
            {i18n(`${i18nField}.UpdateProfile`)}
          </Button>
          <Button
          className="apply-buttonProfile"
            style={{
              backgroundColor: !isProfile ? "#1976D2" : "white",
              color: !isProfile ? "white" : "#1976D2",
              borderRadius: "20px",
              marginLeft: "10px",
              border: "1px solid #1976D2",
              cursor: "pointer",
         
            }}
            onClick={() =>
              setIsProfile(false)
            }
          >
            {i18n(`${i18nField}.SalaryDetail`)}
          </Button>

        </Grid>
        <Grid item xs={12} >
          {isProfile ? (
            <Grid className="apply-bg-color"
            >
              <MyProfile fnAfterSubmit={() => {
                setIsProfile(false)
              }} isJobApply={true} sIDNew={sPostjobID} sMode={sMode} />
            </Grid>
          ) : (
            <JobApplySalary />
          )}
        </Grid>
      </Grid>
    </ProfileProvider>
  );
}