const MyJobInterestTH = {
    MyJobInterest: {
        Btn: {
            BtnApply: "สมัครงาน",
            BtnDisInterest: "เลิกสนใจ",
        },
        Page: "งานที่สนใจ",
        Post: "ลงประกาศเมื่อ",
        LoadMore: "ดูเพิ่มเติม",
        Confirm: {
            Apply: "ต้องการสมัครงานหรือไม่",
            DisInterest: "ต้องการเลิกสนใจหรือไม่",
        }
    }
};
const MyJobInterestEN = {
    MyJobInterest: {
        Btn: {
            BtnApply: "Apply Job",
            BtnDisInterest: "Disinterest",
        },
        Page: "My Job Interested",
        LoadMore: "Load more",
        Post: "Job Posted",
        Confirm: {
            Apply: "Do you want to apply job?",
            DisInterest: "Do you want to dis-interest job?",
        }
        
    }
};

export { MyJobInterestEN, MyJobInterestTH };
