import {
  DataGridMui as Table,
  initRows,
  PaginationInterface,
  FilterFieldInterface,
} from "components/Common/Table/DataGridMui";
import { GridColumns } from "@mui/x-data-grid";
import { BtnViewOnTable } from "components/Common/Button";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import {
  AxiosPost,
  GetPermission,
  ResultAPI,
} from "service/CommonFunction/TS_function";
import { DialogActionCreators } from "store/redux/DialogAlert";
import { Stack, Grid } from "@mui/material";
import AdminCompanyTabPanel from "./AdminCompanyTabPanel";
import { i18n } from "i18n";
import secureLocalStorage from 'react-secure-storage';

export default function AdminCompanyBanner() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = location.search;
  const sID = new URLSearchParams(searchParams).get("sID");
  const [Banner, setBanner] = useState([]);
  const [Bannerlink, setBannerlink] = useState([]);
  const i18nField = 'entities.Admincompany';
  const [loadingTable, setLoadingTable] = useState(true);


  let arrrow = {
    ...initRows,
    sSortExpression: "sUpdateDate",
    sSortDirection: "desc",
  }
  const sPathName = window.location.pathname;
  const sStore = secureLocalStorage.getItem(sPathName);
  if (sStore && sStore !== "") {
    const objRowTemp = JSON.parse(sStore + "");
    if (objRowTemp != null) {
      arrrow = objRowTemp;
      arrrow.arrRows = [];
    }
  }
  const [dataRow, setDataRow] = useState<PaginationInterface>(arrrow);
  // const [dataRow, setDataRow] = useState<PaginationInterface>({
  //   ...initRows,
  //   sSortExpression: "sUpdateDate",
  //   sSortDirection: "desc",
  // });



  const filter: FilterFieldInterface[] = [
    { sTypeFilterMode: "input", sFieldName: "sBanner_Number" },
    { sTypeFilterMode: "input", sFieldName: "sPosition" },
    {
      sTypeFilterMode: "select", sFieldName: "sBanner_Day", lstDataSelect:
        [...Banner]
    },
    {
      sTypeFilterMode: "select", sFieldName: "sBannerLink", lstDataSelect:
        [...Bannerlink]
    },
    { sTypeFilterMode: "daterange", sFieldName: "sBanner_Range" },
    { sTypeFilterMode: "daterange", sFieldName: "sUpdateDate" },
    {
      sTypeFilterMode: "select", sFieldName: "sStatus", lstDataSelect: [
        { value: "1", keyId: 0, label: i18n(`${i18nField}.ActiveName`) },
        { value: "0", keyId: 1, label: i18n(`${i18nField}.InactiveName`) },
      ],
    },
  ];

  useEffect(() => {

    loadData(dataRow);
  }, []);

  const loadData = (p: PaginationInterface) => {
    setLoadingTable(true);
    const objFilter = { ...p, sID: sID };
    AxiosPost(
      "AdminCompany/search_banner",
      objFilter,
      (result) => {
        setLoadingTable(false);
        setBanner(result.lstBanner_Day);
        setBannerlink(result.lstBannerlink)
        setDataRow({
          ...p,
          arrRows: result.lstData,
          nDataLength: result.nDataLength,
          nPageIndex: result.nPageIndex,
        });
      },
      (err) => {
        if (err.response && err.response.status === 401) {
          dispatch(
            DialogActionCreators.OpenDialogWarning("Token expired.", () => {
              navigate("/");
            }) as any
          );
        }
      }
    );
  };

  const onEdit = (sCompanyID: string, sID: string, item: any) => {
    // window.localStorage.setItem("sSubRouteName", item.sSection);
    navigate(
      `/AdminCompanyBannerDetail?sID=${encodeURI(
        sCompanyID
      )}&sBannerID=${encodeURI(sID)}`
    );
  };

  const dataColumn: GridColumns = [
    {
      field: "ปุ่ม (ดูรายละเอียด)",
      headerName: "",
      type: "actions",
      resizable: false,
      sortable: false,
      getActions: (item) => {
        return [
          <BtnViewOnTable
            txt="ดูรายละเอียด"
            onClick={() => onEdit(item.row.sCompanyID, item.row.sID, item.row)}
          />,
        ];
      },
    },
    {
      field: "sBanner_Number",
      headerName: "เลขที่โฆษณา",
      headerAlign: "center",
      align: "center",
      resizable: false,
      sortable: true,
      minWidth: 300,
      flex: 2
    },
    {
      field: "sPosition",
      headerName: "ตำแหน่งโฆษณา",
      headerAlign: "center",
      align: "left",
      resizable: false,
      sortable: true,
      minWidth: 250,
      flex: 1
    },
    {
      field: "sBanner_Day",
      headerName: "แพ็กเกจ",
      headerAlign: "center",
      align: "center",
      resizable: false,
      sortable: true,
      minWidth: 300,
      flex: 2
    },
    {
      field: "sBanner_Range",
      headerName: "ช่วงเวลาโฆษณา",
      headerAlign: "center",
      align: "center",
      resizable: false,
      sortable: true,
      minWidth: 150,
      flex: 1
    },
    {
      field: "sBannerLink",
      headerName: "ลิงก์",
      headerAlign: "center",
      align: "left",
      resizable: false,
      sortable: true,
      minWidth: 200,
      flex: 1
    },
    {
      field: "sUpdateDate",
      headerName: "วันที่ปรับปรุง",
      headerAlign: "center",
      align: "center",
      resizable: false,
      sortable: true,
      minWidth: 180,
      flex: 1
    },
    {
      field: "sStatus",
      headerName: "สถานะ",
      headerAlign: "center",
      align: "center",
      resizable: false,
      minWidth: 180,
      flex: 1
    }
  ];

  return (
    <Grid container>
      <Grid item xs={12} sx={{ mx: "2%" }}>
        {/* // sx={{ mx: "2%", width: "100%" }}
      > */}
        <AdminCompanyTabPanel tabNo={2} />
        <Grid
          sx={{
            marginTop: "2%",
          }}
        ></Grid>
        <Table
          isLoading={loadingTable}
          filterField={filter}
          Rows={dataRow}
          Columns={dataColumn}
          OnLoadData={(p) => loadData(p)}
        />
      </Grid>
    </Grid>
  );
}
