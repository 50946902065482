import { Box } from "@mui/material";
import MyJobViewedCOMP from "components/MyJob/MyJobViewed";
import { Helmet } from 'react-helmet';


const MyJobViewed = () => {
  return (
    <>
      <Box sx={{ display: "none !important", visibility: "hidden" }}>
        <Helmet>
          <title> บริษัทที่ซื้อประวัติคุณ </title>
        </Helmet>
      </Box>


      <MyJobViewedCOMP />
    </>
  );
};

export default MyJobViewed;  