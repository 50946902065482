import { createBrowserHistory } from "history";
import { MsalAuthProvider, AuthenticationState } from 'react-aad-msal';
import configureStore from "../configureStore";
import { initAuthenParams, initOption } from "../reducers/login_azure";
export const loginAzure = async () => {
    const history = createBrowserHistory({ window: window });
    const store = configureStore(history);
    const authProvider = store.getState().azureReducer.ProviderMsal;
    await authProvider.login();
    return {
        type: 'LOGIN',
        payload: {
            AuthenticationState: AuthenticationState.InProgress,
            userData: authProvider.getAccount()
        }
    }
}

export const logoutAzure = async () => {
    const history = createBrowserHistory({ window: window });
    const store = configureStore(history);
    const authProvider = store.getState().azureReducer.ProviderMsal;
    await authProvider.logout();
    return {
        type: 'LOGOUT',
        payload: {
            AuthenticationState: AuthenticationState.Unauthenticated,
            userData: null
        }
    }
}

export const setConfig = (sClientId, sTenantId) => {
    let newConfig = {
        auth: {
            authority: `https://login.microsoftonline.com/${sTenantId}`,
            clientId: sClientId,
            postLogoutRedirectUri: window.location.origin + "/", //// window.location.protocol + "//" + window.location.host + "/", //window.location.origin,
            validateAuthority: true,
            redirectUri: window.location.origin, ////window.location.protocol + "//" + window.location.host,
            navigateToLoginRequestUrl: true,
        },
        cache: {
            CacheStorage: "sessionStorage",
            storeAuthStateInCookie: true
        }
    }
    const authProvider = new MsalAuthProvider(newConfig, initAuthenParams, initOption)
    return {
        type: 'SET_CONFIG',
        payload: {
            ProviderMsal: authProvider
        }
    }
}

export const setUpGoogleProvider = (providerGoogle) => {
    return {
        type: 'SET_UP',
        payload: {
            ProviderGoogle: providerGoogle
        }
    }
}