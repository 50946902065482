import {  Grid,  } from '@mui/material';
import ShowTotalPackage from '../Show/ShowTotalPackage';
import FromCreateJobPackage from './FormCreateJobPackage';
import { i18n } from 'i18n';


const CreateJobPackage = () => {
    const i18nField = 'entities.Package';
    return (
        <Grid className="CreateJobPackage">
            <Grid container xs={12}>
                <Grid item sx={{ mx: "2%", width: "100%" }}>
                    <ShowTotalPackage />
                </Grid>
                <Grid item sx={{ mx: "2%", width: "100%",marginTop: "2%" }}>
                <h3> แพ็กเกจ JOB POST </h3>
                </Grid>
                <Grid item sx={{ mx: "2%", width: "100%",marginTop: "2%" }}>
                    <FromCreateJobPackage/>
                </Grid>
            </Grid>
        </Grid>
    );
}
export default CreateJobPackage