import React, { useState, useEffect } from "react";
import {
  LicenseInfo,
  DataGridPro,
  GridRowModel,
  GridOverlay,
  useGridApiRef,
  GridToolbarFilterButton,
  GridToolbarContainer,
  GridPanelWrapper,
  GridToolbarColumnsButton,
  GridRowId,
} from "@mui/x-data-grid-pro";
import {
  styled,
  Box,
  Pagination,
  Stack,
  Select,
  MenuItem,
  Typography,
  TextField,
  Autocomplete,
  Button,
  AppBar,
  Checkbox,
  Tooltip,
  Grid,
  Dialog,
  Toolbar,
  IconButton,
  Hidden,
} from "@mui/material";
import {
  ExpandMore,
  ExpandLess,
  Add,
  UnfoldMore,
  SaveAlt,
} from "@mui/icons-material";
import PropTypes from "prop-types";
import MultiSelect from "../../../components/Common/ElementInForm/MultiSelectWithChip";
import { BtnSearch, BtnCancel } from "../../../components/Common/Button";
import { FormProvider, useForm } from "react-hook-form";
import AutoCompleteInTable from "../../../components/Common/Table/AutoCompleteInTable";
import ClearIcon from "@mui/icons-material/Clear";
import AutoCompleteRenderItem from "../../../components/Common/ElementInForm/AutoCompleteRenderItem";
import DateRange from "../../../components/Common/ElementInForm/DateRangeSigleBox";
import { i18n } from "i18n";
import FooterOrder from "view/font/Home/FooterOrder";
import CloseIcon from "@mui/icons-material/Close";
import ConditionOrder from "view/font/Home/ConditionOrder";
import { AxiosPost, ResultAPI } from "service/CommonFunction/TS_function";
import { useNavigate } from "react-router-dom";
import { objContent } from "components/Banner/AdminContent/ManageContent";
import ContentPage from "view/font/Home/ContentPage";

import secureLocalStorage from 'react-secure-storage';
import moment from "moment";

LicenseInfo.setLicenseKey(
  "5b31a40e910ea0c2a35371ae57c5b5beT1JERVI6MzYzMjEsRVhQSVJZPTE2NzQyOTE1NTAwMDAsS0VZVkVSU0lPTj0x"
);

export interface PaginationInterface {
  nPageIndex: number;
  nPageSize: number;
  arrRows: GridRowModel[];
  nDataLength: number;
  sSortExpression: string;
  sSortDirection: string;
  sID: string;
}

export interface FilterFieldInterface {
  sFieldName: string;
  sLabel?: string;
  sInputMode?:
  | "text"
  | "numeric"
  | "none"
  | "search"
  | "email"
  | "decimal"
  | "tel"
  | "url";
  sTypeFilterMode: "input" | "select" | "multiselect" | "daterange";
  sType?: string;
  lstDataSelect?: OptionSelect[];
}
interface OptionSelect {
  value: any;
  label: any;
  keyId: any;
}

export const initRows: PaginationInterface = {
  nPageIndex: 1,
  nPageSize: 10,
  arrRows: [],
  nDataLength: 0,
  sSortExpression: "",
  sSortDirection: "",
  sID: "",
};

const StyledGridOverlay = styled(GridOverlay)(({ theme }) => ({
  flexDirection: "column",
}));
const GridToolbarClearFilterButton = styled(Button)(({ theme }) => ({
  fontSize: "0.8125rem",
  padding: "4px 5px",
}));
const i18nField = "dialog";
export function DataGridMui(props) {
  const {
    minHeightCustom,
    onCickRow,
    maxRowNoScroll,
    sxCustomHeader,
    sxCustomTable,
    backgroundHeaderCustom,
    lstPageSize,
    Rows,
    Columns,
    isLoading,
    OnLoadData,
    isShowCheckBox,
    filterField,
    onDelete,
    disableColumnMenu,
    isNotShowTotal,
    isNotShowPagination,
    isCustomOnFilter,
    onFilterCustom,
    isRowSelectable,
    isExportExcel,
    onExportExcel,
    HiddenToolHead,
    HandleDataMode,
    ContentExpand,
    onExpand,
    nExpandRowLength,
    id,
    rowReordering,
    onRowOrderChange,
    isAddCondition,
    isJobPackageShow,
    TermsType,
    isJobPackage,
    isCoinPackage
  } = props;
  const apiRef = useGridApiRef();
  let lstPageData = [];
  let lstPage = [];
  const navigate = useNavigate();
  const [lstSelectRowId, setLstSelectRowId] = useState([]);
  const [IsAddCondition, setIsAddCondition] = useState(true);
  const [nTerms, setTermType] = useState(0);
  const [isShowPreview, setIsShowPreview] = useState<boolean>(false);
  const [IsJobPackageShow, setIsJobPackageShow] = useState<boolean>(false);
  const [lstContentHead, setlstContentHead] = useState([]);
  const [lstContentTerm, setlstContentTerm] = useState([]);
  const [lstContent, setLstPreviewContent] = useState<objContent[]>([]);
  const [sContendHead, setContentHead] = useState<objContent[]>([]);
  const [detailPanelExpandedRowIds, setDetailPanelExpandedRowIds] = React.useState<GridRowId[]>([]);
  const CustomNoRowsOverlay = () => {
    return (
      <StyledGridOverlay>
        <Box>No Data</Box>
      </StyledGridOverlay>
    );
  };
  // nExpandRowLength != undefined &&
  //   console.log("nExpandRowLength", nExpandRowLength);
  const clearFilter = () => {

    const sID = id ? id : "";
    const windowlocation = window.location.pathname;
    secureLocalStorage.removeItem(windowlocation + sID);

    let cloneData = {
      nPageSize: Rows.nPageSize,
      nPageIndex: 1,
      sSortExpression: "",
      sSortDirection: "",
      arrRows: [],
    };

    if (isCustomOnFilter) {
      onFilterCustom(cloneData, null);
    } else {
      ////OnLoadData(cloneData);
      window.location.reload()
    }
  };

  //console.log("Rows.nPageSize", Rows.nPageSize);
  //console.log("Rows.arrRows.length ", Rows.arrRows.length);
  //console.log("ใหม่ Rows.nPageSize <= 10", Rows.nPageSize <= 10);
  //console.log("เก่า Rows.arrRows.length < Rows.nPageSize", Rows.arrRows.length < Rows.nPageSize);


  useEffect(() => {
    setIsAddCondition(isAddCondition);
    setIsJobPackageShow(isJobPackageShow);
    setTermType(TermsType);
  }, [])

  const onOpenCondition = async (nID) => {
    let param = {
      nID,
    };

    let lstPreviewContent = []
    let show = []
    let HeadName = []
    await AxiosPost(`HomeFront/getData_ContentDetail`, param, (res) => {
      ResultAPI(res, "", () => {
        setlstContentTerm(res.contentShow);
        show = res.contentShow
        HeadName = res.contentHead
      });
    },
      (err) => {
        if (!err.response) {
          navigate("/error");
          return;
        }
      }
    );
    show.forEach((f, i) => {
      const nType = f.nType
      let sContent = f.sContent
      let sPageName = f.sPageName
      let fileContent = f.sFilePath;
      let objViewContent = {
        nType: nType,
        sContent: sContent,
        // sPageName: sPageName,
        sFileNameSys: fileContent
      }
      lstPreviewContent.push(objViewContent)
    })
    setContentHead(HeadName);
    setLstPreviewContent(lstPreviewContent)
    setIsShowPreview(true)
  }

  const QuickSearchToolbar = (isFilter: boolean) => {

    const sID = id ? id : "";
    const sPathName = window.location.pathname;
    const sStorage = secureLocalStorage.getItem(sPathName + sID);

    let isStorage = false;
    if (sStorage && sStorage !== "") {
      isStorage = true;
    }

    return (
      <Grid container className="head-container" direction="row"
        sx={{
          px: 0.5,
          pb: 0.5,
          bgcolor: "white",
        }}
      >
        <Grid item lg={10} md={9} sm={7} xs={10} justifyContent="start">
          <GridToolbarContainer>
            {/* <Tooltip title={"Show columns"}> */}

            {isFilter ?
              <Tooltip title={i18n(`${i18nField}.Showcolumns`)}>
                <GridToolbarColumnsButton />
              </Tooltip>
              :
              <></>
            }

            {isFilter ? (<GridToolbarFilterButton disableTouchListener={isLoading} />) : null}

            {filterField && filterField.some((item) => isStorage || (Rows[item.sFieldName] == 0 && item.sTypeFilterMode == "select")) ?
              (<Tooltip title={"Clear filters"}>
                <GridToolbarClearFilterButton
                  disabled={isLoading}
                  onClick={() => clearFilter()}>
                  <ClearIcon sx={{ marginRight: "0.2em" }} />
                  Clear filters
                </GridToolbarClearFilterButton>
              </Tooltip>)
              :
              <></>
            }

            {isExportExcel ?
              (<Tooltip title={"Export excel"}>
                <GridToolbarClearFilterButton
                  disabled={isLoading}
                  onClick={() => onExportExcel()}>
                  <SaveAlt sx={{ marginRight: "0.2em" }} />
                  Export excel
                </GridToolbarClearFilterButton>
              </Tooltip>)
              : null}

          </GridToolbarContainer>
        </Grid>
        <Grid item lg={2} md={3} sm={5} xs={12} justifyContent="end" >
          {isJobPackage && IsAddCondition ? (
            <Button
              sx={{ marginTop: "2%", backgroundColor: "#3d5afe", width: "100%", color: "#feffff", borderRadius: "50px", "&:hover": { backgroundColor: "#3149CB" } }}
              onClick={() => onOpenCondition(nTerms)}>
              {i18n(`${i18nField}.Terms`)}
            </Button>
          ) : isCoinPackage && IsAddCondition ? (
            <Button
              sx={{ marginTop: "2%", backgroundColor: "#121858", width: "100%", color: "#feffff", borderRadius: "50px", "&:hover": { backgroundColor: "#0D1240" } }}
              onClick={() => onOpenCondition(nTerms)}
            >
              {i18n(`${i18nField}.Terms`)}
            </Button>
          ) : null}
        </Grid>
      </Grid>
    );
  };

  const handleDetailPanelExpandedRowIdsChange = React.useCallback(
    (newIds: GridRowId[]) => {
      let nLastIndex = newIds.length - 1;
      let nLastId = newIds[nLastIndex];
      setDetailPanelExpandedRowIds(nLastId ? [nLastId] : []);
      nLastId && onExpand(nLastId);
    }, []
  );

  const CustomPagination = () => {

    const maxRowSizePerPage = Rows.nPageIndex * Rows.nPageSize;
    const rowTotalCount = Rows.nDataLength;
    const minRowSizePerPage = Rows.nPageIndex > 1 ? (Rows.nPageIndex - 1) * Rows.nPageSize + 1 : 1;
    const pageCount = Math.ceil(rowTotalCount / Rows.nPageSize);
    const sumMaxRow = rowTotalCount > maxRowSizePerPage ? maxRowSizePerPage : rowTotalCount;
    lstPageData = [];
    lstPage = [];
    for (let i = 0; i < pageCount; i++) {
      lstPageData.push({ label: i + 1, value: i });
      lstPage.push(<MenuItem value={i}>{i + 1}</MenuItem>);
    }
    return rowTotalCount < 10 && !isNotShowTotal ? (
      <Box sx={{ display: "block", width: "100%" }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignContent="center"
          sx={{ px: 2 }}
        >
          {lstSelectRowId.length > 0 && onDelete != undefined ? (
            <BtnCancel
              isCircleWithOutText
              onClick={() => onDelete(lstSelectRowId)}
            />
          ) : (
            <Box></Box>
          )}
          <Stack justifyContent="center">
            <Typography>Total : {rowTotalCount}</Typography>
          </Stack>
        </Stack>
      </Box>
    ) : (
      !isNotShowPagination && (
        <Stack
          direction="row"
          sx={{ px: 2, minWidth: "750px" }}
          justifyContent="space-between"
          alignItems="center"
          flex={1}
          spacing={1}
        >
          {lstSelectRowId.length > 0 && onDelete != undefined ? (
            <BtnCancel
              isCircleWithOutText
              txt={i18n(`common.destroy`)}
              onClick={() => onDelete(lstSelectRowId)}
            />
          ) : (
            <Box></Box>
          )}
          <Stack direction="row" alignItems="center" spacing={1}>
            <Typography component={"span"}>Per page:</Typography>
            {/* <Typography component={"span"}>{i18n(`pagination.labelRowsPerPage`)}</Typography> */}
            <Select
              label=""
              size="small"
              disabled={isLoading}
              autoWidth
              sx={{
                height: 30,
                ".MuiOutlinedInput-notchedOutline > legend": {
                  width: 0,
                },
              }}
              value={lstPageSize.indexOf(Rows.nPageSize)}
              onChange={(e) => {
                apiRef.current.setPageSize(lstPageSize[e.target.value]);

                const nPageSize = lstPageSize[e.target.value];
                let cloneData = { ...Rows, nPageIndex: 1, nPageSize: nPageSize, arrRows: [] };
                onSaveLocalStorage(cloneData)
              }}
            >
              {lstPageSize.map((item, index) => (
                <MenuItem key={`selPageSize_${index}`} value={index}>
                  {item}
                </MenuItem>
              ))}
            </Select>
            <Typography>{`${minRowSizePerPage > sumMaxRow ? sumMaxRow : minRowSizePerPage
              } - ${sumMaxRow} of ${rowTotalCount}`}</Typography>
            <Typography component={"span"}>Go to</Typography>
            {/* <Typography component={"span"}>{i18n(`pagination.Goto`)}</Typography> */}
            <AutoCompleteInTable
              disabled={isLoading}
              small
              sxCustom={{
                ".MuiOutlinedInput-root": {
                  padding: "4.5px 14px !important",
                  height: "30px",
                  ".MuiOutlinedInput-notchedOutline > legend": {
                    width: 0,
                  },
                },
              }}
              objValue={{ label: Rows.nPageIndex, value: Rows.nPageIndex - 1 }}
              funcOnChange={(event, newValue) => {
                let cloneData = {
                  ...Rows,
                  nPageIndex: newValue.label,
                  arrRows: [],
                };

                if (newValue.label != Rows.nPageIndex) {
                  OnLoadData(cloneData);
                }

              }}
              lstOption={lstPageData}
              disClearable
            />
            <Pagination
              color="primary"
              count={pageCount}
              page={Rows.nPageIndex}
              variant="outlined"
              shape="rounded"
              onChange={(event, value) => {
                if (!isLoading) {
                  let cloneData = { ...Rows, nPageIndex: value, arrRows: [] };
                  onSaveLocalStorage(cloneData)
                  ////console.log("onChange", cloneData);
                  OnLoadData(cloneData);
                }
              }}
            />
          </Stack>
        </Stack>
      )
    );
  };
  const CustomFilterPanel = () => {
    const form = useForm();
    const [innerStateRow, setInnerState] = useState(Rows);

    const sID = id ? id : "";
    const windowlocation = window.location.pathname;
    const sFilter = secureLocalStorage.getItem(windowlocation + sID);
    let objDataFilter = sFilter ? JSON.parse(sFilter + "") : {};
    ////console.log("Storage objDataFilter", objDataFilter);

    const _onSubmit = (e) => {
      let cloneData = {
        nPageSize: innerStateRow.nPageSize,
        nPageIndex: innerStateRow.nPageIndex,
        sSortExpression: "",
        sSortDirection: "",
        rows: [],
      };
      filterField.forEach((item) => {
        let filter = e[`inp_filter_${item.sFieldName}`];
        if ((filter != undefined && filter != "") || (item.sTypeFilterMode == "select" && filter == 0)) {
          if (item.sTypeFilterMode == "daterange" && filter[0] != null && filter[1] != null) {
            let dFirstDate = new Date(filter[0].year(), filter[0].month(), filter[0].date(), 0, 0, 0);
            let dLastDate = new Date(filter[1].year(), filter[1].month(), filter[1].date(), 23, 59, 59);
            cloneData[`lst${item.sFieldName}`] = [
              dFirstDate.getTime(),
              dLastDate.getTime(),
            ];
          }
          cloneData[item.sFieldName] = filter;
        }
      });

      onSaveLocalStorage(cloneData);

      if (isCustomOnFilter) {
        onFilterCustom(cloneData, e);
      } else {
        OnLoadData(cloneData);
      }
    };

    return (
      <div style={{ maxHeight: 200, overflow: "auto", width: "100%" }}>
        <Stack style={{ padding: "10px" }} direction="column" spacing={1}>
          <FormProvider {...form}>
            {filterField.map((item) => {

              const labelFilter = item.sLabel ? item.sLabel : Columns.find((f) => f.field == item.sFieldName).headerName;
              form.setValue(`inp_filter_${item.sFieldName}`, innerStateRow[item.sFieldName] ? innerStateRow[item.sFieldName] : null);

              const Storage = objDataFilter[item.sFieldName];

              switch (item.sTypeFilterMode) {
                case "input": {
                  return (
                    <TextField
                      key={`tf_${item.sFieldName}`}
                      type={item.sType || "text"}
                      inputProps={{
                        inputMode: item.sInputMode != null ? item.sInputMode : "text",
                      }}
                      size="small"
                      autoComplete="off"
                      label={labelFilter}
                      name={`inp_filter_${item.sFieldName}`}
                      {...form.register(`inp_filter_${item.sFieldName}`)}
                      ///value={innerStateRow[item.sFieldName] ? innerStateRow[item.sFieldName] : Storage ? Storage : ""}
                      value={innerStateRow[item.sFieldName] ? innerStateRow[item.sFieldName] : ""}

                      onChange={(e) => {
                        form.setValue(`inp_filter_${item.sFieldName}`, e.currentTarget.value)
                        innerStateRow[item.sFieldName] = e.target.value
                        setInnerState({ ...innerStateRow })
                      }}
                    />
                  );
                }
                case "select": {
                  return (
                    <Autocomplete
                      key={`ac_${item.sFieldName}`}
                      renderOption={(props, option) => {
                        return (
                          <li {...props} key={option.value}>
                            {option.label}
                          </li>
                        );
                      }}
                      defaultValue={
                        innerStateRow[item.sFieldName] != undefined ?
                          {
                            label: item.lstDataSelect.find((f) => f.value == innerStateRow[item.sFieldName]).label,
                            value: innerStateRow[item.sFieldName],
                          } : Storage ? {
                            label: item.lstDataSelect.find((f) => f.value == Storage).label,
                            value: Storage
                          } : null

                        // innerStateRow[item.sFieldName] != undefined ?
                        //   {
                        //     label: item.lstDataSelect.find((f) => f.value == innerStateRow[item.sFieldName]).label,
                        //     value: innerStateRow[item.sFieldName],
                        //   } : null
                      }
                      size="small"
                      name={`inp_filter_${item.sFieldName}`}
                      {...form.register(`inp_filter_${item.sFieldName}`)}
                      onChange={(e, v) => {
                        form.setValue(`inp_filter_${item.sFieldName}`, v?.value ?? null);
                        innerStateRow[item.sFieldName] = v?.value ?? null;
                        setInnerState({ ...innerStateRow });
                      }}
                      options={item.lstDataSelect}
                      getOptionLabel={(itemOption: any) => {
                        return `${itemOption.label}`;
                      }}
                      renderInput={(params) => (
                        <TextField label={labelFilter} {...params} />
                      )}
                    />
                  );
                }
                case "multiselect": {
                  return (
                    <MultiSelect
                      key={`ms_${item.sFieldName}`}
                      valueData={innerStateRow[item.sFieldName] ? innerStateRow[item.sFieldName] : []}
                      fnOnChange={(s) => {
                        innerStateRow[item.sFieldName] = s; setInnerState({ ...innerStateRow });
                      }}
                      onDeleteChip={(e) => {
                        innerStateRow[item.sFieldName] = innerStateRow[item.sFieldName].filter((f) => f != e);
                        setInnerState({ ...innerStateRow });
                      }}
                      small
                      name={`inp_filter_${item.sFieldName}`}
                      options={item.lstDataSelect}
                      label={labelFilter}
                    />
                  );
                }
                case "daterange": {
                  return (
                    <DateRange
                      key={`dr_${item.sFieldName}`}
                      value={innerStateRow[item.sFieldName] ? innerStateRow[item.sFieldName]
                        :
                        Storage ? [moment(Storage[0]), moment(Storage[1])] : [null, null]
                      }
                      small
                      name={`inp_filter_${item.sFieldName}`}
                      label={labelFilter}
                    />
                  );
                }
              }
            })}
            <BtnSearch txt={i18n(`${i18nField}.Search`)} onClick={form.handleSubmit(_onSubmit)} />
          </FormProvider>
        </Stack>
      </div>
    );
  };


  const onSaveLocalStorage = (cloneData) => {
    const sID = id ? id : "";
    let sPathName = window.location.pathname;
    const stringify = JSON.stringify(cloneData);
    secureLocalStorage.setItem(sPathName + sID, stringify);
  }


  return (
    <>
      <Dialog
        fullScreen
        open={isShowPreview}
        onClose={() => setIsShowPreview(false)}
      >
        <Toolbar sx={{ position: "relative", marginBottom: "1rem" }}>
          <IconButton
            edge="end"
            color="inherit"
            onClick={() => setIsShowPreview(false)}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
        <Grid container>
          <Grid item xs={12}>
            <ContentPage lstContent={lstContent} OrderEmp={true} sContendHead={sContendHead} />
          </Grid>
        </Grid>
        {/* <AppBar sx={{ position: "absolute",top: "95%", backgroundColor: "#019dd4" }}>
        {/* <AppBar sx={{ position: "relative", backgroundColor: "#019dd4" }}> */}
        {/* <Toolbar>
            <FooterOrder />
          </Toolbar>
        </AppBar> */}
      </Dialog>

      <DataGridPro
        rowReordering={rowReordering}
        onRowOrderChange={onRowOrderChange}
        sx={{
          ".narongrit": "narongrit",
          minHeight: Rows.arrRows.length < maxRowNoScroll ? "0" : minHeightCustom,
          maxHeight: minHeightCustom,
          //  maxHeight: Rows.arrRows.length < maxRowNoScroll ? 0 : minHeightCustom,
          " .MuiDataGrid-main": {
            overflowX: "hidden",
            overflowY: "auto",
            ".MuiDataGrid-columnHeaders > .MuiDataGrid-columnHeadersInner > .MuiDataGrid-columnHeader--sortable > .MuiDataGrid-columnHeaderDraggableContainer > div ":
            {
              ".MuiDataGrid-iconButtonContainer": {
                visibility: "initial",
                width: "auto",
              },
              ".MuiDataGrid-columnHeaderTitle": {
                overflow: "visible",
              },
            },
          },
          // " .MuiDataGrid-overlay": {
          //   bgcolor: "white",
          // },
          " .MuiDataGrid-columnHeaders": {
            background: backgroundHeaderCustom,
            ...sxCustomHeader,
          },
          " .MuiDataGrid-cell": {
            color: "#333333",
            // backgroundColor: "white",
          },
          " .MuiDataGrid-footerContainer": {
            // backgroundColor: "white",
            overflowX: "auto",
            overflowY: "hidden",
            maxWidth: "100vw",
          },
          ".MuiDataGrid-columnHeader, .MuiDataGrid-cell": {
            borderRight: `1px solid rgba(224, 224, 224, 1)`,
          },
          " .MuiDataGrid-columnSeparator": {
            opacity: "0!important",
          },
          " .MuiDataGrid-columnsContainer, .MuiDataGrid-cell": {
            borderBottom: `1px solid rgba(224, 224, 224, 1)`,
          },
          " .row-odd": {
            bgcolor: "#F1F1F1",
          },
          ...sxCustomTable,
        }}
        density="compact"
        localeText={{
          columnsPanelHideAllButton: i18n(`${i18nField}.Hide`),
          columnsPanelShowAllButton: i18n(`${i18nField}.ShowAll`),
          columnsPanelTextFieldLabel: i18n(`${i18nField}.Searchcolumns`),
          columnsPanelTextFieldPlaceholder: i18n(`${i18nField}.Showcolumns`),
          toolbarColumns: i18n(`${i18nField}.Showcolumns`),
          checkboxSelectionHeaderName: i18n(`${i18nField}.Select`),
        }}
        getRowClassName={(params) =>
          params.indexRelativeToCurrentPage % 2 === 0 ? "row-even" : "row-odd"
        }
        rows={isLoading ? [] : Rows.arrRows}
        getRowId={(item) => item.sID}
        columns={Columns}
        pagination
        disableSelectionOnClick
        apiRef={apiRef}
        checkboxSelection={isShowCheckBox}
        loading={isLoading}

        autoHeight={Rows.arrRows.length < 10}
        //autoHeight={id === "main" ? false : Rows.arrRows.length < Rows.nPageSize}
        //autoHeight={id === "main" ? false : Rows.nPageSize <= 10}

        onSelectionModelChange={(itm) => setLstSelectRowId(itm)}
        filterMode={HandleDataMode}
        paginationMode={HandleDataMode}
        onRowClick={(e) => onCickRow(e)}
        sortingMode={HandleDataMode}
        pageSize={Rows.nPageSize}
        rowCount={Rows.nDataLength}
        page={Rows.nPageIndex}
        disableMultipleColumnsSorting
        onSortModelChange={(model, detail) => {
          if (model.length > 0) {
            let cloneData = {
              ...Rows,
              sSortExpression: model[0].field,
              sSortDirection: model[0].sort,
              arrRows: [],
            };
            OnLoadData(cloneData);
          } else {
            let cloneData = {
              ...Rows,
              sSortExpression: "",
              sSortDirection: "",
              arrRows: [],
            };
            OnLoadData(cloneData);
          }
        }}
        hideFooterSelectedRowCount
        components={{
          FilterPanel: !HiddenToolHead && filterField.length > 0 ? CustomFilterPanel : () => null,
          Toolbar: () => !HiddenToolHead ? QuickSearchToolbar(filterField.length > 0) : null,
          Pagination: CustomPagination,
          NoRowsOverlay: CustomNoRowsOverlay,
          ColumnSortedDescendingIcon: () => <ExpandMore />,
          ColumnSortedAscendingIcon: () => <ExpandLess />,
          ColumnUnsortedIcon: () => <UnfoldMore sx={{ fontSize: "1em" }} />,
        }}
        disableColumnMenu={disableColumnMenu}
        onPageSizeChange={(pageSize) => {
          let cloneData = {
            ...Rows,
            nPageSize: pageSize,
            nPageIndex: 1,
            rows: [],
          };
          OnLoadData(cloneData);
        }}
        isRowSelectable={isRowSelectable}
        rowThreshold={0}
        getDetailPanelHeight={
          ContentExpand
            ? () =>
              nExpandRowLength > 0
                ? 175 + nExpandRowLength * 36
                : nExpandRowLength === 0
                  ? 220
                  : 0
            : null
        }
        getDetailPanelContent={ContentExpand ? () => ContentExpand : null}
        detailPanelExpandedRowIds={detailPanelExpandedRowIds}
        onDetailPanelExpandedRowIdsChange={
          handleDetailPanelExpandedRowIdsChange
        }
      />
    </>
  );
}
DataGridMui.defaultProps = {
  Rows: initRows,
  isLoading: false,
  isShowCheckBox: false,
  filterField: [],
  onDelete: undefined,
  disableColumnMenu: true,
  lstPageSize: [10, 25, 50, 100],
  backgroundHeaderCustom: "#eeeeee",
  maxRowNoScroll: 10,
  minHeightCustom: 500,
  onCickRow: () => { },
  isCustomOnFilter: false,
  onFilterCustom: () => { },
  HiddenToolHead: false,
  HandleDataMode: "server",
  isAddCondition: false
};

DataGridMui.propTypes = {
  Rows: PropTypes.object,
  HiddenToolHead: PropTypes.bool,
  Columns: PropTypes.array.isRequired,
  OnLoadData: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  isShowCheckBox: PropTypes.bool,
  disableColumnMenu: PropTypes.bool,
  filterField: PropTypes.array,
  OnDelete: PropTypes.func,
  isNotShowTotal: PropTypes.bool,
  isNotShowPagination: PropTypes.bool,
  lstPageSize: PropTypes.array,
  backgroundHeaderCustom: PropTypes.string,
  sxCustomHeader: PropTypes.any,
  sxCustomTable: PropTypes.any,
  maxRowNoScroll: PropTypes.number,
  minHeightCustom: PropTypes.number,
  onCickRow: PropTypes.func,
  isCustomOnFilter: PropTypes.bool,
  onFilterCustom: PropTypes.func,
  isRowSelectable: PropTypes.func,
  onExportExcel: PropTypes.func,
  isExportExcel: PropTypes.bool,
  HandleDataMode: PropTypes.oneOf(["server", "client"]),
  ContentExpand: PropTypes.any,
  onExpand: PropTypes.func,
  nExpandRowLength: PropTypes.number,
  id: PropTypes.string,
  rowReordering: PropTypes.bool,
  onRowOrderChange: PropTypes.func,
  isAddCondition: PropTypes.bool,
  TermsType: PropTypes.number,
  isJobPackage: PropTypes.bool,
  isCoinPackage: PropTypes.bool
};