import { Button, Grid, Typography } from "@mui/material"
import { i18n } from "i18n";
import { TbPhotoFilled } from "react-icons/tb";
import logo from "assets/images/BPS-Logo.png"
import BackgroundBack from "assets/images/bg-long2.png"
import { useEffect, useState } from "react";
import MoldalConditions from "./MoldalConditions";
import { useNavigate } from "react-router-dom";
import ModalServiceLogin from "./ModalServiceLogin";
import { AxiosPost } from "service/CommonFunction/TS_function";
import secureLocalStorage from "react-secure-storage";
import { EnumPackageType } from "./ServiceTable";
const ExampleBanner = ({ nPackageTypeID, sBuy }) => {
    const i18nField = "OrderEmployer";
    const [isShowPreview, setIsShowPreview] = useState(false);
    const [isOpenModal, setOpenModal] = useState(false);
    const [isEmployee, setisEmployee] = useState(false);
    const lang = secureLocalStorage.getItem("language") || "th";
    const navigate = useNavigate();
    const stylesCustom = {
        paperContainer: {
            backgroundImage: `url(${BackgroundBack})`,
            backgroundPosition: 'center',
            backgroundSize: '90vw',
            width: "100%",
        },
    }

    const GetDataGuestContactInfo = () => {
        let param = {
            sID: lang
        }
        AxiosPost(`EmployerServiceBPS/GetDataGuestContactInfo`, param, (result) => {
            setisEmployee(result.isEmployee);
        });
    }

    const onBuy = (sID, nMode) => {
        navigate(`/ServicesBPS?sID=${sID}&nMode=${nMode}&nTypePackage=${nPackageTypeID}`);
    }
    useEffect(() => {
        GetDataGuestContactInfo();
    }, [])
    return (
        <>
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
            >
                <Grid container spacing={3}>
                    <Grid item >
                        <Typography className={'title-Table'} >{i18n(`${i18nField}.BannerPackageService`)}</Typography>
                    </Grid>
                    {sBuy === "no" && (
                        <Grid item  >
                            <TbPhotoFilled className="icon-banner" />
                        </Grid>
                    )}
                </Grid >
            </Grid>
            <div className="plan-Banner" >
                <div className="inner-Banner" >
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                    >
                        <img
                            src={logo}
                            alt=""
                            className="logobps-banner"

                        />
                    </Grid>
                    <div className={"inner-Banner1"} style={stylesCustom.paperContainer}>
                        <div className={"plan-seach"} > </div>
                        <div className={"plan-banner1"}>
                            <Grid
                                container
                                direction="column"
                                justifyContent="center"
                                alignItems="center"
                            >
                                <Grid item >
                                    <Typography className="banner1-fontsize" fontWeight={400} color={"#4c4c4c"} >{i18n(`${i18nField}.Banner1`)}</Typography>
                                    <Typography className="banner-size" color={"#a4a4a4"}>{i18n(`${i18nField}.BannerSize1`)}</Typography>
                                </Grid>
                            </Grid >
                        </div>

                        <Grid item md={12} xs={12}>
                            <Grid
                                container
                                direction="row"
                                justifyContent="space-evenly"
                                spacing={1.5}
                            >
                                <Grid item md={9} xs={8}>
                                    <div className={"plan-LastedJobs"}> </div>
                                    <Grid
                                        container
                                        direction="column"
                                        justifyContent="center"
                                        alignItems="center"
                                    >
                                        <div className={"plan-banner4"} >
                                            <Grid
                                                container
                                                direction="column"
                                                justifyContent="center"
                                                alignItems="center"
                                            >
                                                <Grid item >
                                                    <Typography className="banner1-fontsize" fontWeight={400} color={"#4c4c4c"} >{i18n(`${i18nField}.Banner4`)}</Typography>
                                                    <Typography className="banner-size" color={"#a4a4a4"}>{i18n(`${i18nField}.BannerSize4`)}</Typography>
                                                </Grid>

                                            </Grid >
                                        </div>
                                    </Grid >
                                    <div className={"plan-Positions"} > </div>
                                    <Grid
                                        container
                                        direction="column"
                                        justifyContent="center"
                                        alignItems="center"
                                    >
                                        <div className={"plan-banner5"}  >
                                            <Grid
                                                container
                                                direction="column"
                                                justifyContent="center"
                                                alignItems="center"
                                            >
                                                <Grid item >
                                                    <Typography className="banner1-fontsize" fontWeight={400} color={"#4c4c4c"} >{i18n(`${i18nField}.Banner5`)}</Typography>
                                                    <Typography className="banner-size" color={"#a4a4a4"}>{i18n(`${i18nField}.BannerSize5`)}</Typography>
                                                </Grid>

                                            </Grid >
                                        </div>
                                    </Grid>
                                    <div className={"plan-LeadingCompany"}> </div>
                                </Grid>
                                <Grid item md={3} xs={4}>
                                    <div className={"plan-banner2"} >
                                        <Grid
                                            container
                                            direction="column"
                                            justifyContent="center"
                                            alignItems="center"
                                        >
                                            <Typography className="banner1-fontsize" fontWeight={400} color={"#4c4c4c"} >{i18n(`${i18nField}.Banner2`)}</Typography>
                                            <Typography className="banner-size"color={"#a4a4a4"}>{i18n(`${i18nField}.BannerSize2`)}</Typography>
                                        </Grid>

                                    </div>
                                    <div className={"plan-banner3"} >
                                        <Grid
                                            container
                                            direction="column"
                                            justifyContent="center"
                                            alignItems="center"
                                        >
                                            <Typography className="banner1-fontsize" fontWeight={400} color={"#4c4c4c"} >{i18n(`${i18nField}.Banner3`)}</Typography>
                                            <Typography className="banner-size" color={"#a4a4a4"}>{i18n(`${i18nField}.BannerSize3`)}</Typography>
                                        </Grid>

                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>
                </div>

                {sBuy === "no" && (
                    <>
                        <Grid item xs={12} style={{ marginTop: "25px" }}>
                            <Typography className="detail-terms" color={"#a5a6a6"}>{i18n(`${i18nField}.DetailTerms`)}   <a onClick={() => setIsShowPreview(true)} style={{ cursor: "pointer", textDecoration: "underline", color: "#249cdc" }} >{i18n(`${i18nField}.TermsConditions`)}</a> {i18n(`${i18nField}.website`)}</Typography>
                        </Grid>
                        <Grid
                            container
                            direction="row"
                            justifyContent="flex-end"
                            alignItems="center"
                        >
                            <Grid item md={2.5} xs={6} sm={4} style={{ marginTop: "15px" }}>
                                {isEmployee ? (
                                    <Button variant="contained" className="button-banner" onClick={() => onBuy("Banner", 3)}>{i18n(`${i18nField}.BuyNow`)}</Button>
                                ) : (
                                    <>
                                        <Button variant="contained" className="button-banner" onClick={() => setOpenModal(true)}>{i18n(`${i18nField}.Viewdetails`)}</Button>
                                    </>
                                )}
                            </Grid>
                        </Grid>
                    </>
                )}
            </div>

            <ModalServiceLogin isOpenModal={isOpenModal} setOpenModal={setOpenModal} />
            <MoldalConditions nID={nPackageTypeID == EnumPackageType.Banner ? EnumPackageType.BannerID : 0} isShowPreview={isShowPreview} setIsShowPreview={setIsShowPreview} />
        </>
    )
}
export default ExampleBanner