
import { useState, useEffect, useContext, useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Grid, Typography, Box, Paper, IconButton, Stack } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { i18n } from 'i18n';
import Input from 'components/Common/ElementInForm/InputCustomLabelFormItem';
import AutoCompleteSelect from 'components/Common/ElementInForm/AutoCompleteSelect';
import { ProfileContext } from './Context/ProfileContext';
import { IEducation } from './Interface';
import { DialogActionCreators } from "store/redux/DialogAlert";
import { useDispatch } from "react-redux";
import DatePickerFormItem from 'components/Common/ElementInForm/DatePickerFormItem';
import moment from 'moment';
import { AccordionCustom, AccordionDetailsCustom, AccordionSummaryCustom } from './ElementCustom';
import { BtnBack, BtnEditProfile, BtnRadiusProfile, BtnSubmit } from 'components/Common/Button';
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from 'react-router-dom';
import { FnBlock_UI } from 'service/SystemFunction/SystemFunction';
import { AxiosGet, AxiosPost } from 'service/CommonFunction/TS_function';
import yupFormSchemas from 'components/Common/yup/yupFormSchemas';
import CardComplete from './CardComplete';
import CardInComplete from './CardInComplete';
import secureLocalStorage from 'react-secure-storage';
const i18nField = 'entities.SeekerProfile';
const i18nCommon = 'common';

const EducationHistoryNew = ({ Edit, isExpanded, isJobApply, sIDNew,onBackEdit }) => {
    const history = useNavigate();
    const { BlockUI, UnBlockUI } = FnBlock_UI();
    const [expandedEducation, setExpandedEducation] = useState(false);
    const dispatch = useDispatch();
    const [formSchema, setFormSchema] = useState<any>({});
    const [arreducation, setArreducation] = useState<any>([]);
    const navigate = useNavigate();
    const {
        educationContext,
        setEducation,
        optionEducationLevel,
        optionUniversity,
        isEducationHistoryEdit,
        setIsEducationHistoryEdit,
        profileContext,
        isLoading
    } = useContext(ProfileContext);

    // Set up Schema Education
    const [schemaEducation, setSchemaEducation] = useState({});
    const loopSetSchemaEducation = () => {
        const schemaTemp = {};
        (educationContext || []).forEach((item) => {
            schemaTemp[`University${item.Index}`] = yupFormSchemas.object(
                i18n(`${i18nField}.University`),
                { required: true }
            );
            schemaTemp[`EducateLevel${item.Index}`] = yupFormSchemas.object(
                i18n(`${i18nField}.EducateLevel`),
                { required: true }
            );
            schemaTemp[`Faculty${item.Index}`] = yupFormSchemas.string(
                i18n(`${i18nField}.Faculty`),
                { required: false }
            );
            schemaTemp[`Major${item.Index}`] = yupFormSchemas.string(
                i18n(`${i18nField}.Major`),
                { required: true }
            );
            schemaTemp[`GraduationYear${item.Index}`] = yupFormSchemas.datetime(
                i18n(`${i18nField}.GraduationYear`),
                { required: true }
            );
            schemaTemp[`RemarkEdu${item.Index}`] = yupFormSchemas.string(
                i18n(`${i18nField}.EduRemark`)
            );
        });
        setSchemaEducation(schemaTemp);
    };

    const schema = yup.object().shape(formSchema);
    const form = useForm({
        resolver: yupResolver(schema),
        shouldUnregister: false,
        shouldFocusError: true,
        mode: "all",
    });

    const [isComPlete, setisComPlete] = useState(false);



    const GetMyProfileStatus = () => {
       let param ={
        nPanel:7
       }
        AxiosPost("JobSeeker/GetMyProfileStatus", param, (res) => {
            setisComPlete(res.IsCompleted);
            
        });
    };

    useEffect(() => {
        GetMyProfileStatus();
    }, []);
    
    useEffect(() => {
        loopSetSchemaEducation();
    }, [educationContext]);

    // -> Education
    const arrSchemaNameEducation = [
        "University",
        "EducateLevel",
        "Faculty",
        "Major",
        "GraduationYear",
        "RemarkEdu",
    ];

    useEffect(() => {
        for (const [key] of Object.entries(formSchema)) {
            if (arrSchemaNameEducation.some((a) => key.includes(a))) {
                delete formSchema[key];
            }
        }
        setFormSchema({ ...formSchema, ...schemaEducation });
    }, [schemaEducation]);

    const handleChangeValue = (form, index) => {
        let newData = educationContext.map((item) => {
            if (item.Index === index) {
                item.Academy = form.getValues("University" + index);
                item.EducateLevel = form.getValues("EducateLevel" + index);
                item.University = form.getValues("University" + index);
                item.Faculty = form.getValues("Faculty" + index);
                item.Major = form.getValues("Major" + index);
                item.GraduationYear = form.getValues("GraduationYear" + index);
                item.EduRemark = form.getValues("RemarkEdu" + index);
            }
            return item;
        });
        setEducation(newData);
    }

    const handleRemoveEducation = (itemIndex: number) => {
        dispatch(DialogActionCreators.OpenDialogSubmit(i18n(`${i18nCommon}.AlertConfrimDel`), () => {
            dispatch(DialogActionCreators.CloseDialogSubmit() as any);
            dispatch(DialogActionCreators.LoadSubmit("Waiting...") as any);
            let newData = educationContext.filter((data) => { return data.Index !== itemIndex });
            setEducation(newData);
            form.unregister("Academy" + itemIndex);
            form.unregister("EducateLevel" + itemIndex);
            form.unregister("University" + itemIndex);
            form.unregister("Faculty" + itemIndex);
            form.unregister("Major" + itemIndex);
            form.unregister("GraduationYear" + itemIndex);
            form.unregister("RemarkEdu" + itemIndex);
        }));
    }

    const handleAddEducate = () => {
        let nGenIndex = (Math.max.apply(Math, educationContext.map((item: IEducation, index) => { return item.Index; })) || 0) + 1;
        let newData = [
            ...educationContext,
            {
                Index: nGenIndex,
                Academy: "",
                EducateLevel: "",
                University: "",
                Faculty: "",
                Major: "",
                GraduationYear: null,
                Remark: "",
            },
        ] as Array<IEducation>;
        setEducation(newData);
        setFocusNewField(`University${nGenIndex}`);
    };

    const setFocusNewField = (sName) => {
        setTimeout(() => {
            try {
                form.setFocus(sName);
            } catch (error) { }
        }, 500);
    };
    const setDataMyProfile = () => {
        const data = profileContext as any;
        setArreducation(data.listEducation || [])
        let listEducation = [];
        if ((data.listEducation || []).length > 0) {
            listEducation = data.listEducation.map((item) => {
                const selectEduLevel = optionEducationLevel.find((data: any) => {
                    return data.value === item.nEducationLevel;
                });
                const selectUniversity = optionUniversity.find((data: any) => {
                    return data.label === item.sEducateName;
                });

                return {
                    Index: item.nEducationId,
                    Academy: (item.University && item.University["label"]) || "",
                    EducateLevel: selectEduLevel,
                    University: !selectUniversity
                        ? { label: item.sEducateName, value: 0 }
                        : selectUniversity,
                    Faculty: item.sFaculty,
                    Major: item.sMajor,

                    GraduationYear: moment(new Date(item.nEducational_Year, 0, 1)),
                    EduRemark: item.sEducateRemark,
                } as IEducation;
            }) as IEducation[];
        } else {
            listEducation = [
                {
                    Index: 0,
                    Academy: "",
                    EducateLevel: null,
                    University: null,
                    Faculty: "",
                    Major: "",
                    GraduationYear: null,
                    EduRemark: "",
                },
            ] as IEducation[];
        }
        setEducation(listEducation);
        listEducation.forEach((data) => {
            form.setValue("Academy" + data.Index, data.Academy || '');
            form.setValue("EducateLevel" + data.Index, data.EducateLevel || '');
            form.setValue("University" + data.Index, data.University || '');
            form.setValue("Faculty" + data.Index, data.Faculty || '');
            form.setValue("Major" + data.Index, data.Major || '');
            form.setValue("GraduationYear" + data.Index, data.GraduationYear || '');
            form.setValue("RemarkEdu" + data.Index, data.EduRemark || '');
        });
    };

    useEffect(() => {
        if (!isLoading) {
            setDataMyProfile();
        }
    }, [isLoading]);

    const handleEditWorkUnit = () => {
        let sJobApply = "";
        let sID = "";
        if (isJobApply) {
            sJobApply = "sJobApply";
            sID = sIDNew;
        }
        history(`/MyProfileEdit?sMode=Education&sJobApply=${sJobApply}&sIDNew=${sID}`);
    }

    const onBack = () => {
        setIsEducationHistoryEdit(false);
        if (isJobApply) {
            let objProfile = {
                sMode: "Education",
            };
            let objJobDetail = {
                sID: sIDNew || "",
                isApply: true,
                sFromPage: "JobDetail",
            };
            onBackEdit(objProfile,objJobDetail);

        } else {
            history(`/MyProfile?sMode=Education`);
        }
    }

    const onGoPage = () => {
        if (isJobApply) {
            let objProfile = {
                sMode: "Education",
            };
            let objJobDetail = {
                sID: sIDNew || "",
                isApply: true,
                sFromPage: "JobDetail",
            };
            onBackEdit(objProfile,objJobDetail);
        } else {
            history("/MyProfile?sMode=Education");
            //window.location.reload();
        }
    }


    useEffect(() => {
        setIsEducationHistoryEdit(Edit);
        setExpandedEducation(isExpanded);
    }, [])

    const onSubmit = (values) => {
        const arrEducateion = educationContext.map((item) => {
            return {
                nEducationId: item.Index,
                nEducationLevel: item.EducateLevel["value"] || 0,
                nUniversityId: item.University["value"] || 0,
                sEducateName: (item.University && item.University["label"]) || "",
                sFaculty: item.Faculty,
                sMajor: item.Major,
                nGraduationYear: moment(item.GraduationYear).valueOf(),
                sEducateRemark: item.EduRemark,
            };
        });
        const dataProfile = {
            listEducation: arrEducateion,
            nMode: 7,
        };

        dispatch(
            DialogActionCreators.OpenDialogSubmit(i18n(`${i18nField}.ConfirmMsgUpdate`), () => {
                dispatch(DialogActionCreators.CloseDialogSubmit() as any);
                dispatch(DialogActionCreators.LoadSubmit("Waiting...") as any);
                BlockUI();
                AxiosPost(`JobSeeker/UpdateProfile`, dataProfile, (result) => {
                    UnBlockUI();
                    let ProfileNoti = secureLocalStorage.getItem("ProfileNoti");
                    secureLocalStorage.setItem("ProfileNoti", ProfileNoti === "true" ? "false" : "true");
                    const res = result.Data;
                    if (res.IsSuccess) {
                        dispatch(DialogActionCreators.OpenDialogSuccess(i18n(`${i18nField}.MsgPanelEducation`), () => { onGoPage(); })
                        );
                    }
                },
                    (err) => {
                        if (!err?.response) {
                            history("/error");
                        }
                    }
                );
            }
            ) as any
        );
    };


    return (
        <FormProvider {...form} >
            <AccordionCustom
                sx={{ border: "10px", p: 1, flex: 1 }}
                expanded={expandedEducation}
                onChange={() => setExpandedEducation((prev) => !prev)}
            >
                <AccordionSummaryCustom>
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Grid item xs={6} sm={8} md={9}>
                            <Typography >
                                {i18n(`${i18nField}.PanelEducation`)}<span style={{ color: "red" }}> *</span>
                            </Typography>
                        </Grid>
                        <Grid item container
                            justifyContent="flex-end" xs={6} sm={4} md={3}>
                            {/* {isComPlete ? ( */}
                                <CardComplete isComPlete ={isComPlete} />
                            {/* ) : (
                                <CardInComplete />
                            )} */}
                        </Grid>
                    </Grid>


                </AccordionSummaryCustom>
                <AccordionDetailsCustom>
                    {(educationContext || []).map((item: IEducation, index) => {
                        return (
                            <Box component={Paper} sx={{ border: "10px", p: 1, flex: 1, m: "2%" }} key={index}>
                                {isEducationHistoryEdit && (
                                    <>
                                        {index === 0 ? null :
                                            <IconButton aria-label="delete" color="error" onClick={() => handleRemoveEducation(item.Index)}>
                                                <CloseIcon />
                                            </IconButton>}
                                    </>
                                )}
                                <Grid container justifyContent="center" alignItems="center" spacing={1}>
                                    <Grid item container spacing={2} xs={12} >
                                        <Grid item xs={12} sm={4} md={6}>
                                            <AutoCompleteSelect
                                                name={"University" + item.Index}
                                                label={i18n(`${i18nField}.University`)}
                                                onChange={(value) => {
                                                    handleChangeValue(form, item.Index);
                                                }}
                                                required
                                                freeSolo
                                                options={optionUniversity}
                                                disabled={!isEducationHistoryEdit}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={4} md={6}>
                                            <AutoCompleteSelect
                                                name={"EducateLevel" + item.Index}
                                                label={i18n(`${i18nField}.EducateLevel`)}
                                                onChange={(value) => {
                                                    handleChangeValue(form, item.Index);
                                                }}
                                                required
                                                options={optionEducationLevel}
                                                disabled={!isEducationHistoryEdit}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={4} md={5}>
                                            <Input
                                                name={"Faculty" + item.Index}
                                                label={<Typography sx={{ fontWeight: 400 }} component="label">{i18n(`${i18nField}.Faculty`)}</Typography>}
                                                small={true}
                                                maxLength={300}
                                                onBlur={(value) => {
                                                    handleChangeValue(form, item.Index);
                                                }}
                                                disabled={!isEducationHistoryEdit}
                                            // required
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={4} md={5}>
                                            <Input
                                                name={"Major" + item.Index}
                                                label={<Typography sx={{ fontWeight: 400 }} component="label">{i18n(`${i18nField}.Major`)}</Typography>}
                                                small={true}
                                                maxLength={300}
                                                onBlur={(value) => {
                                                    handleChangeValue(form, item.Index);
                                                }}
                                                required
                                                disabled={!isEducationHistoryEdit}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={4} md={2}>
                                            <DatePickerFormItem
                                                name={"GraduationYear" + item.Index}
                                                label={<Typography sx={{ fontWeight: 400 }} component="label">{i18n(`${i18nField}.GraduationYear`)}</Typography>}
                                                small={true}
                                                views={["year"]}
                                                isEnOnly
                                                onChange={() => {
                                                    handleChangeValue(form, item.Index);
                                                }}
                                                format="YYYY"
                                                maxDate={moment()}
                                                required
                                                disabled={!isEducationHistoryEdit}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Input
                                                name={"RemarkEdu" + item.Index}
                                                fullWidth
                                                multiline
                                                label={<Typography sx={{ fontWeight: 400 }} component="label">{i18n(`${i18nField}.EduRemark`)}</Typography>}
                                                rows={4}
                                                maxLength={3000}
                                                onBlur={(value) => {
                                                    handleChangeValue(form, item.Index);
                                                }}
                                                disabled={!isEducationHistoryEdit}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Box>
                        );
                    })}
                    {isEducationHistoryEdit && (
                        <Stack
                            sx={{ border: "10px", p: 1, flex: 1, m: "2%" }}
                            direction="row"
                            justifyContent="center"
                        >
                            <BtnRadiusProfile
                                txt={i18n(`${i18nField}.AddWorkDetail`)}
                                onClick={handleAddEducate}
                            />
                        </Stack>
                    )}

                    {!isEducationHistoryEdit && (
                        <Stack
                            sx={{ border: "10px", p: 1, flex: 1, m: "2%" }}
                            direction="row"
                        >
                            <BtnEditProfile
                                txt={i18n(`${i18nField}.Edit`)}
                                onClick={handleEditWorkUnit}
                            />
                        </Stack>
                    )}
                </AccordionDetailsCustom>
            </AccordionCustom>
            {isEducationHistoryEdit && (
                <Stack
                    spacing={1}
                    sx={{ border: "10px", p: 1, flex: 1, mt: "2%" }}
                    direction="row"
                    justifyContent="space-between"
                    alignItems="flex-start"
                >
                    <BtnBack
                        onClick={() => { onBack(); }}
                        txt={i18n(`${i18nCommon}.back`)}
                    />
                    <BtnSubmit
                        onClick={form.handleSubmit(onSubmit, (err) => console.log("error", err))}
                        txt={i18n(`${i18nCommon}.save`)}
                    />
                </Stack>
            )}
        </FormProvider>
    )
}

export default EducationHistoryNew;