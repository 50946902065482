import { useState, useEffect, useContext } from "react";
import { FormProvider, useForm } from "react-hook-form";
import moment from "moment";
import {
  Grid,
  Typography,
  Box,
  FormControlLabel,
  Checkbox,
  Paper,
  IconButton,
  Stack,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { i18n } from "i18n";
import DatePickerFormItem from "components/Common/ElementInForm/DatePickerFormItem";
import Input from "components/Common/ElementInForm/InputCustomLabelFormItem";
import { ProfileContext } from "./Context/ProfileContext";
import { ICertificate } from "./Interface";
import { DialogActionCreators } from "store/redux/DialogAlert";
import { useDispatch } from "react-redux";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from "react-router-dom";
import { AccordionCustom, AccordionDetailsCustom, AccordionSummaryCustom } from "./ElementCustom";
import { BtnBack, BtnEditProfile, BtnRadiusProfile, BtnSubmit } from "components/Common/Button";
import { AxiosGet, AxiosPost } from "service/CommonFunction/TS_function";
import { FnBlock_UI } from "service/SystemFunction/SystemFunction";
import yupFormSchemas from "components/Common/yup/yupFormSchemas";
import CardComplete from "./CardComplete";
import CardInComplete from "./CardInComplete";
import secureLocalStorage from "react-secure-storage";

const i18nField = "entities.SeekerProfile";
const i18nCommon = "common";

const MyCertificateNew = ({ Edit, isExpanded, isJobApply, sIDNew,onBackEdit }) => {

  const {
    certificateContext,
    setCertificate,
    profileContext,
    isLoading,
    isCertificateEdit,
    setIsCertificateEdit,
  } = useContext(ProfileContext);
  const [expandedCertificate, setExpandedCertificate] = useState(false);
  const history = useNavigate();
  const { BlockUI, UnBlockUI } = FnBlock_UI();
  const [arrcertificate, setArrcertificate] = useState([]);
  const navigate = useNavigate();
  const schemaCert = {};
  (certificateContext || []).forEach((item) => {
    schemaCert[`CertName${item.Index}`] = yupFormSchemas.string(
      i18n(`${i18nField}.CertName`)
    );
    schemaCert[`ApproveBy${item.Index}`] = yupFormSchemas.string(
      i18n(`${i18nField}.ApproveBy`)
    );
    schemaCert[`ApproveDate${item.Index}`] = yupFormSchemas.datetime(
      i18n(`${i18nField}.ApproveDate`)
    );
    schemaCert[`ExpirdDate${item.Index}`] = yupFormSchemas.datetime(
      i18n(`${i18nField}.ExpirdDate`)
    );
    schemaCert[`checkNoExpire${item.Index}`] = yupFormSchemas.boolean(
      i18n(`${i18nField}.checkNoExpire`)
    );
    schemaCert[`CertRemark${item.Index}`] = yupFormSchemas.string(
      i18n(`${i18nField}.CertRemark`)
    );
  });

  const schema = yup.object().shape(schemaCert);
  const form = useForm({
    resolver: yupResolver(schema),
    shouldUnregister: false,
    shouldFocusError: true,
    mode: "all",
  });
  const dispatch = useDispatch();

  const [isNotExpird, setIsNotExpird] = useState(false);
  const [minDateExpire, setMinDateExpire] = useState(null);

  const [isComPlete, setisComPlete] = useState(false);



  const handleChangeValue = (form, index) => {
    let newData = certificateContext.map((item) => {
      if (item.Index === index) {
        item.CertName = form.getValues("CertName" + index);
        item.ApproveBy = form.getValues("ApproveBy" + index);
        item.ApproveDate = form.getValues("ApproveDate" + index);
        item.ExpirdDate = form.getValues("ExpirdDate" + index);
        item.IsNotExpird = form.getValues("checkNoExpire" + index) || false;
        item.CertRemark = form.getValues("CertRemark" + index);
      }

      return item;
    });
    setCertificate(newData);


  };

  const handleRemoveCertificate = (itemIndex: number) => {
    dispatch(
      DialogActionCreators.OpenDialogSubmit(
        i18n(`${i18nCommon}.AlertConfrimDel`),
        () => {
          dispatch(DialogActionCreators.CloseDialogSubmit() as any);
          dispatch(DialogActionCreators.LoadSubmit("Waiting...") as any);
          let newData = certificateContext.filter((data) => {
            return data.Index !== itemIndex;
          });
          setCertificate(newData);
          form.unregister("CertName" + itemIndex);
          form.unregister("ApproveBy" + itemIndex);
          form.unregister("ApproveDate" + itemIndex);
          form.unregister("ExpirdDate" + itemIndex);
          form.unregister("checkNoExpire" + itemIndex);
          form.unregister("CertRemark" + itemIndex);
        }
      )
    );
  };


  const handleAddCertificate = () => {
    let nGenIndex =
      (Math.max.apply(
        Math,
        certificateContext.map((item: ICertificate, index) => {
          return item.Index;
        })
      ) || 0) + 1;

    let newData = [
      ...certificateContext,
      {
        Index: nGenIndex,
        CertName: "",
        ApproveBy: "",
        ApproveDate: null,
        ExpirdDate: null,
        IsNotExpird: false,
        CertRemark: "",
      },
    ] as Array<ICertificate>;
    setCertificate(newData);
    setFocusNewField(`CertName${nGenIndex}`);
  };

  const setFocusNewField = (sName) => {
    setTimeout(() => {
      try {
        form.setFocus(sName);
      } catch (error) { }
    }, 500);
  };



  const onSubmit = (values) => {
    const arrCertificate = certificateContext.map((item) => {
      return {
        nCertificateId: item.Index,
        sCertificateName: item.CertName,
        sApproveCert: item.ApproveBy,
        dApproveDate: item.ApproveDate
          ? moment(item.ApproveDate).toDate()
          : null,
        nApproveDate: moment(item.ApproveDate).valueOf(),
        nExpireDate: moment(item.ExpirdDate).valueOf(),
        dExpireDate: item.IsNotExpird ? null : moment(item.ExpirdDate).toDate(),
        IsNotExpire: item.IsNotExpird,
        sCertRemark: item.CertRemark,
      };
    });
    const dataProfile = {
      listCertificate: arrCertificate,
      nMode: 8,
    };

    dispatch(
      DialogActionCreators.OpenDialogSubmit(i18n(`${i18nField}.ConfirmMsgUpdate`), () => {
        dispatch(DialogActionCreators.CloseDialogSubmit() as any);
        dispatch(DialogActionCreators.LoadSubmit("Waiting...") as any);
        BlockUI();
        AxiosPost(`JobSeeker/UpdateProfile`, dataProfile, (result) => {
          let ProfileNoti = secureLocalStorage.getItem("ProfileNoti");
          secureLocalStorage.setItem("ProfileNoti", ProfileNoti === "true" ? "false" : "true");
          UnBlockUI();
          const res = result.Data;
          if (res.IsSuccess) {
            dispatch(DialogActionCreators.OpenDialogSuccess(i18n(`${i18nField}.MsgPanelCert`), () => { onGoPage(); })
            );
          }
        },
          (err) => {
            if (!err.response) {
              history("/error");
              return;
            }
          }
        );
      }
      ) as any
    );
  };



  const GetMyProfile = () => {
    AxiosGet("JobSeeker/GetMyProfileCertificate", {}, (res) => {
      let listCertificate = [];
      if ((res.listCertificate || []).length > 0) {
        listCertificate = res.listCertificate.map((item) => {
          return {
            Index: item.nCertificateId,
            CertName: item.sCertificateName,
            ApproveBy: item.sApproveCert,
            ApproveDate: item.dApproveDate,
            ExpirdDate: item.dExpireDate,
            nApproveDate: moment(item.dApproveDate).valueOf(),
            nExpireDate: moment(item.dExpireDate).valueOf(),
            IsNotExpird: item.IsNotExpire,
            CertRemark: item.sCertRemark,
          } as ICertificate;
        }) as ICertificate[];
      } else {
        listCertificate = [
          {
            Index: 0,
            CertName: "",
            ApproveBy: "",
            ApproveDate: null,
            ExpirdDate: null,
            IsNotExpird: false,
            CertRemark: "",
          },
        ] as ICertificate[];
      }
      setCertificate(listCertificate);

      listCertificate.forEach((data) => {
        form.setValue("CertName" + data.Index, data.CertName || "");
        form.setValue("ApproveBy" + data.Index, data.ApproveBy || "");
        form.setValue("ApproveDate" + data.Index, data.ApproveDate || "");
        form.setValue("ExpirdDate" + data.Index, data.ExpirdDate || "");
        form.setValue("checkNoExpire" + data.Index, data.IsNotExpird || false);
        form.setValue("CertRemark" + data.Index, data.CertRemark || "");
      });
    });
  };


  const GetMyProfileStatus = () => {
    let param ={
      nPanel:8
     }
     AxiosPost("JobSeeker/GetMyProfileStatus", param, (res) => {
      setisComPlete(res.IsCompleted);

    });
  };

  useEffect(() => {
    GetMyProfileStatus();
    GetMyProfile();
}, [])

  

  const handleEditWorkUnit = () => {
    let sJobApply = "";
    let sID = "";
    if (isJobApply) {
      sJobApply = "sJobApply";
      sID = sIDNew;
    }
    history(`/MyProfileEdit?sMode=Certificate&sJobApply=${sJobApply}&sIDNew=${sID}`);
  }

  const onBack = () => {
    setIsCertificateEdit(false)
    if (isJobApply) {
      let objProfile = {
        sMode: "Certificate",
      };
      let objJobDetail = {
        sID: sIDNew || "",
        isApply: true,
        sFromPage: "JobDetail",
      };
      onBackEdit(objProfile,objJobDetail);

    } else {
      history(`/MyProfile?sMode=Certificate`);
    }
  }
  const onGoPage = () => {
    if (isJobApply) {
      let objProfile = {
        sMode: "Certificate",
      };
      let objJobDetail = {
        sID: sIDNew || "",
        isApply: true,
        sFromPage: "JobDetail",
      };
      onBackEdit(objProfile,objJobDetail);
    } else {
      history(`/MyProfile?sMode=Certificate`);
    }
  }

  useEffect(() => {
    setIsCertificateEdit(Edit);
    setExpandedCertificate(isExpanded);
  }, [])
  return (
    <FormProvider {...form} >
      <AccordionCustom
        sx={{ border: "10px", p: 1, flex: 1 }}
        expanded={expandedCertificate}
        onChange={() => setExpandedCertificate((prev) => !prev)}
      >
        <AccordionSummaryCustom>

          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item xs={6} sm={8} md={9}>
              <Typography >
                {i18n(`${i18nField}.PanelCert`)}
              </Typography>
            </Grid>
            <Grid item container
              justifyContent="flex-end" xs={6} sm={4} md={3}>
              <CardComplete isComPlete={isComPlete} />
              {/* {isComPlete ? (
                <CardComplete />
              ) : (
                <>
                  <CardInComplete />
                </>
              )} */}
            </Grid>
          </Grid>
          {/* <Typography className={isJobApply && !isCertificateEdit ? "container-card" : isCertificateEdit ? "container-cardProEdit" : "container-cardPro"}>
            {i18n(`${i18nField}.PanelCert`)}
          </Typography>
          {(arrcertificate.filter((w) => w.sCertificateName !== "" || w.sApproveCert !== "" || w.sCertRemark !== "" || w.dApproveDate != null).length > 0) ? (
            <CardComplete />
          ) : (
            <>
              <CardInComplete />
            </>
          )} */}
        </AccordionSummaryCustom>
        <AccordionDetailsCustom>
          {(certificateContext || []).map((item: ICertificate, index) => {
            return (
              <Box component={Paper} sx={{ border: "10px", p: 1, flex: 1, m: "2%" }} key={index}>
                {isCertificateEdit && (
                  <>
                    {index === 0 ? null : (
                      <IconButton
                        aria-label="delete"
                        color="error"
                        onClick={() => handleRemoveCertificate(item.Index)}
                      >
                        <CloseIcon />
                      </IconButton>
                    )}
                  </>
                )}
                <Grid container justifyContent="center" alignItems="center" spacing={1}>
                  <Grid item container spacing={2} xs={12}>
                    <Grid item xs={12} sm={6} md={6}>
                      <Input
                        name={"CertName" + item.Index}
                        fullWidth
                        label={
                          <Typography sx={{ fontWeight: 400 }} component="label">
                            {i18n(`${i18nField}.CertName`)}
                          </Typography>
                        }
                        maxLength={200}
                        onBlur={(value) => {
                          handleChangeValue(form, item.Index);
                        }}
                        small
                        disabled={!isCertificateEdit}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <Input
                        name={"ApproveBy" + item.Index}
                        fullWidth
                        label={
                          <Typography sx={{ fontWeight: 400 }} component="label">
                            {i18n(`${i18nField}.ApproveBy`)}
                          </Typography>
                        }
                        small
                        maxLength={200}
                        onBlur={(value) => {
                          handleChangeValue(form, item.Index);
                        }}
                        disabled={!isCertificateEdit}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4} md={4}>
                      <DatePickerFormItem
                        name={"ApproveDate" + item.Index}
                        label={
                          <Typography sx={{ fontWeight: 400 }} component="label">
                            {i18n(`${i18nField}.ApproveDate`)}
                          </Typography>
                        }
                        small={true}
                        onChange={(value) => {
                          form.setValue("ApproveDate" + item.Index, value);
                          let dEnd = form.getValues("ExpirdDate" + item.Index);
                          if (dEnd) {
                            let nDiffDate = moment(dEnd).diff(value, "days");
                            if (nDiffDate < 0) {
                              form.setValue("ExpirdDate" + item.Index, value);
                            }
                          }
                          handleChangeValue(form, item.Index);
                          setMinDateExpire(() => value);
                        }}
                        disabled={!isCertificateEdit}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4} md={4}>
                      <DatePickerFormItem
                        name={"ExpirdDate" + item.Index}
                        minDate={minDateExpire}
                        label={
                          <Typography sx={{ fontWeight: 400 }} component="label">
                            {i18n(`${i18nField}.ExpirdDate`)}
                          </Typography>
                        }
                        small={true}
                        onChange={(value) => {
                          form.setValue("ExpirdDate" + item.Index, value);
                          handleChangeValue(form, item.Index);
                        }}
                        disabled={item.IsNotExpird || !isCertificateEdit}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4} md={4}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name={"checkNoExpire" + item.Index}
                            checked={item.IsNotExpird}
                            onChange={(e, value) => {
                              setIsNotExpird((prev) => !prev);
                              form.setValue("checkNoExpire" + item.Index, value);
                              form.setValue("ExpirdDate" + item.Index, "");
                              handleChangeValue(form, item.Index);
                            }}
                          />
                        }
                        label={i18n(`${i18nField}.NotExpird`)}
                        disabled={!isCertificateEdit}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Input
                        name={"CertRemark" + item.Index}
                        fullWidth
                        multiline
                        maxLength={3000}
                        label={
                          <Typography sx={{ fontWeight: 400 }} component="label">
                            {i18n(`${i18nField}.CertRemark`)}
                          </Typography>
                        }
                        rows={4}
                        onBlur={(value) => {
                          handleChangeValue(form, item.Index);
                        }}
                        disabled={!isCertificateEdit}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            );
          })}
          {isCertificateEdit && (
            <Stack
              sx={{ border: "10px", p: 1, flex: 1, m: "2%" }}
              direction="row"
              justifyContent="center"
            >
              <BtnRadiusProfile
                txt={i18n(`${i18nField}.AddWorkDetail`)}
                onClick={handleAddCertificate}
              />
            </Stack>
          )}
          {!isCertificateEdit && (
            <Stack
              sx={{ border: "10px", p: 1, flex: 1, m: "2%" }}
              direction="row"
            >
              <BtnEditProfile
                txt={i18n(`${i18nField}.Edit`)}
                onClick={handleEditWorkUnit}
              />
            </Stack>
          )}
        </AccordionDetailsCustom>
      </AccordionCustom>

      {isCertificateEdit && (
        <Stack
          spacing={1}
          sx={{ border: "10px", p: 1, flex: 1, mt: "2%" }}
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <BtnBack
            onClick={() => { onBack() }}
            txt={i18n(`${i18nCommon}.back`)}
          />
          <BtnSubmit
            onClick={form.handleSubmit(onSubmit, (err) => console.log("error", err))}
            txt={i18n(`${i18nCommon}.save`)}
          />
        </Stack>
      )}
    </FormProvider>
  );
};

export default MyCertificateNew;