import { Card, CardContent, Divider, Grid, Typography } from "@mui/material";
import BPS_GroupTable from "components/BPS/BPS_Group/BPS_GroupTable";


export default function Group_Table(item) {
  return (
    <Grid container>
      <Grid item className="table" sx={{ mx: "2%", width: "100%" }}>
        <BPS_GroupTable />
      </Grid>
    </Grid>
  );
}