import { Stack } from "@mui/material";
import AdminFreePackageApproveTable from "../../../components/AdminWorkflow/AdminFreePackage/AdminFreePackageApprove";

export default function AdminFreePackageApprove() {
  return (

    <Stack sx={{ mx: "1%" }}>
      <AdminFreePackageApproveTable />
    </Stack>

  );
}