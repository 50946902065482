import { useEffect, useState } from "react";
import { Grid, Typography, Box, Divider, AppBar, Dialog, Toolbar, IconButton } from "@mui/material";
import ImageBPSLogo from "assets/images/BPS-Logo.png";
// import ImageBPSLogo from "ClientApp/public/wwwroot/UploadFile/images/BPS-Logo.png"
import { FnBlock_UI } from "service/SystemFunction/SystemFunction";
import { AxiosGet, AxiosPost, ResultAPI } from "service/CommonFunction/TS_function";
import { useNavigate, Link } from "react-router-dom";
import themes from "../../../themes";
import CloseIcon from "@mui/icons-material/Close";
import secureLocalStorage from "react-secure-storage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLine,
  faFacebookSquare,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";

import { getLanguage, i18n } from "i18n";
import { useDispatch } from "react-redux";
import ContentPage from "view/font/Home/ContentPage";
import { dataContent, objContent } from "components/Banner/AdminContent/ManageContent";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";

const i18nForthisPage = "home";

export default function FooterFront() {
  const { BlockUI, UnBlockUI } = FnBlock_UI();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  //Site Map
  const [lstGuestMenu, setLstGuestMenu] = useState([]);
  const [lstContentTerm, setlstContentTerm] = useState([]);
  const [lstSeekerMenu, setLstSeekerMenu] = useState([]);
  const [lstEmployerMenu, setLstEmployerMenu] = useState([]);
  const [lstContent, setLstPreviewContent] = useState<objContent[]>([]);
  const [isShowPreview, setIsShowPreview] = useState<boolean>(false);
  const [AddressFooter, setAddressFooter] = useState("");
  const [objStateContent, setObjStateContent] = useState({
    nTypeContent_1: 1
  });
  const [initialValues] = useState(() => {
    return {
      lstTag: []
    };
  });

  useEffect(() => {
    getInitData();
    getAddressFooter();
  }, []);

  const [objSchema, setObjSchema] = useState({});

  const schema = yup.object().shape(objSchema);
  const form = useForm({
    resolver: yupResolver(schema),
    shouldUnregister: false,
    shouldFocusError: true,
    mode: "all",
    defaultValues: initialValues as any,
  });

  const getInitData = () => {

    let sLang = "th";
    if (secureLocalStorage.getItem('language')) {
      sLang = secureLocalStorage.getItem('language') as string
    }

    BlockUI();
    AxiosGet("HomeFront/GetFooterSiteMap2", { sLang }, (res) => {
      UnBlockUI();
      if (res.StatusCode === 200) {
        setLstGuestMenu(res.Data.lstGuestMenu);
        setLstSeekerMenu(res.Data.lstSeekerMenu);
        setLstEmployerMenu(res.Data.lstEmployerMenu);
      }
    },
      (err) => {
        if (!err.response) {
          navigate("/error");
        }
      }
    );


    // let param = {
    //   sLang: (secureLocalStorage.getItem('language') || "th"),
    // };
    // AxiosPost(`HomeFront/GetFooterSiteMap`, param, (res) => {
    //   UnBlockUI();
    //   if (res.StatusCode === 200) {
    //     setLstGuestMenu(res.Data.lstGuestMenu);
    //     setLstSeekerMenu(res.Data.lstSeekerMenu);
    //     setLstEmployerMenu(res.Data.lstEmployerMenu);
    //   }
    // },
    //   (err) => {
    //     if (!err.response) {
    //       navigate("/error");
    //     }
    //   }
    // );

  };

  const getAddressFooter = () => {
    let lang = "";
    let isTH = (getLanguage().id === "th");
    isTH ? lang = "th" : lang = "en"
    AxiosGet("Config/GetData_TermsAndConditionFooter", { lang }, (res) => {
      setTimeout(() => {
        setAddressFooter(res.TermsFooter);
      }, 200);

    });
  }






  const onOpenCondition = async (nID) => {
    let param = {
      nID,
    };

    let lstPreviewContent = []
    let show = []
    await AxiosPost(`HomeFront/getData_ContentDetail`, param, (res) => {
      ResultAPI(res, "", () => {
        setlstContentTerm(res.contentShow);
        show = res.contentShow
      });
    },
      (err) => {
        if (!err.response) {
          navigate("/error");
        }
      }
    );
    show.forEach((f, i) => {
      const nType = f.nType
      let sContent = f.sContent
      let fileContent = f.sFilePath;
      let objViewContent = {
        nType: nType,
        sContent: sContent,
        sFileNameSys: fileContent
      }
      lstPreviewContent.push(objViewContent)
    })
    setLstPreviewContent(lstPreviewContent)
    setIsShowPreview(true)
  }

  return (
    <>
      <Grid container spacing={2} sx={{ p: "10px 10vw" }}>
        <Grid item xs={12}>
          <Box
            className="not-cover-image image-size-footer"
            sx={{
              backgroundImage: `url(${ImageBPSLogo})`,
              backgroundSize: "contain",
            }}
          ></Box>
          <Divider />
        </Grid>
        <Grid item md={4} sm={12} xs={12}>
          <Typography sx={{ fontSize: 20, fontWeight: 500, color: "#0065ff" }}>
            {i18n(`${i18nForthisPage}.callUs`)}
          </Typography>
          <Typography sx={{ fontSize: 16 }}>
            {i18n(`${i18nForthisPage}.phone`)}
          </Typography>
          <Box sx={{ height: "20px" }}></Box>
          <Typography sx={{ lineHeight: 1.2, marginTop: "1%" }}>
            {/* address */}
            {AddressFooter}
            {/* {parse(i18n(`${i18nForthisPage}.address`))} */}
          </Typography>
        </Grid>

        {/* Guest */}
        {lstGuestMenu.length > 0 && (
          <Grid item md={2} sm={6} xs={6}>
            <Typography className="site-map-head">
              {i18n(`${i18nForthisPage}.guest`)}
            </Typography>
            <Box sx={{ height: "10px" }}></Box>
            <Typography
              sx={{ fontSize: 14, fontWeight: 600, color: "#0c30b1" }}
            >
              {i18n(`${i18nForthisPage}.mainPage`)}
            </Typography>
            {lstGuestMenu.map((m, i) => (
              <Link key={i} to={m.sURL || "/"}>
                <span style={{ color: "black" }}>-</span>{" "}
                <span className="site-map-label">{`${m.sName}`}</span>
                <br />
              </Link>
            ))}
          </Grid>
        )}

        {/* Job Seeker */}
        {lstSeekerMenu.length > 0 && (
          <Grid item md={2} sm={6} xs={6}>
            <Typography className="site-map-head">
              {i18n(`${i18nForthisPage}.jobSeeker`)}
            </Typography>
            <Box sx={{ height: "10px" }}></Box>
            <Typography
              sx={{ fontSize: 14, fontWeight: 600, color: "#0c30b1" }}
            >
              {i18n(`${i18nForthisPage}.mainPage`)}
            </Typography>
            {lstSeekerMenu.map((m, i) => (
              <Link key={i} to={m.sURL || "/"}>
                <span style={{ color: "black" }}>-</span>{" "}
                <span className="site-map-label">{`${m.sName}`}</span>
                <br />
              </Link>
            ))}
          </Grid>
        )}

        {/* Employer */}
        {lstEmployerMenu.length > 0 && (
          <Grid item md={2} sm={6} xs={6}>
            <Typography className="site-map-head">
              {i18n(`${i18nForthisPage}.employer`)}
            </Typography>
            <Box sx={{ height: "10px" }}></Box>
            <Typography
              sx={{ fontSize: 14, fontWeight: 600, color: "#0c30b1" }}
            >
              {i18n(`${i18nForthisPage}.mainPage`)}
            </Typography>
            {lstEmployerMenu.map((m, i) => (
              <Link key={i} to={m.sURL || "/"}>
                <span style={{ color: "black" }}>-</span>{" "}
                <span className="site-map-label">{`${m.sName}`}</span>
                <br />
              </Link>
            ))}
          </Grid>
        )}

        <Dialog
          fullScreen
          open={isShowPreview}
          onClose={() => setIsShowPreview(false)}
        >
          <AppBar sx={{ position: "relative", marginBottom: "3rem" }}>
            <Toolbar>
              <IconButton
                edge="end"
                color="inherit"
                onClick={() => setIsShowPreview(false)}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
            </Toolbar>
          </AppBar>
          <Grid container>
            <Grid xs={12} item sx={{ mx: "11%" }}>
              <ContentPage lstContent={lstContent} OrderEmp={false} sContendHead={undefined} />
            </Grid>
          </Grid>
        </Dialog>

        <Grid item md={4} sm={6} xs={6}>
          <Typography className="site-map-head">Helpful Resources</Typography>
          <Box sx={{ height: "10px" }}></Box>
          <Typography >- <span className="site-map-label" onClick={() => onOpenCondition(1)}>{i18n(`dialog.Terms`)}</span></Typography>
          <Typography >- <span className="site-map-label" onClick={() => onOpenCondition(3)}>{i18n(`dialog.privacy`)}</span></Typography>
          <Grid item sx={{ mx: "2%" }}>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Box
          sx={{
            width: "100%",
            height: "70px",
            bgcolor: "#F7F7F7",
            pl: "10vw",
            pr: "10vw",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            boxShadow: "0px 0px 7px 1px #C9C9C9",
          }}
        >
          <Grid container>
            <Grid
              item
              md={6}
              sm={12}
              xs={12}
              sx={{
                display: "flex",
                flexDirection: "row",
                [themes.breakpoints.down("md")]: {
                  justifyContent: "center",
                },
                [themes.breakpoints.up("md")]: {
                  justifyContent: "start",
                },
                paddingTop: "7px",
              }}
            >
              {/* 2022 Softthai. All Right Reserved. */}
            </Grid>

            {/* Social Media */}
            <Grid
              item
              md={6}
              sm={12}
              xs={12}
              sx={{
                display: "flex",
                flexDirection: "row",
                [themes.breakpoints.down("md")]: {
                  justifyContent: "center",
                },
                [themes.breakpoints.up("md")]: {
                  justifyContent: "end",
                },
              }}
            >
              <a
                href="https://www.facebook.com/job.pttbps/"
                target="_blank"
                rel="noreferrer"
              >
                <FontAwesomeIcon
                  icon={faFacebookSquare}
                  size="2x"
                  style={{ color: "black" }}
                />
                &nbsp;&nbsp;&nbsp;&nbsp;
              </a>
              <a
                href="https://page.line.me/279yxnan?openQrModal=true/"
                target="_blank"
                rel="noreferrer"
              >
                <FontAwesomeIcon
                  icon={faLine}
                  size="2x"
                  style={{ color: "black" }}
                />
                &nbsp;&nbsp;&nbsp;&nbsp;
              </a>
              <a href="https://bit.ly/3unJtZD" target="_blank" rel="noreferrer">
                <FontAwesomeIcon
                  icon={faLinkedin}
                  size="2x"
                  style={{ color: "black" }}
                />
                &nbsp;&nbsp;&nbsp;&nbsp;
              </a>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </>
  );
}