import { Button, Grid } from "@mui/material";
import BPS_GroupForm from "components/BPS/BPS_Group/BPS_GroupForm";
import { useLocation } from "react-router-dom";

export default function Group_Form() {
  const location = useLocation();
  const searchParams = location.search;
  const sID = new URLSearchParams(searchParams).get('sID');
  const isEditMode = sID ? true : false;
  
  return (
    <Grid container>
      <Grid item xs={12} className="table" sx={{ mx: "2%", width: "100%" }}>
        <BPS_GroupForm />
      </Grid>
    </Grid>
  );
}