/* eslint-disable react/jsx-pascal-case */
import { useState, useEffect, useContext, useMemo } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { getLanguage } from 'i18n';
import Logo from "assets/images/BPS-Logo.png"
// import Logo from "ClientApp/public/wwwroot/UploadFile/images/BPS-Logo.png"
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import { ProfileContext } from 'components/SeekerProfile/Context/ProfileContext';
import { any } from 'prop-types';
import { AxiosGet } from 'service/CommonFunction/TS_function';

import ReactGA from 'react-ga4';
import secureLocalStorage from 'react-secure-storage';


export default function Menu_Layout({ lstAllMenu, setFocus, FocusMenu, index, item, nLevel = 1, isActive = false }) {
  const i18nField = 'entities.LayoutFront';
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl)
  const [widthSubMenu, setWidthSubMenu] = useState(0);
  let lstLowLevelMenu = lstAllMenu.filter(f => f.sHeaderMenu === item.sNameTH && nLevel + 1 === f.nLevel && !f.IsBack)
  const [isMouseFocus, setMouseFocus] = useState(false)
  const [nPercent, setnPercent] = useState(0);
  const [isProfileNoti, setisProfileNoti] = useState(false)
  const location = useLocation();

  const localJwtKey = process.env.REACT_APP_JWT_KEY;
  // ReactGA.initialize('G-2JJP3706C5');
  // ReactGA.pageview(window.location.pathname + window.location.search);

  const _handleOpenSubMenu = (event) => {
    if (open) {
      setAnchorEl(null);
      setWidthSubMenu(0);
    } else if (isMouseFocus && !open) {
      setAnchorEl(event.currentTarget);
      setWidthSubMenu(event.currentTarget.clientWidth);
    }
  }
  useEffect(() => {
    fetchMyProfile();
  }, [])
  const fetchMyProfile = () => {
    const auth_token = secureLocalStorage.getItem(localJwtKey);

    if (auth_token) {
      AxiosGet("JobSeeker/GetMyProfileHome", {}, (res) => {
        setnPercent(res.nMenuProFile)
        setisProfileNoti(res.nMenuProFile < 6);
      });
    }

  };


  useMemo(() => {
    fetchMyProfile()
  }, [secureLocalStorage.getItem("ProfileNoti")])


  useEffect(() => {
    setAnchorEl(null);
    setWidthSubMenu(0);
  }, [isActive])

  useEffect(() => {
    if (!isMouseFocus) setAnchorEl(null)
  }, [isMouseFocus])



  const onSaveMenuGoogleAnalytics = (item) => {
    if (item) {
      ReactGA.event({
        category: item.sNameTH,
        action: "onClick",
        label: "Menu"
      })
    }
  }

  return (
    <>
      {
        item.sUrl === "/" ?
          <Link
            id={"MenuFont" + item.nMenuID}
            style={{ overflow: "hidden", cursor: "pointer", fontSize: "13px", fontFamily: "Prompt" }}
            key={item.sID}
            onClick={(e) => {
              // onSaveMenuGoogleAnalytics(item);
              setFocus()
            }}
            className={`${nLevel > 1 ? "h-[50px]" : "h-[70px]"}  flex flex-col justify-center ${nLevel > 1 ? "items-start" : "items-center"} px-[1em]
                                    ${nLevel > 1 ? "border-b" : "border-b-0"}`}
            to={item.sUrl} >
            <img className="object-contain w-[100px] h-[50px]" alt="" src={Logo} />
          </Link>
          :
          // <>
          // <img className="object-contain w-[100px] h-[50px]" alt="" src={Logo} />
          <Link
            id={"MenuFont" + item.nMenuID}
            style={{ overflow: "hidden", cursor: "pointer", fontSize: "13px", fontFamily: "Prompt" }}
            key={item.sID}
            onClick={(e) => {
              // onSaveMenuGoogleAnalytics(item);
              setFocus()
            }}
            className={`${nLevel > 1 ? "h-[50px]" : "h-[70px]"}  flex flex-col justify-center ${nLevel > 1 ? "items-start" : "items-center"} px-[1em]
                        ${isActive ? "bg-[#304FFE] text-[#ffffff]" : "text-[#000] bg-white"}  hover:bg-[rgba(0,0,0,0.08)] hover:text-[#0366d6]
                        ${nLevel > 1 ? "border-b" : "border-b-0"}`}
            to={item.sUrl} >
            <label style={{ paddingLeft: nLevel > 2 ? nLevel * 3.5 : 0 }} className="cursor-pointer">
              {getLanguage().id === "th" ? item.sNameTH : item.sNameEN}
              <NotificationsNoneIcon style={{ display: ((item.nMenuID == 18 || item.isNoti) && isProfileNoti) ? "" : "none", color: "white", backgroundColor: "red", borderRadius: "20px", fontSize: "14px", marginLeft: "5px" }} />
            </label>
          </Link>
        // </>
      }
    </>
  );
}