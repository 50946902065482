import { useEffect, useState } from "react";
import {
  DataGridMui as Table,
  initRows,
  PaginationInterface,
  FilterFieldInterface,
} from "components/Common/Table/DataGridMui";
import { GridColumns } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import {
  BtnAddOnTable,
  BtnEditOnTable,
  BtnViewOnTable,
} from "components/Common/Button";
import { useDispatch } from "react-redux";
import {
  Tooltip,
} from "@mui/material";
import {
  AxiosPost,
  ResultAPI,
  AxiosGet,
} from "service/CommonFunction/TS_function";
import { DialogActionCreators } from "store/redux/DialogAlert";
import { i18n } from "i18n";
import secureLocalStorage from "react-secure-storage";

export default function OrderEmployerTable() {
  const i18nField = "TableOrderEmployer";
  const i18nField_Common = "common";

  const dispatch = useDispatch();
  const history = useNavigate();
  const navigate = useNavigate();

  const [nPermission, setPermission] = useState(2);
  const [loadingTable, setLoadingTable] = useState(true);
  const [lstOptionStatus, setlstOptionStatus] = useState([]);
  const [lstStatusPackage, setlstStatusPackage] = useState([]);
  const [TypeID, setTypeID] = useState(0);


  let arrrow = {
    ...initRows,
    sSortExpression: "dOrderPurchaseDate",
    sSortDirection: "desc",
  }
  const sPathName = window.location.pathname;
  const sStore = secureLocalStorage.getItem(sPathName + "1");
  if (sStore && sStore !== "") {
    const objRowTemp = JSON.parse(sStore + "");
    if (objRowTemp != null) {
      arrrow = objRowTemp;
      arrrow.arrRows = [];
    }
  }
  const [dataRow, setDataRow] = useState<PaginationInterface>(arrrow);
  // const [dataRow, setDataRow] = useState<PaginationInterface>({
  //   ...initRows,
  //   sSortExpression: "dOrderPurchaseDate",
  //   sSortDirection: "desc",
  // });


  const OnGetData_Status_Order = () => {
    AxiosGet(
      "OrderEmployer/GetData_DropDown_Status_Order",
      {},
      (result) => {
        setlstOptionStatus([]);
        result.lst_Status_Order.map((item, index) => {
          lstOptionStatus.push({
            label: secureLocalStorage.getItem("language") === "th" ? item.label : item.labelEn,
            keyId: index + 1,
            value: item.value,
          });
          setlstOptionStatus(lstOptionStatus);

        });

        result.lst_Status_Package.map((item, index) => {
          lstStatusPackage.push({
            label: secureLocalStorage.getItem("language") === "th" ? item.label : item.labelEn,
            keyId: index + 1,
            value: item.value,
          });
          setlstStatusPackage(lstStatusPackage);

        });
      },
      (err) => {
        if (!err.response) {
          history("/error", { replace: true });
          return;
        }
      }
    );
  };

  useEffect(() => {
    OnGetData_Status_Order();
    loadData(dataRow);
  }, []);

  const loadData = (p: PaginationInterface) => {
    setLoadingTable(true);
    AxiosPost(
      "OrderEmployer/SearchData_TableDataOrderEmployer",
      p,
      (result) => {
        setLoadingTable(false);
        setTypeID(result.lstData.nType_ID)
        setDataRow({
          ...p,
          arrRows: result.lstData || [],
          nDataLength: result.nDataLength,
          nPageIndex: result.nPageIndex,
        });
      }
    );
  };

  const OnDelete = (e) => {
    dispatch(
      DialogActionCreators.OpenDialogSubmit(
        i18n(`${i18nField}.AlertConDel`),
        () => {
          dispatch(DialogActionCreators.CloseDialogSubmit() as any);
          dispatch(DialogActionCreators.LoadSubmit("Waiting...") as any);

          AxiosPost(
            `OrderEmployer/RemoveDataTableOrderEmployer`,
            e,
            (result) => {
              dispatch(DialogActionCreators.CloseDialogSubmit() as any);

              ResultAPI(
                result,
                i18n(`${i18nField_Common}.AlertConfrimDel`),
                () => {
                  dispatch(
                    DialogActionCreators.OpenDialogSuccess(
                      i18n(`${i18nField_Common}.msgAlertDelSuccess`)
                    ) as any
                  );
                  loadData(dataRow);
                }
              );
            },
            (err) => {
              if (!err.response) {
                history("/error", null);
                return;
              }
            }
          );
        }
      ) as any
    );
  };

  // filter
  const filter: FilterFieldInterface[] = [
    { sTypeFilterMode: "input", sFieldName: "sOrderNumber" },
    { sTypeFilterMode: "input", sFieldName: "sOrderPackageName" },
    { sTypeFilterMode: "daterange", sFieldName: "sOrderPurchaseDate" },
    { sTypeFilterMode: "daterange", sFieldName: "sOrderExpiryDate" },
    {
      sTypeFilterMode: "select",
      sFieldName: "nStatusID",
      sLabel: i18n(`${i18nField}.Status`),
      lstDataSelect: lstOptionStatus,
    },
    {
      sTypeFilterMode: "select",
      sFieldName: "nStatusPackageID",
      sLabel: i18n(`${i18nField}.StatusPackage`),
      lstDataSelect: lstStatusPackage,
    },
  ];

  //สร้างตาราง
  const dataColumn: GridColumns = [
    {
      renderHeader: (item) => (
        <BtnAddOnTable
          onClick={() => {
            history("/ServicesBPS");
          }}
          txt={i18n(`${i18nField_Common}.add`)} //Add
        />
      ),
      headerName: "",
      field: i18n(`${i18nField_Common}.ButtonAdd`),
      type: "actions",
      width: 100,
      resizable: false,
      sortable: false,
      getActions: (item) => {
        if (item.row.nType_ID !== 2) {
          if (item.row.nStatusID === 1) {
            return [
              <BtnEditOnTable
                txt={i18n(`${i18nField_Common}.edit`)} //Edit
                onClick={() => goToEdit(item.row.sID, item.row)}
              />,
            ];
          } else if (item.row.nStatusID === 3) {
            return [
              <BtnEditOnTable
                txt={i18n(`${i18nField_Common}.edit`)} //View
                onClick={() => goToEdit(item.row.sID, item.row)}
              />,
            ];
          } else {
            return [
              <BtnViewOnTable
                txt={i18n(`${i18nField_Common}.preview`)} //View
                onClick={() => goToEdit(item.row.sID, item.row)}
              />,
            ];
          }
        } else {
          return [
            <BtnViewOnTable
              txt={i18n(`${i18nField_Common}.preview`)} //View
              onClick={() => goToEdit(item.row.sID, item.row)}
            />,
          ];
        }
      },
    },
    {
      field: "sOrderNumber",
      headerName: i18n(`${i18nField}.OrderNumber`), //"เลขที่คำสั่งซื้อ",
      headerAlign: "center",
      align: "left",
      resizable: false,
      flex: 2,
      minWidth: 200,
    },
    {
      field: "sOrderPackageName",
      headerName: i18n(`${i18nField}.Package`), //"แพ็กเกจ",
      headerAlign: "center",
      align: "left",
      resizable: false,
      flex: 2,
      minWidth: 200,
      renderCell: (item) => {
        return [
          (
            <Tooltip placement="top" title={item.row.sOrderPackageName}>
              <div id={item.row.sID}
                style={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  width: '300px',
                }}>
                {item.row.sOrderPackageName}
              </div>

            </Tooltip>
          )
        ];
      }
    },
    {
      field: "sOrderTotal",
      headerName: i18n(`${i18nField}.Price`), //"ราคา",
      headerAlign: "center",
      align: "right",
      resizable: false,
      flex: 2,
      minWidth: 200,
    },
    {
      field: "sOrderPurchaseDate",
      headerName: i18n(`${i18nField}.OrderDate`), //"วันที่สั่งซื้อ",
      headerAlign: "center",
      align: "center",
      resizable: false,
      flex: 2,
      minWidth: 200,
    },
    {
      field: "sOrderExpiryDate",
      headerName: i18n(`${i18nField}.ExpDate`), //"วันที่หมดอายุ",
      headerAlign: "center",
      align: "center",
      resizable: false,
      flex: 2,
      minWidth: 200,
    },
    {
      field: secureLocalStorage.getItem("language") === "th" ? "sStatusNameTh" : "sStatusNameEn", //"sStatusName",
      headerName: i18n(`${i18nField}.Status`), //"สถานะ",
      headerAlign: "center",
      align: "center",
      resizable: false,
      flex: 2,
      minWidth: 280,
    },
    {
      field: secureLocalStorage.getItem("language") === "th" ? "sStatusPackageTh" : "sStatusPackageEn", //"sStatusName",
      headerName: i18n(`${i18nField}.StatusPackage`), //"สถานะ",
      headerAlign: "center",
      align: "center",
      resizable: false,
      flex: 2,
      minWidth: 200,
    },
  ];

  const goToEdit = (sID: string, item: any) => {
    if (item.nStatusID == 1) {
      let sIDPackage = ""
      if (item.nTypePackage === 3) {
        sIDPackage = item.sID
      } else {
        sIDPackage = item.nCheckPomotion == 1 ? item.sPomotionID : item.sPackage_ID
      }

      history(`/ServicesBPS?sID=${encodeURI(sIDPackage)}&nMode=${item.nCheckPomotion}&nTypePackage=${item.nTypePackage}&sOrderID=${item.sID}`);
    } else {
      history(`/OrderEmployerForm?sID=${encodeURI(sID)}`);
    }


  };

  return (
    <Table
      id="1"
      isLoading={loadingTable}
      filterField={filter}
      Rows={dataRow}
      Columns={dataColumn}
      OnLoadData={(p) => loadData(p)}
      isShowCheckBox={nPermission === 2}
      onDelete={OnDelete}
      isRowSelectable={(p) => p.row.nStatusID == 1 && p.row.nType_ID != 2}
    />
  );
}