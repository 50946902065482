import { Grid, Typography } from "@mui/material"
import CardBanner from "./CardBanner"
import banner1 from "assets/images/banner1.png"
import banner2 from "assets/images/banner2.png"
import banner3 from "assets/images/banner3.png"
import { i18n } from "i18n"
import ParseHTML from "html-react-parser";
import BoxAddress from "./BoxAddress"
const BannerService = () => {
  const i18nField = "OrderEmployer";
  return (
    <>

      <Grid item xs={12} >
        <CardBanner />
      </Grid>

      <Grid container xs={12} style={{ marginTop: "10%" }} justifyContent={"center"} spacing={1}>
        <Grid item xs={4} >
          <img
            className={"zoom-image-banner"}
            src={banner1}
            alt=""
            style={{
              width: "50%",
              marginLeft: "auto",
              marginRight: "auto",
              display: "block"
            }}
          />
          <Grid item container justifyContent={"center"} xs={12}>
              <Typography className="Banner-Icon">{ParseHTML(i18n(`${i18nField}.BannerIcon1`))}</Typography>
          </Grid>
        </Grid>

        <Grid item xs={4} >
          <img
            className={"zoom-image-banner"}
            src={banner2}
            alt=""
            style={{
              width: "50%",
              marginLeft: "auto",
              marginRight: "auto",
              display: "block"
            }}
          />

          <Grid item container justifyContent={"center"} xs={12}>
              <Typography className="Banner-Icon">{ParseHTML(i18n(`${i18nField}.BannerIcon2`))}</Typography>
          </Grid>
        </Grid>
        <Grid item xs={4} >
          <img
            className={"zoom-image-banner"}
            src={banner3}
            alt=""
            style={{
              width: "50%",
              marginLeft: "auto",
              marginRight: "auto",
              display: "block"
            }}
          />
          <Grid item container justifyContent={"center"} xs={12}>
              <Typography className="Banner-Icon">
                {ParseHTML(i18n(`${i18nField}.BannerIcon3`))}
              </Typography>
          </Grid>
        </Grid>

      </Grid>
      <Grid container xs={12} style={{ marginTop: "5%" }} justifyContent={"center"}>
        <BoxAddress />
      </Grid>
    </>
  )
}
export default BannerService