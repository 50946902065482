import { Grid, Box, Tooltip, Hidden } from "@mui/material";
import { useNavigate } from "react-router-dom";

//i18n
import { i18n } from "i18n";
const i18nForthisPage = "home";

export default function LeadingCompany({ lstLeadingCompany = [] }) {
  const navigate = useNavigate();

  let nLeadingCompanySize = 5;
  let nLastRow = Math.ceil(lstLeadingCompany.length / nLeadingCompanySize);

  return (
    <>
      <Grid container spacing={2} sx={{ mt: 2, my: 2, pl: 2, pr: 2, justifyContent: "center" }} columns={5}>
        {lstLeadingCompany.map((item, index) => (
          (item.sURLImage ?
            <Grid item md={1} key={index} >
              <Box
                sx={{
                  width: "100%",
                  height: "auto",
                  display: "flex",
                  backgroundColor: "white",
                  flexDirection: "row",
                  justifyContent: "center",
                  pt: "1vw",

                  borderBottom:
                    Math.ceil((index + 1) / nLeadingCompanySize) === nLastRow
                      ? ""
                      : "1px solid #c1cfdb",
                  borderRight:
                    (index + 1) % nLeadingCompanySize === 0
                      ? ""
                      : "1px solid #c1cfdb",
                  border: "2px #eeeeee solid",
                  borderRadius: 5, p: 3,
                }}
              >
                {(process.env.REACT_APP_API_URL + item.sURLImage) ? (
                  <Tooltip title={i18n(`${i18nForthisPage}.btnViewCompany`)}>
                    {/* <Grid item xs={12} md={1} key={index} > */}
                    <Box
                      className="not-cover-image zoom-image-banner"
                      sx={{
                        backgroundImage: `url("${item.sURLImage}")`,
                        backgroundSize: "contain",
                        width: "12vw",
                        height: "12vw",
                        // width: "180px",
                        // height: "180px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        navigate(
                          `/Guest/Company?sID=${item.sCompanyID}&isFromCompanyID=1`
                        );
                      }}
                    />
                    {/* </Grid> */}
                  </Tooltip>
                ) : null}
              </Box>
            </Grid>
            : null
          )
        ))}
      </Grid>
    </>
  );
}