import { Box, Grid, Typography } from "@mui/material"
import { i18n } from "i18n";
import "./ServicesBPS.css";
import { useState } from "react";
import CardServicesBPS from "./CardServicesBPS";
import JobPost1 from "assets/images/JobPost1.png"
import JobPost2 from "assets/images/JobPost2.png"
import JobPost3 from "assets/images/JobPost3.png"
import ParseHTML from "html-react-parser";
import ServiceTable from "./ServiceTable";
import MoldalConditions from "./MoldalConditions";
import "./swipeCard.css";

const JobPostService = () => {
    const i18nField = "OrderEmployer";
    const [isShowPreview, setIsShowPreview] = useState(false);


    return (
        <>
            <Grid container  >
                <Grid item xs={12}>
                    <CardServicesBPS nTypeID={1} />
                </Grid>
            </Grid>
            <Grid container xs={12} style={{ marginTop: "5%" }} justifyContent={"center"}>
                <Grid item xs={4}>
                    <img
                        className={"zoom-image-banner"}
                        src={JobPost1}
                        alt=""
                        style={{
                            width: "50%",
                            marginLeft: "auto",
                            marginRight: "auto",
                            display: "block"
                        }}
                    />
                    <Grid item container justifyContent={"center"} xs={12}>
                        <Typography className="Banner-Icon">{ParseHTML(i18n(`${i18nField}.JobPosticon1`))}</Typography>
                    </Grid>

                </Grid>

                <Grid item xs={4} >
                    <img
                        className={"zoom-image-banner"}
                        src={JobPost2}
                        alt=""
                        style={{
                            width: "50%",
                            marginLeft: "auto",
                            marginRight: "auto",
                            display: "block"
                        }}
                    />

                    <Grid item container justifyContent={"center"} xs={12}>
                        <Typography className="Banner-Icon">{ParseHTML(i18n(`${i18nField}.JobPosticon2`))}</Typography>
                    </Grid>
                </Grid>
                <Grid item xs={4} >
                    <img
                        className={"zoom-image-banner"}
                        src={JobPost3}
                        alt=""
                        style={{
                            width: "50%",
                            marginLeft: "auto",
                            marginRight: "auto",
                            display: "block"
                        }}
                    />
                    <Grid item container justifyContent={"center"} xs={12}>
                        <Typography className="Banner-Icon">{ParseHTML(i18n(`${i18nField}.JobPosticon3`))}</Typography>
                    </Grid>
                </Grid>

            </Grid>
            <Grid item xs={12} style={{ marginTop: "10%" }}>
                <ServiceTable nPackageTypeID={1} />
            </Grid>
            <MoldalConditions nID={4} isShowPreview={isShowPreview} setIsShowPreview={setIsShowPreview} />
        </>
    )

}
export default JobPostService