import { JobPostEn } from "./JobPost";
import { OrderEmployerEn } from "./OrderEmployer";
import { RegisterEmployerEn } from "./RegisterEmployer";
import { JobDetailEn } from "./JobDetail";
import { SettingSeekerEn } from "./SettingSeeker";
import { SeekerProfileEN } from "./SeekerProfile";
import { MyPageEmployerEN } from "./MyPageEmployer";
import { SearchJobEn } from "./SearchJob";
import { HomeEn } from "./Home";
import { TableOrderEmployerEn } from "./OrderEmployer";
import { TablePackageEn } from "./OrderEmployer";
import { TableBannerEn } from "./OrderEmployer";
import { MyJobInvitation_EN } from "./MyJobInvitation";
import { SetupTemplateEN } from "./SetupTemplate";
import { MyJobInterestEN } from "./MyJobInterest";
import { MyJobEn } from "./MyJob";
import { labelI18nEn } from "layout/CommonLayout/Layout_Font/Modal_Forgot";
import * as EmployerSearchFilter from "components/EmployerSearch/Filter";
import * as EmployerSearchCard from "components/EmployerSearch/CardItem";
import * as EmployerInformation from "components/EmployerSearch/InfomationJobSeeker";
import * as EmployerSearchModal from "components/EmployerSearch/ModalInvite";
import * as JobseekerRegisJobModal from "components/JobSeekerRegisJob/ModalAddTag";
import * as ModalBuyProfile from "components/EmployerSearch/ModalBuyProfile";
import { MyJobApplyEN } from "./MyJobApply";
import * as JobseekerFilterTabs from "components/JobSeekerRegisJob/FilterTabs";

const en = {
  TablePackage: {
    ...TablePackageEn,
  },
  TableBanner: {
    ...TableBannerEn,
  },
  TableOrderEmployer: {
    ...TableOrderEmployerEn,
    Page: {
      Title: "Service Orders",
    }
  },
  searchJob: {
    ...SearchJobEn,
    Page: {
      Title: "Search",
    }
  },
  home: {
    ...HomeEn,
  },
  MyPageEmployer: {
    ...MyPageEmployerEN,
  },
  SettingSeeker: {
    ...SettingSeekerEn,
    Page: {
      Title: "Setting",
    }
  },
  jobPost: {
    ...JobPostEn,
  },
  OrderEmployer: {
    ...OrderEmployerEn,
    Page: {
      Title: "BPS Services",
    }
  },
  RegisterEmployer: {
    ...RegisterEmployerEn,
  },
  jobDetail: {
    ...JobDetailEn,
  },
  myJobInvitation: {
    ...MyJobInvitation_EN,
  },
  SetupTemplate: {
    ...SetupTemplateEN,
    Page: {
      Title: "Employer setting",
    }
  },
  MyJob: {
    ...MyJobEn,
    Page: {
      Title: "My Task",
    }
  },
  common: {
    active: "Active",
    inactive: "Inactive",
    or: "or",
    cancel: "Cancel",
    reset: "Reset",
    back: "Back",
    save: "Save",
    search: "Search",
    edit: "Edit",
    new: "New",
    close: "close",
    preview: "Preview",
    export: "Export to Excel",
    noDataToExport: "No data to export",
    import: "Import",
    discard: "Discard",
    LastUpdate: "Last update",
    yes: "Yes",
    no: "No",
    pause: "Pause",
    areYouSure: "Are you sure?",
    view: "View",
    destroy: "Delete",
    mustSelectARow: "Must select a row",
    start: "Start",
    end: "End",
    select: "Select",
    continue: "Continue",
    filters: "Filters",
    status: "Status",
    description: "Description",
    unit: "Unit",
    selected: "items",
    AlertRequestSuccess: "Request sent successfully, please check your email.",
    AlertConfrimDel: "Do you want to remove data ?",
    AlertConfrimSave: "Do you want to save data ?",
    AlertConfrimSendEmail: "Do you want to send email ?",
    AlertConfrimDeactivate: "Do you want to deactivate data ?",
    AlertConfrimSaveEdit: "Do you want to send invitation email ?",
    msgAlertDelSuccess: "Remove successfully.",
    msgAlertSuccess: "Saved successfully.",
    msgAlertSendEmail: "Send email successfully.",
    msgAlertSpecificData: "Please specific data.",
    msgAlertInvitation: "Invitation has been sent successfully.",
    more_than: "More than",
    between: "Between",
    custom_value: "Custom value",
    Year: "year",
    baht: "THB",
    remove: "Remove",
    add: "Add",
    fileSizeLimit: "File size limit up to",
    AlertConfrimEdit: "Do you want to change your password ?",
    msgAlertLogout: "Do you want to log out ?",
    myJobApply: "Applied Job",
    myJobInterested: "Interested Job",
    myJobViewed: "Interested in you",
    myJobInvitation: "Invited Job",
    msgAlertIncorrectSecureCode: "Incorrect Password",
    msgAlertDataNotFound: "Data not found",
    msgAlertUsernameOrSecureCodeWrong: "Username or Password is wrong",
    msgAlertIncorrect: "Login type is wrong",
    msgAlertMemberWarning: "Plase add permission for this user",
    msAlertStatus: "The user doesn't exist.",
    msAlerlRegister: "Unable to register because this invitation was cancelled by administrator.",
    forgotSecureCode: "Change Password",
    requestExpired: "Request Expired!",
    Expand: "Other Job",
    AlertConfrimUpload: "Without Logo, will not be able to view company details.",
    magAlertPackageDeleted: "Cannot save, the package has been deleted.",
    SessionExpired: "Session expired, Please log in again",
    ButtonAdd: "Button (Add/Edit)",
    duplicateButt: "Duplicate",
    buypackage: "Buy Package",
    email: "Email",
    sendEmail: "Email Invitation",
    register: "Register",
    loginformember: "Member Login",
    memberName: "E-mail",
    memberSecureCode: "Password",
    previous: "Previous",
    next: "Next",
    buypackageforemployer: "Order Package For Employer",
    msgEmailDup: "Email is duplicate",
    loginmember: "LOG IN",
    loginformemberService: "Member Login",
    formemberService: "",
    msgAlertDataNotFoundForgot: "The user doesn't exist.",

    //alert confirm post job page
    AlertDuplicatePostJob: "Do you want to duplicate data ?",
    AlertSaveDraftPostJob: "Do you want to save draft ?",
    AlertPublishPostJob: "Do you want to publish ?",
    AlertBoostPostJob: "Do you want to boostpost ?",
    AlertDeactivatePostJob: "Do you want to deactivate ?",
    AlertDelFile: "A resume is a crucial document that can significantly increase your chances of finding your job. Are you sure you want to delete it?",

    //alert confirm order employer side
    AlertSendProofPayment: "Do you want to send proof of payment ?",
    AlertCancelOrder: "Do you want to cancel ?",
    AlertProofPaymentSuccess: "Send proof of payment successfully.",

    magAlertPackageCan: "Cannot save, the package has been canceled.",

  },
  ForgotPw: {
    ...labelI18nEn,
  },
  app: {
    title: "BPS Job Board",
  },
  EmployerSearch: {
    Filter: { ...EmployerSearchFilter.en },
    Info: { ...EmployerSearchCard.en },
    ModalInvite: { ...EmployerSearchModal.en },
    Information: { ...EmployerInformation.en },
    ModalBuyProfile: { ...ModalBuyProfile.en },
    EmployerSearchName: {
      Title: "Search Jobseeker",
    }
  },
  JobSeekerRegisJob: {
    ModalAddTag: { ...JobseekerRegisJobModal.sEn },
    FilterTabs: { ...JobseekerFilterTabs.en },
      Page: {
          Title: "Job Applicants",
      }
  },
  // auth.login.fields
  entities: {
    ...SeekerProfileEN,
    ...MyJobInterestEN,
    ...MyJobApplyEN,
    auth: {
      login: {
        fields: {
          Username: "Username",
          UserSecureCode: "Password",
          Email: "Email",
          OldSecureCode: "OldPassword",
          sSecureCodeNow: "NewPassword",
          ConfirmSecureCode: "ConfirmNewPassword",
        },
        labels: {
          OldSecureCode: "Old Password",
          sSecureCodeNow: "New Password",
          ConfirmSecureCode: "Confirm New Password",
        },
      },
    },
    graph: {
      fields: {
        BoxNo: "BoxNo",
        ActionDate: "ActionDate",
        Patient: "Patient",
        BackDate: "BackDate",
        Frame: "Frame",
        Display: "Display",
        Zoom: "Zoom",
      },
    },
    rolepermission: {
      fields: {
        Role: "Role",
      },
    },
    note: {
      fields: {
        note: "note",
      },
    },
    userinfo: {
      fields: {
        FirstName: "First Name",
        LastName: "Last Name",
        UserName: "Username",
        Email: "Email",
        Status: "Status",
        Hospital: "Hospital",
        SignatureCode: "Signature Code",
        MaxConcurrent: "Max Concurrent",
        HospitalRole: "Role",
      },
    },
    filteruserinfo: {
      fields: {
        Search: "Name / Email",
        Hospital: "Hospital",
        Role: "Role",
        Status: "Status",
      },
    },
    SignModal: {
      fields: {
        Docter: "Docter Name",
        Electronic: "Signature Code",
        Status: "Status",
        Parent: "Parent",
      },
    },
    ParentModal: {
      fields: {
        Parent: "Parent",
      },
    },
    LoadData: {
      fields: {
        Remark: "Remark",
      },
    },
    UserPermission: {
      fields: {
        Status: "Status",
        GroupUser: "Group User",
        note: "Note",
        unit: "Unit",
        active: "Active",
        inactive: "Inactive",
        Empno: "Employee",
        name: "Name",
        Division: "Unit",
        LastUpdate: "Last Updated",
        warnNotEdit: "Not edit found.",
        hTableEmpCode: "Code",
        hTableGroupUser: "Group User",
        Col_UpdateBy: "Updated By",
      },
    },
    RegisterTime: {
      fields: {
        Col_No: "No.",
        Col_Year: "Year",
        Col_SDate: "Start Date",
        Col_EDate: "End Date",
        Col_LastUpdate: "Last Updated",
        Col_UpdateBy: "Updated By",
        Col_Status: "Status",
        Status: "Status",
        Year: "Year",
        Active: "Active",
        Inactive: "Inactive",
        SDate: "Start Date",
        EDate: "End Date",
        Note: "Note",
        Frequency: "Frequency",
        RegisterTimeLine: "Register Timeline",
        TimeLineRound: "Timeline Round",
      },
    },
    Criteria: {
      fields: {
        Col_Order: "Order",
        Col_Year: "Year",
        Col_MainImpact: "Main Impact",
        Col_SubImp: "Sub Impact Name",
        Col_SubImpact: "Level",
        Col_LastUpdate: "Last Updated",
        Col_Status: "Status",
        Col_Note: "Note",
        Status: "Status",
        Year: "Year",
        Active: "Active",
        Inactive: "Inactive",
        Note: "Note",
        Order: "Order",
        CriteriaName: "Criteria Name",
        MainImpact_name: "Main Impact Name",
        SubCriteriaName: "Sub Impact Name",
        Col_UpdateBy: "Updated By",
      },
    },
    Likelihood: {
      fields: {
        Col_Order: "Order",
        Col_Year: "Year",
        Col_MainImpact: "Sub Impact",
        Col_SubImpact: "Likelihood Level",
        Col_LastUpdate: "Last Updated",
        Col_Status: "Status",
        Col_Note: "Note",
        Col_Level: "Level",
        Status: "Status",
        Year: "Year",
        Active: "Active",
        Inactive: "Inactive",
        Note: "Note",
        Order: "Order",
        LikelihoodName: "Likelihood Name",
        SubLikelihoodName: "Sub Likelihood Name",
        Col_UpdateBy: "Updated By",
      },
    },
    MasterType: {
      fields: {
        No: "No",
        Name: "Master Data Type Name",
        LastUpdate: "Last Updated",
        LastUpdateBy: "Updated By",
        Status: "Status",
        Order: "Order",
        SubType: "SubType",
        Active: "Active",
        Inactive: "Inactive",
        Note: "Note",
        Col_SubData: "Master Data",
      },
    },
    MasterData: {
      fields: {
        No: "No",
        Name: "Master Data Name",
        Code: "Code",
        LastUpdateBy: "Updated By",
        LastUpdate: "Last Updated",
        Status: "Status",
        Order: "Order",
        Active: "Active",
        Inactive: "Inactive",
        Note: "Note",
        Type: "Type",
        Unit: "Unit",
        Sub: "Sub",
        Col_Order: "Order",
        Col_SubData: "Sub Master Data",
        Col_LastUpdate: "Last Updated",
        Col_Status: "Status",
        Col_Note: "Description",
        Col_SubCode: "Sub Code",
        Col_Unitname: "Unit Name",
      },
    },
    ERM: {
      fields: {
        CorporateArea: "Corporate Area",
        DivSec: "Div./Sec.",
        RiskOwner: "Risk Owner",
        DivisionManager: "Division Manager",
        DepartmentManager: "Department Manager",
        RiskInformation: "Risk Information",
        RiskName: "Risk Name",
        RiskDescription: "Risk Description",
        RiskIdentification: "Risk Identification",
        RiskFactor: "Risk Factor",
        RiskArea: "Risk Area",
        RiskSource: "Risk Source",
        TopQty: "Top Quartile Objectives",
        Frequency: "Frequency",
        RiskCategorization: "Risk Categorization",
        Approval: "Approval",
        // BusinessProcessandOperation: "Business Process and Operation",
        RiskSubImpactCategory: "Risk Sub Impact Category",
        RiskLikelihoodCategory: "Risk Likelihood Category",
        QualitativeMeasurement: "Qualitative Measurement",
        InherentImpact: "Inherent Impact (1-4)",
        InherentImpact2: "Inherent Impact",
        ResidualImpact: "Residual Impact",
        Description: "Description",
        InherentLikelihood: "Inherent Likelihood",
        ResidualLikelihood: "Residual Likelihood",
        InherentRiskLevel: "Inherent Risk Level",
        ResidualRiskLevel: "Residual Risk Level",
        InherentRiskRating: "Inherent Risk Rating",
        ResidualRiskRating: "Residual Risk Rating",
        CurrentlyImpact: "Currently Impact",
        CurrentlyRiskLevel: "Currently Risk Level",
        CurrentlyRiskRating: "Currently Risk Rating",
        CurrentlyLikelihood: "Currently Likelihood",
        Treatment: "Treatment",
        ControlMitigation: "Control/Mitigation Plan",
        MyControl: "My Control",
        Control: "Control",
        OtherDivisionSection: "Other Division/Section",
        RiskOwnerName: "Risk Owner Name",
        RiskOwnerCode: "Risk Owner Code",
        DivisionSection: "Division/Section",
        Active: "Active",
        Inactive: "Inactive",
        Inherent: "Inherent",
        Residual: "Residual",
        Year: "Year",
        Month: " Month",
        SectionManager: "Section Manager",
        AddControl: "Add Control",
        Clear: "Clear",
        Status: "Status",
        Submit: "Submit & Send ERM Risk",
        SubmitRisk: "Submit & Send Risk",
        Save: "Save ERM Risk",
        SaveRisk: "Save Risk",
        Cancel: "Cancel",
        Process: "Process",
        SubProcess: "Sub Process",
        ContinuityRisk: "Continuity Risk",
        RiskConcernArea: "Risk Concern Area",
        SMConcerned: "SM Concerned",
        IIFConcerned: "IIF Concerned",
        StakeholderConcerned: "Stakeholder Concerned",
        OpExElementsConcerned: "OpEx Elements Concerned",
        Reject: "Reject",
        Col_RiskId: "Risk ID",
        Col_RiskName: "Risk Name",
        Col_DivSec: "Div./Sec.",
        Col_Currently: "Currently",
        Col_Frequency: "Frequency",
        Col_Year: "Year",
        Col_Inherent: "Inherent",
        Col_Residual: "Residual",
        Col_Control: "Control",
        Col_DeployDate: "Deploy Date",
        Col_Status: "Status",
        Col_ControlId: "Control ID",
        Col_ControlCode: "Control Code",
        Col_ControlName: "Control Name",
        Col_ControlDescription: "Control Description",
        Col_Date: "Date",
        Col_Comment: "Comment",
        ControlEffect: "Control Effectiveness",
        ProgressMitigationPlan: "Progress/Mitigation Plan",
        SaveAndSendEmail: "Save & Create New Record",
        SendToApprove: "SEND TO APPROVE",
      },
    },
    RiskControl: {
      fields: {
        Col_ControlId: "Control ID",
        Col_ControlCode: "Control Code",
        Col_ControlDescription: "Control Description",
        Col_ControlOwner: "Control Owner",
        Col_DivSec: "Div./Sec.",
        Col_Date: "Date",
        Col_Note: "Note",
        Col_CloseControl: "Closed Control",
        Col_Status: "Status",
        ControlEffect: "Control Effectiveness",
        ProgressMitigationPlan: "Progress/Mitigation Plan",
      },
    },
    LogHeader: {
      fields: {
        hLogTableActionName: "Action Name",
        hLogTableActionBy: "Action By",
        hLogTableActionDate: "Action Date",
        hLogTableActionBefore: "Before",
        hLogTableActionAfter: "After",
        hLogTableDetail: "Detail",
        hLogTableComment: "Comment",
      },
    },
    Email: {
      fields: {
        Year: "Year",
        Frequency: "Frequency",
        Round: "Round",
        ScheduleDate: "Schedule Date",
        Subject: "Subject",
        Description: "Description",
        EmailToByRole: "E-Mail To By Role",
        EmailCCByRole: "E-Mail CC By Role",
        EmailBCCByRole: "E-Mail BCC By Role",
        EmailTo: "E-Mail To",
        EmailCC: "E-Mail CC",
        EmailBCC: "E-Mail BCC",
        Content: "Content E-mail",
        Status: "Status",
        Active: "Active",
        Inactive: "Inactive",
      },
    },

    Banner: {
      No: "No",
      Employer: "Employer",
      Po: "PO NO.",
      Order: "Order NO",
      BannerDay: "Duration(Month)",
      StartDate: "Satrt Date",
      EndDate: "End Date",
      Position: "Adv.Position",
      BannerLink: "Banner link to",
      Note: "Note",
      Status: "STATUS",
      Active: " ACTIVE",
      LastEapire: " LAST EXPIRE",
      EmployerName: "EMPLOYER",
      OrderName: " ORDER",
      Start_End: "START - END",
      Inactive: "Inactive",
      AddNew: "ADD NEW POSITION",
      Search: "SEARCH & HISTORY",
      Expire: "Expire",
      Link: "Link",
      Statustb: "Status",
      PoNo: "PO No.",
      UploadFile: "UploadFile",
      Start: "Start",
      Linkto: "Link to",
      ActiveName: "Active",
      InactiveName: "Inactive",
      PostJob: "Post Job"
    },

    Package: {
      DurationMonth: "Duration (Month)",
      Duration: "Duration",
      Month: " Month",
      Pricename: "Price of Banner Package",
      Package: "Package",
      Price: "Price",
      Edit: "Edit",
      Banner: "Banner Package",
      Coin: "Coin Package",
      PackageName: "Package Name",
      CoinAmount: "Coin Amount",
      ExDurationAmoun: " Expiry duration (month)",
      TotalPrice: "Total Price",
      Save: "Discount (%)",
      Savename: "Save %",
      Vat: "Vat",
      Vatname: "Vat 7%",
      NetPrice: "Net Price",
      PriceAfterVat: "Price After Vat",
      Note: "Note",
      StartDate: "Start Date",
      EndDate: "End Date",
      Order: "Order No",
      Job: "Job Post Package",
      Select: "Select Package",
      JobPackage: "Job Package",
      CoinPackage: "Coin Package",
      BannerPackage: "Banner Package",
      PostAmount: "Job Post Amount",
      JobPostname: "JOB POST PACKAGE",
      Coinname: "COIN PACKAGE",
      Banername: "BANNER PACKAGE",
      Employer: "Employer :",
      Active: "Active :",
      AllPackage: " All Package :",
      ActionDate: "Action Date",
      ActionBy: "Action By",
      ActionName: "Action Name",
      EditButt: "Edit",
      JobPost: "Job Post",
      ExpiryDuration: "Expiry Duration (Month)",
      CoinHeadName: "Coin",
      LastUpdate: "Last Update",
      AddButt: "Add",
      Status: "Status",
      NormalPrice: "Normal Price",
      CointAmount: "Coint Amount",
      SpecialPrice: "Special Price",
      Net: "Net Price",
    },
    Customer: {
      Group: "Group",
      FirstName: "First Name",
      LastName: "Last Name",
      UserName: "User Name",
      UserSecureCode: "Password",
      Confirm: "Confirm Password",
      Invalid: "Invalid Confirm Password",
      Position: "Position",
      Email: "E-mail",
      Phone: "Phone Number",
    },

    AdminMaster: {
      NameTH: "Name (TH)",
      NameEN: "Name (ENG)",
      Datatype: "Data Type (TH)",
      Note: "Note",
      Addnew: "Add New",
      AllList: "All List",
      Educationlevel: "Education",
      Employment: "Employment",
      Group: "Industry",
      Position: "Position",
      Region: "Region",
      Province: "Province/District",
      Groupwork: "Group Work",
      work: "Work",
      travel: "Travel",
      benefit: "Benefit",
      country: "Country",
      WorkLocation: "Work Location",
      salaryInfo: "Salary",
      Status: "Status",
      Active: "Active",
      Inactive: "Inactive",
      BackButt: "Back",
      SaveButt: "Save",
      Institution: "Educational Institution",
    },
    AdminMasterTable: {
      EditTable: "Edit",
      Number: "No.",
      DataType: "Data Type",
      NameTH: "Name (TH)",
      NameENG: "Name (ENG)",
      LastUpdate: "Last Update",
      Status: "Status",
      StatusActive: "Active",
      StatusInactive: "Inactive",
    },
    GroupForm: {
      Number: "No.",
      GroupName: "User group name",
      GroupNameTH: "Group Name",
      MenuName: "Menu",
      Permission: "Permission",
      Disabled: "Disable",
      ReadOnly: "Read-Only",
      sCreat: "Creat/Delete/Edit",
      SelectIndustryType: "Select industry type",
      SelectAll: "Select all",
      Status: "Status",
      Active: "Active",
      Inactive: "Inactive",
      BackButt: "Back",
      SaveButt: "Save",
    },
    UserGroup: {
      Group: "Group",
      FirstName: "First Name",
      LastName: "Last Name",
      UserName: "User Name",
      UserSecureCode: "Password",
      Confirm: "Confirm Password",
      Invalid: "Invalid Confirm Password",
      Position: "Position",
      Email: "E-mail",
      Phone: "Phone Number",
      Employer: "Select employer name",
      Status: "Status",
      Active: "Active",
      Inactive: "Inactive",
      ResetSecureCode: "Reset Password",
      SelectIndustryType: "Please select industry type",
      SelectAllIndustry: "Select all",
      GroupPermission: "Permission by group",
      Number: "No.",
      Menu: "Menu",
      Disabled: "Disabled",
      ReadOnly: "Read-Only",
      sCreat: "Creat/Delete/Edit",
      BackButt: "Back",
      SaveButt: "Save",
      forgot_success: "Send success. \nPlease check your e-mail.",
    },

    Candidate: {
      Data: "Contact Infomation",
      Update: "Last Updated",
      Time: "Time",
      NameTH: "Name",
      SurnameTH: "Surname",
      NameEN: "Name",
      SurNameEN: "Surname",
      Mobile: " Mobile",
      Email: "E-mail",
      Socail: "Social",
      WorkExperience: "Work Experience",
      JobTitle: " Job Title",
      Company: " Company",
      StartWork: "Start Work",
      EndWork: " End Work",
      JobFunction: "Job Function",
      JobFunctionDetail: " Job Function Detail",
      Industry: "Industry",
      JobDescription: "Job Description",
      Institution: " Institution",
      Education: "Education",
      Qualificationlevel: "Qualification level",
      Major: "Major",
      finishedyear: "finished year",
      sCourse: "Course Highlights",
      IndividualData: "Individual Data",
      Print: "Print",
      JobPosition: "Expect Job",
      Nationality: "Nationality",
      Region: "Region/Bangkok",
      License: "License",
      Country: "Country",
      Province: "Province/District",
      ExpectSalary: "Expect Salary",
      District: "District",
      LangugeSkill: "Language Skill",
      Languge: "Language",
      Skill: "Skill",
      Status: "Status",
      ReadingSkill: "Reading Skill",
      WritingSkill: "Writing Skill",
      SpeakingSkill: "Speaking Skill",
      OtherAbility: "Other Ability",
      Ability: "Ability",
      MoreData: "More Data",
      JobInterested: " Job Interested",
      ComputerSkill: "Computer Skill",
      EmployerType: "Employer Type",
      Sex: "Sex",
      MinMax: "Min-Max",
      StartDate: "Start Date",
      AttachFile: "Resume and Other Documents",
      UserSecureCodeChange: "เปลี่ยนรหัสผ่าน/Change Password",
      Subscrit:
        "การเลือกรับข่าวสารผ่านช่องทางอีเมลของเว็บไซต์ และบริษัทในเครือ /Subscription ",
      jobInformation: "information",
      Applyforwork: "Apply for work",
      Interestedjob: "Interested job",
      History: "View history",
      Sendinvitation: "Send an invitation",
      Recommended: "Recommended work",
      Setting: "Candidate Settings",
      NumberPost: "Number Post",
      Job_TH: "Job TH",
      Company_Name: "Company Name",
      SendDate: "Send Date",
      PostDate: "Post Date",
      ComfirmDate: "Comfirm Date",
      CreateDate: "Date of interest",
      Comfirm: "Accept/Reject",
      ComfirmRefuseDate: "Accept/Reject Date",
      SendInvitationDate: "Send invitation ",
      IndustryType_Name: "IndustryType Name",
      CountryName: "Country",
      Province_Name: "Province",
      Region_Name: "Region",
      Consent:
        "Opt-in to receive news via the website's email channels and affiliates /Subscrit",
      ConsentLine: "Receive news through the website's email channel.",
      Keyword: "Keyword",
      Certificate: "Certificate",
      Present: "Present",
      ContactOther: "Other",
      NotSpecific: "Not Specific",
      Age: "Age",
      AgeYear: "Years",
      Name: "Name - Surname",
      PostDateName: "Company that bought  profile",
      Downloadprofile: "Download the applicant's resume",
    },
    OrderEmployer: {
      sPackage: "Package Type",
      Select: "Select Package",
      JobPackage: "Job Post Package",
      CoinPackage: "Coin Package",
      BannerPackage: "Banner Package",
      PricePerJob: "Price per Job",
      Discount: "Discount",
      GrandTotal: "Grand Total",
      Baht: "THB",
      SelectType: "Select Package Type",
      Vatname: "Vat 7%",
      TotalPrice: "Total Price",
      Month: "Month",
      Package: "Package",
      Invoice: "Invoice documents",
      Payment: "Proof of payment",
      Tax: "Tax invoice"
    },
    RegisEmployer: {
      // RegisterName: "ชื่อ - นามสกุลผู้ลงทะเบียน",
      // CompanyName: "ชื่อ - นามสกุลผู้ประกอบการ",
      RegisterName: "Registrant's name",
      CompanyName: "Operator's name",
      UserSecureCode: "Password",
      ConfirmSecureCode: "Confirm Password",
      UserSecureCodeIncorrect: "Invalid Confirm Password",
      Email: "Email",
      Phone: "Tel",
      ConfirmRegisDoc: "I want to receive news and related information",//ต้องการรับข่าวสาร และข้อมูลที่เกี่ยวข้องต่างๆ
      ConfirmRegis: "I accept all terms and conditions by BPS", //ข้าพเจ้าขอยอมรับทุกข้อกำหนดและเงื่อนไขต่างๆที่ BPS กำหนด
    },
    LayoutFront: {
      sNameTH: "Test Menu",
      signIn: "Sign in",
      signUp: "Sign up",
      Register: "Register",
      LoginText: "Login to BPS Job Board Platform",
      OwnerLogin: "Employer login",
      JobSeeker: "Job Seeker",
      Employer: "Employer",
      forgot_success: "Send success. \nPlease check your e-mail.",
      RegisterJobSeeker: "Job Seeker Register",
      RegisterEmployer: "Employer Register",
    },
    LoginForm: {
      Username: "E-mail",
      sSecureCode: "Password",
      BackButt: "Back",
      LoginButt: "Log in",
      FotgetSecureCode: "Forgot Password?",
      RememberMe: "Remember Me",
      Or: "Or",
      SignUp: "Register",
      AnotherWay: "With Another Way?",
    },
    GroupFormLog: {
      ActionDate: "Edit Date",
      ActionName: "Stus",
      ActionBy: "Edit By",
    },
    AdminLog: {
      ActionDate: "Edit Date",
      ActionName: "Stus",
      ActionBy: "Edit By",
    },
    AdminUser: {
      AddButt: "Add",
      EditButt: "Edit",
      Number: "No.",
      Name: "Name",
      GroupName: "Group Permission",
      Email: "E-mail",
      PhoneNumber: "Phone Number",
      UpdateBy: "Update By",
      UpdateDate: "Update Date",
      Status: "Status",
      Active: "Active",
      Inactive: "Inactive",
    },
    AdminContent: {
      AddButt: "Add",
      EditButt: "Edit",
      Number: "No.",
      PageName: "Page Name",
      CreateBy: "Create By",
      CreateDate: "Create Date",
      UpdateDate: "Update Date",
      Content: "Content",
      ParagraphAdding: "Add Paragraph",
      BackButt: "Back",
      PreviewButt: "Preview",
      SaveButt: "Save",
      ContentText: "Text",
      PictureText: "Picture",
      RemoveButt: "Delete",
      TextUnderPic: "Text under the picture",
      TextUnderVideo: "Text under the video",
      Status: "Status",
      StatusActive: "Active",
      StatusInactive: "Inactive",
    },
    AdminCandidate: {
      ViewDetail: "View Detail",
      JobSeekerNumber: "Applicant number",
      FullName: "Applicant Name",
      Email: "E-mail",
      PhoneNumber: "Phone Number",
      UpdateDate: "Update Date",
      forgot_success: "Send success. \nPlease check your e-mail.",
    },
    GroupTable: {
      AddButt: "Add",
      EditButt: "Edit",
      GroupName: "User group name",
      Status: "Status",
      Active: "Active",
      Inactive: "Inactive",
      UserNameCount: "User",
      DataIndustryCount: "Industry Type",
      UpdateDate: "Update Date",
    },
    Admincompany: {
      CompanyNo: "Company No",
      CompanyName: "Company Name",
      IndustryType: "Industry Type",
      ProvAndDist: "Province and District",
      UpdateDate: "UpdateDate",
      ActiveName: "Active",
      InactiveName: "Inactive",
    },
    MyJobBoard: {
      ApplyJob: "Apply Job",
      InterestedJob: "Interested Job",
      ViewJob: "Company Interested",
      InvitedJob: "Job Invited",
    },
    MyProfile: {
      Title: "Personal Data",
    },
    MyJobApplySize: {
      Title: "Apply Job",
    }
  },

  dialog: {
    DialogConfirm: "Confirm",
    DialogWarning: "Warning",
    CloseButt: "Close",
    DialogSuccess: "Success",
    DialogSpecify: "Please specify content",
    DialogRemoveSuccess: "Remove successfully.",
    Terms: "Terms & Conditions",
    privacy: "Privacy policy",
    Search: "search",
    Showcolumns: "Show columns",
    Searchcolumns: "Search columns",
    Hide: "Hide",
    ShowAll: "Show All",
    Select: "Select",
    Clearfilters: "Clear filters",
    Perpage: "Per page:",
    Goto: "Go to"
  },

  CompanyEmployee: {
    Name: "Name",
    Email: "Email",
    Role: "Role Setting",
    Status: "Status",
    InviteDate: "Invitation Date",
    LastUse: "Last Login Date",
    Inactive: "Inactive",
    Active: "Active",
    SendRequest: "Invite",
    sendemail: "Send Invitation",
    admin: "Administrator",
    user: "User",
    adminpermission: "Role administrator is allowed access to all menu.",
    assignpermission: "Add permission for this user",
    postjob: "Post Job",
    postjobDetail: " Create, view and edit job post.",
    jobseeker: "Search and manage job seeker",
    jobseekerDetail: "  Search, view jobseeker profile and manage candidate.",
    searchjobseeker: "Service Order Buy packages",
    searchjobseekerDetail: " view purchase order and account details.",
    buyandreportpayment: "Service order",
    buyandreportpaymentDetail: " Buy packages, view perchase order and account details.",
    RegisterMemberName: "Name - Surname Member",
    phonenumber: "Phone Number",
    memberinfo: "Member information",
    Title: "Account Setting",
  },

  DrawerBackMenu: {
    MenuHead: "Menu",
  },

  navbar: {
    Name: "a",
  },

  file: {
    UploadFile: {
      UploadFile: "Upload File",
      AllowFile: "Allowed file types: ",
      FileLimited: "File size limits up to ",
      fileCountLimit: "Upload a maximum of",
      recomandBannerSize: "Recommand banner size is ",
      recomandLogoSize: "Recommand logo size is",
      profilepicSize: "Recommand picture size is",
      UploadFileOnly: "Upload file only",
      recomandNoLogo: "If the logo is not added, it cannot be clicked to the company profile page."
    },
  },

  errors: {
    backToHome: "Back to home",
    403: `Sorry, you don't have access to this page`,
    404: "Sorry, the page you visited does not exist",
    500: "Sorry, the server is reporting an error",
    429: "Too many requests. Please try again later.",
    forbidden: {
      message: "Forbidden",
    },
    validation: {
      message: "An error occurred",
    },
    defaultErrorMessage: "Ops, an error occurred",
  },
  // See https://github.com/jquense/yup#using-a-custom-locale-dictionary
  /* eslint-disable */
  validation: {
    mixed: {
      default: "${path}",
      required: "${path}",
      oneOf: "${path} must be one of the following values: ${values}",
      notOneOf: "${path} must not be one of the following values: ${values}",
      notType: ({ path, type, value, originalValue }) => {
        return `${path} must be a ${type}`;
      },
    },
    string: {
      length: "${path} must be exactly ${length} characters",
      min: "${path} must be at least ${min} characters",
      max: "${path} must be at most ${max} characters",
      matches: '${path} must match the following: "${regex}"',
      email: "must be a valid email",
      url: "${path} must be a valid URL",
      trim: "${path} must be a trimmed string",
      lowercase: "${path} must be a lowercase string",
      uppercase: "${path} must be a upper case string",
      selected: "${path} must be selected",
      required: "${path} is required",
      sSecureCode:
        "Passwords must have at least 8 characters and contain all of the following: uppercase letters, lowercase letters, numbers, symbols. (The symbols can use !, @, #, $, %, ^, &, *)",
      ConSecureCode: "Password confirmation is incorrect",
    },
    number: {
      min: "${path} must be greater than or equal to ${min}",
      max: "${path} must be less than or equal to ${max}",
      lessThan: "${path} must be less than ${less}",
      moreThan: "${path} must be greater than ${more}",
      notEqual: "${path} must be not equal to ${notEqual}",
      positive: "${path} must be a positive number",
      negative: "${path} must be a negative number",
      integer: "${path} must be an integer",
    },
    date: {
      min: "${path} field must be later than ${min}",
      max: "${path} field must be at earlier than ${max}",
    },
    decimal: {
      min: "${path} field must be later than ${min}",
      max: "${path} field must be at earlier than ${max}",
    },
    boolean: {},
    object: {
      required: "${path} is required",
      noUnknown:
        "${path} field cannot have keys not specified in the object shape",
    },
    array: {
      min: ({ min, path }) =>
        min === 1
          ? `${path} is required`
          : `${path} field must have at least ${min} items`,
      max: "${path} field must have less than or equal to ${max} items",
    },
  },
  integer: {
    min: "${path} must be greater than or equal to ${min}",
    max: "${path} must be less than or equal to ${max}",
    required: "${path} is required",
  },

  autocomplete: {
    loading: "Loading...",
    noOptions: "No data found",
  },

  table: {
    noData: "No records found",
    loading: "Loading...",
  },

  pagination: {
    labelDisplayedRows: "{0}-{1} of {2}",
    labelRowsPerPage: "Per page:",
    of: "of",
    Goto: "Go to"
  },

  cookie: {
    text: "This website uses cookies to enhance the user experience. To find out more, read our ",
    policy: "privacy policy",
    cookieButton: "Accept"
  },

  TitlePage: {
    MyJobInterested: "Interested Job",
  }
};

export default en;
