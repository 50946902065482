import { useEffect, useState } from "react";
import {
  DataGridMui as Table,
  FilterFieldInterface,
  initRows,
  PaginationInterface
} from "components/Common/Table/DataGridMui";
import { GridColumns } from "@mui/x-data-grid";
import { AxiosPost, GetPermission, ResultAPI } from "service/CommonFunction/TS_function";
import { useNavigate } from "react-router-dom";
import { BtnAddOnTable, BtnEditOnTable } from "components/Common/Button"
import { useDispatch } from "react-redux"
import { DialogActionCreators } from "store/redux/DialogAlert";
import { FormControlLabel } from "@mui/material";
import IOSSwitch from "components/Common/ElementInForm/IosSwitch";
import { i18n } from "i18n";
import secureLocalStorage from 'react-secure-storage';


export default function BPS_GroupTable() {
  const i18nField = 'entities.GroupTable'
  const i18nCommon = 'common';
  const i18nDialog = 'dialog';
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loadingTable, setLoadingTable] = useState(true);
  const [nPermission, setPermission] = useState(0);

  let arrrow = {
    ...initRows,
    sSortExpression: "dUpdateDate",
    sSortDirection: "desc"
  }
  const sPathName = window.location.pathname;
  const sStore = secureLocalStorage.getItem(sPathName);
  ////console.log("secureLocalStorage", sStore);
  if (sStore && sStore !== "") {
    const objRowTemp = JSON.parse(sStore + "");
    ////console.log("objRowTemp", objRowTemp);
    if (objRowTemp != null) {
      arrrow = objRowTemp;
      arrrow.arrRows = [];
    }
  }
  const [dataRow, setDataRow] = useState<PaginationInterface>(arrrow);
  // const [dataRow, setDataRow] = useState<PaginationInterface>({
  //   ...initRows,
  //   sSortExpression: "dUpdateDate",
  //   sSortDirection: "desc"
  // });


  const filter: FilterFieldInterface[] = [
    { sTypeFilterMode: "input", sFieldName: "sGroupName" },
    { sTypeFilterMode: "daterange", sFieldName: "sUpdateDate" },
    {
      sTypeFilterMode: "select", sFieldName: "isActive", lstDataSelect: [
        { value: "1", keyId: 1, label: "ใช้งาน" },
        { value: "0", keyId: 0, label: "ไม่ใช้งาน" },
      ],
    }
  ]

  useEffect(() => {
    GetPermission(24, setPermission)
    loadData(dataRow);
  }, []);
  const loadData = (p: PaginationInterface) => {
    setLoadingTable(true)
    AxiosPost("GroupBPS/SearchData_Permission", p, (result) => {
      setLoadingTable(false)
      setDataRow({
        ...p,
        arrRows: result.lstGroup,
        nDataLength: result.nDataLength,
        nPageIndex: result.nPageIndex,
      });
    })
  }

  const goToEdit = (sID: string, item: any) => {
    // window.localStorage.removeItem("sSubRouteName");
    navigate(`/GroupForm_BPS?sID=${sID}`);
  };

  const onChangeStatus = (e, item) => {
    let arrRowcopy = [...dataRow.arrRows]
    let sStatus = arrRowcopy.filter(f => f.sID == item.row.sID)[0];
    if (sStatus) {
      sStatus.isActive = e.target.checked
    }

    let objData = {
      isActive: sStatus.isActive,
      sID: item.row.sID,
    }

    AxiosPost("GroupBPS/EditData_ToggleStatus", objData, (res) => {
      ResultAPI(res, "บันทึกเรียบร้อยแล้ว", () => { loadData(dataRow); });
    });
  }

  const onDelete = (e) => {
    dispatch(DialogActionCreators.OpenDialogSubmit("คุณต้องการลบข้อมูลหรือไม่ ?", () => { //Do you want to remove data ?
      dispatch(DialogActionCreators.CloseDialogSubmit() as any);
      dispatch(DialogActionCreators.LoadSubmit("Waiting...") as any);

      AxiosPost(`GroupBPS/EditData_RemoveDataTable`, { lstRemove: e }, (result) => {
        dispatch(DialogActionCreators.CloseDialogSubmit() as any);

        ResultAPI(result, "ลบสำเร็จ", () => { //Remove successfully
          dispatch(DialogActionCreators.OpenDialogSuccess("ลบสำเร็จ") as any) //Remove successfully
          loadData(dataRow);
        });
      }, (err) => {

        if (!err.response) {
          navigate("/error", null)
          return;
        }
      })
    }) as any)
  }

  const dataColumn: GridColumns = [
    {
      renderHeader: (item) => <BtnAddOnTable
        IsDisabled={nPermission !== 2}
        onClick={() => { navigate('/GroupForm_BPS') }}
        txt={"เพิ่ม"} //Add
      />,
      headerName: "ปุ่ม (เพิ่ม/แก้ไข))",
      field: "ปุ่ม (เพิ่ม/แก้ไข)",
      type: "actions",
      width: 100,
      resizable: false,
      sortable: false,
      getActions: (item) => {
        const html = <BtnEditOnTable
          IsDisabled={nPermission !== 2}
          txt={"แก้ไข"} //Edit
          onClick={() => goToEdit(item.row.sID, item.row)}
        />
        return [html]
      },
    },
    {
      field: "sGroupName",
      headerName: "ชื่อกลุ่มผู้ใช้งานระบบ", //Group Name
      headerAlign: "center",
      align: "left",
      resizable: false,
      flex: 3,
      minWidth: 500,
    },
    {
      field: "nDataNameCount",
      headerName: "ประเภทอุตสาหกรรม", //Group Name
      headerAlign: "center",
      align: "center",
      resizable: false,
      flex: 1,
      minWidth: 200,
    },
    {
      field: "nUserNameCount", //correct
      headerName: "ผู้ใช้งาน", //Group Name
      headerAlign: "center",
      align: "center",
      resizable: false,
      flex: 1,
      minWidth: 200,
    },
    {
      field: "sUpdateDate",
      headerName: "ปรับปรุงล่าสุด", //update date
      headerAlign: "center",
      align: "center",
      sortable: true,
      resizable: false,
      flex: 1,
      minWidth: 200,
    },
    {
      field: "isActive",
      headerName: "สถานะ", //Status
      headerAlign: "center",
      align: "left",
      resizable: false,
      sortable: true,
      flex: 2,
      minWidth: 200,
      renderCell: (item) => {
        let sStatust = dataRow.arrRows.filter(f => f.sID == item.row.sID)[0];
        return (
          <FormControlLabel
            control={<IOSSwitch disabled={nPermission !== 2} sx={{ m: 1 }} />}
            label={sStatust.isActive ? "ใช้งาน" : "ไม่ใช้งาน"}
            checked={sStatust.isActive}
            onChange={(e) => {
              onChangeStatus(e, item)
            }}
          />
        );
      },
    }
  ];

  return (
    <Table
      isLoading={loadingTable}
      filterField={filter}
      Rows={dataRow}
      Columns={dataColumn}
      OnLoadData={(p) => loadData(p)}
      isShowCheckBox={nPermission == 2}
      onDelete={onDelete}
      isRowSelectable={(p) => p.row.nUserNameCount == 0}
    />
  );
}