import OrderEmployer from "view/font/OrderEmployer/ShowListOrderEmployer";
import OrderEmployerForm from "view/font/OrderEmployer/OrderEmployerForm";
import Layout_Font from "layout/CommonLayout/Layout_Font";
import LoginEmployer from "view/font/Login/LoginEmployer";
import EmptyLayout from "layout/CommonLayout/EmptyLayout";


const Employer = [
  {
    path: "/OrderEmployer",
    component: OrderEmployer,
    layout: Layout_Font,
    exact: true,
    // propsData: {
    //   imgBGType: 5,
    //   isPaddingTop: true,
    // }
  },
  {
    path: "/LoginEmployerNewForm",
    component: LoginEmployer,
    layout: EmptyLayout,
    exact: true,
    // propsData: {
    //   imgBGType: 5,
    //   isPaddingTop: true,
    // }
  },
  {
    path: "/OrderEmployerForm",
    component: OrderEmployerForm,
    layout: Layout_Font,
    exact: true,
    // propsData: {
    //   imgBGType: 5,
    //   isPaddingTop: true,
    // }
  }
];

export default Employer;
