import React, { useEffect, useState } from 'react'
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import ReactDOM from "react-dom";
import "./index.css";
import { i18n, init as i18nInit } from "./i18n";
import { BrowserRouter } from "react-router-dom";

(async function () {
  // await i18nInit();
  // document.title = i18n("app.title");

  const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
  // const container = document.getElementById('root');
  // const root = ReactDOMClient.createRoot(container);
  // root.render(<BrowserRouter basename={baseUrl} ><App /></BrowserRouter>);
  ReactDOM.render(
    <BrowserRouter basename={baseUrl}>
      <App />
    </BrowserRouter>,
    document.getElementById("root")
  );
})();
