import React from 'react'
import { Tabs, Tab } from '@mui/material'
import { i18n } from 'i18n'

export default function FilterTabsSevice({ value, handleChange }) {
    const i18nField = "OrderEmployer";
    const arrDataTabs = [
        { label: i18n(`${i18nField}.ServiceJobPost`) },
        { label: i18n(`${i18nField}.ServiceCoin`) },
        { label: i18n(`${i18nField}.SeviceBanner`) },
    ]
    return (
        <>
            <Tabs
                value={value}
                onChange={handleChange}
                TabIndicatorProps={{ style: { display: "none" } }}
                sx={{
                    ".MuiTabs-flexContainer": {
                        flexWrap: "wrap",
                        gap: "10px"
                    }
                }}
            >
                {arrDataTabs.map((item, index) => (
                    <Tab
                        key={`tabs_${index}`}
                        label={
                            <span style={{ color: value === index ? "#f9fafd" : "#1c77d2" }}>
                                {item.label}
                            </span>
                        }
                        sx={{
                            height: "30px",
                            minHeight: "0px !important",
                            transition: "background-color 300ms linear",
                            backgroundColor: value === index ? "#1976d2" : "#fff",
                            borderRadius: "1em",
                        }}
                    />
                ))}
            </Tabs>

        </>
    )
}
