import { useEffect, useState } from "react";
import { DataGridMui as Table, initRows, PaginationInterface, FilterFieldInterface } from "components/Common/Table/DataGridMui";
import { GridColumns } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { BtnAddOnTable, BtnEditOnTable } from "components/Common/Button"
import { useDispatch } from "react-redux"
import { FormControlLabel } from "@mui/material";
import { AxiosPost, GetPermission, ResultAPI } from "service/CommonFunction/TS_function";
import IOSSwitch from "components/Common/ElementInForm/IosSwitch";
import { DialogActionCreators } from "store/redux/DialogAlert";
import { useForm } from "react-hook-form";
import { i18n } from "i18n";
import secureLocalStorage from 'react-secure-storage';


export default function AdminUserMasterTable() {

  const i18nField = 'entities.AdminUser';
  const i18nCommon = 'common';
  const i18nDialog = 'dialog';
  const dispatch = useDispatch();
  const history = useNavigate();
  const navigate = useNavigate();
  const [nPermission, setPermission] = useState(0);
  const [loadingTable, setLoadingTable] = useState(true);



  let arrrow = {
    ...initRows,
    sSortExpression: "dUpdate_Date",
    sSortDirection: "desc"
  }
  const sPathName = window.location.pathname;
  const sStore = secureLocalStorage.getItem(sPathName);
  if (sStore && sStore !== "") {
    const objRowTemp = JSON.parse(sStore + "");
    if (objRowTemp != null) {
      arrrow = objRowTemp;
      arrrow.arrRows = [];
    }
  }
  const [dataRow, setDataRow] = useState<PaginationInterface>(arrrow);
  // const [dataRow, setDataRow] = useState<PaginationInterface>({
  //   ...initRows,
  //   sSortExpression: "dUpdate_Date",
  //   sSortDirection: "desc"
  // });



  const onBack = () => {
    history(-1)
  }

  const form = useForm({
    shouldUnregister: false,
    shouldFocusError: true,
    mode: "all",
  });

  useEffect(() => {
    loadData(dataRow);
    GetPermission(10, setPermission);
  }, []);

  const loadData = (p: PaginationInterface) => {
    setLoadingTable(true)
    AxiosPost("UserPermission/SearchData_FormUser", p, (result) => {
      setLoadingTable(false)

      setDataRow({
        ...p,
        arrRows: result.lstUser || [],
        nDataLength: result.nDataLength,
        nPageIndex: result.nPageIndex,
      });
    });
  };

  const OnDelete = (e) => {

    dispatch(DialogActionCreators.OpenDialogSubmit("คุณต้องการลบข้อมูลหรือไม่ ?", () => {
      dispatch(DialogActionCreators.CloseDialogSubmit() as any);
      dispatch(DialogActionCreators.LoadSubmit("Waiting...") as any);

      AxiosPost(`UserPermission/RemoveDataTable`, e, (result) => {
        dispatch(DialogActionCreators.CloseDialogSubmit() as any);

        ResultAPI(result, "ลบสำเร็จ", () => {
          dispatch(DialogActionCreators.OpenDialogSuccess("ลบสำเร็จ") as any)
          loadData(dataRow);
        });
      }, (err) => {

        if (!err.response) {
          history("/error", null)
          return;
        }
      })
    }) as any)
  }

  // filter
  const filter: FilterFieldInterface[] = [

    { sTypeFilterMode: "input", sFieldName: "sUser_Name" },
    { sTypeFilterMode: "input", sFieldName: "sGroup_Name" },
    { sTypeFilterMode: "input", sFieldName: "sUser_Email" },
    { sTypeFilterMode: "input", sFieldName: "sUser_Tel" },
    { sTypeFilterMode: "input", sFieldName: "sUpdate_By" },
    { sTypeFilterMode: "daterange", sFieldName: "sUpdate_Date" },
    {
      sTypeFilterMode: "select", sFieldName: "isActive", lstDataSelect: [
        { value: "1", keyId: 0, label: "ใช้งาน" },
        { value: "0", keyId: 1, label: "ไม่ใช้งาน" },
      ],
    },
  ]

  //status 
  const OnStatus = (e, item) => {
    let arrRowcopy = [...dataRow.arrRows]
    let sStatust = arrRowcopy.filter(f => f.nUser_ID == item.row.nUser_ID)[0];
    if (sStatust) {
      sStatust.isActive = e.target.checked
    }

    let oData = {
      isActive: sStatust.isActive,
      nUser_ID: item.row.nUser_ID
    }

    AxiosPost("UserPermission/ToggleActive", oData, (result) => {
      ResultAPI(result, "บันทึกข้อมูลสำเร็จ", () => {
        loadData(dataRow);
      });
    }, (err) => {
      if (!err.response) {
        history("/error", { replace: true })
        return;
      }
    });
  }

  //สร้างตาราง
  const dataColumn: GridColumns = [
    {
      renderHeader: (item) => <BtnAddOnTable
        IsDisabled={nPermission !== 2}
        onClick={() => { navigate('/AdminUser') }}
        txt={'เพิ่ม'}
      />,

      headerName: "",
      field: "ปุ่ม (เพิ่ม/แก้ไข)",
      type: "actions",
      width: 60,
      resizable: false,
      sortable: false,
      getActions: (item) => {
        return [
          (
            <BtnEditOnTable
              IsDisabled={false}//nPermission !== 2
              txt={'แก้ไข'}
              onClick={() => goToEdit(item.row.sID, item.row)}
            />
          )
        ];
      },
    },
    {
      field: "nNo",
      headerName: "ลำดับ",
      headerAlign: "center",
      align: "center",
      resizable: false,
      width: 100,
    },
    {
      field: "sUser_Name",
      headerName: "ชื่อ-นามสกุล",
      headerAlign: "center",
      align: "left",
      resizable: false,
      flex: 3,
      minWidth: 400,
    },
    {
      field: "sGroup_Name",
      headerName: "ชื่อกลุ่มผู้ใช้งานระบบ",
      headerAlign: "center",
      align: "left",
      resizable: false,
      flex: 3,
      minWidth: 400,
    },
    {
      field: "sUser_Email",
      headerName: "อีเมล",
      headerAlign: "center",
      align: "left",
      resizable: false,
      flex: 2,
      minWidth: 300,
    },
    {
      field: "sUser_Tel",
      headerName: "เบอร์โทรศัพท์",
      headerAlign: "center",
      align: "center",
      resizable: false,
      flex: 1,
      minWidth: 180,
    },
    {
      field: "sUpdate_By",
      headerName: "แก้ไขโดย",
      headerAlign: "center",
      align: "center",
      resizable: false,
      flex: 1,
      minWidth: 180,
    },
    {
      field: "sUpdate_Date",
      headerName: "วันที่แก้ไข",
      headerAlign: "center",
      align: "center",
      resizable: false,
      flex: 1,
      minWidth: 180,
    },
    {
      field: "isActive",
      headerName: "สถานะ",
      headerAlign: "center",
      align: "left",
      resizable: false,
      flex: 1,
      minWidth: 180,
      renderCell: (item) => {
        let sStatust = dataRow.arrRows.filter(f => f.nUser_ID == item.row.nUser_ID)[0];
        if (sStatust) {

        }
        return (
          <>
            <FormControlLabel
              control={<IOSSwitch disabled={nPermission !== 2} sx={{ m: 1 }} />}
              label={sStatust.isActive ? "ใช้งาน" : "ไม่ใช้งาน"}
              checked={sStatust.isActive}
              onChange={
                (e) => { OnStatus(e, item) }
              }
            />
          </>
        );
      },
    },
  ];

  const goToEdit = (sID: string, item: any) => {
    // window.localStorage.setItem("sSubRouteName", item.sSection);
    history(`/AdminUser?sID=${encodeURI(sID)}`);
  };

  return (
    <Table
      isLoading={loadingTable}
      filterField={filter}
      Rows={dataRow}
      Columns={dataColumn}
      OnLoadData={(p) => loadData(p)}
      isShowCheckBox={nPermission == 2}
      onDelete={OnDelete}
    />

  );
}