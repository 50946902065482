import { Stack } from "@mui/material";
import AdminFreePackageDetailCom from "../../../components/AdminWorkflow/AdminFreePackage/AdminFreePackageDetail";


export default function AdminFreePackageDetail() {
 
  return (
    <Stack sx={{ mx: "1%" }}>
        <AdminFreePackageDetailCom nType_ID={0}/>
    </Stack>
  );
}