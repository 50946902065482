import { Grid, } from '@mui/material';
import ShowTotalPackage from '../Show/ShowTotalPackage';
import FromCreateCoinPackage from './FormCreateCoinPackage';
import { i18n } from 'i18n';

const CreateCoinPackage = () => {
    const i18nField = 'entities.Package';
    return (
        <Grid className="CreateCoinPackage">
            <Grid container xs={12}>
                <Grid item sx={{ mx: "2%", width: "100%" }}>
                    <ShowTotalPackage />
                </Grid>
                <Grid item sx={{ mx: "2%" ,marginTop: "2%"}}>
                    <h3>แพ็กเกจ COIN</h3>
                </Grid>
            </Grid>
            <Grid item sx={{ mx: "2%" ,marginTop: "2%"}}>
                <FromCreateCoinPackage />
            </Grid>
        </Grid>
    );
}
export default CreateCoinPackage