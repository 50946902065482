import { Box } from '@mui/material';
import ProfileProvider from 'components/SeekerProfile/Context/ProfileContext';
import React from 'react';
import MyProfile from 'components/SeekerProfile/MyProfile';
import { Helmet } from 'react-helmet';
import { i18n } from "i18n";

export default function BoxContentProfile() {
  const i18nField = "entities.MyProfile";


  return (
    <>
      <Box style={{ display: "none !important", visibility: "hidden" }}>
        <Helmet>
          <title> {i18n(`${i18nField}.Title`)} </title>
        </Helmet>
      </Box>
      <ProfileProvider>
        <Box sx={{ width: '100%' }}>
          <MyProfile isJobApply={false} />
        </Box>
      </ProfileProvider>
    </>

  )
}