import { Box, Grid, Stack } from "@mui/material";
import { BtnRadius } from "components/Common/Button";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import TablePausePackage from "./TablePausePackage";
import { Helmet } from 'react-helmet';


export default function FromMasterTable() {
  const navigate = useNavigate();
  const [nPermission, setPermission] = useState(0);
  const onCreate = () => {
    navigate("/PausePackageAdd");
  }
  return (
    <>
      <Box sx={{ display: "none !important", visibility: "hidden" }}>
        <Helmet>
          <title> ระงับการใช้งาน (ค้างชำระ) </title>
        </Helmet>
      </Box>


      <Stack sx={{ mx: "1%" }}>
        {nPermission !== 2 ? (
          <Grid item md={2} xs={6} >
            <BtnRadius
              onClick={() => onCreate()} txt={'เพิ่ม'} />
          </Grid>
        ) : null}
        <Grid item >
          <TablePausePackage />
        </Grid>
      </Stack>
    </>
  );
}