const MyPageEmployerEN = {
  card: {
    Title: "Home",
    Order: "Orders",
    OrderAll: "All",
    OrderActive: "Active",
    OrderPending: "Pending",
    Candidate: "Candidates",
    CandidateAll: "All",
    CandidateUsed: "Used",
    CandidateInvitation: "Invitation",
    JobTitle: "Job Titles",
    JobTitleAnnounced: "Announced",
    // JobTitleQuota: "Announced Quota",
    JobTitleQuota: "Quota",
    JobTitleView: "Total View",
  },
  dashboard: {
    unread: "unread",
    JobPost: "Job Posts",
    JobPostAll: "All",
    JobPostTable: "Job Post",
    JobPostExpiredTable: "Expired",
    JobPostJobSeekerTable: "Job Seeker",
    JobPostNodataTable: "No Data",
    AccountStatus: "Account Status",
    AccountStatusAll: "All",
    AccountStatusPackageTable: "Package",
    AccountStatusExpiredTable: "Expired",
    AccountStatusJobTable: "Job",
    AccountStatusCoinsTable: "Coins",
    AccountStatusTotalTable: "Total",
    AccountStatusNodataTable: "No Data",
    Banner: "Banner",
    BannerTable: "Banner",
    BannerExpiredTable: "Expired",
    BannerLinkTable: "Link",
    BannerNodataTable: "No Data",
    TitleAccountManager: "Need advice on packages?",
    AccountManager: "Account Manager",
    AccountManagerTelTable: "Tel",
    AccountManagerEmailTable: "E-mail",
    AccountManagerNodataTable: "No Data",
    Dashboard: "DASHBOARD",
  },
};

const MyPageEmployerTH = {
  card: {
    Title: "หน้าแรก",
    Order: "คำสั่งซื้อ",
    OrderAll: "ทั้งหมด",
    OrderActive: " กำลังใช้งาน",
    OrderPending: "รอดำเนินการ",
    Candidate: "ผู้สมัคร",
    CandidateAll: "ทั้งหมด",
    CandidateUsed: "สามารถใช้ได้",
    CandidateInvitation: "ส่งคำเชิญ",
    JobTitle: "ตำแหน่งงาน",
    JobTitleAnnounced: "ประกาศแล้ว",
    JobTitleQuota: "  โควต้าประกาศ",
    JobTitleView: "  โควต้าดูผู้สมัคร",
  },
  dashboard: {
    unread: "ยังไม่ได้อ่าน",
    JobPost: "ตำแหน่งงาน",
    JobPostAll: "ทั้งหมด",
    JobPostTable: "ตำแหน่งงาน",
    JobPostExpiredTable: "วันหมดอายุ",
    JobPostJobSeekerTable: "ผู้สมัครงาน",
    JobPostNodataTable: "ไม่พบข้อมูล",
    AccountStatus: "สถานะบัญชี",
    AccountStatusAll: "ทั้งหมด",
    AccountStatusPackageTable: "แพ็กเกจ",
    AccountStatusExpiredTable: "วันหมดอายุ",
    AccountStatusJobTable: "จำนวนงาน",
    AccountStatusCoinsTable: "จำนวนเหรียญ",
    AccountStatusTotalTable: "ทั้งหมด",
    AccountStatusNodataTable: "ไม่พบข้อมูล",
    Banner: "แบนเนอร์",
    BannerTable: "แบนเนอร์",
    BannerExpiredTable: "วันหมดอายุ",
    BannerLinkTable: "ลิงค์",
    BannerNodataTable: "ไม่พบข้อมูล",
    TitleAccountManager: "ต้องการคำแนะนำเกี่ยวกับแพ็กเกจหรือไม่?",
    AccountManager: "การจัดการบัญชี",
    AccountManagerTelTable: "โทร",
    AccountManagerEmailTable: "อีเมล",
    AccountManagerNodataTable: "ไม่พบข้อมูล",
    Dashboard: "DASHBOARD",
  },
};

export { MyPageEmployerTH, MyPageEmployerEN };
