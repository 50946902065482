import MyJobInterested from "components/MyJob/MyJobInterested";
import { Helmet } from 'react-helmet';
import { i18n } from "i18n";
import { Box } from "@mui/material";

const MyJobInterest = () => {
  const i18nField = "TitlePage";

  return (
    <>
      <Box sx={{ display: "none !important", visibility: "hidden" }}>
        <Helmet>
          <title> {i18n(`${i18nField}.MyJobInterested`)} </title>
        </Helmet>
      </Box>


      <MyJobInterested />
    </>
  );
};

export default MyJobInterest;
