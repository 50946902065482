import React, { useEffect, useState, useRef } from 'react';
import {
  Stack, Select, MenuItem, Box, FormControl, RadioGroup, FormControlLabel, Radio, Typography, TextField, Grid, InputLabel, Hidden
} from "@mui/material";
import Input from "components/Common/ElementInForm/InputCustomLabelFormItem"
import { BtnCancel } from "components/Common/Button";
import Floala from "components/Common/ElementInForm/FroalaContentOnly";
import { cFile } from 'service/CommonInterface/CommonInterface';
import { Extension } from "service/CommonFunction/TS_function";
import UploadFile from "components/Common/ElementInForm/UploadFile/UploadFile";
import yupFormSchemas from 'components/Common/yup/yupFormSchemas';
import { useDispatch } from "react-redux"
import { FormProvider, useFormContext } from "react-hook-form";
import { useLocation } from "react-router-dom"
import { DialogActionCreators } from 'store/redux/DialogAlert';
import { i18n } from "i18n";

export default function BoxContent(props) {
  const dispatch = useDispatch();
  const form = useFormContext();
  const location = useLocation();
  const [IsAddPic, setIsAddpic] = useState(true);
  const [IsEmployerSetting, setEmployerSetting] = useState(true);
  const { nOrder, lstOrder, objStateContent,
    setObjStateContent, objSchema, lstComponent,
    setLstComponent, objData, setLstOrder, setSchema, disabled,
    isAddAction, setIsAddToDefault, setIsChangeOrder, isChangeOrder, isAddPic, isEmployerSetting, keyID_content } = props;
  const [arrFile, setArrFile] = useState<cFile[]>(
    objStateContent[`nTypeContent_${objData.id}`] == 2 && objStateContent[`fFileImg_${objData.id}`]
      ? objStateContent[`fFileImg_${objData.id}`] :
      objData.nType == 2 && objData.fFileImageOrVideo.length > 0 ? objData.fFileImageOrVideo :
        []
  );
  const [arrFileVideo, setArrFileVideo] = useState<cFile[]>(
    objStateContent[`nTypeContent_${objData.id}`] == 3 && objStateContent[`fFileVideo_${objData.id}`]
      ? objStateContent[`fFileVideo_${objData.id}`] :
      objData.nType == 3 && objData.fFileImageOrVideo.length > 0 ? objData.fFileImageOrVideo :
        []
  );
  const [sContent, setContent] = useState<string>(
    objStateContent[`nTypeContent_${objData.id}`] == 1 && objStateContent[`sContentOnly_${objData.id}`]
      ? objStateContent[`sContentOnly_${objData.id}`] :
      objData.nType == 1 && objData.sContent ? objData.sContent : ""
  );
  const [sValueType, setValueType] = useState<number>(
    objStateContent[`nTypeContent_${objData.id}`] ? objStateContent[`nTypeContent_${objData.id}`] : objData.nType
  );

  useEffect(() => {
    if (isAddAction) {
      setIsAddToDefault();
    } else {
      if (objStateContent[`nTypeContent_${objData.id}`] && isChangeOrder) {
        setValueType(objStateContent[`nTypeContent_${objData.id}`])
        setIsChangeOrder(false)
      }
    }
    setIsAddpic(isAddPic);
    setEmployerSetting(isEmployerSetting)
  }, [lstComponent])

  const SetData = () => {
    setValueType(objStateContent[`nTypeContent_${objData.id}`])
    setContent(objStateContent[`sContentOnly_${objData.id}`] ? objStateContent[`sContentOnly_${objData.id}`] : "")
    setArrFile(objStateContent[`fFileImg_${objData.id}`] ? objStateContent[`fFileImg_${objData.id}`] : [])
    setArrFileVideo(objStateContent[`fFileVideo_${objData.id}`] ? objStateContent[`fFileVideo_${objData.id}`] : [])
    objSchema[`sTextUrl_${objData.id}`] = yupFormSchemas.string("Url", { required: objStateContent[`nTypeContent_${objData.id}`] == 4 });
    form.setValue(`sTextBottomImage_${objData.id}`, objStateContent[`sContentBottomImg_${objData.id}`])
    form.setValue(`sTextBottomVideo_${objData.id}`, objStateContent[`sContentBottomVideo_${objData.id}`])
    form.setValue(`sTextUrl_${objData.id}`, objStateContent[`sUrl_${objData.id}`])
    form.setValue(`sTextBottomUrl_${objData.id}`, objStateContent[`sContentBottomUrl_${objData.id}`])
  }
  useEffect(() => {
    if (objStateContent[`nTypeContent_${objData.id}`] && isChangeOrder) {
      SetData();
      setIsChangeOrder(false)
    }
  }, [isChangeOrder])

  const OnChangeOrder = (nNewOrderIndex, oldOrderIndex) => {
    moveArrayItemToNewIndex(lstComponent, oldOrderIndex, nNewOrderIndex);
    setLstComponent([...lstComponent]);
    setIsChangeOrder(true)
  }

  const removeSchema = () => {
    delete objSchema[`sTextBottomImage_${objData.id}`];
    delete objSchema[`sTextBottomVideo_${objData.id}`];
    delete objSchema[`sTextUrl_${objData.id}`];
    delete objSchema[`sTextBottomUrl_${objData.id}`];
    form.unregister(`sTextBottomImage_${objData.id}`)
    form.unregister(`sTextBottomVideo_${objData.id}`)
    form.unregister(`sTextUrl_${objData.id}`)
    form.unregister(`sTextBottomUrl_${objData.id}`)
    delete objStateContent[`nTypeContent_${objData.id}`]
    delete objStateContent[`fFileImg_${objData.id}`]
    delete objStateContent[`fFileVideo_${objData.id}`]
    delete objStateContent[`sContentOnly_${objData.id}`]
    delete objStateContent[`sUrl_${objData.id}`]
    delete objStateContent[`sContentBottomImg_${objData.id}`]
    delete objStateContent[`sContentBottomVideo_${objData.id}`]
    delete objStateContent[`sContentBottomUrl_${objData.id}`]
    setObjStateContent({ ...objStateContent })
    let tmpLst = lstComponent.filter(f => f.id != objData.id)
    setLstComponent(tmpLst);
    setIsChangeOrder(true)
    lstOrder.pop()
    setLstOrder([...lstOrder]);
  }

  const moveArrayItemToNewIndex = (arr, old_index, new_index) => {
    if (new_index >= arr.length) {
      var k = new_index - arr.length + 1;
      while (k--) {
        arr.push(undefined);
      }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr;
  };

  const OnDelete = () => {
    if (lstOrder.length == 1) {
      dispatch(DialogActionCreators.OpenDialogWarning("Please specify content.") as any)
      return;
    }
    switch (sValueType) {
      case 1:
        if (sContent != "") {
          dispatch(DialogActionCreators.OpenDialogSubmit(i18n(`common.AlertConfrimDel`), () => {
            removeSchema();
            dispatch(DialogActionCreators.CloseDialogSubmit() as any);
          }) as any)
        } else {
          removeSchema();
        }
        break;
      case 2:
        if (arrFile.length > 0 || form.getValues(`sTextBottomImage_${nOrder}`)) {
          dispatch(DialogActionCreators.OpenDialogSubmit(i18n(`common.AlertConfrimDel`), () => {
            removeSchema();
            dispatch(DialogActionCreators.CloseDialogSubmit() as any);
          }) as any)
        } else {
          removeSchema();
        }
        break;
      case 3:
        if (arrFile.length > 0 || form.getValues(`sFileVideo${nOrder}`)) {
          dispatch(DialogActionCreators.OpenDialogSubmit(i18n(`common.AlertConfrimDel`), () => {
            removeSchema();
            dispatch(DialogActionCreators.CloseDialogSubmit() as any);
          }) as any)
        } else {
          removeSchema();
        }
        break;
      case 4:
        if (form.getValues(`sTextUrl_${nOrder}`) || form.getValues(`sTextBottomUrl_${nOrder}`)) {
          dispatch(DialogActionCreators.OpenDialogSubmit(i18n(`common.AlertConfrimDel`), () => {
            removeSchema();
            dispatch(DialogActionCreators.CloseDialogSubmit() as any);
          }) as any)
        } else {
          removeSchema();
        }
        break;
    }
  }

  useEffect(() => {
    if (sValueType === 2) {
      objStateContent[`fFileImg_${objData.id}`] = arrFile
      setObjStateContent({ ...objStateContent });
    } else if (sValueType === 3) {
      objStateContent[`fFileVideo_${objData.id}`] = arrFileVideo
      setObjStateContent({ ...objStateContent });
    }
  }, [arrFile, arrFileVideo])

  useEffect(() => {
    objSchema[`sTextUrl_${objData.id}`] = yupFormSchemas.string("Url", { required: sValueType == 4 });
    objStateContent[`nTypeContent_${objData.id}`] = sValueType
    setObjStateContent({ ...objStateContent });
  }, [sValueType])

  useEffect(() => {
    if (sValueType == 1) {
      objStateContent[`sContentOnly_${objData.id}`] = sContent
      setObjStateContent({ ...objStateContent });
    }
  }, [sContent])

  return (
    <FormProvider {...form}>
      <Stack sx={{ border: "1px" }}>
        <Grid container>
          <Grid item md={11} xs={6}>
            <FormControl>
              <RadioGroup
                row
                name={"Radio"}
                value={sValueType}
                onChange={(e) => setValueType(parseInt(e.target.value))}
              >
                {IsEmployerSetting ? (
                  <>
                    <FormControlLabel value={1} control={<Radio disabled={disabled} />} label="ข้อความ" />
                    <FormControlLabel value={2} control={<Radio disabled={disabled} />} label="รูปภาพ" />
                  </>
                ) : (
                  <>
                    <FormControlLabel value={1} control={<Radio disabled={disabled} />} label="ข้อความ" />
                    <FormControlLabel value={2} control={<Radio disabled={disabled} />} label="รูปภาพ" />
                    <FormControlLabel value={3} control={<Radio disabled={disabled} />} label="วิดีโอ" />
                    <FormControlLabel value={4} control={<Radio disabled={disabled} />} label="Youtube" />
                  </>
                )}
              </RadioGroup>
            </FormControl>
          </Grid>
          <Stack spacing={1} direction="row" alignItems="center">
            <Select
              label=""
              autoWidth
              sx={{
                height: "30px", backgroundColor: "white", ".MuiOutlinedInput-notchedOutline > legend": {
                  width: 0
                }
              }}
              value={nOrder - 1}
              onChange={(e) => OnChangeOrder(e.target.value, nOrder - 1)}
              disabled={disabled}
            >
              {lstOrder.map((item, index) => <MenuItem key={`item_${nOrder}_${index}`} value={index}  >{item}</MenuItem>)}
            </Select>
            <Grid item md={1} xs={12}>
              <BtnCancel onClick={() => OnDelete()} IsDisabled={disabled} isCircleWithOutText />
            </Grid>
          </Stack>
        </Grid>

        <Stack>
          <Hidden smUp>
            <Grid item md={12} xs={12} sx={{ width: "50vw" }}>
              {
                sValueType == 1 ?
                  <Floala
                    menuId={location.pathname.includes("AdminContent") ? 12 : location.pathname.includes("JobPostModify") ? 1 : 12}
                    isAlwaysEnabled={(location.pathname.includes("JobPostModify") || location.pathname.includes("SetupTemplate")) ? true : false}
                    valueContent={sContent}
                    SetValueContent={setContent}
                    disabled={disabled}
                    maxHeight={100}
                    charCounterMax={1000}
                  /> : null
              }
            </Grid>
          </Hidden>

          <Hidden smDown>
            <Grid item md={12} xs={12} sx={{ width: "68vw" }}>
              {
                sValueType == 1 ?
                  <Floala
                    menuId={location.pathname.includes("AdminContent") ? 12 : location.pathname.includes("JobPostModify") ? 1 : 12}
                    isAlwaysEnabled={(location.pathname.includes("JobPostModify") || location.pathname.includes("SetupTemplate")) ? true : false}
                    valueContent={sContent}
                    SetValueContent={setContent}
                    disabled={disabled}
                    maxHeight={100}
                    charCounterMax={1000}
                  /> : null
              }
            </Grid>
          </Hidden>
          <Grid item md={12} xs={12}>
            {
              sValueType == 2 ?
                <Stack spacing={2}>
                  <UploadFile
                    keyID={keyID_content != null ? keyID_content : objData.id}
                    sTitle={""}
                    sSubTitle={""}
                    IsRequired={false}
                    arrFile={arrFile}
                    setarrFile={setArrFile}
                    IsFolder={false}
                    IsFile={true}
                    IsMultiple={false}
                    isFileChange={true}
                    Extension={Extension.Image}
                    IsDrag={false}
                    disabled={disabled}
                    isSetupEmployerBanner={false}
                    isSetupEmployerLogo={false}
                  />
                  <Input
                    name={`sTextBottomImage_${objData.id}`}
                    maxLength={250}
                    rows={2}
                    multiline
                    label={<>
                      <Typography sx={{ fontWeight: 600 }} component="label">ข้อความใต้ภาพ</Typography>
                    </>}
                    onChange={(e) => {
                      objStateContent[`sContentBottomImg_${objData.id}`] = e;
                      setObjStateContent({ ...objStateContent });
                    }}
                    disabled={disabled}
                  />
                </Stack>
                : null
            }
            {
              sValueType == 3 ?
                <Stack spacing={2}>
                  <UploadFile
                    keyID={objData.id}
                    nLimitFile={50}
                    sTitle={""}
                    sSubTitle={""}
                    IsRequired={false}
                    arrFile={arrFileVideo}
                    setarrFile={setArrFileVideo}
                    IsFolder={false}
                    isFileChange={true}
                    IsFile={true}
                    IsMultiple={false}
                    Extension={Extension.Video}
                    IsDrag={false}
                    disabled={disabled}
                    isSetupEmployerBanner={false}
                    isSetupEmployerLogo={false}
                  />
                  <Input
                    name={`sTextBottomVideo_${objData.id}`}
                    maxLength={250}
                    rows={2}
                    multiline
                    label={<>
                      <Typography sx={{ fontWeight: 600, paddingTop: "2%" }} component="label">ข้อความใต้วิดีโอ</Typography>
                    </>}
                    disabled={disabled}
                    onChange={(e) => {
                      objStateContent[`sContentBottomVideo_${objData.id}`] = e;
                      setObjStateContent({ ...objStateContent });
                    }}
                  />
                </Stack>
                : null
            }
          </Grid>
          <Grid item md={12} xs={12}>
            {
              sValueType == 4 ?
                <Stack spacing={2}>
                  <Input
                    name={`sTextUrl_${objData.id}`}
                    labelString={""}
                    maxLength={150}
                    label={<>
                      <Typography sx={{ fontWeight: 600 }} component="label">Url</Typography>
                    </>}
                    disabled={disabled}
                    onChange={(e) => {
                      objStateContent[`sUrl${objData.id}`] = e;
                      setObjStateContent({ ...objStateContent });
                    }}
                  />
                  <Input
                    name={`sTextBottomUrl_${objData.id}`}
                    maxLength={250}
                    rows={2}
                    multiline
                    label={<>
                      <Typography sx={{ fontWeight: 600 }} component="label">ข้อความใต้วิดีโอ</Typography>
                    </>}
                    onChange={(e) => {
                      objStateContent[`sContentBottomUrl_${objData.id}`] = e;
                      setObjStateContent({ ...objStateContent });
                    }}
                    disabled={disabled}
                  />
                </Stack>
                : null
            }
          </Grid>
        </Stack>
      </Stack>
    </FormProvider >
  );
}