const MyJobApplyTH = {
  MyJobApply: {
    Page: "งานที่สมัคร",
    SendDate: "สมัครเมื่อวันที่",
    LoadMore: "ดูเพิ่มเติม",
    InterestedDate: "สนใจเมื่อวันที่",
  },
};
const MyJobApplyEN = {
  MyJobApply: {
    Page: "My Job Apply",
    LoadMore: "Load more",
    SendDate: "Applied",
    InterestedDate: "Interrested",
  },
};

export { MyJobApplyTH, MyJobApplyEN };
