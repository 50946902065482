import { useState, useEffect, Fragment } from 'react';
import { DataGridMui as Table, FilterFieldInterface, initRows, PaginationInterface } from "components/Common/Table/DataGridMui";
import { GridColumns } from "@mui/x-data-grid";
import { AxiosPost, GetPermission, ResultAPI } from "service/CommonFunction/TS_function";
import { useNavigate } from "react-router-dom";
import { BtnAddOnTable, BtnEditOnTable } from "components/Common/Button"
import { useDispatch } from 'react-redux';
import { DialogActionCreators } from 'store/redux/DialogAlert';
import { i18n } from 'i18n';
import { Box, Tooltip, Typography } from '@mui/material';
import secureLocalStorage from 'react-secure-storage';


const FormTablelCoinPackage = () => {
  const i18nField = 'entities.Package';
  const i18nDialog = 'dialog';
  const dispatch = useDispatch();
  const history = useNavigate();
  const [nPermission, setPermission] = useState(0);
  const [loadingTable, setLoadingTable] = useState(true);
  const [isDataActive, setIsActive] = useState(true);
  const [isDelete, setIsDelete] = useState(false);

  let arrrow = {
    ...initRows,
    sSortExpression: "sEndDate",
    sSortDirection: "asc"
  }
  const sPathName = window.location.pathname;
  const sStore = secureLocalStorage.getItem(sPathName + "2");
  if (sStore && sStore !== "") {
    const objRowTemp = JSON.parse(sStore + "");
    if (objRowTemp != null) {
      arrrow = objRowTemp;
      arrrow.arrRows = [];
    }
  }
  const [dataRow, setDataRow] = useState<PaginationInterface>(arrrow);
  // const [dataRow, setDataRow] = useState<PaginationInterface>({
  //   ...initRows,
  //   sSortExpression: "sEndDate",
  //   sSortDirection: "asc"
  // });


  const filter: FilterFieldInterface[] = [
    { sTypeFilterMode: "input", sFieldName: "sPackageName" },
    { sTypeFilterMode: "input", sFieldName: "nCoinAmoun" },
    { sTypeFilterMode: "input", sFieldName: "nCoinFree" },
    { sTypeFilterMode: "input", sFieldName: "nTotalCoin" },
    { sTypeFilterMode: "input", sFieldName: "nExDurationAmoun" },
    { sTypeFilterMode: "input", sFieldName: "sPriceAfterVat" },
    { sTypeFilterMode: "daterange", sFieldName: "sLastUpDate" },
    { sTypeFilterMode: "daterange", sFieldName: "sStartDate" },
  ]


  useEffect(() => {
    GetPermission(5, setPermission);
    loadData(dataRow);
  }, []);
  const loadData = (p: PaginationInterface) => {
    setLoadingTable(true)
    AxiosPost("AdminUserPackage/SearchData_TableDataPackage", { ...p, nTypeTable: 2 }, (result) => {
      ////console.log("SearchData_TableDataPackage", result);
      setLoadingTable(false)
      setIsDelete(result.lstMaster.isOrder)
      setDataRow({
        ...p,
        arrRows: result.lstMaster,
        nDataLength: result.nDataLength,
        nPageIndex: result.nPageIndex,
      });
    })
  }

  const OnDelete = (e) => {
    dispatch(DialogActionCreators.OpenDialogSubmit(i18n(`common.AlertConfrimDel`), () => {
      dispatch(DialogActionCreators.CloseDialogSubmit() as any);
      dispatch(DialogActionCreators.LoadSubmit("Waiting...") as any);

      AxiosPost(`AdminUserPackage/RemoveDataTable`, { lstRemoveData: e, nTypeRemove: 2 }, (result) => {
        dispatch(DialogActionCreators.CloseDialogSubmit() as any);
        ResultAPI(result, i18n(`${i18nDialog}.DialogRemoveSuccess`), () => {
          dispatch(DialogActionCreators.OpenDialogSuccess(i18n(`${i18nDialog}.DialogRemoveSuccess`)) as any)
          loadData(dataRow);
        });
      })
    }) as any)
  }

  const goToEdit = (sID: string, item: any) => {
    // window.localStorage.setItem("sSubRouteName", item.sSection);
    history(`/CreateCoinPackage?sID=${encodeURI(sID)}`);
  };
  const dataColumn: GridColumns = [
    {
      headerName: "",
      field: "ปุ่ม (เพิ่ม/แก้ไข)",
      type: "actions",
      width: 100,
      resizable: false,
      sortable: false,
      getActions: (item) => {
        return [<BtnEditOnTable
          IsDisabled={nPermission !== 2}
          txt={'แก้ไข'}
          onClick={() => goToEdit(item.row.sID, item.row)} />
        ];
      },
    },
    {
      field: "sPackageName",
      headerName: "ชื่อแพ็กเกจ",
      headerAlign: "center",
      align: "left",
      resizable: false,
      flex: 3,
      minWidth: 300,
      renderCell: (item) => {
        const html = <Fragment>
          {item.row.isPromotion ?
            <Typography style={{ color: "red" }}>[Promotion]</Typography>
            : ""
          }
          &nbsp;
          <Box sx={{ overflow: 'hidden' }}>
            <Tooltip title={item.row.sPackageName}>
              <Box>{item.row.sPackageName}</Box>
            </Tooltip>
          </Box>
        </Fragment>
        return (html)
      }
    },
    {
      field: "nCoinAmoun",
      headerName: "Coin (ตามแพ็กเกจ)",
      headerAlign: "center",
      align: "center",
      resizable: false,
      flex: 1,
      minWidth: 150,
    },
    {
      field: "nCoinFree",
      headerName: "Coin (Free)",
      headerAlign: "center",
      align: "center",
      resizable: false,
      flex: 1,
      minWidth: 150,
    },
    {
      field: "nTotalCoin",
      headerName: "จำนวน Coin",
      headerAlign: "center",
      align: "center",
      resizable: false,
      flex: 1,
      minWidth: 150,
    },
    {
      field: "nExDurationAmoun",
      headerName: "ระยะเวลา (เดือน)",
      headerAlign: "center",
      align: "center",
      resizable: false,
      flex: 1,
      minWidth: 160,
    },
    {
      field: "sPriceAfterVat",
      headerName: "รวมเป็นเงิน",
      headerAlign: "center",
      align: "right",
      resizable: false,
      flex: 1,
      minWidth: 150,
    },
    {
      field: "sLastUpDate",
      headerName: "แก้ไขล่าสุด",
      headerAlign: "center",
      align: "center",
      resizable: false,
      width: 150,
    },
    {
      field: "sStartDate",
      headerName: "วันที่เริ่มต้น",
      headerAlign: "center",
      align: "center",
      resizable: false,
      flex: 1,
      minWidth: 150,
    },

  ];

  return (
    <Table
      id="2"
      isLoading={loadingTable}
      filterField={filter}
      Rows={dataRow}
      Columns={dataColumn}
      OnLoadData={(p) => loadData(p)}
      isShowCheckBox={nPermission == 2}
      onDelete={OnDelete}
      isRowSelectable={(p) => !p.row.isOrder}

    />
  );
}

export default FormTablelCoinPackage
