import searchImg from "../../../assets/images/search-job.png";
import { Grid } from "@mui/material";
import { i18n } from "i18n";

const i18nForthisPage = "home";

export default function NotSelectJob() {
  return (
    <Grid
      container
      sx={{
        textAlign: "center",
      }}
    >
      <Grid
        item
        xs={12}
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          textAlign: "center",
        }}
      >
        <img src={searchImg} width="150px" alt="search" />
      </Grid>
      <Grid item xs={12} sx={{ height: "20px" }}></Grid>
      <Grid item xs={12}>
        {i18n(`${i18nForthisPage}.selectJob`)}
      </Grid>
    </Grid>
  );
}