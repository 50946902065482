import { useState, useEffect } from 'react';
import { DataGridMui as Table, FilterFieldInterface, initRows, PaginationInterface } from "components/Common/Table/DataGridMui";
import { GridColumns } from "@mui/x-data-grid";
import { AxiosPost, GetPermission, ResultAPI } from "service/CommonFunction/TS_function";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { i18n } from 'i18n';
import { BtnEditOnTable, BtnSubmit, BtnViewOnTable } from 'components/Common/Button';
import { Box, Button, FormHelperText, Grid, Hidden, IconButton, Modal, Tooltip, Typography } from '@mui/material';
import { DialogActionCreators } from 'store/redux/DialogAlert';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import yupFormSchemas from 'components/Common/yup/yupFormSchemas';
import * as yup from "yup";
import InputCustomLabel from 'components/Common/ElementInForm/InputCustomLabelFormItem';
import Close from '@mui/icons-material/Close';
import secureLocalStorage from 'react-secure-storage';
import SendIcon from '@mui/icons-material/Send';
import { addDays } from 'date-fns';

import { Helmet } from 'react-helmet';

const styles = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  borderRadius: "10px",
  p: 2,
  minHeight: 250
};

const MemberTable = () => {
  const i18nField = 'CompanyEmployee';
  const i18nDupEmail = "RegisterEmployer";
  const i18nCommon = 'common';
  const i18nDialog = 'dialog';
  const i18nLabel = "SetupTemplate.label";
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = location.search;

  //state
  const sID = new URLSearchParams(searchParams).get("sID");
  const [nPermission, setPermission] = useState(0);
  const [loadingTable, setLoadingTable] = useState(true);
  const [isOpen, setOpen] = useState(false);
  const langeCode = secureLocalStorage.getItem("language").toString();
  const [isEmailDup1, setIsEmailDup1] = useState(false);
  const [isEmailDup2, setIsEmailDup2] = useState(false);
  const [isEmailDup3, setIsEmailDup3] = useState(false);



  let arrrow = {
    ...initRows,
    sSortExpression: "isEmployee",
    sSortDirection: "asc"
  }
  const sPathName = window.location.pathname;
  const sStore = secureLocalStorage.getItem(sPathName);
  if (sStore && sStore !== "") {
    const objRowTemp = JSON.parse(sStore + "");
    if (objRowTemp != null) {
      arrrow = objRowTemp;
      arrrow.arrRows = [];
    }
  }
  const [dataRow, setDataRow] = useState<PaginationInterface>(arrrow);
  // const [dataRow, setDataRow] = useState<PaginationInterface>({
  //   ...initRows,
  //   sSortExpression: "isEmployee",
  //   sSortDirection: "asc"
  // });

  const objSchema = {
    sEmpEmail: yupFormSchemas.email(i18n(`${i18nField}.Email`), { required: true })
  };

  //Yup object
  const schema = yup.object().shape(objSchema);

  //schema to From
  const form = useForm({
    resolver: yupResolver(schema),
    shouldUnregister: false,
    shouldFocusError: true,
    mode: "all",
  });

  const filter: FilterFieldInterface[] = [
    { sTypeFilterMode: "input", sFieldName: "sEmpName" },
    { sTypeFilterMode: "input", sFieldName: "sEmpEmail" },
    { sTypeFilterMode: "input", sFieldName: "sTel" },
    {
      sTypeFilterMode: "select", sFieldName: "sRole", lstDataSelect: [
        { value: "1", keyId: 0, label: i18n(`${i18nField}.admin`) }, //admin
        { value: "2", keyId: 1, label: i18n(`${i18nField}.user`) }, //user
      ],
    },
    {
      sTypeFilterMode: "select", sFieldName: "sStatus", lstDataSelect: [
        { value: "0", keyId: 0, label: i18n(`${i18nField}.Inactive`) },
        { value: "1", keyId: 1, label: i18n(`${i18nField}.Active`) },
        { value: "2", keyId: 2, label: i18n(`${i18nField}.SendRequest`) },
      ],
    },
    { sTypeFilterMode: "daterange", sFieldName: "sCreateDate" },
    { sTypeFilterMode: "daterange", sFieldName: "sLastedUse" }
  ]

  useEffect(() => {
    GetPermission(58, setPermission);
    loadData(dataRow);
  }, []);

  useEffect(() => {
    if (isOpen) {
      form.clearErrors();
    }
  }, [isOpen])

  const loadData = (p: PaginationInterface) => {
    setLoadingTable(true)
    AxiosPost("CompanyEmployee/SearchData_CompanyEmployee", { ...p, sLanguage: langeCode }, (result) => {
      setLoadingTable(false)
      setDataRow({
        ...p,
        arrRows: result.lstEmployee,// && result.lstMain,
        nDataLength: result.nDataLength,
        nPageIndex: result.nPageIndex,
      });
    })
  }

  const OnDelete = (e) => {
    dispatch(DialogActionCreators.OpenDialogSubmit(i18n(`common.AlertConfrimDel`), () => {
      dispatch(DialogActionCreators.CloseDialogSubmit() as any);
      dispatch(DialogActionCreators.LoadSubmit("Waiting...") as any);

      AxiosPost(`CompanyEmployee/EditData_RemoveDataTable`, { lstRemove: e }, (result) => {
        dispatch(DialogActionCreators.CloseDialogSubmit() as any);
        ResultAPI(result, i18n(`${i18nDialog}.DialogRemoveSuccess`), () => {
          dispatch(DialogActionCreators.OpenDialogSuccess(i18n(`${i18nDialog}.DialogRemoveSuccess`)) as any)
          loadData(dataRow);
        });
      }, (err) => {

        if (!err.response) {
          navigate("/error", null)
          return;
        }
      })
    }) as any)
  }

  const goToEdit = (sID: string, item: any) => {
    navigate(`/MemberEmployeeEdit?sID=${sID}&sMode=2`);
  };
  const goToView = (sID: string, item: any) => {
    navigate(`/MemberEmployeeEdit?sID=${sID}&sMode=1`);
  };


  const sendEmail = (e) => {
    var arr = [e["sEmpEmail"], e["sEmpEmail2"], e["sEmpEmail3"]];

    let objParam = {
      arrEmpEmail: arr
    };
    if (isEmailDup1 || isEmailDup2 || isEmailDup3) {
      dispatch(DialogActionCreators.OpenDialogWarning(
        i18n(`${i18nCommon}.msgEmailDup`) //email is duplicate       
      ) as any);
    } else {
      dispatch(DialogActionCreators.OpenDialogSubmit(i18n(`${i18nCommon}.AlertConfrimSaveEdit`), () => { //Do you want save?
        AxiosPost("CompanyEmployee/SendRequestCreateProfile", { ...objParam }, (result) => {
          dispatch(
            DialogActionCreators.OpenDialogSuccess(
              i18n(`${i18nCommon}.msgAlertInvitation`), //Saved successfully   
              () => window.location.reload()
            ) as any
          );
          setOpen(false)
        }, (err) => {
          if (err.Message != "") {
            dispatch(
              DialogActionCreators.OpenDialogWarning(
                err.Message
              ) as any
            );
          }
        });
      }) as any);
    }
  }

  const sendEmailAgain = (item) => {
    let objParam = {
      sEmpEmail: item
    };

    dispatch(DialogActionCreators.OpenDialogSubmit(i18n(`${i18nCommon}.AlertConfrimSaveEdit`), () => { //Do you want save?
      AxiosPost("CompanyEmployee/SendRequestCreateProfileAgain", { ...objParam }, (result) => {
        dispatch(
          DialogActionCreators.OpenDialogSuccess(
            i18n(`${i18nCommon}.msgAlertInvitation`), //Saved successfully    
            () => window.location.reload()
          ) as any
        );
        setOpen(false)
      }, (err) => {
        if (err.Message != "") {
          dispatch(
            DialogActionCreators.OpenDialogWarning(
              err.Message
            ) as any
          );
        }
      });
    }) as any);
  }

  const CheckExistMail1 = (value) => {
    AxiosPost("CompanyEmployee/CheckEmailDuplicate", { sEmpEmail: value }, (res) => {
      const data = res.Data;
      if (data.IsAlready) {
        setIsEmailDup1(true);
        form.setError("sEmpEmail", { type: "custom" });
      } else {
        setIsEmailDup1(false);
        // form.clearErrors();
        form.clearErrors("sEmpEmail");
      }
    });
  };

  const CheckExistMail2 = (value) => {
    AxiosPost("CompanyEmployee/CheckEmailDuplicate", { sEmpEmail: value }, (res) => {
      const data = res.Data;
      if (data.IsAlready) {
        setIsEmailDup2(true);
        form.setError("sEmpEmail2", { type: "custom" });
      } else {
        setIsEmailDup2(false);
        form.clearErrors("sEmpEmail2");
      }
    });
  };

  const CheckExistMail3 = (value) => {
    AxiosPost("CompanyEmployee/CheckEmailDuplicate", { sEmpEmail: value }, (res) => {
      const data = res.Data;
      if (data.IsAlready) {
        setIsEmailDup3(true);
        form.setError("sEmpEmail3", { type: "custom" });
      } else {
        setIsEmailDup3(false);
        form.clearErrors("sEmpEmail3");
      }
    });
  };

  const dataColumn: GridColumns = [
    {
      headerName: "",
      field: "ปุ่ม (เพิ่ม/แก้ไข)",
      type: "actions",
      width: 100,
      resizable: false,
      sortable: false,

      getActions: (item) => {
        if (item.row.isEmployee === false) {
          return [
            <BtnViewOnTable
              //  IsDisabled={nPermission !== 2}
              txt={i18n(`${i18nCommon}.preview`)}
              onClick={() => goToView(item.row.sID, item.row)}
            />
          ];
        } else {
          return [
            <BtnEditOnTable
              // IsDisabled={nPermission !== 2}
              txt={i18n(`${i18nCommon}.edit`)}
              onClick={() => goToEdit(item.row.sID, item.row)}
            />
          ];
        }

      },

    },
    {
      field: "sEmpName",
      headerName: i18n(`${i18nField}.Name`),
      headerAlign: "center",
      align: "left",
      flex: 3,
      resizable: false,
      minWidth: 200
    },
    {
      field: "sEmpEmail",
      headerName: i18n(`${i18nField}.Email`),
      headerAlign: "center",
      align: "center",
      flex: 3,
      resizable: false,
      minWidth: 200,
      renderCell: (item) => {
        return [
          (
            <Tooltip placement="top" title={item.row.sEmpEmail}>
              <div id={item.row.sID}
                style={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  width: '300px',
                }}>
                {item.row.sEmpEmail}
              </div>
            </Tooltip>
          )
        ];
      }
    },
    {
      field: "sTel",
      headerName: i18n(`${i18nField}.phonenumber`),
      headerAlign: "center",
      align: "center",
      flex: 3,
      resizable: false,
      minWidth: 180,
    },
    {
      field: "sRole",
      headerName: i18n(`${i18nField}.Role`),
      headerAlign: "center",
      align: "center",
      flex: 3,
      resizable: false,
      minWidth: 180,
    },
    {
      field: "sStatus",
      headerName: i18n(`${i18nField}.Status`),
      headerAlign: "center",
      align: "center",
      flex: 3,
      resizable: false,
      minWidth: 180,
      renderCell: (item) => {
        let objEmp = dataRow.arrRows.filter(f => f.sStatus == item.row.sStatus)[0];
        return (
          <>
            {objEmp.nStatus == 2 ? //status 2 = ส่งคำเชิญ
              <>
                {objEmp.sStatus} <br />
                <IconButton color="primary" aria-label="add to shopping cart">
                  <Tooltip title={i18n(`${i18nField}.sendemail`)}>
                    <SendIcon onClick={() => sendEmailAgain(item.row.sEmpEmail)} />
                  </Tooltip>
                </IconButton>
              </> : objEmp.sStatus}
          </>
        );
      }
    },
    {
      field: "sCreateDate",
      headerName: i18n(`${i18nField}.InviteDate`),
      headerAlign: "center",
      align: "center",
      flex: 3,
      resizable: false,
      minWidth: 180,
    },
    {
      field: "sLastedUse",
      headerName: i18n(`${i18nField}.LastUse`),
      headerAlign: "center",
      align: "center",
      flex: 3,
      resizable: false,
      minWidth: 180,
      renderCell: (item) => {
        return (
          <>
            {item.row.nStatus == 2 ? //status 2 = ส่งคำเชิญ
              null : item.row.sLastedUse}
          </>
        );
      }
    }
  ];

  return (
    <>
      <Box sx={{ display: "none !important", visibility: "hidden" }}>
        <Helmet>
          <title> {i18n(`${i18nField}.Title`)}</title>
        </Helmet>
      </Box>


      <FormProvider {...form}>
        <Grid container>
          <Grid item xs={12}>
            <Button
              onClick={() => { setOpen(true) }}
              sx={{
                backgroundColor: "#39B6E8",
                color: "#FFFFFF",
                "&:hover": {
                  backgroundColor: "#309DC8"
                },
                borderRadius: "20px",
                fontSize: "14px",
                height: "30px",
                marginBottom: "2%",
                float: "right"
              }}
            >
              {i18n(`${i18nField}.SendRequest`)}
            </Button>
          </Grid>

          <Grid item xs={12}>
            <Table
              isLoading={loadingTable}
              filterField={filter}
              Rows={dataRow}
              Columns={dataColumn}
              OnLoadData={(p) => loadData(p)}
              isShowCheckBox
              isRowSelectable={(item) => {
                if (item.row.nStatus === 1 || item.row.isEmployee === false) {
                  return false;
                } else {
                  return true;
                }
              }}//choose to disable delete check box
              onDelete={OnDelete}
            />
          </Grid>

          {/* Phone size */}
          <Hidden smUp>
            <Modal
              open={isOpen}
              onClose={() =>
                console.log('')
              }
              closeAfterTransition
            >
              <FormProvider {...form}>
                <Box sx={{ ...styles, width: 300 }} className={" flex flex-col"}>
                  <div className="flex flex-row ">
                    <div className="flex flex-[1] justify-end">
                      <Tooltip title={i18n(`${i18nLabel}.close`)}>
                        <div onClick={() => {
                          setOpen(false)
                        }} className='rounded-[50%] bg-[#d1d1d1] w-[1.5em] h-[1.5em] flex cursor-pointer ease-linear duration-[100ms]  hover:bg-[#8c8c8c]'>
                          <Close fontSize='small' className='m-[auto] text-[white]' />
                        </div>
                      </Tooltip>
                    </div>
                  </div>
                  <div className="flex flex-row mb-[0.8em]">
                    <Typography variant={'caption'} className="!text-[1em] !font-[500]">
                      {i18n(`${i18nCommon}.sendEmail`)} {/* send email */}
                    </Typography>
                  </div>
                  {/* email 1 */}
                  <div style={{ marginTop: 5 }}>
                    <InputCustomLabel
                      rounded
                      name={"sEmpEmail"}
                      required
                      onBlur={(value) => {
                        CheckExistMail1(value.target.value);
                      }}
                      label={i18n(`${i18nField}.Email`)}//email
                    />
                    {isEmailDup1 ? (
                      <FormHelperText style={{ color: "red" }}>
                        {i18n(`${i18nDupEmail}.ErrorEmail`)}
                      </FormHelperText>
                    ) : null}
                  </div>

                  {/* email 2 */}
                  <div style={{ marginTop: 15 }}>
                    <InputCustomLabel
                      rounded
                      name={"sEmpEmail2"}
                      onBlur={(value) => {
                        CheckExistMail2(value.target.value);
                      }}
                      label={i18n(`${i18nField}.Email`)}//email
                    />
                    {isEmailDup2 ? (
                      <FormHelperText style={{ color: "red" }}>
                        {i18n(`${i18nDupEmail}.ErrorEmail`)}
                      </FormHelperText>
                    ) : null}
                  </div>

                  {/* email 3 */}
                  <div style={{ marginTop: 15 }}>
                    <InputCustomLabel
                      rounded
                      name={"sEmpEmail3"}
                      onBlur={(value) => {
                        CheckExistMail3(value.target.value);
                      }}
                      label={i18n(`${i18nField}.Email`)}//email
                    />
                    {isEmailDup3 ? (
                      <FormHelperText style={{ color: "red" }}>
                        {i18n(`${i18nDupEmail}.ErrorEmail`)}
                      </FormHelperText>
                    ) : null}
                  </div>
                  <div className='flex flex-row justify-center' style={{ marginTop: "40px", marginBottom: "40px" }}>
                    <BtnSubmit txt={i18n(`${i18nField}.sendemail`)} onClick={form.handleSubmit((e) => { sendEmail(e); })} />
                  </div>
                </Box>
              </FormProvider>
            </Modal>
          </Hidden>

          {/* Desktop size */}
          <Hidden smDown>
            <Modal
              open={isOpen}
              onClose={() => console.log('')}
              closeAfterTransition
            >
              <FormProvider {...form}>
                <Box sx={{ ...styles, width: 600 }} className={" flex flex-col"} >
                  <div className="flex flex-row ">
                    <div className="flex flex-[1] justify-end">
                      <Tooltip title={i18n(`${i18nLabel}.close`)}>
                        <div onClick={() => {
                          setOpen(false)
                        }} className='rounded-[50%] bg-[#d1d1d1] w-[1.5em] h-[1.5em] flex cursor-pointer ease-linear duration-[100ms]  hover:bg-[#8c8c8c]'>
                          <Close fontSize='small' className='m-[auto] text-[white]' />
                        </div>
                      </Tooltip>
                    </div>
                  </div>
                  <div className="flex flex-row mb-[0.8em]">
                    <Typography variant={'caption'} className="!text-[1em] !font-[500]">
                      {i18n(`${i18nCommon}.sendEmail`)} {/* send email */}
                    </Typography>
                  </div>
                  {/* email 1 */}
                  <div style={{ marginTop: 5 }}>
                    <InputCustomLabel
                      rounded
                      name={"sEmpEmail"}
                      required
                      onBlur={(value1) => {
                        CheckExistMail1(value1.target.value);
                      }}
                      label={
                        <Typography sx={{ fontWeight: 400 }} component="label">
                          {i18n(`${i18nField}.Email`)} {/* email */}
                        </Typography>
                      }
                    />
                    {isEmailDup1 ? (
                      <FormHelperText style={{ color: "red" }}>
                        {i18n(`${i18nDupEmail}.ErrorEmail`)}
                      </FormHelperText>
                    ) : null}
                  </div>

                  {/* email 2 */}
                  <div style={{ marginTop: 15 }}>
                    <InputCustomLabel
                      rounded
                      name={"sEmpEmail2"}
                      onBlur={(value2) => {
                        CheckExistMail2(value2.target.value);
                      }}
                      label={i18n(`${i18nField}.Email`)}//email
                    />
                    {isEmailDup2 ? (
                      <FormHelperText style={{ color: "red" }}>
                        {i18n(`${i18nDupEmail}.ErrorEmail`)}
                      </FormHelperText>
                    ) : null}
                  </div>

                  {/* email 3 */}
                  <div style={{ marginTop: 15 }}>
                    <InputCustomLabel
                      rounded
                      name={"sEmpEmail3"}
                      onBlur={(value3) => {
                        CheckExistMail3(value3.target.value);
                      }}
                      label={i18n(`${i18nField}.Email`)}//email
                    />
                    {isEmailDup3 ? (
                      <FormHelperText style={{ color: "red" }}>
                        {i18n(`${i18nDupEmail}.ErrorEmail`)}
                      </FormHelperText>
                    ) : null}
                  </div>
                  {/* <div className='flex flex-row justify-center' style={{ marginTop: "40px", marginBottom: "40px" }}>*/}
                  <div className='flex flex-row justify-center' style={{ marginTop: "20px" }}>
                    <BtnSubmit txt={i18n(`${i18nField}.sendemail`)} onClick={form.handleSubmit((e) => { sendEmail(e); })} />
                  </div>
                </Box>
              </FormProvider>
            </Modal>
          </Hidden>
        </Grid>
      </FormProvider >
    </>
  );
}

export default MemberTable