import React, { useState } from "react";
import { useFormContext } from "react-hook-form";
import {
  Autocomplete,
  TextField,
  FormControl,
  FormHelperText,
  Box,
  ListItem,
  ListItemButton,
  Collapse,
  List,
  ListItemText,
} from "@mui/material";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import PropTypes, { InferProps } from "prop-types";
import secureLocalStorage from "react-secure-storage";
export default function SelectGroupForm({
  name,
  options,
  label,
  size,
  fullWidth,
  disableClearable,
  required,
  disabled,
  shrink,
  sxLabel,
  small,
  freeSolo,
  onChange,
  hidden,
  customValue,
  rounded,
  isOptionTwoLanguage
}: InferProps<typeof SelectGroupForm.propTypes>) {
  const {
    register,
    getValues,
    setValue,
    watch,
    formState: { errors },
  } = useFormContext();

  const [arrParentIDExpand, setArrParentIDExpand] = useState([]);
  const langCode = secureLocalStorage.getItem('language') || "th"
  const handleExpand = (sParentID) => {
    let arrClone = arrParentIDExpand;
    let isExist = arrClone.findIndex((f) => f == sParentID) != -1;
    if (!isExist) {
      arrClone.push(sParentID);
    } else {
      arrClone = arrClone.filter((f) => f != sParentID);
    }
    setArrParentIDExpand([...arrClone]);
  };

  return (
    <FormControl
      hiddenLabel={hidden || false}
      disabled={disabled}
      sx={{
        width: "100%",
        "label.MuiInputLabel-shrink": {
          top: "0!important",
        },
        "div.MuiOutlinedInput-root": {
          borderRadius: rounded ? "2em" : "",
        },
        "label.MuiInputLabel-formControl": {
          color: Boolean(errors[name]) ? "red" : "",
        },
        "label.MuiInputLabel-formControl > span": {
          color: "red",
        },
        "div.MuiOutlinedInput-root fieldset.MuiOutlinedInput-notchedOutline": {
          borderColor: Boolean(errors[name]) ? "red" : "",
        },
      }}
    >
      <Autocomplete
        hidden={hidden || false}
        renderOption={(props, option) => {
          return option.isParent ? (
            <ListItemButton
              key={option.value}
              style={{ padding: 0 }}
              onClick={() => handleExpand(option.value)}
            >
              <ListItemText> &nbsp;&nbsp;{isOptionTwoLanguage ? option[`label_${langCode}`] : option.label}</ListItemText>
              {arrParentIDExpand.findIndex((f) => f == option.value) !== -1 ||
                getValues("input_" + name).length > 0 ? (
                <Box sx={{ pl: 1, pr: 1, pt: 1.5, pb: 1.5 }}>
                  <ExpandLess />
                </Box>
              ) : (
                <Box sx={{ pl: 1, pr: 1, pt: 1.5, pb: 1.5 }}>
                  <ExpandMore />
                </Box>
              )}
            </ListItemButton>
          ) : (
            <Collapse
              key={option.value}
              in={
                arrParentIDExpand.findIndex((f) => f == option.sParentID) !==
                -1 || getValues("input_" + name).length > 0
              }
              timeout="auto"
              unmountOnExit
            >
              <List component="div" disablePadding>
                <ListItem {...props} key={option.value} value={option.value}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  {isOptionTwoLanguage ? option[`label_${langCode}`] : option.label}
                </ListItem>
              </List>
            </Collapse>
          );
        }}
        disabled={disabled || null}
        value={
          (customValue
            ? options.find((f) => f[customValue.key] === watch(name))
            : watch(name)) || null
        }
        fullWidth={fullWidth || null}
        disableClearable={disableClearable || null}
        size={size as "small" | "medium"}
        name={name}
        freeSolo={freeSolo || null}
        {...register(name)}
        onChange={(e, v) => {
          let value = v ? (customValue ? v[customValue.key] : v) : v;
          setValue(name, value, { shouldValidate: true });
          setValue("input_" + name, "");
          onChange && onChange(value);
        }}
        onInputChange={(e, v, r) => {
          if (freeSolo) {
            setValue(name, { label: v, value: undefined });
            onChange && onChange({ label: v, value: undefined });
          }
        }}
        onBlur={() => {
          setValue("input_" + name, "");
        }}
        options={options || []}
        getOptionLabel={(itemOption: any) => {
          return `${isOptionTwoLanguage ? itemOption[`label_${langCode}`] : itemOption.label}`;
        }}
        isOptionEqualToValue={(option, value) => option.value === value.value}
        renderInput={(params) => (
          <TextField
            name={"input_" + name}
            {...register("input_" + name)}
            required={required || null}
            disabled={disabled || null}
            label={label}
            {...params}
          />
        )}
      />
      {errors && errors[name] ? (
        <FormHelperText sx={{ color: "red" }}>
          {errors[name].message}
        </FormHelperText>
      ) : null}
    </FormControl>
  );
}
SelectGroupForm.propTypes = {
  required: PropTypes.bool,
  name: PropTypes.string.isRequired,
  options: PropTypes.array,
  label: PropTypes.string,
  size: PropTypes.oneOf(["small", "medium"]),
  fullWidth: PropTypes.bool,
  disableClearable: PropTypes.bool,
  freeSolo: PropTypes.bool,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  hidden: PropTypes.bool,
  shrink: PropTypes.bool,
  sxLabel: PropTypes.any,
  small: PropTypes.bool,
  rounded: PropTypes.bool,
  customValue: PropTypes.any,
  isOptionTwoLanguage: PropTypes.bool
};

SelectGroupForm.defaultProps = {
  required: false,
  options: [],
  label: "",
  size: "small",
  isOptionTwoLanguage: false,
  customValue: null,
};
