import React, { Fragment, useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { makeStyles, withStyles } from "@mui/styles";
import { Theme, useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import CheckIcon from "@mui/icons-material/CheckCircleOutline";
import CloseIcon from "@mui/icons-material/Close";
import CancelIcon from "@mui/icons-material/Cancel";
import ConfirmIcon from "@mui/icons-material/HelpOutline";
import ConfirmBtnIcon from "@mui/icons-material/Done";
import LinearProgress from "@mui/material/LinearProgress";
import Fab from "@mui/material/Fab";

import Button from "@mui/material/Button";
import WarningIcon from "@mui/icons-material/ErrorOutline";
// import { DialogActionCreators } from "store/redux/DialogAlert";
import { DialogActionCreators } from "../../../store/redux/DialogAlert";
import { useDispatch } from "react-redux";
import { i18n } from "i18n";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    ///zIndex: '900 !important',
  },
  paper: {
    width: "400px !important",
    height: "auto",
    minHeight: "55% !important",
    borderRadius: "20px !important",
  },

  DialogPaperTemp: {
    height: "70%",
    width: "400px",
    borderRadius: "20px",
  },
  DialogTitle: {
    height: "40%",
    backgroundColor: "#28a745",
    color: "#fff",
    textAlign: "center",
  },

  DialogTitleError: {
    height: "40%",
    backgroundColor: "#ed3847",
    color: "#fff",
    textAlign: "center",
  },
  DialogTitleWarning: {
    height: "40%",
    backgroundColor: "#ffce3d",
    color: "#fff",
    textAlign: "center",
  },
  DialogTitleSubmit: {
    height: "40%",
    backgroundColor: "#163172",
    color: "#333333",
    textAlign: "center",
  },

  DialogContent: {
    height: "50%",
    backgroundColor: "#fff",
    color: "#000",
    padding: "10px 15px",
  },
  DialogAction: {
    // height: "15%",
    backgroundColor: "#fff",
    color: "#000",
    justifyContent: "center !important",
  },
  DialogActionConfirm: {
    // height: "15%",
    backgroundColor: "#fff",
    color: "#000",
    justifyContent: "center !important",
    height: "70px",
  },
  SuccessColor: {
    color: "#fff",
    backgroundColor: "#28a745",
    borderColor: "#28a745",
    // margin: theme.spacing(1),
    "&:hover": {
      backgroundColor: "#28a745",
    },
  },
  ButtonDialog: {
    background: "rgb(250, 250, 250)",
    color: "#000",
    margin: "8px !important",
    width: "auto !important",
    fontSize: "1rem",
    borderRadius: "2em !important",
    padding: "4px 15px !important",
    textTransform: "none",
  },
}));
const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor:
      theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: "#28a745",
  },
}))(LinearProgress);

export const DialogSuccess = (props: { handleClick; open; sMsg?}) => {
  const i18nDialog = 'dialog';
  const dispatch = useDispatch();
  const { handleClick, open, sMsg } = props;
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      classes={{ paper: classes.paper }}
    >
      <DialogTitle className={classes.DialogTitle}>
        <CheckIcon
          style={{
            fontSize: "10rem",
            fontWeight: "bold",
            color: "#fff",
          }}
        />
      </DialogTitle>
      <DialogContent className={classes.DialogContent}>
        <DialogContentText
          style={{
            fontSize: "1.5rem",
            fontWeight: "bold",
            color: "#000",
          }}
        >
          {i18n(`${i18nDialog}.DialogSuccess`)} {/* Success */}
        </DialogContentText>
        <DialogContentText
          style={{
            color: "#000",
          }}
        >
          {sMsg ? sMsg : "Data is already saved."}
        </DialogContentText>
      </DialogContent>
      <DialogActions className={classes.DialogAction}>
        <div style={{ textAlign: "center" }}>
          <Button
            variant="contained"
            className={classes.ButtonDialog}
            style={{
              color: "#fff",
              backgroundColor: "#B6B6B6",
              borderRadius: "20px"
            }}
            size="small"
            startIcon={
              <CloseIcon
                style={{
                  fontSize: "1.5rem",
                }}
              />
            }
            onClick={(c) => {
              handleClick();
              dispatch(DialogActionCreators.CloseDialogSuccess());
              //  (DialogActionCreators.CloseDialogSuccess());
            }}
          >
            {/* Close */}
            {i18n(`${i18nDialog}.CloseButt`)}
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};
export const DialogError = (props: { handleClick; open; sMsg?}) => {
  const i18nDialog = 'dialog';
  const dispatch = useDispatch();
  const { handleClick, open, sMsg } = props;
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      classes={{ paper: classes.paper}}
    >
      <DialogTitle className={classes.DialogTitleError}>
        <CancelIcon
          style={{
            fontSize: "10rem",
            fontWeight: "bold",
            color: "#fff",
          }}
        />
      </DialogTitle>
      <DialogContent className={classes.DialogContent}>
        <DialogContentText
          style={{
            fontSize: "1.5rem",
            fontWeight: "bold",
            color: "#000",
          }}
        >
          Error
        </DialogContentText>
        <DialogContentText
          style={{
            color: "#000",
          }}
        >
          {sMsg ? sMsg : "Ooops, somethis went wrong."}
        </DialogContentText>
      </DialogContent>
      <DialogActions className={classes.DialogAction}>
        <div style={{ textAlign: "center" }}>
          <Button
            variant="contained"
            className={classes.ButtonDialog}
            style={{
              color: "#fff",
              backgroundColor: "#B6B6B6",
              borderRadius: "20px"
            }}
            size="small"
            startIcon={
              <CloseIcon
                style={{
                  fontSize: "1.5rem",
                }}
              />
            }
            onClick={(c) => {
              dispatch(DialogActionCreators.CloseDialogError());
              // (DialogActionCreators.CloseDialogError());
            }}
          >
            {/* Close */}
            {i18n(`${i18nDialog}.CloseButt`)}
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};
export const DialogWarning = (props: { handleClick; open; sMsg?, handleClickSubmit; }) => {
  const i18nDialog = 'dialog';
  const dispatch = useDispatch();
  const { open, sMsg, handleClickSubmit } = props;
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      classes={{ paper: classes.paper }}
    >
      <DialogTitle className={classes.DialogTitleWarning}>
        <WarningIcon
          style={{
            fontSize: "10rem",
            fontWeight: "bold",
            color: "rgb(255 255 255)",
          }}
        />
      </DialogTitle>

      <DialogContent className={classes.DialogContent}>
        <DialogContentText
          style={{
            fontSize: "1.5rem",
            fontWeight: "bold",
            color: "#000",
          }}
        >
          {i18n(`${i18nDialog}.DialogWarning`)}
        </DialogContentText>
        <DialogContentText
          style={{
            color: "#000",
          }}
        >
          {sMsg ? sMsg : "Please specify something."}
          {/* {sMsg ? sMsg : "Please Login."} */}
        </DialogContentText>
      </DialogContent>

      <DialogActions className={classes.DialogAction}>
        <div style={{ textAlign: "center" }}>
          <Button
            variant="contained"
            className={classes.ButtonDialog}
            style={{
              color: "#fff",
              backgroundColor: "#B6B6B6",
              borderRadius: "20px"
            }}
            size="small"
            startIcon={
              <CloseIcon
                style={{
                  fontSize: "1.5rem",
                }}
              />
            }
            onClick={(c) => {
              handleClickSubmit();
              dispatch(DialogActionCreators.CloseDialogWarning());
              // (DialogActionCreators.CloseDialogWarning());
            }}
          >
            {/* Close */}
            {i18n(`${i18nDialog}.CloseButt`)}
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};
export const DialogSubmit = (props: {
  handleClick;
  open;
  sMsg?;
  handleClickSubmit;
  IsLoad;
  handleCancel;
}) => {
  const dispatch = useDispatch();
  const i18nDialog = 'dialog';
  const { handleClick, open, sMsg, handleClickSubmit, IsLoad, handleCancel } =
    props;
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

 

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      classes={{ root:classes.root,  paper: classes.paper  }}
    >
      <DialogTitle className={classes.DialogTitleSubmit}>
        <ConfirmIcon
          style={{
            fontSize: "10rem",
            fontWeight: "bold",
            // color: "#333333",
            color: "#fff",
          }}
        />
      </DialogTitle>
      <DialogContent className={classes.DialogContent}>
        <DialogContentText
          style={{
            fontSize: "1.5rem",
            fontWeight: "bold",
            color: "#000",
          }}
        >
          {/* Confirm */}
          {i18n(`${i18nDialog}.DialogConfirm`)}
        </DialogContentText>
        <DialogContentText
          style={{
            color: "#000",
          }}
        >
          {sMsg ? sMsg : "Do you want to save data?"}
        </DialogContentText>
      </DialogContent>
      <DialogActions className={classes.DialogActionConfirm}>
        <div
          style={{
            display: IsLoad ? "inherit" : "none",
          }}
        >
          <BorderLinearProgress />
        </div>

        <div
          style={{
            display: !IsLoad ? "flex" : "none",
            justifyContent: "center",
          }}
        >
          <Button
            variant="contained"
            className={classes.ButtonDialog}
            style={{
              background: "#28a745",
              color: "white",
              borderRadius: "20px"
              // margin: "8px",
              // width: "auto",
              // fontSize: "1rem",
              // borderRadius: "15px",
              // padding: "4px 15px",
              // textTransform: "none",
            }}
            size="small"
            startIcon={
              <ConfirmBtnIcon
                style={{
                  fontSize: "1.5rem",
                }}
              />
            }
            onClick={handleClickSubmit}
          >
            {/* Confirm */}
            {i18n(`${i18nDialog}.DialogConfirm`)}
          </Button>
          <Button
            variant="contained"
            className={classes.ButtonDialog}
            style={{
              color: "#fff",
              backgroundColor: "#B6B6B6",
              borderRadius: "20px"
            }}
            size="small"
            startIcon={
              <CloseIcon
                style={{
                  fontSize: "1.5rem",
                }}
              />
            }
            onClick={(c) => {
              handleCancel();
              dispatch(DialogActionCreators.CloseDialogSubmit());
              // (DialogActionCreators.CloseDialogSubmit());
            }}
          >
            {/* Close */}
            {i18n(`${i18nDialog}.CloseButt`)}
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export const DialogSubmit_WARNNING = (props: {
  handleClick;
  open;
  sMsg?;
  handleClickSubmit;
  IsLoad;
}) => {
  const i18nDialog = 'dialog';
  const dispatch = useDispatch();
  const { handleClick, open, sMsg, handleClickSubmit, IsLoad } = props;
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open ? open : false}
      classes={{ paper: classes.paper }}
    >
      <DialogTitle className={classes.DialogTitleWarning}>
        <WarningIcon
          style={{
            fontSize: "10rem",
            fontWeight: "bold",
            color: "#000",
          }}
        />
      </DialogTitle>
      <DialogContent className={classes.DialogContent}>
        <DialogContentText
          style={{
            fontSize: "1.5rem",
            fontWeight: "bold",
            color: "#000",
          }}
        >
          {/* Confirm */}
          {i18n(`${i18nDialog}.DialogConfirm`)}
        </DialogContentText>
        <DialogContentText
          style={{
            color: "#000",
          }}
        >
          {sMsg ? sMsg : "Do you want to save data?"}
        </DialogContentText>
      </DialogContent>
      <DialogActions className={classes.DialogActionConfirm}>
        <div
          style={{
            display: IsLoad ? "inherit" : "none",
          }}
        >
          <BorderLinearProgress />
        </div>

        <div
          style={{
            display: !IsLoad ? "flex" : "none",
            justifyContent: "center",
          }}
        >
          <Button
            variant="contained"
            className={classes.ButtonDialog}
            style={{
              background: "#28a745",
              color: "white",
              borderRadius: "20px"
              // margin: "8px",
              // width: "auto",
              // fontSize: "1rem",
              // borderRadius: "15px",
              // padding: "4px 15px",
              // textTransform: "none",
            }}
            size="small"
            startIcon={
              <ConfirmBtnIcon
                style={{
                  fontSize: "1.5rem",
                }}
              />
            }
            onClick={handleClickSubmit}
          >
            {/* Confirm */}
            {i18n(`${i18nDialog}.DialogConfirm`)}
          </Button>
          <Button
            variant="contained"
            className={classes.ButtonDialog}
            style={{
              color: "#fff",
              backgroundColor: "#B6B6B6",
              borderRadius: "20px"
            }}
            size="small"
            startIcon={
              <CloseIcon
                style={{
                  fontSize: "1.5rem",
                }}
              />
            }
            onClick={(c) => {
              // dispatch(DialogActionCreators.CloseDialogSubmitWarning());
              dispatch(DialogActionCreators.CloseDialogSubmitWarning());
            }}
          >
            {/* Close */}
            {i18n(`${i18nDialog}.CloseButt`)}
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};
