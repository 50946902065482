import { useState, useEffect, useContext, useRef, useCallback } from "react";
import { FormProvider, useForm } from "react-hook-form";
import moment from "moment";
import {
  Grid,
  Typography,
  Box,
  FormControlLabel,
  Checkbox,
  Paper,
  IconButton,
  FormControl,
  RadioGroup,
  Radio,
  Stack,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { i18n } from "i18n";
import DatePickerFormItem from "components/Common/ElementInForm/DatePickerFormItem";
import Input from "components/Common/ElementInForm/InputCustomLabelFormItem";
import AutoCompleteSelect from "components/Common/ElementInForm/AutoCompleteSelect";
import { ProfileContext } from "./Context/ProfileContext";
import { IWorkExperience } from "./Interface";
import { DialogActionCreators } from "store/redux/DialogAlert";
import { useDispatch } from "react-redux";
import { AxiosGet } from "service/CommonFunction/TS_function";
import yupFormSchemas from "components/Common/yup/yupFormSchemas";
import InputNumber from "components/Common/ElementInForm/InputNumber";
import secureLocalStorage from "react-secure-storage";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { BtnBack, BtnEditProfile, BtnRadiusProfile, BtnSubmit } from "components/Common/Button";
import { AccordionCustom, AccordionDetailsCustom, AccordionSummaryCustom } from "./ElementCustom";
import { useNavigate } from "react-router-dom";
import { FnBlock_UI } from "service/SystemFunction/SystemFunction";
import { AxiosPost } from "service/CommonFunction/TS_function";
import DoneAllIcon from '@mui/icons-material/DoneAll';
import { RiDonutChartLine } from "react-icons/ri";
import CardComplete from "./CardComplete";
import CardInComplete from "./CardInComplete";
const i18nField = "entities.SeekerProfile";
const i18nCommon = "common";

const WorkExperienceNew = ({ Edit, isJobApply, sIDNew, isExpanded, sMode, ProfileID,onBackEdit }) => {
  const history = useNavigate();
  const dispatch = useDispatch();
  const { BlockUI, UnBlockUI } = FnBlock_UI();
  const [isCurrent, setIsCureant] = useState(false);
  const [minDate, setMinDateExpire] = useState(null);
  const [rawJobDetail, setRawJobDetail] = useState([]);
  const [Parent, setParent] = useState(0);
  const lang = secureLocalStorage.getItem("language").toString() || "th";
  const [expandedWork, setExpandedWork] = useState(true);
  const [schemaWork, setSchemaWork] = useState({});
  const [arrWorkExperience, setArrWorkExperience] = useState([]);

  const {
    arrWorkExperienceContext,
    setWorkExperience,
    optionPosition,
    optionCompany,
    optionJobFunction,
    optionJobIndustry,
    optionJobDetail,
    setOptionJobDeatail,
    isWorkExperience,
    setIsWorkExperience,
    yearExperience,
    setYearExperience,
    optionWorkEXP,
    isWorkExperienceEdit,
    setIsWorkExperienceEdit,
    profileContext,
    isLoading
  } = useContext(ProfileContext);
  const [selectWorkEXP, setselectWorkEXP] = useState("");
  const [arrselectWorkEXP, setArrselectWorkEXP] = useState([]);
  // Set up Schema Work Experience 
  const loopSetSchemaWork = () => {
    const schemaTemp = {};
    arrWorkExperienceContext.forEach((item) => {
      schemaTemp[`Position${item.Index}`] = yupFormSchemas.object(
        i18n(`${i18nField}.Position`),
        { required: isWorkExperience }
      );
      schemaTemp[`Company${item.Index}`] = yupFormSchemas.object(
        i18n(`${i18nField}.Company`),
        { required: isWorkExperience }
      );
      schemaTemp[`WorkDate${item.Index}`] = yupFormSchemas.dateRange(
        i18n(`${i18nField}.StartDate`),
        { required: isWorkExperience }
      );
      schemaTemp[`StartDate${item.Index}`] = yupFormSchemas.datetime(
        i18n(`${i18nField}.StartDate`),
        { required: isWorkExperience }
      );
      schemaTemp[`EndDate${item.Index}`] = yupFormSchemas.datetime(
        i18n(`${i18nField}.EndDate`),
        { required: isWorkExperience && !item.IsCurrent }
      );
      schemaTemp[`JobFunc${item.Index}`] = yupFormSchemas.object(
        i18n(`${i18nField}.JobFunction`),
        { required: isWorkExperience }
      );
      schemaTemp[`JobDetail${item.Index}`] = yupFormSchemas.object(
        i18n(`${i18nField}.JobDetail`),
        { required: isWorkExperience }
      );
      schemaTemp[`JobIndustry${item.Index}`] = yupFormSchemas.object(
        i18n(`${i18nField}.JobIndustry`),
        { required: isWorkExperience }
      );
      schemaTemp[`WorkSalary${item.Index}`] = yupFormSchemas.decimal(
        i18n(`${i18nField}.WorkSalary`),
        { required: isWorkExperience }
      );
      schemaTemp[`WorkRemark${item.Index}`] = yupFormSchemas.string(
        i18n(`${i18nField}.WorkRemark`),
        { required: isWorkExperience }
      );
      schemaTemp[`LeaveRemark${item.Index}`] = yupFormSchemas.string(
        i18n(`${i18nField}.LeaveRemark`)
      );
    });
    setSchemaWork(schemaTemp);
  };

  // -> Work Experience
  const arrSchemaNameWorkExp = [
    "Position",
    "Company",
    "WorkDate",
    "StartDate",
    "EndDate",
    "JobFunc",
    "JobDetail",
    "JobIndustry",
    "WorkSalary",
    "WorkRemark",
    "LeaveRemark",
  ];

  const [formSchema, setFormSchema] = useState<any>({
    WorkEXP: yupFormSchemas.object(i18n(`${i18nField}.WorkEXP`), {
      required: true,
    }),
    ...schemaWork,
  });

  const schema = yup.object().shape(formSchema);
  const form = useForm({
    resolver: yupResolver(schema),
    shouldUnregister: false,
    shouldFocusError: true,
    mode: "all",
  });

  useEffect(() => {
    for (const [key, value] of Object.entries(formSchema)) {
      if (arrSchemaNameWorkExp.some((a) => key.includes(a))) {
        delete formSchema[key];
      }
    }
    setFormSchema({ ...formSchema, ...schemaWork });
  }, [schemaWork]);

  useEffect(() => {
    loopSetSchemaWork();
  }, [isWorkExperience, arrWorkExperienceContext]);


  const fetchJobDetail = () => {
    AxiosGet("DataCollect/GetJobDetail", {}, (res) => {
      setRawJobDetail(res.Data);
    });
  };

  //แก้ไขข้อมูล
  const handleChangeValue = (form, index) => {
    let newData = arrWorkExperienceContext.map((item) => {
      if (item.Index === index) {
        item.Position = form.getValues("Position" + index);
        item.Company = form.getValues("Company" + index);
        item.StartDate = form.getValues("StartDate" + index);
        item.EndDate = form.getValues("EndDate" + index);
        item.IsCurrent = form.getValues("checkCurrent" + index) || false;
        item.JobFunc = form.getValues("JobFunc" + index);
        item.JobDetail = form.getValues("JobDetail" + index);
        item.JobIndustry = form.getValues("JobIndustry" + index);
        item.WorkRemark = form.getValues("WorkRemark" + item.Index);
        item.WorkSalary = form.getValues("WorkSalary" + item.Index);
        item.LeaveRemark = form.getValues("LeaveRemark" + item.Index);
        item.nParent =  form.getValues("JobFunc" + index)["value"] 
        setIsCureant(form.getValues("checkCurrent" + index) || false);
      }
      return item;
    });
    setWorkExperience(newData);
  };

  //ลบข้อมูล
  const handleRemoveWorkUnit = (itemIndex: number) => {
    dispatch(
      DialogActionCreators.OpenDialogSubmit(
        i18n(`${i18nCommon}.AlertConfrimDel`),
        () => {
          dispatch(DialogActionCreators.CloseDialogSubmit() as any);
          dispatch(DialogActionCreators.LoadSubmit("Waiting...") as any);
          let newData = arrWorkExperienceContext.filter((data) => {
            return data.Index !== itemIndex;
          });
          setWorkExperience(newData);
          form.unregister("Position" + itemIndex);
          form.unregister("Company" + itemIndex);
          form.unregister("StartDate" + itemIndex);
          form.unregister("EndDate" + itemIndex);
          form.unregister("checkCurrent" + itemIndex);
          form.unregister("JobFunc" + itemIndex);
          form.unregister("JobDetail" + itemIndex);
          form.unregister("JobIndustry" + itemIndex);
          form.unregister("WorkRemark" + itemIndex);
          form.unregister("WorkSalary" + itemIndex);
          form.unregister("LeaveRemark" + itemIndex);
        }
      )
    );
  };

  //AddNew
  const handleAddWorkUnit = () => {
    let arrIndex =
      arrWorkExperienceContext.map((item, index) => {
        return item.Index;
      }) || [];
    let nGenIndex = 0;
    if (arrIndex.length > 0) {
      nGenIndex = (Math.max.apply(Math, arrIndex) || 0) + 1;
    }
    let newData = [
      ...arrWorkExperienceContext,
      {
        Index: nGenIndex,
        Position: "",
        Company: null,
        StartDate: null,
        EndDate: null,
        IsCurrent: false,
        JobFunc: null,
        JobDetail: null,
        JobIndustry: null,
        WorkRemark: "",
        WorkSalary: null,
        LeaveRemark: "",
        nParent:0
      },
    ] as Array<IWorkExperience>;

    setWorkExperience(newData);
    setFocusNewField(`Position${nGenIndex}`, nGenIndex);

  };

  const setFocusNewField = (sName, nGenIndex) => {
    setTimeout(() => {
      try {
        form.setFocus(sName);
      } catch (error) { }
    }, 500);
  };


  const handleEditWorkUnit = () => {
    let sJobApply = "";
    let sID = "";
    if (isJobApply) {
      sJobApply = "sJobApply";
      sID = sIDNew;
    }
    history(`/MyProfileEdit?sMode=Experience&sJobApply=${sJobApply}&sIDNew=${sID}`);

  }


  useEffect(() => {
    const fetchData = async () => {
      await fetchJobDetail();
    };
    fetchData();
  }, []);

  const onBack = () => {

    setIsWorkExperienceEdit(false)
    if (isJobApply) {
      let objProfile = {
        sMode: "Experience",
      };
      let objJobDetail = {
        sID: sIDNew || "",
        isApply: true,
        sFromPage: "JobDetail",
      };
    
      onBackEdit(objProfile,objJobDetail);

    } else {
      history("/MyProfile?sMode=Experience");

    }
  }
  const onGoPage = () => {
    if (isJobApply) {
      let objProfile = {
        sMode: "Experience",
      };
      let objJobDetail = {
        sID: sIDNew || "",
        isApply: true,
        sFromPage: "JobDetail",
      };
      onBackEdit(objProfile,objJobDetail);
      
    } else {
      history("/MyProfile?sMode=Experience");

    }
  }

  const onSubmit = (values) => {
    let arrWorkEXP = [];
    if (selectWorkEXP === "yes") {
      let dYearExp = 0;
      arrWorkEXP = arrWorkExperienceContext.map((item) => {
        if (item.IsCurrent) {
          let duration = moment.duration(moment().diff(moment(item.StartDate)));
          dYearExp += duration.asYears();
        } else {
          let duration = moment.duration(moment(item.EndDate).diff(moment(item.StartDate)));
          dYearExp += duration.asYears();
        }
        return {
          nWorkExperienceId: item.Index || 0,
          sPosition: item.Position && (item.Position["label"] || ""),
          sCompany: (item.Company && item.Company["label"]) || "",
          nStartDate: moment(item.StartDate).valueOf(),
          nEndDate: item.EndDate ? moment(item.EndDate).valueOf() : null,
          IsCurrent: item.IsCurrent,
          nJobFunction: item.JobFunc["value"] || 0,
          nJobDetail: item.JobDetail["value"] || 0,
          nJobIndustry: item.JobIndustry["value"] || 0,
          sRemark: item.WorkRemark,
          nWorkSalary: item.WorkSalary,
          sLeaveRemark: item.LeaveRemark,
      
        };
      });
    }

 
    const dataProfile = {
      IsWorkExperience: isWorkExperience,
      nYearWorkExperience: isWorkExperience ? +form.getValues("WorkEXP").value : 0,
      listWorkExperience: arrWorkEXP,
      nMode: 1,
    };

    dispatch(DialogActionCreators.OpenDialogSubmit(i18n(`${i18nField}.ConfirmMsgUpdate`), () => {
      dispatch(DialogActionCreators.CloseDialogSubmit() as any);
      dispatch(DialogActionCreators.LoadSubmit("Waiting...") as any);
      BlockUI();
      AxiosPost(`JobSeeker/UpdateProfile`, dataProfile, (result) => {
        let ProfileNoti = secureLocalStorage.getItem("ProfileNoti");
        secureLocalStorage.setItem("ProfileNoti", ProfileNoti === "true" ? "false" : "true");
        UnBlockUI();
        const res = result.Data;
        if (res.IsSuccess) {
          dispatch(DialogActionCreators.OpenDialogSuccess(i18n(`${i18nField}.MsgWorkExperience`), () => { onGoPage(); })
          );
        }
      },
        (err) => {
          if (err.Message != "") {
            dispatch(
              DialogActionCreators.OpenDialogWarning(
                err.Message
              ) as any
            );
          }
        }
      );
    }
    ) as any
    );
  };

  const setDataMyProfile = () => {
    const data = profileContext as any;
    const selectWorkEXP = optionWorkEXP.find((option: any) => {
      return option.value === data.nYearWorkExperience;
    });
    setArrselectWorkEXP(selectWorkEXP);
    form.setValue("WorkEXP", selectWorkEXP);
    setYearExperience(selectWorkEXP);
    setArrWorkExperience(data.listWorkExperience || []);

    let listWorkExperience = [];
    if ((data.listWorkExperience || []).length > 0) {
      listWorkExperience = (data.listWorkExperience || []).map((item) => {
        const selectPosition = optionPosition.find((data: any) => {
          return data.label === item.sPosition;
        });
        const selectCompany = optionCompany.find((data: any) => {
          return data.label === item.sCompany;
        });
        const selectJobFunc = optionJobFunction.find((data: any) => {
          return data.value === item.nJobFunction;
        });
        const selectJobDetail = optionJobDetail.find((data: any) => {
          return data.value === item.nJobDetail;
        });
        const selectJobIndustry = optionJobIndustry.find((data: any) => {
          return data.value === (item.nJobIndustry.toString() || "");
        });
        setIsWorkExperience(data.IsWorkExperience);
        setselectWorkEXP(data.IsWorkExperience ? "yes" : "no")
        return {
          Index: item.nWorkExperienceId,
          Position: selectPosition,
          Company: !selectCompany ? { label: item.sCompany, value: 0 } : selectCompany,
          StartDate: item.dStartDate,
          EndDate: item.dEndDate,
          IsCurrent: item.IsCurrent,
          JobFunc: selectJobFunc,
          JobDetail: selectJobDetail,
          JobIndustry: selectJobIndustry,
          WorkRemark: item.sRemark,
          WorkSalary: item.nWorkSalary,
          LeaveRemark: item.sLeaveRemark || "",
          nParent:item.nJobFunction
        } as IWorkExperience;
      }) as IWorkExperience[];
    } else {
      setIsWorkExperience(false);
      setselectWorkEXP("no")
      listWorkExperience = [
        {
          Index: 0,
          Position: null,
          Company: null,
          StartDate: null,
          EndDate: null,
          IsCurrent: false,
          JobFunc: null,
          JobDetail: null,
          JobIndustry: null,
          WorkRemark: "",
          WorkSalary: null,
          LeaveRemark: "",
          nParent:0
        },
      ] as IWorkExperience[];
    }
    setWorkExperience(listWorkExperience);
    listWorkExperience.forEach((data) => {

      form.setValue("Position" + data.Index, data.Position || "");
      form.setValue("Company" + data.Index, data.Company || "");
      form.setValue("StartDate" + data.Index, data.StartDate || "");
      form.setValue("EndDate" + data.Index, data.EndDate || "");
      form.setValue("checkCurrent" + data.Index, data.IsCurrent);
      form.setValue("JobFunc" + data.Index, data.JobFunc || "");
      form.setValue("JobDetail" + data.Index, data.JobDetail || "");
      form.setValue("JobIndustry" + data.Index, data.JobIndustry || "");
      form.setValue("WorkRemark" + data.Index, data.WorkRemark || "");
      form.setValue("WorkSalary" + data.Index, data.WorkSalary || 0);
      form.setValue("LeaveRemark" + data.Index, data.LeaveRemark || "");
      if (data.JobFunc != null) {
        let ObjData: JobFuncParent = {};
        ObjData = data.JobFunc
        let ObjProblem = {
          nParent: ObjData.value,
        };
        setParent(ObjProblem.nParent)
      }

    });
  };

  useEffect(() => {
    if (!isLoading) {
      setDataMyProfile();
    }
    setIsWorkExperienceEdit(Edit);
    if (sMode || ProfileID) {
      setExpandedWork(isExpanded)
    }

  }, [isLoading]);


  return (
    <FormProvider {...form} >
      <AccordionCustom
        sx={{ border: "10px", p: 1, flex: 1 }}
        expanded={expandedWork}
        onChange={() => setExpandedWork((prev) => !prev)}
      >
        {/* <div ref={oFormRef}> */}
        <AccordionSummaryCustom >
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item xs={6} sm={8} md={9}>
              <Typography >
                {i18n(`${i18nField}.PanelWorkEXP`)} <span style={{ color: "red" }}> *</span>
              </Typography>
            </Grid>
            <Grid item container
              justifyContent="flex-end" xs={6} sm={4} md={3}>
              <CardComplete isComPlete={true} />
            </Grid>
          </Grid>
        </AccordionSummaryCustom>

        <AccordionDetailsCustom>
          <div id={"5"}>
            <Grid item xs={12} sx={{ border: "10px", p: 1, flex: 1, m: "2%" }}>
              <Grid container spacing={1} >
                <Grid item xs={12} >
                  <FormControl>
                    <RadioGroup
                      row
                      name={"isWorkEXP"}
                      value={selectWorkEXP}
                      onChange={(e, v) => {
                        setIsWorkExperience(v === "yes");
                        setselectWorkEXP(v);
                      }}
                    >
                      <FormControlLabel
                        disabled={!isWorkExperienceEdit}
                        value={"yes"}
                        control={<Radio />}
                        label={i18n(`${i18nField}.HaveWorkEXP`)}
                      />
                      <FormControlLabel
                        disabled={!isWorkExperienceEdit}
                        value={"no"}
                        control={<Radio />}
                        label={i18n(`${i18nField}.NotWordEXP`)}
                        onChange={() => form.setValue("WorkEXP", arrselectWorkEXP)}
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                {isWorkExperience && (
                  <Grid item xs={12} sm={12} md={10}>
                    <AutoCompleteSelect
                      name={"WorkEXP"}
                      fullWidth
                      label={i18n(`${i18nField}.WorkEXP`)}
                      options={optionWorkEXP}
                      onChange={(value) => {
                        setYearExperience(value);
                      }}
                      required
                      disabled={!isWorkExperienceEdit}
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
            {isWorkExperience && (
              <>
              {(arrWorkExperienceContext || []).map(
                  (item: IWorkExperience, index) => {
                    return (
                      <Grid item xs={12} key={item.Index}>

                        <Box component={Paper} sx={{ border: "10px", p: 1, flex: 1, m: "2%" }}>
                          {isWorkExperienceEdit && (
                            <>
                              {index === 0 ? null : (
                                <IconButton
                                  aria-label="delete"
                                  color="error"
                                  onClick={() => handleRemoveWorkUnit(item.Index)}
                                  disabled={!isWorkExperienceEdit}
                                >
                                  <CloseIcon />
                                </IconButton>
                              )}
                            </>
                          )}
                          <Grid container spacing={3} >

                            <>
                              <Grid item xs={12} sm={6} md={6}>
                                <AutoCompleteSelect
                                  name={"Position" + item.Index}
                                  fullWidth
                                  label={i18n(`${i18nField}.Position`)}
                                  options={optionPosition}
                                  onChange={(value) => {
                                    handleChangeValue(form, item.Index);

                                  }}
                                  required
                                  freeSolo
                                  disabled={!isWorkExperienceEdit}
                                />
                              </Grid>
                              <Grid item xs={12} sm={6} md={6}>
                                <AutoCompleteSelect
                                  name={"Company" + item.Index}
                                  fullWidth
                                  label={i18n(`${i18nField}.Company`)}
                                  options={optionCompany}
                                  required
                                  freeSolo
                                  onChange={(value) => {
                                    handleChangeValue(form, item.Index);

                                  }}
                                  disabled={!isWorkExperienceEdit}
                                />
                              </Grid>
                              <Grid item xs={12} sm={4} md={4}>
                                <DatePickerFormItem
                                  name={"StartDate" + item.Index}
                                  label={
                                    <Typography sx={{ fontWeight: 400 }} component="label">
                                      {i18n(`${i18nField}.StartDate`)}
                                    </Typography>
                                  }
                                  small={true}
                                  disabled={!isWorkExperienceEdit}
                                  onChange={(value) => {
                                    let dEnd = form.getValues("EndDate" + item.Index);
                                    if (dEnd) {
                                      let nDiffDate = moment(dEnd).diff(value, "days");
                                      if (nDiffDate < 0) {
                                        form.setValue("EndDate" + item.Index, value);
                                      }
                                    }
                                    handleChangeValue(form, item.Index);
                                    setMinDateExpire(() => value);
                                  }}
                                  required
                                />
                              </Grid>
                              <Grid item xs={12} sm={4} md={4}>
                                <DatePickerFormItem
                                  name={"EndDate" + item.Index}
                                  minDate={minDate}
                                  label={i18n(`${i18nField}.EndDate`)}
                                  small={true}
                                  onChange={(value) => {
                                    handleChangeValue(form, item.Index);

                                  }}
                                  required={!isCurrent}
                                  disabled={item.IsCurrent || !isWorkExperienceEdit}
                                />
                              </Grid>
                              <Grid item xs={12} sm={4} md={4}>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      name={"checkCurrent" + item.Index}
                                      checked={item.IsCurrent}
                                      onChange={(e, value) => {
                                        if (value) {
                                          form.setValue("EndDate" + item.Index, null);
                                        }
                                        schemaWork[`EndDate${item.Index}`] = yupFormSchemas.datetime(
                                          i18n(`${i18nField}.EndDate`),
                                          { required: !value }
                                        );
                                        setSchemaWork({ ...schemaWork })
                                        setIsCureant((prev) => !prev);
                                        form.setValue("checkCurrent" + item.Index, value);
                                        handleChangeValue(form, item.Index);

                                      }}
                                    />
                                  }
                                  label={i18n(`${i18nField}.ToCurrent`)}
                                  disabled={!isWorkExperienceEdit}
                                />
                              </Grid>
                              <Grid item xs={12} md={6}>
                                <AutoCompleteSelect
                                  name={"JobFunc" + item.Index}
                                  label={i18n(`${i18nField}.JobFunction`)}
                                  onChange={(value) => {
                                    handleChangeValue(form, item.Index);
                                    form.clearErrors("JobDetail");
                                    form.setValue("JobDetail" + item.Index, 0);
                                    setParent(value ? value.value : 0);
                                    if (value) {
                                      const newJobDetail = rawJobDetail.filter((item) => {
                                        return item.nHeader_ID === value.value;
                                      });
                                      const option = (newJobDetail || []).map((item) => {
                                        return {
                                          label:
                                            lang === "th"
                                              ? item.sData_Name_TH
                                              : item.sData_Name_ENG,
                                          value: item.nData_ID,
                                        };
                                      });
                                      setOptionJobDeatail(option);
                                    }

                                  }}
                                  options={optionJobFunction}
                                  required
                                  disabled={!isWorkExperienceEdit}
                                />

                              </Grid>
                              <Grid item xs={12} md={6}>
                                <AutoCompleteSelect
                                  name={"JobDetail" + item.Index}
                                  label={i18n(`${i18nField}.JobDetail`)}
                                  onChange={(value) => {
                                    if (value) {
                                      handleChangeValue(form, item.Index);
                                    }
                                  }}
                                  options={optionJobDetail.filter((f) => f.sParent === item.nParent)}
                                  required
                                  disabled={!isWorkExperienceEdit}
                                />
                              </Grid>
                              <Grid item xs={12} md={12}>
                                <AutoCompleteSelect
                                  name={"JobIndustry" + item.Index}
                                  label={i18n(`${i18nField}.JobIndustry`)}
                                  onChange={(value) => {
                                    handleChangeValue(form, item.Index);

                                  }}
                                  options={optionJobIndustry}
                                  required
                                  disabled={!isWorkExperienceEdit}
                                />
                              </Grid>
                              <Grid item md={4}>
                                <InputNumber
                                  name={`WorkSalary${item.Index}`}
                                  required
                                  label={
                                    <Typography component="label">{i18n(`${i18nField}.WorkSalary`)}</Typography>
                                  }
                                  onBlur={(value) => {
                                    handleChangeValue(form, item.Index);

                                  }}
                                  labelString={""}
                                  disabled={!isWorkExperienceEdit}
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <Input
                                  name={"WorkRemark" + item.Index}
                                  fullWidth
                                  multiline
                                  label={
                                    <Typography sx={{ fontWeight: 400 }} component="label">
                                      {i18n(`${i18nField}.WorkRemark`)}
                                    </Typography>
                                  }
                                  rows={4}
                                  maxLength={3000}
                                  required
                                  onBlur={(value) => {
                                    handleChangeValue(form, item.Index);

                                  }}
                                  disabled={!isWorkExperienceEdit}
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <Input
                                  name={"LeaveRemark" + item.Index}
                                  fullWidth
                                  multiline
                                  label={
                                    <Typography sx={{ fontWeight: 400 }} component="label">
                                      {i18n(`${i18nField}.LeaveRemark`)}
                                    </Typography>
                                  }
                                  rows={4}
                                  maxLength={3000}
                                  onBlur={(value) => {
                                    handleChangeValue(form, item.Index);

                                  }}
                                  disabled={!isWorkExperienceEdit}
                                />
                              </Grid >
                            </>

                          </Grid >
                        </Box>

                      </Grid>
                    )
                  }
                )}
                {isWorkExperienceEdit && (
                  <Stack
                    sx={{ border: "10px", p: 1, flex: 1, m: "2%" }}
                    direction="row"
                    justifyContent="center"
                  >
                    <BtnRadiusProfile
                      txt={i18n(`${i18nField}.AddWorkDetail`)}
                      onClick={handleAddWorkUnit}
                    />
                  </Stack>
                )
                }
              </>
            )
            }

            {!isWorkExperienceEdit && (
              <Stack
                sx={{ border: "10px", p: 1, flex: 1, m: "2%" }}
                direction="row"
              >
                <BtnEditProfile
                  txt={i18n(`${i18nField}.Edit`)}
                  onClick={handleEditWorkUnit}
                />
              </Stack>
            )}
          </div>
        </AccordionDetailsCustom>
      </AccordionCustom>
      {isWorkExperienceEdit && (
        <Stack
          spacing={1}
          sx={{ border: "10px", p: 1, flex: 1, mt: "2%" }}
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <BtnBack
            onClick={() => { onBack() }}
            txt={i18n(`${i18nCommon}.back`)}
          />
          <BtnSubmit
            onClick={form.handleSubmit(onSubmit, (err) => console.log("error", err))}
            txt={i18n(`${i18nCommon}.save`)}
          />
        </Stack>
      )}
    </FormProvider >
  );
};

export default WorkExperienceNew;
export interface JobFuncParent {
  value?: number;

}
