import { Box, Card, Grid, Hidden, Stack, Typography, } from '@mui/material';
import FormTablelOrderEmployer from 'view/font/OrderEmployer/OrderEmployerTable';
// import FormTablelJobPackage from 'view/font/OrderEmployer/JobPackageTable';
// import FormTablelBannerPackage from 'view/font/OrderEmployer/BannerPackageTable';
// import CoinPackageTable from './CoinPackageTable';
import { i18n } from 'i18n';
import { Helmet } from 'react-helmet';

const ShowListOrderEmployer = () => {
  const i18nField = "TableOrderEmployer.Page";

  return (
    <>
      <Box sx={{ display: "none !important", visibility: "hidden" }}>
        <Helmet>
          <title> {i18n(`${i18nField}.Title`)} </title>
        </Helmet>
      </Box>



      {/* phone size */}
      <Hidden smUp>
        <Card sx={{
          padding: "2em",
          borderRadius: "1em",
          position: "relative",
        }}>
          <Grid className="FormShowListPackage" >
            <Grid container xs={12} >
              <Grid item xs={12} >
                <Stack spacing={3} width={"100%"}>
                  <Grid item sx={{ width: "100%" }}>
                    <FormTablelOrderEmployer />
                  </Grid>
                  {/* <Grid item sx={{ width: "100%", backgroundColor: "#3d5afe", borderRadius: 5, p: 1 }}>
                                        < Typography variant="h6" color="#FFFFFF" component="div" align='center' >
                                            {i18n(`${i18nField_Job}.TitlePackage`)}
                                        </Typography>
                                    </Grid>
                                    <Grid item sx={{ width: "100%" }}>
                                        <FormTablelJobPackage />
                                    </Grid> */}
                  {/* <Grid item sx={{ width: "100%", backgroundColor: "#121858", borderRadius: 5, p: 1 }}>
                                        < Typography variant="h6" color="#FFFFFF" component="div" align='center' >
                                            {i18n(`${i18nField_Banner}.CoinPackage`)}
                                        </Typography>
                                    </Grid>
                                    <Grid item sx={{ width: "100%" }}>
                                        <CoinPackageTable />
                                    </Grid>
                                    <Grid item sx={{ width: "100%", backgroundColor: "#009688", borderRadius: 5, p: 1 }}>
                                        < Typography variant="h6" color="#FFFFFF" component="div" align='center' >
                                            {i18n(`${i18nField_Banner}.TitleBanner`)}
                                        </Typography>
                                    </Grid>
                                    <Grid item sx={{ width: "100%" }}>
                                        <FormTablelBannerPackage />
                                    </Grid> */}
                </Stack>
              </Grid>
            </Grid>
          </Grid>
        </Card>
      </Hidden>

      {/* normal size */}
      <Hidden smDown>
        <Card sx={{
          padding: "2em",
          borderRadius: "1em",
          position: "relative",
          mx: "10%"
        }}>
          <Grid className="FormShowListPackage" >
            <Grid container xs={12} >
              <Grid item xs={12} >
                <Stack spacing={3} width={"100%"}>
                  <Grid item sx={{ width: "100%" }}>
                    <FormTablelOrderEmployer />
                  </Grid>
                  {/* <Grid item sx={{ width: "100%", backgroundColor: "#3d5afe", borderRadius: 5, p: 1 }}>
                                        < Typography variant="h6" color="#FFFFFF" component="div" align='center' >
                                            {i18n(`${i18nField_Job}.TitlePackage`)}
                                        </Typography>
                                    </Grid>
                                    <Grid item sx={{ width: "100%" }}>
                                        <FormTablelJobPackage />
                                    </Grid> */}
                  {/* <Grid item sx={{ width: "100%", backgroundColor: "#121858", borderRadius: 5, p: 1 }}>
                                        < Typography variant="h6" color="#FFFFFF" component="div" align='center' >
                                            {i18n(`${i18nField_Banner}.CoinPackage`)}
                                        </Typography>
                                    </Grid>
                                    <Grid item sx={{ width: "100%" }}>
                                        <CoinPackageTable />
                                    </Grid>
                                    <Grid item sx={{ width: "100%", backgroundColor: "#009688", borderRadius: 5, p: 1 }}>
                                        < Typography variant="h6" color="#FFFFFF" component="div" align='center' >
                                            {i18n(`${i18nField_Banner}.TitleBanner`)}
                                        </Typography>
                                    </Grid>
                                    <Grid item sx={{ width: "100%" }}>
                                        <FormTablelBannerPackage />
                                    </Grid> */}
                </Stack>
              </Grid>
            </Grid>
          </Grid>
        </Card>
      </Hidden>
    </>
  );
}
export default ShowListOrderEmployer
