
import ExampleBanner from "./ExampleBanner";
const CardBanner = ({}) => {
    return (
        <>
            <div className={"plan-Bannercontent"}>
                <ExampleBanner nPackageTypeID={3} sBuy={"no"}/>
            </div>

        </>
    )
}
export default CardBanner