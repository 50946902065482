import { Card, Grid, Typography } from "@mui/material";
import { AccordionCustom, AccordionDetailsCustom, AccordionSummaryCustom } from "components/SeekerProfile/ElementCustom"
import { useState, useContext, useEffect, useMemo, useCallback } from "react";
import { i18n } from "i18n";
import UploadFile from "components/Common/ElementInForm/UploadFile/UploadFile";
import { ProfileContext } from "components/SeekerProfile/Context/ProfileContext";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { DialogActionCreators } from "store/redux/DialogAlert";
import { FnBlock_UI } from "service/SystemFunction/SystemFunction";
import { AxiosGet, AxiosPost, Extension } from "service/CommonFunction/TS_function";
import CardComplete from "./CardComplete";
import CardInComplete from "./CardInComplete";
import { List as LINQ } from "linqts";
import secureLocalStorage from "react-secure-storage";
import _ from "lodash";

const WorkExperienceFile = ({ isExpanded, isJobApply, sIDNew, sMode }) => {

  const navigate = useNavigate();
  const [expandedFile, setExpandedFile] = useState(false);
  const [isDelete, setIsDelete] = useState(true);
  const i18nField = "entities.SeekerProfile";
  const [IsFileComplete, setIsFileComplete] = useState(true);
   const [isComPlete, setisComPlete] = useState(false);
  const {
    fileUploadResumeContext,
    setFileUploadResume,
    profileContext,
    isLoading,
  } = useContext(ProfileContext);
  const { BlockUI, UnBlockUI } = FnBlock_UI();
  const dispatch = useDispatch();
  const history = useNavigate();
  const [formSchema, setFormSchema] = useState<any>({});
  const schema = yup.object().shape(formSchema);
  const [ProfileImage, setProfileImage] = useState([]);
  const [isLoad, setIsLoad] = useState(true);
  const [Mode, setsMode] = useState(sMode);
  const form = useForm({
    resolver: yupResolver(schema),
    shouldUnregister: false,
    shouldFocusError: true,
    mode: "all",
  });



  useEffect(() => {
    if (Mode === "Resume") {
      let nMode = 0;
      let arrData = new LINQ<any>(fileUploadResumeContext).Where((w) => w.IsNew === true && w.IsComplete === true).ToArray();
      if (arrData.length > 0) {
        nMode = 1;
      }
      else if (fileUploadResumeContext.length < ProfileImage.length) {
        nMode = 2;
      }
      if (nMode != 0 && isLoad && IsFileComplete) {
        setIsLoad(false)
        onSubmit(nMode);
      }
    }
    setIsDelete(fileUploadResumeContext.length === 1 ? false : true)
  }, [fileUploadResumeContext]);

  const onSubmit = (nAdd) => {

    const dataProfile = {
      listFileResume: fileUploadResumeContext,
    };

    let sMsg = "";
    if (nAdd === 1) {
      sMsg = i18n(`${i18nField}.MsgResumeUpdated`);
    } else {
      sMsg = i18n(`${i18nField}.MsgResumeDelete`);
    }
    BlockUI();
    AxiosPost(`JobSeeker/SaveDataFileResume`, dataProfile, (result) => {
      let ProfileNoti = secureLocalStorage.getItem("ProfileNoti");
      secureLocalStorage.setItem("ProfileNoti", ProfileNoti === "true" ? "false" : "true");
      if (result.StatusCode === 200) {
        setProfileImage(result.listFileResume);
        setTimeout(() => {
          setIsLoad(true)
          setIsFileComplete(true);
          UnBlockUI();
        }, 2000);
        setFileUploadResume(result.listFileResume)
        dispatch(DialogActionCreators.OpenDialogSuccess(sMsg, () => { onGoPage() })) as any
      }
      GetMyProfileStatus();
    },
      (err) => {
        if (err.Message != "") {
          dispatch(
            DialogActionCreators.OpenDialogWarning(
              err.Message
            ) as any
          );
        }
      }
    );

  };

  const onGoPage = () => {
    if (isJobApply) {
      let objProfile = {
        sMode: "Resume",
      };
      let objJobDetail = {
        sID: sIDNew || "",
        isApply: true,
        sFromPage: "JobDetail",
      };
      navigate("/JobApply", {
        state: {
          objProfile: objProfile,
          objJobDetail: objJobDetail
        },
      });

    } else {
      history("/MyProfile?sMode=Resume");
    }
  }
 
  const GetMyProfile = () => {
    AxiosGet("JobSeeker/GetMyProfileResuMe", {}, (res) => {
      setFileUploadResume(res.listFileResume || []);
      setProfileImage(res.listFileResume || []);
    });
  };


  const GetMyProfileStatus = () => {
    let param = {
      nPanel: 2
    }
    AxiosPost("JobSeeker/GetMyProfileStatus", param, (res) => {
        setisComPlete(res.IsCompleted);
    });
};

  useEffect(() => {
    GetMyProfileStatus();
    setExpandedFile(isExpanded);
    GetMyProfile();
   
  }, []);

  return (
    <>
      <FormProvider {...form}>
        <AccordionCustom
          sx={{ border: "10px", p: 1, flex: 1, }}
          expanded={expandedFile}
          onChange={() => { setExpandedFile((prev) => !prev); setsMode("Resume") }}
        >
          <AccordionSummaryCustom>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item xs={6} sm={8} md={9}>
                <Typography >
                  {i18n(`${i18nField}.Resume`)} <span style={{ color: "red" }}> *</span>
                </Typography>
              </Grid>
              <Grid item container
                justifyContent="flex-end" xs={6} sm={4} md={3}>
                <CardComplete isComPlete={isComPlete} />
                {/* {isComPlete ? (
                  <CardComplete />
                ) : (
                  <CardInComplete />
                )} */}
              </Grid>
            </Grid>
          </AccordionSummaryCustom>
          <AccordionDetailsCustom>
            <Grid sx={{ border: "10px", p: 1, flex: 1, m: "2%" }} item xs={12}>
              {isLoad ?
                <UploadFile
                  id="myFileResume"
                  keyID={1}
                  arrFile={fileUploadResumeContext || []}
                  setarrFile={setFileUploadResume}
                  nLimitFile={2} //ขนาดไฟล์ไม่เกิน 2 Mb
                  nLimitCountFile={5} //Upload ได้ 5 file
                  IsFolder={false}
                  IsFile={true}
                  IsMultiple={true}
                  Extension={[...Extension.DocumentWordPPt, ...Extension.Image]}
                  // Extension={["docx", "pptx", "doc", "ppt", "pdf", ...Extension.Image]}
                  IsDrag={false}
                  isSetupEmployer={false}
                  isSetupEmployerBanner={false}
                  isSetupEmployerLogo={false}
                  IsCanDel={isDelete}
                  CompleteCheck={true}
                  setIsFileComplete={setIsFileComplete}
                  IsFileComplete={IsFileComplete}

                />
                : null}
            </Grid>
          </AccordionDetailsCustom>
        </AccordionCustom>

      </FormProvider>
    </>
  )
}
export default WorkExperienceFile