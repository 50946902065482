import { Grid, Typography } from "@mui/material";
import { AccordionCustom, AccordionDetailsCustom, AccordionSummaryCustom } from "components/SeekerProfile/ElementCustom"
import { useState, useContext, useEffect } from "react";
import { i18n } from "i18n";
import UploadFile from "components/Common/ElementInForm/UploadFile/UploadFile";
import { ProfileContext } from "components/SeekerProfile/Context/ProfileContext";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { DialogActionCreators } from "store/redux/DialogAlert";
import { FnBlock_UI } from "service/SystemFunction/SystemFunction";
import { AxiosGet, AxiosPost, Extension } from "service/CommonFunction/TS_function";
import CardComplete from "./CardComplete";
import CardInComplete from "./CardInComplete";
import { List as LINQ } from "linqts";
import _ from "lodash";
import secureLocalStorage from "react-secure-storage";

const WorkExperienceFileTranscript = ({ isExpanded, isJobApply, sIDNew, sMode }) => {
  const navigate = useNavigate();
  const [expandedFile, setExpandedFile] = useState(false);
  const i18nField = "entities.SeekerProfile";
  const [Mode, setsMode] = useState(sMode);
  const {
    fileUploadTranscriptContext,
    setFileUploadTranscript,
    profileContext,
    isLoading,
  } = useContext(ProfileContext);

  const { BlockUI, UnBlockUI } = FnBlock_UI();
  const dispatch = useDispatch();
  const history = useNavigate();
  const [formSchema, setFormSchema] = useState<any>({});
  const schema = yup.object().shape(formSchema);
  const [ProfileImage, setProfileImage] = useState([]);
  const [isLoad, setIsLoad] = useState(true);
  const [IsFileComplete, setIsFileComplete] = useState(true);
  const [isComPlete, setisComPlete] = useState(false);
  const form = useForm({
    resolver: yupResolver(schema),
    shouldUnregister: false,
    shouldFocusError: true,
    mode: "all",
  });


  useEffect(() => {
    if (Mode === "FileTranscript") {
      let nMode = 0;
      let arrData = new LINQ<any>(fileUploadTranscriptContext).Where((w) => w.IsNew === true && w.IsComplete === true).ToArray();
      if (arrData.length > 0) {
        nMode = 1;
      }
      else if (fileUploadTranscriptContext.length < ProfileImage.length) {
        nMode = 2;
      }
      if (nMode != 0 && isLoad && IsFileComplete) {
        setIsLoad(false)
        onSubmit(nMode);
      }
    }
   
  }, [fileUploadTranscriptContext]);




  const GetMyProfileStatus = () => {
    let param = {
      nPanel: 3
    }
    AxiosPost("JobSeeker/GetMyProfileStatus", param, (res) => {
        setisComPlete(res.IsCompleted);
        
    });
};

const GetMyProfile = () => {
  AxiosGet("JobSeeker/GetMyProfileTranscripte", {}, (res) => {
    setFileUploadTranscript(res.listFileTranscript || [])
    setProfileImage(res.listFileTranscript || []);
  });
};

  useEffect(() => {
    GetMyProfileStatus();
    setExpandedFile(isExpanded);
    GetMyProfile();
  }, []);
  
  const onSubmit = (nAdd) => {
    const dataProfile = {
      listFileTranscript: fileUploadTranscriptContext,
    };
    let sMsg = "";
    if (nAdd === 1) {
      sMsg = i18n(`${i18nField}.MsgTranscript`);
    } else {
      sMsg = i18n(`${i18nField}.MsgTranscriptDelete`);
    }

    BlockUI();
    AxiosPost(`JobSeeker/SaveDataFileTranscript`, dataProfile, (result) => {
      let ProfileNoti = secureLocalStorage.getItem("ProfileNoti");
      secureLocalStorage.setItem("ProfileNoti", ProfileNoti === "true" ? "false" : "true");
      setProfileImage(result.listFileTranscript);
      if (result.StatusCode === 200) {
        setFileUploadTranscript(result.listFileTranscript || [])
        setTimeout(() => {
          setIsLoad(true)
          setIsFileComplete(true);
          UnBlockUI();
        }, 2000);
        dispatch(DialogActionCreators.OpenDialogSuccess(sMsg, () => { onGoPage(); }) as any)
      }
      GetMyProfileStatus();
    },
    (err) => {
      if (err.Message != "") {
        dispatch(
          DialogActionCreators.OpenDialogWarning(
            err.Message
          ) as any
        );
      }
    }
    );
  };

  const onGoPage = () => {
    if (isJobApply) {
      let objProfile = {
        sMode: "FileTranscript",
      };
      let objJobDetail = {
        sID: sIDNew || "",
        isApply: true,
        sFromPage: "JobDetail",
      };
      navigate("/JobApply", {
        state: {
          objProfile: objProfile,
          objJobDetail: objJobDetail
        },
      });

    } else {
      history("/MyProfile?sMode=FileTranscript");
    }
  }

  // const setDataMyProfile = () => {
  //   const data = profileContext as any;
  //   setFileUploadTranscript(data.listFileTranscript || [])
  //   setProfileImage(data.listFileTranscript || []);
  // };


  // useEffect(() => {
  //   setExpandedFile(isExpanded);
  //   if (!isLoading) {
  //     setDataMyProfile();
  //   }
  // }, [isLoading]);

 
  return (
    <FormProvider {...form}>
      <AccordionCustom
        sx={{ border: "10px", p: 1, flex: 1 }}
        expanded={expandedFile}
        onChange={() => {setExpandedFile((prev) => !prev) ;setsMode("FileTranscript")}}
      >
        <AccordionSummaryCustom>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item xs={6} sm={8} md={9}>
              <Typography >
                {i18n(`${i18nField}.Transcript`)}
              </Typography>
            </Grid>
            <Grid item container
              justifyContent="flex-end" xs={6} sm={4} md={3}>
                   <CardComplete isComPlete ={isComPlete} />
              {/* {isComPlete ? (
                <CardComplete />
              ) : (
                <CardInComplete />
              )} */}
            </Grid>
          </Grid>


        </AccordionSummaryCustom>
        <AccordionDetailsCustom>
          <Grid sx={{ border: "10px", p: 1, flex: 1, m: "2%" }} item xs={12}>
            {isLoad ? <UploadFile
              id="myFileTranscript"
              keyID={2}
              arrFile={fileUploadTranscriptContext || []}
              setarrFile={setFileUploadTranscript}
              nLimitFile={2} //ขนาดไฟล์ไม่เกิน 2 Mb
              nLimitCountFile={5} //Upload ได้ 5 file
              IsFolder={false}
              IsFile={true}
              IsMultiple={true}
              // Extension={["docx", "pptx", "doc", "ppt", "pdf", ...Extension.Image]}
              Extension={[...Extension.DocumentWordPPt, ...Extension.Image]}
              IsDrag={false}
              isSetupEmployer={false}
              CompleteCheck={true}
              setIsFileComplete={setIsFileComplete}
              IsFileComplete={IsFileComplete}
            /> : null}
          </Grid>
        </AccordionDetailsCustom>
      </AccordionCustom>
    </FormProvider>
  )
}
export default WorkExperienceFileTranscript