import React, { useEffect, useState } from 'react'
import { Modal, Box, Typography, Tooltip, InputAdornment, IconButton } from "@mui/material"
import { Close, Send, Visibility, VisibilityOff } from "@mui/icons-material"
import InputCustomLabel from 'components/Common/ElementInForm/InputCustomLabelFormItem';
import { useForm, FormProvider } from "react-hook-form"
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup"
import yupFormSchemas, { RegexpPassword } from 'components/Common/yup/yupFormSchemas';
import { BtnCustomIconWithLoading } from "components/Common/Button"
import { i18n } from 'i18n';

export default function ChangePassword({ isOpen, setOpen, funcSubmitPass, isJobSeeker, isLoading }) {
    const i18nLabel = "SetupTemplate.label";
    const [ShowCFPass, setShowCFPass] = useState(false);
    const [ShowNewPass, setShowNewPass] = useState(false);
    const [ShowOldPass, setShowOldPass] = useState(false);

    const styles = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 500,
        bgcolor: 'background.paper',
        borderRadius: "10px",
        p: 2,
        minHeight: 250
    };

    useEffect(() => {
        form.reset();
    }, [isOpen])

    const objSchema = {
        inp_oldPass: yupFormSchemas.string(i18n(`${i18nLabel}.sSecureCodeold`), { required: true }),
        inp_newPass: yupFormSchemas.string(i18n(`${i18nLabel}.UserSecureCodeNow`), {
            matches: RegexpPassword(),
            labelmatches: i18n("validation.string.sSecureCode"),
        }),
        inp_confirmPass: yupFormSchemas.string(i18n(`${i18nLabel}.confirmSecureCode`),
        ).oneOf([yup.ref("inp_newPass")], i18n("validation.string.ConSecureCode"))
    }
    const schema = yup.object().shape(objSchema);
    const formResolver = yupResolver(schema)
    const form = useForm({
        shouldFocusError: true,
        resolver: formResolver,
        defaultValues: {
            inp_oldPass: "",
            inp_newPass: "",
            inp_confirmPass: ""
        }
    });
    return (
        <Modal
            open={isOpen}
            onClose={() => console.log('close')}
            closeAfterTransition
            BackdropProps={{
                timeout: 500,
            }}
        >
            <FormProvider {...form}>
                <Box sx={{ ...styles, width: 600 }} className={" flex flex-col"} >
                    <div className="flex flex-row ">
                        <div className="flex flex-[1] justify-end">
                            <Tooltip title={i18n(`${i18nLabel}.close`)}>
                                <div onClick={() => {
                                    setOpen(false)
                                }} className='rounded-[50%] bg-[#d1d1d1] w-[1.5em] h-[1.5em] flex cursor-pointer ease-linear duration-[100ms]  hover:bg-[#8c8c8c]'>
                                    <Close fontSize='small' className='m-[auto] text-[white]' />
                                </div>
                            </Tooltip>
                        </div>
                    </div>
                    <div className="flex flex-row mb-[0.8em]">
                        <Typography variant={'caption'} className="!text-[1em] !font-[500]">
                            {i18n(`${i18nLabel}.UserSecureCodeChange`)} {/*Change Password*/}
                        </Typography>
                    </div>
                    <div style={{ marginTop: 5 }}>
                        <InputCustomLabel
                            rounded
                            name="inp_oldPass"
                            type={ShowOldPass ? "text" : "password"}
                            label={i18n(`${i18nLabel}.sSecureCodeold`)} //old password
                            onKeyPress={(e) => {
                                if (e.key === "Enter") {
                                    const objForm = form.getValues();
                                    if (!objForm.inp_oldPass) {
                                        form.setValue("inp_oldPass", '', { shouldValidate: true });
                                    } else {
                                        funcSubmitPass(objForm);
                                    }
                                }
                            }}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle p0assword visibility"
                                        edge="end"
                                        onClick={() => setShowOldPass(!ShowOldPass)}
                                    >
                                        {ShowOldPass ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    </div>
                    <div style={{ marginTop: 20, marginBottom: 20 }}>
                        <InputCustomLabel
                            rounded
                            name="inp_newPass"
                            type={ShowNewPass ? "text" : "password"}
                            label={i18n(`${i18nLabel}.UserSecureCodeNow`)} //new password                            
                            onKeyPress={(e) => {
                                if (e.key === "Enter") {
                                    const objForm = form.getValues();
                                    if (!objForm.inp_newPass) {
                                        form.setValue("inp_newPass", '', { shouldValidate: true });
                                    } else {
                                        funcSubmitPass(objForm);
                                    }
                                }
                            }}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle p0assword visibility"
                                        edge="end"
                                        onClick={() => setShowNewPass(!ShowNewPass)}
                                    >
                                        {ShowNewPass ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    </div>
                    {/* <div style={{ marginTop: 5 }}> */}
                    <div>
                        <InputCustomLabel
                            rounded
                            name="inp_confirmPass"
                            type={ShowCFPass ? "text" : "password"}
                            label={i18n(`${i18nLabel}.confirmSecureCode`)} //confirm password
                            onKeyPress={(e) => {
                                if (e.key === "Enter") {
                                    const objForm = form.getValues();
                                    if (!objForm.inp_confirmPass) {
                                        form.setValue("inp_confirmPass", '', { shouldValidate: true });
                                    } else {
                                        funcSubmitPass(objForm);
                                    }
                                }
                            }}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle p0assword visibility"
                                        edge="end"
                                        onClick={() => setShowCFPass(!ShowCFPass)}
                                    >
                                        {ShowCFPass ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    </div>
                    <div className='flex flex-row justify-center'>
                        <BtnCustomIconWithLoading txt={i18n(`${i18nLabel}.send`)} isLoading={isLoading} sxCustom={{
                            backgroundColor: "#1876d2",
                            ":hover": {
                                backgroundColor: "#104c89",
                            },
                            marginTop: 3,
                            marginBottom: 2
                        }} sIconComponent={<Send />} onClick={form.handleSubmit(funcSubmitPass)} />
                    </div>
                </Box>
            </FormProvider>
        </Modal>
    )
}
