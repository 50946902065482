import { Box } from "@mui/material";
import MemberRegister from "components/CompanyEmployee/MemberRegister";
import MemberSetting from "components/Member_ProfileSetting/MemberSetting";
import { Helmet } from 'react-helmet';


const MemberProfileSetting = () => {
  return (
    <>
      <Box style={{ display: "none !important", visibility: "hidden" }}>
        <Helmet>
          <title> ตั้งค่าข้อมูลส่วนตัว </title>
        </Helmet>
      </Box>

      <Box sx={{ width: '100%' }}>
        <MemberSetting />
      </Box>
    </>
  )
};

export default MemberProfileSetting;