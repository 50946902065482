import { Grid, Typography } from "@mui/material";
import WifiCalling3Icon from '@mui/icons-material/WifiCalling3';
import EmailIcon from '@mui/icons-material/Email';
import { IoNavigateCircle } from "react-icons/io5";

import secureLocalStorage from "react-secure-storage";
import { i18n } from "i18n";
import { AxiosPost } from "service/CommonFunction/TS_function";
import { useEffect, useState } from "react";

const BoxAddress = () => {
    const lang = secureLocalStorage.getItem("language") || "th";
    const [isEmployee, setisEmployee] = useState(false);
    const i18nField = "OrderEmployer";
    const [sEmail, setTsEmail] = useState("");
    const [sTel, setTel] = useState("");
    const [sAddress, setAddress] = useState("");
    const GetDataGuestContactInfo = () => {
        let param = {
            sID: lang
        }
        AxiosPost(`EmployerServiceBPS/GetDataGuestContactInfo`, param, (result) => {
            setTsEmail(result.sGuestContactInfoEmail);
            setTel(result.sGuestContactInfoTel);
            setAddress(result.sGuestContactInfoAddress);
            setisEmployee(result.isEmployee);
        });
    }

    useEffect(() => {
        GetDataGuestContactInfo();
    }, [])
    return (
        <>
            {!isEmployee && (
                <div className="plan-box" style={{ marginTop: "5%" }} >
                    <div className="inner-box">
                        <Grid item xs={12} >
                            {lang == "th" ? (
                                <Typography style={{ fontWeight: 400 }} className="box-address" color={"black"}>{i18n(`${i18nField}.Announce`)} <a style={{ fontWeight: 600, color: "black" }}>{i18n(`${i18nField}.SpecialDiscounts`)}</a> <a style={{ fontWeight: 600 }}>{i18n(`${i18nField}.contactus`)}</a></Typography>
                            ) : (
                                <>
                                    <Typography style={{ fontWeight: 400 }} className="box-address" color={"black"}>{i18n(`${i18nField}.Announce`)} <a style={{ fontWeight: 600, color: "black" }}>{i18n(`${i18nField}.SpecialDiscounts`)}</a> <a style={{ fontWeight: 400, color: "black" }}>{i18n(`${i18nField}.please`)}</a> <a style={{ fontWeight: 600 }}>{i18n(`${i18nField}.contactus`)}</a></Typography>
                                </>
                            )}
                        </Grid>
                        <Grid container sx={{ mt: "1%" }}>
                            <Grid item >
                                <EmailIcon className={"icon-email"}style={{ color: "#259bdd", fontSize: "15px" }} />
                            </Grid>
                            <Grid item >
                                <Typography className="box-address" style={{ marginLeft: "15px", marginTop: "5px" }} color={"#989ea1"}>{i18n(`${i18nField}.Email`)}<a style={{ marginLeft: "3px", color: "#259bdd", textDecoration: "underline", marginTop: "7px" }}>{sEmail}</a></Typography>
                            </Grid>
                        </Grid>
                        <Grid container  >
                            <Grid item >
                                <WifiCalling3Icon className={"icon-address"}style={{ color: "#259bdd", fontSize: "15px"}} />
                            </Grid>
                            <Grid item md={11} xs={11} >
                                <Typography style={{ marginLeft: "15px" }} className="box-address" color={"#989ea1"}> {i18n(`${i18nField}.Tel`)} {sTel}</Typography>
                            </Grid>
                        </Grid >
                        <Grid container  >
                            <Grid item >
                                <IoNavigateCircle  className={"icon-address"} style={{ color: "#259bdd", fontSize: "15px",  marginTop: "3px" }} />
                            </Grid>
                            <Grid item md={11} sm={11} xs={11} >
                                <Typography style={{ marginLeft: "15px"}} className="box-address" color={"#989ea1"}> {i18n(`${i18nField}.Address`)} {sAddress} </Typography>
                            </Grid>
                        </Grid >
                    </div>
                </div >
            )}

        </>
    );
}
export default BoxAddress