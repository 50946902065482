import { createSelector } from 'reselect';

const selectRaw = (state) => state.azureReducer;

const getState = createSelector(
    [selectRaw],
    (azureReducer) => azureReducer.AuthenticateionState,
);

const getUser = createSelector(
    [selectRaw],
    (azureReducer) => azureReducer.userData,
);

const getConfig = createSelector(
    [selectRaw],
    (azureReducer) => azureReducer.config,
);

const getProviderMsal = createSelector(
    [selectRaw],
    (azureReducer) => azureReducer.ProviderMsal
)

const AzureSelector = {
    selectRaw,
    getState,
    getUser,
    getConfig,
    getProviderMsal
}
export default AzureSelector;