import React, { useEffect, useState } from "react";
import {
  DataGridMui as Table,
  initRows,
  PaginationInterface,
  FilterFieldInterface
} from "components/Common/Table/DataGridMui";
import { GridColumns } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { BtnEditOnTable, BtnViewOnTable } from "components/Common/Button"
import { useDispatch } from "react-redux"
import { AxiosPost, GetPermission, ResultAPI } from "service/CommonFunction/TS_function";
import { DialogActionCreators } from "store/redux/DialogAlert";
import { useForm } from "react-hook-form";
import { i18n, getLanguage } from "i18n";
import IOSSwitch from "components/Common/ElementInForm/IosSwitch";
import { FormControlLabel, Grid } from "@mui/material";
import secureLocalStorage from 'react-secure-storage';


export default function AdminCondidateMasterTable() {
  const i18nField = 'entities.AdminCandidate'
  const dispatch = useDispatch();
  const history = useNavigate();
  const [nPermission, setPermission] = useState(0);
  const [loadingTable, setLoadingTable] = useState(true);

  let arrrow = {
    ...initRows,
    sSortExpression: "sUpdate_Date",
    sSortDirection: "desc",
  }
  const sPathName = window.location.pathname;
  const sStore = secureLocalStorage.getItem(sPathName);
  if (sStore && sStore !== "") {
    const objRowTemp = JSON.parse(sStore + "");
    if (objRowTemp != null) {
      arrrow = objRowTemp;
      arrrow.arrRows = [];
    }
  }
  const [dataRow, setDataRow] = useState<PaginationInterface>(arrrow);
  // const [dataRow, setDataRow] = useState<PaginationInterface>({
  //   ...initRows,
  //   sSortExpression: "sUpdate_Date",
  //   sSortDirection: "desc"
  // });


  //const isHead = nBanner_ID == 6 || nBanner_ID == 8 ? true : false;
  const onBack = () => {
    history(-1)
  }

  useEffect(() => {
    GetPermission(8, setPermission)
    loadData(dataRow);

  }, []);

  const loadData = (p: PaginationInterface) => {
    setLoadingTable(true)
    AxiosPost("AdminCandidate/SearchData_JobSeeker", p, (result) => {
      setLoadingTable(false)

      setDataRow({
        ...p,
        arrRows: result.lstAdminJobSeeker || [],
        nDataLength: result.nDataLength,
        nPageIndex: result.nPageIndex,
      });
    });
  };
  const OnStatus = (e, item) => {
    let arrRowcopy = [...dataRow.arrRows]
    let sStatust = arrRowcopy.filter(f => f.nJobSeeker_ID == item.row.nJobSeeker_ID)[0];
    if (sStatust) {
      sStatust.sStatus = e.target.checked
    }

    let oData = {
      sStatus: sStatust.sStatus,
      nJobSeeker_ID: item.row.nJobSeeker_ID
    }

    AxiosPost("AdminCandidate/ToggleActive", oData, (result) => {
      ResultAPI(result, "Saved successfully.", () => {
        loadData(dataRow);
      });
    }, (err) => {
      if (!err.response) {
        history("/error", { replace: true })
        return;
      }
    });
  }

  // filter
  const filter: FilterFieldInterface[] = [
    { sTypeFilterMode: "input", sFieldName: "sJobSeeker_No" },
    { sTypeFilterMode: "input", sFieldName: "sFullNameTH" },
    { sTypeFilterMode: "input", sFieldName: "sEmail" },
    { sTypeFilterMode: "input", sFieldName: "sTel" },
    { sTypeFilterMode: "daterange", sFieldName: "sUpdate_Date" },
    {
      sTypeFilterMode: "select", sFieldName: "sStatus", lstDataSelect: [
        { value: "0", keyId: 0, label: i18n(`${i18nField}.ActiveName`) },
        { value: "1", keyId: 1, label: i18n(`${i18nField}.InactiveName`) },
      ],
    },
  ]

  //สร้างตาราง
  const dataColumn: GridColumns = [
    {
      headerName: "",
      field: "ปุ่ม (ดูรายละเอียด)",
      type: "actions",
      width: 70,
      resizable: false,
      sortable: false,
      getActions: (item) => {
        return [
          (
            <BtnViewOnTable
              txt={"ดูรายละเอียด"}
              onClick={() => goToMasterData(item.row.sID, item.row)}
            />
          )
        ];
      },
    },
    {
      field: "sJobSeeker_No",
      headerName: "หมายเลขผู้สมัคร", //หมายเลขผู้สมัคร
      headerAlign: "center",
      align: "center",
      resizable: false,
      flex: 1,
      minWidth: 300,
    },
    {
      field: "sFullNameTH",
      headerName: "ชื่อผู้สมัครงาน", //ชื่อผู้สมัครงาน
      headerAlign: "center",
      align: "left",
      resizable: false,
      flex: 1,
      minWidth: 300,
    },
    {
      field: "sEmail",
      headerName: "อีเมล", //อีเมล
      headerAlign: "center",
      align: "left",
      resizable: false,
      flex: 1,
      minWidth: 300,
    },
    {
      field: "sTel",
      headerName: "เบอร์ติดต่อ", //เบอร์ติดต่อ
      headerAlign: "center",
      align: "center",
      resizable: false,
      flex: 1,
      minWidth: 180,
    },
    {
      field: "sUpdate_Date",
      headerName: "วันทีปรับปรุง", //วันทีปรับปรุง
      headerAlign: "center",
      align: "center",
      resizable: false,
      flex: 1,
      minWidth: 180,
    },
    {
      field: "sStatus",
      headerName: "สถานะ",
      headerAlign: "center",
      align: "center",
      resizable: false,
      flex: 1,
      minWidth: 120,
    }
  ];

  const goToMasterData = (sID: string, item: any) => {
    // window.localStorage.setItem("sSubRouteName", item.sSection);
    history(`/AdminCandidate?sID=${encodeURI(sID)}`);
  };

  return (

    <Table
      isLoading={loadingTable}
      filterField={filter}
      Rows={dataRow}
      Columns={dataColumn}
      OnLoadData={(p) => loadData(p)}
    //isShowCheckBox={nPermission == 2}
    //onDelete={OnDelete}
    />

  );
}