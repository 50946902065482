import { useState, useEffect } from 'react';
import {
  Stack, Typography,
  SxProps, CardMedia, Container,
  Grid
} from "@mui/material"
import { BtnBack } from "components/Common/Button";
import { useNavigate } from "react-router-dom";
import ModalViewImage from "components/Common/ModalViewImage/ModalViewImage"
import MailOutlinedIcon from '@mui/icons-material/Mail';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookSquare, faLine } from '@fortawesome/free-brands-svg-icons';
import { AxiosGet } from 'service/CommonFunction/TS_function';
import { getLanguage } from 'i18n';
import ParseHTML from "html-react-parser";

const objStyle = {
  w_600: {
    fontWeight: 600,
  } as SxProps,
  w_500: {
    fontWeight: 500,
  } as SxProps,
  fs_12: {
    marginTop: "1em",
    fontWeight: 400,
  } as SxProps,
  text_date: {
    color: "#1ca5fc",
    fontSize: "0.8em",
  } as SxProps,
  image_cover: {
    display: "flex", flex: 1, minHeight: 400, backgroundSize: "cover"
  } as SxProps,
  container: {
    " > div": {
      marginTop: "2rem",
    }
  } as SxProps,
  youtube_style: {
    width: "60vw;",
    height: "calc((60vw)*9/16);",
    maxHeight: "100vw",
    maxWidth: "100%",
    " @media (max-width:767px) ": {
      width: "80vw;",
      height: "calc((80vw)*9/16);",
    }
  } as SxProps,
  row_tag: {
    padding: "0px 1rem",
    marginTop: "5px"
  } as SxProps,
  img_list: {
    minWidth: "20vw",
    maxWidth: "30vw",
    minHeight: "85%",
    maxHeight: "25vh",
    padding: "5px 10px",
    paddingTop: "10px",
    textAlign: "center",
    display: 'flex',
    justifyContent: "center",
    " @media (max-width:1100px) ": {
      minWidth: "15vw",
      minHeight: "90%",
      maxWidth: "25vw",
    },
    " @media (max-width:767px) ": {
      minWidth: "30vw",
      minHeight: "90%",
      maxWidth: "50vw",
    },
  } as SxProps
}

const ContentPage = ({ lstContent, isCanBack = false, OrderEmp, sContendHead }) => {
  let history = useNavigate();
  const [isViewImage, setIsViewImage] = useState(false);
  const [imageView, setImageView] = useState("");
  const [sTermsFooter, setTermsFooter] = useState("");

  useEffect(() => {
    getTermsFooter();
  }, [])

  const getTermsFooter = () => {
    let lang = "";
    let isTH = (getLanguage().id === "th");
    isTH ? lang = "th" : lang = "en"
    AxiosGet("Config/GetData_TermsAndConditionFooter", { lang }, (res) => {
      setTimeout(() => {
        setTermsFooter(res.TermsFooter);
      }, 200);
    });
  }

  return (
    <>
      <ModalViewImage
        image={imageView}
        isOpen={isViewImage}
        handleClose={() => setIsViewImage(false)}
      />
      <Container sx={objStyle.container}>
        {sContendHead && sContendHead.map((item, key) => {
          return (
            <Grid item xs={12}>
              <Typography sx={{ fontWeight: 900, fontSize: "18px" }}>{item.sPageName}</Typography>
            </Grid>
          )
        })}
        {lstContent.map((item, key) => {
          switch (item.nType) {
            case 1:
              return (
                <Stack key={`type_1_${key}`} direction="row">
                  <Grid container>
                    {/* <Grid item xs={12}>
                      <Typography sx={{ fontWeight: 900, fontSize: "18px" }}>{item.sPageName}</Typography>
                    </Grid> */}
                    <Grid item xs={12}>
                      <div>{ParseHTML(item.sContent)}</div>
                    </Grid>
                  </Grid>
                </Stack>
              )
            case 2:
              return (
                <Stack key={`type_2_${key}`} direction="row" >
                  <Stack direction="column" alignItems="center" flex={1}>
                    <CardMedia onClick={() => {
                      setImageView(item.sFileNameSys)
                      setIsViewImage(true)
                    }} component={"img"} sx={{
                      objectFit: "contain",
                      width: "auto",
                      maxWidth: "100%",
                      maxHeight: "calc(90vh - 45px)",
                      cursor: "pointer"
                    }} src={item.sFileNameSys} />
                    <Typography sx={objStyle.fs_12} variant="body1" >{item.sContent}</Typography>
                  </Stack>
                </Stack>
              )
          }
        })}
      </Container >
      {
        isCanBack === true && <Stack direction="row" sx={{ marginTop: "0px", marginLeft: "10%", paddingBottom: "10px" }}>
          <BtnBack onClick={() => history(-1)} />
        </Stack>
      }

      {OrderEmp ?
        // <Grid container sx={{ backgroundColor: "#019dd4", marginTop: "7%" }}>
        <Grid container sx={{ backgroundColor: "#019dd4", marginTop: "7%", bottom: 0 }}>
          <Grid item md={12} sm={12} xs={12} sx={{ textAlign: "center", color: "white", marginTop: "1%" }}>
            {sTermsFooter}
            {/* Business Professional Solutions Recruitment Co.,Ltd. (BPS) 555/1 Energy Complex, Building A,10 th floor,Vibhavadi Rangsit Road,Chatuchak,Bangkok 10900 /Tel : +662 140 3158-60 or +662 140 3191-92 */}
          </Grid>

          {/* Social Media */}
          <Grid item md={12} sm={12} xs={12} sx={{ textAlign: "center", marginTop: "10px" }}>
            <a
              href="https://page.line.me/279yxnan?openQrModal=true/"
              target="_blank"
              rel="noreferrer"
            >
              <FontAwesomeIcon
                icon={faLine}
                size="2x"
                style={{ color: "white" }}
              />
              &nbsp;&nbsp;&nbsp;&nbsp;
            </a>
            <a
              href="https://www.facebook.com/job.pttbps/"
              target="_blank"
              rel="noreferrer"
            >
              <FontAwesomeIcon
                icon={faFacebookSquare}
                size="2x"
                style={{ color: "white" }}
              />
              &nbsp;&nbsp;&nbsp;&nbsp;
            </a>
            <a
              href="mailto:center_bps@bpscorporate.com"
              target="_blank"
              rel="noreferrer"
            >
              <MailOutlinedIcon
                style={{ color: "white", fontSize: 40, marginTop: "-15px" }}
              />
              &nbsp;&nbsp;&nbsp;&nbsp;
            </a>
          </Grid>
        </Grid> : null
      }
    </>
  );
}
export default ContentPage;