import { Box, Stack } from "@mui/material";
import AdminOrderDetailCom from "../../../components/AdminWorkflow/AdminOrder/AdminOrderDetail";
import { Helmet } from 'react-helmet';

export default function AdminSpecialDetail() {

  return (
    <>
      <Box sx={{ display: "none !important", visibility: "hidden" }}>
        <Helmet>
          <title> อนุมัติใบสั่งซื้อกรณีพิเศษ </title>
        </Helmet>
      </Box>


      <Stack sx={{ mx: "1%" }}>
        <AdminOrderDetailCom nType_ID={1} SpecialEdit={true} />
      </Stack>
    </>
  );
}