import {
  ButtonBase,
  Grid,
  Pagination,
  Paper,
  styled,
  Tooltip,
  Typography,
} from "@mui/material";
import { useState } from "react";
import LogoBPS from "assets/images/BPS-Logo.png";
// import LogoBPS from "ClientApp/public/wwwroot/UploadFile/images/BPS-Logo.png"
import EventNoteIcon from "@mui/icons-material/EventNote";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import EventBusyIcon from "@mui/icons-material/EventBusy";

const BoxJobItem = () => {
  const Img = styled("img")({
    margin: "auto",
    display: "block",
    maxWidth: "100%",
    maxHeight: "100%",
  });
  const items = Array.from(Array(20).keys());
  const [objPaging, setPaging] = useState({
    nPage: 1,
    nPageSize: 10,
    nTotal: 200,
  });
  return (
    <>
      <Grid container spacing={3}>
        {items.map((item) => (
          <Grid item xs={12} lg={6} xl={6} key={item}>
            <Paper
              sx={{
                p: 2,
                margin: "auto",
                // maxWidth: 500,
                flexGrow: 1,
                backgroundColor: (theme) =>
                  theme.palette.mode === "dark" ? "#1A2027" : "#fff",
              }}
            >
              <Grid container spacing={2}>
                <Grid item sx={{ flexGrow: 1 }} xs container>
                  <Grid item xs container direction="column" spacing={2}>
                    <Grid item xs>
                      <Typography variant="subtitle1" component="div">
                        Softthai application Co.,Ltd
                      </Typography>
                      <Typography variant="body2">
                        กรุงเทพมหานคร, ดินแดง
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography
                        gutterBottom
                        variant="body2"
                        color="text.secondary"
                      >
                        ประกาศปัจจุบัน 5 ตำแหน่ง
                      </Typography>
                      <Typography variant="body2">
                        ดูข้อมูลเมื่อ 12/06/2022
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item>
                  <Grid container direction="column" spacing={2}>
                    <Grid item textAlign={"right"}>
                      <Tooltip title="ADD" placement="top">
                        <ButtonBase>
                          <EventNoteIcon htmlColor="#1A2027" />
                        </ButtonBase>
                      </Tooltip>
                      <Tooltip title="UPDATE" placement="top">
                        <ButtonBase>
                          <EventAvailableIcon htmlColor="#D0E17D" />
                        </ButtonBase>
                      </Tooltip>
                      <Tooltip title="DELETE" placement="top">
                        <ButtonBase>
                          <EventBusyIcon htmlColor="#ed3847" />
                        </ButtonBase>
                      </Tooltip>
                    </Grid>
                    <Grid item textAlign={"right"}>
                      <ButtonBase sx={{ width: 86, height: 86 }}>
                        <Img alt="complex" src={LogoBPS} />
                      </ButtonBase>
                    </Grid>
                    <Grid item>
                      {Array.from(Array(2).keys()).map((item2) => (
                        <Typography
                          key={item2}
                          component="p"
                          bgcolor={"#D0E17D"}
                          variant="body2"
                          color="text.secondary"
                          sx={{ marginBottom: "2px", padding: "0 5px 0 5px" }}
                        >
                          พนักงานประจำ
                        </Typography>
                      ))}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        ))}
      </Grid>
      {items.length > objPaging.nPageSize && (
        <Grid container justifyContent={"center"} sx={{ marginTop: "3rem" }}>
          <Grid item>
            <Pagination
              variant="outlined"
              shape="rounded"
              showFirstButton
              showLastButton
              count={Math.ceil(objPaging.nTotal / objPaging.nPageSize)}
              page={objPaging.nPage}
              onChange={(e, page) => setPaging({ ...objPaging, nPage: page })}
            />
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default BoxJobItem;
