import { useEffect, useContext, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Grid, Box, Typography, Stack } from "@mui/material";
import { getLanguage, i18n } from "i18n";
import { ProfileContext } from "components/SeekerProfile/Context/ProfileContext";
import AutoCompleteSelect from "components/Common/ElementInForm/AutoCompleteSelect";
import { IAddress } from "./Interface";
import { AxiosGet, AxiosPost } from "service/CommonFunction/TS_function";
import Input from "components/Common/ElementInForm/InputCustomLabelFormItem";
import yupFormSchemas from "components/Common/yup/yupFormSchemas";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { AccordionCustom, AccordionDetailsCustom, AccordionSummaryCustom } from "./ElementCustom";
import { BtnBack, BtnEditProfile, BtnSubmit } from "components/Common/Button";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { DialogActionCreators } from "store/redux/DialogAlert";
import { FnBlock_UI } from "service/SystemFunction/SystemFunction";
import CardComplete from "./CardComplete";
import CardInComplete from "./CardInComplete";
import secureLocalStorage from "react-secure-storage";
const i18nField = "entities.SeekerProfile";
const i18nCommon = "common";

const MyAddressNew = ({ Edit, isExpanded, isJobApply, sIDNew ,onBackEdit}) => {
  const [IsInterMain, setIsInterMain] = useState(false);
  const [expandedAddress, setExpandedAddress] = useState(false);
  const [addressCheck, setaddressCheck] = useState(null);
  const formSchema = {
    Country: yupFormSchemas.object(i18n(`${i18nField}.Country`), { required: true, }),
    Region: yupFormSchemas.object(i18n(`${i18nField}.Region`), { required: IsInterMain, }),
    Province: yupFormSchemas.object(i18n(`${i18nField}.Province`), { required: IsInterMain, }),

    //Address
    District: yupFormSchemas.object(i18n(`${i18nField}.District`), { required: false, }),
    SubDistrict: yupFormSchemas.object(i18n(`${i18nField}.SubDistrict`), { required: false, }),
    AddressNo: yupFormSchemas.string(i18n(`${i18nField}.AddressNo`), { required: false, }),
    Moo: yupFormSchemas.string(i18n(`${i18nField}.Moo`), { required: false, }),
    Street: yupFormSchemas.string(i18n(`${i18nField}.Street`), { required: false, }),
    PostCode: yupFormSchemas.string(i18n(`${i18nField}.PostCode`), { required: false, }),
  };

  const schema = yup.object().shape(formSchema);
  const form = useForm({
    resolver: yupResolver(schema),
    shouldUnregister: false,
    shouldFocusError: true,
    mode: "all",
  });

  const {
    addresssContext,
    setAddress,
    optionCountry,
    setOptionCountry,
    optionReion,
    setOptionRegion,
    optionProvice,
    setOptionProvince,
    optionDistrict,
    optionSubDistrict,
    isMyAddressEdit,
    setIsMyAddressEdit,
    profileContext,
    isLoading
    // IsNotShowDetail,
  } = useContext(ProfileContext);

  const [rawCountry, setRawCountry] = useState([]);
  const [rawReion, setRawnRegion] = useState([]);
  const [rawProvince, setRawnProvince] = useState([]);
  const navigate = useNavigate();


  const [IsInter, setIsInter] = useState(false);
  const [ProvinceParent, setProvinceParent] = useState(0);
  const dispatch = useDispatch();
  const history = useNavigate();
  const { BlockUI, UnBlockUI } = FnBlock_UI();

  const [isComPlete, setisComPlete] = useState(false);

  const GetMyProfileStatus = () => {
    let param ={
      nPanel:6
     }
     AxiosPost("JobSeeker/GetMyProfileStatus", param, (res) => {
      setisComPlete(res.IsCompleted);

    });
  };

  useEffect(() => {
    GetMyProfileStatus();

}, [])

  const handleChangeValue = (form) => {
    setAddress({
      Country: form.getValues("Country"),
      Region: form.getValues("Region"),
      Province: form.getValues("Province"),
      District: form.getValues("District"),
      SubDistrict: form.getValues("SubDistrict"),
      AddressNo: form.getValues("AddressNo"),
      Moo: form.getValues("Moo"),
      Street: form.getValues("Street"),
      PostCode: form.getValues("PostCode"),
    });
  };

  const fetchCountry = () => {
    AxiosGet("DataCollect/GetCountry", {}, (res) => {
      setRawCountry(res.Data);
    });
  };
  const fetchRegion = () => {
    AxiosGet("DataCollect/GetRegion", {}, (res) => {
      setRawnRegion(res.Data);
    });
  };
  const fetchProvince = () => {
    let lang = "";
    let isTH = (getLanguage().id === "th");
    isTH ? lang = "th" : lang = "en"
    AxiosGet(`DataFilter/SetFilter_SelectProvince`, { lang }, (res) => {
      setRawnProvince(res.lstOptionAdmin);
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      await fetchCountry();
      await fetchRegion();
      await fetchProvince();
    };
    fetchData();

  }, [IsInterMain]);

  const handleEditWorkUnit = () => {
    let sJobApply = "";
    let sID = "";
    if (isJobApply) {
      sJobApply = "sJobApply";
      sID = sIDNew;
    }
    history(`/MyProfileEdit?sMode=MyAddress&sJobApply=${sJobApply}&sIDNew=${sID}`);
  }

  const onBack = () => {
    setIsMyAddressEdit(false);
    if (isJobApply) {
      let objProfile = {
        sMode: "MyAddress",
      };
      let objJobDetail = {
        sID: sIDNew || "",
        isApply: true,
        sFromPage: "JobDetail",
      };
      onBackEdit(objProfile,objJobDetail);

    } else {
      history(`/MyProfile?sMode=MyAddress`);
    }
  }
  const onGoPage = () => {
    if (isJobApply) {
      let objProfile = {
        sMode: "MyAddress",
      };
      let objJobDetail = {
        sID: sIDNew || "",
        isApply: true,
        sFromPage: "JobDetail",
      };
      onBackEdit(objProfile,objJobDetail);
     
    } else {
      history(`/MyProfile?sMode=MyAddress`);

    }
  }

  useEffect(() => {
    setIsMyAddressEdit(Edit);
    setExpandedAddress(isExpanded);
  }, [])

  const setDataMyProfile = () => {
    const data = profileContext as any;
    setaddressCheck(data.nCountryId)
    const selectCountry = optionCountry.find(
      (f: any) => f.value === data.nCountryId
    );
    const selectRegion = optionReion.find(
      (f: any) => f.value === data.nRegionId
    );
    const selectProvince = optionProvice.find(
      (f: any) => f.value === data.nProvince
    );
    const selectDistrict = optionDistrict.find(
      (f: any) => f.value === data.nDistrictId
    );
    const selectSubDistrict = optionSubDistrict.find(
      (f: any) => f.value === data.nSubDistrictId
    );
    const address = {
      Country: selectCountry,
      Region: selectRegion,
      Province: selectProvince,
      District: selectDistrict,
      SubDistrict: selectSubDistrict,
      AddressNo: data.sAddressNo || "",
      Moo: data.sMoo || "",
      Street: data.sStreet || "",
      PostCode: data.sPostCode || "",
    } as IAddress;

    setAddress(address);
    if (data.nCountryId > 0) {
      form.setValue("Country", selectCountry);
      form.setValue("Region", selectRegion);
      form.setValue("Province", selectProvince);
      form.setValue("District", selectDistrict);
      form.setValue("SubDistrict", selectSubDistrict);
      form.setValue("AddressNo", data.sAddressNo);
      form.setValue("Moo", data.sMoo);
      form.setValue("Street", data.sStreet);
      form.setValue("PostCode", data.sPostCode);

      setIsInter(data.nCountryId !== 519);
      setProvinceParent(data.nRegionId);
    }
  };



  useEffect(() => {
    if (!isLoading) {
      setDataMyProfile();
    }
  }, [isLoading]);


  const onSubmit = (values) => {
    const dataProfile = {
      nCountryId: addresssContext.Country && (addresssContext.Country["value"] || 0),
      nRegionId: addresssContext.Region && (addresssContext.Region["value"] || null),
      nProvince: addresssContext.Province && (addresssContext.Province["value"] || null),
      nDistrictId: addresssContext.District && (addresssContext.District["value"] || null),
      nSubDistrictId: addresssContext.SubDistrict && (addresssContext.SubDistrict["value"] || null),
      sAddressNo: addresssContext.AddressNo || "",
      sMoo: addresssContext.Moo || "",
      sStreet: addresssContext.Street || "",
      sPostCode: addresssContext.PostCode || "",
      nMode: 6,
    };

    dispatch(
      DialogActionCreators.OpenDialogSubmit(i18n(`${i18nField}.ConfirmMsgUpdate`), () => {
        dispatch(DialogActionCreators.CloseDialogSubmit() as any);
        dispatch(DialogActionCreators.LoadSubmit("Waiting...") as any);
        BlockUI();
        AxiosPost(`JobSeeker/UpdateProfile`, dataProfile, (result) => {
          UnBlockUI();
          let ProfileNoti = secureLocalStorage.getItem("ProfileNoti");
          secureLocalStorage.setItem("ProfileNoti", ProfileNoti === "true" ? "false" : "true");
          const res = result.Data;
          if (res.IsSuccess) {
            dispatch(DialogActionCreators.OpenDialogSuccess(i18n(`${i18nField}.MsgPanelAddress`), () => { onGoPage(); })
            );
          }
        },
          (err) => {
            if (!err.response) {
              history("/error");
              return;
            }
          }
        );
      }
      ) as any
    );
  };

  return (
    <>
      <FormProvider {...form}>
        <AccordionCustom
          sx={{ border: "10px", p: 1, flex: 1 }}
          expanded={expandedAddress}
          onChange={() => setExpandedAddress((prev) => !prev)}
        >
          <AccordionSummaryCustom>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item xs={6} sm={8} md={9}>
                <Typography >
                  {i18n(`${i18nField}.PanelAddress`)}<span style={{ color: "red" }}> *</span>
                </Typography>
              </Grid>
              <Grid item container
                justifyContent="flex-end" xs={6} sm={4} md={3}>
                {/* {isComPlete ? (
                  <CardComplete />
                ) : (
                  <CardInComplete />
                )} */}
                   <CardComplete isComPlete ={isComPlete} />
              </Grid>
            </Grid>

            {/* <Typography className= {isJobApply &&  !isMyAddressEdit ? "container-card" : isMyAddressEdit ?"container-cardProEdit":"container-cardPro"}>
              {i18n(`${i18nField}.PanelAddress`)}<span style={{ color: "red" }}> *</span>
            </Typography>
            {addressCheck ? (
              <CardComplete />
            ) : (
              <>
                <CardInComplete />
              </>
            )} */}
          </AccordionSummaryCustom>
          <AccordionDetailsCustom>
            <Box sx={{ border: "10px", p: 1, flex: 1, m: "2%" }}>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                spacing={1}
              >
                <Grid item container spacing={2} xs={12}>
                  <Grid item md={4} xs={12}>
                    <AutoCompleteSelect
                      name={"Country"}
                      fullWidth
                      label={i18n(`${i18nField}.Country`)}
                      options={optionCountry}
                      required
                      disabled={!isMyAddressEdit}
                      onChange={(value) => {
                        if (value && value.value !== 519) {
                          setIsInterMain(false);
                          setIsInter(true);
                          form.clearErrors("Region");
                          form.clearErrors("Province");
                          form.setValue("Region", null);
                          form.setValue("Province", null);
                          form.setValue("AddressNo", null);
                          form.setValue("Moo", null);
                          form.setValue("Street", null);
                          form.setValue("PostCode", null);
                          setAddress({
                            ...addresssContext,
                            Region: null,
                            Province: null,
                          });
                        } else {
                          setIsInter(false);
                          setIsInterMain(true);
                          form.clearErrors("Region");
                          form.clearErrors("Province");
                          form.setValue("Region", null);
                          form.setValue("Province", null);
                          form.setValue("AddressNo", null);
                          form.setValue("Moo", null);
                          form.setValue("Street", null);
                          form.setValue("PostCode", null);
                          setAddress({
                            ...addresssContext,
                            Region: null,
                            Province: null,
                          });
                        }
                        form.setValue("Country", value);
                        handleChangeValue(form);
                      }
                      }
                    />
                  </Grid>
                  {IsInter ? null : (
                    <>
                      <Grid item md={4} xs={12}>
                        <AutoCompleteSelect
                          name={"Region"}
                          required
                          fullWidth
                          label={i18n(`${i18nField}.Region`)}
                          options={optionReion.filter((f) => f.value !== 1779)}
                          disabled={!isMyAddressEdit}
                          onChange={(v) => {
                            if (v && v.value !== "Region") {
                              form.clearErrors("Province");
                              form.setValue("Province", 0);
                            } else {
                              form.clearErrors("Province");
                              form.setValue('Province', v ? v.value : 0);
                              setIsInterMain(true);
                            }
                            handleChangeValue(form);
                            setProvinceParent(v ? v.value : 0);
                          }}
                        />
                      </Grid>
                      <Grid item md={4} xs={12}>
                        <AutoCompleteSelect
                          name={"Province"}
                          required
                          fullWidth
                          label={i18n(`${i18nField}.Province`)}
                          options={rawProvince.filter((f) => f.sParent == ProvinceParent)}
                          disabled={!isMyAddressEdit}
                          onChange={(v) => {
                            form.setValue("Province", v ? v : null);
                            setIsInterMain(true);
                            handleChangeValue(form);
                          }}
                        />
                      </Grid>
                      <Grid
                        item
                        md={2}
                        xs={12}
                      // sx={IsNotShowDetail && { display: "none" }}
                      >
                        <Input
                          name={"PostCode"}
                          fullWidth
                          maxLength={5}
                          small={true}
                          label={
                            <Typography sx={{ fontWeight: 400 }} component="label">
                              {i18n(`${i18nField}.PostCode`)}
                            </Typography>
                          }
                          onBlur={(value) => {
                            handleChangeValue(form);
                          }}
                          disabled={!isMyAddressEdit}
                        />
                      </Grid>
                      <Grid
                        item
                        md={6}
                        xs={12}
                      >
                        <Input
                          name={"Street"}
                          fullWidth
                          maxLength={500}
                          small={true}
                          label={
                            <Typography sx={{ fontWeight: 400 }} component="label">
                              {i18n(`${i18nField}.Street`)}
                            </Typography>
                          }
                          onBlur={(value) => {
                            handleChangeValue(form);
                          }}
                          disabled={!isMyAddressEdit}
                        />
                      </Grid>
                      <Grid
                        item
                        md={2}
                        xs={12}
                      >
                        <Input
                          name={"Moo"}
                          fullWidth
                          maxLength={3}
                          small={true}
                          label={
                            <Typography sx={{ fontWeight: 400 }} component="label">
                              {i18n(`${i18nField}.Moo`)}
                            </Typography>
                          }
                          onBlur={(value) => {
                            handleChangeValue(form);
                          }}
                          disabled={!isMyAddressEdit}
                        />
                      </Grid>
                      <Grid
                        item
                        md={2}
                        xs={12}
                      >
                        <Input
                          name={"AddressNo"}
                          fullWidth
                          maxLength={20}
                          small={true}
                          label={
                            <Typography sx={{ fontWeight: 400 }} component="label">
                              {i18n(`${i18nField}.AddressNo`)}
                            </Typography>
                          }
                          onBlur={(value) => {
                            handleChangeValue(form);
                          }}
                          disabled={!isMyAddressEdit}
                        />
                      </Grid>
                    </>
                  )}
                  <Grid item md={8} xs={12}></Grid>
                </Grid>
              </Grid>
            </Box>
            {!isMyAddressEdit && (
              <Stack
                sx={{ border: "10px", p: 1, flex: 1, m: "2%" }}
                direction="row"
              >
                <BtnEditProfile
                  txt={i18n(`${i18nField}.Edit`)}
                  onClick={handleEditWorkUnit}
                />
              </Stack>
            )}
          </AccordionDetailsCustom>
        </AccordionCustom>
        {isMyAddressEdit && (
          <Stack
            spacing={1}
            sx={{ border: "10px", p: 1, flex: 1, mt: "2%" }}
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <BtnBack
              onClick={() => { onBack() }}
              txt={i18n(`${i18nCommon}.back`)}
            />
            <BtnSubmit
              onClick={form.handleSubmit(onSubmit, (err) => console.log("error", err))}
              txt={i18n(`${i18nCommon}.save`)}
            />
          </Stack>
        )}
      </FormProvider>
    </>
  );
};

export default MyAddressNew;