import React, { useEffect, useState } from 'react'
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import WorkIcon from '@mui/icons-material/Work';
import SchoolIcon from '@mui/icons-material/School';
import RoomIcon from '@mui/icons-material/Room';
import BadgeIcon from '@mui/icons-material/Badge';
import StarIcon from '@mui/icons-material/Star';
import EmailIcon from '@mui/icons-material/Email';
import ContactPageIcon from '@mui/icons-material/ContactPage';
import { i18n, getLanguage } from 'i18n';
import moment from 'moment';
import { AxiosPost } from 'service/CommonFunction/TS_function';
import LockIcon from '@mui/icons-material/Lock';
import CloseIcon from '@mui/icons-material/Close';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { useDispatch } from 'react-redux';
import { DialogActionCreators } from 'store/redux/DialogAlert';
import SearchIcon from '@mui/icons-material/Search';
import { Link } from 'react-router-dom';
import { Box, Button, Checkbox, Grid, Hidden, Tooltip } from "@mui/material"
import { JobPostSentFormTable, TabFilterJobseekerCandidate } from 'service/CommonInterface/CommonInterface';
import LabelIcon from '@mui/icons-material/Label';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import InfoIcon from '@mui/icons-material/Info';
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import InfomationJobSeeker from './InfomationJobSeeker';
import FactoryIcon from '@mui/icons-material/Factory';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import BlockIcon from '@mui/icons-material/Block';
import secureLocalStorage from 'react-secure-storage';
import { HiDocumentText } from "react-icons/hi";

export const th = {
  exp_low: "ประสบการณ์การทำงานน้อยกว่า ",
  exp_hight: "ประสบการณ์การทำงานมากกว่า ",
  exp_year: "ปี",
  live_in_pre: "อาศัยอยู่ที่ ",
  none: "ไม่ระบุ",
  none_exp: "ไม่มีประสบการณ์การทำงาน",
  nation_pre: "สนใจงานที่ ",
  SalaryLabel: "ช่วงเงินเดือนที่ต้องการ",
  exp_compare: "ประสบการณ์การทำงาน ",
  interested: "สนใจ",
  invite: "ส่งคำเชิญ",
  buy_profile: "ซื้อประวัติ",
  update_when: "อัปเดตเมื่อ",
  Applied_to: "สมัครงานตำแหน่ง",
  Posted: "ประกาศเมื่อ",
  NoJob: "ไม่มีการสมัครงาน",
  Purchase: "ซื้อเมื่อ",
  On: "สมัครเมื่อ",
  NewSeeker: "ใหม่",
  open_profile: "กำลังมองหางาน",
  limited_profile: "ยังไม่ต้องการหางาน",
  view_more: "ดูข้อมูลเพิ่มเติม",
  view_less: "ดูน้อยลง",
  IsNotSpecifiedSalary: "สามารถต่อรองเงินเดือนได้",
  introduction: "แนะนำตัวเบื้องต้น",
  less_exp_year: "น้อยกว่า",
  at: "ที่",
  SalaryLasted: "เงินเดือนล่าสุด :",
  SalaryExpect: "เงินเดือนที่ต้องการ :",
  industry: "ประเภทอุตสาหกรรม",
  view_profile: "ดูโปรไฟล์",
  delete_account: "บัญชีนี้ถูกลบ",

  Resume: "Resume",
  OtherDocuments: "เอกสารแนบอื่นๆ",
  ResumeAndDocuments: "Resume และเอกสารแนบอื่นๆ",
}
export const en = {
  exp_low: "Less work experience than ",
  exp_hight: "More work experience than ",
  exp_year: "year",
  live_in_pre: "Live in ",
  none: "Not Specific",
  none_exp: "No experience",
  nation_pre: "Allow in ",
  SalaryLabel: "Desired salary range ",
  exp_compare: "Work experience ",
  interested: "Interested",
  invite: "Invite",
  buy_profile: "Buy Profile",
  update_when: "Last update",
  Applied_to: "Applied for",
  Posted: "Posted",
  NoJob: "No job assigned",
  Purchase: "Purchased on",
  open_profile: "Open Profile",
  On: "on",
  NewSeeker: "New",
  limited_profile: "Limited Profile",
  view_more: "Show more info",
  view_less: "Hide Additinal info",
  IsNotSpecifiedSalary: "Specified Salary",
  introduction: "Introduction",
  less_exp_year: "Less than",
  at: "at",
  SalaryLasted: "Current Salary :",
  SalaryExpect: "Expect Salary :",
  industry: "Industry",
  view_profile: "View Profile",
  delete_account: "Account deleted",

  Resume: "Resume",
  OtherDocuments: "Other Documents",
  ResumeAndDocuments: "Resume and Other Documents",
}

export default function CardItem({ item, optionFilter, setLstData, lstData, setIsOpenModal, setJsId,
  valueTabs = 0, setSelectJobPost = null, arrSelectJobPost = [],
  setIntroductionView = null, setIsOpenModalIntroduction = null, setIsOpenModalBuyProfile, ReloadFunction = null
}) {

  const arrIdTabNotJobPost = [TabFilterJobseekerCandidate.Interested, TabFilterJobseekerCandidate.Buy_Profile]
  // const ApplyJobView = item.nJobPostId != 0 && item.sRelationId == item.sRelationId && (item.isComfirmInvite || item.nTypeJobPostSentFormTable == JobPostSentFormTable.JobApply);
  const isApplyJob = item.nJobPostId != 0 && (item.isComfirmInvite || item.nTypeJobPostSentFormTable == JobPostSentFormTable.JobApply);
  const isApplyJobforSearch = item.nJobPostId != 0 && (item.JobApply);
  const i18nField = "EmployerSearch.Info."
  ///console.log("i18nField", i18nField);
  const sPathName = window.location.pathname;
  const ControllerApiJobSeekerRegisJob = "JobSeekerRegisJob"
  const isEmployerSearch = sPathName.includes("/EmployerSearch");
  const [jobFunction, setJobFunction] = useState("");
  const [industry, setIndustry] = useState("");
  const [liveIn, setLiveIn] = useState("");
  const [nation, setNation] = useState("");
  const [ShowNew, setShowNew] = useState(false);
  const [liveInNaion, setNatinLiveIn] = useState("");
  const isHideJobExpFromInvitedTab = (!item.isBuy && !item.isComfirmInvite)
  const [education, setEducation] = useState("");
  let isCheck = !isEmployerSearch && !arrIdTabNotJobPost.includes(valueTabs) ?
    arrSelectJobPost.some(s => s.sJobPostId.indexOf(item.sJobPostId) !== -1 && s.sJobSeekerId.indexOf(item.sId) !== -1 && s.sRelationId.indexOf(item.sRelationId) !== -1)
    : false;
  const [isOpenInformation, setOpenInformation] = useState(false)
  const ControllerApi = "EmployerSearch";
  const dispatch = useDispatch();


  const getDataWithLangCode = (objField) => {
    const getLangCode = secureLocalStorage.getItem("language").toString();
    if (!objField) return "";
    if (getLangCode.toLocaleLowerCase() == "th") {
      return objField["sTh"];
    } else {
      return objField["sEn"];
    }
  }

  const _setJobFunctionText = () => {
    if (item.objWorkFunc && item.objWorkFuncDetail) {
      setJobFunction(`${getDataWithLangCode(item.objWorkFunc)} > ${getDataWithLangCode(item.objWorkFuncDetail)}`)
    } else if (item.objWorkFunc) {
      setJobFunction(`${getDataWithLangCode(item.objWorkFunc)}`)
    } else {
      setJobFunction("-")
    }
  }

  const _setIndustry = () => {
    if (item.objIndustry && item.objIndustry["sTh"]) {
      //  setIndustry(`${i18n(`${i18nField}industry`)} ${getDataWithLangCode(item.objIndustry)}`)
      setIndustry(getDataWithLangCode(item.objIndustry))
    } else {
      setIndustry("")
    }
  }

  const _setLiveInText = () => {
    if (item.objLiveIn) {
      setLiveIn(`${i18n(`${i18nField}live_in_pre`)} ${getDataWithLangCode(item.objLiveIn)}`)
    } else {
      setLiveIn("")
    }
  }

  const _setLiveInNationText = () => {
    if (item.objNation) {
      setNatinLiveIn(`${i18n(`${i18nField}live_in_pre`)} ${getDataWithLangCode(item.objNation)}`)
    } else {
      setNatinLiveIn("")
    }
  }

  const _setNation = () => {
    if (item.objNation) {
      setNation(`${i18n(`${i18nField}nation_pre`)} ${getDataWithLangCode(item.objNation)}`)
    } else {
      setNation("")
    }
  }
  const _setEducation = () => {
    if (item.objEducation) {
      setEducation(getDataWithLangCode(item.objEducation))
    } else {
      setEducation("")
    }
  }

  const _saveViewed = () => {
    const param = {
      sId: item.sId,
      sRelationId: item.sRelationId,
      nPostJobApplyID: item.nPostJobApplyID,
    };

    AxiosPost(`${ControllerApi}/SaveViewed`, param, (res) => {
      if (res.nRalation_ID == null) {
        setShowNew(true);
      }
      item.dViewLast = new Date();
      setLstData([...lstData])
    }, (err) => dispatch(DialogActionCreators.OpenDialogWarning(err.Message) as any));
  }


  const replaceDataParams = (sText: string) => {
    let sTextReplace = sText;
    const getLangCode = secureLocalStorage.getItem("language");
    sTextReplace = sTextReplace.replace("(ExpLow)", i18n(`${i18nField}exp_low`));
    sTextReplace = sTextReplace.replace("(ExpHight)", i18n(`${i18nField}exp_hight`));
    sTextReplace = sTextReplace.replace("(ExpYear)", i18n(`${i18nField}exp_year`));
    sTextReplace = sTextReplace.replace("(WorkFuncDetail)", jobFunction)
    sTextReplace = sTextReplace.replace("(LiveIn)", liveIn)
    sTextReplace = sTextReplace.replace("(Nation)", liveInNaion)
    sTextReplace = sTextReplace.replace("(Education)", education)
    sTextReplace = sTextReplace.replace("(Industry)", industry)
    sTextReplace = sTextReplace.replace("(SalaryLabel)", i18n(`${i18nField}SalaryLabel`))
    sTextReplace = sTextReplace.replace("(StartSalary)", "0")
    sTextReplace = sTextReplace.replace("(ExpCompare)", i18n(`${i18nField}exp_compare`))
    if (optionFilter != null) {
      if (optionFilter.lstSalary?.length > 0)
        sTextReplace = sTextReplace.replace("(EndSalary)", `${i18n("common.more_than")} ${optionFilter.lstSalary[optionFilter.lstSalary.length - 1].label}`)
      if (item.nValueFilterDate && optionFilter.lstUpdateWhen?.length > 0)
        sTextReplace = sTextReplace.replace("(UpdateWhen)", optionFilter.lstUpdateWhen.find(f => f.value == item.nValueFilterDate)[`label_${getLangCode}`])
    }

    return sTextReplace;
  }
  const _onFavorite = () => {
    AxiosPost(`${ControllerApi}/FavoriteJobSeeker`, { sId: item.sId }, (res) => {
      if (!isEmployerSearch && valueTabs == TabFilterJobseekerCandidate.Interested) {
        ReloadFunction();
        item.isFavorite = res.Data.isFavorite;
        setLstData([...lstData])
      } else {
        item.isFavorite = res.Data.isFavorite;
        setLstData([...lstData])
      }
    }, (err) => dispatch(DialogActionCreators.OpenDialogSuccess(err.Message) as any));
  }

  useEffect(() => {
    _setJobFunctionText();
    _setLiveInText();
    _setNation();
    _setEducation();
    _setIndustry();
    _setLiveInNationText();
  }, [])

  const setToSelect = () => {
    setSelectJobPost(prev => {
      if (prev.length > 0 && prev.some(s => s.sJobPostId.indexOf(item.sJobPostId) !== -1 && s.sJobSeekerId.indexOf(item.sId) !== -1 && s.sRelationId.indexOf(item.sRelationId) !== -1)) {
        prev = prev.filter(s => {
          return !(s.sJobPostId.includes(item.sJobPostId) && s.sJobSeekerId.includes(item.sId) && s.sRelationId.includes(item.sRelationId))
        })
      } else {
        prev.push({
          sJobSeekerId: item.sId,
          sJobPostId: item.sJobPostId,
          sRelationId: item.sRelationId,
          nTypeJobPostSentFormTable: item.nTypeJobPostSentFormTable,
          sManageId: item.sManageId
        })
      }
      return [...prev]
    })
  }


  const htmlDataFile = () => {
    let html = <></>
    const lstFile = item.lstFileUpload;
    // isEmployerSearch คือการcheckว่าหน้าที่เปิดEmployerSearch หรือไม่

    let btnResumeGreen = <></>;
    let btnOtherGreen = <></>;

    let btnResumeBlue = <></>;
    let btnOtherBlue = <></>;

    let btnResumeYellow = <></>;
    let btnOtherYellow = <></>;


    btnResumeGreen = <Button variant="contained" disableElevation startIcon={<HiDocumentText style={{ fontSize: "17px" }} />}
      sx={{
        padding: "7px 8px !important",
        cursor: "default", textTransform: "none", fontSize: "10px", borderRadius: "20px",
        backgroundColor: "#3fd63f",
        "&:hover": {
          backgroundColor: "#3fd63f"
        }
      }}>
      <Box sx={{ fontSize: "11px", fontWeight: "600" }}>Resume</Box>
    </Button>
    btnOtherGreen = <Button variant="contained" disableElevation startIcon={<HiDocumentText style={{ fontSize: "17px" }} />}
      sx={{
        padding: "7px 8px !important",
        cursor: "default", textTransform: "none", fontSize: "10px", borderRadius: "20px",
        backgroundColor: "#3fd63f",
        "&:hover": {
          backgroundColor: "#3fd63f"
        }
      }}>
      <Box sx={{ fontSize: "11px", fontWeight: "600" }}>
        {/* เอกสารแนบอื่นๆ */}
        {i18n(`${i18nField}OtherDocuments`)}
      </Box>
    </Button>


    btnResumeBlue = <Button variant="contained" disableElevation startIcon={<HiDocumentText style={{ fontSize: "17px" }} />}
      sx={{
        padding: "7px 8px !important",
        cursor: "default", textTransform: "none", fontSize: "10px", borderRadius: "20px",
        backgroundColor: "#42d4fa",
        "&:hover": {
          backgroundColor: "#42d4fa"
        }
      }}>
      <Box sx={{ fontSize: "11px", fontWeight: "600" }}>Resume</Box>
    </Button>
    btnOtherBlue = <Button variant="contained" disableElevation startIcon={<HiDocumentText style={{ fontSize: "17px" }} />}
      sx={{
        padding: "7px 8px !important",
        cursor: "default", textTransform: "none", fontSize: "10px", borderRadius: "20px",
        backgroundColor: "#42d4fa",
        "&:hover": {
          backgroundColor: "#42d4fa"
        }
      }}>
      <Box sx={{ fontSize: "11px", fontWeight: "600" }}>
        {/* เอกสารแนบอื่นๆ */}
        {i18n(`${i18nField}OtherDocuments`)}
      </Box>
    </Button>


    btnResumeYellow = <Button variant="contained" disableElevation startIcon={<HiDocumentText style={{ fontSize: "17px" }} />}
      sx={{
        padding: "7px 8px !important",
        cursor: "default", textTransform: "none", fontSize: "10px", borderRadius: "20px",
        backgroundColor: "#ffd940",
        "&:hover": {
          backgroundColor: "#ffd940"
        }
      }}>
      <Box sx={{ fontSize: "11px", fontWeight: "600" }}>Resume</Box>
    </Button>
    btnOtherYellow = <Button variant="contained" disableElevation startIcon={<HiDocumentText style={{ fontSize: "17px" }} />}
      sx={{
        padding: "7px 8px !important",
        cursor: "default", textTransform: "none", fontSize: "10px", borderRadius: "20px",
        backgroundColor: "#ffd940",
        "&:hover": {
          backgroundColor: "#ffd940"
        }
      }}>
      <Box sx={{ fontSize: "11px", fontWeight: "600" }}>
        {/* เอกสารแนบอื่นๆ */}
        {i18n(`${i18nField}OtherDocuments`)}
      </Box>
    </Button>

    if (lstFile && isEmployerSearch) {

      //สีเขียว#3fd63f
      if (!item.isBuy) {
        if (item.isDisclosure && item.objJobPost && !item.sPurchaseDate) {
          if (lstFile.length === 1) {
            ///ถ้าได้2เท่ากับResume
            if (lstFile.find((element) => element === 2)) {
              html = (btnResumeGreen)
            }
            else if (lstFile.find((element) => element === 3)) {
              html = (btnOtherGreen)
            }
          }
          else if (lstFile.length === 2) {
            html = <Box sx={{ display: "flex", flexDirection: "row", columnGap: "10px" }}>
              {(btnResumeGreen)}
              {(btnOtherGreen)}
            </Box>
          }
        }
      }
      else {
        if (item.sPurchaseDate && item.objJobPost == null) {
          if (lstFile.length === 1) {
            ///ถ้าได้2เท่ากับResume
            if (lstFile.find((element) => element === 2)) {
              html = (btnResumeGreen)
            }
            else if (lstFile.find((element) => element === 3)) {
              html = (btnOtherGreen)
            }
          }
          else if (lstFile.length === 2) {
            html = <Box sx={{ display: "flex", flexDirection: "row", columnGap: "10px" }}>
              {(btnResumeGreen)}
              {(btnOtherGreen)}
            </Box>
          }
        }
        else if (item.sPurchaseDate && item.isDisclosure && item.objJobPost != null && item.isComfirmInvite == null) {
          if (lstFile.length === 1) {
            ///ถ้าได้2เท่ากับResume
            if (lstFile.find((element) => element === 2)) {
              html = (btnResumeGreen)
            }
            else if (lstFile.find((element) => element === 3)) {
              html = (btnOtherGreen)
            }
          }
          else if (lstFile.length === 2) {
            html = <Box sx={{ display: "flex", flexDirection: "row", columnGap: "10px" }}>
              {(btnResumeGreen)}
              {(btnOtherGreen)}
            </Box>
          }
        }
      }


      if (!item.isDisclosure && item.nTypeJobPostSentFormTable === JobPostSentFormTable.Invited && item.objJobPost != null) {
        if (lstFile.length === 1) {
          ///ถ้าได้2เท่ากับResume
          if (lstFile.find((element) => element === 2)) {
            html = (btnResumeGreen)
          }
          else if (lstFile.find((element) => element === 3)) {
            html = (btnOtherGreen)
          }
        }
        else if (lstFile.length === 2) {
          html = <Box sx={{ display: "flex", flexDirection: "row", columnGap: "10px" }}>
            {(btnResumeGreen)}
            {(btnOtherGreen)}
          </Box>
        }
      }

      if (!item.isDisclosure && item.nTypeJobPostSentFormTable !== JobPostSentFormTable.Invited && item.objJobPost != null) {
        if (lstFile.length === 1) {
          ///ถ้าได้2เท่ากับResume
          if (lstFile.find((element) => element === 2)) {
            html = (btnResumeGreen)
          }
          else if (lstFile.find((element) => element === 3)) {
            html = (btnOtherGreen)
          }
        }
        else if (lstFile.length === 2) {
          html = <Box sx={{ display: "flex", flexDirection: "row", columnGap: "10px" }}>
            {(btnResumeGreen)}
            {(btnOtherGreen)}
          </Box>
        }
      }

      // สีฟ้า#42d4fa
      if (item.isDisclosure && item.objJobPost == null && !item.sPurchaseDate) {
        if (lstFile.length === 1) {
          ///ถ้าได้2เท่ากับResume
          if (lstFile.find((element) => element === 2)) {
            html = (btnResumeBlue)
          }
          else if (lstFile.find((element) => element === 3)) {
            html = (btnOtherBlue)
          }
        }
        else if (lstFile.length === 2) {
          html = <Box sx={{ display: "flex", flexDirection: "row", columnGap: "10px" }}>
            {(btnResumeBlue)}
            {(btnOtherBlue)}
          </Box>
        }
      }

      //สีเหลือง#efac00 
      if (!item.isDisclosure && item.objJobPost == null && (item.isComfirmInvite == null || item.isComfirmInvite == false)) {
        if (lstFile.length === 1) {
          ///ถ้าได้2เท่ากับResume
          if (lstFile.find((element) => element === 2)) {
            html = (btnResumeYellow)
          }
          else if (lstFile.find((element) => element === 3)) {
            html = (btnOtherYellow)
          }
        }
        else if (lstFile.length === 2) {
          html = <Box sx={{ display: "flex", flexDirection: "row", columnGap: "10px" }}>
            {(btnResumeYellow)}
            {(btnOtherYellow)}
          </Box>
        }
      }

    }
    else if (lstFile && !isEmployerSearch) {

      if (lstFile.length === 0) {
        html = <></>
      }
      else if (lstFile.length === 1) {
        if (lstFile.find((element) => element === 2)) {
          html = <Box sx={{ display: "flex", flexDirection: "row" }}>
            <Box><HiDocumentText style={{ fontSize: "1.5rem" }} /></Box>
            {/* <Box>{"Resume"}</Box> */}
            <Box>{i18n(`${i18nField}Resume`)}</Box>
          </Box>
        }
        else if (lstFile.find((element) => element === 3)) {
          html = <Box sx={{ display: "flex", flexDirection: "row" }}>
            <Box><HiDocumentText style={{ fontSize: "1.5rem" }} /></Box>
            {/* <Box>{"เอกสารแนบอื่นๆ"}</Box> */}
            <Box>{i18n(`${i18nField}OtherDocuments`)}</Box>
          </Box>
        }
      }
      else {
        html = <Box sx={{ display: "flex", flexDirection: "row" }}>
          <Box><HiDocumentText style={{ fontSize: "1.5rem" }} /></Box>
          {/* <Box>{"Resume และเอกสารแนบอื่นๆ"} </Box> */}
          <Box>{i18n(`${i18nField}ResumeAndDocuments`)}</Box>
        </Box>
      }

    }
    return (html)
  }

  return (
    <>
      {/* phone size */}
      <Hidden smUp>
        <div
          onClick={() => {
            // ถ้าไม่ใช่หน้าค้นหาผู้สมัคร และ ไม่ใช่ tab ซื้อประวัติ สนใจ และ ส่งคำชวน
            if (!isEmployerSearch && !arrIdTabNotJobPost.includes(valueTabs) && valueTabs != TabFilterJobseekerCandidate.Invited) {
              setToSelect();
            }
          }}
          className={`flex flex-col rounded-[1em] shadow-[0_1px_4px_rgba(0,0,0,0.3)] w-full py-[0.4em] ${isCheck ? "bg-[#d1e5fa]" : "bg-white"}  px-[15px] ${(!isEmployerSearch && !arrIdTabNotJobPost.includes(valueTabs) && valueTabs != TabFilterJobseekerCandidate.Invited) ? "hover:bg-[#d1e5fa] cursor-pointer" : ""} `}>
          {/* ถ้าไม่ใช่หน้าค้นหาผู้สมัคร และ ไม่ใช่ tab ซื้อประวัติ สนใจ */}
          {(!arrIdTabNotJobPost.includes(valueTabs) && !isEmployerSearch) && (
            <Grid container className='flex flex-row'>

              <Grid item xs={1}>
                {valueTabs != TabFilterJobseekerCandidate.Invited && (
                  <Checkbox sx={{ p: 0, mr: 1 }} size="small" checked={isCheck} />
                )}
              </Grid>

              <Grid item xs={1}></Grid>

              {TabFilterJobseekerCandidate.Waiting_Apply == valueTabs && !item.dViewLast && (
                <Grid item xs={6} className=' py-[2px] bg-[#324FC8]  text-[white] rounded-[15px] sm:rounded text-[0.85em] w-[30px] h-[30px] sm:h-[auto] sm:min-w-[80px] sm:w-[auto] items-center ease-linear duration-[100ms] justify-center flex gap-[2px] cursor-pointer hover:bg-[#04a1c8]' style={{ marginRight: "1%", borderRadius: "20px" }}>
                  <span className='sm:flex'>{i18n(`${i18nField}NewSeeker`)}</span>
                </Grid>
              )}

              <Grid item xs={10} >
                {(isApplyJob || item.isBuy) && (<label className='font-[500] text-[#1976d2] text-[1.1em] break-all'>
                  {(getLanguage().id === "th") ? item.sSeekerNameTH : item.sSeekerNameEN}
                </label>)}
              </Grid>

              <Grid item xs={12}>
                {(isHideJobExpFromInvitedTab && valueTabs == TabFilterJobseekerCandidate.Invited) && <label className={`text-[#1976d2] font-[500] text-[1.1em] break-all`}>{item.sName ? (item.sName as string).replaceAll("(at)", i18n(`${i18nField}at`)) : i18n(`${i18nField}none_exp`)}</label>}
              </Grid>
              {item.nTypeJobPostSentFormTable == JobPostSentFormTable.Invited ?
                item.isComfirmInvite ?
                  <Grid xs={6} className='ml-[2px] flex text-[#00C851] text-[1em] w-[20px] h-[30x] items-center justify-center'>
                    <EventAvailableIcon className='!text-[1em]' />
                  </Grid>
                  :
                  <Grid xs={6} className='  ml-[2px] flex text-[#ff6d00] text-[1em] w-[20px] h-[30x] items-center justify-center'>
                    <CalendarTodayIcon className='!text-[1em]' />
                  </Grid>
                :
                <Grid xs={6}></Grid>
              }

              {(item.nTypeJobPostSentFormTable == JobPostSentFormTable.JobApply && item.sIntroduction) && (
                <Tooltip title={i18n(`${i18nField}introduction`)}>
                  <Grid xs={6} onClick={() => {
                    setIntroductionView(item.sIntroduction)
                    setIsOpenModalIntroduction()
                    setToSelect();
                  }} className='ml-[2px] flex text-[#00C851] text-[1.05em] w-[20px] h-[30x] items-center justify-center'>
                    <InfoIcon className='!text-[1.05em]' />
                  </Grid>
                </Tooltip>
              )}

              {(!item.isBuy || !item.sPurchaseDate) && !isApplyJob && item.isDisclosure ? (
                <div className='flex flex-1 justify-end items-center '>
                  <div
                    style={{ borderRadius: "20px" }}
                    onClick={() => {
                      setJsId(item.sId);
                      setIsOpenModalBuyProfile()
                      // _onBuyProfile()
                      setToSelect();
                    }} className=' py-[2px] px-[5px] bg-[#42d5fb]  text-[white] rounded-[15px] sm:rounded text-[0.85em] w-[30px] h-[30px] sm:h-[auto] sm:min-w-[80px] w-[auto] items-center  ease-linear duration-[100ms]  justify-center flex gap-[2px] cursor-pointer hover:bg-[#04a1c8] whitespace-nowrap'>
                    <ContactPageIcon className='!text-[1em]' /><span className='hidden sm:flex'>{i18n(`${i18nField}buy_profile`)}</span>
                  </div>
                </div>
              ) : !item.isBuy && !isApplyJob && !item.isComfirmInvite && !item.isDisclosure ? (
                <div className='flex flex-1 justify-end items-center '>
                  <div
                    style={{
                      marginRight: "1%",
                      borderRadius: "20px"
                    }}
                    onClick={() => {
                      setJsId(item.sId);
                      setIsOpenModal();
                    }} className=' py-[2px] px-[5px] bg-[#ffd940]  text-[white] rounded-[15px] sm:rounded text-[0.85em] w-[30px] h-[30px] sm:h-[auto] sm:min-w-[80px] sm:w-[auto] items-center  ease-linear duration-[100ms]  justify-center flex gap-[2px] cursor-pointer hover:bg-[#cfa500]'>
                    <EmailIcon className='!text-[1em]' /><span className='hidden sm:flex'>{i18n(`${i18nField}invite`)}</span>
                  </div>
                </div>
              ) : !item.isBuy && !isApplyJob && item.isComfirmInvite ? (
                <div className='flex flex-1 justify-end items-center '>
                  <div
                    style={{ borderRadius: "20px" }}
                    onClick={() => {
                      _saveViewed();
                      if (valueTabs != TabFilterJobseekerCandidate.Invited)
                        setToSelect();
                      //else //JobPostSentFormTable.JobApply
                    }}
                    className='relative py-[2px] whitespace-nowrap  px-[5px] bg-[#3fd63f]  text-[white] rounded-[15px] sm:rounded text-[0.85em] w-[30px] h-[30px] sm:h-[auto] sm:min-w-[80px] w-[auto] items-center  ease-linear duration-[100ms]  justify-center flex gap-[2px] cursor-pointer hover:bg-[#24a824]'>
                    <CheckCircleIcon className='!text-[1em]' /><Link target="_blank" to={`/EmployerSearchViewJobseeker?sID=${item.sId}`} className='!text-[white]' ><span>{i18n(`${i18nField}view_profile`)}</span></Link>
                    {item.dViewLast != null && (<span className='absolute !text-[0.6em] bottom-[-2em] text-[#a0a0a0]'>{moment(item.dViewLast).format("DD/MM/yyyy")}</span>)}
                  </div>
                </div>
              ) : (
                <div className='flex flex-1 justify-end items-center'>
                  <div
                    style={{ borderRadius: "20px" }}
                    onClick={() => {
                      _saveViewed();
                      if (valueTabs != TabFilterJobseekerCandidate.Invited)
                        setToSelect();
                      //else //JobPostSentFormTable.JobApply
                    }}
                    className='relative py-[2px] whitespace-nowrap  px-[5px] bg-[#3fd63f]  text-[white] rounded-[15px] sm:rounded text-[0.85em] w-[30px] h-[30px] sm:h-[auto] sm:min-w-[80px] w-[auto] items-center  ease-linear duration-[100ms]  justify-center flex gap-[2px] cursor-pointer hover:bg-[#24a824]'>
                    <CheckCircleIcon className='!text-[1em]' /><Link target="_blank" to={`/EmployerSearchViewJobseeker?sID=${item.sId}`} className='!text-[white]' ><span>{i18n(`${i18nField}view_profile`)}</span></Link>
                    {item.dViewLast != null && (<span className='absolute !text-[0.6em] bottom-[-2em] text-[#a0a0a0]'>{moment(item.dViewLast).format("DD/MM/yyyy")}</span>)}
                  </div>
                </div>
              )}
            </Grid>
          )}

          {(!isEmployerSearch && item.isBuy && arrIdTabNotJobPost.includes(valueTabs))
            ?
            (
              <>
                <Grid container className='flex flex-row justify-between' justifyContent="end" sx={{ marginTop: "1%" }}>
                  <Grid item xs={12} >
                    <label className='font-[500] text-[#1976d2] text-[1.1em] break-all'>
                      {(getLanguage().id === "th") ? item.sSeekerNameTH : item.sSeekerNameEN}
                    </label>
                  </Grid>
                  <Grid className='flex flex-row gap-[5px] items-center'>
                    {(valueTabs != TabFilterJobseekerCandidate.Buy_Profile) && <Grid item xs={6} onClick={() => { _onFavorite() }} className={` py-[2px]  ${item.isFavorite ? "text-[#ffcc00] font-[600]" : "text-[#656565]"}  rounded-[15px] sm:rounded text-[0.85em] w-[30px] h-[30px] sm:h-[auto] sm:min-w-[80px] sm:w-[auto] items-center  ease-linear duration-[100ms] justify-center flex gap-[2px] cursor-pointer hover:bg-[#f1f1f1]`}>
                      <StarIcon className='!text-[1em]' /><span className='hidden sm:flex'>{i18n(`${i18nField}interested`)}</span>
                    </Grid>}
                    {!item.isDisclosure && valueTabs != TabFilterJobseekerCandidate.Buy_Profile && (
                      <Grid item
                        style={{
                          marginRight: "1%",
                          borderRadius: "20px"
                        }}
                        onClick={() => {
                          setJsId(item.sId);
                          setIsOpenModal();
                        }} className='py-[2px] px-[5px] bg-[#ffd940] text-[white] rounded-[15px] sm:rounded text-[0.85em] w-[30px] h-[30px] sm:h-[auto] sm:min-w-[80px] w-[auto] items-center  ease-linear duration-[100ms] justify-center flex gap-[2px] cursor-pointer hover:bg-[#cfa500]'>
                        <EmailIcon className='!text-[1em]' /><span className='sm:flex'>{i18n(`${i18nField}invite`)}</span> {/*invite icon only */}
                      </Grid>
                    )}

                    {!item.isBuy ? (
                      item.isDisclosure && (isEmployerSearch && isApplyJob && item.objJobPost && item.dViewLast) ? ( //seeker close history but apply job
                        // <div className='flex flex-1 justify-end items-center'>
                        //     <div
                        //         style={{ borderRadius: "20px" }}
                        //         onClick={() => {
                        //             _saveViewed();
                        //         }} className='relative py-[2px] whitespace-nowrap px-[5px] bg-[#3fd63f]  text-[white] rounded-[15px] sm:rounded text-[0.85em] w-[30px] h-[30px] sm:h-[auto] sm:min-w-[80px] w-[auto] items-center  ease-linear duration-[100ms]  justify-center flex gap-[2px] cursor-pointer hover:bg-[#24a824]'>
                        //         <CheckCircleIcon className='!text-[1em]' /><Link target="_blank" to={`/EmployerSearchViewJobseeker?sID=${item.sId}`} className='!text-[white]'><span>{i18n(`${i18nField}view_profile`)}</span></Link>
                        //         {item.dViewLast != null && (<span className='absolute !text-[0.6em] bottom-[-2em] text-[#a0a0a0]'>{moment(item.dViewLast).format("DD/MM/yyyy")}</span>)}
                        //     </div>
                        // </div>
                        <Grid xs={5} className='flex flex-1 justify-end items-center'>
                          <Grid
                            sx={{ borderRadius: "20px" }}
                            onClick={() => {
                              _saveViewed();
                              if (valueTabs != TabFilterJobseekerCandidate.Invited)
                                setToSelect();
                            }} className='relative py-[2px] whitespace-nowrap px-[5px] bg-[#3fd63f] text-[white] rounded-[15px] sm:rounded text-[0.85em] w-[30px] h-[30px] sm:h-[auto] sm:min-w-[80px] w-[auto] items-center  ease-linear duration-[100ms] justify-center flex gap-[2px] cursor-pointer hover:bg-[#24a824]'>
                            <CheckCircleIcon className='!text-[1em]' /><Link target="_blank" to={`/EmployerSearchViewJobseeker?sID=${item.sId}`} className='!text-[white]' ><span>{i18n(`${i18nField}view_profile`)}</span></Link>
                            {item.dViewLast != null && (<span className='absolute !text-[0.6em] bottom-[-2em] text-[#a0a0a0]'>{moment(item.dViewLast).format("DD/MM/yyyy")}</span>)}
                          </Grid>
                        </Grid>
                      ) : item.isDisclosure && isEmployerSearch && item.objJobPost == null && !item.sPurchaseDate ? (//seeker close history and not apply job
                        <div
                          style={{
                            marginRight: "1%",
                            borderRadius: "20px"
                          }}
                          onClick={() => {
                            setJsId(item.sId);
                            setIsOpenModalBuyProfile()
                            // _onBuyProfile()
                          }} className=' py-[2px] px-[5px] bg-[#42d5fb] whitespace-nowrap  text-[white] rounded-[15px] sm:rounded text-[0.85em] w-[30px] h-[30px] sm:h-[auto] sm:min-w-[80px] sm:w-[auto] items-center  ease-linear duration-[100ms]  justify-center flex gap-[2px] cursor-pointer hover:bg-[#04a1c8]'>
                          <ContactPageIcon className='!text-[1em]' /><span className='hidden sm:flex'>{i18n(`${i18nField}buy_profile`)}</span>
                        </div>
                      ) : null //unexpected case
                    ) : (
                      <Grid xs={12} className='flex flex-1 justify-end items-center'>
                        <Grid
                          sx={{ borderRadius: "20px" }}
                          onClick={() => {
                            _saveViewed();
                          }} className='relative py-[2px] whitespace-nowrap px-[5px] bg-[#3fd63f]  text-[white] rounded-[15px] sm:rounded text-[0.85em] w-[30px] h-[30px] sm:h-[auto] sm:min-w-[80px] w-[auto] items-center  ease-linear duration-[100ms]  justify-center flex gap-[2px] cursor-pointer hover:bg-[#24a824]'>
                          <CheckCircleIcon className='!text-[1em]' /><Link target="_blank" to={`/EmployerSearchViewJobseeker?sID=${item.sId}`} className='!text-[white]'><span>{i18n(`${i18nField}view_profile`)}</span></Link>
                          {item.dViewLast != null && (<span className='absolute !text-[0.6em] bottom-[-2em] text-[#a0a0a0]'>{moment(item.dViewLast).format("DD/MM/yyyy")}</span>)}
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
                <Grid item xs={12} sx={{ marginTop: "10%" }} className='flex flex-row justify-between'>
                  <label className={` ${isEmployerSearch || arrIdTabNotJobPost.includes(valueTabs) ? "text-[#1976d2] font-[500]" : "text-[#1976d2]"} text-[1.1em] break-all`} >{item.sName ? (item.sName as string).replaceAll("(at)", i18n(`${i18nField}at`)) : i18n(`${i18nField}none_exp`)}</label>
                </Grid>
              </>
            )
            :
            (
              <Grid container className='flex flex-row justify-between' justifyContent={"end"} sx={{ marginTop: "1%" }}>
                <Grid item xs={12} sx={{ marginTop: "10%" }}>
                  {(valueTabs != TabFilterJobseekerCandidate.Invited || !isHideJobExpFromInvitedTab) && <label className={` ${isEmployerSearch || arrIdTabNotJobPost.includes(valueTabs) ? "text-[#1976d2] font-[500]" : "text-[#1976d2]"} text-[1.1em] break-all`} >{item.sName ? (item.sName as string).replaceAll("(at)", i18n(`${i18nField}at`)) : i18n(`${i18nField}none_exp`)}</label>}
                </Grid>
                <Grid xs={1}></Grid>
                <Grid item className='flex flex-row gap-[5px] items-center'>
                  {isEmployerSearch || arrIdTabNotJobPost.includes(valueTabs) ? (
                    <>
                      {(valueTabs != TabFilterJobseekerCandidate.Buy_Profile) && <div onClick={() => { _onFavorite() }} className={` py-[2px]  ${item.isFavorite ? "text-[#ffcc00] font-[600]" : "text-[#656565]"}  rounded-[15px] sm:rounded text-[0.85em] w-[30px] h-[30px] sm:h-[auto] sm:min-w-[80px] sm:w-[auto] items-center  ease-linear duration-[100ms] justify-center flex gap-[2px] cursor-pointer hover:bg-[#f1f1f1]`}>
                        <StarIcon className='!text-[1em]' /><span className='hidden sm:flex'>{i18n(`${i18nField}interested`)}</span>
                      </div>}
                      {!item.isDisclosure && valueTabs != TabFilterJobseekerCandidate.Buy_Profile && !isEmployerSearch && (
                        <div
                          style={{
                            marginRight: "1%",
                            borderRadius: "20px"
                          }}
                          onClick={() => {
                            setJsId(item.sId);
                            setIsOpenModal();
                          }} className=' py-[2px] px-[5px] bg-[#ffd940] whitespace-nowrap  text-[white] rounded-[15px] sm:rounded text-[0.85em] w-[30px] h-[30px] sm:h-[auto] sm:min-w-[80px] sm:w-[auto] items-center  ease-linear duration-[100ms]  justify-center flex gap-[2px] cursor-pointer hover:bg-[#cfa500]'>
                          <EmailIcon className='!text-[1em]' /><span className='hidden sm:flex'>{i18n(`${i18nField}invite`)}</span>
                        </div>
                      )}
                      {!item.isDisclosure && valueTabs != TabFilterJobseekerCandidate.Buy_Profile && isEmployerSearch && item.objJobPost == null && (item.isComfirmInvite == null || item.isComfirmInvite == false) && (
                        <div
                          style={{
                            marginRight: "1%",
                            borderRadius: "20px"
                          }}
                          onClick={() => {
                            setJsId(item.sId);
                            setIsOpenModal();
                          }} className=' py-[2px] px-[5px] bg-[#ffd940] whitespace-nowrap  text-[white] rounded-[15px] sm:rounded text-[0.85em] w-[30px] h-[30px] sm:h-[auto] sm:min-w-[80px] sm:w-[auto] items-center  ease-linear duration-[100ms]  justify-center flex gap-[2px] cursor-pointer hover:bg-[#cfa500]'>
                          <EmailIcon className='!text-[1em]' /><span className='hidden sm:flex'>{i18n(`${i18nField}invite`)}</span>
                        </div>
                      )}
                      {/* {!item.isDisclosure && isEmployerSearch && item.nTypeJobPostSentFormTable == JobPostSentFormTable.Invited && item.isComfirmInvite == false && (
                                                <div
                                                    style={{
                                                        marginRight: "1%",
                                                        borderRadius: "20px"
                                                    }}
                                                    onClick={() => {
                                                        setJsId(item.sId);
                                                        setIsOpenModal();
                                                    }} className=' py-[2px] px-[5px] bg-[#ffd940] whitespace-nowrap  text-[white] rounded-[15px] sm:rounded text-[0.85em] w-[30px] h-[30px] sm:h-[auto] sm:min-w-[80px] sm:w-[auto] items-center  ease-linear duration-[100ms]  justify-center flex gap-[2px] cursor-pointer hover:bg-[#cfa500]'>
                                                    <EmailIcon className='!text-[1em]' /><span className='hidden sm:flex'>{i18n(`${i18nField}invite`)}ffff</span>
                                                </div>
                                            )} */}
                      {!item.isDisclosure && isEmployerSearch && item.nTypeJobPostSentFormTable == JobPostSentFormTable.Invited /*&& item.isComfirmInvite == true*/ && item.objJobPost != null && (
                        <div className='flex flex-1 justify-end items-center'>
                          <div
                            style={{ borderRadius: "20px" }}
                            onClick={() => {
                              _saveViewed();
                            }} className='relative py-[2px] whitespace-nowrap px-[5px] bg-[#3fd63f]  text-[white] rounded-[15px] sm:rounded text-[0.85em] w-[30px] h-[30px] sm:h-[auto] sm:min-w-[80px] w-[auto] items-center  ease-linear duration-[100ms]  justify-center flex gap-[2px] cursor-pointer hover:bg-[#24a824]'>
                            <CheckCircleIcon className='!text-[1em]' /><Link target="_blank" to={`/EmployerSearchViewJobseeker?sID=${item.sId}`} className='!text-[white]'><span>{i18n(`${i18nField}view_profile`)}</span></Link>
                            {item.dViewLast != null && (<span className='absolute !text-[0.6em] bottom-[-2em] text-[#a0a0a0]'>{moment(item.dViewLast).format("DD/MM/yyyy")}</span>)}
                          </div>
                        </div>
                      )}
                      {!item.isDisclosure && isEmployerSearch && item.nTypeJobPostSentFormTable != JobPostSentFormTable.Invited && item.objJobPost != null && (
                        <div className='flex flex-1 justify-end items-center'>
                          <div
                            style={{ borderRadius: "20px" }}
                            onClick={() => {
                              _saveViewed();
                            }} className='relative py-[2px] whitespace-nowrap px-[5px] bg-[#3fd63f]  text-[white] rounded-[15px] sm:rounded text-[0.85em] w-[30px] h-[30px] sm:h-[auto] sm:min-w-[80px] w-[auto] items-center  ease-linear duration-[100ms]  justify-center flex gap-[2px] cursor-pointer hover:bg-[#24a824]'>
                            <CheckCircleIcon className='!text-[1em]' /><Link target="_blank" to={`/EmployerSearchViewJobseeker?sID=${item.sId}`} className='!text-[white]'><span>{i18n(`${i18nField}view_profile`)}</span></Link>
                            {item.dViewLast != null && (<span className='absolute !text-[0.6em] bottom-[-2em] text-[#a0a0a0]'>{moment(item.dViewLast).format("DD/MM/yyyy")} </span>)}
                          </div>
                        </div>
                      )}
                      {/* {isEmployerSearch && item.nTypeJobPostSentFormTable == JobPostSentFormTable.Invited && item.isComfirmInvite == true && item.objJobPost != null && (
                                                <div className='flex flex-1 justify-end items-center'>
                                                    <div
                                                        style={{ borderRadius: "20px" }}
                                                        onClick={() => {
                                                            _saveViewed();
                                                        }} className='relative py-[2px] whitespace-nowrap px-[5px] bg-[#3fd63f]  text-[white] rounded-[15px] sm:rounded text-[0.85em] w-[30px] h-[30px] sm:h-[auto] sm:min-w-[80px] w-[auto] items-center  ease-linear duration-[100ms]  justify-center flex gap-[2px] cursor-pointer hover:bg-[#24a824]'>
                                                        <CheckCircleIcon className='!text-[1em]' /><Link target="_blank" to={`/EmployerSearchViewJobseeker?sID=${item.sId}`} className='!text-[white]'><span>{i18n(`${i18nField}view_profile`)}</span></Link>
                                                        {item.dViewLast != null && (<span className='absolute !text-[0.6em] bottom-[-2em] text-[#a0a0a0]'>{moment(item.dViewLast).format("DD/MM/yyyy")}</span>)}
                                                    </div>
                                                </div>
                                            )} */}
                      {!item.isBuy ? (
                        item.isDisclosure && ((isEmployerSearch || isApplyJob) && item.objJobPost && !item.sPurchaseDate) ? ( //seeker close history but apply job
                          <div className='flex flex-1 justify-end items-center'>
                            <div
                              style={{ borderRadius: "20px" }}
                              onClick={() => {
                                _saveViewed();
                              }} className='relative py-[2px] whitespace-nowrap px-[5px] bg-[#3fd63f]  text-[white] rounded-[15px] sm:rounded text-[0.85em] w-[30px] h-[30px] sm:h-[auto] sm:min-w-[80px] w-[auto] items-center  ease-linear duration-[100ms]  justify-center flex gap-[2px] cursor-pointer hover:bg-[#24a824]'>
                              <CheckCircleIcon className='!text-[1em]' /><Link target="_blank" to={`/EmployerSearchViewJobseeker?sID=${item.sId}`} className='!text-[white]'><span>{i18n(`${i18nField}view_profile`)} </span></Link>
                              {item.dViewLast != null && (<span className='absolute !text-[0.6em] bottom-[-2em] text-[#a0a0a0]'>{moment(item.dViewLast).format("DD/MM/yyyy")}</span>)}
                            </div>
                          </div>
                        ) : item.isDisclosure && (!isEmployerSearch && !isApplyJob && item.objJobPost == null && !item.sPurchaseDate) ? (//seeker close history and not apply job
                          <div
                            style={{
                              marginRight: "1%",
                              borderRadius: "20px"
                            }}
                            onClick={() => {
                              setJsId(item.sId);
                              setIsOpenModalBuyProfile()
                              // _onBuyProfile()
                            }} className=' py-[2px] px-[5px] bg-[#42d5fb] whitespace-nowrap  text-[white] rounded-[15px] sm:rounded text-[0.85em] w-[30px] h-[30px] sm:h-[auto] sm:min-w-[80px] sm:w-[auto] items-center  ease-linear duration-[100ms]  justify-center flex gap-[2px] cursor-pointer hover:bg-[#04a1c8]'>
                            <ContactPageIcon className='!text-[1em]' /><span className='hidden sm:flex'>{i18n(`${i18nField}buy_profile`)}</span>
                          </div>
                        ) : item.isDisclosure && isEmployerSearch && item.objJobPost == null && !item.sPurchaseDate ? (//seeker close history and not apply job
                          <div
                            style={{
                              marginRight: "1%",
                              borderRadius: "20px"
                            }}
                            onClick={() => {
                              setJsId(item.sId);
                              setIsOpenModalBuyProfile()
                              // _onBuyProfile()
                            }} className=' py-[2px] px-[5px] bg-[#42d5fb] whitespace-nowrap  text-[white] rounded-[15px] sm:rounded text-[0.85em] w-[30px] h-[30px] sm:h-[auto] sm:min-w-[80px] sm:w-[auto] items-center  ease-linear duration-[100ms]  justify-center flex gap-[2px] cursor-pointer hover:bg-[#04a1c8]'>
                            <ContactPageIcon className='!text-[1em]' /><span className='hidden sm:flex'>{i18n(`${i18nField}buy_profile`)}</span>
                          </div>
                        ) /*: item.isDisclosure && (!isEmployerSearch && isApplyJob && item.objJobPost != null) ? (//seeker close history and not apply job
                                                <div className='flex flex-1 justify-end items-center'>
                                                    <div
                                                        style={{ borderRadius: "20px" }}
                                                        onClick={() => {
                                                            _saveViewed();
                                                        }} className='relative py-[2px] whitespace-nowrap px-[5px] bg-[#3fd63f]  text-[white] rounded-[15px] sm:rounded text-[0.85em] w-[30px] h-[30px] sm:h-[auto] sm:min-w-[80px] w-[auto] items-center  ease-linear duration-[100ms]  justify-center flex gap-[2px] cursor-pointer hover:bg-[#24a824]'>
                                                        <CheckCircleIcon className='!text-[1em]' /><Link target="_blank" to={`/EmployerSearchViewJobseeker?sID=${item.sId}`} className='!text-[white]'><span>{i18n(`${i18nField}view_profile`)}  01</span></Link>
                                                        {item.dViewLast != null && (<span className='absolute !text-[0.6em] bottom-[-2em] text-[#a0a0a0]'>{moment(item.dViewLast).format("DD/MM/yyyy")}</span>)}
                                                    </div>
                                                </div>
                                            )*/
                          : null //unexpected case
                      ) : (!isEmployerSearch && /*item.nTypeJobPostSentFormTable == JobPostSentFormTable.Invited &&/*/ item.isComfirmInvite != true && item.objJobPost != null) ?
                        <div className='flex flex-1 justify-end items-center'>
                          <div
                            style={{ borderRadius: "20px" }}
                            onClick={() => {
                              _saveViewed();
                            }} className='relative py-[2px] whitespace-nowrap px-[5px] bg-[#3fd63f]  text-[white] rounded-[15px] sm:rounded text-[0.85em] w-[30px] h-[30px] sm:h-[auto] sm:min-w-[80px] w-[auto] items-center  ease-linear duration-[100ms]  justify-center flex gap-[2px] cursor-pointer hover:bg-[#24a824]'>
                            <CheckCircleIcon className='!text-[1em]' /><Link target="_blank" to={`/EmployerSearchViewJobseeker?sID=${item.sId}`} className='!text-[white]'><span>{i18n(`${i18nField}view_profile`)}</span></Link>
                            {item.dViewLast != null && (<span className='absolute !text-[0.6em] bottom-[-2em] text-[#a0a0a0]'>{moment(item.dViewLast).format("DD/MM/yyyy")}</span>)}
                          </div>
                        </div> : (isEmployerSearch && item.sPurchaseDate && item.objJobPost == null /*item.nTypeJobPostSentFormTable == JobPostSentFormTable.Invited &&/*/ /*&& item.isComfirmInvite != true && item.objJobPost != null*/) ?
                          <div className='flex flex-1 justify-end items-center'>
                            <div
                              style={{ borderRadius: "20px" }}
                              onClick={() => {
                                _saveViewed();
                              }} className='relative py-[2px] whitespace-nowrap px-[5px] bg-[#3fd63f]  text-[white] rounded-[15px] sm:rounded text-[0.85em] w-[30px] h-[30px] sm:h-[auto] sm:min-w-[80px] w-[auto] items-center  ease-linear duration-[100ms]  justify-center flex gap-[2px] cursor-pointer hover:bg-[#24a824]'>
                              <CheckCircleIcon className='!text-[1em]' /><Link target="_blank" to={`/EmployerSearchViewJobseeker?sID=${item.sId}`} className='!text-[white]'><span>{i18n(`${i18nField}view_profile`)}</span></Link>
                              {item.dViewLast != null && (<span className='absolute !text-[0.6em] bottom-[-2em] text-[#a0a0a0]'>{moment(item.dViewLast).format("DD/MM/yyyy")}</span>)}
                            </div>
                          </div> : (item.isDisclosure && isEmployerSearch && item.sPurchaseDate && item.objJobPost != null && item.isComfirmInvite == null /*item.nTypeJobPostSentFormTable == JobPostSentFormTable.Invited &&/*/ /*&& item.isComfirmInvite != true && item.objJobPost != null*/) ?
                            <div className='flex flex-1 justify-end items-center'>
                              <div
                                style={{ borderRadius: "20px" }}
                                onClick={() => {
                                  _saveViewed();
                                }} className='relative py-[2px] whitespace-nowrap px-[5px] bg-[#3fd63f]  text-[white] rounded-[15px] sm:rounded text-[0.85em] w-[30px] h-[30px] sm:h-[auto] sm:min-w-[80px] w-[auto] items-center  ease-linear duration-[100ms]  justify-center flex gap-[2px] cursor-pointer hover:bg-[#24a824]'>
                                <CheckCircleIcon className='!text-[1em]' /><Link target="_blank" to={`/EmployerSearchViewJobseeker?sID=${item.sId}`} className='!text-[white]'><span>{i18n(`${i18nField}view_profile`)}</span></Link>
                                {item.dViewLast != null && (<span className='absolute !text-[0.6em] bottom-[-2em] text-[#a0a0a0]'>{moment(item.dViewLast).format("DD/MM/yyyy")}</span>)}
                              </div>
                            </div> : <></>
                      }
                      {/* </>)} */}
                    </>
                  ) : <></>}
                </Grid>
              </Grid>
            )}

          {(isEmployerSearch || arrIdTabNotJobPost.includes(valueTabs)) && <div className='flex flex-row'>
            <label className='font-[500]  text-[1em]' >{item.sPosition}</label>
          </div>}

          {industry && <div className='flex flex-row items-center gap-[3px]'>
            <FactoryIcon className='text-[0.6em]' /><span className="text-[1em]">{industry}</span>
          </div>}

          <div className='flex flex-row gap-[5px] flex-wrap'>
            <div className='flex flex-row gap-[3px]'>
              <WorkIcon className='text-[0.6em]' /><span className="text-[1em] ">{item.nWorkExperienceYear != null ? item.nWorkExperienceYear == 0 ? `${i18n(`${i18nField}less_exp_year`)} 1 ${i18n(`${i18nField}exp_year`)}` : `${item.nWorkExperienceYear} ${i18n(`${i18nField}exp_year`)}` : i18n(`${i18nField}none_exp`)}</span>
            </div>
            <div className='flex flex-row gap-[3px]'>
              <SchoolIcon className='text-[0.6em]' />
              <span className="text-[1em] ">{education ? education : i18n(`${i18nField}none`)}</span>
            </div>
            <div className='flex flex-row gap-[3px]'>
              <RoomIcon className='text-[0.6em]' /><span className="text-[1em] ">{liveIn ? liveIn : i18n(`${i18nField}none`)}</span>
            </div>
            <div className='flex flex-row gap-[3px]'>
              <BadgeIcon className='text-[0.6em]' /><span className="text-[1em] ">{nation ? nation : i18n(`${i18nField}none`)}</span>
            </div>
          </div>

          {item.arrFilterMatch?.length > 0 && <div className='flex flex-row gap-[5px] flex-wrap	'>
            <div className=" flex justify-center items-center">
              <SearchIcon className="!text-[1.2em] font-[600] text-[#1976d2]" />
            </div>
            {item.arrFilterMatch.map((m, i) => {
              return (<div key={`tag_filter_${i}`} className="px-[3px] py-[4px] bg-[#1976d2] rounded border flex">
                <span className="text-[0.6em] font-[600] text-[white]">{replaceDataParams(m)}</span>
              </div>)
            })}
          </div>}

          <Box sx={{ display: "flex", flexDirection: "row", paddingTop: "5px", paddingBottom: "5px" }}>
            {htmlDataFile()}
          </Box>

          {item.sLastedSalary && <div className='flex flex-row items-center gap-[5px]'>
            <span className="text-[0.9em] text-[black]">{`${i18n(`${i18nField}SalaryLasted`)} ${item.sLastedSalary} ${i18n("common.baht")}`}</span>
          </div>}

          <div className='flex flex-row items-center gap-[5px]' >
            <span className="text-[0.9em] text-[black]">{`${i18n(`${i18nField}SalaryExpect`)} ${item.sExpectSalary} ${i18n("common.baht")}`}</span>
            {item.IsNotSpecifiedSalary && <div className="px-[3px] py-[4px] bg-[#ff7f00] rounded border flex" style={{ borderRadius: "20px" }}>
              <span className="text-[0.85em] font-[300] text-[white]">{i18n(`${i18nField}IsNotSpecifiedSalary`)}</span>
            </div>}
          </div>

          {!isEmployerSearch && item.isDeleteAccount && (
            <div className={`flex flex-row text-[0.85em] font-[500] text-[#a1a1a1] items-center gap-[5px]`}>
              <BlockIcon className='!text-[1em]' /> <span className=''> {i18n(`${i18nField}delete_account`)}</span>
            </div>
          )}

          {(isEmployerSearch || arrIdTabNotJobPost.includes(valueTabs)) && (
            <div className={`flex flex-row text-[0.85em] font-[500] items-center gap-[5px] ${item.isDisclosure ? "text-[#00cc1e]" : "text-[#ff7c00]"}`}>
              {item.isDisclosure ? <LockOpenIcon className='!text-[1em]' /> : <LockIcon className='!text-[1em]' />} <span className=''> {item.isDisclosure ? i18n(`${i18nField}open_profile`) : i18n(`${i18nField}limited_profile`)}</span>
            </div>
          )}
          {item.arrTagManage?.length > 0 && <div className='flex flex-row gap-[5px] flex-wrap	'>
            <div className=" flex justify-center items-center">
              <LabelIcon className="!text-[1.2em] font-[600] text-[#00bfa5]" />
            </div>
            {item.arrTagManage.map((m, i) => {
              return (<div key={`tag_filter_${i}`} className="px-[3px] py-[4px] bg-[#00bfa5] gap-[3px] items-center rounded border flex">
                <span className="text-[0.6em] font-[600] text-[white]">{m}</span>
                <CloseIcon onClick={() => {
                  setToSelect();
                  let objReq = {
                    sTag: m,
                    sManageId: item.sManageId
                  }
                  AxiosPost(`${ControllerApiJobSeekerRegisJob}/OnDeleteTag`, objReq, (res) => {
                    item.arrTagManage = item.arrTagManage.filter(f => f !== m) || [];
                    setLstData([...lstData])
                  }, (err) => dispatch(DialogActionCreators.OpenDialogWarning(err.Message) as any))
                }} className="!text-[1em] font-[600] text-[white]" />
              </div>)
            })}
          </div>}

          {<div onClick={() => {
            setOpenInformation(prev => !prev)
            if (!isEmployerSearch && !arrIdTabNotJobPost.includes(valueTabs) && valueTabs != TabFilterJobseekerCandidate.Invited) {
              setToSelect();
            }
          }} className={`flex flex-row text-[0.85em] cursor-pointer font-[500] items-center gap-[5px] text-[#1976d2]`}>
            {isOpenInformation ? <ArrowDropUp className='!text-[1em]' /> : <ArrowDropDown className='!text-[1em]' />}<span className=''>{isOpenInformation ? i18n(`${i18nField}view_less`) : i18n(`${i18nField}view_more`)}</span>
          </div>}

          <InfomationJobSeeker isBuyProfile={item.isBuy} handleSelectCard={() => {
            if (!isEmployerSearch && !arrIdTabNotJobPost.includes(valueTabs) && valueTabs != TabFilterJobseekerCandidate.Invited) {
              setToSelect();
            }
          }} isOpenInformation={isOpenInformation} sId={item.sId} />
          <div className='flex flex-row border-b border-b-[#f1f1f1] my-[5px]  ' />

          {/* {item.isBuy && <div
                // onClick={() => setOpenInformation(prev => !prev)} 
                className={`flex flex-row text-[0.85em] cursor-pointer font-[500] items-center gap-[5px] text-[#1976d2]`}>
                <Link to={`/EmployerSearchViewJobseeker?sID=${item.sId}`} className=''><span>{i18n(`${i18nField}view_more`)}</span></Link>
            </div>} */}


          {/* if seeker apply */}
          {((!isEmployerSearch && isApplyJob && !item.isBuy) || (isEmployerSearch && !item.sPurchaseDate)) && item.objJobPost != null && item.isComfirmInvite == null ? (
            <div className='flex flex-row'>
              <span className="text-[0.8em] text-[#a1a1a1] ">{i18n(`${i18nField}Applied_to`)} {getDataWithLangCode(item.objJobPost)} ({i18n(`${i18nField}Posted`)}: {item.sPostDate}) {i18n(`${i18nField}On`)} {item.sSendDate} <br /> {i18n(`${i18nField}update_when`)} {item.sUpdateDate}</span>
            </div>
          ) : !isEmployerSearch && item.isBuy && item.objJobPost == null ? (
            <div className='flex flex-row'>
              <span className="text-[0.8em] text-[#a1a1a1] ">{i18n(`${i18nField}NoJob`)},
                <br />{i18n(`${i18nField}Purchase`)} {item.sPurchaseDate ? item.sPurchaseDate : item.sSendDate}, {i18n(`${i18nField}update_when`)} {item.sUpdateDate}</span>
            </div>
          ) : item.sPurchaseDate && isEmployerSearch && item.objJobPost == null ? (
            <div className='flex flex-row'>
              <span className="text-[0.8em] text-[#a1a1a1] ">{i18n(`${i18nField}NoJob`)},
                <br />{i18n(`${i18nField}Purchase`)} {item.sPurchaseDate}, {i18n(`${i18nField}update_when`)} {item.sUpdateDate}</span>
              {/* <span className="text-[0.8em] text-[#a1a1a1] ">{i18n(`${i18nField}Applied_to`)} {getDataWithLangCode(item.objJobPost)}  ,{i18n(`${i18nField}Purchase`)} {item.sPurchaseDate}</span> */}
            </div>
          ) : (((!isEmployerSearch && isApplyJob) && item.isBuy) || (isEmployerSearch && item.sPurchaseDate)) && item.objJobPost != null && item.isComfirmInvite == null ? (
            <div className='flex flex-row'>
              <span className="text-[0.8em] text-[#a1a1a1] ">{i18n(`${i18nField}Applied_to`)} {getDataWithLangCode(item.objJobPost)} ({i18n(`${i18nField}Posted`)}: {item.sPostDate}) {i18n(`${i18nField}On`)} {item.sSendDate},
                <br />{i18n(`${i18nField}Purchase`)} {item.sPurchaseDate ? item.sPurchaseDate : item.sSendDate}, {i18n(`${i18nField}update_when`)} {item.sUpdateDate}
              </span>
            </div>
          ) : isEmployerSearch && item.nTypeJobPostSentFormTable == JobPostSentFormTable.Invited && item.isComfirmInvite == true && item.objJobPost != null ? (
            <div className='flex flex-row'>
              <span className="text-[0.8em] text-[#a1a1a1] ">{i18n(`${i18nField}Applied_to`)} {getDataWithLangCode(item.objJobPost)} ({i18n(`${i18nField}Posted`)}: {item.sPostDate}) {i18n(`${i18nField}On`)} {item.sSendDate} <br /> {i18n(`${i18nField}update_when`)} {item.sUpdateDate}</span>
            </div>
          ) : isEmployerSearch && item.nTypeJobPostSentFormTable == JobPostSentFormTable.Invited && item.isComfirmInvite == false && item.objJobPost != null ? (
            <div className='flex flex-row'>
              <span className="text-[0.8em] text-[#a1a1a1] ">{i18n(`${i18nField}Applied_to`)} {getDataWithLangCode(item.objJobPost)} ({i18n(`${i18nField}Posted`)}: {item.sPostDate}) {i18n(`${i18nField}On`)} {item.sSendDate} <br /> {i18n(`${i18nField}update_when`)} {item.sUpdateDate}</span>
            </div>
          ) : isEmployerSearch && item.nTypeJobPostSentFormTable == JobPostSentFormTable.Invited && item.isComfirmInvite == false && item.objJobPost == null ? (
            <div className='flex flex-row'>
              <span className="text-[0.8em] text-[#a1a1a1] ">{i18n(`${i18nField}NoJob`)}, {i18n(`${i18nField}update_when`)} {item.sUpdateDate}</span>
            </div>
          ) : valueTabs == TabFilterJobseekerCandidate.Invited && item.isComfirmInvite == true ? (
            <div className='flex flex-row'>
              <span className="text-[0.8em] text-[#a1a1a1] ">{i18n(`${i18nField}Applied_to`)} {getDataWithLangCode(item.objJobPost)} ({i18n(`${i18nField}Posted`)}: {item.sPostDate}) {i18n(`${i18nField}On`)} {item.sSendDate} <br /> {i18n(`${i18nField}update_when`)} {item.sUpdateDate}</span>
            </div>
          ) : valueTabs == TabFilterJobseekerCandidate.Invited && item.isComfirmInvite == false ? (
            <div className='flex flex-row'>
              <span className="text-[0.8em] text-[#a1a1a1] ">{i18n(`${i18nField}NoJob`)}, {i18n(`${i18nField}update_when`)} {item.sUpdateDate}</span>
            </div>
          ) : valueTabs == TabFilterJobseekerCandidate.Invited && item.isComfirmInvite == null ? (
            <div className='flex flex-row'>
              <span className="text-[0.8em] text-[#a1a1a1] ">{i18n(`${i18nField}update_when`)} {item.sUpdateDate}</span>
            </div>
          ) : !isEmployerSearch && valueTabs != TabFilterJobseekerCandidate.Invited && item.isComfirmInvite == true ? (
            <div className='flex flex-row'>
              <span className="text-[0.8em] text-[#a1a1a1] ">{i18n(`${i18nField}Applied_to`)} {getDataWithLangCode(item.objJobPost)} ({i18n(`${i18nField}Posted`)}: {item.sPostDate}) {i18n(`${i18nField}On`)} {item.sSendDate} <br /> {i18n(`${i18nField}update_when`)} {item.sUpdateDate}</span>
            </div>
          ) : !isEmployerSearch && valueTabs != TabFilterJobseekerCandidate.Invited && item.isComfirmInvite == false ? (
            <div className='flex flex-row'>
              <span className="text-[0.8em] text-[#a1a1a1] ">{i18n(`${i18nField}NoJob`)}, {i18n(`${i18nField}update_when`)} {item.sUpdateDate}</span>
            </div>
          ) : !isEmployerSearch && valueTabs != TabFilterJobseekerCandidate.Invited && item.isComfirmInvite == null ? (
            <div className='flex flex-row'>
              <span className="text-[0.8em] text-[#a1a1a1] ">{i18n(`${i18nField}update_when`)} {item.sUpdateDate}</span>
            </div>
          ) : <div className='flex flex-row'>
            <span className="text-[0.8em] text-[#a1a1a1] ">{i18n(`${i18nField}update_when`)} {item.sUpdateDate}</span>
          </div>
          }
        </div>
      </Hidden>

      {/* normal size */}
      <Hidden smDown>
        <div
          onClick={() => {
            // ถ้าไม่ใช่หน้าค้นหาผู้สมัคร และ ไม่ใช่ tab ซื้อประวัติ สนใจ และ ส่งคำชวน
            if (!isEmployerSearch && !arrIdTabNotJobPost.includes(valueTabs) && valueTabs != TabFilterJobseekerCandidate.Invited) {
              setToSelect();
            }
          }}
          className={`flex flex-col rounded-[1em] shadow-[0_1px_4px_rgba(0,0,0,0.3)] w-full py-[0.4em] ${isCheck ? "bg-[#d1e5fa]" : "bg-white"}  px-[15px] ${(!isEmployerSearch && !arrIdTabNotJobPost.includes(valueTabs) && valueTabs != TabFilterJobseekerCandidate.Invited) ? "hover:bg-[#d1e5fa] cursor-pointer" : ""} `}>
          {/* ถ้าไม่ใช่หน้าค้นหาผู้สมัคร และ ไม่ใช่ tab ซื้อประวัติ สนใจ */}
          {(!arrIdTabNotJobPost.includes(valueTabs) && !isEmployerSearch) && (
            <div className='flex flex-row'>

              {valueTabs != TabFilterJobseekerCandidate.Invited && (
                <Checkbox sx={{ p: 0, mr: 1 }} size="small" checked={isCheck} />
              )}

              {TabFilterJobseekerCandidate.Waiting_Apply == valueTabs && !item.dViewLast && (
                <div className=' py-[2px] bg-[#324FC8] text-[white] rounded-[15px] sm:rounded text-[0.85em] w-[30px] h-[30px] sm:h-[auto] sm:min-w-[80px] sm:w-[auto] items-center  ease-linear duration-[100ms]  justify-center flex gap-[2px] cursor-pointer hover:bg-[#04a1c8]' style={{ marginRight: "1%", borderRadius: "20px" }}>
                  <span className='hidden sm:flex'>{i18n(`${i18nField}NewSeeker`)}</span>
                </div>
              )}

              {(isApplyJob || item.isBuy) && (<label className='font-[500] text-[#1976d2] text-[1.1em] break-all'>
                {(getLanguage().id === "th") ? item.sSeekerNameTH : item.sSeekerNameEN}
              </label>)}

              {(isHideJobExpFromInvitedTab && valueTabs == TabFilterJobseekerCandidate.Invited) && <label className={`text-[#1976d2] font-[500] text-[1.1em] break-all`} >{item.sName ? (item.sName as string).replaceAll("(at)", i18n(`${i18nField}at`)) : i18n(`${i18nField}none_exp`)}</label>}

              {item.nTypeJobPostSentFormTable == JobPostSentFormTable.Invited ?
                item.isComfirmInvite ?
                  <div className='  ml-[2px] flex text-[#00C851]  text-[1em] w-[20px] h-[30x]  items-center   justify-center '>
                    <EventAvailableIcon className='!text-[1em]' />
                  </div>
                  :
                  <div className='  ml-[2px] flex  text-[#ff6d00]  text-[1em] w-[20px] h-[30x]  items-center   justify-center '>
                    <CalendarTodayIcon className='!text-[1em]' />
                  </div>
                :
                null
              }

              {(item.nTypeJobPostSentFormTable == JobPostSentFormTable.JobApply && item.sIntroduction) && (
                <Tooltip title={i18n(`${i18nField}introduction`)}>
                  <div onClick={() => {
                    setIntroductionView(item.sIntroduction)
                    setIsOpenModalIntroduction()
                    setToSelect();
                  }} className='  ml-[2px] flex text-[#00C851]  text-[1.05em] w-[20px] h-[30x]  items-center   justify-center  '>
                    <InfoIcon className='!text-[1.05em]' />
                  </div>
                </Tooltip>
              )}

              {(!item.isBuy || !item.sPurchaseDate) && !isApplyJob && item.isDisclosure && valueTabs != TabFilterJobseekerCandidate.Invited ? (
                <div className='flex flex-1 justify-end items-center '>
                  <div
                    style={{ borderRadius: "20px" }}
                    onClick={() => {
                      setJsId(item.sId);
                      setIsOpenModalBuyProfile()
                      // _onBuyProfile()
                      setToSelect();
                    }} className=' py-[2px] px-[5px] bg-[#42d5fb]  text-[white] rounded-[15px] sm:rounded text-[0.85em] w-[30px] h-[30px] sm:h-[auto] sm:min-w-[80px] w-[auto] items-center  ease-linear duration-[100ms]  justify-center flex gap-[2px] cursor-pointer hover:bg-[#04a1c8] whitespace-nowrap'>
                    <ContactPageIcon className='!text-[1em]' /><span className='hidden sm:flex'>{i18n(`${i18nField}buy_profile`)}</span>
                  </div>
                </div>
              ) : valueTabs == TabFilterJobseekerCandidate.Invited && !item.isComfirmInvite ? (
                <div className='flex flex-1 justify-end items-center '>
                  <div
                    style={{
                      marginRight: "1%",
                      borderRadius: "20px"
                    }}
                    onClick={() => {
                      setJsId(item.sId);
                      setIsOpenModal();
                    }} className=' py-[2px] px-[5px] bg-[#ffd940]  text-[white] rounded-[15px] sm:rounded text-[0.85em] w-[30px] h-[30px] sm:h-[auto] sm:min-w-[80px] sm:w-[auto] items-center  ease-linear duration-[100ms]  justify-center flex gap-[2px] cursor-pointer hover:bg-[#cfa500]'>
                    <EmailIcon className='!text-[1em]' />
                    <span className='hidden sm:flex'>{i18n(`${i18nField}invite`)}</span>
                  </div>
                </div>
              ) : !item.isBuy && !isApplyJob && !item.isComfirmInvite ? (
                <div className='flex flex-1 justify-end items-center '>
                  <div
                    style={{
                      marginRight: "1%",
                      borderRadius: "20px"
                    }}
                    onClick={() => {
                      setJsId(item.sId);
                      setIsOpenModal();
                    }} className=' py-[2px] px-[5px] bg-[#ffd940]  text-[white] rounded-[15px] sm:rounded text-[0.85em] w-[30px] h-[30px] sm:h-[auto] sm:min-w-[80px] sm:w-[auto] items-center  ease-linear duration-[100ms]  justify-center flex gap-[2px] cursor-pointer hover:bg-[#cfa500]'>
                    <EmailIcon className='!text-[1em]' />
                    <span className='hidden sm:flex'>{i18n(`${i18nField}invite`)}</span>
                  </div>
                </div>
              ) : !item.isBuy && !isApplyJob && item.isComfirmInvite ? (
                <div className='flex flex-1 justify-end items-center '>
                  <div
                    style={{ borderRadius: "20px" }}
                    onClick={() => {
                      _saveViewed();
                      if (valueTabs != TabFilterJobseekerCandidate.Invited)
                        setToSelect();
                      //else //JobPostSentFormTable.JobApply
                    }}
                    className='relative py-[2px] whitespace-nowrap  px-[5px] bg-[#3fd63f]  text-[white] rounded-[15px] sm:rounded text-[0.85em] w-[30px] h-[30px] sm:h-[auto] sm:min-w-[80px] w-[auto] items-center  ease-linear duration-[100ms]  justify-center flex gap-[2px] cursor-pointer hover:bg-[#24a824]'>
                    <CheckCircleIcon className='!text-[1em]' />
                    <Link target="_blank" to={`/EmployerSearchViewJobseeker?sID=${item.sId}`} className='!text-[white]' >
                      <span>{i18n(`${i18nField}view_profile`)}</span>
                    </Link>
                    {item.dViewLast != null && (<span className='absolute !text-[0.6em] bottom-[-2em] text-[#a0a0a0]'>{moment(item.dViewLast).format("DD/MM/yyyy")} </span>)}
                  </div>
                </div>
              ) : /*(!item.isBuy || !item.sPurchaseDate) && isApplyJob && item.isDisclosure && valueTabs != TabFilterJobseekerCandidate.Invited ? */(
                <div className='flex flex-1 justify-end items-center'>
                  <div
                    style={{ borderRadius: "20px" }}
                    onClick={() => {
                      _saveViewed();
                      if (valueTabs != TabFilterJobseekerCandidate.Invited)
                        setToSelect();
                      //else //JobPostSentFormTable.JobApply
                    }}
                    className='relative py-[2px] whitespace-nowrap  px-[5px] bg-[#3fd63f]  text-[white] rounded-[15px] sm:rounded text-[0.85em] w-[30px] h-[30px] sm:h-[auto] sm:min-w-[80px] w-[auto] items-center  ease-linear duration-[100ms]  justify-center flex gap-[2px] cursor-pointer hover:bg-[#24a824]'>
                    <CheckCircleIcon className='!text-[1em]' />
                    <Link target="_blank" to={`/EmployerSearchViewJobseeker?sID=${item.sId}`} className='!text-[white]' >
                      <span>{i18n(`${i18nField}view_profile`)}</span>
                    </Link>
                    {item.dViewLast != null && (<span className='absolute !text-[0.6em] bottom-[-2em] text-[#a0a0a0]'>{moment(item.dViewLast).format("DD/MM/yyyy")} </span>)}
                  </div>
                </div>
              ) /*: <></>*/}
            </div>
          )}

          {(!isEmployerSearch && item.isBuy && arrIdTabNotJobPost.includes(valueTabs)) ?
            (
              <>
                <div className='flex flex-row justify-between'>
                  <label className='font-[500] text-[#1976d2] text-[1.1em] break-all'>
                    {(getLanguage().id === "th") ? item.sSeekerNameTH : item.sSeekerNameEN}
                  </label>

                  <div className='flex flex-row gap-[5px] items-center'>

                    {(valueTabs != TabFilterJobseekerCandidate.Buy_Profile) &&
                      <div onClick={() => { _onFavorite() }}
                        className={` py-[2px]  ${item.isFavorite ?
                          "text-[#ffcc00] font-[600]" :
                          "text-[#656565]"}  rounded-[15px] sm:rounded text-[0.85em] w-[30px] h-[30px] sm:h-[auto] sm:min-w-[80px] sm:w-[auto] items-center  ease-linear duration-[100ms] justify-center flex gap-[2px] cursor-pointer hover:bg-[#f1f1f1]`}>
                        <StarIcon className='!text-[1em]' />
                        <span className='hidden sm:flex'>{i18n(`${i18nField}interested`)}</span>
                      </div>}
                    {!item.isDisclosure && valueTabs != TabFilterJobseekerCandidate.Buy_Profile && (
                      <div
                        style={{
                          ///marginRight: "1%",
                          borderRadius: "20px"
                        }}
                        onClick={() => {
                          setJsId(item.sId);
                          setIsOpenModal();
                        }} className=' py-[2px] px-[5px] bg-[#ffd940]  text-[white] rounded-[15px] sm:rounded text-[0.85em] w-[30px] h-[30px] sm:h-[auto] sm:min-w-[80px] sm:w-[auto] items-center  ease-linear duration-[100ms]  justify-center flex gap-[2px] cursor-pointer hover:bg-[#cfa500]'>
                        <EmailIcon className='!text-[1em]' />
                        <span className='hidden sm:flex'>{i18n(`${i18nField}invite`)}</span>
                      </div>
                    )}
                    {!item.isBuy ? (
                      item.isDisclosure && (<div
                        style={{
                          ///marginRight: "1%",
                          borderRadius: "20px"
                        }}
                        onClick={() => {
                          setJsId(item.sId);
                          setIsOpenModalBuyProfile()
                          // _onBuyProfile()
                        }}
                        className='py-[2px] px-[5px] bg-[#42d5fb] whitespace-nowrap  text-[white] rounded-[15px] sm:rounded text-[0.85em] w-[30px] h-[30px] sm:h-[auto] sm:min-w-[80px] sm:w-[auto] items-center  ease-linear duration-[100ms]  justify-center flex gap-[2px] cursor-pointer hover:bg-[#04a1c8]'>
                        <ContactPageIcon className='!text-[1em]' /><span className='hidden sm:flex'>{i18n(`${i18nField}buy_profile`)}</span>
                      </div>)
                    ) : (
                      <div className='flex flex-1 justify-end items-center'>
                        <div style={{ borderRadius: "20px" }}
                          onClick={() => { _saveViewed() }}
                          className='relative py-[2px] whitespace-nowrap px-[5px] bg-[#3fd63f]  text-[white] rounded-[15px] sm:rounded text-[0.85em] w-[30px] h-[30px] sm:h-[auto] sm:min-w-[80px] w-[auto] items-center  ease-linear duration-[100ms]  justify-center flex gap-[2px] cursor-pointer hover:bg-[#24a824]'>
                          <CheckCircleIcon className='!text-[1em]' />
                          <Link target="_blank" to={`/EmployerSearchViewJobseeker?sID=${item.sId}`} className='!text-[white]'>
                            <span>{i18n(`${i18nField}view_profile`)}</span>
                          </Link>
                          {item.dViewLast != null && (<span className='absolute !text-[0.6em] bottom-[-2em] text-[#a0a0a0]'>{moment(item.dViewLast).format("DD/MM/yyyy")} </span>)}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className='flex flex-row justify-between'>
                  <label className={` ${isEmployerSearch || arrIdTabNotJobPost.includes(valueTabs) ? "text-[#1976d2] font-[500]" : "text-[#1976d2]"} text-[1.1em] break-all	`} >{item.sName ? (item.sName as string).replaceAll("(at)", i18n(`${i18nField}at`)) : i18n(`${i18nField}none_exp`)}</label>
                </div>
              </>
            )
            :
            (
              <div className='flex flex-row justify-between'>

                {(valueTabs != TabFilterJobseekerCandidate.Invited || !isHideJobExpFromInvitedTab) &&
                  <label className={` ${isEmployerSearch || arrIdTabNotJobPost.includes(valueTabs) ? "text-[#1976d2] font-[500]" : "text-[#1976d2]"} text-[1.1em] break-all	`} >
                    {item.sName ? (item.sName as string).replaceAll("(at)", i18n(`${i18nField}at`)) : i18n(`${i18nField}none_exp`)}
                  </label>
                }

                <div className='flex flex-row gap-[5px] items-center'>
                  {isEmployerSearch || arrIdTabNotJobPost.includes(valueTabs) ? (
                    <>

                      {/* สนใจ */}
                      {(valueTabs != TabFilterJobseekerCandidate.Buy_Profile) &&
                        <div onClick={() => { _onFavorite() }} className={` py-[2px]  ${item.isFavorite ? "text-[#ffcc00] font-[600]" : "text-[#656565]"}  rounded-[15px] sm:rounded text-[0.85em] w-[30px] h-[30px] sm:h-[auto] sm:min-w-[80px] sm:w-[auto] items-center  ease-linear duration-[100ms] justify-center flex gap-[2px] cursor-pointer hover:bg-[#f1f1f1]`}>
                          <StarIcon className='!text-[1em]' />
                          <span className='hidden sm:flex'>{i18n(`${i18nField}interested`)}</span>
                        </div>
                      }

                      {/* ไม่ใช่หน้าEmployerSearch */}
                      {!item.isDisclosure && valueTabs != TabFilterJobseekerCandidate.Buy_Profile && !isEmployerSearch && (
                        <div style={{ marginRight: "1%", borderRadius: "20px" }}
                          onClick={() => {
                            setJsId(item.sId);
                            setIsOpenModal();
                          }}
                          className=' py-[2px] px-[5px] bg-[#ffd940] whitespace-nowrap  text-[white] rounded-[15px] sm:rounded text-[0.85em] w-[30px] h-[30px] sm:h-[auto] sm:min-w-[80px] sm:w-[auto] items-center  ease-linear duration-[100ms]  justify-center flex gap-[2px] cursor-pointer hover:bg-[#cfa500]'>
                          <EmailIcon className='!text-[1em]' />
                          <span className='hidden sm:flex'>{i18n(`${i18nField}invite`)}</span>
                        </div>
                      )}


                      {!item.isDisclosure && valueTabs != TabFilterJobseekerCandidate.Buy_Profile && isEmployerSearch && item.objJobPost == null && (item.isComfirmInvite == null || item.isComfirmInvite == false) && (
                        <div style={{ marginRight: "1%", borderRadius: "20px" }}
                          onClick={() => { setJsId(item.sId); setIsOpenModal(); }}
                          className=' py-[2px] px-[5px] bg-[#ffd940] whitespace-nowrap  text-[white] rounded-[15px] sm:rounded text-[0.85em] w-[30px] h-[30px] sm:h-[auto] sm:min-w-[80px] sm:w-[auto] items-center  ease-linear duration-[100ms]  justify-center flex gap-[2px] cursor-pointer hover:bg-[#cfa500]'>
                          <EmailIcon className='!text-[1em]' />
                          <span className='hidden sm:flex'>{i18n(`${i18nField}invite`)}</span>
                        </div>
                      )}

                      {!item.isBuy ?
                        (item.isDisclosure && ((isEmployerSearch || isApplyJob) && item.objJobPost && !item.sPurchaseDate) ?
                          (
                            <div className='flex flex-1 justify-end items-center'>
                              <div style={{ borderRadius: "20px" }}
                                onClick={() => { _saveViewed(); }}
                                className='relative py-[2px] whitespace-nowrap px-[5px] bg-[#3fd63f]  text-[white] rounded-[15px] sm:rounded text-[0.85em] w-[30px] h-[30px] sm:h-[auto] sm:min-w-[80px] w-[auto] items-center  ease-linear duration-[100ms]  justify-center flex gap-[2px] cursor-pointer hover:bg-[#24a824]'>
                                <CheckCircleIcon className='!text-[1em]' /><Link target="_blank" to={`/EmployerSearchViewJobseeker?sID=${item.sId}`} className='!text-[white]'>
                                  <span>{i18n(`${i18nField}view_profile`)}</span>
                                </Link>
                                {item.dViewLast != null && (<span className='absolute !text-[0.6em] bottom-[-2em] text-[#a0a0a0]'>{moment(item.dViewLast).format("DD/MM/yyyy")} </span>)}
                              </div>
                            </div>
                          )
                          :
                          // ไม่ใช่หน้าEmployerSearch
                          item.isDisclosure && (!isEmployerSearch && !isApplyJob && item.objJobPost == null && !item.sPurchaseDate) ?
                            (
                              <div style={{ marginRight: "1%", borderRadius: "20px" }}
                                onClick={() => { setJsId(item.sId); setIsOpenModalBuyProfile() }}
                                className=' py-[2px] px-[5px] bg-[#42d5fb] whitespace-nowrap  text-[white] rounded-[15px] sm:rounded text-[0.85em] w-[30px] h-[30px] sm:h-[auto] sm:min-w-[80px] sm:w-[auto] items-center  ease-linear duration-[100ms]  justify-center flex gap-[2px] cursor-pointer hover:bg-[#04a1c8]'>
                                <ContactPageIcon className='!text-[1em]' />
                                <span className='hidden sm:flex'>{i18n(`${i18nField}buy_profile`)}</span>
                              </div>
                            )
                            :
                            item.isDisclosure && isEmployerSearch && item.objJobPost == null && !item.sPurchaseDate ?
                              (
                                <div style={{ marginRight: "1%", borderRadius: "20px" }}
                                  onClick={() => { setJsId(item.sId); setIsOpenModalBuyProfile() }}
                                  className=' py-[2px] px-[5px] bg-[#42d5fb] whitespace-nowrap  text-[white] rounded-[15px] sm:rounded text-[0.85em] w-[30px] h-[30px] sm:h-[auto] sm:min-w-[80px] sm:w-[auto] items-center  ease-linear duration-[100ms]  justify-center flex gap-[2px] cursor-pointer hover:bg-[#04a1c8]'>
                                  <ContactPageIcon className='!text-[1em]' />
                                  <span className='hidden sm:flex'>{i18n(`${i18nField}buy_profile`)}</span>
                                </div>
                              )
                              : null
                        )
                        :
                        (!isEmployerSearch && item.isComfirmInvite != true && item.objJobPost != null) ?
                          <div className='flex flex-1 justify-end items-center'>
                            <div style={{ borderRadius: "20px" }}
                              onClick={() => { _saveViewed(); }}
                              className='relative py-[2px] whitespace-nowrap px-[5px] bg-[#3fd63f]  text-[white] rounded-[15px] sm:rounded text-[0.85em] w-[30px] h-[30px] sm:h-[auto] sm:min-w-[80px] w-[auto] items-center  ease-linear duration-[100ms]  justify-center flex gap-[2px] cursor-pointer hover:bg-[#24a824]'>
                              <CheckCircleIcon className='!text-[1em]' /><Link target="_blank" to={`/EmployerSearchViewJobseeker?sID=${item.sId}`} className='!text-[white]'>
                                <span>{i18n(`${i18nField}view_profile`)}</span></Link>
                              {item.dViewLast != null && (<span className='absolute !text-[0.6em] bottom-[-2em] text-[#a0a0a0]'>{moment(item.dViewLast).format("DD/MM/yyyy")}</span>)}
                            </div>
                          </div>
                          :
                          (isEmployerSearch && item.sPurchaseDate && item.objJobPost == null) ?
                            <div className='flex flex-1 justify-end items-center'>
                              <div style={{ borderRadius: "20px" }}
                                onClick={() => { _saveViewed(); }}
                                className='relative py-[2px] whitespace-nowrap px-[5px] bg-[#3fd63f]  text-[white] rounded-[15px] sm:rounded text-[0.85em] w-[30px] h-[30px] sm:h-[auto] sm:min-w-[80px] w-[auto] items-center  ease-linear duration-[100ms]  justify-center flex gap-[2px] cursor-pointer hover:bg-[#24a824]'>
                                <CheckCircleIcon className='!text-[1em]' /><Link target="_blank" to={`/EmployerSearchViewJobseeker?sID=${item.sId}`} className='!text-[white]'>
                                  <span>{i18n(`${i18nField}view_profile`)}</span></Link>
                                {item.dViewLast != null && (<span className='absolute !text-[0.6em] bottom-[-2em] text-[#a0a0a0]'>{moment(item.dViewLast).format("DD/MM/yyyy")}</span>)}
                              </div>
                            </div>
                            :
                            (isEmployerSearch && item.sPurchaseDate && item.isDisclosure && item.objJobPost != null && item.isComfirmInvite == null) ?
                              <div className='flex flex-1 justify-end items-center'>
                                <div style={{ borderRadius: "20px" }}
                                  onClick={() => { _saveViewed(); }}
                                  className='relative py-[2px] whitespace-nowrap px-[5px] bg-[#3fd63f]  text-[white] rounded-[15px] sm:rounded text-[0.85em] w-[30px] h-[30px] sm:h-[auto] sm:min-w-[80px] w-[auto] items-center  ease-linear duration-[100ms]  justify-center flex gap-[2px] cursor-pointer hover:bg-[#24a824]'>
                                  <CheckCircleIcon className='!text-[1em]' /><Link target="_blank" to={`/EmployerSearchViewJobseeker?sID=${item.sId}`} className='!text-[white]'>
                                    <span>{i18n(`${i18nField}view_profile`)}</span></Link>
                                  {item.dViewLast != null && (<span className='absolute !text-[0.6em] bottom-[-2em] text-[#a0a0a0]'>{moment(item.dViewLast).format("DD/MM/yyyy")}</span>)}
                                </div>
                              </div>
                              :
                              <></>
                      }


                      {!item.isDisclosure && isEmployerSearch && item.nTypeJobPostSentFormTable == JobPostSentFormTable.Invited && item.objJobPost != null && (
                        <div className='flex flex-1 justify-end items-center'>
                          <div style={{ borderRadius: "20px" }}
                            onClick={() => { _saveViewed(); }}
                            className='relative py-[2px] whitespace-nowrap px-[5px] bg-[#3fd63f]  text-[white] rounded-[15px] sm:rounded text-[0.85em] w-[30px] h-[30px] sm:h-[auto] sm:min-w-[80px] w-[auto] items-center  ease-linear duration-[100ms]  justify-center flex gap-[2px] cursor-pointer hover:bg-[#24a824]'>
                            <CheckCircleIcon className='!text-[1em]' /><Link target="_blank" to={`/EmployerSearchViewJobseeker?sID=${item.sId}`} className='!text-[white]'>
                              <span>{i18n(`${i18nField}view_profile`)}</span></Link>
                            {item.dViewLast != null && (<span className='absolute !text-[0.6em] bottom-[-2em] text-[#a0a0a0]'>{moment(item.dViewLast).format("DD/MM/yyyy")} </span>)}
                          </div>
                        </div>
                      )}

                      {!item.isDisclosure && isEmployerSearch && item.nTypeJobPostSentFormTable != JobPostSentFormTable.Invited && item.objJobPost != null && (
                        <div className='flex flex-1 justify-end items-center'>
                          <div style={{ borderRadius: "20px" }}
                            onClick={() => { _saveViewed(); }}
                            className='relative py-[2px] whitespace-nowrap px-[5px] bg-[#3fd63f]  text-[white] rounded-[15px] sm:rounded text-[0.85em] w-[30px] h-[30px] sm:h-[auto] sm:min-w-[80px] w-[auto] items-center  ease-linear duration-[100ms]  justify-center flex gap-[2px] cursor-pointer hover:bg-[#24a824]'>
                            <CheckCircleIcon className='!text-[1em]' /><Link target="_blank" to={`/EmployerSearchViewJobseeker?sID=${item.sId}`} className='!text-[white]'>
                              <span>{i18n(`${i18nField}view_profile`)}</span></Link>
                            {item.dViewLast != null && (<span className='absolute !text-[0.6em] bottom-[-2em] text-[#a0a0a0]'>{moment(item.dViewLast).format("DD/MM/yyyy")} </span>)}
                          </div>
                        </div>
                      )}

                    </>
                  )
                    :
                    <></>}

                </div>
              </div>
            )}

          {(isEmployerSearch || arrIdTabNotJobPost.includes(valueTabs)) && <div className='flex flex-row'>
            <label className='font-[500]  text-[1em]' >{item.sPosition}</label>
          </div>}

          {industry && <div className='flex flex-row items-center gap-[3px]'>
            <FactoryIcon className='text-[0.6em]' /><span className="text-[1em]">{industry}</span>
          </div>}

          <div className='flex flex-row gap-[5px] flex-wrap'>

            {item.nWorkExperienceYear == null ?
              <></>
              :
              <div className='flex flex-row gap-[3px]'>
                <WorkIcon className='text-[0.6em]' />
                <span className="text-[1em]">
                  {
                    item.nWorkExperienceYear != null ?
                      item.nWorkExperienceYear == 0
                        ? `${i18n(`${i18nField}less_exp_year`)} 1 ${i18n(`${i18nField}exp_year`)}`
                        :
                        `${item.nWorkExperienceYear} ${i18n(`${i18nField}exp_year`)}`
                      :
                      ""
                  }
                </span>
              </div>
            }


            {item.objEducation ?
              <>
                {item.objEducation["sTh"] == null || item.objEducation["sEn"] == null ?
                  <></>
                  :
                  <div className='flex flex-row gap-[3px]'>
                    <SchoolIcon className='text-[0.6em]' />
                    <span className="text-[1em] ">{education}</span>
                  </div>
                }
              </>
              :
              <></>
            }

            {item.objLiveIn ?
              <div className='flex flex-row gap-[3px]'>
                <RoomIcon className='text-[0.6em]' /><span className="text-[1em] ">{liveIn}</span>
              </div>
              :
              <></>
            }

            {item.objNation ?
              <div className='flex flex-row gap-[3px]'>
                <BadgeIcon className='text-[0.6em]' /><span className="text-[1em] ">{nation}</span>
              </div>
              :
              <></>
            }

          </div>

          {item.arrFilterMatch?.length > 0 && <div className='flex flex-row gap-[5px] flex-wrap	'>
            <div className=" flex justify-center items-center">
              <SearchIcon className="!text-[1.2em] font-[600] text-[#1976d2]" />
            </div>
            {item.arrFilterMatch.map((m, i) => {
              return (<div key={`tag_filter_${i}`} className="px-[3px] py-[4px] bg-[#1976d2] rounded border flex">
                <span className="text-[0.6em] font-[600] text-[white]">{replaceDataParams(m)}</span>
              </div>)
            })}
          </div>}

          <Box sx={{ display: "flex", flexDirection: "row", paddingTop: "5px", paddingBottom: "5px" }}>
            {htmlDataFile()}
          </Box>

          {item.sLastedSalary && <div className='flex flex-row items-center gap-[5px]'>
            <span className="text-[0.9em] text-[black]">{`${i18n(`${i18nField}SalaryLasted`)} ${item.sLastedSalary} ${i18n("common.baht")}`}</span>
          </div>}

          <div className='flex flex-row items-center gap-[5px]' >
            {
              item.sExpectSalary === "" ?
                <span className="text-[0.9em] text-[black]">{`${i18n(`${i18nField}SalaryExpect`)} -`}</span>
                :
                <span className="text-[0.9em] text-[black]">{`${i18n(`${i18nField}SalaryExpect`)} ${item.sExpectSalary} ${i18n("common.baht")}`}</span>
            }

            {item.IsNotSpecifiedSalary && <div className="px-[3px] py-[4px] bg-[#ff7f00] rounded border flex" style={{ borderRadius: "20px" }}>
              <span className="text-[0.85em] font-[300] text-[white]">{i18n(`${i18nField}IsNotSpecifiedSalary`)}</span>
            </div>}
          </div>

          {!isEmployerSearch && item.isDeleteAccount && (
            <div className={`flex flex-row text-[0.85em] font-[500] text-[#a1a1a1] items-center gap-[5px]`}>
              <BlockIcon className='!text-[1em]' /> <span className=''> {i18n(`${i18nField}delete_account`)}</span>
            </div>
          )}

          {(isEmployerSearch || arrIdTabNotJobPost.includes(valueTabs)) && (
            <div className={`flex flex-row text-[0.85em] font-[500] items-center gap-[5px] ${item.isDisclosure ? "text-[#00cc1e]" : "text-[#ff7c00]"}`}>
              {item.isDisclosure ? <LockOpenIcon className='!text-[1em]' /> : <LockIcon className='!text-[1em]' />} <span className=''> {item.isDisclosure ? i18n(`${i18nField}open_profile`) : i18n(`${i18nField}limited_profile`)}</span>
            </div>
          )}
          {item.arrTagManage?.length > 0 && <div className='flex flex-row gap-[5px] flex-wrap	'>
            <div className=" flex justify-center items-center">
              <LabelIcon className="!text-[1.2em] font-[600] text-[#00bfa5]" />
            </div>
            {item.arrTagManage.map((m, i) => {
              return (<div key={`tag_filter_${i}`} className="px-[3px] py-[4px] bg-[#00bfa5] gap-[3px] items-center rounded border flex">
                <span className="text-[0.6em] font-[600] text-[white]">{m}</span>
                <CloseIcon onClick={() => {
                  setToSelect();
                  let objReq = {
                    sTag: m,
                    sManageId: item.sManageId
                  }
                  AxiosPost(`${ControllerApiJobSeekerRegisJob}/OnDeleteTag`, objReq, (res) => {
                    item.arrTagManage = item.arrTagManage.filter(f => f !== m) || [];
                    setLstData([...lstData])
                  }, (err) => dispatch(DialogActionCreators.OpenDialogWarning(err.Message) as any))
                  // dispatch(DialogActionCreators.OpenDialogSubmit(`คุณต้องการลบแท็ก "${m}" ใช่หรือไม่ ?`, () => {
                  //     dispatch(DialogActionCreators.CloseDialogSubmit() as any)
                  //     dispatch(DialogActionCreators.OpenDialogSuccess(`ลบแท็ก "${m}" สำเร็จ`))
                  // }))
                }} className="!text-[1em] font-[600] text-[white]" />
              </div>)
            })}
          </div>}

          {/* {item.isBuy && <div
                // onClick={() => setOpenInformation(prev => !prev)} 
                className={`flex flex-row text-[0.85em] cursor-pointer font-[500] items-center gap-[5px] text-[#1976d2]`}>
                <Link to={`/EmployerSearchViewJobseeker?sID=${item.sId}`} className=''><span>{i18n(`${i18nField}view_more`)}</span></Link>
            </div>} */}

          {<div onClick={() => {
            setOpenInformation(prev => !prev)
            if (!isEmployerSearch && !arrIdTabNotJobPost.includes(valueTabs) && valueTabs != TabFilterJobseekerCandidate.Invited) {
              setToSelect();
            }
          }} className={`flex flex-row text-[0.85em] cursor-pointer font-[500] items-center gap-[5px] text-[#1976d2]`}>
            {isOpenInformation ? <ArrowDropUp className='!text-[1em]' /> : <ArrowDropDown className='!text-[1em]' />}<span className=''>{isOpenInformation ? i18n(`${i18nField}view_less`) : i18n(`${i18nField}view_more`)}</span>
          </div>}

          <InfomationJobSeeker
            sId={item.sId}
            isOpenInformation={isOpenInformation}
            isBuyProfile={item.isBuy}
            handleSelectCard={() => {
              if (!isEmployerSearch && !arrIdTabNotJobPost.includes(valueTabs) && valueTabs != TabFilterJobseekerCandidate.Invited) {
                setToSelect();
              }
            }}
          />

          <div className='flex flex-row border-b border-b-[#f1f1f1] my-[5px]  ' />

          {/* if seeker apply */}
          {((!isEmployerSearch && isApplyJob && !item.isBuy) || (isEmployerSearch && !item.sPurchaseDate)) && item.objJobPost != null && item.isComfirmInvite == null ? (
            <div className='flex flex-row'>
              <span className="text-[0.8em] text-[#a1a1a1] ">{i18n(`${i18nField}Applied_to`)} {getDataWithLangCode(item.objJobPost)} ({i18n(`${i18nField}Posted`)}: {item.sPostDate}) {i18n(`${i18nField}On`)} {item.sSendDate} <br /> {i18n(`${i18nField}update_when`)} {item.sUpdateDate}</span>
            </div>
          ) : !isEmployerSearch && item.isBuy && item.objJobPost == null ? (
            <div className='flex flex-row'>
              <span className="text-[0.8em] text-[#a1a1a1] ">{i18n(`${i18nField}NoJob`)},
                <br />{i18n(`${i18nField}Purchase`)} {item.sPurchaseDate ? item.sPurchaseDate : item.sSendDate}, {i18n(`${i18nField}update_when`)} {item.sUpdateDate}</span>
            </div>
          ) : item.sPurchaseDate && isEmployerSearch && item.objJobPost == null ? (
            <div className='flex flex-row'>
              <span className="text-[0.8em] text-[#a1a1a1] ">{i18n(`${i18nField}NoJob`)},
                <br />{i18n(`${i18nField}Purchase`)} {item.sPurchaseDate}, {i18n(`${i18nField}update_when`)} {item.sUpdateDate}</span>
              {/* <span className="text-[0.8em] text-[#a1a1a1] ">{i18n(`${i18nField}Applied_to`)} {getDataWithLangCode(item.objJobPost)}  ,{i18n(`${i18nField}Purchase`)} {item.sPurchaseDate}</span> */}
            </div>
          ) : (((!isEmployerSearch && isApplyJob) && item.isBuy) || (isEmployerSearch && item.sPurchaseDate)) && item.objJobPost != null && item.isComfirmInvite == null ? (
            <div className='flex flex-row'>
              <span className="text-[0.8em] text-[#a1a1a1] ">{i18n(`${i18nField}Applied_to`)} {getDataWithLangCode(item.objJobPost)} ({i18n(`${i18nField}Posted`)}: {item.sPostDate}) {i18n(`${i18nField}On`)} {item.sSendDate},
                <br />{i18n(`${i18nField}Purchase`)} {item.sPurchaseDate ? item.sPurchaseDate : item.sSendDate}, {i18n(`${i18nField}update_when`)} {item.sUpdateDate}
              </span>
            </div>
          ) : isEmployerSearch && item.nTypeJobPostSentFormTable == JobPostSentFormTable.Invited && item.isComfirmInvite == true && item.objJobPost != null ? (
            <div className='flex flex-row'>
              <span className="text-[0.8em] text-[#a1a1a1] ">{i18n(`${i18nField}Applied_to`)} {getDataWithLangCode(item.objJobPost)} ({i18n(`${i18nField}Posted`)}: {item.sPostDate}) {i18n(`${i18nField}On`)} {item.sSendDate} <br /> {i18n(`${i18nField}update_when`)} {item.sUpdateDate}</span>
            </div>
          ) : !isEmployerSearch && valueTabs != TabFilterJobseekerCandidate.Invited && item.nTypeJobPostSentFormTable == JobPostSentFormTable.JobApply ? (
            <div className='flex flex-row'>
              <span className="text-[0.8em] text-[#a1a1a1] ">{i18n(`${i18nField}Applied_to`)} {getDataWithLangCode(item.objJobPost)} ({i18n(`${i18nField}Posted`)}: {item.sPostDate}) {i18n(`${i18nField}On`)} {item.sSendDate} <br /> {i18n(`${i18nField}update_when`)} {item.sUpdateDate}</span>
            </div>
          ) : isEmployerSearch && item.nTypeJobPostSentFormTable == JobPostSentFormTable.Invited && item.isComfirmInvite == false && item.objJobPost != null ? (
            <div className='flex flex-row'>
              <span className="text-[0.8em] text-[#a1a1a1] ">{i18n(`${i18nField}Applied_to`)} {getDataWithLangCode(item.objJobPost)} ({i18n(`${i18nField}Posted`)}: {item.sPostDate}) {i18n(`${i18nField}On`)} {item.sSendDate} <br /> {i18n(`${i18nField}update_when`)} {item.sUpdateDate}</span>
            </div>
          ) : isEmployerSearch && item.nTypeJobPostSentFormTable == JobPostSentFormTable.Invited && item.isComfirmInvite == false && item.objJobPost == null ? (
            <div className='flex flex-row'>
              <span className="text-[0.8em] text-[#a1a1a1] ">{i18n(`${i18nField}NoJob`)}, {i18n(`${i18nField}update_when`)} {item.sUpdateDate}</span>
            </div>
          ) : valueTabs == TabFilterJobseekerCandidate.Invited && item.isComfirmInvite == true ? (
            <div className='flex flex-row'>
              <span className="text-[0.8em] text-[#a1a1a1] ">{i18n(`${i18nField}Applied_to`)} {getDataWithLangCode(item.objJobPost)} ({i18n(`${i18nField}Posted`)}: {item.sPostDate}) {i18n(`${i18nField}On`)} {item.sSendDate} <br /> {i18n(`${i18nField}update_when`)} {item.sUpdateDate}</span>
            </div>
          ) : valueTabs == TabFilterJobseekerCandidate.Invited && item.isComfirmInvite == false ? (
            <div className='flex flex-row'>
              <span className="text-[0.8em] text-[#a1a1a1] ">{i18n(`${i18nField}NoJob`)}, {i18n(`${i18nField}update_when`)} {item.sUpdateDate}</span>
            </div>
          ) : valueTabs == TabFilterJobseekerCandidate.Invited && item.isComfirmInvite == null ? (
            <div className='flex flex-row'>
              <span className="text-[0.8em] text-[#a1a1a1] ">{i18n(`${i18nField}update_when`)} {item.sUpdateDate}</span>
            </div>
          ) : !isEmployerSearch && valueTabs != TabFilterJobseekerCandidate.Invited && item.isComfirmInvite == true ? (
            <div className='flex flex-row'>
              <span className="text-[0.8em] text-[#a1a1a1] ">{i18n(`${i18nField}Applied_to`)} {getDataWithLangCode(item.objJobPost)} ({i18n(`${i18nField}Posted`)}: {item.sPostDate}) {i18n(`${i18nField}On`)} {item.sSendDate} <br /> {i18n(`${i18nField}update_when`)} {item.sUpdateDate}</span>
            </div>
          ) : !isEmployerSearch && valueTabs != TabFilterJobseekerCandidate.Invited && item.isComfirmInvite == false ? (
            <div className='flex flex-row'>
              <span className="text-[0.8em] text-[#a1a1a1] ">{i18n(`${i18nField}NoJob`)}, {i18n(`${i18nField}update_when`)} {item.sUpdateDate}</span>
            </div>
          ) : !isEmployerSearch && valueTabs != TabFilterJobseekerCandidate.Invited && item.isComfirmInvite == null ? (
            <div className='flex flex-row'>
              <span className="text-[0.8em] text-[#a1a1a1] ">{i18n(`${i18nField}update_when`)} {item.sUpdateDate}</span>
            </div>
          ) : <div className='flex flex-row'>
            <span className="text-[0.8em] text-[#a1a1a1] ">{i18n(`${i18nField}update_when`)} {item.sUpdateDate}</span>
          </div>
          }
        </div>
      </Hidden>
    </>
  )
}