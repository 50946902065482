import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
};

export default function ModalViewImage({ image, isOpen, handleClose }) {
    return (
        <Modal
            open={isOpen}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            onBackdropClick={handleClose}
        >
            <Box sx={style}>
                <img src={image} style={{ width: "100%", height: "100%", maxWidth: "80vh", maxHeight: "80vh" }} />
            </Box>
        </Modal>
    );
}