import React, { useState, useMemo, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import {
    Autocomplete,
    TextField,
    FormControl,
    InputLabel,
    FormHelperText,
    OutlinedInput,
    Grid, FormGroup, FormControlLabel, Checkbox, Popper, Paper, ClickAwayListener, Slider, Box, IconButton, InputAdornment
} from "@mui/material";
import { Clear, ArrowDropDown, ArrowDropUp } from "@mui/icons-material"
import PropTypes, { InferProps } from "prop-types";
import { i18n } from "i18n";

// value ของ component จะออกมาในรูปแบบ object
export interface ResultComponent {
    label: string,
    valueOptionStart?: number,
    valueOptionEnd?: number,
    isCustomValue: boolean,
    valueCustomStart?: number,
    valueCustomEnd?: number
}

export function AutoCompleteSelectWithSlider({
    name,
    options,
    label,
    size,
    fullWidth,
    disableClearable,
    required,
    disabled,
    shrink,
    sxLabel,
    small,
    onChange,
    hidden,
    customValue,
    rounded,
    step,
    maxValueSlider, // กำหนดค่าสูงสุดของ slider
    disableZeroOption, // ปิด option ตัวแรกของฝั่งเริ่มต้น
    disableAboveMaxOption, // ปิด option ตัวสุดท้ายของฝั่งสิ้นสุด
    sAboveText,  //ข้อความสำหรับ option ตัวสุดท้าย
    sBetweenText, //ข้อความตรงกลาง
    sTextChangeMode, //ข้อความเปลี่ยนโหลดเป็นกำหนดค่า
    sLabelFirstOptionCustom, // label ของ option แรกในฝั่งเริ่มต้น
    sEndLabelOption, // label ของ option แรกในฝั่งเริ่มต้น
}: InferProps<typeof AutoCompleteSelectWithSlider.propTypes>) {
    const {
        register,
        setValue,
        watch,
        setFocus,
        getValues,
        formState: { errors },
    } = useFormContext();

    const [openOption, setOpenOption] = useState(false)
    const [isCustomValue, setIsCustomValue] = useState(false)
    const [valueOptionStart, setValueOptionStart] = useState(null)
    const [valueOptionEnd, setValueOptionEnd] = useState(null)
    const [tmpOptionEnd, setTmpOptionEnd] = useState([])
    const optionZero = { label: "0", value: -1 };
    const optionAboveMax = { label: `${sAboveText} ${options.length > 0 ? options[options.length - 1].label : ""}`, value: -1 };


    // component โหมดกำหนดค่า
    const SliderState = () => {
        const [lstValueSlider, setValueSlider] = useState([0, 100])
        const [nStartValueSlider, setStartValueSlider] = useState(0)
        const [nEndValueSlider, setEndValueSlider] = useState(maxValueSlider)

        // set ค่าต่างๆลง state และ form
        const setupData = (valueStart, valueEnd) => {
            setStartValueSlider(valueStart as number)
            setEndValueSlider(valueEnd as number)
            setValue(`inp_start_value_custom_${name}`, valueStart as number)
            setValue(`inp_end_value_custom_${name}`, valueEnd as number)
            setValueSlider([valueStart as number, valueEnd as number])
        }

        // เช็คข้อมูลและนำไป set ใส่ state เมื่อมีการเปลี่ยนมาเป็นโหมดกำหนดค่า
        useEffect(() => {
            let objValue = getValues(name);
            if (objValue) {
                if (objValue.valueOptionStart && objValue.valueOptionEnd && !objValue.isCustomValue) {
                    let objOptionStart = options.find(f => f.value == objValue.valueOptionStart);
                    let objOptionEnd = options.find(f => f.value == objValue.valueOptionEnd);
                    if (objValue.valueOptionStart == -1) {
                        objOptionStart = optionZero
                    }
                    if (objValue.valueOptionEnd == -1) {
                        objOptionEnd = options[options.length - 1];
                    }
                    if (objOptionStart && objOptionEnd) {
                        let labelIntStart = objOptionStart.label.replaceAll(",", "");
                        let labelIntEnd = objOptionEnd.label.replaceAll(",", "");
                        setupData(labelIntStart, labelIntEnd)
                    }
                } else if (objValue.isCustomValue) {
                    setupData(objValue.valueCustomStart, objValue.valueCustomEnd)
                }
            } else {
                if (valueOptionStart && valueOptionEnd) {
                    let labelIntStart = valueOptionStart.label.replaceAll(",", "");
                    let labelIntEnd = valueOptionEnd.label.replaceAll(",", "");
                    if (valueOptionStart.value == -1) {
                        labelIntStart = "0"
                    }
                    if (valueOptionEnd.value == -1) {
                        labelIntEnd = options[options.length - 1].label.replaceAll(",", "");
                    }
                    setupData(labelIntStart, labelIntEnd)
                } else {
                    setupData(0, maxValueSlider)
                }
            }
        }, [])


        return (
            <React.Fragment>
                <Box sx={{ width: "100%", borderBottom: "1px solid #d1d1d1", paddingX: "5px", marginBottom: "10px" }} />
                <Grid container paddingX={1} paddingBottom={1} justifyContent="center" alignItems="center" direction="row" >
                    <Grid item >
                        {/* input สำหรับใส่ค่าเริ่มต้น */}
                        <TextField
                            sx={{
                                minWidth: 50,
                                width: `${maxValueSlider.toString().length * 1}vw`
                            }}
                            value={nStartValueSlider}
                            {...register(`inp_start_value_custom_${name}`)}
                            name={`inp_start_value_custom_${name}`}
                            size="small"
                            onChange={(e) => {
                                setStartValueSlider(e.target.value === '' ? 0 : Number(_regexNumberOnly(e.target.value)));
                            }}
                            onBlur={() => {
                                if (nStartValueSlider >= nEndValueSlider) {
                                    lstValueSlider[0] = nEndValueSlider - step
                                } else {
                                    lstValueSlider[0] = nStartValueSlider
                                }
                                setValue(`inp_start_value_custom_${name}`, lstValueSlider[0])
                                setValueSlider([...lstValueSlider]);
                                setStartValueSlider(lstValueSlider[0])
                            }}
                            inputProps={{
                                'aria-labelledby': 'input-slider',
                            }}
                        />
                    </Grid>
                    <Grid item container flex={1} marginX={2} justifyContent="center">
                        {/* input ตัว slide เปลี่ยนค่า */}
                        <Slider
                            max={maxValueSlider}
                            size="small"
                            value={lstValueSlider}
                            onChangeCommitted={(e, v) => {
                            }}
                            onChange={(e, v) => {
                                setupData(v[0], v[1])
                                // setValue(`inp_start_value_custom_${name}`, lstValueSlider[0])
                                // setValue(`inp_end_value_custom_${name}`, lstValueSlider[1])
                                // setValueSlider(v as number[]);
                                // setStartValueSlider(v[0])
                                // setEndValueSlider(v[1])
                            }}
                            step={step}
                        />
                    </Grid>
                    <Grid item >
                        {/* input สำหรับใส่ค่าสิ้นสุด */}
                        <TextField
                            sx={{
                                minWidth: 50,
                                width: `${maxValueSlider.toString().length * 1}vw`
                            }}
                            value={nEndValueSlider}
                            {...register(`inp_end_value_custom_${name}`)}
                            name={`inp_end_value_custom_${name}`}
                            size="small"
                            onChange={(e) => {
                                setEndValueSlider(e.target.value === '' ? 0 : Number(_regexNumberOnly(e.target.value)));
                            }}
                            onBlur={() => {
                                if (nEndValueSlider > maxValueSlider) {
                                    lstValueSlider[1] = maxValueSlider
                                } else if (nEndValueSlider <= nStartValueSlider) {
                                    lstValueSlider[1] = nStartValueSlider + step
                                } else {
                                    lstValueSlider[1] = nEndValueSlider
                                }
                                setValue(`inp_end_value_custom_${name}`, lstValueSlider[1])
                                setValueSlider([...lstValueSlider]);
                                setEndValueSlider(lstValueSlider[1])
                            }}
                            inputProps={{
                                'aria-labelledby': 'input-slider',
                            }}
                        />
                    </Grid>
                </Grid>
            </React.Fragment>
        );
    }

    // function สำหรับใส่ comma ให้ตัวเลขที่ไม่มี comma
    const _stringOfNumberWithComma = (sTextNumber) => {
        const txt = /\B(?=(\d{3})+(?!\d))/g;
        return sTextNumber.replace(txt, ",")
    }
    const _regexNumberOnly = (sValue) => {
        return sValue.replace(/[^0-9]/g, "")
    }

    // assign value ลงใน form 
    useEffect(() => {
        if (!openOption) {
            if (isCustomValue) {
                let objValue: ResultComponent = {
                    isCustomValue: true,
                    label: `${_stringOfNumberWithComma(getValues(`inp_start_value_custom_${name}`).toString())} - ${_stringOfNumberWithComma(getValues(`inp_end_value_custom_${name}`).toString())}`,
                    valueCustomEnd: getValues(`inp_end_value_custom_${name}`),
                    valueCustomStart: getValues(`inp_start_value_custom_${name}`),
                    valueOptionEnd: null,
                    valueOptionStart: null,
                }
                setValue(name, objValue)
                onChange && onChange(objValue)
            } else if (!isCustomValue && valueOptionStart && valueOptionEnd) {
                let objValue: ResultComponent = {
                    isCustomValue: false,
                    label: `${valueOptionStart.label} - ${valueOptionEnd.label}`,
                    valueCustomEnd: null,
                    valueCustomStart: null,
                    valueOptionEnd: valueOptionEnd.value,
                    valueOptionStart: valueOptionStart.value,
                }
                setValue(name, objValue)
                onChange && onChange(objValue)
            }
        } else {
            let objValue = getValues(name);
            if (objValue) {
                setIsCustomValue(objValue.isCustomValue)
                if (objValue.isCustomValue && objValue.valueCustomStart) {
                    setTmpOptionEnd(options.filter(f => parseInt(f.label.replaceAll(",", "")) > objValue.valueCustomStart))
                } else if (!objValue.isCustomValue && objValue.valueOptionStart) {
                    let sTextOptionStart = options.find(f => f.value == objValue.valueOptionStart);
                    if (sTextOptionStart) {
                        let nOptionStart = parseInt(sTextOptionStart.label.replaceAll(",", ""))
                        setTmpOptionEnd(options.filter(f => parseInt(f.label.replaceAll(",", "")) > nOptionStart))
                        setValueOptionStart(sTextOptionStart)
                    } else {
                        setValueOptionStart(optionZero)
                        setTmpOptionEnd(options)
                    }
                    let objOptionEnd = options.find(f => f.value == objValue.valueOptionEnd);
                    setValueOptionEnd(objOptionEnd)
                } else {
                    setTmpOptionEnd(options)
                }
            } else {
                if (options.length == 0) setIsCustomValue(true)
                setTmpOptionEnd(options)
            }

        }
    }, [openOption])

    return (
        <ClickAwayListener onClickAway={() => setOpenOption(false)} >
            <FormControl hiddenLabel={hidden || false} disabled={disabled} sx={{
                width: "100%",
                "label.MuiInputLabel-shrink": {
                    top: "0!important",
                },
                "div.MuiOutlinedInput-root": {
                    borderRadius: rounded ? '2em' : '',
                },
                "label.MuiInputLabel-formControl": {
                    color: Boolean(errors[name]) ? "red" : ""
                },
                "label.MuiInputLabel-formControl > span": {
                    color: "red"
                },
                "div.MuiOutlinedInput-root fieldset.MuiOutlinedInput-notchedOutline": {
                    borderColor: Boolean(errors[name]) ? 'red' : '',
                },
            }}>
                <Autocomplete
                    hidden={hidden || false}
                    renderOption={(props, option: any) => {
                        return (

                            <li {...props} key={option.value}>
                                {option.label}
                            </li>
                        );
                    }}
                    disabled={disabled || null}
                    value={watch(name) || null}
                    fullWidth={fullWidth || null}
                    disableClearable={disableClearable || null}
                    size={size as "small" | "medium"}
                    name={name}
                    open={openOption}
                    PaperComponent={(props) => {
                        // overide paper เดิมด้วยการเขียนใหม่
                        return (<Paper>
                            <Grid container justifyContent={"space-between"} paddingX={1} >
                                <FormGroup onClick={() => {
                                    if (options.length > 0) {
                                        if (isCustomValue) {
                                            let sTextLabelStart = getValues(`inp_start_value_custom_${name}`).toString();
                                            if (sTextLabelStart == "0" && !disableZeroOption) {
                                                setValueOptionStart(optionZero)
                                            } else {
                                                let objOptionStart = options.find(f => f.label == _stringOfNumberWithComma(sTextLabelStart));
                                                setValueOptionStart(objOptionStart);
                                            }
                                            let sTextLabelEnd = getValues(`inp_end_value_custom_${name}`).toString();
                                            let objOptionEnd = options.find(f => f.label == _stringOfNumberWithComma(sTextLabelEnd));
                                            setValueOptionEnd(objOptionEnd);
                                        }
                                        setIsCustomValue(prev => !prev)
                                    }
                                }}>
                                    <FormControlLabel disabled={options.length == 0} checked={isCustomValue} control={<Checkbox disabled={options.length == 0} size={size as "small" | "medium"} />} label={sTextChangeMode} />
                                </FormGroup>
                                {!disableClearable && <IconButton
                                    onClick={() => {
                                        setIsCustomValue(false)
                                        setValueOptionStart(null)
                                        setValueOptionEnd(null)
                                        setValue(name, null)
                                        setOpenOption(false)
                                    }}
                                >
                                    <Clear fontSize={size as "small" | "medium"} />
                                </IconButton>}
                            </Grid>
                            {isCustomValue ? <SliderState /> : (
                                <React.Fragment>
                                    <Box sx={{ width: "100%", borderBottom: "1px solid #d1d1d1", paddingX: "5px", marginBottom: "10px" }} />
                                    <Grid container sx={{ minWidth: "300px", maxHeight: "150px", paddingX: "5px" }} paddingBottom={1} direction="row" justifyContent="center" alignItems="center" >
                                        <Grid item flex={1} container direction="column" flexWrap="nowrap" alignItems="center"
                                            sx={{ overflowX: "hidden", overflowY: "auto", maxHeight: "100px", border: "1px solid #d1d1d1", borderRadius: "10px" }}>
                                            {!disableZeroOption && <Box key={`option_start_all`} sx={{
                                                paddingY: "3px",
                                                cursor: "pointer",
                                                textAlign: "center",
                                                width: "100%",
                                                transition: "background-color 300ms",
                                                backgroundColor: valueOptionStart && valueOptionStart.value == -1 ? "#eaeaea" : "transparent",
                                                ":hover": {
                                                    backgroundColor: "#eaeaea",
                                                }
                                            }} onClick={() => {
                                                setValueOptionStart(optionZero)
                                                setTmpOptionEnd(options)
                                            }} >{sLabelFirstOptionCustom}</Box >}
                                            {options.map((m, i) => {
                                                return (<Box key={`option_start_${i}`} sx={{
                                                    paddingY: "3px",
                                                    cursor: "pointer",
                                                    textAlign: "center",
                                                    width: "100%",
                                                    transition: "background-color 300ms",
                                                    backgroundColor: valueOptionStart && valueOptionStart.value == m.value ? "#eaeaea" : "transparent",
                                                    ":hover": {
                                                        backgroundColor: "#eaeaea",
                                                    }
                                                }} onClick={() => {
                                                    setValueOptionStart(m)
                                                    let nOptionStart = parseInt(m.label.replaceAll(",", ""))
                                                    setTmpOptionEnd(options.filter(f => parseInt(f.label.replaceAll(",", "")) > nOptionStart))
                                                    if (valueOptionEnd) {
                                                        let nOptionEnd = parseInt(valueOptionEnd.label.replaceAll(",", ""))
                                                        if (nOptionEnd <= nOptionStart)
                                                            setValueOptionEnd(null)
                                                    }
                                                }} >{m.label} {sEndLabelOption}</Box >);
                                            })}
                                        </Grid>
                                        <Grid sx={{ marginX: "5%" }} item> {sBetweenText} </Grid>
                                        <Grid item flex={1} container direction="column" flexWrap="nowrap" alignItems="center"
                                            sx={{ overflowX: "hidden", overflowY: "auto", maxHeight: "100px", border: "1px solid #d1d1d1", borderRadius: "10px" }}>
                                            {tmpOptionEnd.map((m, i) => {
                                                return (<Box key={`option_end_${i} `} sx={{
                                                    paddingY: "3px",
                                                    cursor: "pointer",
                                                    textAlign: "center",
                                                    width: "100%",
                                                    transition: "background-color 300ms",
                                                    backgroundColor: valueOptionEnd && valueOptionEnd.value == m.value ? "#eaeaea" : "transparent",
                                                    ":hover": {
                                                        backgroundColor: "#eaeaea",
                                                    }
                                                }} onClick={() => {
                                                    setValueOptionEnd(m)
                                                }}  >{m.label} {sEndLabelOption}</Box >);
                                            })}
                                            {!disableAboveMaxOption && <Box key={`option_end_all`} sx={{
                                                paddingY: "3px",
                                                cursor: "pointer",
                                                textAlign: "center",
                                                width: "100%",
                                                transition: "background-color 300ms",
                                                backgroundColor: valueOptionEnd && valueOptionEnd.value == -1 ? "#eaeaea" : "transparent",
                                                ":hover": {
                                                    backgroundColor: "#eaeaea",
                                                }
                                            }} onClick={() => {
                                                setValueOptionEnd(optionAboveMax)
                                            }} >{sAboveText} {options[options.length - 1]?.label} {sEndLabelOption}</Box >}
                                        </Grid>
                                    </Grid>
                                </React.Fragment>

                            )}
                        </Paper>);
                    }}
                    freeSolo={false}
                    {...register(name)}
                    options={options || []}
                    clearIcon={<Clear fontSize={size as "small" | "medium"} onClick={() => {
                        setIsCustomValue(false)
                        setValueOptionStart(null)
                        setValueOptionEnd(null)
                        setValue(name, null)
                        setOpenOption(false)
                    }} />}
                    renderInput={(params) => {
                        // delete params.InputProps.endAdornment;
                        return (<TextField
                            required={required || null}
                            disabled={disabled || null}
                            label={label}
                            {...params}
                            size={size as "small" | "medium"}
                            onKeyDown={(e) => {
                                e.preventDefault();
                            }}
                            onClick={() => setOpenOption(true)}
                        />)
                    }}
                />
                {errors && errors[name] ?
                    <FormHelperText sx={{ color: "red" }} >{errors[name].message}</FormHelperText>
                    :
                    null}
            </FormControl>
        </ClickAwayListener>
    )
}


AutoCompleteSelectWithSlider.propTypes = {
    required: PropTypes.bool,
    name: PropTypes.string.isRequired,
    options: PropTypes.array,
    label: PropTypes.string,
    size: PropTypes.oneOf(["small", "medium"]),
    fullWidth: PropTypes.bool,
    disableClearable: PropTypes.bool,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    hidden: PropTypes.bool,
    shrink: PropTypes.bool,
    sxLabel: PropTypes.any,
    small: PropTypes.bool,
    rounded: PropTypes.bool,
    customValue: PropTypes.any,
    step: PropTypes.number,
    maxValueSlider: PropTypes.number,
    disableZeroOption: PropTypes.bool,
    disableAboveMaxOption: PropTypes.bool,
    sAboveText: PropTypes.string,
    sBetweenText: PropTypes.string,
    sTextChangeMode: PropTypes.string,
    sLabelFirstOptionCustom: PropTypes.string,
    sEndLabelOption: PropTypes.string
};

AutoCompleteSelectWithSlider.defaultProps = {
    required: false,
    options: [],
    label: "",
    size: "small",
    customValue: null,
    step: 1,
    maxValueSlider: 100,
    disableZeroOption: false,
    disableAboveMaxOption: false,
    sAboveText: "มากกว่า",
    sBetweenText: "ถึง",
    sTextChangeMode: "กำหนดค่า",
    sLabelFirstOptionCustom: "0",
    sEndLabelOption: ""
};