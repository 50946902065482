import { useState, useEffect } from 'react';
import { Box, Divider, FormControlLabel, Grid, Stack, Typography } from '@mui/material';
import { useNavigate, useLocation } from "react-router-dom";
import { BtnBack, BtnSave } from 'components/Common/Button';
import { AxiosPost, ResultAPI } from 'service/CommonFunction/TS_function';
import { DialogActionCreators } from 'store/redux/DialogAlert';
import { useDispatch } from "react-redux"
import { FormProvider, useForm } from 'react-hook-form';
import Input from "components/Common/ElementInForm/InputCustomLabelFormItem";
import yupFormSchemas from 'components/Common/yup/yupFormSchemas';
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import IOSSwitch from 'components/Common/ElementInForm/IosSwitch';
import { i18n } from 'i18n';

interface saveData {
  sID?: string,
  sDataTypeID: string,
  // nSaveOrder: number,
  isDataActive: boolean,
  sDataName: string,
  sDataDescription: string
}
export interface dataSource {
  isDataActive: boolean,
  sDataName: string,
  sDataDescription: string
}
export default function CustomerCreateForm() {
  const history = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const searchParams = location.search;
  const sID = new URLSearchParams(searchParams).get('sID');
  const mId = new URLSearchParams(searchParams).get('mId');
  const isEditMode = sID ? true : false;
  const [isDataActive, setIsActive] = useState(true);
  const [lstSeverityData, setLstSeverityData] = useState([]);
  const [lstComponent, setLstComponent] = useState([]);

  const onBack = () => {
    history(-1)
  }
  
  const [objSchema, setObjSchema] = useState({
    sDataName: yupFormSchemas.string("Name", { required: true })
  });
  const schema = yup.object().shape(objSchema);
  const form = useForm({
      resolver: yupResolver(schema),
      shouldUnregister: false,
      shouldFocusError: true,
      mode: "all",
      // defaultValues: initialValues as any,
  });

  useEffect(() => {
    AxiosPost("MasterData/GetDetail", { sID: sID }, (res) => {     
      ResultAPI(res, "", () => {   
        setLstSeverityData(res.lstServerity)
        if (isEditMode) {          
          let resData = res.lstSaveData[0];
          form.setValue("sDataName", resData.sDataName);
          form.setValue("sDataDescription", resData.sDataDescription);
          // form.setValue("isDataActive", resData.isDataActive);
          setIsActive(resData.isDataActive || false);
        } else {
          setLstComponent([
            { sDataName: "", sDataDescription: [], id: 1}
          ]);
        }
      });
    }, (err) => {
      if (!err.response) {
        history("/error", {replace: true})
        return;
      }
      if (err.response && err.response.status === 401) {
        dispatch(DialogActionCreators.OpenDialogWarning("Token expired.", () => {
          history("/", {replace: true})
        }) as any)
      }
    });
  }, [])

  const onSubmit = (e) => {
    dispatch(DialogActionCreators.OpenDialogSubmit(i18n(`common.AlertConfrimSave`), () => {
      dispatch(DialogActionCreators.CloseDialogSubmit() as any);
      
      let objData: saveData = {
          sDataName: e['sDataName'],
          sDataDescription: e['sDataDescription'],
          isDataActive: isDataActive,
          sDataTypeID: mId
      }      
      AxiosPost("MasterData/SaveData_MasterData", objData, (result) => {             
        ResultAPI(result, i18n(`common.AlertConfrimSave`), () => {
          onBack()
        });
      }, (err) => {      
        if (!err.response) {
          history("/error", {replace: true})
          return;
        }
      });
    }) as any);
  }

  return (
  <FormProvider {...form}>
    <Stack spacing={2} direction="column" sx={{ border: "1px #eeeeee solid", backgroundColor: "rgba(255,255,255,0.75)", borderRadius: 5, p: 2, flex: 1 }}>
      <Stack direction="column" spacing={2}>
        <Stack direction="row" spacing={2}>
        <Input
            name={"sDataName"}
            fullWidth
            required
            maxLength={100}
            label={
              <>
                <Typography sx={{ fontWeight: 600 }} component="label">Code</Typography>
            </>}
            labelString={""}
          />

          <Input
            name={"sDataName"}
            fullWidth
            required
            maxLength={100}
            label={
              <>
                <Typography sx={{ fontWeight: 600 }} component="label">Name & Surname</Typography>
            </>}
            labelString={""}
          />
        </Stack>        
        <Input
          name={"sDataDescription"}
          fullWidth
          maxLength={100}
          label={
            <>
              <Typography sx={{ fontWeight: 600 }} component="label">Unit</Typography>
          </>}
          labelString={""}
        />

        <Input
          name={"sDataDescription"}
          fullWidth
          maxLength={100}
          label={
            <>
              <Typography sx={{ fontWeight: 600 }} component="label">Unit name</Typography>
          </>}
          labelString={""}
        />

        <Input
          name={"sDataDescription"}
          fullWidth
          maxLength={100}
          label={
            <>
              <Typography sx={{ fontWeight: 600 }} component="label">Note</Typography>
          </>}
          labelString={""}
        />
      </Stack>

        <Grid item xs={12}>
          <Typography>
            <span style={{ fontWeight: 600 }}> {"Status"} </span>
            <span style={{ color: "red" }}> *</span>
          </Typography>
          <Typography>
            <FormControlLabel
              control={
                <IOSSwitch
                  sx={{ m: 1 }}
                  onChange={() => setIsActive(!isDataActive)}
                  checked={isDataActive}
                />
              }
              label={isDataActive ? "Active" : "Inactive"}
            />
          </Typography>
        </Grid>

      <Divider />

      <Stack direction="row" spacing={1} sx={{ alignSelf: 'center' }}>        
        <BtnSave onClick={form.handleSubmit(e => (
          onSubmit(e)
        ))} />

        <Box sx={{ flex: 1 }}>
          <BtnBack onClick={() => onBack()} />
        </Box>
      </Stack>  

      
    </Stack>    
  </FormProvider>
  );
}