
import { useState, useEffect, useContext } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Grid, Typography,  Stack, FormHelperText } from '@mui/material';
import { i18n } from 'i18n';
import { ProfileContext } from './Context/ProfileContext';
import { DialogActionCreators } from "store/redux/DialogAlert";
import { useDispatch } from "react-redux";
import { AccordionCustom, AccordionDetailsCustom, AccordionSummaryCustom } from './ElementCustom';
import { BtnBack, BtnEditProfile, BtnSubmit } from 'components/Common/Button';
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from 'react-router-dom';
import AutoCompleteRenderItem from 'components/Common/ElementInForm/AutoCompleteRenderItem';
import { AxiosGet, AxiosPost } from 'service/CommonFunction/TS_function';
import { FnBlock_UI } from 'service/SystemFunction/SystemFunction';
import yupFormSchemas from 'components/Common/yup/yupFormSchemas';
import CardComplete from './CardComplete';
import CardInComplete from './CardInComplete';
import secureLocalStorage from 'react-secure-storage';

const i18nField = 'entities.SeekerProfile';
const i18nCommon = 'common';
const PanelAbility = ({ Edit, isExpanded, isJobApply, sIDNew ,onBackEdit}) => {
    const navigate = useNavigate();
    const {
        profileContext,
        isLoading,
        isPanelAbilityEdit,
        setIsPanelAbilityEdit,
        ability,
        setAbility,
    } = useContext(ProfileContext);

    const { BlockUI, UnBlockUI } = FnBlock_UI();
    const history = useNavigate();
    const [expandedAbility, setExpandedAbility] = useState(false);
    const dispatch = useDispatch();
    const [lstAbility, setlstAbility] = useState([]);
    const [formSchema, setFormSchema] = useState<any>({
        sAbility: yupFormSchemas.stringArray(i18n(`${i18nField}.Ability`)),
    });
    const schema = yup.object().shape(formSchema);
    const form = useForm({
        resolver: yupResolver(schema),
        shouldUnregister: false,
        shouldFocusError: true,
        mode: "all",
    });
    const [isComPlete, setisComPlete] = useState(false);

    useEffect(() => {
        GetMyProfileStatus();
        GetMyProfile();
    }, []);


    const GetMyProfileStatus = () => {
        let param = {
            nPanel: 9
        }
        AxiosPost("JobSeeker/GetMyProfileStatus", param, (res) => {
          setisComPlete(res.IsCompleted);
    
        });
      };
    
      const GetMyProfile = () => {
        AxiosGet("JobSeeker/GetMyProfileAbility", {}, (res) => {
        //  setisComPlete(res.isAbility);
          form.setValue("sAbility", res.listAbility || []);
          setlstAbility(res.listAbility || []);
        });
      };
   

    const handleEditWorkUnit = () => {
        let sJobApply = "";
        let sID = "";
        if (isJobApply) {
            sJobApply = "sJobApply";
            sID = sIDNew;
        }
        history(`/MyProfileEdit?sMode=PanelAbility&sJobApply=${sJobApply}&sIDNew=${sID}`);
    }
    const onBack = () => {
        setIsPanelAbilityEdit(false)
        if (isJobApply) {
            let objProfile = {
                sMode: "PanelAbility",
            };
            let objJobDetail = {
                sID: sIDNew || "",
                isApply: true,
                sFromPage: "JobDetail",
            };
            onBackEdit(objProfile,objJobDetail);

        } else {
            history(`/MyProfile?sMode=PanelAbility`);
        }
    }

    const onGoPage = () => {
        if (isJobApply) {
            let objProfile = {
                sMode: "PanelAbility",
            };
            let objJobDetail = {
                sID: sIDNew || "",
                isApply: true,
                sFromPage: "JobDetail",
            };
            onBackEdit(objProfile,objJobDetail);
        } else {
            history("/MyProfile?sMode=PanelAbility");
        }
    }

    useEffect(() => {
        setIsPanelAbilityEdit(Edit);
        setExpandedAbility(isExpanded);
    }, [])


    const onSubmit = (values) => {
        const dataProfile = {
            listAbility: values.sAbility || [],
            nMode: 9,
        };

        dispatch(
            DialogActionCreators.OpenDialogSubmit(i18n(`${i18nField}.ConfirmMsgUpdate`), () => {
                dispatch(DialogActionCreators.CloseDialogSubmit() as any);
                dispatch(DialogActionCreators.LoadSubmit("Waiting...") as any);
                BlockUI();
                AxiosPost(`JobSeeker/UpdateProfile`, dataProfile, (result) => {
                    let ProfileNoti = secureLocalStorage.getItem("ProfileNoti");
                    secureLocalStorage.setItem("ProfileNoti", ProfileNoti === "true" ? "false" : "true");
                    UnBlockUI();
                    const res = result.Data;
                    if (res.IsSuccess) {
                        dispatch(DialogActionCreators.OpenDialogSuccess(i18n(`${i18nField}.MsgPanelAbility`), () => { onGoPage(); })
                        );
                    }
                },
                    (err) => {
                        if (!err.response) {
                            history("/error");
                            return;
                        }
                    }
                );
            }
            ) as any
        );
    };


    useEffect(() => {
        if (isPanelAbilityEdit) {
            let arrAbility = [];
            if (form.getValues("sAbility")) {
                arrAbility = form.getValues("sAbility")
                if (arrAbility.length > 0) {
                    arrAbility.forEach(f => {
                        if (f.length > 1000) {
                            dispatch(DialogActionCreators.OpenDialogWarning(i18n(`${i18nField}.AbilityWarning`)));
                        }
                    })
                }
            }
        }

    }, [form.getValues()]);
    return (
        <FormProvider {...form} >
            <AccordionCustom
                sx={{ border: "10px", p: 1, flex: 1 }}
                expanded={expandedAbility}
                onChange={() => setExpandedAbility((prev) => !prev)}
            >
                <AccordionSummaryCustom>
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                       <Grid item xs={6} sm={8} md={9}>
                            <Typography >
                                {i18n(`${i18nField}.PanelAbility`)}
                            </Typography>
                        </Grid>
                        <Grid item container
                            justifyContent="flex-end" xs={6} sm={4} md={3}>
                                   <CardComplete isComPlete ={isComPlete} />
                         
                        </Grid>
                    </Grid>
                
                </AccordionSummaryCustom>
                <AccordionDetailsCustom>
                    <Grid
                        sx={{ border: "10px", p: 1, flex: 1, m: "2%" }}
                        item
                        md={12}
                        xs={12}
                    >
                        <AutoCompleteRenderItem
                            isFreeCreate
                            name={"sAbility"}
                            fullWidth
                            label={i18n(`${i18nField}.Ability`)}
                            options={[]}
                            disabled={!isPanelAbilityEdit}
                            maxLength={1000}
                        />
                        <FormHelperText sx={{ color: "grey" }}>
                            {i18n(`${i18nField}.MsgSkill`)}
                        </FormHelperText>
                    </Grid>
                    {!isPanelAbilityEdit && (
                        <Stack
                            sx={{ border: "10px", p: 1, flex: 1, m: "2%" }}
                            direction="row"
                        >
                            <BtnEditProfile
                                txt={i18n(`${i18nField}.Edit`)}
                                onClick={handleEditWorkUnit}
                            />
                        </Stack>
                    )}
                </AccordionDetailsCustom>
            </AccordionCustom >
            {isPanelAbilityEdit && (
                <Stack
                    spacing={1}
                    sx={{ border: "10px", p: 1, flex: 1, mt: "2%" }}
                    direction="row"
                    justifyContent="space-between"
                    alignItems="flex-start"
                >
                    <BtnBack
                        onClick={() => { onBack(); }}
                        txt={i18n(`${i18nCommon}.back`)}
                    />
                    <BtnSubmit
                        onClick={form.handleSubmit(onSubmit, (err) => console.log("error", err))}
                        txt={i18n(`${i18nCommon}.save`)}
                    />
                </Stack>
            )}
        </FormProvider >
    )
}

export default PanelAbility;