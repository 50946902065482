import React, { useState, useEffect } from 'react'
import { Box, Button, Grid, Stack, Typography, Tooltip } from "@mui/material";
import { AxiosPost, GetPermission, ResultAPI } from "service/CommonFunction/TS_function";
import { DataGridMui as Table, FilterFieldInterface, initRows, PaginationInterface } from "components/Common/Table/DataGridMui";
import { GridColumns } from "@mui/x-data-grid";
import { BtnAddOnTable, BtnEditOnTable, BtnViewOnTable } from "components/Common/Button"
import { DialogActionCreators } from 'store/redux/DialogAlert';
import { i18n } from 'i18n';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import secureLocalStorage from 'react-secure-storage';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import AutoCompleteInTable from 'components/Common/Table/AutoCompleteInTable';


const Managepromotion = () => {
  const sPathName = window.location.pathname;
  /////usePageTracking();

  const [open, setOpen] = React.useState(false);
  const [openCoin, setOpenCoin] = React.useState(false);

  const i18nDialog = 'dialog';
  const dispatch = useDispatch();
  const [permission, setPermission] = useState(0);
  const [loadingTable, setLoadingTable] = useState(true);
  const [loadingTableCoin, setLoadingTableCoin] = useState(true);
  const [loadingTableOrder, setLoadingTableOrder] = useState(true);
  const [loadingTableOrderCoin, setLoadingTableOrderCoin] = useState(true);

  const navigate = useNavigate();
  const history = useNavigate();


  let arrrowJob = {
    ...initRows,
    sSortExpression: "sLastUpDate",
    sSortDirection: "desc"
  }
  const sStorageJob = secureLocalStorage.getItem(sPathName + "1");
  if (sStorageJob && sStorageJob !== "") {
    const objRowTemp = JSON.parse(sStorageJob + "");
    if (objRowTemp != null) {
      arrrowJob = objRowTemp;
      arrrowJob.arrRows = [];
    }
  }
  const [dataRow, setDataRow] = useState<PaginationInterface>(arrrowJob);
  // const [dataRow, setDataRow] = useState<PaginationInterface>({
  //    ...initRows,
  //    sSortExpression: "dUpdate",
  //    sSortDirection: "desc"
  // });



  let arrrowCoin = {
    ...initRows,
    sSortExpression: "sLastUpDate",
    sSortDirection: "desc"
  }
  const sStorageCoin = secureLocalStorage.getItem(sPathName + "2");
  if (sStorageCoin && sStorageCoin !== "") {
    const objRowTemp = JSON.parse(sStorageCoin + "");
    if (objRowTemp != null) {
      arrrowCoin = objRowTemp;
      arrrowCoin.arrRows = [];
    }
  }
  const [dataRowCoin, setDataRowCoin] = useState<PaginationInterface>(arrrowCoin);
  // const [dataRowCoin, setDataRowCoin] = useState<PaginationInterface>({
  //    ...initRows,
  //    sSortExpression: "dUpdate",
  //    sSortDirection: "desc"
  // });


  const [dataRowOrderJob, setDataRowOrderJob] = useState<PaginationInterface>({
    ...initRows,
    // sSortExpression: "dUpdate",
    // sSortDirection: "desc"
  });

  const [dataRowOrderCoin, setDataRowOrderCoin] = useState<PaginationInterface>({
    ...initRows,
    // sSortExpression: "dUpdate",
    // sSortDirection: "desc"
  });


  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpenCoin = () => {
    setOpenCoin(true);
  };

  const handleCloseCoin = () => {
    setOpenCoin(false);
  };


  useEffect(() => {
    GetPermission(64, setPermission);
    onloadJobData(dataRow);
    onloadCoinData(dataRowCoin);
    onLoadDataOrderJob(dataRowOrderJob);
    onLoadDataOrderCoin(dataRowOrderCoin);
  }, []);


  const onloadJobData = (p: PaginationInterface) => {
    setLoadingTable(true);
    const param = {
      ...p,
    }
    AxiosPost("ManagePromotion/GetDataJobTable", param, (res) => {
      ////console.log("GetDataJobTable", res);
      setDataRow({
        ...p,
        arrRows: res.lstPromotionDetail,
        nDataLength: res.nDataLength,
        nPageIndex: res.nPageIndex,
      });
      setLoadingTable(false);
    })
  }

  const onloadCoinData = (p: PaginationInterface) => {
    setLoadingTableCoin(true);
    const param = {
      ...p,
    }
    AxiosPost("ManagePromotion/GetDataCoinTable", param, (res) => {
      ////console.log("GetDataCoinTable", res);
      setDataRowCoin({
        ...p,
        arrRows: res.lstPromotionDetail,
        nDataLength: res.nDataLength,
        nPageIndex: res.nPageIndex,
      });
      setLoadingTableCoin(false);
    })
  }

  const onLoadDataOrderJob = (p: PaginationInterface) => {
    ////console.log("dataRowOrder", dataRowOrder);
    setLoadingTableOrder(true);
    const param = {
      ...p,
      nPackageType: 1,
    }
    AxiosPost("ManagePromotion/GetDataOrderTable", param, (res) => {
      ////console.log("onLoadDataOrderJob", res);
      setDataRowOrderJob({
        ...p,
        arrRows: res.lstRenderOrder,
        nDataLength: res.nDataLength,
        nPageIndex: res.nPageIndex,
      });
      setLoadingTableOrder(false);
      ///setLoadingTableCoin(true);
    })
  }

  const onLoadDataOrderCoin = (p: PaginationInterface) => {
    setLoadingTableOrderCoin(true);
    const param = {
      ...p,
      nPackageType: 2,
    }
    AxiosPost("ManagePromotion/GetDataOrderTable", param, (res) => {
      ////console.log("onLoadDataOrderCoin", res);
      setDataRowOrderCoin({
        ...p,
        arrRows: res.lstRenderOrder,
        nDataLength: res.nDataLength,
        nPageIndex: res.nPageIndex,
      });
      setLoadingTableOrderCoin(false);
    })
  }


  const onAdd = (type) => {
    if (type === "job") {
      history(`/ManagePromotionJob?sID=${"0"}`);
    }
    else if (type === "coin") {
      history(`/ManagePromotionCoin?sID=${"0"}`);
    }
  }

  const onEdit = (sID: string, sIDEnc, type) => {
    if (type === "job") {
      history(`/ManagePromotionJob?sIDEnc=${sIDEnc}&sID=${sID}`);
    }
    else if (type === "coin") {
      history(`/ManagePromotionCoin?sIDEnc=${sIDEnc}&sID=${sID}`);
    }
  };


  //================== Job ==================
  const dataColumn: GridColumns = [
    {
      renderHeader: (item) => {
        const html = <BtnAddOnTable
          IsDisabled={permission !== 2}
          onClick={() => { onAdd("job") }}
          txt={"เพิ่ม"}
        />
        return (html)
      },
      headerName: "",
      field: "ปุ่ม (เพิ่ม/แก้ไข)",
      type: "actions",
      width: 70,
      resizable: false,
      sortable: false,
      getActions: (item) => {
        let html = <></>
        if (permission === 1) {
          html =
            <BtnViewOnTable
              IsDisabled={false}
              txt="ดู"
              onClick={() => onEdit(item.row.sID, item.row.sIDEnc, "job")}
            />
        }
        else if (permission === 2) {
          html = <BtnEditOnTable
            IsDisabled={permission !== 2}
            txt={'แก้ไข'}
            onClick={() => onEdit(item.row.sID, item.row.sIDEnc, "job")}
          />
        }
        return [html]
      },
    },
    {
      field: "sPromotionName",
      headerName: "รายการโปรโมชั่น",
      headerAlign: "center",
      align: "left",
      resizable: false,
      flex: 1,
      minWidth: 150,
      renderCell: (item) => {
        ///console.log(item);
        const html = <Box sx={{ overflow: 'hidden' }}>
          <Tooltip title={item.row.sPromotionName}>
            <Box>{item.row.sPromotionName}</Box>
          </Tooltip>
        </Box>
        return (html)
      },
    },
    {
      field: "sPackageName",
      headerName: "รายการแพ็กเกจ",
      headerAlign: "center",
      align: "left",
      resizable: false,
      flex: 1,
      minWidth: 350,
      renderCell: (item) => {
        const html = <Box sx={{ overflow: 'hidden' }}>
          <Tooltip title={item.row.sPackageName}>
            <Box>{item.row.sPackageName}</Box>
          </Tooltip>
        </Box>
        return (html)
      },
    },
    {
      field: "sPrice",
      headerName: " ราคารวม",
      headerAlign: "center",
      align: "right",
      resizable: false,
      flex: 1,
      minWidth: 120,
    },
    {
      field: "sStartDate",
      headerName: "วันที่เริ่มต้นโปรโมชั่น",
      headerAlign: "center",
      align: "center",
      resizable: false,
      flex: 1,
      minWidth: 170
    },
    {
      field: "sEndDate",
      headerName: "วันที่สิ้นสุดโปรโมชั่น",
      headerAlign: "center",
      align: "center",
      resizable: false,
      flex: 1,
      minWidth: 170,
    },
    {
      field: "sStatus",
      headerName: "สถานะ",
      headerAlign: "center",
      align: "center",
      resizable: false,
      flex: 1,
      minWidth: 120,
    },
    {
      field: "sLastUpDate",
      headerName: "แก้ไขล่าสุด",
      headerAlign: "center",
      align: "center",
      resizable: false,
      flex: 1,
      minWidth: 140,
    },
  ];

  const filter: FilterFieldInterface[] = [
    { sTypeFilterMode: "input", sFieldName: "sPromotionName" },
    { sTypeFilterMode: "input", sFieldName: "sPackageName" },
    { sTypeFilterMode: "input", sFieldName: "sPrice" },
    { sTypeFilterMode: "daterange", sFieldName: "sStartDate" },
    { sTypeFilterMode: "daterange", sFieldName: "sEndDate" },
    {
      sTypeFilterMode: "select", sFieldName: "sStatus", lstDataSelect: [
        { value: "รอประกาศ", keyId: 1, label: "รอประกาศ" },
        { value: "กำลังประกาศ", keyId: 2, label: "กำลังประกาศ" },
        { value: "หมดอายุ", keyId: 3, label: "หมดอายุ" },
        { value: "ไม่ใช้งาน", keyId: 4, label: "ไม่ใช้งาน" },
      ],
    },
    { sTypeFilterMode: "daterange", sFieldName: "sLastUpDate" },
  ]


  const onDelete = (item) => {
    dispatch(DialogActionCreators.OpenDialogSubmit(i18n(`common.AlertConfrimDel`), () => {
      dispatch(DialogActionCreators.CloseDialogSubmit() as any);
      dispatch(DialogActionCreators.LoadSubmit("Waiting...") as any);

      AxiosPost(`ManagePromotion/Delete`, item, (result) => {
        dispatch(DialogActionCreators.CloseDialogSubmit() as any);
        ResultAPI(result, i18n(`${i18nDialog}.DialogRemoveSuccess`), () => {
          dispatch(DialogActionCreators.OpenDialogSuccess(i18n(`${i18nDialog}.DialogRemoveSuccess`)) as any)
        });
        onloadJobData(dataRow);
        onloadCoinData(dataRowCoin);

        onLoadDataOrderJob(dataRowOrderJob);
        onLoadDataOrderCoin(dataRowOrderCoin);
      })
    }) as any)
  }


  //================== Coin ==================
  const dataColumnCoin: GridColumns = [
    {
      renderHeader: (item) => {
        const html = <BtnAddOnTable
          IsDisabled={permission !== 2}
          onClick={() => { onAdd("coin") }}
          txt={"เพิ่ม"}
        />
        return (html)
      },
      headerName: "",
      field: "ปุ่ม (เพิ่ม/แก้ไข)",
      type: "actions",
      width: 70,
      resizable: false,
      sortable: false,
      getActions: (item) => {
        let html = <></>
        if (permission === 1) {
          html =
            <BtnViewOnTable
              IsDisabled={false}
              txt="ดู"
              onClick={() => onEdit(item.row.sID, item.row.sIDEnc, "coin")}
            />
        }
        else if (permission === 2) {
          html = <BtnEditOnTable
            IsDisabled={permission !== 2}
            txt={'แก้ไข'}
            onClick={() => onEdit(item.row.sID, item.row.sIDEnc, "coin")}
          />
        }
        return [html]
      },
    },
    {
      field: "sPromotionName",
      headerName: "รายการโปรโมชั่น",
      headerAlign: "center",
      align: "left",
      resizable: false,
      flex: 1,
      minWidth: 150,
      renderCell: (item) => {
        const html = <Box sx={{ overflow: 'hidden' }}>
          <Tooltip title={item.row.sPromotionName}>
            <Box>{item.row.sPromotionName}</Box>
          </Tooltip>
        </Box>

        return (html)
      },
    },
    {
      field: "sPackageName",
      headerName: "รายการแพ็กเกจ",
      headerAlign: "center",
      align: "left",
      resizable: false,
      flex: 1,
      minWidth: 350,
      renderCell: (item) => {
        const html = <Box sx={{ overflow: 'hidden' }}>
          <Tooltip title={item.row.sPackageName}>
            <Box>{item.row.sPackageName}</Box>
          </Tooltip>
        </Box>

        return (html)
      },
    },
    {
      field: "sPrice",
      headerName: "ราคารวม",
      headerAlign: "center",
      align: "right",
      resizable: false,
      flex: 1,
      minWidth: 120,
    },
    {
      field: "sStartDate",
      headerName: "วันที่เริ่มต้นโปรโมชั่น",
      headerAlign: "center",
      align: "center",
      resizable: false,
      flex: 1,
      minWidth: 170
    },
    {
      field: "sEndDate",
      headerName: "วันที่สิ้นสุดโปรโมชั่น",
      headerAlign: "center",
      align: "center",
      resizable: false,
      flex: 1,
      minWidth: 170,
    },
    {
      field: "sStatus",
      headerName: "สถานะ",
      headerAlign: "center",
      align: "center",
      resizable: false,
      flex: 1,
      minWidth: 120,
    },
    {
      field: "sLastUpDate",
      headerName: "แก้ไขล่าสุด",
      headerAlign: "center",
      align: "center",
      resizable: false,
      flex: 1,
      minWidth: 140,
    },
  ];


  const filterCoin: FilterFieldInterface[] = [
    { sTypeFilterMode: "input", sFieldName: "sPromotionName" },
    { sTypeFilterMode: "input", sFieldName: "sPackageName" },
    { sTypeFilterMode: "input", sFieldName: "sPrice" },
    { sTypeFilterMode: "daterange", sFieldName: "sStartDate" },
    { sTypeFilterMode: "daterange", sFieldName: "sEndDate" },
    {
      sTypeFilterMode: "select", sFieldName: "sStatus", lstDataSelect: [
        { value: "รอประกาศ", keyId: 1, label: "รอประกาศ" },
        { value: "กำลังประกาศ", keyId: 2, label: "กำลังประกาศ" },
        { value: "หมดอายุ", keyId: 3, label: "หมดอายุ" },
        { value: "ไม่ใช้งาน", keyId: 4, label: "ไม่ใช้งาน" },
      ],
    },
    { sTypeFilterMode: "daterange", sFieldName: "sLastUpDate" },
  ]


  //================== Order ==================
  const filterOrder: FilterFieldInterface[] = [
  ]

  const dataColumnOrder: GridColumns = [
    {
      headerName: "ลำดับที่",
      field: "ปุ่ม (เพิ่ม/แก้ไข)",
      type: "actions",
      width: 150,
      resizable: false,
      sortable: false,
      getActions: (item) => {
        const lstOrder = item.row.lstOrder;
        const html = <AutoCompleteInTable
          small
          disabled={permission !== 2}
          objValue={{
            label: item.row.nOrder,
            value: item.row.sID,
          }}
          funcOnChange={(newValue: any) => {
            const nCategoryID = item.row.nPromotionCategoryID;
            const nOrder = newValue.target.innerText;
            const nPackageTypeID = item.row.nPackageType_ID;
            onChangeOrder(nCategoryID, nOrder, nPackageTypeID);
          }}
          lstOption={lstOrder}
          disClearable

        />
        return [html]
      },
    },
    {
      field: "sPromotionCategory",
      headerName: "รายการโปรโมชั่น",
      headerAlign: "center",
      align: "left",
      resizable: false,
      sortable: false,
      flex: 1,
      minWidth: 150,
      renderCell: (item) => {
        const html = <Box sx={{ overflow: 'hidden' }}>
          <Tooltip title={item.row.sPromotionCategory}>
            <Box>{item.row.sPromotionCategory}</Box>
          </Tooltip>
        </Box>
        return (html)
      },
    },
  ];


  const onChangeOrder = (nCategoryID, nOrder, nPackageTypeID) => {
    const param = {
      nCategoryID: nCategoryID,
      nOrder: nOrder,
      nPackageType: nPackageTypeID,
    }

    dispatch(DialogActionCreators.OpenDialogSubmit("คุณต้องการจะเปลี่ยนลำดับใช่หรือไม่", () => {
      dispatch(DialogActionCreators.CloseDialogSubmit() as any);
      dispatch(DialogActionCreators.LoadSubmit("Waiting...") as any);

      AxiosPost("ManagePromotion/ChangeOrder", param, (result) => {
        dispatch(DialogActionCreators.CloseDialogSubmit() as any);
        ResultAPI(result, "สำเร็จ", () => {
          dispatch(DialogActionCreators.OpenDialogSuccess("เปลี่ยนลำดับสำเร็จ") as any)
        });
        if (nPackageTypeID === 1) {
          onLoadDataOrderJob(dataRowOrderJob);
        }
        else if (nPackageTypeID === 2) {
          onLoadDataOrderCoin(dataRowOrderCoin);
        }
        else {
          onLoadDataOrderJob(dataRowOrderJob);
          onLoadDataOrderCoin(dataRowOrderCoin);
        }
      })
    }) as any)
  }


  const onDeleteOrder = (lstID, nPackageTypeID) => {
    const param = {
      arrID: lstID,
      nPackageType: nPackageTypeID
    }
    ///console.log("onDeleteOrder", param);
    dispatch(DialogActionCreators.OpenDialogSubmit(i18n(`common.AlertConfrimDel`), () => {
      dispatch(DialogActionCreators.CloseDialogSubmit() as any);
      dispatch(DialogActionCreators.LoadSubmit("Waiting...") as any);

      AxiosPost("ManagePromotion/DeleteOrder", param, (result) => {
        dispatch(DialogActionCreators.CloseDialogSubmit() as any);
        ResultAPI(result, i18n(`${i18nDialog}.DialogRemoveSuccess`), () => {
          dispatch(DialogActionCreators.OpenDialogSuccess(i18n(`${i18nDialog}.DialogRemoveSuccess`)) as any)
        });

        if (nPackageTypeID === 1) {
          onLoadDataOrderJob(dataRowOrderJob);
        }
        else if (nPackageTypeID === 2) {
          onLoadDataOrderCoin(dataRowOrderCoin);
        }
        else {
          onLoadDataOrderJob(dataRowOrderJob);
          onLoadDataOrderCoin(dataRowOrderCoin);
        }



      })
    }) as any)

  }

  return (
    <>
      <Stack sx={{ mx: "2%" }}>
        <Grid className="">
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={12}>
                <Stack spacing={3} width={"100%"} sx={{ border: "2px #eeeeee solid", backgroundColor: "rgba(255,255,255,0.75)", borderRadius: 5, p: 2, my: 2 }}>

                  <Grid item sx={{ width: "100%", backgroundColor: "#ff6b6b", borderRadius: 5, p: 1 }}>
                    <Typography variant="h6" color="#FFFFFF" component="div" align='center' >
                      แพ็กเกจ JOB POST
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Button variant="contained" sx={{ borderRadius: "22px" }} onClick={handleClickOpen}>
                      จัดลำดับโปรโมชั่น
                    </Button>
                  </Grid>
                  <Grid item sx={{ width: "100%" }}>
                    <Table
                      id="1"
                      isLoading={loadingTable}
                      filterField={filter}
                      Rows={dataRow}
                      Columns={dataColumn}
                      OnLoadData={(p) => onloadJobData(p)}
                      isShowCheckBox={permission === 2}
                      onDelete={(e) => onDelete(e)}
                      isRowSelectable={(e) => e.row.isDelete}
                    />
                  </Grid>

                  <Grid item sx={{ width: "100%", backgroundColor: "#ffd83b", borderRadius: 5, p: 1 }}>
                    <Typography variant="h6" color="#FFFFFF" component="div" align='center' >
                      แพ็กเกจ COIN
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Button variant="contained" sx={{ borderRadius: "22px" }} onClick={handleClickOpenCoin}>
                      จัดลำดับโปรโมชั่น
                    </Button>
                  </Grid>
                  <Grid item sx={{ width: "100%" }}>
                    <Table
                      id="2"
                      isLoading={loadingTableCoin}
                      filterField={filterCoin}
                      Rows={dataRowCoin}
                      Columns={dataColumnCoin}
                      OnLoadData={(p) => onloadCoinData(p)}
                      isShowCheckBox={permission === 2}
                      onDelete={(e) => onDelete(e)}
                      isRowSelectable={(e) => e.row.isDelete}
                    />
                  </Grid>

                </Stack>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Stack>


      {/* ============== Dialog Job ============== */}

      <Dialog
        fullWidth={true}
        maxWidth={"md"}
        open={open}
        onClose={handleClose}
      >
        <DialogTitle sx={{ backgroundColor: "#1976d2", color: "#fff", fontSize: "16px" }}>
          จัดลำดับการแสดงโปรโมชั่น
        </DialogTitle>
        <DialogContent sx={{ paddingTop: "25px !important" }}>
          <DialogContentText>
          </DialogContentText>
          <Table
            isLoading={loadingTableOrder}
            filterField={filterOrder}
            Rows={dataRowOrderJob}
            Columns={dataColumnOrder}
            ///OnLoadData={(e) => { console.log(e) }}
            OnLoadData={(p) => onLoadDataOrderJob(p)}
            isShowCheckBox={permission === 2}
            onDelete={(e) => { onDeleteOrder(e, 1) }}
            isRowSelectable={(e) => e.row.isDelete}
          />
        </DialogContent>
        <DialogActions>
          <Button variant="contained"
            sx={{ backgroundColor: "#898989 !important" }}
            onClick={handleClose}>
            ปิด
          </Button>
        </DialogActions>
      </Dialog>



      {/* ============== Dialog Coin ============== */}

      <Dialog
        fullWidth={true}
        maxWidth={"md"}
        open={openCoin}
        onClose={handleCloseCoin}
      >
        <DialogTitle sx={{ backgroundColor: "#1976d2", color: "#fff", fontSize: "16px" }}>
          จัดลำดับการแสดงโปรโมชั่น
        </DialogTitle>
        <DialogContent sx={{ paddingTop: "25px !important" }}>
          <DialogContentText>
          </DialogContentText>
          <Table
            isLoading={loadingTableOrderCoin}
            filterField={filterOrder}
            Rows={dataRowOrderCoin}
            Columns={dataColumnOrder}
            OnLoadData={(p) => onLoadDataOrderCoin(p)}
            isShowCheckBox={permission === 2}
            onDelete={(e) => { onDeleteOrder(e, 2) }}
            isRowSelectable={(e) => e.row.isDelete}
          />
        </DialogContent>
        <DialogActions>
          <Button variant="contained"
            sx={{ backgroundColor: "#898989 !important" }}
            onClick={handleCloseCoin}>
            ปิด
          </Button>
        </DialogActions>
      </Dialog>

    </>
  )
}

export default Managepromotion