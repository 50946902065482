import { useState, useEffect, useContext, useRef } from "react";
import * as yup from "yup";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Grid,
  Typography,
  Stack,
  Box,
  FormControlLabel,
  FormControl,
  RadioGroup,
  Radio,
  Paper,
  FormLabel,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import {
  AccordionCustom,
  AccordionDetailsCustom,
  AccordionSummaryCustom,
} from "components/SeekerProfile/ElementCustom";
import { i18n } from "i18n";
import yupFormSchemas from "components/Common/yup/yupFormSchemas";
import Input from "components/Common/ElementInForm/InputCustomLabelFormItem";
import UploadFile from "components/Common/ElementInForm/UploadFile/UploadFile";
import { AxiosGet, AxiosPost } from "service/CommonFunction/TS_function";
import moment from "moment";
import { DialogActionCreators } from "store/redux/DialogAlert";
import AutoCompleteSelect from "components/Common/ElementInForm/AutoCompleteSelect";
import DatePickerFormItem from "components/Common/ElementInForm/DatePickerFormItem";
import {
  BtnBack,
  BtnEditProfile,
  BtnSubmit,
} from "components/Common/Button";
import { FnBlock_UI } from "service/SystemFunction/SystemFunction";
import secureLocalStorage from "react-secure-storage";
import { ProfileContext } from "./Context/ProfileContext";
import CardComplete from "./CardComplete";
import CardInComplete from "./CardInComplete";
const i18nField = "entities.SeekerProfile";
const i18nCommon = "common";
const OptionSexlTH = [
  { label: "ชาย", value: 0 },
  { label: "หญิง", value: 1 },
  { label: "ไม่ระบุ", value: 2 },
];
const OptionSexEN = [
  { label: "Male", value: 0 },
  { label: "Female", value: 1 },
  { label: "Not Specific", value: 2 },
];
const OptionArmyTH = [
  { label: "ไม่เกี่ยวข้อง", value: 0 },
  { label: "อยู่ในระหว่างรับราชการทหาร ", value: 1 },
  { label: "ผ่านการเกณฑ์ทหารมาแล้วโดยการเป็นทหารเกณฑ์", value: 2 },
  {
    label:
      "ได้รับการยกเว้นโดยการเรียน ร.ด.จบหลักสูตร หรือจับฉลากได้ใบดำ หรือร่างกายไม่ได้ขนาด หรือกำลังเป็นนักศึกษา",
    value: 3,
  },
];
const OptionArmyEN = [
  { label: "Not Related", value: 0 },
  { label: "Serving", value: 1 },
  { label: "Completed", value: 2 },
  { label: "Exempted", value: 3 },
];
const lang = secureLocalStorage.getItem("language").toString() || "th";
const optionSex = lang === "th" ? OptionSexlTH : OptionSexEN;
const OptionArmy = lang === "th" ? OptionArmyTH : OptionArmyEN;

const PanelProfile = ({ Edit, isExpanded, isJobApply, sIDNew,onBackEdit }) => {
  const [isComPlete, setisComPlete] = useState(false);
  const dispatch = useDispatch();
  const history = useNavigate();
  const { BlockUI, UnBlockUI } = FnBlock_UI();
  const {
    profileContext,
    isLoading,
    isPanelProfileEdit,
    setIsPanelProfileEdit,
    fileUploadResumeContext,
    fileUploadTranscriptContext,
    setFileUploadResume,
    setFileUploadTranscript,
  } = useContext(ProfileContext);
  const [isSexFemale, setIsSexFemale] = useState(false);
  const [expandedProfile, setExpandedProfile] = useState(false);
  const [isMarried, setIsMarried] = useState(false);
  const [selectMarried, setSelectMarried] = useState(isMarried ? "married" : "single");
  const [BirthDate, setBirthDate] = useState(null);
  const [ProfileImage, setProfileImage] = useState([]);
  const [SexCheck, setSexCheck] = useState(null);
  const navigate = useNavigate();
  const formSchema = {
    Name: yupFormSchemas.string(i18n(`${i18nField}.Name`), { required: true }),
    Surname: yupFormSchemas.string(i18n(`${i18nField}.Surname`), { required: true, }),
    NameEN: yupFormSchemas.string(i18n(`${i18nField}.NameEN`), { required: true, }).matches(/^[a-zA-Z]+$/, i18n(`${i18nField}.ErrorNameEN`)),
    SurnameEN: yupFormSchemas.string(i18n(`${i18nField}.SurnameEN`), { required: true, }).matches(/^[a-zA-Z]+$/, i18n(`${i18nField}.ErrorNameEN`)),
    Email: yupFormSchemas.email(i18n(`${i18nField}.Email`)),
    OtherContact: yupFormSchemas.string(i18n(`${i18nField}.OtherContact`)),
    Sex: yupFormSchemas.object(i18n(`${i18nField}.Sex`), { required: true }),
    Tel: yupFormSchemas.string(i18n(`${i18nField}.Tel`), { required: true }),
    DOB: yupFormSchemas.date(i18n(`${i18nField}.DOB`), { required: true }),
    Army: yupFormSchemas.object(i18n(`${i18nField}.Army`), { required: true }),
  }
  const schema = yup.object().shape(formSchema);

  //คำนวนอายุ
  const DivAge = styled("div")(({ theme }) => ({
    ...theme.typography.button,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(1),
    textTransform: "capitalize",
  }));

  const form = useForm({
    resolver: yupResolver(schema),
    shouldUnregister: false,
    shouldFocusError: true,
    mode: "all",
  });

  const handleCalAge = () => {
    if (BirthDate) {
      const age = moment().diff(BirthDate, "years", false);
      return age + " " + i18n(`${i18nField}.AgeYear`);
    } else {
      return "";
    }
  };




  const onSubmit = (values) => {
    const dataProfile = {
      sName: form.getValues("Name"),
      sSurname: form.getValues("Surname"),
      sNameEN: form.getValues("NameEN"),
      sSurnameEN: form.getValues("SurnameEN"),
      sEmail: form.getValues("Email"),
      sOtherContact: form.getValues("OtherContact"),
      nSex: values.Sex["value"],
      sTel: values.Tel,
      nBirthDate: moment(values.DOB).valueOf(),
      nConscription: values.Army["value"] || 0,
      IsMarried: isMarried,
      listFileProfile: ProfileImage,
      listFileResume: fileUploadResumeContext,
      listFileTranscript: fileUploadTranscriptContext,
      nMode: 5,
    };

    dispatch(
      DialogActionCreators.OpenDialogSubmit(i18n(`${i18nField}.ConfirmMsgUpdate`), () => {
        dispatch(DialogActionCreators.CloseDialogSubmit() as any);
        dispatch(DialogActionCreators.LoadSubmit("Waiting...") as any);
        BlockUI();
        AxiosPost(`JobSeeker/UpdateProfile`, dataProfile, (result) => {
          UnBlockUI();
          let ProfileNoti = secureLocalStorage.getItem("ProfileNoti");
          secureLocalStorage.setItem("ProfileNoti", ProfileNoti === "true" ? "false" : "true");
          const res = result.Data;
          if (res.IsSuccess) {
            dispatch(DialogActionCreators.OpenDialogSuccess(i18n(`${i18nField}.MsgPanelProfile`), () => { onGoPage(); })
            );
          }
        },
          (err) => {
            if (!err.response) {
              history("/error");
              return;
            }
          }
        );
      }
      ) as any
    );
  };


  const GetMyProfileStatus = () => {
    let param = {
      nPanel: 5
    }
    AxiosPost("JobSeeker/GetMyProfileStatus", param, (res) => {
      setisComPlete(res.IsCompleted);

    });
  };

  const GetMyProfile = () => {
    AxiosGet("JobSeeker/GetMyProfileName", {}, (res) => {
      const selectSex =
        optionSex.find((option: any) => {
          return option.value === res.Data.nSex;
        }) || null;
      const selectArmy =
        OptionArmy.find((option: any) => {
          return option.value === res.Data.nConscription;
        }) || null;

      setProfileImage(res.Data.listFileProfile || []);
      form.setValue("Name", res.Data.sName);
      form.setValue("Surname", res.Data.sSurname);
      form.setValue("NameEN", res.Data.sNameEN);
      form.setValue("SurnameEN", res.Data.sSurnameEN);
      form.setValue("Email", res.Data.sEmail);
      form.setValue("OtherContact", res.Data.sOtherContact);
      form.setValue("Sex", selectSex);
      form.setValue("Tel", res.Data.sTel);
      form.setValue("DOB", res.Data.dBirthDate);
      form.setValue("Army", selectArmy);

      setIsSexFemale(res.Data.nSex === 1);
      if (res.Data.dBirthDate) setBirthDate(res.Data.dBirthDate);
      setIsMarried(res.Data.IsMarried);
      setSelectMarried(res.Data.IsMarried ? "married" : "single");
      setSexCheck(res.Data.nSex)
    });
  };

  useEffect(() => {
    GetMyProfileStatus();
    GetMyProfile();
  }, []);



  const handleEditWorkUnit = () => {
    let sJobApply = "";
    let sID = "";
    if (isJobApply) {
      sJobApply = "sJobApply";
      sID = sIDNew;
    }
    history(`/MyProfileEdit?sMode=PanelProfile&sJobApply=${sJobApply}&sIDNew=${sID}`);

  }


  const onBack = () => {
    setExpandedProfile(true);
    setIsPanelProfileEdit(false);
    if (isJobApply) {
      let objProfile = {
        sMode: "PanelProfile",
      };
      let objJobDetail = {
        sID: sIDNew || "",
        isApply: true,
        sFromPage: "JobDetail",
      };
      onBackEdit(objProfile,objJobDetail);
      // navigate("/JobApply", {
      //   state: {
      //     objProfile: objProfile,
      //     objJobDetail: objJobDetail
      //   },
      // });

    } else {
      history(`/MyProfile?sMode=PanelProfile`);
    }
  }

  const onGoPage = () => {
    if (isJobApply) {
      let objProfile = {
        sMode: "PanelProfile",
      };
      let objJobDetail = {
        sID: sIDNew || "",
        isApply: true,
        sFromPage: "JobDetail",
      };
      onBackEdit(objProfile,objJobDetail);
      // navigate("/JobApply", {
      //   state: {
      //     objProfile: objProfile,
      //     objJobDetail: objJobDetail
      //   },
      // });
    } else {
      history("/MyProfile?sMode=PanelProfile");
      // window.location.reload();
    }
  }
  useEffect(() => {
    setIsPanelProfileEdit(Edit);
    setExpandedProfile(isExpanded);
  }, []);


  return (
    <FormProvider {...form}>
      <AccordionCustom
        sx={{ border: "10px", p: 1, flex: 1 }}
        expanded={expandedProfile}
        onChange={() => setExpandedProfile((prev) => !prev)}
        id={"5"}
      >
        <AccordionSummaryCustom>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item xs={6} sm={8} md={9}>
              <Typography >
                {i18n(`${i18nField}.PanelProfile`)} <span style={{ color: "red" }}> *</span>
              </Typography>
            </Grid>
            <Grid item container
              justifyContent="flex-end" xs={6} sm={4} md={3}>
              <CardComplete isComPlete={isComPlete} />
              {/* {isComPlete ? (
                <CardComplete />
              ) : (
                <CardInComplete />
              )} */}
            </Grid>
          </Grid>

        </AccordionSummaryCustom>
        <AccordionDetailsCustom>
          <Box
            component={Paper}
            sx={{ border: "10px", p: 1, flex: 1, m: "2%" }}
          >
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              spacing={2}
            >
              <Grid item container spacing={2} xs={12}>
                <Grid item xs={12} sm={12} md={6}>
                  <Input
                    name={"Name"}
                    fullWidth
                    label={
                      <Typography sx={{ fontWeight: 400 }} component="label">
                        {i18n(`${i18nField}.Name`)}
                      </Typography>
                    }
                    small={true}
                    maxLength={200}
                    required={true}
                    disabled={!isPanelProfileEdit}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <Input
                    name={"Surname"}
                    fullWidth
                    required
                    maxLength={200}
                    small={true}
                    label={
                      <Typography sx={{ fontWeight: 400 }} component="label">
                        {i18n(`${i18nField}.Surname`)}
                      </Typography>
                    }
                    disabled={!isPanelProfileEdit}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <Input
                    name={"NameEN"}
                    fullWidth
                    label={
                      <Typography sx={{ fontWeight: 400 }} component="label">
                        {i18n(`${i18nField}.NameEN`)}
                      </Typography>
                    }
                    small={true}
                    maxLength={200}
                    required={true}
                    disabled={!isPanelProfileEdit}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <Input
                    name={"SurnameEN"}
                    fullWidth
                    required
                    maxLength={200}
                    small={true}
                    label={
                      <Typography sx={{ fontWeight: 400 }} component="label">
                        {i18n(`${i18nField}.SurnameEN`)}
                      </Typography>
                    }
                    disabled={!isPanelProfileEdit}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={2}>
                  <AutoCompleteSelect
                    name={"Sex"}
                    fullWidth
                    label={i18n(`${i18nField}.Sex`)}
                    options={optionSex}
                    onChange={(value) => {

                      if (value) {
                        if (value.value === 1) {
                          const selectArmy =
                            OptionArmy.find((option: any) => {
                              return option.value === 0;
                            }) || null;
                          form.setValue("Army", selectArmy);
                          setIsSexFemale(true);
                        } else {
                          form.setValue("Army", null);
                          setIsSexFemale(false);
                        }
                      } else {
                        form.setValue("Army", null);
                        setIsSexFemale(false);
                      }
                    }}
                    required
                    disabled={!isPanelProfileEdit}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={5}>
                  <Input
                    name={"Email"}
                    fullWidth
                    required
                    maxLength={200}
                    small={true}
                    label={
                      <Typography sx={{ fontWeight: 400 }} component="label">
                        {i18n(`${i18nField}.Email`)}
                      </Typography>
                    }
                    disabled
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={5}>
                  <Input
                    name={"Tel"}
                    fullWidth
                    required
                    maxLength={20}
                    small={true}
                    label={
                      <Typography sx={{ fontWeight: 400 }} component="label">
                        {i18n(`${i18nField}.Tel`)}
                      </Typography>
                    }
                    disabled={!isPanelProfileEdit}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <Input
                    name={"OtherContact"}
                    fullWidth
                    maxLength={1000}
                    small={true}
                    label={
                      <Typography sx={{ fontWeight: 400 }} component="label">
                        {i18n(`${i18nField}.OtherContact`)}
                      </Typography>
                    }
                    disabled={!isPanelProfileEdit}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <div style={{ display: "flex" }}>
                    <Grid item xs={12} md={3}>
                      <DatePickerFormItem
                        name={"DOB"}
                        label={
                          <Typography sx={{ fontWeight: 400 }} component="label">
                            {i18n(`${i18nField}.DOB`)}
                          </Typography>
                        }
                        small={true}
                        onChange={(value) => {
                          setBirthDate(() => value);
                        }}
                        required
                        disabled={!isPanelProfileEdit}
                      />
                    </Grid>
                    <Grid item xs={8} md={2}>
                      <DivAge>{handleCalAge()}</DivAge>
                    </Grid>

                  </div>
                </Grid>

                <Grid item md={4} xs={12}>
                  <FormControl>
                    <FormLabel>{i18n(`${i18nField}.MarriedStatus`)}</FormLabel>
                    <RadioGroup
                      row
                      value={selectMarried}
                      onChange={(e, v) => {
                        setIsMarried(v === "married");
                        setSelectMarried(v);
                      }}
                    >
                      <FormControlLabel
                        value="single"
                        control={<Radio />}
                        label={i18n(`${i18nField}.Single`)}
                        disabled={!isPanelProfileEdit}
                      />
                      <FormControlLabel
                        value="married"
                        control={<Radio />}
                        label={i18n(`${i18nField}.Married`)}
                        disabled={!isPanelProfileEdit}
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid item md={6} xs={12}>
                  <FormLabel>{i18n(`${i18nField}.Army`)}</FormLabel>
                  <AutoCompleteSelect
                    name={"Army"}
                    fullWidth
                    options={OptionArmy}
                    required
                    disabled={!isPanelProfileEdit || isSexFemale}
                  />
                </Grid>
                <Grid item xs={12} >
                  <FormControl>
                    <FormLabel>{i18n(`${i18nField}.UploadProfile`)}</FormLabel>
                    <UploadFile
                      id="myImage"
                      keyID={2}
                      arrFile={ProfileImage || []}
                      setarrFile={setProfileImage}
                      nLimitFile={2} //ขนาดไฟล์ไม่เกิน 2 Mb
                      IsFolder={false}
                      IsFile={true}
                      IsMultiple={false}
                      Extension={["jpg", "jpeg", "png", "gif"]}
                      IsDrag={false}
                      isSetupEmployer={false}
                      isUploadJobSeekerPicture={true}
                      disabled={!isPanelProfileEdit}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
          </Box>

          {!isPanelProfileEdit && (
            <Stack
              sx={{ border: "10px", p: 1, flex: 1, m: "2%" }}
              direction="row"
            >
              <BtnEditProfile
                txt={i18n(`${i18nField}.Edit`)}
                onClick={handleEditWorkUnit}
              />
            </Stack>
          )}
        </AccordionDetailsCustom>
      </AccordionCustom>
      {isPanelProfileEdit && (
        <Stack
          spacing={1}
          sx={{ border: "10px", p: 1, flex: 1, mt: "2%" }}
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <BtnBack

            onClick={() => { onBack() }}
            txt={i18n(`${i18nCommon}.back`)}
          />
          <BtnSubmit
            onClick={form.handleSubmit(onSubmit, (err) => console.log("error", err))}
            txt={i18n(`${i18nCommon}.save`)}
          />
        </Stack>
      )}

    </FormProvider >
  );
};

export default PanelProfile;