import React, { useState, useEffect, useRef } from 'react';
import FroalaEditor from "react-froala-wysiwyg";
import "froala-editor/css/froala_style.min.css";
import "froala-editor/css/froala_editor.pkgd.min.css";
import "froala-editor/js/froala_editor.pkgd.min.js";
import "froala-editor/js/plugins.pkgd.min.js";
import PropTypes, { InferProps } from "prop-types";
import { AxiosPost } from "service/CommonFunction/TS_function"

export default function FroalaContentOnly(props) {
  const { valueContent, SetValueContent, maxHeight, disabled, menuId, isAlwaysEnabled, maxwidth, charCounterMax } = props;
  const refContainer = useRef(null);

  const [configs, setConfigs] = useState({
    attribution: false,
    heightMax: maxHeight,
    charCounterMax: charCounterMax,
    toolbarButtons: {
      'moreText': {
        'buttons': ['bold', 'italic', 'underline', 'fontFamily', 'fontSize', 'textColor', 'backgroundColor', 'clearFormatting']
      },
      'moreMisc': {
        'buttons': ['undo', 'redo', 'fullscreen', 'selectAll',],
        'buttonsVisible': 2
      },
    },
    colorsText: ['#61BD6D', '#1ABC9C', '#54ACD2', '#2C82C9', '#9365B8', '#475577', '#CCCCCC',
      '#41A85F', '#00A885', '#3D8EB9', '#2969B0', '#553982', '#28324E', '#000000',
      '#F7DA64', '#FBA026', '#EB6B56', '#E25041', '#A38F84', '#EFEFEF', '#FFFFFF',
      '#FAC51C', '#F37934', '#D14841', '#B8312F', '#7C706B', '#D1D5D8', '#00dbff',
      '#00a3ff', '#0068ff', '#0000ff', '#000099', '#000064', '#00003b', 'REMOVE'],
    quickInsertEnabled: false,
    imageUploadURL:
      process.env.REACT_APP_API_URL + "api/UploadfileEmail/Uploadfile",
    key: "0BA3jA11B5C7C4A4D3aIVLEABVAYFKc2Cb1MYGH1g1NYVMiG5G4E3C3A1C8A6D4A3B4==",
    events: {
      'html.set': function () {
        let editor: any = this
        let param = { nMenuID: menuId };
        if (disabled) {
          editor.edit.off();
        } else {
          AxiosPost(`Home/Permission`, param, (res) => {
            if (isAlwaysEnabled) {
              editor.edit.on();
            } else if (res.nPermission != 2) {
              editor.edit.off();
            }

          });
        }
      }
    }
  });

  useEffect(() => {
    if (refContainer) {
      if (refContainer.current) {
        if (disabled) {
          refContainer.current.off();
        } else {
          refContainer.current.on();
        }
      }
    }
  }, [disabled, refContainer])

  // var configs = {
  //     attribution: false,
  //     heightMax: maxHeight,
  //     toolbarButtons: {
  //         'moreText': {
  //             'buttons': ['bold', 'italic', 'underline', 'fontFamily', 'fontSize', 'textColor', 'backgroundColor', 'clearFormatting']
  //         },
  //         'moreMisc': {
  //             'buttons': ['undo', 'redo', 'fullscreen', 'selectAll',],
  //             'buttonsVisible': 2
  //         },
  //     },
  //     quickInsertEnabled: false,
  //     imageUploadURL:
  //         process.env.REACT_APP_API_URL + "api/UploadfileEmail/Uploadfile",
  //     key: "0BA3jA11B5C7C4A4D3aIVLEABVAYFKc2Cb1MYGH1g1NYVMiG5G4E3C3A1C8A6D4A3B4==",
  //     events: {
  //         initialized() {
  //             const editor = this;
  //             if (disabled) {
  //                 editor.edit.off();
  //             } else {
  //                 editor.edit.on();
  //             }
  //         }
  //     }
  // };

  return <FroalaEditor
    model={valueContent}
    onModelChange={SetValueContent}
    config={{
      attribution: false,
      heightMax: maxHeight,
      charCounterMax: charCounterMax,
      toolbarButtons: {
        'moreText': {
          'buttons': ['bold', 'italic', 'underline', 'fontFamily', 'fontSize', 'textColor', 'backgroundColor', 'clearFormatting']
        },
        'moreMisc': {
          'buttons': ['undo', 'redo', 'fullscreen', 'selectAll',],
          'buttonsVisible': 2
        },
      },
      fontFamily: {
        'Prompt,sans-serif': 'Prompt',
      },
      colorsText: ['#61BD6D', '#1ABC9C', '#54ACD2', '#2C82C9', '#9365B8', '#475577', '#CCCCCC',
        '#41A85F', '#00A885', '#3D8EB9', '#2969B0', '#553982', '#28324E', '#000000',
        '#F7DA64', '#FBA026', '#EB6B56', '#E25041', '#A38F84', '#EFEFEF', '#FFFFFF',
        '#FAC51C', '#F37934', '#D14841', '#B8312F', '#7C706B', '#D1D5D8', '#00dbff',
        '#00a3ff', '#0068ff', '#0000ff', '#000099', '#000064', '#00003b', 'REMOVE'],
      quickInsertEnabled: false,
      imageUploadURL: process.env.REACT_APP_API_URL + "api/UploadfileEmail/Uploadfile",
      key: "0BA3jA11B5C7C4A4D3aIVLEABVAYFKc2Cb1MYGH1g1NYVMiG5G4E3C3A1C8A6D4A3B4==",
      events: {
        'html.set': function () {
          let editor: any = this
          let param = { nMenuID: menuId };
          if (disabled) {
            editor.edit.off();
          } else {
            AxiosPost(`Home/Permission`, param, (res) => {
              refContainer.current = editor.edit;
              if (isAlwaysEnabled) {
                editor.edit.on();
              } else if (res.nPermission === 2) {
                if (disabled) {
                  editor.edit.off();
                }
                else {
                  editor.edit.on();
                }
              }
              else if (res.nPermission != 2) {
                editor.edit.off();
              }

            });
          }
        }
      }
    }}

  />;
}

FroalaContentOnly.propTypes = {
  SetValueContent: PropTypes.func.isRequired,
  valueContent: PropTypes.string.isRequired,
  maxHeight: PropTypes.number,
  disabled: PropTypes.bool,
  menuId: PropTypes.number.isRequired,
  isAlwaysEnabled: PropTypes.bool,
  charCounterMax: PropTypes.number
};

FroalaContentOnly.defaultProps = {
  maxHeight: 250,
  isAlwaysEnabled: false
};