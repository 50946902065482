import { useEffect, useState } from 'react';
import { Grid, Stack } from '@mui/material';
import AdminCard from 'components/Banner/AdminBanner'
import { AxiosGet } from 'service/CommonFunction/TS_function';
import { useLocation, useNavigate } from "react-router-dom";
import { FnBlock_UI } from 'service/SystemFunction/SystemFunction';

const Banner = () => {
    const { UnBlockUI } = FnBlock_UI();
    const history = useNavigate();
    const location = useLocation();
    const searchParams = location.search;
    const sID = new URLSearchParams(searchParams).get('sID');
    const [sNamecard, setsNamecard] = useState([]);

    useEffect(() => {
        OnGetData();
    }, []);

    const OnGetData = () => {
        AxiosGet("Email/GetData_Email", {}, (result) => {
            UnBlockUI();
          
        },
            (err) => {
                if (!err.response) {
                    history("/error", { replace: true })
                    return;
                }
            });
    }

    return (
        <Grid>
            <Stack width={"100%"} sx={{ border: "2px #eeeeee solid", backgroundColor: "rgba(255,255,255,0.75)", borderRadius: 5, p: 2, }}>
                <Grid container spacing={2} justifyContent="center">
               <button >testttttorder</button>

                </Grid>
            </Stack>
        </Grid>
    );
}

export default Banner
