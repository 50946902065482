import Carousel from "react-material-ui-carousel";
import { Card } from "@mui/material";
import { useNavigate } from "react-router-dom";

export default function PicSlide({
  lstBanner = [],
  width = "100%",
  height = "400px",
}) {
  const navigate = useNavigate();

  const gotoLink = (m) => {
    if (m.sLinkToURL != "") {
      window.open(m.sLinkToURL, "_blank");
    }
  }

  const stylesCustom = {
    boxBanner: {
      minHeight: height,
    },
    cardBanner: {
      borderRadius: "1em",
      overflow: "hidden",
      width: width,
      height: height,
      backgroundColor: "transparent"
    },
    inputText: {
      width: "100%",
    },
  };

  return (
    <div style={stylesCustom.boxBanner}>
      <Carousel
        // animation={'slide'}
        duration={1000}
        indicatorContainerProps={{
          style: {
            marginTop: "0px",
            position: "relative",
            zIndex: 1,
            top: "-25px",
          },
        }}
        height={height}
        sx={{ transform: "unset" }}
      >
        {lstBanner.map((m, i) => (
          <Card
            key={i}
            style={stylesCustom.cardBanner}
             onClick={() => gotoLink(m)}
            sx={m.sLinkToURL && { cursor: "pointer" }}
          >
            {m.sCheckFile == ".mp4" ? (
              // <ReactPlayer
              //   url={m.sPathFileBanner}
              //   muted
              //   // width="90%"
              //   // height="20%"
              //   playing
              //   style={{
              //     width: "100%",
              //     height: height,
              //     // height: "auto",
              //     objectFit: "cover",
              //   }}
              // />
              <video
                contextMenu="return false;"
                // id={"video_" + IDName}
                src={m.sPathFileBanner}
                controls
                autoPlay
                muted
                controlsList="nodownload"
                style={{
                  width: "100%",
                  height: height,
                  // height: "auto",
                  objectFit: "cover",
                }}
              />
            ) : (
              <img
                className={m.sLinkToURL && "zoom-image-banner"}
                src={m.sPathFileBanner}
                alt="banner"
                style={{
                  width: "100%",
                  height: height,
                  // height: "auto",
                  objectFit: "cover",
                }}
              />
            )}
          </Card>
        ))}
      </Carousel>
    </div>
  );
}
