import { Box, Button, Card, Divider, Grid, Modal, Stack, Tooltip, Typography, useMediaQuery } from "@mui/material"
import { i18n } from "i18n";
import "./ServicesBPS.css";
import VerifiedIcon from '@mui/icons-material/Verified';
import { AxiosPost } from "service/CommonFunction/TS_function";
import React, { useEffect, useRef, useState } from "react";
import secureLocalStorage from 'react-secure-storage';
import { useNavigate } from "react-router-dom";
import ModalServiceLogin from "./ModalServiceLogin";
import { FnBlock_UI } from "service/SystemFunction/SystemFunction";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import MoldalConditions from "./MoldalConditions";


const CardServicesBPS = ({ nTypeID }) => {
    const LayoutWeb = useMediaQuery('(min-width:1100px)');
    const Layoutipad = useMediaQuery('(min-width:760px)');
    const lang = secureLocalStorage.getItem("language") || "th";
    const i18nField = "OrderEmployer";
    const i18nFieldlog = "dialog";
    const [arrPomotion, setArrPomotion] = useState([])
    const [isOpenModal, setOpenModal] = useState(false);
    const [isEmployee, setisEmployee] = useState(false);
    const [isShowPreview, setIsShowPreview] = useState(false);
    const navigate = useNavigate();
    const { BlockUI, UnBlockUI } = FnBlock_UI();
    const GetDataPromotion = () => {
        let param = {
            nTypeID: nTypeID
        }
        BlockUI();
        AxiosPost(`EmployerServiceBPS/GetDataCardPromotion`, param, (result) => {
            UnBlockUI();
            setArrPomotion(result.lstPomotion || []);
            setisEmployee(result.isEmployee);
        });
    }

    useEffect(() => {
        GetDataPromotion();
    }, [])

    const onBuy = (sID, nMode) => {
        navigate(`/ServicesBPS?sID=${sID}&nMode=${nMode}&nTypePackage=${nTypeID}`);
    }

    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: LayoutWeb ? 3 : Layoutipad ? 2 : 1,
        slidesToScroll: LayoutWeb ? 3 : Layoutipad ? 2 : 1,
        adaptiveHeight: false,
    };


    return (
        <>
            {arrPomotion.length > 0 && (
                <>
                    <div className="slider-container">
                        <Slider {...settings} style={{ width: "100%" }}>
                            {arrPomotion.map((item, index) => {
                                return (
                                    <div key={item.sID} className='item-swiper'>
                                        <div className="plan" style={{ width: LayoutWeb ? "26.3vw" : Layoutipad && !LayoutWeb ? "39vw" : "75vw" }} >
                                            <div className="inner" style={{ padding: LayoutWeb ? "40px" : "30px" }}>
                                                <Typography className='title' alignItems={"center"}>{item.sPomotionName}</Typography>
                                                <Divider style={{ backgroundColor: "#cbcccc", height: "1px", marginTop: "4%" }} />

                                                {(!isEmployee && item.isShowTotalPrice) && (
                                                    <>
                                                        <Grid container justifyContent={"center"} style={{ marginTop: "15%" }} alignItems={"center"}>
                                                            <Typography className='title-2'>฿ {item.sTotalPrice}</Typography>
                                                        </Grid>

                                                        <Grid container justifyContent={"center"} alignItems={"center"}>

                                                            {lang == "th" ? (
                                                                <Typography className='title-4'>{i18n(`${i18nField}.Vat`)} 7%</Typography>
                                                            ) : (
                                                                <>
                                                                    <Typography className='title-4'> {i18n(`${i18nField}.Vat`)} 7% {i18n(`${i18nField}.Included`)}</Typography>
                                                                </>
                                                            )}
                                                        </Grid>
                                                    </>
                                                )}

                                                {isEmployee && (
                                                    <>
                                                        <Grid container justifyContent={"center"} style={{ marginTop: "15%" }} alignItems={"center"}>
                                                            <Typography className='title-2'>฿ {item.sTotalPrice}</Typography>
                                                        </Grid>
                                                        <Grid container justifyContent={"center"} alignItems={"center"}>

                                                            {lang == "th" ? (
                                                                <Typography className='title-4'>{i18n(`${i18nField}.Vat`)} 7%</Typography>
                                                            ) : (
                                                                <>
                                                                    <Typography className='title-4'> {i18n(`${i18nField}.Vat`)} 7% {i18n(`${i18nField}.Included`)}</Typography>
                                                                </>
                                                            )}
                                                        </Grid>
                                                    </>
                                                )}
                                                <Grid item xs={12} >
                                                    <Grid container justifyContent={"center"} alignItems={"center"}>
                                                        <Grid item xs={10} >
                                                            <div className="action">
                                                                {isEmployee ? (
                                                                    <Button variant="contained" className="button" onClick={() => onBuy(item.sID, 1)}>{i18n(`${i18nField}.BuyNow`)}</Button>
                                                                ) : (
                                                                    <>
                                                                        <Button variant="contained" className="button" onClick={() => setOpenModal(true)}>{i18n(`${i18nField}.Viewdetails`)}</Button>
                                                                    </>
                                                                )}
                                                            </div>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                {item.isShowDiscount && (
                                                    <Grid item xs={12} >
                                                        <Grid container justifyContent={"center"} >
                                                            <Grid item container justifyContent={"center"} alignItems={"center"} xs={10} style={{ backgroundColor: "#ffebb6", marginTop: "5%" }} >
                                                                {lang == "th" ? (
                                                                    <Typography className='title-1' fontSize={15}>{i18n(`${i18nField}.Save`)} {item.nDiscount}%</Typography>
                                                                ) : (
                                                                    <>
                                                                        <Typography className='title-1' fontSize={15}> {item.nDiscount}% {i18n(`${i18nField}.Save`)}</Typography>
                                                                    </>
                                                                )}
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                )}

                                                <ul className="features">
                                                    {(item.isShowJobPostAmount && nTypeID == 1) && (
                                                        <>
                                                            {lang == "th" ? (
                                                                <Typography fontSize={15} style={{ marginTop: "10px" }}> <VerifiedIcon className="icon" /> {i18n(`${i18nField}.JobPosting`)} {item.sJobPostAmount} Jobs </Typography>
                                                            ) : (
                                                                <>
                                                                    <Typography style={{ marginTop: "10px" }}> <VerifiedIcon className="icon" /> {item.sJobPostAmount} {i18n(`${i18nField}.JobPosting`)}</Typography>
                                                                </>
                                                            )}
                                                        </>
                                                    )}

                                                    {(item.isShowCoinAmount && nTypeID == 2) && (
                                                        <>
                                                            {lang == "th" ? (
                                                                <Typography fontSize={15} style={{ marginTop: "10px" }}> <VerifiedIcon className="icon" /> {i18n(`${i18nField}.CoinCard`)} {item.sCoinAmount} Coins </Typography>
                                                            ) : (
                                                                <>
                                                                    <Typography style={{ marginTop: "10px" }}> <VerifiedIcon className="icon" /> {item.sCoinAmount} {i18n(`${i18nField}.Coins`)}</Typography>
                                                                </>
                                                            )}
                                                        </>
                                                    )}
                                                    {item.isShowCoinFreeAmount && (
                                                        <Typography fontSize={15} style={{ marginTop: "10px" }}> <VerifiedIcon className="icon" /> {i18n(`${i18nField}.Free`)} {item.sCoinFreeAmount} {i18n(`${i18nField}.Coins`)}  </Typography>
                                                    )}
                                                    {item.isShowExpiryDuration && (
                                                        <Typography fontSize={15} style={{ marginTop: "10px" }}> <VerifiedIcon className="icon" /> {i18n(`${i18nField}.Expired`)} {item.nExpiryDuration} {i18n(`${i18nField}.MonthExpired`)}  </Typography>
                                                    )}

                                                    {item.isShowBenefit && (
                                                        <>
                                                            {
                                                                item.lstBenefit.map((m) => {
                                                                    return (
                                                                        <>
                                                                            < Typography fontSize={15} style={{ marginTop: "10px" }}> <VerifiedIcon className="icon" /> {m.sBenefit}  </Typography>
                                                                        </>
                                                                    )
                                                                })
                                                            }
                                                        </>
                                                    )}
                                                </ul>

                                                {(item.isShowPeriodStart && item.isShowPeriodEnd) ? (
                                                    <Grid
                                                        container
                                                        direction="row"
                                                        justifyContent="center"
                                                        alignItems="flex-end"
                                                        style={{ height: "auto" }}
                                                    >
                                                        {lang == "th" ? (
                                                            <Typography className='title-3'>{i18n(`${i18nField}.PromotionPeriod`)} {item.sPromotionPeriodTH}</Typography>
                                                        ) : (
                                                            <>
                                                                <Typography className='title-3'>{i18n(`${i18nField}.PromotionPeriod`)} {item.sPromotionPeriodEN}</Typography>
                                                            </>
                                                        )}
                                                    </Grid>

                                                ) : (
                                                    <>
                                                        {item.isShowPeriodStart && (
                                                            <Grid container justifyContent={"center"} style={{ marginTop: "10%" }} alignItems={"center"}>
                                                                {lang == "th" ? (
                                                                    <Typography className='title-3'>{i18n(`${i18nField}.PromotionPeriod`)} {item.sPromotionPeriodTHStart}</Typography>
                                                                ) : (
                                                                    <>
                                                                        <Typography className='title-3'>{i18n(`${i18nField}.PromotionPeriod`)} {item.sPromotionPeriodENStart}</Typography>
                                                                    </>
                                                                )}
                                                            </Grid>
                                                        )}
                                                        {item.isShowPeriodEnd && (
                                                            <Grid container justifyContent={"center"} style={{ marginTop: "10%" }} alignItems={"center"}>
                                                                {lang == "th" ? (
                                                                    <Typography className='title-3'>{i18n(`${i18nField}.PromotionPeriod`)} {item.sPromotionPeriodTHEnd}</Typography>
                                                                ) : (
                                                                    <>
                                                                        <Typography className='title-3'>{i18n(`${i18nField}.PromotionPeriod`)} {item.sPromotionPeriodENEnd}</Typography>
                                                                    </>
                                                                )}
                                                            </Grid>
                                                        )}
                                                    </>
                                                )}


                                            </div>
                                        </div>
                                    </div>
                                );
                            })}

                        </Slider >
                    </div >

                    <Grid item md={12} sm={11.7} xs={12} >
                        <Grid
                            container
                            direction="row"
                            justifyContent="flex-end"
                            alignItems="flex-start"
                        >
                            <a onClick={() => setIsShowPreview(true)} style={{ cursor: "pointer", textDecoration: "underline", color: "#267ed4", fontSize: LayoutWeb ? 12 : 9 }} >{i18n(`${i18nFieldlog}.Terms`)}</a>
                        </Grid>
                    </Grid>
                </>
            )}
            < ModalServiceLogin isOpenModal={isOpenModal} setOpenModal={setOpenModal} />
            <MoldalConditions nID={nTypeID == 1 ? 4 : 5} isShowPreview={isShowPreview} setIsShowPreview={setIsShowPreview} />
        </>
    )

}
export default CardServicesBPS