import {  Checkbox, FormControlLabel, Grid,Stack,Table, TableBody, TableCell, TableContainer, TableHead, TableRow,  Typography, useMediaQuery } from "@mui/material"
import { i18n } from "i18n";
import "./ServicesBPS.css";
import { PiSealPercentFill } from "react-icons/pi";
import {AxiosPost, ResultAPI } from "service/CommonFunction/TS_function";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import WorkIcon from '@mui/icons-material/Work';
import yupFormSchemas from "components/Common/yup/yupFormSchemas";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import AutoCompleteSelect from "components/Common/ElementInForm/AutoCompleteSelect";
import VerifiedIcon from '@mui/icons-material/Verified';
import { BtnBack, BtnSaveDraft, BtnSubmit } from "components/Common/Button";
import { useDispatch } from "react-redux";
import { DialogActionCreators } from "store/redux/DialogAlert";
import secureLocalStorage from "react-secure-storage";
import { FaCoins } from "react-icons/fa6";
import ExampleBanner from "./ExampleBanner";
import { FnBlock_UI } from "service/SystemFunction/SystemFunction";
const BuyServicesBPS = () => {
  const lang = secureLocalStorage.getItem("language") || "th";
  const dispatch = useDispatch();
  const history = useNavigate();
  const location = useLocation();
  const searchParams = location.search;
  const sID = new URLSearchParams(searchParams).get('sID');
  const nMode = new URLSearchParams(searchParams).get('nMode');
  const nTypePackage = new URLSearchParams(searchParams).get('nTypePackage');
  const sOrderID = new URLSearchParams(searchParams).get('sOrderID');
  const i18nField = "OrderEmployer";
  const i18nCommon = "common";
  const [arrJobPackage, setArrJobPackage] = useState([]);
  const [arrPackage, setArrPackage] = useState([]);
  const [sPomotionName, setPomotionName] = useState("");
  const [arrDuration, setarrDuration] = useState([]);
  const [arrlstRow, setarrlstRow] = useState([]);
  const [lstarrValue, setarrValue] = useState([]);
  const [lstCheck, setlstCheck] = useState([]);
  const [sGrandTotal, setGrandTotal] = useState("");
  const [nCheckGrandTotal, setCheckGrandTotal] = useState(0);
  const objSchema = {
    nPackageID: yupFormSchemas.object(i18n(`${i18nField}.Package`), { required: nTypePackage === EnumPackage.Banner ? false : true })
  };
  const schema = yup.object().shape(objSchema);
  const form = useForm({
    resolver: yupResolver(schema),
    shouldUnregister: false,
    shouldFocusError: true,
    mode: "all",
  });
  const LayoutWeb = useMediaQuery('(min-width:1000px)');
  const { BlockUI, UnBlockUI } = FnBlock_UI();


  const GetPackagePomotion = (sID) => {
    let param = {
      sID: sID,
      nMode: nMode,
      nTypePackageID: nTypePackage
    }
    BlockUI();
    AxiosPost(`EmployerServiceBPS/GetPackagePomotion`, param, (result) => {
      UnBlockUI();
      setArrPackage(result.lstPomotionDetail || []);
      setPomotionName(result.sPomotionName);
      form.setValue(`nPackageID`, result.oPomotionPackage);
    });
  }

  const GetDataDropDownPackage = () => {
    let param = {
      sID: nTypePackage
    }

    AxiosPost(`EmployerServiceBPS/GetDataDropDownPackage`, param, (result) => {
      setArrJobPackage(result.lstOptionPackage || []);
    });
  }
  const GetTableBannerDataPackage = () => {
    let param = {
      sID: sID
    }

    BlockUI();
    AxiosPost(`EmployerServiceBPS/GetTableBannerDataPackage`, param, (result) => {
      UnBlockUI();
      setarrDuration(result.lstDuration);
      setarrlstRow(result.lstRow);
      setarrValue(result.lstValue);
      setlstCheck(result.lstBanner);
      setCheckGrandTotal(result.nGrandTotal ?? 0)
      setGrandTotal(result.nGrandTotal.toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }))
    });
  }

  const handleChangeCheckboxBanner = (e) => {
    let nPosition_ID = +e.target.value.split("_")[0];
    let nBannerPackage_ID = +e.target.value.split("_")[1];
    let objCheck = lstCheck.filter((f) => f.nPosition_ID === nPosition_ID)[0];
    if (objCheck) {
      if (e.target.checked) {
        objCheck.nBannerPackage_ID = nBannerPackage_ID;
        let obj = lstarrValue.filter((f) => f.nPosition_ID === objCheck.nPosition_ID && f.nBannerPackage_ID === nBannerPackage_ID)[0];
        objCheck.nBannerPackageDuration = obj.nBannerPackageDuration;
        objCheck.sPrice = obj.sName;
        objCheck.nPrice = obj.nValue;
      } else {
        objCheck.nBannerPackage_ID = null;
        objCheck.nBannerPackageDuration = null;
        objCheck.sPrice = null;
        objCheck.nPrice = null;
      }
    }
    setlstCheck([...lstCheck]);
    CalGrandTotal();
  };
  const CalGrandTotal = () => {
    let total = 0;
    lstCheck.map((item) => {
      let obj = lstarrValue.filter((f) => f.nPosition_ID === item.nPosition_ID && f.nBannerPackage_ID === item.nBannerPackage_ID)[0];
      if (obj) {
        total = total + obj.nValue;
      }
    });
    let cal = Math.round(total);
    setCheckGrandTotal(cal);
    setGrandTotal(cal.toLocaleString('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }))
  };

  useEffect(() => {
    GetPackagePomotion(sID);
    GetDataDropDownPackage();
    GetTableBannerDataPackage();
  }, [])

  useEffect(() => {
    setTimeout(() => {
      document.getElementById("scroll_Top") && document.getElementById("scroll_Top").scrollIntoView();
    }, 200);
  }, [])

  const onBack = () => {
    history(-1)
  }
  const onGoto = () => {
    history("/OrderEmployer")
  }

  const onSubmit = (e, status) => {
    let msgAlert = status == 2 ? i18n(`${i18nField}.msgConfirmorder`) : i18n(`${i18nField}.msgConfirmorderDraft`);
    if (nTypePackage === EnumPackage.Banner && nCheckGrandTotal <= 0) {
      dispatch(DialogActionCreators.OpenDialogWarning(i18n(`${i18nField}.BannermsgAlert`)));
    } else {
      let objSaveData = {
        sID: sOrderID ?? "",
        lstPackage: arrPackage,
        nMode: status,
        nTypePackageID: nTypePackage,
        lstPackageBanner: lstCheck,
        nGrandTotal: nCheckGrandTotal,
        isPromotion: nMode === "1"
      };
      dispatch(
        DialogActionCreators.OpenDialogSubmit(msgAlert, () => { //Do you want to save ?
          dispatch(DialogActionCreators.CloseDialogSubmit() as any);

          AxiosPost("EmployerServiceBPS/SaveDataOrderPomotion", objSaveData, (result) => {
            dispatch(DialogActionCreators.CloseDialogSubmit() as any);
            ResultAPI(result, "Save successfully", () => {
              let msgAlertSuccess = status == 2 ? i18n(`${i18nField}.msgConfirmorderSuccess`) : i18n(`${i18nField}.msgConfirmorderDraftSuccess`);
              dispatch(
                DialogActionCreators.OpenDialogSuccess(
                  msgAlertSuccess, //Save successfully
                  () => onGoto()
                ) as any
              );
            });
          },
            (err) => {
              if (err.Status === 409) {
                dispatch(DialogActionCreators.OpenDialogWarning(i18n(`${i18nCommon}.magAlertPackageDeleted`), () => nMode === "1" ? history("/OrderEmployer") : ""
                ) as any
                );
              }
              else if (err.Status === 400) {
                dispatch(
                  DialogActionCreators.OpenDialogWarning(i18n(`${i18nCommon}.magAlertPackageCan`), () => nMode === "1" ? history("/OrderEmployer") : "" //cannot save, the package has been deleted
                  ) as any
                );
              }
              else if (err.Message !== "") {
                dispatch(
                  DialogActionCreators.OpenDialogWarning(err.Message) as any
                );
              }
            }
          );
        }) as any
      );

    }


  };


  return (
    <FormProvider {...form}>
      <Grid container justifyContent={"center"} alignItems={"center"} id={"1"}>
        <Grid item md={9} xs={12} >
          <div className="card-DetailPomotin shadow">
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              display={"flex"}
            >
              <Grid item xs={2} sm={3} md={3.5}>
                <PiSealPercentFill className="fontsize-iconDetail" style={{ color: "#1976db"}} />
              </Grid>
              <Grid item xs={10} sm={9} md={8}>
                {nMode == EnumPackage.Pomotion ? (
                  <>
                    <Typography className={'card-DetailPomotin-title'}>{i18n(`${i18nField}.DetailPomotion`)} </Typography>
                  </>
                ) : (
                  <>
                    <Typography className={'card-DetailPomotin-title'}>{i18n(`${i18nField}.DetailPackage`)} </Typography>
                  </>
                )}
              </Grid>
            </Grid >
          </div>
        </Grid >

        <Grid item md={9} xs={12} style={{ marginTop: "3%" }}>
          <div className="plan-Table" >
            <div className="inner-DetailPack" >
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
              >
                {nMode == EnumPackage.Pomotion && (
                  <>
                    {nTypePackage == EnumPackage.Jobpost ? (
                      <Typography className={'title-Table'}>{sPomotionName}: {i18n(`${i18nField}.MediumPlanJobPost`)}
                       <WorkIcon className="icon-jobost"/></Typography>
                    ) : (

                      <Grid container spacing={3}>
                        <Grid item >
                          <Typography className={'title-Table'}  >{sPomotionName}: {i18n(`${i18nField}.CoinPomotionTitle`)}</Typography>
                        </Grid>
                        <Grid item  >
                          <FaCoins className="icon-coin" />
                        </Grid>
                      </Grid >
                    )}

                  </>
                )}

                {(nMode == EnumPackage.Package && nTypePackage !== EnumPackage.Banner) && (
                  <>
                    {nTypePackage == EnumPackage.Jobpost ? (
                      <Typography className={'title-Table'}> {i18n(`${i18nField}.JobPostPackage`)} </Typography>
                    ) : (
                      <Typography className={'title-Table'}>{i18n(`${i18nField}.CoinPackageService`)}</Typography>
                    )}

                  </>
                )}
              </Grid>

              {nTypePackage === EnumPackage.Banner ? (
                <>
                  <Grid item xs={12} sm={12} md={12} >
                    <ExampleBanner nPackageTypeID={3} sBuy={"yes"} />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12} sx={{ marginTop: "10%" }}>
                    <TableContainer >
                      <Table aria-label="simple table">
                        <TableHead>
                          <TableRow sx={{ background: "#eeeeee", border: " 1px solid #dddddd" }}>
                            <TableCell align="center" >
                              <Typography fontSize={18} fontWeight={500} color={"black"}>{i18n(`${i18nField}.Package`)}</Typography>

                            </TableCell>
                            {arrDuration.length > 0
                              && arrDuration.map((item, index) => {
                                return (
                                  <TableCell
                                    align="center"
                                    key={index + item.sName}
                                    sx={{ border: " 1px solid #dddddd" }}
                                  >
                                    <Typography fontSize={18} fontWeight={500} color={"black"}>{item.sName}  {i18n(`${i18nField}.Month`)}</Typography>
                                  </TableCell>
                                );
                              })}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {arrlstRow.length > 0 && arrlstRow.map((item, index) => {
                            return (
                              <TableRow key={index + item.sName}>
                                <TableCell align="center" style={{ border: " 1px solid #dddddd" }}><Typography fontSize={18} fontWeight={500} width={"110px"} color={"black"}>{item.sName}</Typography></TableCell>

                                {arrDuration != null && item.lstValue.length > 0 ? arrDuration.map((itemSub, index) => {
                                  let nvar = item.lstValue.find(e => e.nBannerPackage_ID == itemSub.nBannerPackage_ID)
                                  return (

                                    <TableCell key={index + itemSub.nBannerPackage_ID} align="center" sx={{ border: " 1px solid #dddddd", padding: "1px" }}>
                                      <FormControlLabel
                                        sx={{ padding: 0, width: "90px" }}
                                        control={
                                          <Checkbox
                                            sx={{ padding: 0 }}
                                            size="small"
                                            checked={lstCheck.filter((f) => f.nPosition_ID === nvar.nPosition_ID && f.nBannerPackage_ID === nvar.nBannerPackage_ID)[0] ? true : false}
                                            onChange={(e) =>
                                              handleChangeCheckboxBanner(e)
                                            }
                                            value={nvar.nPosition_ID + "_" + nvar.nBannerPackage_ID}
                                          />
                                        }
                                        label={nvar.sName}
                                        labelPlacement="top"
                                      />
                                    </TableCell>
                                  )
                                }) : null}
                              </TableRow>
                            );
                          })
                          }
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                  {nCheckGrandTotal > 0 && (
                    <>
                      <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        sx={{ mt: "10%" }}
                      >
                        <Typography className='title-Table-Detail'><VerifiedIcon style={{ fontSize: 30, color: "#1976d2" }} />   Banner Package</Typography>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} sx={{ mt: "2%" }}>
                        {lstCheck.map((item, index) => {
                          return (
                            <>
                              {item.nBannerPackage_ID != null && (

                                <Stack
                                  key={item.nPosition_ID}
                                  sx={{ flex: 1, padding: "20px", marginLeft: "15px", marginRight: "15px" }}
                                  direction="row"
                                  justifyContent="space-between"
                                  alignItems="flex-start"
                                >
                                  <Typography color={"black"}>{i18n(`${i18nField}.Banner`)} {item.nPosition_ID} {i18n(`${i18nField}.BannerPeriod`)} {item.nBannerPackageDuration} {i18n(`${i18nField}.MonthExpired`)}</Typography>
                                  <Typography color={"black"} >{item.sPrice}</Typography>
                                </Stack>
                              )}
                            </>

                          )
                        })}


                        <Stack
                          sx={{ flex: 1, background: "#fff6df", height: "60px", padding: "20px", marginLeft: "15px", marginRight: "15px" }}
                          direction="row"
                          justifyContent="space-between"
                          alignItems="flex-start"

                        >
                          <Typography fontWeight={600} color={"black"}>{i18n(`${i18nField}.GrandTotal`)}</Typography>
                          <Typography fontWeight={600} color={"black"}>{sGrandTotal}</Typography>
                        </Stack>

                      </Grid>
                    </>
                  )}

                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="flex-start"
                    sx={{ mt: "2%" }}
                  >
                    <Typography color={"#acacac"} className="fontsize-BannerRemark">{i18n(`${i18nField}.BannerRemark`)}</Typography>
                  </Grid>
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    sx={{ mt: "2%", marginLeft: "20px" }}
                  >
                    <Typography color={"#acacac"} className="fontsize-Detail">{i18n(`${i18nField}.Note`)}</Typography>
                  </Grid>
                </>
              ) : (
                <>
                  <Grid item xs={12} sm={12} md={12} sx={{ mt: "2%" }}>
                    <AutoCompleteSelect
                      name={'nPackageID'}
                      label={""}
                      options={arrJobPackage}
                      disabled={nMode === "1"}
                      onChange={(v) => {
                        GetPackagePomotion(v != null ? v.sID : null)
                      }}
                    />
                  </Grid>
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    sx={{ mt: "2%" }}
                  >
                    <Typography className='title-Table-Detail'><VerifiedIcon style={{ fontSize: 30, color: "#1976d2" }} />  {nTypePackage == "1" ? "Job Post" : "Coins Package"}</Typography>
                  </Grid>

                  <Grid item xs={12} sm={12} md={12} sx={{ mt: "2%" }}>
                    {arrPackage.map((item, index) => {
                      return (
                        <Grid key={index}>
                          <Stack
                            sx={{ flex: 1, padding: "20px", marginLeft: "15px", marginRight: "15px" }}
                            direction="row"
                            justifyContent="space-between"
                            alignItems="flex-start"
                          >
                            {item.nPackageType_ID == 1 ? (
                              <>
                                <Typography color={"black"}>{i18n(`${i18nField}.JobPosting`)}</Typography>
                                <Typography color={"black"}>{item.sJobPostAmount}</Typography>
                              </>

                            ) : (
                              <>
                                <Typography color={"black"}>{i18n(`${i18nField}.CoinPakageAmount`)}</Typography>
                                <Typography color={"black"}>{item.sCoinPackageCoinAmount}</Typography>
                              </>
                            )}

                          </Stack>
                          <Stack
                            sx={{ flex: 1, padding: "20px", marginLeft: "15px", marginRight: "15px" }}
                            direction="row"
                            justifyContent="space-between"
                            alignItems="flex-start"
                          >
                            <Typography style={{ color: "red" }}>{i18n(`${i18nField}.CoinsFree`)}</Typography>
                            <Typography style={{ color: "red" }}>{item.sCoinFree}</Typography>
                          </Stack>
                          {item.nPackageType_ID == 2 && (
                            <Stack
                              sx={{ flex: 1, padding: "20px", marginLeft: "15px", marginRight: "15px" }}
                              direction="row"
                              justifyContent="space-between"
                              alignItems="flex-start"
                            >
                              <Typography style={{ color: "black" }}>{i18n(`${i18nField}.TotalCoin`)}</Typography>
                              <Typography style={{ color: "black" }}>{item.sTotalCoin}</Typography>
                            </Stack>
                          )}
                          <Stack
                            sx={{ flex: 1, padding: "20px", marginLeft: "15px", marginRight: "15px" }}
                            direction="row"
                            justifyContent="space-between"
                            alignItems="flex-start"
                          >
                            <Typography color={"black"}>{i18n(`${i18nField}.PeriodPackage`)}</Typography>
                            <Typography color={"black"}>{item.nExpiryDuration}</Typography>
                          </Stack>


                          <Stack
                            sx={{ flex: 1, padding: "20px", marginLeft: "15px", marginRight: "15px" }}
                            direction="row"
                            justifyContent="space-between"
                            alignItems="flex-start"
                          >
                            {item.nPackageType_ID == 1 ? (
                              <Typography color={"black"}>{i18n(`${i18nField}.PriceperJobPosting`)}</Typography>
                            ) : (<>
                              <Typography color={"black"}>{i18n(`${i18nField}.CoinPirce`)}</Typography>
                            </>)}
                            <Typography color={"black"}>{item.sPrice}</Typography>
                          </Stack>


                          <Stack
                            sx={{ flex: 1, padding: "20px", marginLeft: "15px", marginRight: "15px" }}
                            direction="row"
                            justifyContent="space-between"
                            alignItems="flex-start"
                          >
                            <Typography color={"black"}>{i18n(`${i18nField}.Save`)} (%)</Typography>
                            <Typography color={"black"}>{item.nDiscount}</Typography>
                          </Stack>

                          <Stack
                            sx={{ flex: 1, padding: "20px", marginLeft: "15px", marginRight: "15px" }}
                            direction="row"
                            justifyContent="space-between"
                            alignItems="flex-start"
                          >
                            {item.nPackageType_ID == 1 ? (
                              <Typography color={"black"}>{i18n(`${i18nField}.NetPrice`)}</Typography>
                            ) : (<>
                              <Typography color={"black"}>{i18n(`${i18nField}.CoinPriceTotal`)}</Typography>
                            </>)}

                            <Typography color={"black"}>{item.sPackagePrice}</Typography>
                          </Stack>

                          <Stack
                            sx={{ flex: 1, padding: "20px", marginLeft: "15px", marginRight: "15px" }}
                            direction="row"
                            justifyContent="space-between"
                            alignItems="flex-start"
                          >
                            <Typography color={"black"}>{i18n(`${i18nField}.Vatname`)}</Typography>
                            <Typography color={"black"} >{item.sPackageVat}</Typography>
                          </Stack>

                          <Stack
                            sx={{ flex: 1, background: "#fff6df", height: "60px", padding: "20px", marginLeft: "15px", marginRight: "15px" }}
                            direction="row"
                            justifyContent="space-between"
                            alignItems="flex-start"

                          >
                            {LayoutWeb ? (
                              <Typography fontWeight={600} color={"black"}>{i18n(`${i18nField}.GrandTotalPomotion`)}</Typography>
                            ) : (
                              <>
                                <Typography fontWeight={600} color={"black"}>{i18n(`${i18nField}.GrandTotal`)}</Typography>
                              </>
                            )}

                            <Typography fontWeight={600} color={"black"}>{item.sGrandTotal}</Typography>
                          </Stack>
                          {item.nPackageType_ID == 1 && (
                            <Stack
                              sx={{ flex: 1, marginTop:"2%", marginLeft: "15px" }}
                              direction="row"
                              justifyContent="space-between"
                              alignItems="flex-start"
                            >
                              <Typography color={"#acacac"} className="fontsize-Detail">{i18n(`${i18nField}.DaysPerJobPosting`)}</Typography>
                            </Stack>
                          )}
                          <Stack
                            sx={{ flex: 1, marginLeft: "15px", marginTop: item.nPackageType_ID == 2 ? "1%" : "" }}
                            direction="row"
                            justifyContent="space-between"
                            alignItems="flex-start"
                          >
                            <Typography color={"#acacac"} className="fontsize-Detail" >{i18n(`${i18nField}.Note`)}</Typography>
                          </Stack>
                        </Grid>
                      );
                    })}
                  </Grid>
                </>
              )}

              <Grid item xs={12} sx={{ mt: "5%"}}>
                <Grid container>
                  <Grid item md={3} sm={3} xs={lang == "th" ? 4.2 : 3}>
                    <Grid
                      container
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="flex-start"

                    >
                      <BtnBack
                        txt={i18n(`${i18nField}.BtnBack`)}
                        onClick={() => onBack()}
                      />
                    </Grid>
                  </Grid>
                  <Grid item md={9} sm={9} xs={lang == "th" ? 7.8 : 9}>
                    <Grid
                      container
                      direction="row"
                      justifyContent="flex-end"
                      alignItems="flex-start"
                      spacing={1}
                    >
                      <Grid item>
                        <BtnSaveDraft
                          txt={i18n(`${i18nField}.BtnDraft`)}
                          IsDisabled={false}
                          onClick={form.handleSubmit((e) => {
                            onSubmit(e, 1);
                          })}
                        />
                      </Grid>

                      <Grid item>
                        <BtnSubmit
                          txt={i18n(`${i18nField}.BtnSave`)}
                          IsDisabled={false}
                          onClick={form.handleSubmit((e) => {
                            onSubmit(e, 2);
                          })}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                </Grid>
              </Grid>
            </div>
          </div>
        </Grid >
      </Grid >

    </FormProvider >
  )

}
export default BuyServicesBPS

export const enum EnumPackage {
  Jobpost = "1",
  Coin = "2",
  Banner = "3",
  Pomotion = "1",
  Package = "2",
}