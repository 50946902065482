const OrderEmployerEn = {
  sPackage: "Package Type",
  Select: "Select Package",
  JobPackage: "Job Package",
  CoinPackage: "Coin Package",
  BannerPackage: "Banner Package",
  PricePerJob: "Price per Job Posting",
  Discount: "Discount",
  GrandTotal: "Grand Total",
  Baht: "THB",
  SelectType: "Select Package Type",
  Vatname: "Vat 7%",
  TotalPrice: "Total Price",
  Month: " Month",
  Package: "Package",
  BtnBack: "Back",
  BtnCancle: "Cancle order",
  BtnDraft: "Save Draft",
  BtnSave: "Save",
  BtnSavePayment: "Submit proof of payment",
  Coint: "Coin",
  JobPost: "Job Post",
  Expirey: "Expirey",
  Employer: "Employer",
  UploadPaymentButt: "Upload Payment",
  Invoice: "Invoice documents",
  Payment: "Proof of payment",
  Tax: "Tax invoice",
  Post: "Post",
  CoinNumber: "Number of coins",
  BPSService: " BPS Services",
  ServiceCoin: "Coins",
  ServiceJobPost: "Job Post",
  SeviceBanner: "Banner",
  JobPosticon1: "<center>Save time on the job posting <br /> and payment are ready to be <br /> posted immediately</center>",
  JobPosticon2: "<center>Save money <br /> in each job posting.</center>",
  JobPosticon3: "<center>Get more coin <br /> to reach more job applicants.</center>",
  JobPosting: "Job Posting",
  JobPosting1: "",
  Free: "Free",
  Coins: "Coins",
  Expired: "Expired in ",
  MonthExpired: "Month(s)",
  Save: "Save",
  BuyNow: "Buy Now",
  Vat: "Vat",
  Included: "Included",
  Viewdetails: "View details",
  JobPostPackage: "Job Post Package",
  JobPostingTable: "<center>Job <br />Posting</center>",
  CoinsFreeTable: "<center>Coins <br />(Free)</center>",
  PeriodPackageTable: "<center>Period <br />Package<br /> (Month(s))</center>",
  DaysPer: "30 Days Per 1 Job Posting",
  DetailTerms: "The customer as a result of this confirms that the customer  has read and accepts all",
  TermsConditions: "Terms & Conditions",
  website: "in www.jobsbps.com",
  Remark: "Remark: Vat 7% Included",
  Seemore: "See more",
  Seeless: "See less",
  PromotionPeriod: "Promotion Period",
  GrandTotalTable: "<center>Grand Total</center>",
  DetailPomotion: "Promotion Detail",
  MediumPlanJobPost: "Job Post",
  CoinsFree: "Coins (Free)",
  PeriodPackage: "Period Package (Month(s))",
  PriceperJobPosting: "Price per Job Posting",
  NetPrice: "Net Price",
  GrandTotalPomotion: "Grand Total",
  DaysPerJobPosting: "30 Days Per 1 Job Posting",
  Note: "Notice: After purchasing the package the BPS admin will contact you again soon.",
  Announce: "If you wish to announce additional job opportunities",
  SpecialDiscounts: "with special discounts,",
  please: "please",
  contactus: "contact us.",
  Email: "E-mail:",
  Tel: "  Tel:",
  Or: "or",
  Address: "Address: ",
  DetailPackage: "Package Detail",
  CoinIconPhone1: "<center>Save time by paying to upgrade job applicant’s profiles instantly.</center>",
  CoinIconPhone2: "<center>Enhance your search for suitable job applicant's profiles and improve user accessibility.</center>",
  CoinIcon1: "<center>Save time by paying to upgrade job<br />applicant’s profiles instantly.</center>",
  CoinIcon2: "<center>Enhance your search for suitable<br />job applicant's profiles and<br />improve user accessibility.</center>",
  CoinIcon3: "<center>Increase your purchase of<br />Coins to gain more benefits.</center>",
  CoinPackageService: "Coins Package",
  CoinTable: "<center>Coins</center>",
  CoinDetailTable: "Tips: For maximum benefit, use coins for the specified package duration",
  TotalCoin: "Coins (Amount)",
  CoinPomotionTitle: "Coins",
  CoinPirce: "Price",
  CoinPriceTotal: "Total",
  BannerPackageService: "Banner Package",
  Banner1: "Banner 1",
  BannerSize1: "Size 1500x300 px",
  Banner2: "Banner 2",
  BannerSize2: "Size 320x490 px",
  Banner3: "Banner 3",
  BannerSize3: "Size 350x200 px",
  Banner4: "Banner 4",
  BannerSize4: "Size 1400x220 px",
  Banner5: "Banner 5",
  BannerSize5: "Size 970x220 px",
  BannerIcon1: "<center>Stand out with your company<br />logo and an inviting job  <br />application message.</center>",
  BannerIcon2: "<center>Enhance visibility for job<br />postings or your organization's <br /> advertisements.</center>",
  BannerIcon3: "<center>Stand out with a larger banner size <br />to increase job application<br /> opportunities.</center>",
  BannerIconPhone1: "<center>Stand out with your company logo and an inviting job application message.</center>",
  BannerIconPhone2: "<center>Enhance visibility for job postings or your organization's advertisements.</center>",
  BannerIconPhone3: "<center>Stand out with a larger banner size to increase job application opportunities.</center>",
  BannerPeriod: "Period",
  Banner: "Banner",
  BannermsgAlert: "Please select banner.",
  BannerRemark: "Vat 7% Included",
  Job: "Job",
  OrderPeriodPackage: "Period Package",
  CoinPakageAmount: "Coins (Package)",
  msgConfirmorder: "Do you want to confirm order ?",
  msgConfirmorderSuccess: "Confirm order successfully.",
  msgCalSuccess: "Cancel order successfully.",
  msgConfirmorderDraft: "Do you want to save draft ?",
  msgConfirmorderDraftSuccess: "Save draft successfully.",
  MsgPayment: "Please fill out the proof of payment",
};
const OrderEmployerTh = {
  sPackage: "Package Type",
  Select: "เลือกแพ็กเกจ",
  JobPackage: "Job Package",
  CoinPackage: "แพ็กเกจ COIN",
  BannerPackage: "Banner Package",
  PricePerJob: "ราคาปกติต่องาน",
  Discount: "ส่วนลด",
  GrandTotal: "ราคารวมทั้งสิ้น",
  Baht: "บาท",
  SelectType: "เลือกประเภทแพ็กเกจ",
  Vatname: "ภาษีมูลค่าเพิ่ม 7%",
  TotalPrice: "ราคารวม",
  Month: " เดือน",
  Package: "แพ็กเกจ",
  BtnBack: "ย้อนกลับ",
  BtnCancle: "ยกเลิกคำสั่งซื้อ",
  BtnDraft: "บันทึกร่าง",
  BtnSave: "บันทึก",
  BtnSavePayment: "ส่งหลักฐานยืนยันการชำระเงิน",
  Coint: "เหรียญ",
  JobPost: "จำนวนโพสต์",
  Expirey: "วันหมดอายุ",
  Employer: "ผู้ประกอบการ",
  UploadPaymentButt: "ส่งหลักฐานการชำระเงิน",
  Invoice: "เอกสารใบแจ้งหนี้",
  Payment: "หลักฐานการชำระเงิน",
  Tax: "ใบกำกับภาษี",
  Post: "โพสต์",
  CoinNumber: "จำนวนเหรียญ",
  BPSService: "บริการของ BPS",
  ServiceCoin: "Coins",
  ServiceJobPost: "Job Post",
  SeviceBanner: "Banner",
  JobPosticon1: "<center>ประหยัดเวลาในการลงประกาศ <br /> ชำระเงินพร้อมลงประกาศได้ทันที</center>",
  JobPosticon2: "<center>ประหยัดค่าใช้จ่าย <br /> ในการลงประกาศงานแต่ละครั้ง </center>",
  JobPosticon3: "<center>ได้รับ Coin <br />เพื่อเข้าถึงผู้สมัครงานได้มากขึ้น</center>",
  JobPosting: "จำนวน Job Post",
  JobPosting1: "Jobs",
  Free: "ได้รับ Coins ฟรี",
  Coins: "Coins",
  Expired: "ระยะเวลาหมดอายุ",
  MonthExpired: "เดือน",
  Save: "ส่วนลด",
  BuyNow: "ซื้อแพ็กเกจนี้",
  Vat: "รวม Vat",
  Included: "",
  Viewdetails: "ดูรายละเอียด",
  JobPostPackage: "แพ็กเกจ Job Post",
  JobPostingTable: "<center>จำนวน <br />Job Post</center>",
  CoinsFreeTable: "<center>จำนวน Coins <br />(Free)</center>",
  PeriodPackageTable: "<center>ระยะเวลา <br />แพ็กเกจ<br /> (เดือน)</center>",
  DaysPer: "ระยะเวลาการแสดงประกาศ ตำแหน่งละ 30 วัน",
  DetailTerms: "ลูกค้ายืนยันว่าได้อ่านและยินยอมปฏิบัติตาม",
  TermsConditions: "ข้อกำหนดและเงื่อนไข",
  website: "ของเว็บไซต์ www.jobsbps.com เรียบร้อยแล้ว",
  Remark: "หมายเหตุ: ราคารวม Vat 7%",
  Seemore: "ดูแพ็กเกจทั้งหมด",
  Seeless: "ย่อแพ็กเกจ",
  PromotionPeriod: "ระยะเวลาโปรโมชั่น",
  GrandTotalTable: "<center>ราคารวมทั้งสิ้น</center>",
  DetailPomotion: "รายละเอียดโปรโมชั่น",
  MediumPlanJobPost: "Job Post",
  CoinsFree: "จำนวน Coins (Free)",
  PeriodPackage: "ระยะเวลาแพ็กเกจ (เดือน)",
  PriceperJobPosting: "ราคาปกติต่องาน",
  NetPrice: "ราคาสุทธิ",
  GrandTotalPomotion: "ราคารวมทั้งสิ้น (Grand Total)",
  DaysPerJobPosting: "ระยะเวลาการแสดงประกาศ ตำแหน่งละ 30 วัน",
  Note: "หมายเหตุ: หลังจากกดซื้อแพ็กเกจแล้ว ทางแอดมินจะติดต่อกลับอีกครั้ง",
  Announce: "กรณีต้องการประกาศงานเพิ่มเติม",
  SpecialDiscounts: "พร้อมรับส่วนลดพิเศษ",
  contactus: "ติดต่อเรา",
  Email: "E-mail:",
  Tel: "เบอร์โทร:",
  Or: "หรือ",
  Address: "ที่อยู่: ",
  DetailPackage: "รายละเอียดแพ็กเกจ",
  CoinIconPhone1: "<center>ประหยัดเวลา<br />ชำระเงินพร้อมซื้อโปรไฟล์ผู้สมัครงานไ้ด้ทันที</center>",
  CoinIconPhone2: "<center>ค้นหาประวัติผู้สมัครงานที่ตรงใจ<br />และเข้าถึงผู้ใช้งานได้มากขึ้น</center>",
  CoinIcon1: "<center>ประหยัดเวลา<br />ชำระเงินพร้อมซื้อโปรไฟล์ผู้สมัครงานไ้ด้ทันที</center>",
  CoinIcon2: "<center>ค้นหาประวัติผู้สมัครงานที่ตรงใจ<br />และเข้าถึงผู้ใช้งานได้มากขึ้น</center>",
  CoinIcon3: "<center>ซื้อ Coin มากขึ้น<br />เพื่อได้รับสิทธิประโยชน์มากขึ้น</center>",
  CoinPackageService: "แพ็กเกจ Coins",
  CoinTable: "<center>จำนวน<br />Coins</center>",
  CoinDetailTable: "ข้อแนะนำ: เพื่อประโยชน์สูงสุด ควรใช้ Coins ตามระยะเวลาแพ็กเกจที่กำหนด",
  CoinCard: "ได้รับ",
  TotalCoin: "จำนวน Coins ทั้งหมด",
  CoinPomotionTitle: "Coins",
  CoinPirce: "ราคา",
  CoinPriceTotal: "ราคารวม (Total)",
  BannerPackageService: "แพ็กเกจ Banner",
  Banner1: "Banner 1",
  BannerSize1: " Size 1500x300 px",
  Banner2: "Banner 2",
  BannerSize2: "Size 320x490 px",
  Banner3: "Banner 3",
  BannerSize3: "Size 350x200 px",
  Banner4: "Banner 4",
  BannerSize4: "Size 1400x220 px",
  Banner5: "Banner 5",
  BannerSize5: "Size 970x220 px",
  BannerIcon1: "<center>โดดเด่นด้วยโลโก้บริษัท<br />พร้อมข้อความเชิญชวนสมัครงาน</center>",
  BannerIcon2: "<center>เพิ่มโอกาสในการมองเห็นการลงโฆษณา<br />ตำแหน่งงาน หรือองค์กรของท่าน</center>",
  BannerIcon3: "<center>โดดเด่นด้วยขนาด Banenr<br />เพิ่มโอกาสได้ใบสมัคร</center>",
  BannerIconPhone1: "<center>โดดเด่นด้วยโลโก้บริษัท<br />พร้อมข้อความเชิญชวนสมัครงาน</center>",
  BannerIconPhone2: "<center>เพิ่มโอกาสในการมองเห็นการลงโฆษณา<br />ตำแหน่งงาน หรือองค์กรของท่าน</center>",
  BannerIconPhone3: "<center>โดดเด่นด้วยขนาด Banenr<br />เพิ่มโอกาสได้ใบสมัคร</center>",
  BannerPeriod: "ระยะเวลา",
  Banner: "Banner",
  BannermsgAlert: "กรุณาเลือกแพ็กเกจ Banner",
  BannerRemark: "ราคาดังกล่าวรวม Vat7% แล้ว",
  Job: "งาน",
  OrderPeriodPackage: "ระยะเวลาแพ็กเกจ",
  CoinPakageAmount: "จำนวน Coins ตามแพ็กเกจ",
  msgConfirmorder: "คุณต้องการยืนยันคำสั่งซื้อหรือไม่ ?",
  msgConfirmorderSuccess: "ยืนยันคำสั่งซื้อสำเร็จ",
  msgCalSuccess: "ยกเลิกการสั่งซื้อสำเร็จ",
  msgConfirmorderDraft: "คุณต้องการบันทึกแบบร่างหรือไม่ ?",
  msgConfirmorderDraftSuccess: "บันทึกแบบร่างสำเร็จ",
  MsgPayment: "กรุณากรอกข้อมูลหลักฐานการชำระเงิน",
};

const TableOrderEmployerTh = {
  No: "ที่",
  OrderNumber: "เลขที่คำสั่งซื้อ",
  Package: "แพ็กเกจ",
  Price: "ราคารวมทั้งสิ้น",
  OrderDate: "วันที่สั่งซื้อ",
  ExpDate: "วันที่หมดอายุ",
  Status: "สถานะคำสั่งซื้อ",
  AlertConDel: "คุณต้องการลบข้อมูลการสั่งซื้อใช่หรือไม่ ?",
  StatusPackage: "สถานะแพ็กเกจ",
  PackageExpire: "แพ็กเกจหมดอายุ"
};
const TableOrderEmployerEn = {
  No: "No",
  OrderNumber: "Order Number",
  Package: "Package",
  Price: "Total Price",
  OrderDate: "Order Date",
  ExpDate: "Expiration Date",
  Status: "Order Status",
  AlertConDel: "Do you want to remove order ?",
  StatusPackage: "Package Status",
  PackageExpire: "Package Expiration Date"
};

const TablePackageTh = {
  TitlePackage: "แพ็กเกจ JOB POST",
  No: "ที่",
  Package: "แพ็กเกจ",
  PackageTable: "ชื่อแพ็กเกจ",
  Position: "ตำแหน่ง",
  Coin: "เหรียญ",
  ExpDate: "วันที่หมดอายุ",
  PriceBeforeTax: "ราคาก่อนภาษี",
  ExpiryDuration: "ระยะเวลาหมดอายุ/เดือน",
  NormalPrice: "ราคาปกติ",
  JobPosting: "จำนวน Job Post",
  JobPostingPeriod: "ระยะเวลาประกาศ (วัน)",
  NormalPricePerJob: "ราคาปกติต่องาน",
  Special: "พิเศษ",
  PricePerJob: "ราคาต่องาน",
  TotalPrice: "ราคารวม",
  PercentSave: "ส่วนลด (%)",
  Net: "ราคาสุทธิ",
  Vat: "ภาษีมูลค่าเพิ่ม 7%",
  GrandTotal: "ราคารวมทั้งสิ้น",
};
const TablePackageEn = {
  TitlePackage: "Job Post Package",
  No: "No",
  Package: "Package",
  PackageTable: "Free Token Package & Period",
  Position: "Position",
  Coin: "Coin",
  ExpDate: "Expiration Date",
  PriceBeforeTax: "Price Before Tax",
  ExpiryDuration: "Expiry Duration/Month",
  NormalPrice: "Normal Price",
  JobPosting: "Job Posting",
  JobPostingPeriod: "Job Posting Period",
  NormalPricePerJob: "Normal Price/Job",
  Special: "Special",
  PricePerJob: "Price Per Job",
  TotalPrice: "Total Price",
  PercentSave: "% Save",
  Net: "Net",
  Vat: "Vat 7%",
  GrandTotal: "Grand Total",
};

const TableBannerTh = {
  TitleBanner: "แพ็กเกจ BANNER",
  Position: "ตำแหน่ง",
  DurationMonth: " เดือน",
  CoinPackage: "แพ็กเกจ COIN"
};
const TableBannerEn = {
  TitleBanner: "Banner Package",
  Position: "Position",
  DurationMonth: " Month",
  CoinPackage: "Coin Package"
};
export {
  OrderEmployerEn, OrderEmployerTh,
  TableOrderEmployerTh, TableOrderEmployerEn,
  TablePackageTh, TablePackageEn,
  TableBannerTh, TableBannerEn
};
