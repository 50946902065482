import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { BtnBack } from "components/Common/Button";
import { AxiosGet } from "service/CommonFunction/TS_function";
import {
  Checkbox,
  Divider,
  Grid,
  Stack,
  Typography,
  FormGroup,
  FormControlLabel,
} from "@mui/material";
import Content from "../../font/JobDetail/Content";
import AdminCompanyTabPanel from "./AdminCompanyTabPanel";
import { FnBlock_UI } from "service/SystemFunction/SystemFunction";

const AdminCompanyBannerDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = location.search;
  const sID = new URLSearchParams(searchParams).get("sID");
  const sBannerID = new URLSearchParams(searchParams).get("sBannerID");

  const [detail, setDetail] = useState<{ [key: string]: any }>({});
  const { BlockUI, UnBlockUI } = FnBlock_UI();

  const onGetDetail = () => {
    BlockUI();
    AxiosGet(
      "AdminCompany/detail_banner",
      { sID: sID, sBannerID: sBannerID },
      (res) => {
        setDetail(res);
        UnBlockUI();
      }
    );
  };

  const onBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    onGetDetail();
  }, []);

  return (
    <Grid container>
      <Grid item className="table" sx={{ mx: "2%", width: "100%" }}>
        <AdminCompanyTabPanel tabNo={2} />
        <Stack
          spacing={2}
          width={"100%"}
          sx={{
            marginTop: "2%",
            border: "2px #eeeeee solid",
            backgroundColor: "rgba(255,255,255,0.75)",
            borderRadius: 5,
            p: 3,
          }}
        >

          <Stack direction={"row"} justifyContent="space-between">
            <Typography color={"#03a9f4"} fontWeight={500} fontSize={18}>
              ข้อมูลคำสั่งซื้อ/แพ็กเกจ
            </Typography>

          </Stack>
          <Stack direction={"row"}  >
            <Divider sx={{ width: "100%", backgroundColor: "#03a9f4" }} />
          </Stack>


          <Stack direction={"column"} sx={{ mt: "10px !important", gap: "10px" }} >
            <Stack direction={"row"} sx={{ gap: "1em" }}>
              <Stack direction={"column"} justifyContent="center" sx={{ width: "200px", marginLeft: "20px" }}>
                <Typography fontWeight={500} variant="subtitle1">
                  เลขที่คำสั่งซื้อ
                </Typography>
              </Stack>
              <Stack direction={"column"} flex={1}>
                <Stack direction={"row"} alignItems="center" sx={{ mt: "0px !important", gap: "5px" }} >
                  <Typography fontWeight={400} variant="subtitle2"> {detail.sBanner_Number || "-"} </Typography>
                </Stack>
              </Stack>
            </Stack>

            <Stack direction={"row"} sx={{ gap: "1em" }}>
              <Stack direction={"column"} justifyContent="center" sx={{ width: "200px", marginLeft: "20px" }}>
                <Typography fontWeight={500} variant="subtitle1">
                  ผู้ประกอบการ
                </Typography>
              </Stack>
              <Stack direction={"column"} flex={1}>
                <Stack direction={"row"} alignItems="center" sx={{ mt: "0px !important", gap: "5px" }} >
                  <Typography fontWeight={400} variant="subtitle2">{detail.sCompany_Name} </Typography>
                </Stack>
              </Stack>
            </Stack>


            <Stack direction={"row"} sx={{ gap: "1em" }}>
              <Stack direction={"column"} justifyContent="center" sx={{ width: "200px", marginLeft: "20px" }}>
                <Typography fontWeight={500} variant="subtitle1">
                  วันที่สั่งซื้อ
                </Typography>
              </Stack>
              <Stack direction={"column"} flex={1}>
                <Stack direction={"row"} alignItems="center" sx={{ mt: "0px !important", gap: "5px" }} >
                  <Typography fontWeight={400} variant="subtitle2"> {detail.sCreateDate} </Typography>
                </Stack>
              </Stack>
            </Stack>

            <Stack direction={"row"} sx={{ gap: "1em" }}>
              <Stack direction={"column"} justifyContent="center" sx={{ width: "200px", marginLeft: "20px" }}>
                <Typography fontWeight={500} variant="subtitle1">
                  แพ็กเกจ
                </Typography>
              </Stack>
              <Stack direction={"column"} flex={1}>
                <Stack direction={"row"} alignItems="center" sx={{ mt: "0px !important", gap: "5px" }} >
                  <Typography fontWeight={400} variant="subtitle2"> {detail.sPosition} </Typography>
                </Stack>
              </Stack>
            </Stack>

            <Stack direction={"row"} sx={{ gap: "1em" }}>
              <Stack direction={"column"} justifyContent="center" sx={{ width: "200px", marginLeft: "20px" }}>
                <Typography fontWeight={500} variant="subtitle1">
                  ราคา
                </Typography>
              </Stack>
              <Stack direction={"column"} flex={1}>
                <Stack direction={"row"} alignItems="center" sx={{ mt: "0px !important", gap: "5px" }} >
                  <Typography fontWeight={400} variant="subtitle2"> {detail.sOrder_Total} </Typography>
                </Stack>
              </Stack>
            </Stack>

            <Stack direction={"row"} sx={{ gap: "1em" }}>
              <Stack direction={"column"} justifyContent="start"  sx={{ width: "200px", marginLeft: "20px" }}>
                <Typography fontWeight={500} variant="subtitle1">
                  ผู้ติดต่อประสานงาน
                </Typography>
              </Stack>
              <Stack direction={"column"} flex={1} >
                <Stack  direction={"column"} alignItems="start" sx={{ mt: "0px !important", gap: "5px"}} >
                {detail.sAccountManager ? (detail.sAccountManager.split("<br/>")).map((m, index) => 
                <Typography key={index} fontWeight={400} variant="subtitle2" >{m}<br/></Typography>) : null}
                </Stack>
              </Stack>
            </Stack>
            <Grid item md={12} xs={12} sx={{ marginTop: "2%" }}>
              <img src={detail.sFileURL}></img>
            </Grid>
          </Stack>

          <Stack direction={"row"} justifyContent="space-between">
            <Typography color={"#03a9f4"} fontWeight={500} fontSize={18}>
              รายละเอียดการโฆษณา
            </Typography>

          </Stack>
          <Stack direction={"row"}  >
            <Divider sx={{ width: "100%", backgroundColor: "#03a9f4" }} />
          </Stack>

          <Stack direction={"column"} sx={{ mt: "10px !important", gap: "10px" }} >
            <Stack direction={"row"} sx={{ gap: "1em" }}>
              <Stack direction={"column"} justifyContent="center" sx={{ width: "200px", marginLeft: "20px" }}>
                <Typography fontWeight={500} variant="subtitle1">
                วันที่ปรับปรุง
                </Typography>
              </Stack>
              <Stack direction={"column"} flex={1}>
                <Stack direction={"row"} alignItems="center" sx={{ mt: "0px !important", gap: "5px" }} >
                  <Typography fontWeight={400} variant="subtitle2">  {detail.sUpdateDate} </Typography>
                </Stack>
              </Stack>
            </Stack>
            <Stack direction={"row"} sx={{ gap: "1em" }}>
              <Stack direction={"column"} justifyContent="center" sx={{ width: "200px", marginLeft: "20px" }}>
                <Typography fontWeight={500} variant="subtitle1">
                  ผู้ประกาศ
                </Typography>
              </Stack>
              <Stack direction={"column"} flex={1}>
                <Stack direction={"row"} alignItems="center" sx={{ mt: "0px !important", gap: "5px" }} >
                  <Typography fontWeight={400} variant="subtitle2">  {detail.sCreateBy}</Typography>
                </Stack>
              </Stack>
            </Stack>

            <Stack direction={"row"} sx={{ gap: "1em" }}>
              <Stack direction={"column"} justifyContent="center" sx={{ width: "200px", marginLeft: "20px" }}>
                <Typography fontWeight={500} variant="subtitle1">
                  ช่วงเวลาโฆษณา
                </Typography>
              </Stack>
              <Stack direction={"column"} flex={1}>
                <Stack direction={"row"} alignItems="center" sx={{ mt: "0px !important", gap: "5px" }} >
                  <Typography fontWeight={400} variant="subtitle2">   {detail.sBanner_Range}</Typography>
                </Stack>
              </Stack>
            </Stack>

            <Stack direction={"row"} sx={{ gap: "1em" }}>
              <Stack direction={"column"} justifyContent="center" sx={{ width: "200px", marginLeft: "20px" }}>
                <Typography fontWeight={500} variant="subtitle1">
                  ลิงก์ไปยัง
                </Typography>
              </Stack>
              <Stack direction={"column"} flex={1}>
                <Stack direction={"row"} alignItems="center" sx={{ mt: "0px !important", gap: "5px" }} >
                  <Typography fontWeight={400} variant="subtitle2">   {detail.sBannerLink}</Typography>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
        <Grid
          item
          xs={12}
          justifyContent={"start"}
          marginTop={3}
          marginBottom={3}
        >
          <BtnBack 
          txt="ย้อนกลับ" onClick={() => onBack()} />
        </Grid>
      </Grid >
    </Grid >
  );
};
export default AdminCompanyBannerDetail;
