import MyJobApply from "components/MyJob/MyJobApply";
import { Helmet } from 'react-helmet';
import { i18n } from "i18n";
import { Box } from "@mui/material";

const MyJobApplied = () => {
  const i18nField = "entities.MyJobApplySize";

  return (
    <>
      <Box style={{ display: "none !important", visibility: "hidden" }}>
        <Helmet>
          <title> {i18n(`${i18nField}.Title`)} </title>
        </Helmet>
      </Box>
      <MyJobApply />
    </>
  );
};

export default MyJobApplied;
