
import { useState, useEffect, useContext } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Grid, Typography, Box, Paper, Stack, FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import { i18n } from 'i18n';
import Input from 'components/Common/ElementInForm/InputCustomLabelFormItem';
import { ProfileContext } from './Context/ProfileContext';
import { DialogActionCreators } from "store/redux/DialogAlert";
import { useDispatch } from "react-redux";
import { AccordionCustom, AccordionDetailsCustom, AccordionSummaryCustom } from './ElementCustom';
import { BtnBack, BtnEditProfile, BtnSubmit } from 'components/Common/Button';
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from 'react-router-dom';
import { AxiosGet, AxiosPost } from 'service/CommonFunction/TS_function';
import { FnBlock_UI } from 'service/SystemFunction/SystemFunction';
import yupFormSchemas from 'components/Common/yup/yupFormSchemas';
import CardComplete from './CardComplete';
import CardInComplete from './CardInComplete';
import secureLocalStorage from 'react-secure-storage';

const i18nField = 'entities.SeekerProfile';
const i18nCommon = 'common';
const PanelDriving = ({ Edit, isExpanded, isJobApply, sIDNew ,onBackEdit}) => {
    const navigate = useNavigate();
    const {
        profileContext,
        isLoading,
        isPanelDrivingEdit,
        setIsPanelDrivingEdit,
    } = useContext(ProfileContext);

    const dispatch = useDispatch();
    const history = useNavigate();
    const { BlockUI, UnBlockUI } = FnBlock_UI();
    const [arrLicense, setArrLicense] = useState([]);
    const [isComPlete, setisComPlete] = useState(false);
    const [selectLicense, setSelectLicense] = useState({
        selectCar: false,
        selectMotorcyc: false,
        selectOther: false,
    });
    const { selectCar, selectMotorcyc, selectOther } = selectLicense;
    const [expandDiverLicense, setExpandDiverLicense] = useState(false);
    const formSchema = {
        CarNo: yupFormSchemas.string(i18n(`${i18nField}.LicenseNo`), { required: selectCar, }),
        MotorcycleNo: yupFormSchemas.string(i18n(`${i18nField}.LicenseNo`), { required: selectMotorcyc }),
        OtherName: yupFormSchemas.string(i18n(`${i18nField}.Other`), { required: selectOther }),
        OtherNo: yupFormSchemas.string(i18n(`${i18nField}.LicenseNo`), { required: selectOther }),
    };

    const schema = yup.object().shape(formSchema);
    const form = useForm({
        resolver: yupResolver(schema),
        shouldUnregister: false,
        shouldFocusError: true,
        mode: "all",
    });

    useEffect(() => {
        GetMyProfileStatus();
        GetMyProfile();
    }, []);


    const GetMyProfileStatus = () => {
        let param = {
            nPanel: 12
        }
        AxiosPost("JobSeeker/GetMyProfileStatus", param, (res) => {
          setisComPlete(res.IsCompleted);
    
        });
      };
    const GetMyProfile = () => {
        AxiosGet("JobSeeker/GetMyProfileLicense", {}, (res) => {
            setArrLicense(res.listLicense || []);
            (res.listLicense || []).forEach((item) => {
                let arrClone = selectLicense;

                if (item.nLicenseId === 0) {
                    arrClone.selectCar = true;
                    form.setValue("CarNo", item.sLicenseNo);
                }
                if (item.nLicenseId === 1) {
                    arrClone.selectMotorcyc = true;
                    form.setValue("MotorcycleNo", item.sLicenseNo);
                }
                if (item.nLicenseId === 2) {
                    arrClone.selectOther = true;
                    form.setValue("OtherNo", item.sLicenseNo);
                    form.setValue("OtherName", item.sOther);
                }
                setSelectLicense({ ...arrClone });
            });

        });
    };
 


    const handleEditWorkUnit = () => {
        let sJobApply = "";
        let sID = "";
        if (isJobApply) {
            sJobApply = "sJobApply";
            sID = sIDNew;
        }
        history(`/MyProfileEdit?sMode=PanelDriving&sJobApply=${sJobApply}&sIDNew=${sID}`);

    }

    const onBack = () => {
        setIsPanelDrivingEdit(false);
        setExpandDiverLicense(true);
        if (isJobApply) {
            let objProfile = {
                sMode: "PanelDriving",
            };
            let objJobDetail = {
                sID: sIDNew || "",
                isApply: true,
                sFromPage: "JobDetail",
            };
            onBackEdit(objProfile,objJobDetail);

        } else {
            history(`/MyProfile?sMode=PanelDriving`);
        }
    }
    const onGoPage = () => {
        if (isJobApply) {
            let objProfile = {
                sMode: "PanelDriving",
            };
            let objJobDetail = {
                sID: sIDNew || "",
                isApply: true,
                sFromPage: "JobDetail",
            };
            onBackEdit(objProfile,objJobDetail);
        } else {
            history(`/MyProfile?sMode=PanelDriving`);
        }
    }


    useEffect(() => {
        setIsPanelDrivingEdit(Edit);
        setExpandDiverLicense(isExpanded);

    }, [])

    const onSubmit = (values) => {
        const arrLicese = [];
        if (selectCar) {
            arrLicese.push({
                nLicenseId: 0,
                sLicenseNo: values.CarNo,
            });
        }
        if (selectMotorcyc) {
            arrLicese.push({
                nLicenseId: 1,
                sLicenseNo: values.MotorcycleNo,
            });
        }
        if (selectOther) {
            arrLicese.push({
                nLicenseId: 2,
                sLicenseNo: values.OtherNo,
                sOther: values.OtherName,
            });
        }
        const dataProfile = {
            listLicense: arrLicese,
            nMode: 12,
        };

        dispatch(
            DialogActionCreators.OpenDialogSubmit(i18n(`${i18nField}.ConfirmMsgUpdate`), () => {
                dispatch(DialogActionCreators.CloseDialogSubmit() as any);
                dispatch(DialogActionCreators.LoadSubmit("Waiting...") as any);
                BlockUI();
                AxiosPost(`JobSeeker/UpdateProfile`, dataProfile, (result) => {
                    let ProfileNoti = secureLocalStorage.getItem("ProfileNoti");
                    secureLocalStorage.setItem("ProfileNoti", ProfileNoti === "true" ? "false" : "true");
                    UnBlockUI();
                    const res = result.Data;
                    if (res.IsSuccess) {
                        dispatch(DialogActionCreators.OpenDialogSuccess(i18n(`${i18nField}.MsgPanelDriving`), () => { onGoPage(); })
                        );
                    }
                },
                    (err) => {
                        if (!err.response) {
                            history("/error");
                            return;
                        }
                    }
                );
            }
            ) as any
        );
    };

    return (
        <FormProvider {...form} >
            <AccordionCustom
                sx={{ border: "10px", p: 1, flex: 1 }}
                expanded={expandDiverLicense}
                onChange={() => setExpandDiverLicense((prev) => !prev)}
            >
                <AccordionSummaryCustom>
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Grid item xs={6} sm={8} md={9}>
                            <Typography >
                                {i18n(`${i18nField}.PanelDriving`)}
                            </Typography>
                        </Grid>
                        <Grid item container
                            justifyContent="flex-end" xs={6} sm={4} md={3}>
                            <CardComplete isComPlete={isComPlete} />
                            {/* {isComPlete ? (
                                <CardComplete />
                            ) : (
                                <CardInComplete />
                            )} */}
                        </Grid>
                    </Grid>
                </AccordionSummaryCustom>
                <AccordionDetailsCustom>
                    <Box
                        component={Paper}
                        sx={{ border: "10px", p: 1, flex: 1, m: "2%" }}
                    >
                        <Grid
                            container
                            justifyContent="center"
                            alignItems="center"
                            spacing={2}
                        >
                            <Grid item container spacing={2} xs={12}>
                                <Grid item xs={12}>
                                    <FormGroup>
                                        <div style={{ display: "inherit", paddingBottom: "10px" }}>
                                            <FormControlLabel
                                                style={{ width: "200px" }}
                                                control={
                                                    <Checkbox
                                                        disabled={!isPanelDrivingEdit}
                                                        checked={selectCar}
                                                        name="Car"
                                                        onChange={(e) => {
                                                            form.setValue("CarNo", null);
                                                            setSelectLicense({
                                                                ...selectLicense,
                                                                selectCar: e.target.checked,
                                                            });
                                                            if (!e.target.checked) {
                                                                form.clearErrors("CarNo");
                                                            }
                                                        }}
                                                    />
                                                }
                                                label={i18n(`${i18nField}.Car`)}
                                            />
                                            <Grid item md={4} xs={12}>
                                                {selectCar ? (
                                                    <Input
                                                        name={"CarNo"}
                                                        small
                                                        required
                                                        maxLength={50}
                                                        label={
                                                            <Typography
                                                                sx={{ fontWeight: 400 }}
                                                                component="label"
                                                            >
                                                                {i18n(`${i18nField}.LicenseNo`)}
                                                            </Typography>
                                                        }
                                                        disabled={!isPanelDrivingEdit}
                                                    />
                                                ) : null}
                                            </Grid>
                                        </div>
                                        <div style={{ display: "inherit", paddingBottom: "10px" }}>
                                            <FormControlLabel
                                                style={{ width: "200px" }}
                                                control={
                                                    <Checkbox
                                                        checked={selectMotorcyc}
                                                        name="Motorcycle"
                                                        onChange={(e) => {
                                                            form.setValue("MotorcycleNo", null);
                                                            setSelectLicense({
                                                                ...selectLicense,
                                                                selectMotorcyc: e.target.checked,
                                                            });
                                                            if (!e.target.checked) {
                                                                form.clearErrors("MotorcycleNo");
                                                            }
                                                        }}
                                                        disabled={!isPanelDrivingEdit}

                                                    />
                                                }
                                                label={i18n(`${i18nField}.Motorcycle`)}
                                            />
                                            <Grid item md={4} xs={12}>
                                                {selectMotorcyc ? (
                                                    <Input
                                                        name={"MotorcycleNo"}
                                                        small
                                                        required
                                                        maxLength={50}
                                                        disabled={!isPanelDrivingEdit}
                                                        label={
                                                            <Typography
                                                                sx={{ fontWeight: 400 }}
                                                                component="label"
                                                            >
                                                                {i18n(`${i18nField}.LicenseNo`)}
                                                            </Typography>
                                                        }
                                                    />
                                                ) : null}
                                            </Grid>
                                        </div>

                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={selectOther}
                                                    name="Other"
                                                    onChange={(e) => {
                                                        form.setValue("OtherName", null);
                                                        form.setValue("OtherNo", null);
                                                        setSelectLicense({
                                                            ...selectLicense,
                                                            selectOther: e.target.checked,
                                                        });
                                                        if (!e.target.checked) {
                                                            form.clearErrors("OtherName");
                                                            form.clearErrors("OtherNo");
                                                        }
                                                    }}
                                                    disabled={!isPanelDrivingEdit}
                                                />
                                            }
                                            label={i18n(`${i18nField}.Other`)}
                                        />
                                        {selectOther ? (
                                            <Grid container spacing={1} >
                                                <Grid item md={4} xs={12}>
                                                    <Input
                                                        name={"OtherName"}
                                                        small
                                                        required
                                                        maxLength={50}
                                                        disabled={!isPanelDrivingEdit}
                                                    />
                                                </Grid>
                                                <Grid item md={4} xs={12}>
                                                    <Input
                                                        name={"OtherNo"}
                                                        small
                                                        required
                                                        maxLength={50}
                                                        label={
                                                            <Typography
                                                                sx={{ fontWeight: 400 }}
                                                                component="label"
                                                            >
                                                                {i18n(`${i18nField}.LicenseNo`)}
                                                            </Typography>
                                                        }
                                                        disabled={!isPanelDrivingEdit}
                                                    />
                                                </Grid>
                                            </Grid>
                                        ) : null}
                                    </FormGroup>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>

                    {!isPanelDrivingEdit && (
                        <Stack
                            sx={{ border: "10px", p: 1, flex: 1, m: "2%" }}
                            direction="row"
                        >
                            <BtnEditProfile
                                txt={i18n(`${i18nField}.Edit`)}
                                onClick={handleEditWorkUnit}
                            />
                        </Stack>
                    )}

                </AccordionDetailsCustom>
            </AccordionCustom>
            {isPanelDrivingEdit && (
                <Stack
                    spacing={1}
                    sx={{ border: "10px", p: 1, flex: 1, mt: "2%" }}
                    direction="row"
                    justifyContent="space-between"
                    alignItems="flex-start"
                >
                    <BtnBack
                        onClick={() => { onBack(); }}
                        txt={i18n(`${i18nCommon}.back`)}
                    />
                    <BtnSubmit
                        onClick={form.handleSubmit(onSubmit, (err) => console.log("error", err))}
                        txt={i18n(`${i18nCommon}.save`)}
                    />
                </Stack>
            )}
        </FormProvider >
    )
}

export default PanelDriving;