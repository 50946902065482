import React, { useEffect, useState } from "react";
import {
    DataGridMui as Table,
    initRows,
    PaginationInterface,
    FilterFieldInterface
} from "components/Common/Table/DataGridMui";
import { GridColumns } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { BtnEditOnTable } from "components/Common/Button"
import { useDispatch } from "react-redux"
import { AxiosPost, GetPermission, ResultAPI } from "service/CommonFunction/TS_function";
import { i18n } from "i18n";
import { DialogActionCreators } from "store/redux/DialogAlert";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
export default function HoldPackageMasterTable(props: { sId; GetData?}) {
    const i18nDialog = 'dialog';
    const { sId, GetData } = props;
    const dispatch = useDispatch();
    const history = useNavigate();
    const [nPermission, setPermission] = useState(0);
    const [loadingTable, setLoadingTable] = useState(true);
    const [dataRow, setDataRow] = useState<PaginationInterface>({
        ...initRows,
        sSortExpression: "sCreateDate",
        sSortDirection: "desc"
    });

    useEffect(() => {
        GetPermission(5, setPermission)
        loadData(dataRow);

    }, [sId]);

    const loadData = (p: PaginationInterface) => {
        if (!sId) return;
        let objSearch = {
            sId: sId,
            sSortExpression: p.sSortExpression + "",
            sSortDirection: p.sSortDirection + "",
            nPageSize: p.nPageSize,
            nPageIndex: p.nPageIndex,
        };
        setLoadingTable(true)
        AxiosPost("HoldPackage/SearchData_LogPausePackagedetail", objSearch, (result) => {
            setLoadingTable(false)
            setDataRow({
                ...p,
                arrRows: result.lstData,
                nDataLength: result.nDataLength,
                nPageIndex: result.nPageIndex,

            });
        });
    };

   

    // filter
    const filter: FilterFieldInterface[] = [

        { sTypeFilterMode: "daterange", sFieldName: "sStart_Date" },
        { sTypeFilterMode: "daterange", sFieldName: "sEnd_Date" },
        { sTypeFilterMode: "input", sFieldName: "sOrder_ID" },
        { sTypeFilterMode: "input", sFieldName: "sOrder_Name" },
        { sTypeFilterMode: "daterange", sFieldName: "sRemark" },
        { sTypeFilterMode: "input", sFieldName: "sAction_By" },
        { sTypeFilterMode: "daterange", sFieldName: "sCreateDate" },
    ]

    //สร้างตาราง
    const dataColumn: GridColumns = [
        {
            field: "sStart_Date",
            headerName: "วันที่ระงับการใช้งาน",
            headerAlign: "center",
            align: "left",
            resizable: false,
            sortable: true,
            flex: 1,
            minWidth: 180,
        },
        {
            field: "sEnd_Date",
            headerName: "วันที่กลับมาใช้",
            headerAlign: "center",
            align: "left",
            resizable: false,
            sortable: true,
            flex: 1,
            minWidth: 180,
        },
      
        {
            field: "sOrder_ID",
            headerName: "เลขที่ใบสั่งซื้อ",
            headerAlign: "center",
            align: "left",
            resizable: false,
            sortable: true,
            flex: 1,
            minWidth: 180,
        },
        {
            field: "sOrder_Name",
            headerName: "แพ็กเกจ",
            headerAlign: "center",
            align: "left",
            resizable: false,
            sortable: true,
            flex: 1,
            minWidth: 180,
            renderCell: (item) => {
                return [
                    (
                        <Tooltip placement="top" title={item.row.sOrder_Name}>
                            <div id={item.row.sID}
                                style={{
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    width: '300px',
                                }}>
                                {item.row.sOrder_Name}
                            </div>

                        </Tooltip>
                    )
                ];
            }
        },
        {
            field: "sRemark",
            headerName: "รายละเอียดเพิ่มเติม",
            headerAlign: "center",
            align: "left",
            resizable: false,
            sortable: true,
            flex: 1,
            minWidth: 250,
            renderCell: (item) => {
                return [
                    (
                        <Tooltip placement="top" title={item.row.sRemark}>
                            <div id={item.row.sID}
                                style={{
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    width: '300px',
                                }}>
                                {item.row.sRemark}
                            </div>

                        </Tooltip>
                    )
                ];
            }
        },
        {
            field: "sAction_By",
            headerName: "ผู้สร้าง",
            headerAlign: "center",
            align: "left",
            resizable: false,
            sortable: true,
            flex: 1,
            minWidth: 180,
        },
        {
            field: "sCreateDate",
            headerName: "วันที่อัปเดต",
            headerAlign: "center",
            align: "left",
            resizable: false,
            sortable: true,
            flex: 1,
            minWidth: 180,
        },

    ];

  
    return (
        <>
            <Typography variant="h6">{"ประวัติการระงับการใช้งาน (ค้างชำระ)"}</Typography>
            <Table
                isLoading={loadingTable}
                filterField={filter}
                Rows={dataRow}
                Columns={dataColumn}
                OnLoadData={(p) => loadData(p)}

            />
        </>
    );
}


