import InputCustomLabel from 'components/Common/ElementInForm/InputCustomLabelFormItem'
import { useForm, FormProvider } from "react-hook-form"
import { Box, Card, Grid, Hidden, Stack, Typography } from "@mui/material"
import { BtnAzureLogin, BtnLoginWithLoading } from "components/Common/Button"
import yupFormSchemas from 'components/Common/yup/yupFormSchemas'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from "yup";
import { useDispatch } from "react-redux"
import { DialogActionCreators } from 'store/redux/DialogAlert'
import { useLocation, useNavigate } from 'react-router-dom'
import { AxiosPost, AzureFunction, GoogleFunction } from 'service/CommonFunction/TS_function'
import { FnBlock_UI } from 'service/SystemFunction/SystemFunction'
import secureLocalStorage from 'react-secure-storage'
import { useEffect } from 'react'
import { AuthenticationState } from "react-aad-msal"

export default function LoginAdminPage() {
    const { LogoutAzure, LoginAzure, GetStateAzure, GetAccountAzure, GetConfigAzure, SetConfigAzure } = AzureFunction();
    const { SetUpGoogleProvider } = GoogleFunction()
    const navigate = useNavigate();
    const i18nField = 'entities.LoginForm';
    const { BlockUI, UnBlockUI } = FnBlock_UI();
    const dispatchRedux = useDispatch();

    const location = useLocation();
    const searchParams = location.search;
    const sID = new URLSearchParams(searchParams).get("sID");

    const isOrderDetail = new URLSearchParams(searchParams).get("sMode") === "1";
    const isOrderEmployerForm = new URLSearchParams(searchParams).get("sMode") === "2";
    const isAdminSpecialDetail = new URLSearchParams(searchParams).get("sMode") === "3";
    const isFreeApproveDetail = new URLSearchParams(searchParams).get("sMode") === "4";
    const isFreePackageDetail = new URLSearchParams(searchParams).get("sMode") === "5";
    const isHoldPackage = new URLSearchParams(searchParams).get("sMode") === "6";
    const isPausePackage = new URLSearchParams(searchParams).get("sMode") === "7";

    const objSchema = {
        f_username: yupFormSchemas.string("ชื่อผู้ใช้", { required: true }),
        f_usercode: yupFormSchemas.string("รหัสผ่าน", { required: true }),
        b_remember: yupFormSchemas.string("ให้ฉันอยู่ในระบบต่อไป")
    }

    const initValue: any = {
        f_username: "",
        f_usercode: "",
        b_remember: "0",
    };
    const schema = yup.object().shape(objSchema);
    const formResolver = yupResolver(schema)
    const form = useForm({
        resolver: formResolver,
        shouldUnregister: false,
        shouldFocusError: true,
        mode: "all",
        defaultValues: initValue,
    });

    const _RememberAuthen = () => {
        let localJwtKey = process.env.REACT_APP_JWT_KEY as string;

        AxiosPost("Authen/NormalAuthen", { isRemember: true, sUserId: secureLocalStorage.getItem("sUserId") }, (res) => {
            if (res.sUserKeys) {
                secureLocalStorage.setItem("sUserId", res.sUserKeys)
                secureLocalStorage.setItem("sUrlQrCode", res.sUrlQrCode)
                secureLocalStorage.setItem("isVerify", res.isEnable2Fa ? "1" : "")
                secureLocalStorage.setItem("sUserFullNameTH", res.sFullNameTH)
                secureLocalStorage.setItem("sUserFullNameEN", res.sFullNameEN)
                secureLocalStorage.setItem("sUserNickName", res.sNickName)
                secureLocalStorage.setItem(localJwtKey, res.sToken)

                navigate({ pathname: "/" })
            }
        }, (err) => {
            dispatchRedux(DialogActionCreators.OpenDialogWarning(err.Message) as any)
        }, () => UnBlockUI())
    }

    useEffect(() => {
        // if (GetAccountAzure()) {

        // } else {
        AxiosPost("Authen/AzureMapData", null, (res) => {
            setTimeout(() => {
                SetConfigAzure(res.sClientId, res.sTenantId);
            }, 3000);
        })
        // }
        //  azureLogin();
    }, [])

    useEffect(() => {
        if (GetAccountAzure()) {
            let userlogin = {
                sUserName: GetAccountAzure().userName,
                isRemember: false,
                nType: 0,
                nAdminLoginType: 1 //for azure login
            }
            AxiosPost("Authen/NormalAuthen", userlogin, async (res) => {
                if (res.sUserKeys) {
                    secureLocalStorage.setItem("sUserId", res.sUserKeys)
                    secureLocalStorage.setItem("sUrlQrCode", res.sUrlQrCode)
                    secureLocalStorage.setItem("isVerify", res.isEnable2Fa ? "1" : "")
                    secureLocalStorage.setItem("sUserFullNameTH", res.sFullNameTH)
                    secureLocalStorage.setItem("sUserFullNameEN", res.sFullNameEN)
                    secureLocalStorage.setItem("nGroupType", res.nGroupType)
                    secureLocalStorage.setItem("sUserNickName", res.sNickName)
                    await secureLocalStorage.setItem(
                        process.env.REACT_APP_JWT_KEY as string,
                        res.sToken
                    );
                    navigate(res.sPath);
                }
            }, (err) => {
                if (err.Status === 400) {
                    dispatchRedux(DialogActionCreators.OpenDialogWarning("ชื่อผู้ใช้นี้ไม่ได้ใช้งานในระบบ", () => {
                    }) as any)
                } else {
                    dispatchRedux(DialogActionCreators.OpenDialogWarning("โปรดระบุชื่อผู้ใช้งานหรือรหัสผ่านให้ถูกต้อง") as any)
                }
            })
        }
    }, [])

    const login = () => {
        LoginAzure();
    }

    const _onSubmit = (e) => {
        BlockUI();
        let userlogin = {
            sUserName: e.f_username,
            sUserCode: e.f_usercode,
            isRemember: "false",
            nType: 0,
            sID: sID ? sID : "",
            isOrderDetail: isOrderDetail,
            isOrderEmployerForm: isOrderEmployerForm,
            isAdminSpecialDetail: isAdminSpecialDetail,
            isFreeApproveDetail: isFreeApproveDetail,
            isFreePackageDetail: isFreePackageDetail,
            isHoldPackage: isHoldPackage,
            isPausePackage: isPausePackage
        }

        // Login type : job seeker = 1 , employer = 2
        AxiosPost("Authen/NormalAuthen", userlogin, async (res) => {
            if (res.sUserKeys) {
                if (e.f_remember) { secureLocalStorage.setItem("isRemember", "1") }
                secureLocalStorage.setItem("sUserId", res.sUserKeys)
                secureLocalStorage.setItem("sUrlQrCode", res.sUrlQrCode)
                secureLocalStorage.setItem("isVerify", res.isEnable2Fa ? "1" : "")
                secureLocalStorage.setItem("sUserFullNameTH", res.sFullNameTH)
                secureLocalStorage.setItem("sUserFullNameEN", res.sFullNameEN)
                secureLocalStorage.setItem("nGroupType", res.nGroupType)
                secureLocalStorage.setItem("sUserNickName", res.sNickName)
                secureLocalStorage.setItem(
                    process.env.REACT_APP_JWT_KEY as string,
                    res.sToken
                );
                navigate(res.sPath);
            }
        }, (err) => {
            if (err.Status === 400) {
                dispatchRedux(DialogActionCreators.OpenDialogWarning("ชื่อผู้ใช้นี้ไม่ได้ใช้งานในระบบ", () => {
                }) as any)
            } else {
                dispatchRedux(DialogActionCreators.OpenDialogWarning("โปรดระบุชื่อผู้ใช้งานหรือรหัสผ่านให้ถูกต้อง") as any)
            }
        }, () => UnBlockUI())
    }

    const EmptyGrid = () => <Grid item xs={1} md={4} ></Grid>;

    return (
        <Grid
            container spacing={2}
            // columns={{ xs: 6 }}
            direction="row"
            justifyContent="center"
            alignItems="center"
            style={{ height: "100%" }}
        >
            <EmptyGrid />
            <Grid item xs={10} md={4} >
                <Card sx={{ padding: "1em", borderRadius: "1em" }}>
                    <FormProvider {...form}>
                        <Stack spacing={1}>
                            <Typography variant={'h4'}
                                align='center'
                                sx={{ padding: "0.5em 1em" }} >เข้าสู่ระบบผู้ดูแลระบบ</Typography>
                            <Box sx={{ paddingX: 1, width: "100%" }}>
                                <Typography variant={'caption'} sx={{ paddingLeft: "1em" }} >ชื่อผู้ใช้</Typography>
                                <InputCustomLabel
                                    rounded
                                    name="f_username"
                                    onKeyPress={(e) => {
                                        if (e.key === "Enter") {
                                            form.setFocus("f_usercode")
                                        }
                                    }}
                                />
                            </Box>
                            <Box sx={{ paddingX: 1, width: "100%" }}>
                                <Typography variant={'caption'} sx={{ paddingLeft: "1em" }} >รหัสผ่าน
                                </Typography>
                                <InputCustomLabel
                                    rounded
                                    name="f_usercode"
                                    type="Password"
                                    onKeyPress={(e) => {
                                        if (e.key === "Enter") {
                                            const objForm = form.getValues();
                                            if (!objForm.f_username) {
                                                form.setValue("f_username", '', { shouldValidate: true });
                                                // form.setError("f_username")
                                            } else {
                                                _onSubmit(objForm);
                                            }
                                        }
                                    }}
                                />
                            </Box>
                            {/* phone size */}
                            <Hidden smUp>
                                <Grid container>
                                    <Grid item xs={12} sx={{ padding: 1, width: "100%" }}>
                                        {GetStateAzure() === AuthenticationState.Authenticated ?
                                            <BtnAzureLogin txt="Azure logout" onClick={LogoutAzure} />
                                            :
                                            <BtnAzureLogin onClick={LoginAzure} />
                                        }
                                    </Grid>
                                    <Grid item xs={12} sx={{ padding: 1, width: "100%" }} >
                                        <BtnLoginWithLoading
                                            txt={"เข้าสู่ระบบ"}
                                            isLoading={false}
                                            onClick={form.handleSubmit(e => {
                                                _onSubmit(e);
                                            })}
                                        />
                                    </Grid>
                                </Grid>
                            </Hidden>

                            {/* normal size */}
                            <Hidden smDown>
                                <Stack direction="row" sx={{ mx: "3vw" }}>
                                    <Grid container>
                                        <Grid item md={8} justifyContent="flex-end" sx={{ padding: 1, width: "100%" }}>
                                            {GetStateAzure() === AuthenticationState.Authenticated ?
                                                <BtnAzureLogin txt="Azure logout" onClick={LogoutAzure} />
                                                :
                                                <BtnAzureLogin onClick={() => { login() }} />
                                            }
                                        </Grid>
                                        <Grid item md={4} justifyContent="flex-start" sx={{ padding: 1, width: "100%" }} >
                                            <BtnLoginWithLoading
                                                txt={"เข้าสู่ระบบ"}
                                                isLoading={false}
                                                onClick={form.handleSubmit(e => {
                                                    _onSubmit(e);
                                                })}
                                            />
                                        </Grid>
                                    </Grid>
                                </Stack>
                            </Hidden>
                        </Stack>
                    </FormProvider >
                </Card>
            </Grid>
            <EmptyGrid />

        </Grid >

    )
}