import React, { useEffect, useState } from 'react'
import { Modal, Box, Typography, Tooltip, FormHelperText } from "@mui/material"
import { Close, Send } from "@mui/icons-material"
import InputCustomLabel from 'components/Common/ElementInForm/InputCustomLabelFormItem';
import { useForm, FormProvider } from "react-hook-form"
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup"
import yupFormSchemas from 'components/Common/yup/yupFormSchemas';
import { BtnCustomIconWithLoading } from "components/Common/Button"
import { i18n } from 'i18n';

export const labelI18nTh = {
    close: "ปิด",
    title: "แบบฟอร์มแจ้งลืมรหัสผ่าน",
    email: "อีเมล",
    sub_input: "หากลืมอีเมลที่ใช้สมัคร ติดต่อ",
    send: "ส่งคำขอ",
}

export const labelI18nEn = {
    close: "Close",
    title: "Form forgot password",
    email: "E-mail",
    sub_input: "If you forgot the email you used to register contact",
    send: "Send",
}

export default function Modal_Forgot({ isOpen, setOpen, funcSubmitForgot, isJobSeeker, isLoading }) {
    const styles = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        borderRadius: "10px",
        p: 2,
        minHeight: 250
    };
    const i18nField = "ForgotPw.";

    const objSchema = {
        inp_email: yupFormSchemas.email(i18n(`${i18nField}email`), { required: true }),
    }
    const schema = yup.object().shape(objSchema);
    const formResolver = yupResolver(schema)
    const form = useForm({
        shouldFocusError: true,
        resolver: formResolver,
        defaultValues: {
            inp_email: ""
        }
    });

    useEffect(() => {
        form.reset();
    }, [isOpen])

    return (
        <Modal
            open={isOpen}
            onClose={() => console.log('close')}
            closeAfterTransition
            BackdropProps={{
                timeout: 500,
            }}
        >
            <FormProvider {...form}>
                <Box sx={{ ...styles, width: 420 }} className={" flex flex-col"} >
                    <div className="flex flex-row ">
                        <div className="flex flex-[1] justify-end  ">
                            <Tooltip title={i18n(`${i18nField}close`)}>
                                <div onClick={() => {
                                    setOpen(false)
                                }} className='rounded-[50%] bg-[#d1d1d1] w-[1.5em] h-[1.5em] flex cursor-pointer ease-linear duration-[100ms]  hover:bg-[#8c8c8c]'>
                                    <Close fontSize='small' className='m-[auto] text-[white]' />
                                </div>
                            </Tooltip>
                        </div>
                    </div>
                    <div className="flex flex-row mb-[0.8em]">
                        <Typography variant={'caption'} className="!text-[1em] !font-[500]"  >
                            {i18n(`${i18nField}title`)} {`(${isJobSeeker ? i18n(`entities.LayoutFront.JobSeeker`) : i18n(`entities.LayoutFront.Employer`)})`}
                        </Typography>
                    </div>
                    <div style={{ marginTop: 5 }}>
                        <InputCustomLabel
                            rounded
                            name="inp_email"
                            label={i18n(`${i18nField}email`)}
                            onKeyPress={(e) => {
                                if (e.key === "Enter") {
                                    const objForm = form.getValues();
                                    if (!objForm.inp_email) {
                                        form.setValue("inp_email", '', { shouldValidate: true });
                                    } else {
                                        funcSubmitForgot(objForm);
                                    }
                                }
                            }}
                        />
                    </div>
                    <div className='flex flex-row mb-[0.8em]' style={{ marginTop: 5 }}>
                        <Typography variant={'caption'} className="!text-[0.6em] text-[#a1a1a1]"  >
                            {i18n(`${i18nField}sub_input`)} <a href="mailto:center_bps@bpscorporate.com">center_bps@bpscorporate.com</a>
                        </Typography>
                    </div>
                    <div className='flex flex-row justify-center'>
                        <BtnCustomIconWithLoading txt={i18n(`${i18nField}send`)} isLoading={isLoading} sxCustom={{
                            backgroundColor: "#1876d2",
                            ":hover": {
                                backgroundColor: "#104c89",
                            },
                            marginTop: 3,
                            marginBottom: 2
                        }} sIconComponent={<Send />} onClick={form.handleSubmit(funcSubmitForgot)} />
                    </div>
                </Box>
            </FormProvider>
        </Modal>
    )
}
