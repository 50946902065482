import { Card, Divider, Grid, Typography } from "@mui/material"
import { i18n } from "i18n";
import "./MyProfile.css";
import { useState, useEffect, useContext } from "react";
import { ProfileContext } from "components/SeekerProfile/Context/ProfileContext";
import { FormProvider, useFormContext } from "react-hook-form";
import WorkExperienceNew from "./WorkExperienceNew";
import WorkExperienceFile from "./WorkExperienceFile";
import MyJobPositonNew from "./MyJobPositonNew";
import MyProfileDayStart from "./MyProfileDayStart";
import PanelProfile from "./PanelProfile";
import MyAddressNew from "./MyAddressNew";
import EducationHistoryNew from "./EducationHistoryNew";
import MyCertificateNew from "./MyCertificateNew";
import PanelAbility from "./PanelAbility";
import LanguageSkillNew from "./LanguageSkillNew";
import MyComputerSkillNew from "./MyComputerSkillNew";
import PanelDriving from "./PanelDriving";
import WorkExperienceFileTranscript from "./WorkExperienceFileTranscript";
import CardPersonalInformation from "./CardPersonalInformation";
import { useLocation } from 'react-router-dom';
import { AxiosGet } from "service/CommonFunction/TS_function";


const MyProfile = (props) => {
	const { isJobApply, sIDNew, sMode } = props;
	const {
		isWorkExperienceEdit,
		isMyJobPositonEdit,
		isDayStartEdit,
		isMyAddressEdit,
		isEducationHistoryEdit,
		isCertificateEdit,
		isPanelAbilityEdit,
		isLanguageSkillEdit,
		isComputerSkillEdit,
		isPanelDrivingEdit,
	} = useContext(ProfileContext);
	const [isexpandedAddress, setisExpandedAddress] = useState(false);
	const i18nField = "entities.SeekerProfile";
	const location = useLocation();
	const searchParams = location.search;
	const sID = new URLSearchParams(searchParams).get('sMode');
	const form = useFormContext();

	useEffect(() => {
		if (sID || sMode) {
			setTimeout(() => {
				if (sID === "Experience" || sMode === "Experience") {
					document.getElementById("Experience").scrollIntoView({
						behavior: "smooth",
					})
				}
				else if (sID === "Resume" || sMode === "Resume") {
					document.getElementById("Resume").scrollIntoView({
						behavior: "smooth",
					})
				}
				else if (sID === "FileTranscript" || sMode === "FileTranscript") {
					document.getElementById("FileTranscript").scrollIntoView({
						behavior: "smooth",
					})
				}
				else if (sID === "Position" || sMode === "Position") {
					document.getElementById("Position").scrollIntoView({
						behavior: "smooth",
					})
				} else if (sID === "DayStartNew" || sMode === "DayStartNew") {
					document.getElementById("DayStartNew").scrollIntoView({
						behavior: "smooth",
					})
				}
				else if (sID === "PanelProfile" || sMode === "PanelProfile") {
					document.getElementById("PanelProfile").scrollIntoView({
						behavior: "smooth",
					})
				}
				else if (sID === "MyAddress" || sMode === "MyAddress") {
					document.getElementById("MyAddress").scrollIntoView({
						behavior: "smooth",
					})
				}
				else if (sID === "Education" || sMode === "Education") {
					document.getElementById("Education").scrollIntoView({
						behavior: "smooth",
					})
				}
				else if (sID === "Certificate" || sMode === "Certificate") {
					document.getElementById("Certificate").scrollIntoView({
						behavior: "smooth",
					})
				}
				else if (sID === "PanelAbility" || sMode === "PanelAbility") {
					document.getElementById("PanelAbility").scrollIntoView({
						behavior: "smooth",
					})
				}
				else if (sID === "LanguageSkill" || sMode === "LanguageSkill") {
					document.getElementById("LanguageSkill").scrollIntoView({
						behavior: "smooth",
					})
				}
				else if (sID === "ComputerSkill" || sMode === "ComputerSkill") {
					document.getElementById("ComputerSkill").scrollIntoView({
						behavior: 'smooth',
						
					})
				}
				else if (sID === "PanelDriving" || sMode === "PanelDriving") {
					document.getElementById("PanelDriving").scrollIntoView(
						{
						behavior: "smooth",
					})
				}
			}, 600);
		}else{
			document.getElementById("scroll_Top").scrollIntoView(true)
		}
	}, []);
    const Onback = () => {


    }
	return (
		<FormProvider {...form}>
			<Grid container spacing={2}  justifyContent={"center"} >
				{!isJobApply && (
					<CardPersonalInformation />
				)}
				<Grid item md={isJobApply ? 4 : 3} xs={isJobApply ? 7.5 : 6} style={{ marginTop: isJobApply ? "" : "25px" }}>
					<Card
						sx={{
							position: 'relative',
							borderRadius: "30px",
							padding: '15px !important',
							background: "linear-gradient(90deg, rgba(1,128,190,1) 0%, rgba(8,63,136,1) 100%, rgba(0,212,255,1) 100%)"
						}}
					>
						<Typography color={"white"} fontWeight={600} style={{ marginLeft: 10 }}>{i18n(`${i18nField}.WorkInformation`)}</Typography>
					</Card>
				</Grid>
				<Grid item md={isJobApply ? 8 : 6.7} xs={isJobApply ? 3.5 : 5}>
					<Divider style={{ backgroundColor: "#cbcccc", height: "2px", marginTop: isJobApply ? "25px" : "50px" }} />
				</Grid>
				<Grid item md={isJobApply ? 12 : 9.7} xs={11}>
				<Grid item md={12} xs={12} >
					<div id={"Experience"} className="container-div"></div>
					<div  className="container"></div>
						<WorkExperienceNew
							Edit={isWorkExperienceEdit}
							isExpanded={sID === "Experience" || sMode === "Experience" ? true : isexpandedAddress}
							isJobApply={isJobApply}
							sIDNew={sIDNew}
							sMode={sMode}
							ProfileID={sID}
							onBackEdit={Onback}
							
							 />
					</Grid>
					
					<Grid item md={12} xs={12} id={"Resume"} >
					<div className="container-div"></div>
					<div  className="container"></div>
						<WorkExperienceFile
							isExpanded={sID === "Resume" || sMode === "Resume" ? true : isexpandedAddress}
							isJobApply={isJobApply}
							sIDNew={sIDNew}
							sMode={sID || sMode }
							// isComPlete = {isFileResume}	
							 />
					</Grid>
				
					<Grid item md={12} xs={12} id={"FileTranscript"} >
					<div className="container-div"></div>
					<div  className="container"></div>
						<WorkExperienceFileTranscript
							isExpanded={sID === "FileTranscript" || sMode === "FileTranscript" ? true : isexpandedAddress}
							isJobApply={isJobApply}
							sIDNew={sIDNew}
							sMode={sID || sMode }
						/>
					</Grid>
				
					<Grid item md={12} xs={12}>
					<div id={"Position"} className="container-div"></div>
					<div  className="container"></div>
						<MyJobPositonNew
							Edit={isMyJobPositonEdit}
							isExpanded={sID === "Position" || sMode === "Position" ? true : isexpandedAddress}
							isJobApply={isJobApply}
							sIDNew={sIDNew}
							onBackEdit={Onback}
							 />
					</Grid>
					<div id={"DayStartNew"} className="container-div"></div>
					<Grid item md={12} xs={12}>
					<div  className="container"></div>
						<MyProfileDayStart
							Edit={isDayStartEdit}
							isJobApply={isJobApply}
							sIDNew={sIDNew}
							onBackEdit={Onback}
						/>
					</Grid>
				</Grid>

				<Grid item md={isJobApply ? 4 : 3} xs={isJobApply ? 7.5 : 6} style={{ marginTop: "35px" }}>
					<Card
						sx={{
							position: 'relative',
							borderRadius: "30px",
							padding: '15px !important',
							background: "linear-gradient(90deg, rgba(1,128,190,1) 0%, rgba(8,63,136,1) 100%, rgba(0,212,255,1) 100%)"
						}}
					>
						<Typography color={"white"} fontWeight={600} style={{ marginLeft: 10 }}>{i18n(`${i18nField}.PanelProfile`)}</Typography>
					</Card>
				</Grid>
				<Grid item md={isJobApply ? 8 : 6.7} xs={isJobApply ? 3.5 : 5}>
					<Divider style={{ backgroundColor: "#cbcccc", height: "2px", marginTop: "60px" }} />
				</Grid>
				<Grid item md={isJobApply ? 12 : 9.7} xs={11}>
					<Grid item md={12} xs={12} sx={{marginTop:"5px"}}>
					<div id={"PanelProfile"} className="container-div"></div>
					<div  className="container"></div>
						<PanelProfile
							Edit={isWorkExperienceEdit}
							isExpanded={sID === "PanelProfile" || sMode === "PanelProfile" ? true : isexpandedAddress}
							isJobApply={isJobApply}
							sIDNew={sIDNew}
							onBackEdit={Onback}
						/>
					</Grid>
					
					<Grid item md={12} xs={12} >
					<div id={"MyAddress"}className="container-div"></div>
					<div  className="container"></div>
						<MyAddressNew
							Edit={isMyAddressEdit}
							isExpanded={sID === "MyAddress" || sMode === "MyAddress" ? true : isexpandedAddress}
							isJobApply={isJobApply}
							sIDNew={sIDNew}
							onBackEdit={Onback}
							/>
					</Grid>

					<Grid item md={12} xs={12}>
					<div id={"Education"} className="container-div"></div>
					<div  className="container"></div>
						<EducationHistoryNew
							Edit={isEducationHistoryEdit}
							isExpanded={sID === "Education" || sMode === "Education" ? true : isexpandedAddress}
							isJobApply={isJobApply}
							sIDNew={sIDNew}
							onBackEdit={Onback}
							/>
					</Grid>


					
					<Grid item md={12} xs={12}>
					<div id={"Certificate"} className="container-div"></div>
					<div  className="container"></div>
						<MyCertificateNew
							Edit={isCertificateEdit}
							isExpanded={sID === "Certificate" || sMode === "Certificate" ? true : isexpandedAddress}
							isJobApply={isJobApply}
							sIDNew={sIDNew}
							onBackEdit={Onback}
							/>
					</Grid>

					
					<Grid item md={12} xs={12}>
					<div id={"PanelAbility"}className="container-div"></div>
					<div  className="container"></div>
						<PanelAbility
							Edit={isPanelAbilityEdit}
							isExpanded={sID === "PanelAbility" || sMode === "PanelAbility" ? true : isexpandedAddress}
							isJobApply={isJobApply}
							sIDNew={sIDNew}
							onBackEdit={Onback}
							 />
					</Grid>

				
					<Grid item md={12} xs={12}>
					<div id={"LanguageSkill"} className="container-div"></div>
					<div  className="container"></div>
						<LanguageSkillNew
							Edit={isLanguageSkillEdit}
							isExpanded={sID === "LanguageSkill" || sMode === "LanguageSkill" ? true : isexpandedAddress}
							isJobApply={isJobApply}
							sIDNew={sIDNew}
							onBackEdit={Onback}
							/>
					</Grid>

					
					<Grid item md={12} xs={12}>
					<div id={"ComputerSkill"} className="container-div"></div>
					<div  className="container"></div>
						<MyComputerSkillNew
							Edit={isComputerSkillEdit}
							isExpanded={sID === "ComputerSkill" || sMode === "ComputerSkill" ? true : isexpandedAddress}
							isJobApply={isJobApply}
							sIDNew={sIDNew}
							onBackEdit={Onback}
							/>
					</Grid>

					
					<Grid item md={12} xs={12}>
					<div id={"PanelDriving"} className="container-div"></div>
						<div  className="container"></div>
						<PanelDriving
							Edit={isPanelDrivingEdit}
							isExpanded={sID === "PanelDriving" || sMode === "PanelDriving" ? true : isexpandedAddress}
							isJobApply={isJobApply}
							sIDNew={sIDNew}
							onBackEdit={Onback}
							 />
					</Grid>
				</Grid>
			</Grid>
			<div className="container-divNew"></div>
		
		</FormProvider >
	)
}
export default MyProfile