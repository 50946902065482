import { Stack } from "@mui/material";
import AdminOrderDetailCom from "../../../components/AdminWorkflow/AdminOrder/AdminOrderDetail";

export default function AdminOrderDetail() {
 
  return (
    <Stack sx={{ mx: "1%" }}>
        <AdminOrderDetailCom  nType_ID={0} SpecialEdit={false}/>
    </Stack>
  );
}