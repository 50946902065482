import React, { useEffect, useState } from "react";
import { useLocation,useNavigate } from "react-router-dom";
import { AxiosPost } from "service/CommonFunction/TS_function";
import { useDispatch } from "react-redux";
import { DialogActionCreators } from "store/redux/DialogAlert";
import { FnBlock_UI } from "service/SystemFunction/SystemFunction";
export default function AdminResetPWD() {
  const location = useLocation();
  const history = useNavigate();
  const dispatch = useDispatch();
  const { BlockUI, UnBlockUI } = FnBlock_UI();
  useEffect(() => {
    BlockUI();
    const sCode = new URLSearchParams(location.search).get("sCode");
    AxiosPost(
      "UserPermission/OnConfirmResetPWD",
      { sResetPWDCode: sCode },
      (res) => {
        UnBlockUI();
        if (res.Status === 200) {
          dispatch(
            DialogActionCreators.OpenDialogSuccess(res.Message, () => {
                history("/");
            })
          );
        } else {
          dispatch(DialogActionCreators.OpenDialogWarning(res.Message));
        }
      },
      (err) => {
        if (!err.response) {
          history("/error", null);
          return;
        }
      }
    );
  }, []);
  return <div></div>;
}
