const SeekerProfileTH = {
  SeekerProfile: {
    //stepper
    Step1: "สร้างโปรไฟล์เข้าใช้งาน",
    Step2: "Resume และเอกสารแนบอื่นๆ",
    Step3: "ข้อมูลส่วนตัว",

    PanelProfile: "ข้อมูลส่วนตัว",
    Tel: "เบอร์โทรศัพท์",
    Sex: "เพศ",
    DOB: "วันเกิด",
    AgeYear: "ปี",
    Army: "สถานะการเกณฑ์ทหาร",
    MarriedStatus: "สถานะการสมรส",
    Single: "โสด",
    Married: "สมรส",
    // Create Profile
    Name: "ชื่อ",
    Surname: "นามสกุล",
    NameEN: "ชื่อ(ภาษาอังกฤษ)",
    SurnameEN: "นามสกุล(ภาษาอังกฤษ)",
    Email: "อีเมล",
    OtherContact:
      "ช่องทางติดต่ออื่นๆ (Facebook, website, Line, Instagram, Twitter)",
    Phone: "เบอร์โทรศัพท์",
    sSecureCode: "รหัสผ่าน",
    ConsentLine1: "สามารถดำเนินการในระบบต่อไป",
    ConsentLine2: "รับข่าวสารผ่านช่องทางอีเมลของเว็บไซต์",
    ConsentLine3:
      "ขณะทำรายการมีอายุไม่ต่ำกว่า 20 ปี หรือได้รับความยินยอมจากผู้ปกครอง",
    BtnCreateProfile: "สร้างโปรไฟล์",
    ErrorConsent: "กรุณา 'ยอมรับ' รายการข้อที่ 2 เพื่อใช้งานระบบต่อไป",
    ErrorEmail: "Email นี้ถูกใช้งานแล้ว",
    ErrorNameEN: "กรุณาระบุตัวอักษรภาษาอังกฤษเท่านั้น",
    ErrorName: "กรุณาระบุตัวอักษรภาษาไทยเท่านั้น",

    // WorkExperience
    PanelWorkEXP: "ประสบการณ์การทำงานที่ผ่านมา",
    UploadProfile: "รูปถ่าย",
    HaveWorkEXP: "มีประสบการณ์ทำงาน",
    WorkEXP: "ประสบการณ์ทำงาน (ปี)",
    NotWordEXP: "ยังไม่มีประสบการณ์ทำงาน",
    PnWorkDetail: "ประสบการณ์ทำงานล่าสุด",
    Position: "ตำแหน่ง",
    Company: "บริษัท",
    PeriodDate: "ช่วงวันที่ทำงาน",
    StartDate: "เริ่ม",
    EndDate: "สิ้นสุด",
    ToCurrent: "จนถึงปัจจุบัน",
    JobFunction: "ประเภทงาน",
    JobDetail: "ชื่องาน",
    JobIndustry: "ประเภทอุตสาหกรรม",
    AddWorkDetail: "เพิ่มรายการ",
    WorkRemark: "รายละเอียดเพิ่มเติม (สรุปหน้าที่ ความรับผิดชอบ)",
    WorkSalary: "เงินเดือน (บาท)",
    LeaveRemark: "สาเหตุที่ลาออกจากงาน",



    // Address
    PanelAddress: "ที่อยู่ปัจจุบัน",
    Country: "ประเทศ",
    Region: "ภูมิภาค/กรุงเทพมหานคร",
    Province: "จังหวัด/เขต",
    AddressNo: "เลขที่",
    District: "อำเภอ",
    SubDistrict: "ตำบล",
    Moo: "หมู่ที่",
    Street: "ถนน",
    PostCode: "รหัสไปรษณีย์",

    //Education
    PanelEducation: "ประวัติการศึกษา",
    Academy: "สถาบันการศึกษา",
    EducateLevel: "ระดับการศึกษา",
    University: "สถาบันการศึกษา",
    Faculty: "คณะ",
    Major: "สาขา",
    GraduationYear: "ปีจบการศึกษา",
    EduRemark: "หลักสูตร หรือการอบรมอื่นๆ ระหว่างเรียน",

    //Certificate
    PanelCert: "ใบอนุญาต หรือประกาศนียบัตร",
    CertName: "ชื่อใบอนุญาต หรือประกาศนียบัตร",
    ApproveBy: "สถาบัน หรือองค์กรที่ออกให้",
    ApproveDate: "วันที่ออกให้",
    ExpirdDate: "วันที่หมดอายุ",
    NotExpird: "ตลอดชีพ",
    CertRemark: "รายละเอียดเพิ่มเติม",

    //Ability
    PanelAbility: "ทักษะความสามารถ",
    Ability: "ชื่อทักษะ หรือความสามารถ",
    AbilityWarning: "ชื่อทักษะหรือความสามารถ จำนวนตัวอักษรเกิน 1000 ตัวอักษร",

    //langPro
    PanelLangPro: "ความสามารถทางภาษา",
    Language: "ภาษา",
    ConvsSkill: "ระดับความชำนาญการพูด",
    ReadSkill: "ระดับความชำนาญการอ่าน",
    WriteSkill: "ระดับความชำนาญการเขียน",

    //ComputerSkill
    PanelComputerSkill: "ความสามารถทักษะโปรแกรมคอมพิวเตอร์",
    Program: "เลือกโปรแกรม",
    SkillLevel: "ระดับความชำนาญในการใช้งาน",

    //Driving License
    PanelDriving: "ความสามารถในการขับขี่",
    Car: "รถยนต์",
    Motorcycle: "รถจักรยานยนต์",
    Other: "อื่นๆ",
    LicenseNo: "เลขที่ใบขับขี่",

    //JobApply
    PanelJobApply: "ตำแหน่งงานที่ต้องการสมัคร",
    ApplyPosition: " ชื่อตำแหน่งงาน",
    ApplyJobFunction: "ประเภทงาน",
    ApplyJobDetail: "ชื่องาน",
    ApplyJobType: "ลักษณะการจ้างงาน",
    ApplySalaryStart: " เงินเริ่มต้นคาดหวัง",
    ApplySalaryEnd: "เงินสูงสุดคาดหวัง",
    ApplySalarySpeccified: "สามารถต่อรองเงินเดือนได้",
    MsgInvalidSalaryEnd: "เงินสูงสุดต้องมากกว่าเงินเริ่มต้น",
    Details: "โปรดระบุข้อมูล หากต้องการให้ระบบแนะนำตำแหน่งงานที่ท่านสนใจ",
    MsgUpdateProfile: "กรุณาอัปเดตข้อมูลส่วนตัวให้ครบถ้วน",

    //My File
    PanelMyFile: "เอกสารแนบ",

    //Tag
    PanelTag: "คำค้นหาสำคัญ สำหรับการค้นหาข้อมูลจากผู้ประกอบการ",
    TagName: "ระบุคำค้นสำคัญ",
    MsgTag: "ระบุ คำค้น แล้วกด Enter",
    MsgSkill: "ระบุทักษะแล้วกด Enter",
    TagWarning: "คำค้นหาสำคัญจำนวนตัวอักษรเกิน 1000 ตัวอักษร",

    StartNow: "สามารถเริ่มงานได้ทันที",
    StartLate: "สามารถเริ่มงานได้นับถัดจากวันที่ได้รับยืนยันรับเข้าทำงาน",
    Day: "วัน",
    CurrentSalary: "เงินเดือนล่าสุดที่ได้รับ (บาท)",
    ConfirmMsgCreate: "กรอกข้อมูลครบถ้วน และต้องการสร้างโปรไฟล์ ใช่หรือไม่",
    MsgCreated: "กรุณาตรวจสอบอีเมล ของท่านเพื่อยืนยันตัวตน",
    MsgResume: "กรุณากรอกข้อมูล เอกสาร Resume",

    ConfirmMsgUpdate: "กรอกข้อมูลครบถ้วน และต้องแก้ไขโปรไฟล์ ใช่หรือไม่",
    MsgUpdated: "แก้ไขโปรไฟล์เรียบร้อย",
    MsgWorkExperience: "อัปเดตประสบการณ์ทำงานที่ผ่านมาเรียบร้อย",
    MsgResumeUpdated:"อัปเดตเอกสาร Resume เรียบร้อย",
    MsgTranscript:"อัปเดตเอกสารแนบอื่นๆ (Transcript, Certificate and TOEIC, ETC.) เรียบร้อย",
    MsgPanelJobApply:"อัปเดตตำแหน่งงานที่ต้องการสมัครเรียบร้อย",
    MsgPanelTag:"อัปเดตคำค้นหาสำคัญสำหรับการค้นหาข้อมูลจากผู้ประกอบการเรียบร้อย",
    MsgPanelProfile:"อัปเดตข้อมูลส่วนตัวเรียบร้อย",
    MsgPanelAddress:"อัปเดตที่อยู่ปัจจุบันเรียบร้อย",
    MsgPanelEducation:"อัปเดตประวัติการศึกษาเรียบร้อย",
    MsgPanelCert:"อัปเดตใบอนุญาตหรือประกาศนียบัตรเรียบร้อย",
    MsgPanelAbility:"อัปเดตทักษะความสามารถเรียบร้อย",
    MsgPanelLangPro:"อัปเดตความสามารถทางภาษาเรียบร้อย",
    MsgPanelComputerSkill:"อัปเดตความสามารถทักษะโปรแกรมคอมพิวเตอร์เรียบร้อย",
    MsgPanelDriving:"อัปเดตความสามารถในการขับขี่เรียบร้อย",
    MsgResumeDelete:"ลบเอกสาร Resume สำเร็จ",
    MsgTranscriptDelete:"ลบเอกสารแนบอื่นๆ (Transcript, Certificate and TOEIC, ETC.) สำเร็จ",

    ConfirmMsgJobApply: "ต้องการส่งใบสมัครงาน ใช่หรือไม่",
    MsgJobApply: "สมัครงานเรียบร้อย",

    //Job Apply
    jobApply: "สมัครงาน",
    RequireSalary: "เงินเดือนที่ต้องการ (บาท)",
    Introduction: "แนะนำตัวเบื้องต้น",
    ApplySalaryByCompany: "ตามโครงสร้างบริษัท / ต่อรองได้",
    UpdateProfile: "อัปเดตประวัติ",
    SalaryDetail: "เงินเดือน / ข้อมูลเพิ่มเติม",
    Policy: "เมื่อคุณคลิกสร้างโปรไฟล์ คุณได้ยอมรับ ",
    Policy2: "ประกาศความเป็นส่วนบุคคล",
    Policy3: "และ",
    Policy4: "ข้อกำหนดและเงื่อนไข",
    Policy5: "แล้ว",

    //เอกสาร Resume
    Resume: "เอกสาร Resume",
    fileCountLimit: "เอกสาร Resume และเอกสารแนบอื่นๆ (Transcript, Certificate and TOEIC, ETC.) อัปโหลดสูงสุดได้อย่างละ 5 ไฟล์",

    //เอกสารแนบอื่นๆ 
    Transcript: "เอกสารแนบอื่นๆ (Transcript, Certificate and TOEIC, ETC.)",

    //ปรับปรุงประวัติ
    PersonalInformation: "ข้อมูลของท่านยังไม่สมบูรณ์ กรุณาปรับปรุงข้อมูลการทำงานและประวัติส่วนตัว เพื่อเพิ่มโอกาสในการพิจารณาสำหรับผู้ประกอบการ",
    PersonalInformationComplete: "อัปเดตโปรไฟล์ จะช่วยให้ระบบแนะนำงานที่ตรงกับคุณมากขึ้น และเพิ่มโอกาสในการพิจารณาสำหรับผู้ประกอบการ",

    //ข้อมูลโปรไฟล์
    ProfileInformation: "ข้อมูลโปรไฟล์",

    //ข้อมูลการทำงาน
    WorkInformation: "ข้อมูลการทำงาน",

    //ปุ่มแก้ไข
    Edit: "แก้ไข",

    //สมบูรณ์
    Complete: "สมบูรณ์",
    InComplete: "ไม่สมบูรณ์"
  },
};

const SeekerProfileEN = {
  SeekerProfile: {
    //stepper
    Step1: "Create account",
    Step2: "Resume & Other Documents",
    Step3: "Summary Profile",

    MessageConfirm: "ระบุข้อมูลครบถ่วนแล้วใช่หรือไม่",
    PanelProfile: "My Profile",
    Tel: "Mobile Phone",
    Sex: "Gender",
    DOB: "Birth Date",
    AgeYear: "Years",
    Army: "Military Status",
    MarriedStatus: "Married Status",
    Single: "Single",
    Married: "Married",
    // first
    Name: "Name",
    Surname: "Surname",
    NameEN: "Name (English)",
    SurnameEN: "Surname (English)",
    Email: "Email",
    OtherContact:
      "Other contact methods (Facebook, website, Line, Instagram, Twitter)",
    Phone: "Phone",
    sSecureCode: "Password",
    ConsentLine1: "Can continue in the system",
    ConsentLine2: "Receive news through the website's email channel.",
    ConsentLine3:
      "At the time of the transaction, the age is not less than 20 years or has the consent of the parents.",
    BtnCreateProfile: "Create Profile",
    ErrorConsent:
      "Please 'Accept' consent number 2 to continue using the system.",
    ErrorEmail: "Email is already in system.",
    ErrorNameEN: "Please enter English characters only.",
    ErrorName: "Please enter Thai characters only.",

    // WorkExperience
    PanelWorkEXP: "Work Experience",
    UploadProfile: "Photo",
    WorkEXP: "Work Experience (Year)",
    HaveWorkEXP: "Have Work Experience",
    NotWordEXP: "No Work Experience",
    PnWorkDetail: "Work Experience",
    Position: "Position",
    Company: "Company",
    PeriodDate: "Period Date",
    StartDate: "Start Date",
    EndDate: "Last Date",
    ToCurrent: "Is Current",
    JobFunction: "Job Function",
    JobDetail: "Job Detail",
    JobIndustry: "Industry Type",
    AddWorkDetail: "Add",
    WorkRemark: "Role and Responsibility",
    WorkSalary: "Salary (THB)",
    LeaveRemark: "Leave Remark",

    // Address
    PanelAddress: "Address",
    Country: "Country",
    Region: "Region/Bangkok",
    Province: "Provice/Area",
    AddressNo: "Address No.",
    District: "District",
    SubDistrict: "Sub District",
    Moo: "Moo",
    Street: "Street",
    PostCode: "Post Code",

    //Education
    PanelEducation: "Educational History",
    Academy: "Educational Institution",
    EducateLevel: "Educational Level",
    University: "Educational Institution",
    Faculty: "Faculty",
    Major: "Field of Study",
    GraduationYear: "Graduation Year",
    EduRemark: "Other Training Courses",

    //Certificate
    PanelCert: "Certificate",
    CertName: "Certificate Name",
    ApproveBy: "Institution",
    ApproveDate: "Institution Date",
    ExpirdDate: "Expire Date",
    NotExpird: "Is Not Expire",
    CertRemark: "Remark",

    //Ability
    PanelAbility: "Ability",
    Ability: "Ability",
    AbilityWarning: "Name of skill or ability Number of characters exceeds 1000 characters.",

    //langPro
    PanelLangPro: "Language Skill",
    Language: "Language",
    ConvsSkill: "Speaking Level",
    ReadSkill: "Reading Level",
    WriteSkill: "Writing Level",

    //ComputerSkill
    PanelComputerSkill: "Computer Skill",
    Program: "Program",
    SkillLevel: "Level",

    //driving license
    PanelDriving: "Driving License",
    Car: "Car",
    Motorcycle: "Motorcycle",
    Other: "Other",
    LicenseNo: "License No.",

    //JobApply
    PanelJobApply: "Job Interested",
    ApplyPosition: "Position/Title",
    ApplyJobFunction: "Job Function",
    ApplyJobDetail: "Job Detail",
    ApplyJobType: "Job Type",
    ApplySalaryStart: "Salary Start",
    ApplySalaryEnd: "Salary End",
    ApplySalarySpeccified: "Negotiable Salary",
    MsgInvalidSalaryEnd:
      "The maximum salary must be greater than the starting salary.",
    Details: "Please specify information If you want the system to recommend job positions that interest you.",
    MsgUpdateProfile: "Please update your personal information completely.",

    //My File
    PanelMyFile: "Attachment",

    //Tag
    PanelTag: "Keywords for searching information of employers",
    TagName: "Keywords",
    MsgTag: "You can specify keywords then pressing Enter.",
    MsgSkill: "Please input Ability or Skill and press Enter!",
    TagWarning: "Search for keywords with more than 1000 characters.",

    StartNow: "available to start now",
    StartLate: "available to start with in",
    Day: "Day(s)",
    CurrentSalary: "Current Salary (THB)",
    ConfirmMsgCreate: "Do you want to create profile?",
    MsgCreated: "Check you mail box for activate account.",
    MsgResume: "Please fill out the Resume document.",

    ConfirmMsgUpdate: "Do you want to update profile?",
    MsgUpdated: "Update profile success.",
    MsgWorkExperience:"Work Experience Updated",
    MsgResumeUpdated:"Resume Updated",
    MsgTranscript:"Other Documents (Transcript, Certificate and TOEIC, ETC.) Updated",
    MsgPanelJobApply:"Job Interested Updated",
    MsgPanelTag:"Keywords for searching information of employers Updated",
    MsgPanelProfile:"My Profile Updated",
    MsgPanelAddress:"Address Updated",
    MsgPanelEducation:"Educational History Updated",
    MsgPanelCert:"Certificate Updated",
    MsgPanelAbility:"Ability Updated",
    MsgPanelLangPro:"Language Skill Updated",
    MsgPanelComputerSkill:"Computer Skill Updated",
    MsgPanelDriving:"Driving License Updated",
    MsgResumeDelete:"Remove Resume successfully.",
    MsgTranscriptDelete:"Remove Other Documents (Transcript, Certificate and TOEIC, ETC.) successfully.",

    ConfirmMsgJobApply: "Do you want to apply job?",
    MsgJobApply: "Apply job success.",

    //Job Apply
    jobApply: "Job Apply",
    RequireSalary: "Require Salary (THB)",
    Introduction: "Introduction",
    ApplySalaryByCompany: "According to the company structure / negotiable",
    UpdateProfile: "Update Profile",
    SalaryDetail: "Salary / More Detail",
    Policy: "By creating a profile, I confirm I have read and accept ",
    Policy2: "Privacy Statement",
    Policy3: "and",
    Policy4: "Terms and Conditions.",
    Policy5: " ",

    //เอกสาร Resume
    Resume: "Resume",
    fileCountLimit: "Resume and Transcript, Certificate and TOEIC, ETC. Upload a maximum of 5 File !",
  
    //เอกสารแนบอื่นๆ 
    Transcript: "Other Documents (Transcript, Certificate and TOEIC, ETC.)",

    //ปรับปรุงประวัติ
    PersonalInformation: "Your profile is incomplete. Please update your profile to enhance your chances of being considered by employers.",
    PersonalInformationComplete: "Update your profile to receive better job recommendations and increase your chances of being considered by employers.",
    //ข้อมูลโปรไฟล์
    ProfileInformation: "Profile Information",

    //ข้อมูลการทำงาน
    WorkInformation: "Work Information",

    //ปุ่มแก้ไข
    Edit: "Edit",

    //สมบรูณ์
    Complete: "Completed",
    InComplete: "Incomplete"
  },
};

export { SeekerProfileEN, SeekerProfileTH };
