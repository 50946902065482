import { useState, useEffect, useContext, useRef, useCallback } from "react";
import { FormProvider, useForm } from "react-hook-form";
import {
  Grid,
  Typography,
  Box,
  Paper,
  IconButton,
  FormControlLabel,
  Checkbox,
  FormHelperText,
  Stack,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { i18n } from "i18n";
import Input from "components/Common/ElementInForm/InputCustomLabelFormItem";
import AutoCompleteSelect from "components/Common/ElementInForm/AutoCompleteSelect";
import { ProfileContext } from "./Context/ProfileContext";
import { IMyJobPosition } from "./Interface";
import { DialogActionCreators } from "store/redux/DialogAlert";
import { useDispatch } from "react-redux";
import InputNumber from "components/Common/ElementInForm/InputNumber";
import { AccordionCustom, AccordionDetailsCustom, AccordionSummaryCustom } from "./ElementCustom";
import { BtnBack, BtnEditProfile, BtnRadiusProfile, BtnSubmit } from "components/Common/Button";
import yupFormSchemas from "components/Common/yup/yupFormSchemas";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { FnBlock_UI } from "service/SystemFunction/SystemFunction";
import { AxiosGet, AxiosPost } from "service/CommonFunction/TS_function";
import CardComplete from "./CardComplete";
import secureLocalStorage from "react-secure-storage";
const i18nField = "entities.SeekerProfile";
const i18nCommon = "common";
const MyJobPositonNew = ({ Edit, isExpanded, isJobApply, sIDNew,onBackEdit }) => {
  const navigate = useNavigate();
  const history = useNavigate();
  const dispatch = useDispatch();
  const {
    jobPositionContext,
    setJobPosition,
    optionPosition,
    optionJobFunction,
    optionJobDetail,
    setOptionJobDeatail,
    optionJobIndustry,
    optionJobType,
    isMyJobPositonEdit,
    setIsMyJobPositonEdit,
    setnMode,
    profileContext,
    isLoading,
  } = useContext(ProfileContext);
;
  const [isSpeccified, setIsSpeccified] = useState(false);
  const [isInvalidSalaryEnd, setIsInvalidSalaryEnd] = useState(false);
  const [Parent, setParent] = useState(0);
  const [expandedJobApply, setExpandedJobApply] = useState(false);
  const { BlockUI, UnBlockUI } = FnBlock_UI();
  const [arrjobPosition, setArrjobPosition] = useState([]);
  const [isComPlete, setisComPlete] = useState(false);

  // Set up Schema Position Job 
  const [shcemaPositionJob, setShcemaPositionJob] = useState({});
  const loopSetSchemaPositionJob = () => {
    const schemaTemp = {};
    jobPositionContext.forEach((item) => {
      schemaTemp[`ApplyPosition${item.Index}`] = yupFormSchemas.string(i18n(`${i18nField}.ApplyPosition`), { required: true });
      schemaTemp[`ApplyJobFunction${item.Index}`] = yupFormSchemas.object(i18n(`${i18nField}.ApplyJobFunction`), { required: true });
      schemaTemp[`ApplyJobDetail${item.Index}`] = yupFormSchemas.object(i18n(`${i18nField}.ApplyJobDetail`), { required: true });
      schemaTemp[`ApplyJobType${item.Index}`] = yupFormSchemas.object(i18n(`${i18nField}.ApplyJobType`), { required: true });
      schemaTemp[`ApplyIndustryType${item.Index}`] = yupFormSchemas.object(i18n(`${i18nField}.JobIndustry`), { required: true });
      schemaTemp[`ApplySalaryStart${item.Index}`] = yupFormSchemas.integer(i18n(`${i18nField}.ApplySalaryStart`), { required: true });
      schemaTemp[`ApplySalaryEnd${item.Index}`] = yupFormSchemas.integer(i18n(`${i18nField}.ApplySalaryEnd`));
    });
    setShcemaPositionJob(schemaTemp);
  };
  const [formSchema, setFormSchema] = useState<any>({
    ...shcemaPositionJob,
  });
  // -> Position Job
  const arrSchemaNamePositionJob = [
    "ApplyPosition",
    "ApplyJobFunction",
    "ApplyJobDetail",
    "ApplyJobType",
    "ApplyIndustryType",
    "ApplySalaryStart",
    "ApplySalaryEnd",
  ];

  useEffect(() => {
    for (const [key, value] of Object.entries(formSchema)) {
      if (arrSchemaNamePositionJob.some((a) => key.includes(a))) {
        delete formSchema[key];
      }
    }
    setFormSchema({ ...formSchema, ...shcemaPositionJob });
  }, [shcemaPositionJob]);

  const schema = yup.object().shape(formSchema);
  const form = useForm({
    resolver: yupResolver(schema),
    shouldUnregister: false,
    shouldFocusError: true,
    mode: "all",
  });

  // useEffact set new schema 
  useEffect(() => {
    loopSetSchemaPositionJob();
  }, [jobPositionContext])




const GetMyProfileStatus = () => {
  let param = {
    nPanel: 4
}
  AxiosPost("JobSeeker/GetMyProfileStatus", param, (res) => {
      setisComPlete(res.IsCompleted);

    });
  };

  useEffect(() => {
    GetMyProfileStatus();
}, []);

  const handleChangeValue = (form, index) => {

    let newData = jobPositionContext.map((item) => {
      if (item.Index === index) {
        item.ApplyPosition = form.getValues("ApplyPosition" + index);
        if (form.getValues("ApplyJobFunction" + index).value) {
          item.ApplyJobFunction = form.getValues("ApplyJobFunction" + index);
        }
        if (form.getValues("ApplyJobDetail" + index).value) {
          item.ApplyJobDetail = form.getValues("ApplyJobDetail" + index);
        }
        item.ApplyIndustryType = form.getValues("ApplyIndustryType" + index);
        item.ApplyJobType = form.getValues("ApplyJobType" + index);
        item.ApplySalaryStart = form.getValues("ApplySalaryStart" + index);
        item.ApplySalaryEnd = form.getValues("ApplySalaryEnd" + index);
        item.IsNotSpeccified = form.getValues("checkNotSpeccified" + index);
        item.nParent = form.getValues("ApplyJobFunction" + index).value;
      }
      setIsSpeccified(form.getValues("checkNotSpeccified" + index))
      return item;
    });

    setJobPosition(newData);
  };

  const handleRemoveJobApply = (itemIndex: number) => {
    dispatch(
      DialogActionCreators.OpenDialogSubmit(
        i18n(`${i18nCommon}.AlertConfrimDel`),
        () => {
          dispatch(DialogActionCreators.CloseDialogSubmit() as any);
          dispatch(DialogActionCreators.LoadSubmit("Waiting...") as any);
          let newData = jobPositionContext.filter((data) => {
            return data.Index !== itemIndex;
          });
          setJobPosition(newData);

          form.unregister("ApplyPosition" + itemIndex);
          form.unregister("ApplyJobFunction" + itemIndex);
          form.unregister("ApplyJobDetail" + itemIndex);
          form.unregister("ApplyJobType" + itemIndex);
          form.unregister("ApplyIndustryType" + itemIndex);
          form.unregister("ApplySalaryStart" + itemIndex);
          form.unregister("ApplySalaryEnd" + itemIndex);
          form.unregister("checkNotSpeccified" + itemIndex);
        }
      )
    );
  };

  const handleAddJobAppiy = () => {
    let arrIndex =
      jobPositionContext.map((item, index) => {
        return item.Index;
      }) || [];
    let nGenIndex = 0;
    if (arrIndex.length > 0) {
      nGenIndex = (Math.max.apply(Math, arrIndex) || 0) + 1;
    }
    let newData = [
      ...jobPositionContext,
      {
        Index: nGenIndex,
        ApplyPosition: null,
        ApplyJobFunction: null,
        ApplyJobDetail: null,
        ApplyJobType: null,
        ApplyIndustryType: null,
        ApplySalaryStart: null,
        ApplySalaryEnd: null,
        IsNotSpeccified: null,
      },
    ] as Array<IMyJobPosition>;
    setJobPosition(newData);
    setFocusNewField(`ApplyPosition${nGenIndex}`);
  };

  const setFocusNewField = (sName) => {
    setTimeout(() => {
      try {
        form.setFocus(sName);
      } catch (error) { }
    }, 500);
  };


  const setDataMyProfile = () => {
    const data = profileContext as any;
    setArrjobPosition(data.listPosition || []);
    let listPosition = [];
    if ((data.listPosition || []).length > 0) {
      listPosition = data.listPosition.map((item) => {
        const selectJobFunc = optionJobFunction.find((data: any) => {
          return data.value === item.nJobFunctionId;
        });
        const selectJobDetail = optionJobDetail.find((data: any) => {
          return data.value === item.nJobDetailId;
        });
        const selectJobType = optionJobType.find((data: any) => {
          return data.value === item.nJobTypeId;
        });
        const selectJobIndustry = optionJobIndustry.find((data: any) => {
          return data.value === (item.nIndustryTypeId.toString() || "");
        });
        return {
          Index: item.nPositionId,
          ApplyPosition: item.sJobPositionName,
          ApplyJobFunction: selectJobFunc,
          ApplyJobDetail: selectJobDetail,
          ApplyJobType: selectJobType,
          ApplyIndustryType: selectJobIndustry,
          ApplySalaryStart: item.nSalaryStart,
          ApplySalaryEnd: item.nSalaryEnd,
          IsNotSpeccified: item.IsNotSpecified,
          nParent: item.nJobFunctionId

        } as IMyJobPosition;
      }) as IMyJobPosition[];
    } else {
      listPosition = [
        {
          Index: 0,
          ApplyPosition: null,
          ApplyJobFunction: null,
          ApplyJobDetail: null,
          ApplyJobType: null,
          ApplyIndustryType: null,
          ApplySalaryStart: null,
          ApplySalaryEnd: null,
          IsNotSpeccified: false,
          nParent: 0
        },
      ] as IMyJobPosition[];
    }
    setJobPosition(listPosition);
    listPosition.forEach((data) => {
   
      form.setValue("ApplyPosition" + data.Index, data.ApplyPosition || "");
      form.setValue("ApplyJobFunction" + data.Index, data.ApplyJobFunction || "");
      form.setValue("ApplyJobDetail" + data.Index, data.ApplyJobDetail || "");
      form.setValue("ApplyJobType" + data.Index, data.ApplyJobType || "");
      form.setValue("ApplyIndustryType" + data.Index, data.ApplyIndustryType || null);
      form.setValue("ApplySalaryStart" + data.Index, data.ApplySalaryStart);
      form.setValue("ApplySalaryEnd" + data.Index, data.ApplySalaryEnd );
      form.setValue("checkNotSpeccified" + data.Index, data.IsNotSpeccified || false);
    });

  };



  const handleEditWorkUnit = () => {
    let sJobApply = "";
    let sID = "";
    if (isJobApply) {
      sJobApply = "sJobApply";
      sID = sIDNew;
    }
    history(`/MyProfileEdit?sMode=Position&sJobApply=${sJobApply}&sIDNew=${sID}`);
  }

  const onBack = () => {
    setExpandedJobApply(true);
    setIsMyJobPositonEdit(false);
    if (isJobApply) {
      let objProfile = {
        sMode: "Position",
      };
      let objJobDetail = {
        sID: sIDNew || "",
        isApply: true,
        sFromPage: "JobDetail",
      };
      onBackEdit(objProfile,objJobDetail);

    } else {
      history(`/MyProfile?sMode=Position`);
    }
  }
  const onGoPage = () => {
    if (isJobApply) {
      let objProfile = {
        sMode: "Position",
      };
      let objJobDetail = {
        sID: sIDNew || "",
        isApply: true,
        sFromPage: "JobDetail",
      };
      onBackEdit(objProfile,objJobDetail);
    } else {
      history(`/MyProfile?sMode=Position`);
    }
  }

  useEffect(() => {
    setExpandedJobApply(isExpanded);
    setIsMyJobPositonEdit(Edit);

  }, []);

  useEffect(() => {
    if (!isLoading) {
      setDataMyProfile();
    }
  }, [isLoading]);



  const onSubmit = (values) => {
    const arrJobPosition = jobPositionContext.map((item) => {
      return {
        nPositionId: item.Index,
        sJobPositionName: item.ApplyPosition,
        nJobFunctionId: item.ApplyJobFunction["value"] || 0,
        nJobDetailId: item.ApplyJobDetail["value"] || 0,
        nJobTypeId: item.ApplyJobType["value"] || 0,
        nIndustryTypeId: item.ApplyIndustryType["value"] || 0,
        nSalaryStart: item.ApplySalaryStart,
        nSalaryEnd: item.ApplySalaryEnd,
        IsNotSpecified: item.IsNotSpeccified || false,
      };
    });

    //Check validate salary end
    let isInvalid = false;
    let nIndex = -1;
    arrJobPosition.forEach((f) => {
      if (f.nSalaryEnd && f.nSalaryEnd < +f.nSalaryStart) {
        nIndex = f.nPositionId;
        isInvalid = true;
        return;
      }
    });

    if (isInvalid) {
      form.setFocus("ApplySalaryEnd" + nIndex);
      form.setError("ApplySalaryEnd" + nIndex, {
        type: "custom",
      });
      return false;
    }
    const dataProfile = {
      listPosition: arrJobPosition,
      nMode: 4,
    };
    dispatch(DialogActionCreators.OpenDialogSubmit(i18n(`${i18nField}.ConfirmMsgUpdate`), () => {
      dispatch(DialogActionCreators.CloseDialogSubmit() as any);
      dispatch(DialogActionCreators.LoadSubmit("Waiting...") as any);
      BlockUI();
      AxiosPost(`JobSeeker/UpdateProfile`, dataProfile, (result) => {
        UnBlockUI();
        let ProfileNoti = secureLocalStorage.getItem("ProfileNoti");
        secureLocalStorage.setItem("ProfileNoti", ProfileNoti === "true" ? "false" : "true");
        const res = result.Data;
        if (res.IsSuccess) {
          dispatch(
            DialogActionCreators.OpenDialogSuccess(
              i18n(`${i18nField}.MsgPanelJobApply`),
              () => {
                onGoPage();
              }
            ) as any
          );
        }
      },
        (err) => {
          if (!err.response) {
            history("/error");
            return;
          }
        }
      );
    }
    ) as any
    );
  }


  const JobPositionContextNew = useCallback(() => {
    return (
      <FormProvider {...form} >

        {(jobPositionContext || []).map((item: IMyJobPosition, index) => {
          return (
            <Box component={Paper} sx={{ border: "10px", p: 1, flex: 1, m: "2%" }} key={index}>
              {isMyJobPositonEdit && (
                <>
                  {index === 0 ? null : (

                    <IconButton
                      aria-label="delete"
                      color="error"
                      onClick={() => handleRemoveJobApply(item.Index)}
                    >
                      <CloseIcon />
                    </IconButton>
                  )}
                </>
              )}
              <Grid container justifyContent="center" alignItems="center" spacing={1}   >
                <Grid item container spacing={2} xs={12}>
                  <Grid item xs={12} sm={6} md={6}>
                    <Input
                      name={"ApplyPosition" + item.Index}
                      small
                      fullWidth
                      label={
                        <Typography sx={{ fontWeight: 400 }} component="label">
                          {i18n(`${i18nField}.ApplyPosition`)}
                        </Typography>
                      }
                      required
                      onBlur={(value) => {
                        handleChangeValue(form, item.Index);
                      }}
                      disabled={!isMyJobPositonEdit}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <AutoCompleteSelect
                      name={"ApplyJobFunction" + item.Index}
                      fullWidth
                      label={i18n(`${i18nField}.ApplyJobFunction`)}
                      options={optionJobFunction}
                      required
                      onChange={(value) => {
                        form.clearErrors("ApplyJobDetail");
                        form.setValue("ApplyJobDetail" + item.Index, 0);
                        setParent(value ? value.value : 0);
                        if (value) {
                          handleChangeValue(form, item.Index);
                        }
                      }}
                      disabled={!isMyJobPositonEdit}
                      freeSolo={false}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <AutoCompleteSelect
                      name={"ApplyJobDetail" + item.Index}
                      label={i18n(`${i18nField}.ApplyJobDetail`)}
                      options={optionJobDetail.filter((f) => f.sParent === item.nParent)}
                      required
                      disabled={!isMyJobPositonEdit}
                      onChange={(value) => {
                        if (value) { handleChangeValue(form, item.Index) }
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <AutoCompleteSelect
                      name={"ApplyIndustryType" + item.Index}
                      fullWidth
                      label={i18n(`${i18nField}.JobIndustry`)}
                      options={optionJobIndustry}
                      required
                      onChange={(value) => {
                        form.setValue("ApplyIndustryType" + item.Index, value);
                        handleChangeValue(form, item.Index);
                      }}
                      disabled={!isMyJobPositonEdit}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <AutoCompleteSelect
                      name={"ApplyJobType" + item.Index}
                      fullWidth
                      label={i18n(`${i18nField}.ApplyJobType`)}
                      options={optionJobType}
                      required
                      onChange={(value) => {
                        form.setValue("ApplyJobType" + item.Index, value);
                        handleChangeValue(form, item.Index);
                      }}
                      disabled={!isMyJobPositonEdit}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <InputNumber
                      name={"ApplySalaryStart" + item.Index}
                      small
                      fullWidth
                      label={i18n(`${i18nField}.ApplySalaryStart`)}
                      required
                      onBlur={(value) => {
                        handleChangeValue(form, item.Index);
                      }}
                      disabled={!isMyJobPositonEdit}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <InputNumber
                      name={"ApplySalaryEnd" + item.Index}
                      small
                      fullWidth
                      label={i18n(`${i18nField}.ApplySalaryEnd`)}
                      onBlur={(value) => {
                        if (value && value.target.value) {
                          if (
                            +value.target.value <
                            (form.watch("ApplySalaryStart" + item.Index) || 0)
                          ) {
                            setIsInvalidSalaryEnd(true);
                            form.setError("ApplySalaryEnd" + item.Index, {
                              type: "custom",
                            });
                          } else {
                            setIsInvalidSalaryEnd(false);
                            form.clearErrors("ApplySalaryEnd" + item.Index);
                          }
                        }
                        handleChangeValue(form, item.Index);
                      }}
                      disabled={!isMyJobPositonEdit}
                    />
                    {isInvalidSalaryEnd && (
                      <FormHelperText sx={{ color: "red" }}>
                        {i18n(`${i18nField}.MsgInvalidSalaryEnd`)}
                      </FormHelperText>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name={"checkNotSpeccified" + item.Index}
                          checked={item.IsNotSpeccified}
                          // checked={isSpeccified}
                          onChange={(e, value) => {
                            setIsSpeccified((prev) => !prev);
                            form.setValue("checkNotSpeccified" + item.Index, value);
                            handleChangeValue(form, item.Index);
                          }}
                          disabled={!isMyJobPositonEdit}
                        />
                      }
                      label={i18n(`${i18nField}.ApplySalarySpeccified`)}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          );
        })}
      </FormProvider>
    )
  }, [jobPositionContext]);
  return (
    <FormProvider {...form} >

      <AccordionCustom
        sx={{ border: "10px", p: 1, flex: 1 }}
        expanded={expandedJobApply}
        onChange={() => setExpandedJobApply((prev) => !prev)}
      // id={"4"} defaultValue={"4"}
      >
        <AccordionSummaryCustom>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item xs={6} sm={8} md={9}>
              <Typography >
                {i18n(`${i18nField}.PanelJobApply`)}<span style={{ color: "red" }}> *</span>
              </Typography>
            </Grid>
            <Grid item container
              justifyContent="flex-end" xs={6} sm={4} md={3}>
                   <CardComplete isComPlete ={isComPlete} />
            </Grid>
          </Grid>

        </AccordionSummaryCustom>
        <AccordionDetailsCustom>
          <Grid item sx={{ border: "10px", p: 1, flex: 1, m: "2%" }} >
            <Typography sx={{ flexShrink: 0, color: "#dc3636" }}>
              {i18n(`${i18nField}.Details`)}
            </Typography>
          </Grid>
          <JobPositionContextNew />
          {isMyJobPositonEdit && (
            <Stack
              sx={{ border: "10px", p: 1, flex: 1, m: "2%" }}
              direction="row"
              justifyContent="center"
            >
              <BtnRadiusProfile
                txt={i18n(`${i18nField}.AddWorkDetail`)}
                onClick={handleAddJobAppiy}
              />
            </Stack>
          )}

          {!isMyJobPositonEdit && (
            <Stack
              sx={{ border: "10px", p: 1, flex: 1, m: "2%" }}
              direction="row"
            >
              <BtnEditProfile
                txt={i18n(`${i18nField}.Edit`)}
                onClick={() => {
                  handleEditWorkUnit();
                }}

              />
            </Stack>
          )
          }

        </AccordionDetailsCustom>
      </AccordionCustom>
      {isMyJobPositonEdit && (
        <Stack
          spacing={1}
          sx={{ border: "10px", p: 1, flex: 1, mt: "2%" }}
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <BtnBack
            onClick={() => { onBack() }}
            txt={i18n(`${i18nCommon}.back`)}
          />
          <BtnSubmit
            onClick={form.handleSubmit(onSubmit, (err) => console.log("error", err))}
            txt={i18n(`${i18nCommon}.save`)}
          />
        </Stack>
      )}

    </FormProvider >
  );
};

export default MyJobPositonNew;