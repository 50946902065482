const SearchJobEn = {
  perpage: "{0} ",
  perpageLast: "{0} ",
  foundData: "Of {0} item(s)",
  criteria: {
    search: "Job title, Keyword, Company",
    jobFunction: "Job function",
    location: "Location",
    education: "Education",
    date: {
      0: "Post date",
      1: "Register date",
      2: "Interest date",
      3: "Invite date",
      4: "View date",
    },
    salaryStart: "Salary start",
    salaryEnd: "Salary end",
    employmentType: "Employment type",
    industryType: "Industry type",
    career: "Career",
    above : "More than",
    btnsearch: "Search"
  },
  btn: {
    fullSearch: "Full Search",
    shortSearch: "Short Search",
  },
};
const SearchJobTh = {
  perpage: "{0} ",
  perpageLast: "{0} ",
  foundData: "จาก {0} รายการ", //phase 2
  // foundData: "{0} รายการ",
  criteria: {
    search: "ชื่อตำแหน่ง, คำค้น, ชื่อบริษัท",
    jobFunction: "กลุ่มตำแหน่ง",
    location: "พื้นที่ปฎิบัติงาน ทั้งหมด",
    education: "ระดับการศึกษา",
    date: {
      0: "ช่วงเวลาประกาศงาน",
      1: "ช่วงเวลาที่สมัครงาน",
      2: "ช่วงเวลาที่สนใจ",
      3: "ช่วงเวลาที่ได้รับคำเชิญ",
      4: "ช่วงเวลาที่เข้าดูประวัติ",
   
    },
    salaryStart: "ช่วงเงินเดือนเริ่มต้น",
    salaryEnd: "ช่วงเงินเดือนสิ้นสุด",
    employmentType: "ลักษณะการจ้างงาน",
    industryType: "ประเภทอุตสาหกรรม",
    career: "ระดับปฏิบัติงาน",
    above : "มากกว่า",
    btnsearch: "ค้นหา"
  },
  btn: {
    fullSearch: "ตัวกรองทั้งหมด",
    shortSearch: "ตัวกรองบางส่วน",
  },
};

export { SearchJobEn, SearchJobTh };
