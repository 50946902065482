import {
  DataGridMui as Table,
  initRows,
  PaginationInterface,
  FilterFieldInterface,
} from "components/Common/Table/DataGridMui";
import { GridColumns } from "@mui/x-data-grid";
import { BtnViewOnTable } from "components/Common/Button";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  AxiosPost,
} from "service/CommonFunction/TS_function";
import { DialogActionCreators } from "store/redux/DialogAlert";
import { Grid, Tooltip } from "@mui/material";
import secureLocalStorage from 'react-secure-storage';

export default function AdminCompany() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [loadingTable, setLoadingTable] = useState(true);
  const i18nField = 'entities.Admincompany';


  let arrrow = {
    ...initRows,
    sSortExpression: "sUpdateDate",
    sSortDirection: "desc",
  }
  const sPathName = window.location.pathname;
  const sStore = secureLocalStorage.getItem(sPathName);
  if (sStore && sStore !== "") {
    const objRowTemp = JSON.parse(sStore + "");
    if (objRowTemp != null) {
      arrrow = objRowTemp;
      arrrow.arrRows = [];
    }
  }
  const [dataRow, setDataRow] = useState<PaginationInterface>(arrrow);
  // const [dataRow, setDataRow] = useState<PaginationInterface>({
  //   ...initRows,
  //   sSortExpression: "sUpdateDate",
  //   sSortDirection: "desc",
  // });

  const filter: FilterFieldInterface[] = [
    { sTypeFilterMode: "input", sFieldName: "sCompany_No" },
    { sTypeFilterMode: "input", sFieldName: "sCompany_Name" },
    { sTypeFilterMode: "input", sFieldName: "sIndustryType" },
    { sTypeFilterMode: "input", sFieldName: "sRegion" },
    { sTypeFilterMode: "input", sFieldName: "sProvAndDist" },
    { sTypeFilterMode: "daterange", sFieldName: "sUpdateDate" }
  ];

  useEffect(() => {
    //   GetPermission(1, setPermission);
    loadData(dataRow);
  }, []);

  const loadData = (p: PaginationInterface) => {
    setLoadingTable(true);
    const objFilter = { ...p };
    AxiosPost(
      "AdminCompany/search",
      objFilter,
      (result) => {
        setLoadingTable(false);

        setDataRow({
          ...p,
          arrRows: result.lstAdminCompany,
          nDataLength: result.nDataLength,
          nPageIndex: result.nPageIndex,
        });
      },
      (err) => {
        if (err.response && err.response.status === 401) {
          dispatch(
            DialogActionCreators.OpenDialogWarning("Token expired.", () => {
              navigate("/");
            }) as any
          );
        }
      }
    );
  };

  const onEdit = (sID: string, item: any) => {
    // window.localStorage.setItem("sSubRouteName", item.sSection);
    navigate(`/AdminCompanyInfo?sID=${encodeURI(sID)}`);
  };

  const dataColumn: GridColumns = [
    {
      field: "ปุ่ม (ดูรายละเอียด)",
      headerName: "",
      type: "actions",
      resizable: false,
      sortable: false,
      getActions: (item) => {
        return [
          <BtnViewOnTable
            txt="ดูรายละเอียด"
            onClick={() => onEdit(item.row.sID, item.row)}
          />,
        ];
      },
    },
    {
      field: "sCompany_No",
      headerName: "เลขที่ผู้ประกอบการ",
      headerAlign: "center",
      align: "left",
      resizable: false,
      sortable: true,
      flex: 1,
      minWidth: 170,
    },
    {
      field: "sCompany_Name",
      headerName: "ชื่อผู้ประกอบการ",
      headerAlign: "center",
      align: "left",
      resizable: false,
      sortable: true,
      flex: 1,
      minWidth: 300,
    },
    {
      field: "sIndustryType",
      headerName: "ประเภทอุตสาหกรรม",//industry type
      headerAlign: "center",
      align: "left",
      resizable: false,
      sortable: true,
      flex: 1,
      minWidth: 300,
      renderCell: (item) => {
        return [
          (
            <Tooltip placement="top" title={item.row.sIndustryType}>
              <div id={item.row.sID}
                style={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  width: '300px',
                }}>
                {item.row.sIndustryType}
              </div>

            </Tooltip>
          )
        ];
      }
    },
    {
      field: "sRegion",
      headerName: "ภูมิภาค/กรุงเทพมหานคร",//sRegion 
      headerAlign: "center",
      align: "left",
      resizable: false,
      sortable: true,
      flex: 1,
      minWidth: 250,
    },
    {
      field: "sProvAndDist",
      headerName: "จังหวัด/เขต",//Province and District
      headerAlign: "center",
      align: "left",
      resizable: false,
      sortable: true,
      flex: 1,
      minWidth: 200,
    },
    {
      field: "sUpdateDate",
      headerName: "วันที่ปรับปรุง",
      headerAlign: "center",
      align: "center",
      resizable: false,
      sortable: true,
      flex: 1,
      minWidth: 150,
    },
  ];

  return (

    <Grid container>
      <Grid item className="table" sx={{ mx: "2%", width: "100%" }}>
        <Table
          isLoading={loadingTable}
          filterField={filter}
          Rows={dataRow}
          Columns={dataColumn}
          OnLoadData={(p) => loadData(p)}
        />
      </Grid>
    </Grid>

  );
}