import { Grid, Typography} from "@mui/material"
import { i18n } from "i18n";
import "./ServicesBPS.css";
import { useState } from "react";
import CardServicesBPS from "./CardServicesBPS";
import coin1 from "assets/images/coin1.png"
import coin2 from "assets/images/coin2.png"
import coin3 from "assets/images/coin3.png"
import ParseHTML from "html-react-parser";
import ServiceTable from "./ServiceTable";
import MoldalConditions from "./MoldalConditions";

const CoinService = () => {
    const i18nField = "OrderEmployer";
    const [isShowPreview, setIsShowPreview] = useState(false);
    return (
        <>
            <Grid container  >
                <Grid item xs={12}>
                    <CardServicesBPS nTypeID={2} />
                </Grid>
            </Grid>
            <Grid container xs={12} style={{ marginTop: "5%" }} justifyContent={"center"}>
                <Grid item xs={4} >
                    <img
                        className={"zoom-image-banner"}
                        src={coin1}
                        alt=""
                        style={{
                            width: "50%",
                            marginLeft: "auto",
                            marginRight: "auto",
                            display: "block"
                        }}
                    />
                    <Grid item container justifyContent={"center"} xs={12}>
                        <Typography className="Banner-Icon">{ParseHTML(i18n(`${i18nField}.CoinIcon1`))}</Typography>
                    </Grid>
                </Grid>

                <Grid item xs={4} >
                    <img
                        className={"zoom-image-banner"}
                        src={coin2}
                        alt=""
                        style={{
                            width: "50%",
                            marginLeft: "auto",
                            marginRight: "auto",
                            display: "block"
                        }}
                    />

                    <Grid item container justifyContent={"center"} xs={12}>
                        <Typography className="Banner-Icon">{ParseHTML(i18n(`${i18nField}.CoinIcon2`))}</Typography>
                    </Grid>
                </Grid>
                <Grid item xs={4} >
                    <img
                        className={"zoom-image-banner"}
                        src={coin3}
                        alt=""
                        style={{
                            width: "50%",
                            marginLeft: "auto",
                            marginRight: "auto",
                            display: "block"
                        }}
                    />
                    <Grid item container justifyContent={"center"} xs={12}>
                        <Typography className="Banner-Icon" >{ParseHTML(i18n(`${i18nField}.CoinIcon3`))}</Typography>
                    </Grid>
                </Grid>

            </Grid>
            <Grid item xs={12} style={{ marginTop: "10%" }}>
                <ServiceTable nPackageTypeID={2} />
            </Grid>
            <MoldalConditions nID={5} isShowPreview={isShowPreview} setIsShowPreview={setIsShowPreview} />
        </>
    )

}
export default CoinService