const SettingSeekerTh = {
  Tile: "การเปิดเผยข้อมูล",
  UpdateOn: "ปรับปรุงเมื่อ",
  Time: "เวลา",

  Disclosure: "การเปิดเผยข้อมูล",
  DisclosureDetail:
    "ประวัติของคุณสามารถถูกสืบค้นจากบริษัทที่เป็นสมาชิกของเว็บไซต์ ซึ่งกำลังต้องการพนักงาน หรือผู้บริหารที่มีความสามารถเช่นคุณ",
  NonDisclosure: "ไม่เปิดเผยข้อมูล",
  NonDisclosureDetail:
    "คุณสามารถปกปิดประวัติของคุณเพื่อรักษาความเป็นส่วนตัวของคุณได้ ประวัติของคุณจะไม่ถูกซื้อ แต่สามารถถูกสืบค้นจากบริษัท รวมถึงรับคำเชิญได้",
    // "คุณสามารถปกปิดประวัติเพื่อรักษาความเป็นส่วนตัวของคุณได้ โดยประวัติของคุณจะไม่สามารถถูกสืบค้นจากบริษัทใดๆ การฝากประวัติของคุณมีไว้เพื่อใช้ในบริการส่งใบสมัครเท่านั้น",

  TitleChangeSecureCode: "เปลี่ยนรหัสผ่าน",
  SecureCodeOld: "รหัสผ่านเดิม",
  UserSecureCodeNow: "รหัสผ่านใหม่",
  ConfirmSecureCode: "ยืนยันรหัสผ่านใหม่",
  UserSecureCodeIncorrect: "ยืนยันรหัสผ่านไม่ถูกต้อง",

  TitleDeleteAcc: "ลบบัญชีผู้ใช้งาน",
  UsingAccount: "ยังคงใช้งานบัญชีผู้ใช้นี้",
  DeleteAccount:
    "ลบบัญชีผู้ใช้นี้ (เป็นการลบบัญชีถาวร ไม่สามารถกลับมาใช้งานได้อีก)",

  TitleChooseInformation:
    "การเลือกรับข่าวสารผ่านช่องทางอีเมลของเว็บไซต์ และบริษัทในเครือ",
  ChooseInformation: "รับข่าวสาร",
  NonChooseInformation: "ไม่รับข่าวสาร",

  TileChangeSecureCode: "เปลี่ยนรหัสผ่าน",
  UserSecureCodeChange: "เปลี่ยนรหัสผ่าน",
  NonChangeSecureCode: "ไม่เปลี่ยนรหัสผ่าน",
  msgSecureCodeDup: "รหัสผ่านเดิมไม่ถูกต้อง",

  TitleConsent: "ความยินยอมจากบิดา/มารดา หรือผู้ปกครอง",
  Underage: "ขณะทำรายการมีอายุไม่ต่ำกว่า 20 ปี",
  NotUnderage:
    "ขณะทำรายการมีอายุต่ำกว่า 20 ปี หรือได้รับความยินยอมจากผู้ปกครอง",
};
const SettingSeekerEn = {
  Tile: "Disclosure",
  UpdateOn: "Update On",
  Time: "Time",

  Disclosure: "Disclosure",
  DisclosureDetail:
    "Your profile can be retrieved from companies that are members of the website. which is in need of staff or a competent executive like you",
  NonDisclosure: "Non-disclosure",
  NonDisclosureDetail:
    "You can mask your history to maintain your privacy. Your profile cannot be searched by any company. The deposit of your CV is for use in the application submission service only.",

  TitleChangeSecureCode: "Change Password",
  SecureCodeOld: "Old Password",
  UserSecureCodeNow: "New Password",
  ConfirmSecureCode: "Confirm Password",
  UserSecureCodeIncorrect: "Invalid Confirm Password",

  TitleDeleteAcc: "Delete User Account",
  UsingAccount: "Still Using This Account",
  DeleteAccount:
    "Delete This Account (This is a permanent account deletion. cannot be reactivated.)",

  TitleChooseInformation:
    "Opt-in to receive news via the website's email channels and affiliates / Subscription ",
  ChooseInformation: "Receive News",
  NonChooseInformation: "Do Not Receive News",

  TileChangeSecureCode: "ChangePassword",
  UserSecureCodeChange: "ChangePassword",
  NonChangeSecureCode: "Non - ChangePassword",
  msgSecureCodeDup: "The old password was invalid",

  TitleConsent: "Father/Mother's consent or parents",
  Underage: "At the time of the transaction, the age is not less than 20 years",
  NotUnderage:
    "At the time of the transaction, you are under 20 years old or have your parent's consent.",
};
export { SettingSeekerEn, SettingSeekerTh };
