import { Dialog, Grid, IconButton, Toolbar } from "@mui/material"
import ContentPage from "../Home/ContentPage"
import { useEffect, useState } from "react";
import { objContent } from "../SetupTemplate/ManageContent";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import { AxiosPost } from "service/CommonFunction/TS_function";
const MoldalConditions = ({ nID, isShowPreview, setIsShowPreview }) => {
    const [lstContent, setLstPreviewContent] = useState<objContent[]>([]);
    const [sContendHead, setContentHead] = useState<objContent[]>([]);
    const navigate = useNavigate();
    const onOpenCondition = () => {

        let param = {
            nID: nID,
        };

        let lstPreviewContent = []
        AxiosPost(`HomeFront/getData_ContentDetail`, param, (res) => {
            res.contentShow.forEach((f, i) => {
                const nType = f.nType
                let sContent = f.sContent
                let fileContent = f.sFilePath;
                let objViewContent = {
                    nType: nType,
                    sContent: sContent,
                    sFileNameSys: fileContent
                }
                lstPreviewContent.push(objViewContent)
            })
            setContentHead(res.contentHead);
            setLstPreviewContent(lstPreviewContent)
        },
            (err) => {
                if (!err.response) {
                    navigate("/error");
                    return;
                }
            }
        );
    }

    useEffect(() => {

        onOpenCondition();
    }, [])
    return (
        <Dialog
            fullScreen
            open={isShowPreview}
            onClose={() => setIsShowPreview(false)}
        >
            <Toolbar sx={{ position: "relative", marginBottom: "1rem" }}>
                <IconButton
                    edge="end"
                    color="inherit"
                    onClick={() => setIsShowPreview(false)}
                    aria-label="close"
                >
                    <CloseIcon />
                </IconButton>
            </Toolbar>
            <Grid container>
                <Grid item xs={12}>
                    <ContentPage lstContent={lstContent} OrderEmp={true} sContendHead={sContendHead} />
                </Grid>
            </Grid>
        </Dialog>
    )
}
export default MoldalConditions