import React, { useEffect, useState } from "react";
import { Button, Divider, Grid, Skeleton, Stack, Typography, Box } from "@mui/material";
import { i18n, getLanguage } from "i18n";
import PrintIcon from "@mui/icons-material/Print";

import { AxiosPostFilePDF, AxiosGet, AxiosPost, GetPermission } from "service/CommonFunction/TS_function";

import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { DialogActionCreators } from "store/redux/DialogAlert";
import Link from "@mui/material/Link";
import "./styles.css";
import ImageIcon from '@mui/icons-material/Image';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { BtnBack } from "components/Common/Button"
import moment from "moment";
import secureLocalStorage from "react-secure-storage";

import {
  FaFileWord,
  FaFilePowerpoint,
  FaFilePdf,
  FaFileImage,
} from "react-icons/fa6";

const Profile = (props) => {
  const { isAdmin } = props;
  const i18nField = "entities.Candidate";
  const history = useNavigate();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = location.search;
  const sID = new URLSearchParams(searchParams).get("sID");
  const [nPermission, setPermission] = useState(0);
  const [dataInfo, setDataInfo] = useState<{ [key: string]: any }>({});
  const [nCountryID, setCountry] = useState(0);
  const [dataWorkExp, setDataWorkExp] = useState([]);

  const [dataEducational, setDataEducational] = useState([]);
  const [dataLanguageProficiency, setDataLanguageProficiency] = useState([]);
  const [dataAbility, setDataAbility] = useState([]);
  const [dataCertificate, setDataCertificate] = useState([])
  const [dataJobSeekerPosition, setDataJobSeekerPosition] = useState([]);
  const [dataLicense, setDataLicense] = useState([]);
  const [dataJobSeekerFile, setDataJobSeekerFile] = useState([]);

  const [fileResume, setFileResume] = useState([]);
  const [fileOther, setFileOther] = useState([]);

  const [isLoadProfile, setLoadProfile] = useState(false);
  const [dataComputerSkill, setDataComputerSkill] = useState([])
  const [dataKeyword, setDataKeyword] = useState([]);
  const isEmployer = window.location.pathname.includes("/EmployerSearchViewJobseeker");
  const langeCode = isEmployer ? (secureLocalStorage.getItem("language").toString() || "EN").toLocaleUpperCase() : "TH"



  const ExportPDF = () => {

    let fileName = dataInfo.sFullNameTH.replace(" ", "_")

    AxiosPostFilePDF(fileName, "AdminCandidate/GetReportCandidateProfile", { sID: sID, sLang: langeCode }, (result) => {

      const blob = new Blob([result], {
        type: "application/pdf",
      },);

      const link = document.createElement("a");
      link.target = "_blank";
      const objectUrl = URL.createObjectURL(blob);
      window.open(objectUrl, "_blank");

      // link.href = (objectUrl);
      // link.setAttribute("visibility", "hidden");
      // // link.setAttribute("download", fileName);
      // link.click();
      // link.remove();

      // window.open(objectUrl, "_blank");


      // const link = document.createElement("a");
      // link.download = fileName;

      // link.target = "_blank";
      // var objectUrl = URL.createObjectURL(blob);
      // // link.href = (objectUrl);
      // window.open(objectUrl,"test")
      // // link.setAttribute("visibility", "hidden");
      // // link.click();
      // // link.remove();
    },
      (err) => {
        if (err.Message != "") {
          dispatch(
            DialogActionCreators.OpenDialogWarning(
              err.Message
            ) as any
          );
        }
      }
    );
  };

  const forceDownload = (blob, filename) => {

    var a = document.createElement('a');
    // a.download = filename;
    a.setAttribute("visibility", filename);
    a.href = blob;
    // For Firefox https://stackoverflow.com/a/32226068
    document.body.appendChild(a);
    a.click();
    a.remove();
  };
  const onBack = () => {
    navigate("/AdminCandidateTable");
  };
  const onGetDetail = () => {
    setLoadProfile(true)
    AxiosGet("AdminCandidate/GetInfo", { sID: sID }, (res) => {
      ////console.log("AdminCandidate/GetInfo", res);

      setDataInfo(res.JobSeekerInfo);
      setDataWorkExp(res.lstWorkExperience || []);
      setDataEducational(res.lstEducational || []);
      setDataLanguageProficiency(res.lstLanguageProficiency || []);
      setDataAbility(res.lstAbility || []);
      setDataJobSeekerPosition(res.lstJobSeekerPosition || []);

      ///setDataJobSeekerFile(res.lstJobSeekerFile || []);
      if (res.lstJobSeekerFile) {
        const lstFileResume = [];
        const lstFileOther = [];
        res.lstJobSeekerFile.map((item) => {
          if (item.nFileTypeID === 2) {
            ///console.log("item", item);
            lstFileResume.push(item);
          }
          else if (item.nFileTypeID === 3) {
            lstFileOther.push(item);
          }
        })

        setFileResume(lstFileResume);
        setFileOther(lstFileOther);
      }

      setDataLicense(res.lstLicense || []);
      setDataKeyword(res.lstTag || [])
      setDataCertificate(res.lstCertificate || [])
      setDataComputerSkill(res.lstComputerSkill || [])
    }, (err) => dispatch(DialogActionCreators.OpenDialogWarning(err.Message)), () => setLoadProfile(false));
  };

  const handleCalAge = (sBirthDate) => {
    const age = moment().diff(sBirthDate, 'years', false);
    return age + " " + i18n(`${i18nField}.AgeYear`);
  }
  const getYear = (sDate) => {
    if (langeCode == "TH") {
      return (moment(sDate).year() + 543).toString();
    } else {
      return (moment(sDate).year()).toString();
    }
  }

  useEffect(() => {
    //setLang(window.localStorage.getItem("language"));
    onGetDetail();
    GetPermission(1, setPermission);
  }, []);

  return (
    <Grid container>
      <Grid item xs={12} className="table" sx={{ mx: "2%", width: "100%" }}>
        <Grid container
          ///justifyContent="center"
          sx={{
            marginTop: "2%",
            border: "2px #eeeeee solid",
            backgroundColor: "rgba(255,255,255,0.75)",
            borderRadius: "20px",
            padding: "24px",
            width: "100%",
            rowGap: "1rem",
            flexDirection: "column"
          }}
          width={"100%"}

        >
          {isLoadProfile ? (
            <>
              <Stack direction={"row"} justifyContent="space-between">
                <Skeleton height={50} width={140} />
                <Skeleton height={50} width={100} />
              </Stack>
              <Stack direction={"row"} justifyContent="space-between" sx={{ mt: "5px !important" }}>
                <Skeleton height={50} width={200} />
                <Skeleton height={50} width={100} />
              </Stack>
            </>
          ) : (
            <>
              <Grid container
                direction="row"
                justifyContent="flex-end"
                alignItems="flex-start" >
                <Button
                  onClick={() => ExportPDF()}
                  variant="outlined"
                  startIcon={<PrintIcon />}
                  sx={{ borderRadius: "20px" }}
                  disabled={nPermission !== 2 && !isEmployer}
                >
                  {i18n(`${i18nField}.Print`)}
                </Button>
              </Grid>
              <Stack direction={"row"}  >
                <Divider sx={{ width: "100%", backgroundColor: "#03a9f4" }} />
              </Stack>

              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start"
              >

                <Typography color={"#03a9f4"} fontWeight={500} fontSize={18}>
                  {i18n(`${i18nField}.IndividualData`)}
                </Typography>

                <Typography fontSize={12} variant="subtitle1">
                  {i18n(`${i18nField}.Update`)}
                  :  {dataInfo.sUpdate_Date}{" "}
                  {i18n(`${i18nField}.Time`)}  {dataInfo.sUpdate_Time}
                </Typography>
              </Grid>


              {/* ประวัติส่วนตัว */}
              <Grid container sx={{ flexDirection: { sm: "row", xs: "column-reverse" } }}>
                <Grid item md={10} sm={9} xs={12}>

                  <Grid container direction={"row"} sx={{ mt: "5px !important", columnGap: "1rem" }}>
                    <Grid item md={3} sm={3} xs={5} sx={{ marginLeft: "20px" }}>
                      <Typography fontWeight={400} variant="subtitle2">
                        {i18n(`${i18nField}.Name`)}
                      </Typography>
                    </Grid>

                    <Grid item md={5} sm={6} xs={5}>
                      <Typography fontWeight={400} variant="subtitle2">
                        {dataInfo ?
                          <>
                            {`${dataInfo["sFName_" + langeCode]} ${dataInfo["sLName_" + langeCode]}`}
                          </>
                          :
                          <></>
                        }

                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid container sx={{ mt: "5px !important", columnGap: "1rem" }}>
                    <Grid item md={3} sm={3} xs={5} sx={{ marginLeft: "20px" }}>
                      <Typography fontWeight={400} variant="subtitle2">
                        {i18n(`${i18nField}.Sex`)}
                      </Typography>
                    </Grid>

                    <Grid item md={5} sm={6} xs={5}>
                      <Typography fontWeight={400} variant="subtitle2">
                        {dataInfo["sSex_" + langeCode]}
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid container sx={{ mt: "5px !important", columnGap: "1rem" }}>
                    <Grid item md={3} sm={3} xs={5} sx={{ marginLeft: "20px" }}>
                      <Typography fontWeight={400} variant="subtitle2">
                        {i18n(`${i18nField}.Status`)}
                      </Typography>
                    </Grid>

                    <Grid item md={5} sm={6} xs={5}>
                      <Typography fontWeight={400} variant="subtitle2">
                        {dataInfo["sStatus_" + langeCode]}
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid container sx={{ mt: "5px !important", columnGap: "1rem" }}>
                    <Grid item md={3} sm={3} xs={5} sx={{ marginLeft: "20px" }}>
                      <Typography fontWeight={400} variant="subtitle2">
                        {i18n(`${i18nField}.Age`)}
                      </Typography>
                    </Grid>

                    <Grid item md={5} sm={6} xs={5}>
                      <Typography fontWeight={400} variant="subtitle2">
                        {
                          dataInfo.dBirthDate ?
                            <>
                              {
                                `${handleCalAge(dataInfo.dBirthDate)} 
                                  (${moment(dataInfo.dBirthDate).format("DD/MM/YYYY")})`
                              }
                            </>
                            :
                            "-"
                        }
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid container sx={{ mt: "5px !important", columnGap: "1rem" }}>
                    <Grid item md={3} sm={3} xs={5} sx={{ marginLeft: "20px" }}>
                      <Typography fontWeight={400} variant="subtitle2">
                        {i18n(`${i18nField}.Country`)}
                      </Typography>
                    </Grid>

                    <Grid item md={5} sm={6} xs={5}>
                      <Typography fontWeight={400} variant="subtitle2">
                        {dataInfo.sCountry_Name_TH === "" || dataInfo.sCountry_Name_EN === "" ?
                          "-"
                          :
                          <>{`${dataInfo["sCountry_Name_" + langeCode]}`}</>
                        }
                      </Typography>
                    </Grid>
                  </Grid>

                  {dataInfo.nCountryID === 519 ? (
                    <Grid container sx={{ mt: "5px !important", columnGap: "1rem" }}>
                      <Grid item md={3} sm={3} xs={5} sx={{ marginLeft: "20px" }}>
                        <Typography fontWeight={400} variant="subtitle2">
                          {i18n(`${i18nField}.Region`)}
                        </Typography>
                      </Grid>

                      <Grid item md={5} sm={6} xs={5}>
                        <Typography fontWeight={400} variant="subtitle2">{`${dataInfo["sRegion_Name_" + langeCode]}`}</Typography>
                      </Grid>
                    </Grid>
                  ) : <></>}

                  {dataInfo.nCountryID === 519 ? (
                    <Grid container sx={{ mt: "5px !important", columnGap: "1rem" }}>
                      <Grid item md={3} sm={3} xs={5} sx={{ marginLeft: "20px" }}>
                        <Typography fontWeight={400} variant="subtitle2">
                          {i18n(`${i18nField}.Province`)}
                        </Typography>
                      </Grid>

                      <Grid item md={5} sm={6} xs={5}>
                        <Typography fontWeight={400} variant="subtitle2">
                          {`${dataInfo["sProvince_Name_" + langeCode]}`}
                        </Typography>
                      </Grid>
                    </Grid>
                  ) : <></>}

                </Grid>

                {(dataInfo.sUrl) && (
                  <Grid item md={2} sm={3} xs={12} sx={{ textAlign: "-webkit-center" }}>
                    <img src={dataInfo.sUrl} alt=""
                      style={{
                        width: "120px !important",
                        height: "140px !important",
                        objectFit: "cover",
                      }}
                    // style={{
                    //   width: "10vw", //10vw or 151px
                    //   height: "13vw", //13vw or 227px
                    //   objectFit: "cover",
                    // }}
                    />
                  </Grid>
                )}

              </Grid>


              <Stack direction={"row"}  >
                <Divider sx={{ width: "100%", backgroundColor: "#03a9f4" }} />
              </Stack>

              {/* ข้อมูลสำหรับติดต่อ */}
              <Stack direction={"row"} alignItems="end" justifyContent="space-between">
                <Typography color={"#03a9f4"} fontWeight={500} fontSize={18}>
                  {i18n(`${i18nField}.Data`)}
                </Typography>
              </Stack>
              <Grid container item xs={12}>

                <Grid container sx={{ mt: "5px !important", columnGap: "1rem" }}>
                  <Grid item md={2.5} sm={3} xs={4.5} sx={{ marginLeft: "20px" }}>
                    <Typography fontWeight={400} variant="subtitle2">
                      {i18n(`${i18nField}.Mobile`)}
                    </Typography>
                  </Grid>

                  <Grid item md={7} sm={7} xs={5.5}>
                    <Typography fontWeight={400} variant="subtitle2">
                      {dataInfo.sTel}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid container sx={{ mt: "5px !important", columnGap: "1rem" }}>
                  <Grid item md={2.5} sm={3} xs={4.5} sx={{ marginLeft: "20px" }}>
                    <Typography fontWeight={400} variant="subtitle2">
                      {i18n(`${i18nField}.Email`)}
                    </Typography>
                  </Grid>

                  <Grid item md={7} sm={7} xs={5.5}>
                    <Typography fontWeight={400} variant="subtitle2">{dataInfo.sEmail}</Typography>
                  </Grid>
                </Grid>

                <Grid container sx={{ mt: "5px !important", columnGap: "1rem" }}>
                  <Grid item md={2.5} sm={3} xs={4.5} sx={{ marginLeft: "20px" }}>
                    <Typography fontWeight={400} variant="subtitle2">
                      {i18n(`${i18nField}.ContactOther`)}
                    </Typography>
                  </Grid>

                  <Grid item md={7} sm={7} xs={5.5}>
                    <Typography fontWeight={400} variant="subtitle2">
                      {dataInfo.sOtherContact === "" ? "-" : dataInfo.sOtherContact}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>


              <Stack direction={"row"}  >
                <Divider sx={{ width: "100%", backgroundColor: "#03a9f4" }} />
              </Stack>

              {/* ประสบการณ์ */}
              <Stack direction={"row"} alignItems="end" justifyContent="space-between">
                <Typography color={"#03a9f4"} fontWeight={500} fontSize={18}>
                  {i18n(`${i18nField}.WorkExperience`)}
                </Typography>
              </Stack>

              {dataWorkExp.map((item, index) => {
                let html =
                  <Grid container sx={{ mt: "5px !important", gap: "1rem" }}>

                    <Grid item lg={2.5} md={3.5} sm={12} xs={12} sx={{ marginLeft: { md: "20px", xs: "0px" } }}>
                      <Typography fontSize={16} fontWeight={500}>
                        {item.IsWorkCurrent ?
                          `${moment(item.dStart_Date).format("MMMM YYYY")} - ${i18n(`${i18nField}.Present`)}`
                          :
                          `${moment(item.dStart_Date).format("MMMM YYYY")} - ${moment(item.dEnd_Date).format("MMMM YYYY")}`}
                      </Typography>
                    </Grid>

                    <Grid container item lg={8} md={7} sm={12} xs={12} sx={{ flexDirection: "column", rowGap: "4px" }} >
                      <Box sx={{ mt: "0px !important" }}>
                        <Typography fontSize={16} fontWeight={500}>
                          {item.sPosition_Name}
                        </Typography>
                      </Box>

                      <Box sx={{ mt: "0px !important" }}>
                        <Typography fontSize={16} fontWeight={500}>
                          {item.sCompany_Name}
                        </Typography>
                      </Box>

                      <Grid container sx={{ columnGap: "1rem", marginTop: "0px !important" }}>
                        <Grid item lg={3} md={4} sm={6} xs={5.5}>
                          <Typography fontWeight={400} variant="subtitle2">
                            {i18n(`${i18nField}.Industry`)}
                          </Typography>
                        </Grid>

                        <Grid item lg={6} md={5.5} sm={5} xs={5.5} sx={{ mt: "0px !important" }} >
                          <Typography fontWeight={400} variant="subtitle2">
                            {`${item["sIndustryType_Name_" + langeCode]}`}
                          </Typography>
                        </Grid>
                      </Grid>

                      <Grid container sx={{ columnGap: "1rem", marginTop: "0px !important" }}>
                        <Grid item lg={3} md={4} sm={6} xs={5.5}>
                          <Typography fontWeight={400} variant="subtitle2">
                            {i18n(`${i18nField}.JobFunction`)}
                          </Typography>
                        </Grid>

                        <Grid item lg={6} md={5.5} sm={5} xs={5.5} sx={{ mt: "0px !important" }} >
                          <Typography fontWeight={400} variant="subtitle2">
                            {`${item["sJobFunction_Name_" + langeCode]}`}
                          </Typography>
                        </Grid>
                      </Grid>

                      <Grid container sx={{ columnGap: "1rem", marginTop: "0px !important" }}>
                        <Grid item lg={3} md={4} sm={6} xs={5.5}>
                          <Typography fontWeight={400} variant="subtitle2">
                            {i18n(`${i18nField}.JobFunctionDetail`)}
                          </Typography>
                        </Grid>
                        <Grid item lg={6} md={5.5} xs={5.5} sx={{ mt: "0px !important" }} >
                          <Typography fontWeight={400} variant="subtitle2">
                            {`${item["sJobDetail_Name_" + langeCode]}`}
                          </Typography>
                        </Grid>
                      </Grid>

                      <Grid container sx={{ marginTop: "0px !important" }}>
                        <Grid item xs={12}>
                          <Typography fontWeight={400} variant="subtitle2">
                            {i18n(`${i18nField}.JobDescription`)}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ mt: "0px !important", gap: "5px", whiteSpace: "pre-wrap", wordBreak: "break-all", maxWidth: "100%", overflow: "auto" }} >
                          <Typography sx={{ maxWidth: "100%" }} fontWeight={400} variant="subtitle2">
                            {item.sRemark}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>

                  </Grid>
                return (html)
              })}


              <Stack direction={"row"}  >
                <Divider sx={{ width: "100%", backgroundColor: "#03a9f4" }} />
              </Stack>

              {/* การศึกษา */}
              <Stack direction={"row"} alignItems="end" justifyContent="space-between">
                <Typography color={"#03a9f4"} fontWeight={500} fontSize={18}>
                  {i18n(`${i18nField}.Education`)}
                </Typography>
              </Stack>

              {dataEducational.map((item, index) => {
                let html =
                  <Grid container sx={{ mt: "5px !important", gap: "1rem" }}>
                    <Grid item lg={2.5} md={3.5} sm={12} xs={12} sx={{ marginLeft: { md: "20px", xs: "0px" } }}>
                      <Typography fontWeight={500} fontSize={16}>
                        {`${item.nEducational_Year}`}
                      </Typography>
                    </Grid>

                    <Grid container item lg={8} md={7} sm={12} xs={12} sx={{ flexDirection: "column", rowGap: "4px" }} >
                      <Box sx={{ mt: "0px !important" }}>
                        <Typography fontWeight={500} fontSize={16}>
                          {item["sUniversity_Name_" + langeCode]}
                        </Typography>
                      </Box>

                      <Box sx={{ mt: "0px !important" }}>
                        <Typography fontWeight={500} fontSize={16}>
                          {`${item["sEducationalType_Name_" + langeCode]}`}
                        </Typography>
                      </Box>

                      <Grid container sx={{ columnGap: "1rem", marginTop: "0px !important" }}>
                        <Grid item lg={3} md={4} sm={6} xs={5.5}>
                          <Typography fontWeight={400} variant="subtitle2">
                            {i18n(`${i18nField}.Major`)}
                          </Typography>
                        </Grid>

                        <Grid item lg={6} md={5.5} sm={5} xs={5.5} sx={{ mt: "0px !important" }}>
                          <Typography fontWeight={400} variant="subtitle2">
                            {`${item.sEducational_Branch}`}
                          </Typography>
                        </Grid>
                      </Grid>

                      <Grid container sx={{ columnGap: "1rem", marginTop: "0px !important" }}>
                        <Grid item xs={12}>
                          <Typography fontWeight={400} variant="subtitle2">
                            {i18n(`${i18nField}.sCourse`)}
                          </Typography>
                        </Grid>

                        <Grid item xs={12} sx={{ mt: "0px !important", gap: "5px", whiteSpace: "pre-wrap", wordBreak: "break-all", maxWidth: "100%", overflow: "auto" }} >
                          <Typography fontWeight={400} sx={{ maxWidth: "100%" }} variant="subtitle2">
                            {item.sRemark === "" ? "-" : item.sRemark}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                return (html)
              })}

              <Stack direction={"row"}  >
                <Divider sx={{ width: "100%", backgroundColor: "#03a9f4" }} />
              </Stack>

              {/* ใบอนุญาต หรือประกาศนียบัตร */}
              <Stack direction={"row"} alignItems="end" justifyContent="space-between">
                <Typography color={"#03a9f4"} fontWeight={500} fontSize={18}>
                  {i18n(`${i18nField}.Certificate`)}
                </Typography>
              </Stack>

              {dataCertificate.map((item, index) => {
                let html =
                  <Grid container sx={{ mt: "5px !important", gap: "1rem" }}>
                    <Grid item lg={2.5} md={3.5} sm={12} xs={12} sx={{ marginLeft: { md: "20px", xs: "0px" } }}>
                      <Typography fontWeight={500} fontSize={16}>
                        {moment(item.dCreateDate).format("YYYY")}
                      </Typography>
                    </Grid>

                    <Grid container item lg={8} md={7} sm={12} xs={12} sx={{ rowGap: "4px" }}>
                      <Grid item xs={12} sx={{ mt: "0px !important" }}>
                        <Typography fontWeight={400} variant="subtitle2">
                          {item.sCertificate_Name === "" ? "-" : item.sCertificate_Name}
                        </Typography>
                      </Grid>

                      <Grid item xs={12} sx={{ mt: "0px !important" }}>
                        <Typography fontWeight={400} variant="subtitle2">
                          {item.sInstitution_Name}
                        </Typography>
                      </Grid>

                      <Grid item xs={12} sx={{ mt: "0px !important", gap: "5px", whiteSpace: "pre-wrap", wordBreak: "break-all", maxWidth: "100%", overflow: "auto" }} >
                        <Typography fontWeight={400} variant="subtitle2">
                          {item.sRemark}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                return (html)
              })}

              <Stack direction={"row"}  >
                <Divider sx={{ width: "100%", backgroundColor: "#03a9f4" }} />
              </Stack>

              {/* ทักษะทางคอมพิวเตอร์ */}
              <Stack direction={"row"} alignItems="end" justifyContent="space-between">
                <Typography color={"#03a9f4"} fontWeight={500} fontSize={18}>
                  {i18n(`${i18nField}.ComputerSkill`)}
                </Typography>
              </Stack>

              <Grid container>
                {dataComputerSkill.map((item, index) => {
                  const html =
                    <Grid container sx={{ columnGap: "1rem" }}>
                      <Grid item md={2.5} sm={3} xs={4.5} sx={{ marginLeft: { md: "20px", xs: "0px" } }}>
                        <Typography fontWeight={500} fontSize={16}>
                          {item.sSkillName}
                        </Typography>
                      </Grid>

                      <Grid item md={7} sm={7} xs={5.5}>
                        <Typography fontWeight={400} variant="subtitle2">
                          {item["sProficiency_" + langeCode]}
                        </Typography>
                      </Grid>
                    </Grid>
                  return (html)
                })}
              </Grid>


              <Stack direction={"row"}  >
                <Divider sx={{ width: "100%", backgroundColor: "#03a9f4" }} />
              </Stack>

              {/* ความสามารถอื่นๆ */}
              <Stack direction={"row"} alignItems="end" justifyContent="space-between">
                <Typography color={"#03a9f4"} fontWeight={500} fontSize={18}>
                  {i18n(`${i18nField}.OtherAbility`)}
                </Typography>
              </Stack>

              <Grid container item xs={12} sx={{ flexDirection: "row", gap: "1rem !important", mt: "5px !important" }}>
                {dataAbility.map((item, index) => {
                  const html =
                    <Box className="border  border-[#1976d2] rounded-[1em] min-w-[55px] px-[5px] py-[1] border-w-[2px] flex justify-center items-center ">
                      <Typography fontWeight={400} variant="subtitle2">
                        {item.sAbility_Name}
                      </Typography>
                    </Box>
                  return (html);
                })}
              </Grid>

              <Stack direction={"row"}  >
                <Divider sx={{ width: "100%", backgroundColor: "#03a9f4" }} />
              </Stack>


              {/* ความสามารถทางภาษา */}
              <Stack direction={"row"} alignItems="end" justifyContent="space-between">
                <Typography color={"#03a9f4"} fontWeight={500} fontSize={18}>
                  {i18n(`${i18nField}.LangugeSkill`)}
                </Typography>
              </Stack>

              {dataLanguageProficiency.map((item, index) => {
                let html =
                  <Grid container sx={{ mt: "5px !important", columnGap: "1rem" }}>
                    <Grid item lg={2.5} md={3.5} sm={12} xs={12} sx={{ marginLeft: { md: "20px", xs: "0px" } }}>
                      <Typography fontWeight={500} fontSize={16}>
                        {`${item["sLanguage_Name_" + langeCode]}`}
                      </Typography>
                    </Grid>

                    <Grid container item lg={8} md={7} sm={12} xs={12} sx={{ flexDirection: "column" }} >

                      <Grid container sx={{ columnGap: "1rem", marginTop: "0px !important" }}>
                        <Grid item lg={3} md={4} sm={6} xs={5.5}>
                          <Typography fontWeight={400} variant="subtitle2">
                            {i18n(`${i18nField}.SpeakingSkill`)}
                          </Typography>
                        </Grid>

                        <Grid item lg={6} md={5.5} sm={5} xs={5.5} sx={{ mt: "0px !important" }}>
                          <Typography fontWeight={400} variant="subtitle2">
                            {`${item["sSpeeking_Name_" + langeCode]}`}
                          </Typography>
                        </Grid>
                      </Grid>

                      <Grid container sx={{ columnGap: "1rem", marginTop: "0px !important" }}>
                        <Grid item lg={3} md={4} sm={6} xs={5.5}>
                          <Typography fontWeight={400} variant="subtitle2">
                            {i18n(`${i18nField}.ReadingSkill`)}
                          </Typography>
                        </Grid>

                        <Grid item lg={6} md={5.5} sm={5} xs={5.5} sx={{ mt: "0px !important" }}>
                          <Typography fontWeight={400} variant="subtitle2">
                            {`${item["sReading_Name_" + langeCode]}`}
                          </Typography>
                        </Grid>
                      </Grid>

                      <Grid container sx={{ columnGap: "1rem", marginTop: "0px !important" }}>
                        <Grid item lg={3} md={4} sm={6} xs={5.5}>
                          <Typography fontWeight={400} variant="subtitle2">
                            {i18n(`${i18nField}.WritingSkill`)}
                          </Typography>
                        </Grid>

                        <Grid item lg={6} md={5.5} sm={5} xs={5.5} sx={{ mt: "0px !important" }}>
                          <Typography fontWeight={400} variant="subtitle2">
                            {`${item["sWriting_Name_" + langeCode]}`}
                          </Typography>
                        </Grid>
                      </Grid>

                    </Grid>

                  </Grid>
                return (html);
              })}


              <Stack direction={"row"}  >
                <Divider sx={{ width: "100%", backgroundColor: "#03a9f4" }} />
              </Stack>


              {/* ตำแหน่งงานที่ต้องการ */}
              <Stack direction={"row"} alignItems="end" justifyContent="space-between">
                <Typography color={"#03a9f4"} fontWeight={500} fontSize={18}>
                  {i18n(`${i18nField}.JobPosition`)}
                </Typography>
              </Stack>

              {dataJobSeekerPosition.map((item, index) => {
                const html =
                  <Grid container sx={{ mt: "5px !important", columnGap: "1rem" }}>
                    <Grid item lg={2.5} md={3.5} sm={12} xs={12} sx={{ marginLeft: { md: "20px", xs: "0px" } }}>
                      <Typography fontWeight={500} fontSize={16}>
                        {item.sPosition_Name}
                      </Typography>
                    </Grid>

                    <Grid container item lg={8} md={7} sm={12} xs={12} sx={{ flexDirection: "column" }}>

                      <Box sx={{ mt: "0px !important" }}>
                        <Typography fontWeight={500} fontSize={16}>
                          {item["sPositionType_Name_" + langeCode]}
                        </Typography>
                      </Box>

                      <Box sx={{ mt: "0px !important" }}>
                        <Typography fontWeight={500} fontSize={16}>
                          {item["sWorkType_Name_" + langeCode]}
                        </Typography>
                      </Box>

                      <Grid container sx={{ columnGap: "1rem", marginTop: "0px !important" }}>
                        <Grid item lg={3} md={4} sm={6} xs={5.5}>
                          <Typography fontWeight={400} variant="subtitle2">
                            {i18n(`${i18nField}.EmployerType`)}
                          </Typography>
                        </Grid>

                        <Grid item lg={6} md={5.5} sm={5} xs={5.5} sx={{ mt: "0px !important" }}>
                          {langeCode === "EN" ?
                            <Typography fontWeight={400} variant="subtitle2"> {`${item.sEmploymentType_Name_EN}`}</Typography>
                            :
                            <Typography fontWeight={400} variant="subtitle2"> {`${item.sEmploymentType_Name_TH}`}</Typography>
                          }
                        </Grid>
                      </Grid>

                      <Grid container sx={{ columnGap: "1rem", marginTop: "0px !important" }}>
                        <Grid item lg={3} md={4} sm={6} xs={5.5}>
                          <Typography fontWeight={400} variant="subtitle2">
                            {i18n(`${i18nField}.ExpectSalary`)}
                          </Typography>
                        </Grid>

                        <Grid item lg={6} md={5.5} sm={5} xs={5.5} sx={{ mt: "0px !important" }}>
                          <Typography fontWeight={400} variant="subtitle2">
                            {`${item.sSaraly_Start}${item.sSaraly_End ? ` - ${item.sSaraly_End}` : ""}`}
                          </Typography>
                        </Grid>
                      </Grid>

                    </Grid>

                  </Grid>
                return (html);
              })}

              <Stack direction={"row"}  >
                <Divider sx={{ width: "100%", backgroundColor: "#03a9f4" }} />
              </Stack>


              {/* ความสามารถในการขับขี่ */}
              <Stack direction={"row"} alignItems="end" justifyContent="space-between">
                <Typography color={"#03a9f4"} fontWeight={500} fontSize={18}>
                  {i18n(`${i18nField}.License`)}
                </Typography>
              </Stack>

              <Grid container>
                {dataLicense.map((item, index) => {
                  const html =
                    <Grid container sx={{ columnGap: "1rem" }}>
                      <Grid item md={2.5} sm={3} xs={4.5} sx={{ marginLeft: { md: "20px", xs: "0px" } }}>
                        <Typography fontWeight={500} fontSize={16}>
                          {item["sLicenseName_" + langeCode]}
                        </Typography>
                      </Grid>

                      <Grid item md={7} sm={7} xs={5.5}>
                        <Typography fontWeight={400} fontSize={14}>
                          {item.sLicenseNo}
                        </Typography>
                      </Grid>
                    </Grid>
                  return (html);
                })}
              </Grid>

              <Stack direction={"row"}  >
                <Divider sx={{ width: "100%", backgroundColor: "#03a9f4" }} />
              </Stack>


              {/* คีย์เวิร์ด */}
              <Stack direction={"row"} alignItems="end" justifyContent="space-between">
                <Typography color={"#03a9f4"} fontWeight={500} fontSize={18}>
                  {i18n(`${i18nField}.Keyword`)}
                </Typography>
              </Stack>

              <Grid container item xs={12} sx={{ flexDirection: "row", gap: "1rem !important", mt: "5px !important" }}>
                {dataKeyword.map((item, index) => {
                  const html =
                    <Box className="border border-[#1976d2] rounded-[1em] min-w-[55px] px-[5px] py-[1] border-w-[2px] flex justify-center items-center ">
                      <Typography fontWeight={400} variant="subtitle2">
                        {item}
                      </Typography>
                    </Box>
                  return (html)
                })}
              </Grid>

              <Stack direction={"row"}  >
                <Divider sx={{ width: "100%", backgroundColor: "#03a9f4" }} />
              </Stack>


              {/* เอกสารแนบ */}
              <Stack direction={"row"} alignItems="end" justifyContent="space-between">
                <Typography color={"#03a9f4"} fontWeight={500} fontSize={18}>
                  {i18n(`${i18nField}.AttachFile`)}
                </Typography>
              </Stack>

              <Grid container item xs={12} sx={{ flexDirection: "row", gap: "1rem !important", mt: "5px !important" }}>
                {fileResume ?
                  <>
                    {fileResume.map((item) => {

                      let ValueTarget = "";
                      const FileExtension = [".png", ".gif", ".jpeg", ".jpg", ".pdf"];
                      if (FileExtension.includes(item.sCheckFile)) {
                        ValueTarget = "_blank";
                      }

                      let icon = <FaFileImage style={{ fontSize: "20px" }} />

                      if (item.sCheckFile === ".pdf") {
                        icon = <FaFilePdf style={{ fontSize: "20px" }} />
                      }
                      else if (item.sCheckFile === ".docx" || item.sCheckFile === ".doc") {
                        icon = <FaFileWord style={{ fontSize: "20px" }} />
                      }
                      else if (item.sCheckFile === ".pptx" || item.sCheckFile === ".ppt") {
                        icon = <FaFilePowerpoint style={{ fontSize: "20px" }} />
                      }

                      let html = <Link href={item.sUrl} target={ValueTarget}>
                        <Button variant="contained" disableElevation
                          startIcon={icon}
                          sx={{
                            cursor: "pointer", textTransform: "none", fontSize: "10px", borderRadius: "20px",
                            backgroundColor: "#505dd9",
                            "&:hover": {
                              backgroundColor: "#2330a8"
                            }
                          }}>
                          <Box sx={{ fontSize: "11px", fontWeight: "600" }}>{item.sFileName}</Box>
                        </Button>
                      </Link>

                      if (FileExtension.includes(item.sCheckFile)) {
                        html = <Link href={item.sUrl} target={ValueTarget}>
                          <Button variant="contained" disableElevation
                            startIcon={icon}
                            sx={{
                              cursor: "pointer", textTransform: "none", fontSize: "10px", borderRadius: "20px",
                              backgroundColor: "#fe7028",
                              "&:hover": {
                                backgroundColor: "#d54700"
                              }
                            }}>
                            <Box sx={{ fontSize: "11px", fontWeight: "600" }}>{item.sFileName}</Box>
                          </Button>
                        </Link>
                      }

                      return (html)
                    })
                    }
                  </>
                  :
                  <></>
                }
              </Grid>

              <Grid container item xs={12} sx={{ flexDirection: "row", gap: "1rem !important", mt: "5px !important" }}>
                {fileOther ?
                  <>
                    {fileOther.map((item) => {

                      let ValueTarget = "";
                      const FileExtension = [".png", ".gif", ".jpeg", ".jpg", ".pdf"];
                      if (FileExtension.includes(item.sCheckFile)) {
                        ValueTarget = "_blank";
                      }

                      let icon = <FaFileImage style={{ fontSize: "20px" }} />

                      if (item.sCheckFile === ".pdf") {
                        icon = <FaFilePdf style={{ fontSize: "20px" }} />
                      }
                      else if (item.sCheckFile === ".docx" || item.sCheckFile === ".doc") {
                        icon = <FaFileWord style={{ fontSize: "20px" }} />
                      }
                      else if (item.sCheckFile === ".pptx" || item.sCheckFile === ".ppt") {
                        icon = <FaFilePowerpoint style={{ fontSize: "20px" }} />
                      }

                      let html = <Link href={item.sUrl} target={ValueTarget}>
                        <Button variant="contained" disableElevation
                          startIcon={icon}
                          sx={{
                            cursor: "pointer", textTransform: "none", fontSize: "10px", borderRadius: "20px",
                            backgroundColor: "#505dd9",
                            "&:hover": {
                              backgroundColor: "#2330a8"
                            }
                          }}>
                          <Box sx={{ fontSize: "11px", fontWeight: "600" }}>{item.sFileName}</Box>
                        </Button>
                      </Link>

                      if (FileExtension.includes(item.sCheckFile)) {
                        html = <Link href={item.sUrl} target={ValueTarget}>
                          <Button variant="contained" disableElevation
                            startIcon={icon}
                            sx={{
                              cursor: "pointer", textTransform: "none", fontSize: "10px", borderRadius: "20px",
                              backgroundColor: "#fe7028",
                              "&:hover": {
                                backgroundColor: "#d54700"
                              }
                            }}>
                            <Box sx={{ fontSize: "11px", fontWeight: "600" }}>{item.sFileName}</Box>
                          </Button>
                        </Link>
                      }

                      return (html)
                    })
                    }
                  </>
                  :
                  <></>
                }
              </Grid>

            </>

          )}

        </Grid>

        {isAdmin && (
          <Grid item xs={12} justifyContent={"start"} marginTop={3} marginBottom={3}>
            <BtnBack txt="ย้อนกลับ" onClick={() => onBack()} />
          </Grid>
        )}

      </Grid>
    </Grid >
  )
};
export default Profile;