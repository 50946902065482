import { useEffect, useContext, useState, useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form'
import { Grid, Box, IconButton, Paper, Typography, Stack } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { i18n } from 'i18n';
import { ProfileContext } from 'components/SeekerProfile/Context/ProfileContext';
import AutoCompleteSelect from 'components/Common/ElementInForm/AutoCompleteSelect';
import { IComputerSkill, IPropsComputerSkill } from './Interface';
import { DialogActionCreators } from "store/redux/DialogAlert";
import { useDispatch } from "react-redux";
import { useNavigate } from 'react-router-dom';
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { BtnBack, BtnEditProfile, BtnRadiusProfile, BtnSubmit } from 'components/Common/Button';
import { AccordionCustom, AccordionDetailsCustom, AccordionSummaryCustom } from './ElementCustom';
import { AxiosGet, AxiosPost } from 'service/CommonFunction/TS_function';
import { FnBlock_UI } from 'service/SystemFunction/SystemFunction';
import yupFormSchemas from 'components/Common/yup/yupFormSchemas';
import CardComplete from './CardComplete';
import CardInComplete from './CardInComplete';
const i18nCommon = 'common';
const i18nField = 'entities.SeekerProfile';
const OptionProgram = [
    { label: "Word", value: 1 },
    { label: "Excel", value: 2 },
    { label: "Power Point", value: 3 },
    { label: "Java", value: 4 },
    { label: "Javascript", value: 5 },
    { label: "C#", value: 6 },
    { label: "PHP", value: 7 },
    { label: "C++", value: 8 },
    { label: "Python", value: 9 },
    { label: "C", value: 10 },
    { label: "SQL", value: 11 },
    { label: "RUBY", value: 12 },
    { label: "Objective-C", value: 13 },
    { label: "Perl", value: 14 },
    { label: ".NET", value: 15 },
    { label: "Visual", value: 16 },
];


const MyComputerSkillNew = ({ Edit, isExpanded, isJobApply, sIDNew,onBackEdit }) => {

    const {
        computerSkillContext,
        setComputerSkill,
        optionLangSkill,
        isComputerSkillEdit,
        setIsComputerSkillEdit,
        isLoading,
        profileContext,
    } = useContext(ProfileContext);

    const [expandComputerSkill, setExpandComputerSkill] = useState(false);
    const { BlockUI, UnBlockUI } = FnBlock_UI();
    const dispatch = useDispatch();
    const history = useNavigate();
    const [formSchema, setFormSchema] = useState<any>({});
    const [arrcomputerSkill, setArrcomputerSkill] = useState<any>([]);
    const navigate = useNavigate();
    // Set up Schema Computer Skill 
    const [schemaComputerSkill, setSchemaComputerSkill] = useState({});
    const [isComputerSkill, setisComputerSkill] = useState(false);
    const schema = yup.object().shape(formSchema);
    const form = useForm({
        resolver: yupResolver(schema),
        shouldUnregister: false,
        shouldFocusError: true,
        mode: "all",
    });

    // -> Computer Skill
    const arrSchemaNameComputerSkill = [
        "Program",
        "SkillLevel",
    ];

    const [isComPlete, setisComPlete] = useState(false);

    useEffect(() => {
        for (const [key, value] of Object.entries(formSchema)) {
            if (arrSchemaNameComputerSkill.some((a) => key.includes(a))) {
                delete formSchema[key];
            }
        }
        setFormSchema({ ...formSchema, ...schemaComputerSkill });

    }, [schemaComputerSkill, isComputerSkill]);


    const handleChangeValue = (form, index, value) => {
        let newData = computerSkillContext.map((item: IComputerSkill) => {
            if (item.Index === index) {
                item.Program = form.getValues("Program" + index);
                item.SkillLevel = form.getValues("SkillLevel" + index);
                item.sOther = value ? form.getValues("Program" + index)["label"] : "";
            }
            return item;
        });
        setComputerSkill(newData);
        handleChangerequired(newData)
    }

    const handleAddComputerSkill = () => {

        let nGenIndex =
            (Math.max.apply(Math, computerSkillContext.map((item: IComputerSkill, index) => { return item.Index; })) || 0) + 1;

        let newData = [
            ...computerSkillContext,
            {
                Index: nGenIndex,
                Program: null,
                LevelSkill: "",
                sOther: "",
                SkillLevel: "",
            },
        ] as Array<IComputerSkill>;
        setComputerSkill(newData);
        setFocusNewField(`Program${nGenIndex}`, nGenIndex);
        handleChangerequired(newData);
    };
    const setFocusNewField = (sName, nGenIndex) => {
        setTimeout(() => {
            try {
                form.setFocus(sName);

            } catch (error) { }
        }, 500);
    };
    const handleChangerequired = (newData) => {
        const schemaTemp = {};
        newData.forEach((item) => {
            let isRequired = item.Program !== null
            schemaTemp[`Program${item.Index}`] = yupFormSchemas.object(
                i18n(`${i18nField}.Program`),
                { required: false }
            );
            schemaTemp[`SkillLevel${item.Index}`] = yupFormSchemas.object(
                i18n(`${i18nField}.SkillLevel`),
                { required: isRequired }
            );
            setisComputerSkill(false);
        });
        setSchemaComputerSkill(schemaTemp);
    }

    const handleRemoveComputerSkill = (itemIndex: number) => {
        dispatch(DialogActionCreators.OpenDialogSubmit(i18n(`${i18nCommon}.AlertConfrimDel`), () => {
            dispatch(DialogActionCreators.CloseDialogSubmit() as any);
            dispatch(DialogActionCreators.LoadSubmit("Waiting...") as any);
            let newData = computerSkillContext.filter((data) => { return data.Index !== itemIndex });
            setComputerSkill(newData);
            form.unregister("Program" + itemIndex);
            form.unregister("SkillLevel" + itemIndex);
        }));

    };

    const GetMyProfile = () => {
        AxiosGet("JobSeeker/GetMyProfileComputerSkill", {}, (res) => {
            //setisComPlete(res.isComputerSkill);

            setArrcomputerSkill(res.listComputerSkill || []);
            let listComputerSkill = [];
            if ((res.listComputerSkill || []).length > 0) {
                listComputerSkill = (res.listComputerSkill || []).map((item) => {
                    const selectProgram =
                        OptionProgram.find((f: any) => f.value === item.nProgramId) || {};
                    const selectSkill =
                        optionLangSkill.find((f: any) => f.value === item.nSkillLevel) || {};
                    return {
                        Index: item.nComputerSkillId,
                        Program: item.nProgramId === 99
                            ? { label: item.sOther, value: item.nProgramId }
                            : selectProgram,
                        SkillLevel: selectSkill,
                        sOther: item.sOther
                    } as IComputerSkill;
                }) as IComputerSkill[];

            } else {
                listComputerSkill = [
                    {
                        Index: 0,
                        Program: null,
                        SkillLevel: null,
                        sOther: "",
                    },
                ] as IComputerSkill[];
            }

            setComputerSkill(listComputerSkill);
            listComputerSkill.forEach((data) => {
                form.setValue("Program" + data.Index, data.Program || '');
                form.setValue("SkillLevel" + data.Index, data.SkillLevel || '');
            });

        });
    };

    const GetMyProfileStatus = () => {
        let param = {
            nPanel: 11
        }
        AxiosPost("JobSeeker/GetMyProfileStatus", param, (res) => {
            setisComPlete(res.IsCompleted);

        });
    };

    useEffect(() => {
        GetMyProfileStatus();
        GetMyProfile();
    }, [])
    const handleEditWorkUnit = () => {
        let sJobApply = "";
        let sID = "";
        if (isJobApply) {
            sJobApply = "sJobApply";
            sID = sIDNew;
        }
        history(`/MyProfileEdit?sMode=ComputerSkill&sJobApply=${sJobApply}&sIDNew=${sID}`);
    }

    const onBack = () => {
        setIsComputerSkillEdit(false)
        if (isJobApply) {
            let objProfile = {
                sMode: "ComputerSkill",
            };
            let objJobDetail = {
                sID: sIDNew || "",
                isApply: true,
                sFromPage: "JobDetail",
            };
            onBackEdit(objProfile,objJobDetail);

        } else {
            history(`/MyProfile?sMode=ComputerSkill`);
        }
    }
    const onGoPage = () => {
        if (isJobApply) {
            let objProfile = {
                sMode: "ComputerSkill",
            };
            let objJobDetail = {
                sID: sIDNew || "",
                isApply: true,
                sFromPage: "JobDetail",
            };
            onBackEdit(objProfile,objJobDetail);
        } else {
            history(`/MyProfile?sMode=ComputerSkill`);

        }
    }

    useEffect(() => {
        setIsComputerSkillEdit(Edit);
        setExpandComputerSkill(isExpanded);
    }, [])

    const onSubmit = (values) => {
        const arrComputerSkill = computerSkillContext.map((item) => {

            return {
                nComputerSkillId: item.Index,
                nProgramId: item.Program == null || item.Program["value"] === undefined ? 99 : item.Program["value"],
                nSkillLevel: item.SkillLevel == null ? null : item.SkillLevel["value"],
                sOther: item.sOther
            };
        });


        const dataProfile = {
            listComputerSkill: arrComputerSkill,
            nMode: 11,
        };
        dispatch(
            DialogActionCreators.OpenDialogSubmit(i18n(`${i18nField}.ConfirmMsgUpdate`), () => {
                dispatch(DialogActionCreators.CloseDialogSubmit() as any);
                dispatch(DialogActionCreators.LoadSubmit("Waiting...") as any);
                BlockUI();
                AxiosPost(`JobSeeker/UpdateProfile`, dataProfile, (result) => {
                    UnBlockUI();
                    const res = result.Data;
                    if (res.IsSuccess) {
                        dispatch(DialogActionCreators.OpenDialogSuccess(i18n(`${i18nField}.MsgPanelComputerSkill`), () => { onGoPage(); })
                        );
                    }
                },
                    (err) => {
                        if (!err.response) {
                            history("/error");
                            return;
                        }
                    }
                );
            }
            ) as any
        );
    };


    const ComputerSkill = useCallback(() => {
        return (
            <FormProvider {...form} >
                {
                    (computerSkillContext || []).map((item: IComputerSkill, index) => {
                        return (
                            <Box component={Paper} sx={{ border: "10px", p: 1, flex: 1, m: "2%" }} key={index}>
                                {isComputerSkillEdit && (
                                    <>
                                        {index === 0 ? null :
                                            <IconButton aria-label="delete" color="error" onClick={() => handleRemoveComputerSkill(item.Index)}>
                                                <CloseIcon />
                                            </IconButton>}
                                    </>)}
                                <Grid container justifyContent="center" alignItems="center" spacing={1} >
                                    <Grid item container spacing={2} xs={12} >
                                        <Grid item md={6} xs={12} >
                                            <AutoCompleteSelect
                                                name={"Program" + item.Index}
                                                label={i18n(`${i18nField}.Program`)}
                                                options={OptionProgram}
                                                onChange={(value) => {
                                                    form.setValue("Program" + item.Index, value);
                                                    if (value == null) {
                                                        form.setValue("SkillLevel" + item.Index, 0);
                                                    }
                                                    handleChangeValue(form, item.Index, value);
                                                }}
                                                freeSolo
                                                required={false}
                                                disabled={!isComputerSkillEdit}
                                            />
                                        </Grid>
                                        <Grid item md={6} xs={12} >
                                            <AutoCompleteSelect
                                                name={"SkillLevel" + item.Index}
                                                label={i18n(`${i18nField}.SkillLevel`)}
                                                options={optionLangSkill}
                                                onChange={(value) => {
                                                    form.setValue("SkillLevel" + item.Index, value);
                                                    handleChangeValue(form, item.Index, value);
                                                }}
                                                required={false}
                                                disabled={!isComputerSkillEdit}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Box>
                        )
                    })
                }
            </FormProvider>
        )
    }, [computerSkillContext, isComputerSkill]);
    return (
        <>
            <FormProvider {...form} >
                <AccordionCustom
                    sx={{ border: "10px", p: 1, flex: 1 }}
                    expanded={expandComputerSkill}
                    onChange={() => setExpandComputerSkill((prev) => !prev)}
                >
                    <AccordionSummaryCustom>

                        <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                        >
                            <Grid item xs={6} sm={8} md={9}>
                                <Typography >
                                    {i18n(`${i18nField}.PanelComputerSkill`)}
                                </Typography>
                            </Grid>
                            <Grid item container
                                justifyContent="flex-end" xs={6} sm={4} md={3}>
                                <CardComplete isComPlete={isComPlete} />
                                {/* {isComPlete ? (
                                    <CardComplete />
                                ) : (
                                    <CardInComplete />
                                )} */}
                            </Grid>
                        </Grid>

                    </AccordionSummaryCustom>
                    <AccordionDetailsCustom>
                        <ComputerSkill />
                        {isComputerSkillEdit && (
                            <Stack
                                sx={{ border: "10px", p: 1, flex: 1, m: "2%" }}
                                direction="row"
                                justifyContent="center"
                            >
                                <BtnRadiusProfile
                                    txt={i18n(`${i18nField}.AddWorkDetail`)}
                                    onClick={handleAddComputerSkill}
                                />
                            </Stack>
                        )}
                        {!isComputerSkillEdit && (
                            <Stack
                                sx={{ border: "10px", p: 1, flex: 1, m: "2%" }}
                                direction="row"
                            >
                                <BtnEditProfile
                                    txt={i18n(`${i18nField}.Edit`)}
                                    onClick={handleEditWorkUnit}
                                />
                            </Stack>
                        )}
                    </AccordionDetailsCustom>
                </AccordionCustom>
                {isComputerSkillEdit && (
                    <Stack
                        spacing={1}
                        sx={{ border: "10px", p: 1, flex: 1, mt: "2%" }}
                        direction="row"
                        justifyContent="space-between"
                        alignItems="flex-start"
                    >
                        <BtnBack
                            onClick={() => { onBack(); }}
                            txt={i18n(`${i18nCommon}.back`)}
                        />
                        <BtnSubmit
                            onClick={form.handleSubmit(onSubmit, (err) => console.log("error", err))}
                            txt={i18n(`${i18nCommon}.save`)}
                        />
                    </Stack>
                )}
            </FormProvider>
        </>
    )
}

export default MyComputerSkillNew;