import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import { Box, FormControl, FormControlLabel, FormHelperText, Grid, InputAdornment, Paper, Radio, RadioGroup, Stack, Typography, } from "@mui/material";
import InputNumber from "components/Common/ElementInForm/InputNumber";
import { AccordionCustom, AccordionDetailsCustom, AccordionSummaryCustom } from "./ElementCustom";
import { useContext, useEffect, useState } from "react";
import { i18n } from "i18n";
import Input from "components/Common/ElementInForm/InputCustomLabelFormItem";
import AutoCompleteRenderItem from "components/Common/ElementInForm/AutoCompleteRenderItem";
import { BtnBack, BtnEditProfile, BtnSubmit } from "components/Common/Button";
import { useNavigate } from "react-router-dom";
import { ProfileContext } from "./Context/ProfileContext";
import { DialogActionCreators } from "store/redux/DialogAlert";
import { useDispatch } from "react-redux";
import { AxiosPost } from "service/CommonFunction/TS_function";
import { FnBlock_UI } from "service/SystemFunction/SystemFunction";
import yupFormSchemas from "components/Common/yup/yupFormSchemas";

const MyProfileDayStart = ({ Edit, isJobApply, sIDNew,onBackEdit }) => {
    const navigate = useNavigate();
    const history = useNavigate();
    const dispatch = useDispatch();
    const { BlockUI, UnBlockUI } = FnBlock_UI();
    const i18nCommon = "common";
    const i18nField = "entities.SeekerProfile";
    const [isStartNow, setIsStartNow] = useState(true);
    const [expandedAbility, setExpandedAbility] = useState(true);
    const formSchema = {
        nDayStart: yupFormSchemas.integer(i18n(`${i18nField}.Day`), { required: !isStartNow, }),
        nCurrentSalary: yupFormSchemas.decimal(i18n(`${i18nField}.CurrentSalary`)),
        sTag: yupFormSchemas.stringArray(i18n(`${i18nField}.Tag`)),
    };

    const schema = yup.object().shape(formSchema);
    const form = useForm({
        resolver: yupResolver(schema),
        shouldUnregister: false,
        shouldFocusError: true,
        mode: "all",
    });
    const {
        isLoading,
        isDayStartEdit,
        setIsDayStartEdit,
        profileContext,
    } = useContext(ProfileContext);

    useEffect(() => {
        setIsDayStartEdit(Edit);

    }, []);

    const setDataMyProfile = () => {
        const data = profileContext as any;
        form.setValue("nDayStart", data.nDay);
        form.setValue("nCurrentSalary", data.nLast_Salary);
        form.setValue("sTag", data.listTag);
        setIsStartNow(data.nDay === 0);
    };

    useEffect(() => {
        if (!isLoading) {
            setDataMyProfile();
        }

    }, [
        isLoading,
    ]);


    const handleEditWorkUnit = () => {
        let sJobApply = "";
        let sID = "";
        if (isJobApply) {
            sJobApply = "sJobApply";
            sID = sIDNew;
        }
        history(`/MyProfileEdit?sMode=DayStartNew&sJobApply=${sJobApply}&sIDNew=${sID}`);
    }

    const onBack = () => {
        setIsDayStartEdit(false);
        if (isJobApply) {
            let objProfile = {
                sMode: "DayStartNew",
            };
            let objJobDetail = {
                sID: sIDNew || "",
                isApply: true,
                sFromPage: "JobDetail",
            };
            onBackEdit(objProfile,objJobDetail);

        } else {
            history(`/MyProfile?sMode=DayStartNew`);
        }
    }

    const onGoPage = () => {
        if (isJobApply) {
            let objProfile = {
                sMode: "DayStartNew",
            };
            let objJobDetail = {
                sID: sIDNew || "",
                isApply: true,
                sFromPage: "JobDetail",
            };
            onBackEdit(objProfile,objJobDetail);
        } else {
            history("/MyProfile?sMode=DayStartNew");
        }
    }
    const onSubmit = (values) => {
        const dataProfile = {
            IsWork: isStartNow,
            nDay: isStartNow ? 0 : values.nDayStart,
            nLast_Salary:form.getValues("nCurrentSalary"),
            listTag: values.sTag || [],
            nMode: 13,
        };
     
        dispatch(DialogActionCreators.OpenDialogSubmit(i18n(`${i18nField}.ConfirmMsgUpdate`), () => {
            dispatch(DialogActionCreators.CloseDialogSubmit() as any);
            dispatch(DialogActionCreators.LoadSubmit("Waiting...") as any);
            BlockUI();
            AxiosPost(`JobSeeker/UpdateProfile`, dataProfile, (result) => {
                UnBlockUI();
                const res = result.Data;
                if (res.IsSuccess) {
                    dispatch(DialogActionCreators.OpenDialogSuccess(i18n(`${i18nField}.MsgPanelTag`), () => { onGoPage() })
                    );
                }
            },
                (err) => {
                    if (!err.response) {
                        history("/error");
                        return;
                    }
                }
            );
        }
        ) as any
        );
    };

    return (
        <>
            <FormProvider {...form} >
                <Box component={Paper} sx={{
                    border: "10px",
                    p: 1,
                    flex: 1,
                    borderRadius: "15px",
                    backgroundColor: "white",
                    background: "white",
                    boxShadow: "0px 0px 17px 1px rgba(0,0,0,0.3)",
                    width: "100%",

                }}>
                    <Grid
                        container
                        spacing={1}
                        sx={{ border: "10px", p: 1, flex: 1, m: "2%" }}
                        id={"5"}
                    >
                        <Grid item xs={12}>
                            <FormControl>
                                <RadioGroup
                                    value={isStartNow ? "now" : "late"}
                                    onChange={(e, v) => {
                                        setIsStartNow(v === "now");
                                        if (v === "now") {
                                            form.setValue("nDayStart", null);
                                            form.clearErrors("nDayStart");
                                        }
                                    }}
                                >
                                    <FormControlLabel
                                        value="now"
                                        control={<Radio />}
                                        label={i18n(`${i18nField}.StartNow`)}
                                        disabled={!isDayStartEdit}
                                    />
                                    <div style={{ display: "inherit" }}>
                                        <Grid item md={8} xs={8}  sm={8.5}>
                                            <FormControlLabel
                                                // style={{ width: LayoutWeb ? "800px" : "450px" }}
                                                value="late"
                                                control={<Radio />}
                                                label={i18n(`${i18nField}.StartLate`)}
                                                disabled={!isDayStartEdit}
                                            />
                                        </Grid>
                                        <Grid item md={2.8} xs={3.5} sm={3}>
                                            <Input
                                                name={"nDayStart"}
                                                small
                                                // sx={{ m: 1, width: LayoutWeb ? "10ch" : "7ch" }}
                                                disabled={isStartNow || !isDayStartEdit}
                                                required={!isStartNow}

                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        {i18n(`${i18nField}.Day`)}
                                                    </InputAdornment>
                                                }
                                            />
                                        </Grid>
                                    </div>
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        <Grid item xs={11.5} >
                            <InputNumber
                                name={"nCurrentSalary"}
                                small
                                label={i18n(`${i18nField}.CurrentSalary`)}
                                disabled={!isDayStartEdit}
                            />
                        </Grid>
                    </Grid>

                    <AccordionCustom
                        sx={{ border: "10px", p: 1, flex: 1, m: "2%" }}
                        expanded={expandedAbility}
                        onChange={() => setExpandedAbility((prev) => !prev)}
                        id={"5"}
                    >
                        <AccordionSummaryCustom>
                            <Typography sx={{ width: "100%", flexShrink: 0 }}>
                                {i18n(`${i18nField}.PanelTag`)}
                            </Typography>
                        </AccordionSummaryCustom>
                        <AccordionDetailsCustom>
                            <Grid
                                sx={{ border: "10px", p: 1, flex: 1, m: "2%" }}
                                item
                                md={12}
                                xs={12}
                            >
                                <AutoCompleteRenderItem
                                    isFreeCreate
                                    name={"sTag"}
                                    fullWidth
                                    label={i18n(`${i18nField}.TagName`)}
                                    options={[]}
                                    disabled={!isDayStartEdit}
                                    maxLength={1000}
                                />
                                <FormHelperText sx={{ color: "grey" }}>
                                    {i18n(`${i18nField}.MsgTag`)}
                                </FormHelperText>
                            </Grid>
                        </AccordionDetailsCustom>
                    </AccordionCustom>

                    {!isDayStartEdit ? (
                        <Stack
                            sx={{ border: "10px", p: 1, flex: 1, m: "2%" }}
                            direction="row"
                        >
                            <BtnEditProfile
                                txt={i18n(`${i18nField}.Edit`)}
                                onClick={handleEditWorkUnit}
                            />
                        </Stack>
                    ):(
                        <div style={{height:"6vh"}} ></div>
                    )}
                </Box>

                {isDayStartEdit && (
                    <Stack
                        spacing={1}
                        sx={{ border: "10px", p: 1, flex: 1, mt: "2%" }}
                        direction="row"
                        justifyContent="space-between"
                        alignItems="flex-start"
                    >
                        <BtnBack
                            onClick={() => { onBack() }}
                            txt={i18n(`${i18nCommon}.back`)}
                        />
                        <BtnSubmit
                            onClick={form.handleSubmit(onSubmit, (err) => console.log("error", err))}
                            txt={i18n(`${i18nCommon}.save`)}
                        />
                    </Stack>
                )}
            </FormProvider>
        </>
    )
}
export default MyProfileDayStart
