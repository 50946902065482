import { useState, useEffect, useContext, useRef, useMemo } from "react";
import { Card, Grid, LinearProgress, Stack, Typography, linearProgressClasses, styled } from "@mui/material";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { i18n } from "i18n";
import { ProfileContext } from "components/SeekerProfile/Context/ProfileContext";
import secureLocalStorage from "react-secure-storage";
import { AxiosGet } from "service/CommonFunction/TS_function";
const CardPersonalInformation = () => {

    const {
        profileContext,
        isLoading,
        fileUploadTranscriptContext,
        fileUploadResumeContext,
        arrWorkExperienceContext
    } = useContext(ProfileContext);
    const [nPercent, setnPercent] = useState(0);
    const [nSumProfile, setSumProfile] = useState(0);
    const [nTranscript, setTranscript] = useState(0);
    const [nResume, setResume] = useState(0);
    const GetMyProfileStatus = () => {
        AxiosGet("JobSeeker/GetMyProfileComPlete", {}, (res) => {
            setnPercent(res.nComPlete + 1);
        });
    };

    useEffect(() => {
        GetMyProfileStatus();
    }, []);


    useMemo(() => {
        GetMyProfileStatus()
      }, [secureLocalStorage.getItem("ProfileNoti")])

    const i18nField = "entities.SeekerProfile";
    const dPercent = ((nPercent) / 12) * 100;
    const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
        height: 10,
        borderRadius: 5,
        [`&.${linearProgressClasses.colorPrimary}`]: {
            backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 300 : 800],
        },
        [`& .${linearProgressClasses.bar}`]: {
            borderRadius: 5,
            backgroundColor: '#4cb162',
        }
    }));

    return (
        <>
            <Grid container spacing={2} justifyContent={"center"} alignItems={"center"} style={{ marginTop: '1vh' }}>
                <Grid item md={5.5} xs={11} style={{ marginLeft: "15px" }}>
                    <Card
                        sx={{
                            position: 'relative',
                            backgroundColor: "#d7ebff",
                            borderRadius: "30px",
                            padding: '30px !important'
                        }}
                    >
                        <Grid container spacing={1} justifyContent={"flex-start"} alignItems={"center"}>
                            <Grid item xs={12} >
                                <Grid container spacing={2} alignItems={"center"}>
                                    <Grid item xs={3} md={2} sm={2} >
                                        <InfoOutlinedIcon style={{ fontSize: '3.5em', color: '#18478d' }} />
                                    </Grid>
                                    <Grid item xs={9} md={10} sm={10} >
                                        <Grid container justifyContent={"flex-end"}>
                                            <Grid item xs={12} container justifyContent="flex-end">
                                                {nPercent  === 12 ? (
                                                    <Typography className='card-title'>{i18n(`${i18nField}.PersonalInformationComplete`)}</Typography>
                                                ) : (
                                                    <>
                                                        <Typography className='card-title'>{i18n(`${i18nField}.PersonalInformation`)}</Typography>
                                                    </>
                                                )}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
                <Grid item md={0.2} xs={12} ></Grid>
                <Grid item md={3.5} xs={11} sx={{ padding: "10px 5%", marginLeft: "40px" }} >
                    <Stack direction={"row"} justifyContent={"center"}>
                        <Typography fontSize={"30px"}>{i18n(`${i18nField}.ProfileInformation`)}</Typography>
                    </Stack>
                    <BorderLinearProgress variant="determinate" value={dPercent} />
                </Grid>
            </Grid>
        </>
    );
}
export default CardPersonalInformation 