import React, { useEffect, useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import {
  Box,
  Card,
  Grid,
  Stack,
  Typography,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import { BtnChangePassword } from "components/Common/Button";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { DialogActionCreators } from "store/redux/DialogAlert";
import { AxiosPost } from "service/CommonFunction/TS_function";
import { FnBlock_UI } from "service/SystemFunction/SystemFunction";
import { i18n } from "i18n";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import Input from "components/Common/ElementInForm/InputCustomLabelFormItem";
import yupFormSchemas, {
  RegexpPassword,
} from "components/Common/yup/yupFormSchemas";

const i18nField = "SettingSeeker";
const i18nField_Common = "common";

export default function ForgotPassword() {
  const navigate = useNavigate();
  const { BlockUI, UnBlockUI } = FnBlock_UI();
  const dispatch = useDispatch();
  const location = useLocation();
  const searchParams = location.search;

  const sID = new URLSearchParams(searchParams).get("sID");
  const isJobSeeker = new URLSearchParams(searchParams).get("sMode") === "1";
  const isuser = new URLSearchParams(searchParams).get("sMode") === "2";
  const iscompany = new URLSearchParams(searchParams).get("sMode") === "3";
  const isEmployee = new URLSearchParams(searchParams).get("sMode") === "4";
  const [showPwdNew, setShowPwdNew] = useState(false);
  const [showCPassword, setShowCPassword] = useState(false);

  const objSchema = {
    sUser_PW: yupFormSchemas.string(i18n(`${i18nField}.UserSecureCodeNow`), {
      required: true,
      matches: RegexpPassword(),
      labelmatches: i18n("validation.string.sSecureCode"),
    }),
    sConfirmPWD: yupFormSchemas
      .string(i18n(`${i18nField}.ConfirmSecureCode`), {
        required: true,
      })
      .oneOf([yup.ref("sUser_PW")], i18n(`${i18nField}.UserSecureCodeIncorrect`)),
  };

  const initValue: any = {
    sUser_PW: "",
    sConfirmPWD: "",
  };
  const schema = yup.object().shape(objSchema);
  const formResolver = yupResolver(schema);
  const form = useForm({
    resolver: formResolver,
    shouldUnregister: false,
    shouldFocusError: true,
    mode: "all",
    defaultValues: initValue,
  });

  const _onSubmit = (e) => {
    BlockUI();
    let param = {
      sNewPassword: e.sUser_PW,
      sID: sID,
      isJobSeeker: isJobSeeker,
      isuser: isuser,
      iscompany: iscompany,
      isEmployee: isEmployee
    };
    

    AxiosPost(
      "Authen/ConfirmChangePassword",
      param,
      (res) => {

        // i18n(`${i18nField_Common}.requestExpired`)
        if (res.Status === 200) {
          dispatch(
            DialogActionCreators.OpenDialogSuccess(
              i18n(`${i18nField_Common}.msgAlertSuccess`),
              () => navigate("/")
            ) as any
          );
        } else {
          dispatch(
            DialogActionCreators.OpenDialogWarning(
              i18n(`${i18nField_Common}.requestExpired`)
            ) as any
          );
        }
      },
      (err) => {
        if (err.Status == 410) {
          dispatch(
            DialogActionCreators.OpenDialogWarning(
              i18n(`${i18nField_Common}.requestExpired`)
            ) as any
          );
        }
      },
      () => UnBlockUI()
    );
  };
  const EmptyGrid = () => <Grid item xs={1} md={4}></Grid>;

  return (
    <Grid
      container
      spacing={2}
      // columns={{ xs: 6 }}
      direction="row"
      justifyContent="center"
      alignItems="center"
      style={{ height: "100%" }}
    >
      <EmptyGrid />
      <Grid item xs={10} md={4}>
        <Card sx={{ padding: "1em", borderRadius: "1em" }}>
          <FormProvider {...form}>
            <Stack spacing={1}>
              <Typography
                variant={"h4"}
                align="center"
                sx={{ padding: "0.5em 1em" }}
              >
                {i18n(`${i18nField_Common}.forgotSecureCode`)}
              </Typography>
              <Grid item md={12} xs={12}>
                <Input
                  name={"sUser_PW"}
                  labelString={i18n(`${i18nField}.UserSecureCodeNow`)}
                  required
                  fullWidth
                  type={showPwdNew ? "text" : "password"}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        edge="end"
                        onClick={() => setShowPwdNew(!showPwdNew)}
                      >
                        {showPwdNew ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label={
                    <>
                      <Typography sx={{ fontWeight: 400 }} component="label">
                        {i18n(`${i18nField}.UserSecureCodeNow`)}
                      </Typography>
                    </>
                  }
                />
              </Grid>

              <Grid item md={12} xs={12}>
                <Input
                  name={"sConfirmPWD"}
                  labelString={i18n(`${i18nField}.ConfirmSecureCode`)}
                  required
                  fullWidth
                  type={showCPassword ? "text" : "password"}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        edge="end"
                        onClick={() => setShowCPassword(!showCPassword)}
                      >
                        {showCPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label={
                    <>
                      <Typography sx={{ fontWeight: 400 }} component="label">
                        {i18n(`${i18nField}.ConfirmSecureCode`)}
                      </Typography>
                    </>
                  }
                />
              </Grid>
              <Box
                sx={{
                  padding: 1,
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                }}
              >
                <BtnChangePassword
                  txt={i18n(`${i18nField_Common}.save`)}
                  isLoading={false}
                  onClick={form.handleSubmit((e) => {
                    _onSubmit(e);
                  })}
                />
              </Box>
            </Stack>
          </FormProvider>
        </Card>
      </Grid>
      <EmptyGrid />
    </Grid>
  );
}
