import React from 'react'
import { Tabs, Tab, Grid, Hidden } from '@mui/material'
import { i18n } from 'i18n'
export const th = {
    pending: "รอตรวจสอบ",
    interest: "สนใจ",
    buy_profile: "ซื้อประวัติ",
    invited: "ส่งคำเชิญ",
    shortlist: "จัดเข้ากลุ่ม",
    not_suitable: "ยังไม่ผ่าน"
}
export const en = {
    pending: "Pending",
    interest: "Interested",
    buy_profile: "Buy Profile",
    invited: "Invited",
    shortlist: "Shortlists",
    not_suitable: "Not Suitable"
}
export default function FilterTabs({ value, handleChange }) {
    const i18nField = "JobSeekerRegisJob.FilterTabs."
    const arrDataTabs = [
        { label: i18n(`${i18nField}pending`) },
        { label: i18n(`${i18nField}interest`) },
        { label: i18n(`${i18nField}buy_profile`) },
        { label: i18n(`${i18nField}invited`) },
        { label: i18n(`${i18nField}shortlist`) },
        { label: i18n(`${i18nField}not_suitable`) },
    ]
    // columnSpacing={5} 
    return (
        <>
            {/* phone size */}
            <Hidden smUp>
                <Grid container item direction={"column"} columnSpacing={3} flexDirection={"row"} sx={{ marginRight: "1%", marginLeft: "1%" }}>
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        TabIndicatorProps={{ style: { display: "none" } }}
                        sx={{
                            justifyContent: "space-around",
                            // justifyContent: "center",
                            ".MuiTabs-flexContainer": {
                                flexWrap: "wrap",
                                // gap: "10px"
                                gap: "10px"
                            }
                        }}
                    >
                        {arrDataTabs.map((item, index) => (
                            <Tab
                                key={`tabs_${index}`}
                                label={
                                    <span style={{ color: value === index ? "#f9fafd" : "#474747" }}>
                                        {item.label}
                                    </span>
                                }
                                sx={{
                                    height: "30px",
                                    minHeight: "0px !important",
                                    transition: "background-color 300ms linear",
                                    backgroundColor: value === index ? "#1976d2" : "#fff",
                                    borderRadius: "1em"
                                }}
                            />
                        ))}
                    </Tabs>
                </Grid>
            </Hidden>

            {/* normal size */}
            <Hidden smDown>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    TabIndicatorProps={{ style: { display: "none" } }}
                    sx={{
                        ".MuiTabs-flexContainer": {
                            flexWrap: "wrap",
                            gap: "10px"
                        }
                    }}
                >
                    {arrDataTabs.map((item, index) => (
                        <Tab
                            key={`tabs_${index}`}
                            label={
                                <span style={{ color: value === index ? "#f9fafd" : "#474747" }}>
                                    {item.label}
                                </span>
                            }
                            sx={{
                                height: "30px",
                                minHeight: "0px !important",
                                transition: "background-color 300ms linear",
                                backgroundColor: value === index ? "#1976d2" : "#fff",
                                borderRadius: "1em"
                            }}
                        />
                    ))}
                </Tabs>
            </Hidden>
        </>
    )
}
