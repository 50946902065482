const SetupTemplateEN = {
  label: {
    UploadLogo: "Upload Logo",
    UploadBanner: "Upload Banner",
    Content: "Entrepreneur's business",
    AddParagraphAdd: "Add Paragraph",
    UserSecureCodeChange: "Change Password",
    send: "Send",
    sSecureCodeold: "Old Password",
    close: "Close",
    UserSecureCodeNow: "New Password",
    confirmSecureCode: "Confirm Password",
    HelperTXTContent: "Introduce your companies' profiles here to let potential job seekers know your companies!",
    Or: "or"
  },
};

const SetupTemplateTH = {
  label: {
    UploadLogo: "อัปโหลด โลโก้",
    UploadBanner: "อัปโหลด แบนเนอร์",
    Content: "ธุรกิจของผู้ประกอบการ",
    AddParagraphAdd: "เพิ่มเนื้อหา",
    UserSecureCodeChange: "เปลี่ยนรหัสผ่าน",
    send: "ส่ง",
    sSecureCodeold: "รหัสผ่านเดิม",
    close: "ปิด",
    UserSecureCodeNow: "รหัสผ่านใหม่",
    confirmSecureCode: "ยืนยันรหัสผ่าน",
    HelperTXTContent: "แนะนำบริษัทของคุณที่นี่ เพื่อให้ผู้สมัครงานรู้จักบริษัทของคุณ!",
    Or: "หรือ"
  },
};

export { SetupTemplateTH, SetupTemplateEN };