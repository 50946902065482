import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { BtnBack } from "components/Common/Button";
import { AxiosGet } from "service/CommonFunction/TS_function";
import {
  Checkbox,
  Divider,
  Grid,
  Stack,
  Typography,
  FormGroup,
  FormControlLabel,
} from "@mui/material";
import Content from "../../font/JobDetail/Content";
import AdminCompanyTabPanel from "./AdminCompanyTabPanel";
import { FnBlock_UI } from "service/SystemFunction/SystemFunction";

import secureLocalStorage from 'react-secure-storage';


const AdminCompanyInfo = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = location.search;
  const sID = new URLSearchParams(searchParams).get("sID");
  ///console.log("sID", sID);

  const [info, setInfo] = useState<{ [key: string]: any }>({});
  const [infoReg, setInfoReg] = useState<{ [key: string]: any }>({});
  const { BlockUI, UnBlockUI } = FnBlock_UI();

  let tabNo = 0;

  const sPathName = "AdminCompany";
  const sStore = secureLocalStorage.getItem(sPathName);
  if (sStore && sStore !== "") {
    const DataItem = JSON.parse(sStore + "");
    if (DataItem != null) {
      tabNo = DataItem;
    }
  }


  const onGetDetail = () => {
    BlockUI();
    AxiosGet("AdminCompany/info", { sID: sID }, (res) => {
      setInfo(res);
      setInfoReg(res.CompanyInfo);
      UnBlockUI();
    });
  };

  useEffect(() => {
    if (tabNo === 0) {
      onGetDetail();
    }
    else {
      onGoPage(tabNo)
    }
  }, []);


  const onGoPage = (newValue) => {

    switch (newValue) {
      case 0:
        navigate(`/AdminCompanyInfo?sID=${encodeURI(sID)}`);
        break;
      case 1:
        navigate(`/AdminCompanyJob?sID=${encodeURI(sID)}`);
        break;
      case 2:
        navigate(`/AdminCompanyBanner?sID=${encodeURI(sID)}`);
        break;
      case 3:
        navigate(`/AdminCompanyOrder?sID=${encodeURI(sID)}`);
        break;
      case 4:
        navigate(`/AdminCompanySetting?sID=${encodeURI(sID)}`);
        break;
      case 5:
        navigate(`/AdminCompanyMember?sID=${encodeURI(sID)}`);
        break;
    }
  }


  const onBack = () => {
    navigate("/AdminCompany");
  };


  return (
    <Grid container>
      <Grid item className="table" sx={{ mx: "2%", width: "100%" }}>
        <Stack
          width={"100%"}
          sx={{
            marginTop: "2%",
            border: "2px #eeeeee solid",
            backgroundColor: "rgba(255,255,255,0.75)",
            borderRadius: 5,
            p: 3,
          }}
        >

          <AdminCompanyTabPanel tabNo={tabNo} />

          <Stack
            spacing={2}
            width={"100%"}
            sx={{
              marginTop: "2%",
              border: "2px #eeeeee solid",
              backgroundColor: "rgba(255,255,255,0.75)",
              borderRadius: 5,
              p: 3,
            }}
          >

            <Grid container>
              <Grid item md={12} xs={12}>
                <Stack direction={"row"} justifyContent="space-between">
                  <Typography color={"#03a9f4"} fontWeight={500} fontSize={18}>
                    ข้อมูลผู้สมัคร (Register Information)
                  </Typography>
                  <Typography variant="subtitle2" alignItems="end" >
                    ปรับปรุงเมื่อ : {infoReg.sUpdateDate}
                  </Typography>
                </Stack>
                <Stack direction={"row"}  >
                  <Divider sx={{ width: "100%", backgroundColor: "#03a9f4" }} />
                </Stack>
              </Grid>

              <Stack direction={"column"} sx={{ mt: "15px !important", gap: "15px" }} >
                <Stack direction={"row"} sx={{ gap: "1em" }}>
                  <Stack direction={"column"} justifyContent="center" sx={{ width: "200px", marginLeft: "20px" }}>
                    <Typography fontWeight={500} variant="subtitle1">
                      อีเมล
                    </Typography>
                  </Stack>
                  <Stack direction={"column"} flex={1}>
                    <Stack direction={"row"} alignItems="center" sx={{ mt: "0px !important", gap: "5px" }} >
                      <Typography fontWeight={400} variant="subtitle2">{infoReg.sEmail} </Typography>
                    </Stack>
                  </Stack>
                </Stack>

                <Stack direction={"row"} sx={{ gap: "1em" }}>
                  <Stack direction={"column"} justifyContent="center" sx={{ width: "200px", marginLeft: "20px" }}>
                    <Typography fontWeight={500} variant="subtitle1">
                      ชื่อ นามสกุล
                    </Typography>
                  </Stack>
                  <Stack direction={"column"} flex={1}>
                    <Stack direction={"row"} alignItems="center" sx={{ mt: "0px !important", gap: "5px" }} >
                      <Typography fontWeight={400} variant="subtitle2">{infoReg.sRegister_Name} </Typography>
                    </Stack>
                  </Stack>
                </Stack>

                <Stack direction={"row"} sx={{ gap: "1em" }}>
                  <Stack direction={"column"} justifyContent="center" sx={{ width: "200px", marginLeft: "20px" }}>
                    <Typography fontWeight={500} variant="subtitle1">
                      ชื่อผู้ประกอบการ
                    </Typography>
                  </Stack>
                  <Stack direction={"column"} flex={1}>
                    <Stack direction={"row"} alignItems="center" sx={{ mt: "0px !important", gap: "5px" }} >
                      <Typography fontWeight={400} variant="subtitle2"> {infoReg.sCompany_Name} </Typography>
                    </Stack>
                  </Stack>
                </Stack>

                <Stack direction={"row"} sx={{ gap: "1em" }}>
                  <Stack direction={"column"} justifyContent="center" sx={{ width: "200px", marginLeft: "20px" }}>
                    <Typography fontWeight={500} variant="subtitle1">
                      เบอร์โทรติดต่อ
                    </Typography>
                  </Stack>
                  <Stack direction={"column"} flex={1}>
                    <Stack direction={"row"} alignItems="center" sx={{ mt: "0px !important", gap: "5px" }} >
                      <Typography fontWeight={400} variant="subtitle2">{infoReg.sTel} </Typography>
                    </Stack>
                  </Stack>
                </Stack>
              </Stack>

            </Grid>

            <Grid container>
              <Grid item xs={12}>
                <Stack direction={"row"} alignItems="end" justifyContent="space-between">
                  <Typography color={"#03a9f4"} fontWeight={500} fontSize={18}>
                    ข้อมูลผู้ประกอบการ (Employer Information)

                  </Typography>
                </Stack>
                <Stack direction={"row"}  >
                  <Divider sx={{ width: "100%", backgroundColor: "#03a9f4" }} />
                </Stack>
              </Grid>
              <Grid item xs={12} marginTop={3}>
                {info.lstCompanyContent != null &&
                  info.lstCompanyContent.length > 0 ? (
                  <Content lstContent={info.lstCompanyContent} />
                ) : (
                  ""
                )}
              </Grid>
            </Grid>

          </Stack>
          <Grid
            item
            xs={12}
            justifyContent={"start"}
            marginTop={3}
            marginBottom={3}
          >
            <BtnBack
              txt="ย้อนกลับ"
              onClick={() => onBack()} />
          </Grid>
        </Stack>
      </Grid>
    </Grid>
  );
};
export default AdminCompanyInfo;
