import React, { useEffect, useRef, useState } from 'react'
import { Modal, Box, Tooltip, CircularProgress, Grid, Typography, typographyClasses } from "@mui/material"
import { Close } from "@mui/icons-material"
import { i18n } from 'i18n';
import { useForm, FormProvider } from "react-hook-form"
import { AxiosGet, AxiosPost } from 'service/CommonFunction/TS_function';
import { useDispatch } from 'react-redux';
import { DialogActionCreators } from 'store/redux/DialogAlert';
import { BtnBack, BtnSave } from "components/Common/Button"
import { JobPostSentGroupStatus } from 'service/CommonInterface/CommonInterface';
import AutoCompleteRenderItem from 'components/Common/ElementInForm/AutoCompleteRenderItem';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup"
import yupFormSchemas from 'components/Common/yup/yupFormSchemas';

import { FnBlock_UI } from 'service/SystemFunction/SystemFunction';
export const sTh = {
    sTag: "เพิ่มแท็ก",
    sTagFavorite: "เพิ่มแท็กที่ใช้บ่อย",
    sTagSubmit: "คุณต้องการเพิ่มแท็กใช่หรือไม่ ?",
    sTagFavoriteSubmit: "คุณต้องการเพิ่มแท็กที่ใช้บ่อยใช่หรือไม่ ",
    sGuideTag: "พิมพ์แล้วกด Enter ทุกครั้งก่อนกดบันทึก",
    Save: "บันทึก"
}

export const sEn = {
    sTag: "Add label",
    sTagFavorite: "Add favorite label",
    sTagSubmit: "Do you want to add label ?",
    sTagFavoriteSubmit: "Do you want to add favorite label ?",
    sGuideTag: "Please type words and click Enter every time before clicking SAVE",
    Save:"Save"
}
export default function ModalAddTag({ isOpen, onClose, arrSelectJobPost, isBookmark = false }) {
    const i18nField = "JobSeekerRegisJob.ModalAddTag."
    const objSchema = {
        arrTag: yupFormSchemas.stringArray(i18n(`${i18nField}sTag`), { required: true }),
    };
    const { BlockUI, UnBlockUI } = FnBlock_UI();
    const schema = yup.object().shape(objSchema);
    const form = useForm({
        defaultValues: { arrTag: [] },
        resolver: yupResolver(schema),
        shouldFocusError: true
    });
    const dispatch = useDispatch();
    const ControllerApi = "JobSeekerRegisJob"
    const [optionTag, setOptionTag] = useState([])
    // const [isLoading, setLoading] = useState(false);
    const styles = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        borderRadius: "10px",
        p: 2,
        "@media screen and (min-width:640px)": {
            width: 600,
        }
    };
    const onSubmit = (e) => {
        dispatch(DialogActionCreators.OpenDialogSubmit(!isBookmark ? i18n(`${i18nField}sTagSubmit`) : i18n(`${i18nField}sTagFavoriteSubmit`), () => {
            dispatch(DialogActionCreators.CloseDialogSubmit())
            dispatch(DialogActionCreators.LoadSubmit(true))
            let objReq = {
                nStatus: 0,
                nReqFromStatus: JobPostSentGroupStatus.Shortlist,
                arrJobPost: arrSelectJobPost,
                arrStringTag: e.arrTag,
                isBookmark: isBookmark
            }
            AxiosPost(`${ControllerApi}/OnSaveTag`, objReq, (res) => {
                dispatch(DialogActionCreators.LoadSubmit(false))
                dispatch(DialogActionCreators.OpenDialogSuccess("เพิ่มแท็กสำเร็จ​", () => {
                    onClose();
                }) as any)
            }, (err) => {
                dispatch(DialogActionCreators.LoadSubmit(false))
                dispatch(DialogActionCreators.OpenDialogWarning(err.Message, () => {
                    onClose();
                }) as any)
            })
        }))
    }

    useEffect(() => {
        if (!isBookmark && isOpen) {
            BlockUI()
            AxiosGet(`${ControllerApi}/OnGetTagFavorire`, {}, (res) => {
                setOptionTag(res.Data)
            }, (err) => {
                dispatch(DialogActionCreators.OpenDialogWarning(err.Message, () => {
                    onClose();
                }) as any)
            }, UnBlockUI)
        } else if (!isOpen) {
            form.reset();
        }
    }, [isOpen])


    return (
        <Modal
            open={isOpen}
            onClose={() => {
                onClose()
            }}
            closeAfterTransition
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Box sx={{ ...styles, width: 350, maxHeight: "80vh", overflow: "hidden" }} className={" flex flex-col"} >
                <div className="flex flex-row ">
                    <div className="flex  ">
                        <Typography variant='h6' >{!isBookmark ? i18n(`${i18nField}sTag`) : i18n(`${i18nField}sTagFavorite`)}</Typography>
                    </div>
                    <div className="flex flex-[1] justify-end  ">
                        <Tooltip title={i18n(`common.close`)}>
                            <div onClick={() => {
                                onClose()
                            }} className='rounded-[50%] bg-[#d1d1d1] w-[1.5em] h-[1.5em] flex cursor-pointer ease-linear duration-[100ms]  hover:bg-[#8c8c8c]'>
                                <Close fontSize='small' className='m-[auto] text-[white]' />
                            </div>
                        </Tooltip>
                    </div>
                </div>
                <div className='flex flex-col gap-[10px] py-[1em] overflow-hidden'>
                    <FormProvider {...form}>
                        <AutoCompleteRenderItem isFreeCreate fullWidth isClearable options={optionTag} label={!isBookmark ? i18n(`${i18nField}sTag`) : i18n(`${i18nField}sTagFavorite`)} name='arrTag' />
                        <Typography sx={{ fontSize: "12px", color: "#897f72" }}>{i18n(`${i18nField}sGuideTag`)}</Typography>
                        <Grid container direction={"row"} justifyContent="center">
                            <BtnSave
                            txt={i18n(`${i18nField}Save`)}
                                onClick={form.handleSubmit((e) => {
                                    onSubmit(e)
                                })}
                            />
                        </Grid>

                    </FormProvider>
                </div>
            </Box>
        </Modal>
    )
}
