import React, { useEffect, useState } from "react";
import {
    DataGridMui as Table,
    initRows,
    PaginationInterface,
    FilterFieldInterface
} from "components/Common/Table/DataGridMui";
import { GridColumns } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { BtnEditOnTable, BtnTimeTable, BtnDeleteTable, BtnViewOnTable } from "components/Common/Button"
import { useDispatch } from "react-redux"
import { AxiosPost, GetPermission, ResultAPI } from "service/CommonFunction/TS_function";
import { useForm } from "react-hook-form";
import { i18n } from "i18n";
import { DialogActionCreators } from "store/redux/DialogAlert";
import { TrendingUp } from "@mui/icons-material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { icon } from "@fortawesome/fontawesome-svg-core";
import TransitionsModal from "./ModalLogHold";
import { string } from "prop-types";
import ModalHoldCancle from "./ModalCancleHold";
import { Tooltip } from "@mui/material";
import secureLocalStorage from 'react-secure-storage';


export default function HoldPackageMasterTable() {
    const i18nDialog = 'dialog';
    const dispatch = useDispatch();
    const history = useNavigate();
    const [nPermission, setPermission] = useState(0);
    const [loadingTable, setLoadingTable] = useState(true);
    const [time, settime] = useState(false);
    const [sId, setJsId] = useState("");
    const [Status, setStatus] = useState([]);




    let arrrow = {
        ...initRows,
        sSortExpression: "nNo",
        sSortDirection: "asc"
      }
      const sPathName = window.location.pathname;
      const sStore = secureLocalStorage.getItem(sPathName);
      if (sStore && sStore !== "") {
        const objRowTemp = JSON.parse(sStore + "");
        if (objRowTemp != null) {
          arrrow = objRowTemp;
          arrrow.arrRows = [];
        }
      }
      const [dataRow, setDataRow] = useState<PaginationInterface>(arrrow);
    // const [dataRow, setDataRow] = useState<PaginationInterface>({
    //     ...initRows,
    //     sSortExpression: "nNo",
    //     sSortDirection: "asc"
    // });


    // filter
    const filter: FilterFieldInterface[] = [
        { sTypeFilterMode: "input", sFieldName: "sCompany_Name" },
        { sTypeFilterMode: "input", sFieldName: "sOrder_ID" },
        { sTypeFilterMode: "input", sFieldName: "sOrder_Name" },
        { sTypeFilterMode: "daterange", sFieldName: "sDuePackage" },
        { sTypeFilterMode: "daterange", sFieldName: "sStart_Date" },
        { sTypeFilterMode: "daterange", sFieldName: "sStartUnhold_Date" },
      // { sTypeFilterMode: "input", sFieldName: "sStatus" },
      {
        sTypeFilterMode: "select", sFieldName: "sStatus", lstDataSelect:
          [...Status],
      },
        { sTypeFilterMode: "daterange", sFieldName: "sExpiry_Date" },
    ];

    const form = useForm({
        shouldUnregister: false,
        shouldFocusError: true,
        mode: "all",
    });

    useEffect(() => {
        GetPermission(50, setPermission)
        loadData(dataRow);

    }, []);

    const loadData = (p: PaginationInterface) => {
        setLoadingTable(true)
        AxiosPost("HoldPackage/SearchData_HoldPackaagedetail", p, (result) => {
            setLoadingTable(false)
            settime(result.lstData.isTime);
            setStatus(result.lstFilterStatus);
         
            setDataRow({
                ...p,
                arrRows: result.lstData,
                nDataLength: result.nDataLength,
                nPageIndex: result.nPageIndex,

            });
        });
    };

    const OnDelete = (e) => {
        let item = dataRow.arrRows.find(f => f.sID === e);
        let sDate = "";
        let sID = e
        if (item) {

            sDate = item.sStartUnhold_Date;
        }
        //  dispatch(DialogActionCreators.OpenDialogSubmit(i18n(`common.AlertConfrimDel`), () => {
        dispatch(DialogActionCreators.OpenDialogSubmit(`การลบรายการจะมีผลต่อวันเปิดใช้งานแพ็กเกจ วันที่เปิดใช้งานแพ็กเกจปัจจุบัน:${sDate}`, () => {
            dispatch(DialogActionCreators.CloseDialogSubmit() as any);
            dispatch(DialogActionCreators.LoadSubmit("Waiting...") as any);

            AxiosPost(`HoldPackage/RemoveDataTable`, { sID: sID }, (result) => {
                dispatch(DialogActionCreators.CloseDialogSubmit() as any);
                ResultAPI(result, i18n(`${i18nDialog}.DialogRemoveSuccess`), () => {
                    dispatch(DialogActionCreators.OpenDialogSuccess(i18n(`${i18nDialog}.DialogRemoveSuccess`)) as any)
                    loadData(dataRow);
                });
            }, (err) => {
                if (!err.response) {
                    history("/error", null)
                    return;
                }
            })
        }) as any)
    }



    //สร้างตาราง
    const dataColumn: GridColumns = [
       
          {
            headerName: "",
            field: "ลบ/ยกเลิก",
            type: "actions",
            width: 50,
            resizable: false,
            sortable: false,
            getActions: (item) => {
                return [
                    (
                        <BtnDeleteTable
                            IsDisabled={nPermission !== 2 || item.row.isCancle === true  || item.row.nStatus === 15 }
                            txt="ลบ/ยกเลิก"
                            onClick={form.handleSubmit(e => { onSubmit(item.row.sID, item.row) })}
                        />
                    )
                ];
            },
        },
      
        {
            headerName: "",
            field: "ปุ่ม (เพิ่ม/แก้ไข)",
            type: "actions",
            width: 50,
            resizable: false,
            sortable: false,
            getActions: (item) => {
                if (item.row.nStatus === 21 || item.row.nStatus === 15) {
                    return [
                        <BtnViewOnTable
                            IsDisabled={nPermission !== 2}
                            txt="ดู" //Edit
                            onClick={() => goToEdit(item.row.sID, item.row)}
                        />
                    ];
                } else {
                    return [
                        <BtnEditOnTable
                            IsDisabled={nPermission !== 2}
                            txt="แก้ไข" //Edit
                            onClick={() => goToEdit(item.row.sID, item.row)}
                        />
                    ];
                }
              
            },
        },
        {
            field: "nNo",
            headerName: "ที่",
            headerAlign: "center",
            align: "center",
            resizable: false,
            sortable: true,
            width: 50,
        },
        {
            field: "sCompany_Name",
            headerName: "ผู้ประกอบการ",
            headerAlign: "center",
            align: "left",
            resizable: false,
            sortable: true,
            flex: 1,
            minWidth: 250,
        },
        {
            field: "sOrder_ID",
            headerName: "เลขที่ใบสั่งซื้อ",
            headerAlign: "center",
            align: "left",
            resizable: false,
            sortable: true,
            flex: 1,
            minWidth: 150,
        },
        {
            field: "sOrder_Name",
            headerName: "แพ็กเกจ",
            headerAlign: "center",
            align: "left",
            resizable: false,
            sortable: true,
            flex: 1,
            minWidth: 250,
            renderCell: (item) => {
                return [
                    (
                        <Tooltip placement="top" title={item.row.sOrder_Name}>
                            <div id={item.row.sID}
                                style={{
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    width: '300px',
                                }}>
                                {item.row.sOrder_Name}
                            </div>

                        </Tooltip>
                    )
                ];
            }
        },

        {
            field: "sDuePackage",
            headerName: "Due Package",
            headerAlign: "center",
            align: "center",
            resizable: false,
            sortable: true,
            flex: 1,
            minWidth: 170,
        },
        {
            field: "sStart_Date",
            headerName: "Hold Package",
            headerAlign: "center",
            align: "center",
            resizable: false,
            sortable: true,
            flex: 1,
            minWidth: 170,
        },
        {
            field: "sStartUnhold_Date",
            headerName: "Start Package",
            headerAlign: "center",
            align: "center",
            resizable: false,
            sortable: true,
            flex: 1,
            minWidth: 170,
        },
        {
            field: "sExpiry_Date",
            headerName: "วันที่หมดอายุแพ็กเกจ",
            headerAlign: "center",
            align: "center",
            resizable: false,
            sortable: true,
            flex: 1,
            minWidth: 170,
        },
        {
            field: "sStatus",
            headerName: "สถานะ",
            headerAlign: "center",
            align: "left",
            resizable: false,
            sortable: true,
            flex: 1,
            minWidth: 170,
        },
        {
            headerName: "",
            field: "",
            type: "actions",
            width: 50,
            resizable: false,
            sortable: false,
            getActions: (item) => {

                let stime = dataRow.arrRows.filter(f => f.nHold_ID === item.row.nHold_ID)[0];
                return [
                    (
                        <BtnTimeTable
                            IsDisabled={item.row.isHistory === false}
                            txt={"ประวัติ"}
                            onClick={() => Download(item.row.sOrder, item.row)}
                        />
                    )
                ];
            }
        },

    ];

    const [isOpen, setIsOpen] = useState(false)
    const [sOrder, setOrder] = useState("");
    const onSubmit = (sID: string, item: any) => {
        setIsOpen(true);
        setJsId(sID)
        setOrder(sId)
    };

    const goToEdit = (sID: string, item: any) => {
        history(`/HoldPackageAdd?sID=${encodeURI(sID)}`);
    };
    const [isOpenModalDownload, setIsOpenModalDownload] = useState(false)

    const Download = (sId: string, item: any) => {
        setIsOpenModalDownload(true);
        setJsId(sId)
    };


    return (
        <>
            <Table
                isLoading={loadingTable}
                filterField={filter}
                Rows={dataRow}
                Columns={dataColumn}
                OnLoadData={(p) => loadData(p)}
            // isShowCheckBox={true}
            //  onDelete={OnDelete}
            />
            <TransitionsModal isOpen={isOpenModalDownload} setOpen={setIsOpenModalDownload} sId={sId} />
            <ModalHoldCancle isOpen={isOpen} setOpen={setIsOpen} sID={sId} sOrder={sOrder} setOrder={setOrder} />
        </>
    );
}