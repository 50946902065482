import Layout_Font from "layout/CommonLayout/Layout_Font";
import MyJobInvitation from "view/font/MyJob/MyJobInvitation";
import MyJobViewed from "view/font/MyJob/MyJobViewed";
import MyJobInterested from "view/font/MyJob/MyJobInterested";
import MyJobApplied from "view/font/MyJob/MyJobApplied";
import MyJob from "view/font/MyJob/MyJobBoard/MyJob";

const MyJobRoute = [
    {
      path: "/MyJobInvitation",
      component: MyJobInvitation,
      layout: Layout_Font,
      exact: true,
    },
    {
      path: "/MyJobViewed",
      component: MyJobViewed,
      layout: Layout_Font,
      exact: true,
    },
    {
      path: "/MyJobInterested",
      component: MyJobInterested,
      layout: Layout_Font,
      exact: true,
    },
    {
      path: "/MyJobApply",
      component: MyJobApplied,
      layout: Layout_Font,
      exact: true,
    },
    {
      path: "/MyJob",
      component: MyJob,
      layout: Layout_Font,
      exact: true,
    },
  ];
  
  export default MyJobRoute;