import { Grid, Box, Hidden, Skeleton } from "@mui/material";

export default function SkeletonJobTab() {
  return (
    <>
      {/* Desktop */}
      <Grid item md={3} xs={12} sx={{ pb: 1 }}>
        <Skeleton
          height={"80vh"}
          width={"100%"}
          sx={{ borderRadius: "20px" }}
        />
      </Grid>
      <Grid item md={3} xs={12} sx={{ pb: 1 }}>
        <Skeleton
          height={"80vh"}
          width={"100%"}
          sx={{ borderRadius: "20px" }}
        />
      </Grid>
      <Grid item md={3} xs={12} sx={{ pb: 1 }}>
        <Skeleton
          height={"80vh"}
          width={"100%"}
          sx={{ borderRadius: "20px" }}
        />
      </Grid>
      <Grid item md={3} xs={12} sx={{ pb: 1 }}>
        <Skeleton
          height={"80vh"}
          width={"100%"}
          sx={{ borderRadius: "20px" }}
        />
      </Grid>
    </>
  );
}
