import {
  AppBar,
  Autocomplete,
  Button,
  Checkbox,
  Chip,
  Dialog,
  Divider,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  Radio,
  Skeleton,
  Stack,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import { getLanguage, i18n } from "i18n";
import { FormProvider, useForm } from "react-hook-form";
import yupFormSchemas from "components/Common/yup/yupFormSchemas";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { FC, useEffect, useRef, useState } from "react";
import {
  BtnApprove,
  BtnBack,
  BtnCancel,
  BtnPreview,
  BtnSaveDraft,
  BtnSubmit,
} from "components/Common/Button";
import Input from "components/Common/ElementInForm/InputCustomLabelFormItem";
import BoxContent from "components/Banner/AdminContent/BoxContent";
import { dataContent } from "components/Banner/AdminContent/ManageContent";
import { Add } from "@mui/icons-material";
import AutoCompleteSelect from "components/Common/ElementInForm/AutoCompleteSelect";
import DatePickerFormItem from "components/Common/ElementInForm/DatePickerFormItem";
import { AxiosGet, AxiosPost } from "service/CommonFunction/TS_function";
import { useDispatch } from "react-redux";
import { DialogActionCreators } from "store/redux/DialogAlert";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import DetailJob from "components/JobDetail/DetailJob";
import DetailCompany from "components/JobDetail/DetailCompany";
import CloseIcon from "@mui/icons-material/Close";
import MultiSelect from "components/Common/ElementInForm/MultiSelectWithChip"
import InputNumber from "components/Common/ElementInForm/InputNumber";
import secureLocalStorage from "react-secure-storage";

const i18nForthisPage = "jobPost.ModifyPage.";
const i18nForValidate = `${i18nForthisPage}validate.`;
const ControllerAPI = "JobPost";
const JobPostModify: FC<{}> = () => {
  const dispatch = useDispatch();
  const history = useNavigate();
  const language = secureLocalStorage.getItem("language").toString();
  const tempAppManagementAndSumForJob = {
    nID: 1,
    sValue: "",
    IsNew: true,
    IsDelete: false,
  };
  const lstTempYear = Array.from(Array(22).keys()).map((item) => ({
    value: item.toString(),
    label: item === 0 ? (i18n(`${i18nForValidate}minYearStart`)) : item === 21 ? (i18n(`${i18nForValidate}maxYearEnd`)) : item,
  }));

  const tempJobFunction = {
    nID: 1,
    value: { sJobFunctionID: "", sJobDetailID: "" },
    IsNew: true,
    IsDelete: false,
  };

  const location = useLocation();
  const searchParams = location.search;
  const sID = new URLSearchParams(searchParams).get('sID');
  const isEdit = sID ? true : false;

  //#region State
  const [objOptionSelect, setOptionSelect] = useState({
    lstOptPackage: [],
    lstOptPackageNoExpire: [],
    lstOptJobFunction: [],
    lstOptJobDetail: [],
    lstOptIndustry: [],
    lstOptWorkLocation: [],
    lstOptTraveling: [],
    lstOptEmploymentType: [],
    lstOptSaralyInfo: [],
    lstOptBenefit: [],
    lstOptCareerLevel: [],
    lstOptEducationLevel: [],
    lstOptTag: [],
    lstOptCountry: [],
    lstOptOtherInfo: []
  });
  const [lstAppManagement, setAppManagement] = useState([]);
  const [lstSumPointForJob, setSumForJob] = useState([]);
  const [lstJobFunction, setJobFunction] = useState([]);
  const [lstTag, setTag] = useState([]);
  const [sTimeWorkID, setTimeWorkID] = useState("1");
  const [isJobSeekerAllow, setIsJobSeekerAllow] = useState(false);
  const [isURL, setIsURL] = useState(false);
  const [lstContent, setLstContent] = useState<dataContent[]>([]);
  const [objStateContent, setObjStateContent] = useState({});
  const [lstOrder, setLstOrder] = useState<number[]>([]);
  const [isAddAction, setIsAddAction] = useState(false);
  const [isChangeOrder, setIsChangeOrder] = useState(false);
  const [isOpenDialogPreview, setOpenDialogPreview] = useState(false);
  const [overMax, setOverMax] = useState(false);
  const [rawIndustry, setRawIndustry] = useState<any>([]);
  const [rawCountry, setRawCountry] = useState<any>([]);
  const [rawRegion, setRawRegion] = useState<any>([]);
  const [rawProvince, setRawProvince] = useState<any>([]);
  const [FrequencyData, setFrequency] = useState<any>([]);
  const [IsInter, setIsInter] = useState<boolean>(false);
  const [ProvinceParent, setProvinceParent] = useState(0);
  const [showDelete, setShowDelete] = useState(false);
  const [state, setState] = useState({});
  const [isCancelStatus, setCancelStatus] = useState(false);
  const [isDeleteStatus, setDeleteStatus] = useState(false);
  const [nStatusNow, setStatusNow] = useState(0);

  const [jobPostPreview, setJobPostPreview] = useState({
    sJob_TH: "",
    sJob_EN: "",
    lstJobContent: [],
    lstJobPoint: [],
    sCompanyFile_Logo: "",
    lstCareerLevel_TH: [],
    lstCareerLevel_EN: [],
    sYearsExperience: "",
    lstEducation_TH: [],
    lstEducation_EN: [],
    sIndustry_TH: "",
    sIndustry_EN: "",
    sSalaryRange: "",
    sSalaryType_TH: "",
    sSalaryType_EN: "",
    lstOtherInfo_TH: [],
    lstOtherInfo_EN: [],
    lstJobBenefit_TH: [],
    lstJobBenefit_EN: [],
    lstEmploymentType_TH: [],
    lstEmploymentType_EN: [],
  });
  const [companyDetail, setCompanyDetail] = useState({
    sLinkLogo: "",
    lstContent: [],
  });
  const [isLoading, setLoading] = useState(true);
  const [isDisabled, setDisabled] = useState(false);
  const [showFrequency, setShowFrequency] = useState(false);
  const [ShowEdit, setShowEdit] = useState(false);
  const [isDraft, setIsDraft] = useState(false);
  const [lstCheck, setLstCheck] = useState([]);
  const [isDisablePackage, setIsDisablePackage] = useState(false);
  const [isRequirePackage, setRequirePackage] = useState(false)
  //#endregion

  //#region Form & schema
  const objSchema = {
    sPackageID: yupFormSchemas.string(i18n(`${i18nForValidate}package`), {
      required: true || isRequirePackage,
    }),
    sTitle_en: yupFormSchemas.string(i18n(`${i18nForValidate}jobTitleEn`), {
      required: true,
    }),
    sTitle_th: yupFormSchemas.string(i18n(`${i18nForValidate}jobTitleTh`), {
      required: true,
    }),
    sIndustryID: yupFormSchemas.string(i18n(`${i18nForValidate}industry`), {
      required: true,
    }),
    dPostDate: yupFormSchemas.string(i18n(`${i18nForValidate}postDate`), {
      required: true,
    }),
    sSalaryTypeID: yupFormSchemas.string(i18n(`${i18nForValidate}salaryType`), {
      required: true,
    }),
    sSalaryStart: yupFormSchemas.string(i18n(`${i18nForValidate}salaryStart`), {
      required: true,
      min: 0,
    }),
    sSalaryEnd: yupFormSchemas.string(i18n(`${i18nForValidate}salaryEnd`), {
      required: true,
    }),
    sCareerID: yupFormSchemas.stringArray(i18n(`${i18nForValidate}careerLevel`), {
      required: true,
    }),
    sEducationLevelID: yupFormSchemas.stringArray(
      i18n(`${i18nForValidate}minimumEducation`),
      {
        required: true,
      }
    ),
    sYearExpStart: yupFormSchemas.string(
      i18n(`${i18nForValidate}minumumYearExpStart`),
      {
        required: true,
      }
    ),
    sYearExpEnd: yupFormSchemas.string(
      i18n(`${i18nForValidate}minumumYearExpEnd`),
      {
        required: true,
      }
    ),
    sTimeWorkOther: yupFormSchemas.string(
      i18n(`${i18nForValidate}timeWorkOtherTxtBox`),
      {
        required: true,
      }
    ),
    nCountryID: yupFormSchemas.object(i18n(`${i18nForValidate}Country`), {
      required: true,
    }),
  };

  const [objSchemaContext, setSchemaContext] = useState(objSchema);
  const schema = yup.object().shape(objSchemaContext);
  const form = useForm({
    resolver: yupResolver(schema),
    shouldUnregister: false,
    shouldFocusError: true,
    mode: "all",
    defaultValues: {
      sEducationLevelID: [],
      sCareerID: [],
      sOtherInfo: []
    } as any
  });
  //#endregion

  //#region Event handle
  const onGetIndustry = () => {
    let lang = "";
    let isTH = (getLanguage().id === "th");
    isTH ? lang = "th" : lang = "en"
    AxiosGet(`DataFilter/SetFilter_SelectIndustrytype`, { lang }, (res) => {
      setRawIndustry(res.lstOptionAdmin || []);
    });
  };

  const onGetCountry = () => {
    let lang = "";
    let isTH = (getLanguage().id === "th");
    isTH ? lang = "th" : lang = "en"
    AxiosGet(`DataFilter/SetFilter_SelectCountry`, { lang }, (res) => {
      setRawCountry(res.lstOptionAdmin || []);

      form.setValue('nCountryID', res.lstThailand[0]);
    });
  };

  const onGetRegion = () => {
    let lang = "";
    let isTH = (getLanguage().id === "th");
    isTH ? lang = "th" : lang = "en"
    AxiosGet(`DataFilter/SetFilter_SelectRegion`, { lang }, (res) => {
      setRawRegion(res.lstOptionAdmin);
    });
  };

  const onGetProvince = () => {
    let lang = "";
    let isTH = (getLanguage().id === "th");
    isTH ? lang = "th" : lang = "en"
    AxiosGet(`DataFilter/SetFilter_SelectProvince`, { lang }, (res) => {
      setRawProvince(res.lstOptionAdmin);
    });
  };

  const onGetFrequency = () => {
    let lang = "";
    let isTH = (getLanguage().id === "th");
    isTH ? lang = "th" : lang = "en"
    AxiosGet(`DataFilter/SetFilter_Frequency`, { lang }, (res) => {
      setFrequency(res.lstOptionFrequency);
    });
  };

  const AddComponent = () => {
    const maxId =
      lstContent.length > 0
        ? lstContent.reduce(
          (max, objItem) => (objItem.id > max ? objItem.id : max),
          lstContent[0].id
        )
        : 0;

    let objDataComponent: dataContent = {
      nType: 1,
      sContent: "",
      fFileImageOrVideo: [],
      id: maxId + 1,
      nOrder: lstOrder.length + 1,
    };
    let objContent = {};
    objContent[`nTypeContent_${objDataComponent.id}`] = 1;
    setIsAddAction(true);
    setObjStateContent({ ...objStateContent, ...objContent });
    setLstContent([...lstContent, objDataComponent]);
    setLstOrder([...lstOrder, lstOrder.length + 1]);
  };

  const AddAppManagementEmail = () => {
    let newData = { ...tempAppManagementAndSumForJob };
    let maxID = Math.max(...lstAppManagement.map((item) => item.nID));
    newData.nID = maxID < 1 ? 1 : maxID + 1;
    setAppManagement([...lstAppManagement, { ...newData }]);
  };

  const AddSumPointForJob = (nRow = 1) => {
    if (nRow == 1) {
      let newData = { ...tempAppManagementAndSumForJob };
      let maxID = Math.max(...lstSumPointForJob.map((item) => item.nID));
      newData.nID = maxID < 1 ? 1 : maxID + 1;

      setSumForJob([...lstSumPointForJob, { ...newData }]);
    } else {
      let arrPoint = [] as any;
      for (let index = 0; index < nRow; index++) {
        let newData = { ...tempAppManagementAndSumForJob };
        let maxID = Math.max(...arrPoint.map((item) => item.nID));
        newData.nID = maxID < 1 ? 1 : maxID + 1;
        arrPoint.push(newData);
      }
      setSumForJob([...lstSumPointForJob, ...arrPoint]);
    }
  };

  const AddJobFunction = () => {
    let newData = { ...tempJobFunction };
    let maxID = Math.max(...lstJobFunction.map((item) => item.nID));
    newData.nID = maxID < 1 ? 1 : maxID + 1;

    setJobFunction([...lstJobFunction, { ...newData }]);
  };

  const onDeleteEmail = (nID) => {
    dispatch(
      DialogActionCreators.OpenDialogSubmit(
        i18n(`common.AlertConfrimDel`),
        (c) => {
          let objData = lstAppManagement.find((f) => f.nID === nID);
          if (objData) {
            objData.IsDelete = true;
          }
          setAppManagement([...lstAppManagement]);
          dispatch(
            DialogActionCreators.OpenDialogSuccess(
              i18n(`common.msgAlertDelSuccess`),
              () => {
                dispatch(DialogActionCreators.LoadSubmit(false) as any);
              }
            ) as any
          );
        }
      ) as any
    );
  };

  const onDeleteSumPoint = (nID) => {
    dispatch(
      DialogActionCreators.OpenDialogSubmit(
        i18n(`common.AlertConfrimDel`),
        (c) => {
          let objData = lstSumPointForJob.find((f) => f.nID === nID);
          if (objData) {
            objData.IsDelete = true;
          }
          setSumForJob([...lstSumPointForJob]);
          dispatch(
            DialogActionCreators.OpenDialogSuccess(
              i18n(`common.msgAlertDelSuccess`),
              () => {
                dispatch(DialogActionCreators.LoadSubmit(false) as any);
              }
            ) as any
          );
        }
      ) as any
    );
  };

  const onDeleteJobDesc = (nID) => {
    dispatch(
      DialogActionCreators.OpenDialogSubmit(
        i18n(`common.AlertConfrimDel`),
        (c) => {
          let objData = lstJobFunction.find((f) => f.nID === nID);
          if (objData) {
            objData.IsDelete = true;
            setOverMax(false);
          }
          setJobFunction([...lstJobFunction]);
          dispatch(
            DialogActionCreators.OpenDialogSuccess(
              i18n(`common.msgAlertDelSuccess`),
              () => {
                dispatch(DialogActionCreators.LoadSubmit(false) as any);
              }
            ) as any
          );
        }
      ) as any
    );
  };

  const InitialOnPageLoad = async () => {
    setState({
      name: 'Jhon',
      surname: 'Doe',
    })

    if (!isEdit) {
      onGetCountry();
      onGetRegion();
      onGetProvince();
      onGetIndustry();
      onGetFrequency();
      AddSumPointForJob(3);
      AddAppManagementEmail();
      AddJobFunction();
      AddComponent();

      form.register("lstEmploymentType");
      form.setValue("lstEmploymentType", []);

      form.register("lstBenifits");
      form.setValue("lstBenifits", []);

      setLoading(false);
      setShowDelete(false)
    } else {
      await getDataDetail();
    }
  };

  const onDeactivate = async (values, nStatusID) => {
    if (checkValidate()) {
      let lstDataContent = [];
      lstContent.forEach((f, indx) => {
        let objDataContent = {
          nID: f.id,
          nTypeID: objStateContent[`nTypeContent_${f.id}`],
          nOrder: indx + 1,
          sContent: "",
          fImageOrVideo: {},
          sUrl: "",
        };
        switch (objStateContent[`nTypeContent_${f.id}`]) {
          case 1:
            objDataContent.sContent = objStateContent[`sContentOnly_${f.id}`];
            break;
          case 2:
            objDataContent.fImageOrVideo =
              objStateContent[`fFileImg_${f.id}`][0];
            objDataContent.sContent =
              objStateContent[`sContentBottomImg_${f.id}`];
            break;
          case 3:
            objDataContent.fImageOrVideo =
              objStateContent[`fFileVideo_${f.id}`][0];
            objDataContent.sContent =
              objStateContent[`sContentBottomVideo_${f.id}`];
            break;
          case 4:
            objDataContent.sUrl = objStateContent[`sUrl${f.id}`];
            f.sContent = objStateContent[`sContentBottomUrl_${f.id}`];
            break;
        }
        lstDataContent.push(objDataContent);
      });



      if (nStatusID == 2 && moment(values.dPostDate).valueOf() > moment(Date.now()).valueOf()) {
        nStatusID = 3;
      }

      let param = {
        sTimeWorkOther: "",
        ...values,
        sJobPostID: isEdit ? sID : null,
        sPostDate: "",
        sExpireDate: "",

        dPostDate: values.dPostDate ? moment(values.dPostDate).set({ "hour": 23, "minute": 59, "second": 59 }).valueOf() : null,
        dExpireDate: values.dExpireDate ? moment(values.dExpireDate).set({ "hour": 23, "minute": 59, "second": 59 }).valueOf() : null,
        nCountryID: form.getValues("nCountryID") ? form.getValues("nCountryID").value : 0,
        nRegionID: form.getValues("nRegionID") ? form.getValues("nRegionID").value : 0,
        nProvinceID: form.getValues("nProvinceID") ? form.getValues("nProvinceID").value : 0,
        sBenefitDesc: form.getValues("sBenefitDesc") || null,
        sEducationLevelID: form.getValues("sEducationLevelID") || 0,
        sCareerID: form.getValues("sCareerID") || 0,
        sOtherInfo: form.getValues("sOtherInfo") || 0,
        nFrequencyID: form.getValues("nFrequencyID") ? (form.getValues("nFrequencyID").value) : 0,
        sSalaryStart: form.getValues("sSalaryStart") + "" || 0 + "",
        sSalaryEnd: form.getValues("sSalaryEnd") + "" || 0 + "",
        lstContent: lstDataContent,
        lstAppManagement,
        lstSumPointForJob,
        lstJobFunction,
        lstTag,
        sTimeWorkID,
        isJobSeekerAllow,
        isURL,
        nStatusID,
      };

      if (!showFrequency) {
        if (parseInt(form.getValues("sYearExpStart")) > parseInt(form.getValues("sYearExpEnd"))) {
          dispatch(
            DialogActionCreators.OpenDialogWarning(
              i18n(`${i18nForthisPage}form.label.yearExp`)
            ) as any //year exp start cannot greater than year exp end
          );
        } else if (parseInt(form.getValues("sSalaryStart")) > parseInt(form.getValues("sSalaryEnd"))) {
          dispatch(
            DialogActionCreators.OpenDialogWarning(
              i18n(`${i18nForthisPage}form.label.SalaryValid`) //start salary cannot greater than end salary
            ) as any
          );
        }
      } else {
        dispatch(
          DialogActionCreators.OpenDialogSubmit(
            i18n(`common.AlertDeactivatePostJob`),
            async (c) => {
              dispatch(DialogActionCreators.LoadSubmit(true) as any);
              await AxiosPost(
                `${ControllerAPI}/CreateUpdateJobPost`,
                param,
                (res) => {
                  if (res.Status === 200) {
                    dispatch(
                      DialogActionCreators.OpenDialogSuccess(
                        i18n(`common.msgAlertSuccess`),
                        () => goBack()
                      ) as any
                    );
                  }
                },
                null,
                () => {
                  dispatch(DialogActionCreators.LoadSubmit(false) as any);
                }
              );
            }
          ) as any
        );
      }
    }
  };

  const onSubmit = async (values, nStatusID, nDuplicateType) => {
    let sTitle = (nStatusID == 1 && nDuplicateType == 1) ? i18n(`common.AlertDuplicatePostJob`) :
      (nStatusID == 1 && nDuplicateType == 0) ? i18n(`common.AlertSaveDraftPostJob`) ://AlertSaveDraftPostJob
        (nStatusID == 2 && nDuplicateType == 0) ? i18n(`common.AlertPublishPostJob`) ://AlertPublishPostJob
          (nStatusID == 2 && nDuplicateType == 2) ? i18n(`common.AlertBoostPostJob`) ://AlertBoostPostJob
            nStatusID == 3 ? i18n(`common.AlertPublishPostJob`) ://scheduled
              i18n(`common.AlertConfrimSave`);

    if (checkValidate()) {
      let lstDataContent = [];
      lstContent.forEach((f, indx) => {
        let objDataContent = {
          nID: f.id,
          nTypeID: objStateContent[`nTypeContent_${f.id}`],
          nOrder: indx + 1,
          sContent: "",
          fImageOrVideo: {},
          sUrl: "",
        };
        switch (objStateContent[`nTypeContent_${f.id}`]) {
          case 1:
            objDataContent.sContent = objStateContent[`sContentOnly_${f.id}`];
            break;
          case 2:
            objDataContent.fImageOrVideo =
              objStateContent[`fFileImg_${f.id}`][0];
            objDataContent.sContent =
              objStateContent[`sContentBottomImg_${f.id}`];
            break;
          case 3:
            objDataContent.fImageOrVideo =
              objStateContent[`fFileVideo_${f.id}`][0];
            objDataContent.sContent =
              objStateContent[`sContentBottomVideo_${f.id}`];
            break;
          case 4:
            objDataContent.sUrl = objStateContent[`sUrl${f.id}`];
            f.sContent = objStateContent[`sContentBottomUrl_${f.id}`];
            break;
        }
        lstDataContent.push(objDataContent);
      });
      let date = new Date;

      let postDateforStatus = moment(values.dPostDate).set({ "hour": date.getHours(), "minute": date.getMinutes(), "second": date.getSeconds() }).valueOf();

      let postDate = values.dPostDate ? moment(values.dPostDate).set({ "hour": date.getHours(), "minute": date.getMinutes(), "second": date.getSeconds() }).valueOf() : date.valueOf();

      if (nStatusID == 1 && nDuplicateType == 1) {
        postDate = moment(values.dPostDate).valueOf();
      }
      // var expaireDate = values.dExpireDate ? moment(values.dExpireDate).set({ "hour": 23, "minute": 59, "second": 59 }).valueOf() : date.valueOf()
      let expaireDate = values.dExpireDate ? moment(values.dExpireDate).set({ "hour": 23, "minute": 59, "second": 59 }).valueOf() : moment(date).add("29", "days").valueOf()//date.valueOf()

      // const dPostDate = values.dPostDate;
      // const now = Date.now();
      // const sdsdsdsd = moment(now).format('YYYY-MM-DD HH:mm:ss');
      // const dateNow = moment(Date.now()).valueOf();
      // const sjjjjj = moment(dateNow).format('YYYY-MM-DD HH:mm:ss');
      // const postDateforStatusMac = moment(postDateforStatus).format('YYYY-MM-DD HH:mm:ss');


      // let sddd = false;
      // if (postDateforStatus > dateNow) {
      //   sddd = true
      // }

      if (nStatusID == 2 && nDuplicateType != 2 && postDateforStatus > moment(Date.now()).valueOf()) {
        nStatusID = 3;
      }
      else if (nStatusID == 3 && postDateforStatus == moment(Date.now()).valueOf()) {
        nStatusID = 2;
      }

      let param = {
        sTimeWorkOther: "",
        ...values,
        sJobPostID: isEdit ? sID : null,
        sPostDate: "",
        sExpireDate: "",

        dPostDate: postDate,
        dExpireDate: expaireDate,
        nCountryID: form.getValues("nCountryID") ? form.getValues("nCountryID").value : 0,
        nRegionID: form.getValues("nRegionID") ? form.getValues("nRegionID").value : 0,
        nProvinceID: form.getValues("nProvinceID") ? form.getValues("nProvinceID").value : 0,
        sBenefitDesc: form.getValues("sBenefitDesc") || null,
        sEducationLevelID: form.getValues("sEducationLevelID") || 0,
        sCareerID: form.getValues("sCareerID") || 0,
        sOtherInfo: form.getValues("sOtherInfo") || 0,
        nFrequencyID: form.getValues("nFrequencyID") ? (form.getValues("nFrequencyID").value) : 0,
        sSalaryStart: form.getValues("sSalaryStart") + "" || 0 + "",
        sSalaryEnd: form.getValues("sSalaryEnd") + "" || 0 + "",
        lstContent: lstDataContent,
        lstAppManagement,
        lstSumPointForJob,
        lstJobFunction,
        lstTag,
        sTimeWorkID,
        isJobSeekerAllow,
        isURL,
        nStatusID,
        nDuplicateType, //check duplicate type if 2 = check boost post(use for modal text only), 1 = duplicate and 0 = not duplicate
        isEdit: isEdit,
      };
      ////console.log("param", param);


      let timeElapsed = Date.now();
      let today = new Date(timeElapsed);

      if (parseInt(form.getValues("sYearExpStart")) > parseInt(form.getValues("sYearExpEnd"))) {
        dispatch(
          DialogActionCreators.OpenDialogWarning(
            i18n(`${i18nForthisPage}form.label.yearExp`)
          ) as any //year exp start cannot greater than year exp end
        );
      } else if (parseInt(form.getValues("sSalaryStart")) > parseInt(form.getValues("sSalaryEnd"))) {
        dispatch(
          DialogActionCreators.OpenDialogWarning(
            i18n(`${i18nForthisPage}form.label.SalaryValid`) //start salary cannot greater than end salary
          ) as any
        );
      } else if (((moment(values.dPostDate).set({ "hour": 23, "minute": 59, "second": 59 }).valueOf() < today.valueOf()) || (postDate > expaireDate)) && nStatusNow === 1) {
        dispatch(
          DialogActionCreators.OpenDialogWarning(
            i18n(`${i18nForthisPage}form.label.DateLowerThanToday`) //start salary cannot greater than end salary
          ) as any
        );
      } else {
        dispatch(DialogActionCreators.OpenDialogSubmit(sTitle, async (c) => {
          dispatch(DialogActionCreators.LoadSubmit(true) as any);
          await AxiosPost(`${ControllerAPI}/CreateUpdateJobPost`, param, (res) => {
            if (res.Status === 200) {
              dispatch(DialogActionCreators.OpenDialogSuccess(i18n(`common.msgAlertSuccess`),
                () => goBack()
              ) as any);
            }
          },
            (err) => {
              if (err.Status === 400) {
                dispatch(DialogActionCreators.OpenDialogWarning(i18n(`${i18nForthisPage}validate.notcoin`), () => {
                }) as any)
                setLoading(false)
              } else if (err.Status === 402) { //package ถูกระงับ
                dispatch(DialogActionCreators.OpenDialogWarning(i18n(`${i18nForthisPage}validate.PackagePause`), () => {
                  setRequirePackage(true);
                  scroll();
                }) as any)
                setLoading(false)
              } else if (err.Status === 403) { //package ชะลอ
                dispatch(DialogActionCreators.OpenDialogWarning(i18n(`${i18nForthisPage}validate.PackageHold`), () => {
                  setRequirePackage(true);
                  scroll();
                }) as any)
                setLoading(false)
              } else if (err.Status === 405) { //package หมดอายุ
                dispatch(DialogActionCreators.OpenDialogWarning(i18n(`${i18nForthisPage}validate.PackageExpire`), () => {
                  setRequirePackage(true);
                  scroll();
                }) as any)
                setLoading(false)
              } else if (err.Status === 409) { //ประกาศงาหมด
                dispatch(DialogActionCreators.OpenDialogWarning(i18n(`${i18nForthisPage}validate.notcoin`), () => {
                  setRequirePackage(true);
                  scroll();
                }) as any)
                setLoading(false)
              }
            }
          );
        }
        ) as any
        );
      }
    }
  };

  const scroll = () => {
    setTimeout(() => {
      document.getElementById("scroll_Top").scrollIntoView({
        behavior: "smooth",
      })
    }, 200);

    form.setValue("sPackageID", "")
  }

  const checkValidate = () => {
    setIsDraft(false)
    let arrElement = [];
    lstContent.forEach((f) => {
      if (objStateContent[`nTypeContent_${f.id}`] === 1) {
        if (!objStateContent[`sContentOnly_${f.id}`]) {
          setIsDraft(true)
        }
      } else if (objStateContent[`nTypeContent_${f.id}`] === 2) {
        if (
          !objStateContent[`fFileImg_${f.id}`] ||
          objStateContent[`fFileImg_${f.id}`].length <= 0
        ) {
          arrElement.push(`box_content_${f.id}`);
        }
      }
    });
    let lstEmploymentTypeValue = form.watch("lstEmploymentType");
    if (!lstEmploymentTypeValue || lstEmploymentTypeValue.length <= 0) { }
    if (arrElement.length > 0) {
      dispatch(
        DialogActionCreators.OpenDialogWarning(
          i18n(`common.msgAlertSpecificData`),
          () => {
            setTimeout(() => {
              document.getElementById(arrElement[0]).scrollIntoView();
            }, 100);
          }
        ) as any
      );
    }
    return arrElement.length <= 0;
  };

  const getMasterData = async () => {
    let language = "";
    let isTH = (getLanguage().id === "th");
    isTH ? language = "th" : language = "en"

    AxiosPost(`${ControllerAPI}/GetMasterData`, { language, sJobPostID: sID }, (res) => {
      let objSetLable = {};
      Object.keys(res).forEach((key) => {
        if (typeof res[key] === "object" && key !== "Message" && res[key]) {
          objSetLable[key] = res[key].map((item) => ({
            parentValue: item.parentValue,
            value: item.value,
            label: item[`label_${language}`],
          }));
        }
      });

      setIsDisablePackage(res.isDisablePackage == false ? false : true);

      setOptionSelect({
        ...res,
        ...objSetLable,
        lstOptTag: res.lstOptTag,
      });

      setLstCheck(res.lstOptEducationLevel || []);
      setLstCheck(res.lstOptOtherInfo || []);
      setLstCheck(res.lstOptCareerLevel || []);
    });
  };

  const goBack = () => {
    history("/JobPostList");
  };

  const getDataDetail = async () => {
    let lang = "";
    let isTH = (getLanguage().id === "th");
    isTH ? lang = "th" : lang = "en"

    let param = {
      lang: lang,
      sID: sID
    }

    await AxiosPost(
      `${ControllerAPI}/GetJobPostByID`,
      param,
      (res) => {
        onGetFrequency();
        setIsURL(res.isURL);
        setIsJobSeekerAllow(res.isJobSeekerAllow);
        setAppManagement(res.lstAppManagement);
        setSumForJob(res.lstSumPointForJob);
        setJobFunction(res.lstJobFunction);
        setTimeWorkID(res.sTimeWorkID);
        setTag(res.lstTag);
        setRawCountry(res.lstOptCountry);
        setRawRegion(res.lstOptRegion);
        setRawProvince(res.lstOptProvince);
        setFrequency(res.lstFrequency);
        setStatusNow(res.nStatusID);

        form.setValue("sPackageID", res.lstOptPackage)

        let lstTmpComponent: dataContent[] = [];
        let tempOrder = [];
        res.lstContent.forEach((f) => {
          objStateContent[`nTypeContent_${f.nID}`] = f.nTypeID;
          if (f.nTypeID === 2 || f.nTypeID === 3) {
            f.fImageOrVideo.sFileType =
              f.fImageOrVideo.sSysFileName.split(".")[1];
            objStateContent[
              `${f.nTypeID === 2 ? "fFileImg_" : "fFileVideo_"}${f.nID}`
            ] = [f.fImageOrVideo];
          }
          let dataComponent: dataContent = {
            nType: f.nTypeID,
            fFileImageOrVideo:
              f.nTypeID === 2 || f.nTypeID === 3 ? [f.fImageOrVideo] : [],
            sContent: f.sContent,
            nOrder: f.nOrder,
            id: f.nID,
          };

          switch (f.nTypeID) {
            case 1:
              objStateContent[`sContentOnly_${f.nID}`] = f.sContent;
              break;
            case 2:
              objStateContent[`sContentBottomImg_${f.nID}`] = f.sContent;
              break;
            case 3:
              objStateContent[`sContentBottomVideo_${f.nID}`] = f.sContent;
              break;
            case 4:
              objStateContent[`sUrl${f.nID}`] = f.sUrl;
              objStateContent[`sContentBottomUrl_${f.nID}`] = f.sContent;
              break;
          }
          setObjStateContent({ ...objStateContent });
          lstTmpComponent.push(dataComponent);
          tempOrder.push(f.nOrder);
        });
        setLstOrder([...tempOrder]);
        setLstContent(lstTmpComponent);
        setIsChangeOrder(true);
        Object.keys(res).forEach((key) => {
          if (key === "sEducationLevelID" || key === "sCareerID" || key === "sOtherInfo") {
            form.setValue(key, res[key].map(m => m.value));
          } else {
            form.setValue(key, res[key]);
          }

          switch (key) {
            case "lstAppManagement":
              res[key].forEach((f) => {
                form.setValue(`app_manage_${f.nID}`, f.sValue);
              });
              break;
            case "lstSumPointForJob":
              res[key].forEach((f) => {
                form.setValue(`sum_job_${f.nID}`, f.sValue);
              });
              break;
            case "lstJobFunction":
              res[key].forEach((f) => {
                form.setValue(
                  `jobfunction_${f.nID}`,
                  f.value.nJobFunctionID + ""
                );
                form.setValue(`jobdetail_${f.nID}`, f.value.nJobDetailID + "");
              });
              break;
          }
        });
        setDisabled(res.nStatusID !== (1)); // != Draft

        if (res.nStatusID == 2) {
          setShowFrequency(true)
        }

        if (res.nStatusID == 3) {
          setShowEdit(true)
        }

        if (res.nStatusID == 7) {
          setCancelStatus(true);
        }

        if (res.nStatusID == 4) {
          setDeleteStatus(true);
        }

        if (res.objCountry.value === "519") {
          setIsInter(false);
        } else {
          setIsInter(true);
        }

        form.setValue("nCountryID", res.objCountry);
        form.setValue("nRegionID", res.objRegion);
        form.setValue("nProvinceID", res.objProvince);
        form.setValue("nFrequencyID", res.objFrequency);

        form.setValue("sSalaryStart", res.sSalaryStart ? parseFloat((res.sSalaryStart as string).replaceAll(",", "")) : "");
        form.setValue("sSalaryEnd", res.sSalaryEnd ? parseFloat((res.sSalaryEnd as string).replaceAll(",", "")) : "");

        setShowDelete(false)
      },
      null,
      () => setLoading(false)
    );
  };

  const GetDetailCompany = async () => {
    let sJobPostID = isEdit ? `/${sID}` : "";
    AxiosGet(`${ControllerAPI}/GetCompanyDetail${sJobPostID}`, null, (res) => {
      setCompanyDetail({ ...res });
    });
  };

  const Preview = () => {
    var txt = /\B(?=(\d{3})+(?!\d))/g;
    let lstDataContent = [];
    lstContent.forEach((f, indx) => {
      let objDataContent = {
        nTypeId: objStateContent[`nTypeContent_${f.id}`],
        sContent: "",
        sFileNameSys: "",
      };
      switch (objStateContent[`nTypeContent_${f.id}`]) {
        case 1:
          objDataContent.sContent = objStateContent[`sContentOnly_${f.id}`];
          break;
        case 2:
          objDataContent.sFileNameSys =
            objStateContent[`fFileImg_${f.id}`][0].sFileLink;
          objDataContent.sContent =
            objStateContent[`sContentBottomImg_${f.id}`];
          break;
      }
      lstDataContent.push(objDataContent);
    });
    setJobPostPreview({
      sJob_TH: form.getValues("sTitle_th"),
      sJob_EN: form.getValues("sTitle_en"),
      lstJobContent: lstDataContent,
      lstJobPoint: lstSumPointForJob.map((item) => ({
        sPoint_Detail: item.sValue,
      })),
      sCompanyFile_Logo: companyDetail.sLinkLogo,
      sYearsExperience: `${form.getValues("sYearExpStart")} - ${form.getValues(
        "sYearExpEnd")} `,

      sIndustry_TH: objOptionSelect.lstOptIndustry.find(
        (f) => f.value === form.getValues("sIndustryID")
      )?.label,
      sIndustry_EN: objOptionSelect.lstOptIndustry.find(
        (f) => f.value === form.getValues("sIndustryID")
      )?.label,
      sSalaryRange: `${form.getValues("sSalaryStart").toString().replace(txt, ",")} - 
        ${form.getValues("sSalaryEnd").toString().replace(txt, ",")} 
        ${language === "th" ? "บาท" : "THB"}`,
      sSalaryType_TH: objOptionSelect.lstOptSaralyInfo.find(
        (f) => f.value === form.getValues("sSalaryTypeID")
      )?.label,
      sSalaryType_EN: objOptionSelect.lstOptSaralyInfo.find(
        (f) => f.value === form.getValues("sSalaryTypeID")
      )?.label,

      lstCareerLevel_TH: objOptionSelect.lstOptCareerLevel
        .filter((f) => form.getValues("sCareerID").indexOf(f.value) > -1)
        .map((item) => item.label),
      lstCareerLevel_EN: objOptionSelect.lstOptCareerLevel
        .filter((f) => form.getValues("sCareerID").indexOf(f.value) > -1)
        .map((item) => item.label),

      lstEducation_TH: objOptionSelect.lstOptEducationLevel
        .filter((f) => form.getValues("sEducationLevelID").indexOf(f.value) > -1)
        .map((item) => item.label),
      lstEducation_EN: objOptionSelect.lstOptEducationLevel
        .filter((f) => form.getValues("sEducationLevelID").indexOf(f.value) > -1)
        .map((item) => item.label),

      lstOtherInfo_TH: objOptionSelect.lstOptOtherInfo
        .filter((f) => form.getValues("sOtherInfo").indexOf(f.value) > -1)
        .map((item) => item.label),
      lstOtherInfo_EN: objOptionSelect.lstOptOtherInfo
        .filter((f) => form.getValues("sOtherInfo").indexOf(f.value) > -1)
        .map((item) => item.label),

      lstEmploymentType_TH: objOptionSelect.lstOptEmploymentType
        .filter((f) => form.getValues("lstEmploymentType").indexOf(f.value) > -1)
        .map((item) => item.label),
      lstEmploymentType_EN: objOptionSelect.lstOptEmploymentType
        .filter((f) => form.getValues("lstEmploymentType").indexOf(f.value) > -1)
        .map((item) => item.label),

      lstJobBenefit_TH: objOptionSelect.lstOptBenefit
        .filter((f) => form.getValues("lstBenifits").indexOf(f.value) > -1)
        .map((item) => item.label),
      lstJobBenefit_EN: objOptionSelect.lstOptBenefit
        .filter((f) => form.getValues("lstBenifits").indexOf(f.value) > -1)
        .map((item) => item.label),
    });
    setOpenDialogPreview(true);
  };
  //#endregion

  //#region UseEffect
  useEffect(() => {
    if (lstJobFunction.length == 3) {
      setOverMax(true);
    } else {
      setOverMax(false);
    }
  }, [lstJobFunction.length]);

  useEffect(() => {
    const LoadDataOnPageLoad = async () => {
      GetDetailCompany();
      await getMasterData();
      await InitialOnPageLoad();
    };
    LoadDataOnPageLoad();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return () => {
      setState({}); // This worked for me
    };
  }, []);

  useEffect(() => {
    lstAppManagement.forEach((f) => {
      if (f.IsDelete) {
        delete objSchemaContext[`app_manage_${f.nID}`];
      } else {
        if (!objSchemaContext[`app_manage_${f.nID}`]) {
          objSchemaContext[`app_manage_${f.nID}`] = yupFormSchemas.string(
            i18n(`${i18nForthisPage}form.label.emailReceiver`),
            {
              required: true,
              matches: RegexpPassword(),
              labelmatches: i18n("validation.string.email"), //valid email
            }
          );
        }
      }
    });
    setSchemaContext({
      ...objSchemaContext,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lstAppManagement]);

  useEffect(() => {
    lstSumPointForJob.forEach((f) => {
      if (f.IsDelete) {
        delete objSchemaContext[`sum_job_${f.nID}`];
      } else {
        if (!objSchemaContext[`sum_job_${f.nID}`]) {
          objSchemaContext[`sum_job_${f.nID}`] = yupFormSchemas.string(
            i18n(`${i18nForthisPage}form.label.pointForJobDetail`),
            {
              required: true,
            }
          );
        }
      }
    });
    setSchemaContext({
      ...objSchemaContext,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lstSumPointForJob]);

  useEffect(() => {
    lstJobFunction.forEach((f) => {
      if (f.IsDelete) {
        delete objSchemaContext[`jobfunction_${f.nID}`];
        delete objSchemaContext[`jobdetail_${f.nID}`];
      } else {
        if (!objSchemaContext[`jobfunction_${f.nID}`]) {
          objSchemaContext[`jobfunction_${f.nID}`] = yupFormSchemas.string(
            i18n(`${i18nForthisPage}form.label.jobFunction`),
            {
              required: true,
            }
          );
        }
        if (!objSchemaContext[`jobdetail_${f.nID}`]) {
          objSchemaContext[`jobdetail_${f.nID}`] = yupFormSchemas.string(
            i18n(`${i18nForthisPage}form.label.jobDetail`),
            {
              required: true,
            }
          );
        }
      }
    });
    setSchemaContext({
      ...objSchemaContext,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lstJobFunction]);

  useEffect(() => {
    if (isJobSeekerAllow) {
      objSchemaContext[`sCountryID`] = yupFormSchemas.string(
        i18n(`${i18nForthisPage}form.label.jobSeekerAllow`),
        {
          required: true,
        }
      );
    } else {
      delete objSchemaContext[`sCountryID`];
    }

    setSchemaContext({
      ...objSchemaContext,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isJobSeekerAllow]);

  useEffect(() => {
    if (isURL) {
      objSchemaContext[`sURL`] = yupFormSchemas.string(
        i18n(`${i18nForthisPage}form.label.url`),
        {
          required: true,
        }
      );
    } else {
      delete objSchemaContext[`sURL`];
    }

    setSchemaContext({
      ...objSchemaContext,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isURL]);

  useEffect(() => {
    if (sTimeWorkID === "3" || sTimeWorkID === "4") {
      objSchemaContext[`sTimeWorkOther`] = yupFormSchemas.string(
        i18n(`${i18nForthisPage}form.label.timeWorkShiftOther`),
        {
          required: true,
        }
      );
    } else {
      delete objSchemaContext[`sTimeWorkOther`];
    }

    setSchemaContext({
      ...objSchemaContext,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sTimeWorkID]);
  //#endregion

  return (
    <>
      {isLoading ? (
        <Grid container>
          <Grid item xs={12}>
            <Skeleton height={50} />
          </Grid>
          <Grid item xs={12}>
            <Grid container>
              {/* Package */}
              <Grid item xs={12}>
                <Skeleton height={50} />
              </Grid>
              {/* Job Titel EN */}
              <Grid item xs={12}>
                <Skeleton height={50} />
              </Grid>
              {/* Job Titel TH */}
              <Grid item xs={12}>
                <Skeleton height={50} />
              </Grid>
              {/* Content */}
              <Grid item xs={12}>
                <Stack
                  sx={{
                    marginTop: "0px !important",
                    border: "1px #eeeeee solid",
                    backgroundColor: "rgba(255,255,255,0.75)",
                    borderRadius: "5px",
                    p: 2,
                    flex: 1,
                  }}
                  direction="column"
                >
                  <Skeleton height={50} width="100%" />
                  <Skeleton height={50} width="100%" />
                  <Skeleton height={50} width="100%" />

                  <Stack direction="row">
                    <Skeleton height={50} width={100} />
                  </Stack>
                </Stack>
              </Grid>
              {/* Application Management */}
              <Grid item xs={12}>
                <Skeleton height={50} />
                <Grid container>
                  <Skeleton height={50} width="100%" />
                  <Skeleton height={50} width="100%" />
                  <Grid item xs={12}>
                    <Skeleton height={50} width={100} />
                  </Grid>
                </Grid>
              </Grid>
              {/* Summary Point For Job */}
              <Grid item xs={12}>
                <Skeleton height={50} />
                <Grid container>
                  <Skeleton height={50} width="100%" />
                  <Skeleton height={50} width="100%" />
                  <Grid item xs={12}>
                    <Skeleton height={50} width={100} />
                  </Grid>
                </Grid>
              </Grid>
              {/* Job description */}
              <Grid item xs={12}>
                <Skeleton height={50} width="100%" />
                <Skeleton height={50} width="100%" />
                <Skeleton height={50} width="100%" />

                <Grid item xs={12} style={{ marginTop: 10 }}>
                  <Skeleton height={50} width={100} />
                </Grid>
              </Grid>
              {/* Industry */}
              <Grid item xs={12}>
                <Skeleton height={50} />
              </Grid>
            </Grid>
          </Grid>
          {/* Button */}
          <Grid item xs={12}>
            <Grid container spacing={1} sx={{ justifyContent: "flex-end" }}>
              <Grid item>
                <Skeleton height={50} width={100} />
              </Grid>
              <Grid item>
                <Skeleton height={50} width={100} />
              </Grid>
              <Grid item>
                <Skeleton height={50} width={100} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <FormProvider {...form}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography>{i18n(`${i18nForthisPage}Header`)}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                {/* Package */}
                <Grid item xs={12} id="scroll_Top">
                  <AutoCompleteSelect
                    label={i18n(`${i18nForthisPage}form.label.package`)}
                    fullWidth
                    name={"sPackageID"}
                    options={showDelete ? [] :
                      isRequirePackage ? objOptionSelect.lstOptPackageNoExpire :
                        objOptionSelect.lstOptPackage.filter((f) => (!f.isDelete && !f.isActive) || (form.getValues('sPackageID') ? f.value === form.getValues('sPackageID') : false))
                    }
                    required={true}
                    customValue={{ key: "value" }}
                    disabled={isDisabled}
                  />
                </Grid>
                {/* Job Titel EN */}
                <Grid item xs={12}>
                  <Input
                    name="sTitle_en"
                    label={
                      <Typography sx={{ fontWeight: 400 }} component="label">
                        {i18n(`${i18nForthisPage}form.label.jobTitleEn`)}
                      </Typography>
                    }
                    fullWidth
                    required
                    small
                    maxLength={100}
                    disabled={isDisabled}
                  />
                </Grid>
                {/* Job Titel TH */}
                <Grid item xs={12}>
                  <Input
                    name="sTitle_th"
                    label={
                      <Typography sx={{ fontWeight: 400 }} component="label">
                        {i18n(`${i18nForthisPage}form.label.jobTitleTh`)}
                      </Typography>
                    }
                    fullWidth
                    required
                    small
                    maxLength={100}
                    disabled={isDisabled}
                  />
                </Grid>
                {/* Content */}
                <Grid item xs={12}>
                  <Stack
                    sx={{
                      marginTop: "0px !important",
                      border: "1px #eeeeee solid",
                      backgroundColor: "rgba(255,255,255,0.75)",
                      borderRadius: "5px",
                      p: 2,
                      flex: 1,
                    }}
                    spacing={2}
                    direction="column"
                  >
                    <Typography>
                      {i18n(`${i18nForthisPage}JobDesc`)}
                    </Typography>
                    <Typography sx={{ color: "#8C8C8C" }}>
                      {i18n(`${i18nForthisPage}JobDescHelptxt`)}
                    </Typography>
                    {lstContent.map((item, index) => {
                      return (
                        <div key={`f_${index}`} id={`box_content_${item.id}`}>
                          {/* Job Description */}
                          {index > 0 ? (
                            <Divider
                              key={`divider_c_${index}`}
                              sx={{ marginTop: "24px !important" }}
                            />
                          ) : null}
                          <BoxContent
                            key={`box_form_c_${index}`}
                            objData={item}
                            lstComponent={lstContent}
                            setLstComponent={setLstContent}
                            objSchema={{}}
                            setSchema={setSchemaContext}
                            objStateContent={objStateContent}
                            setObjStateContent={setObjStateContent}
                            lstOrder={lstOrder}
                            setLstOrder={setLstOrder}
                            nOrder={index + 1}
                            isAddAction={isAddAction}
                            setIsAddToDefault={() => setIsAddAction(false)}
                            setIsChangeOrder={(e) => setIsChangeOrder(e)}
                            isChangeOrder={isChangeOrder}
                            isAddPic={false}
                            isEmployerSetting={false}
                          />
                        </div>
                      );
                    })}
                    {/* {!isDisabled && ( */}
                    <Stack direction="row">
                      <Button
                        startIcon={<Add />}
                        onClick={() => AddComponent()}
                        variant="contained"
                        sx={{ borderRadius: "20px", }}
                      >
                        {i18n(`${i18nForthisPage}button.addParagraph`)}
                      </Button>
                    </Stack>
                    {/* )} */}
                  </Stack>
                </Grid>
                {/* Application Management */}
                <Grid item xs={12}>
                  <Typography style={{ marginBottom: 10 }}>
                    {i18n(`${i18nForthisPage}form.label.appManagement`)}
                  </Typography>
                  <Grid container spacing={2}>
                    {lstAppManagement
                      .filter((f) => !f.IsDelete)
                      .map((item, indx) => {
                        return (
                          <Grid item key={`app_manage_${indx}`} xs={12}>
                            <Grid container spacing={2}>
                              <Grid item sx={{ flexGrow: 1 }}>
                                <Input
                                  name={`app_manage_${item.nID}`}
                                  label={
                                    <Typography sx={{ fontWeight: 400 }} component="label">
                                      {i18n(
                                        `${i18nForthisPage}form.label.emailReceiver`
                                      )}
                                    </Typography>
                                  }
                                  fullWidth
                                  required
                                  small
                                  onChange={(val) => (item.sValue = val)}
                                />
                              </Grid>
                              {lstAppManagement.filter((f) => !f.IsDelete)
                                .length > 1 &&
                                (
                                  <Grid item>
                                    <BtnCancel
                                      onClick={() => onDeleteEmail(item.nID)}
                                      IsDisabled={false}
                                      isCircleWithOutText
                                    />
                                  </Grid>
                                )
                              }
                            </Grid>
                          </Grid>
                        );
                      })}
                    <Grid item xs={12}>
                      <Button
                        startIcon={<Add />}
                        onClick={AddAppManagementEmail}
                        variant="contained"
                        sx={{ borderRadius: "20px", }}
                      >
                        {i18n(`${i18nForthisPage}button.addMailReceiver`)}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
                {/* Summary Point For Job */}
                <Grid item xs={12}>
                  <Typography style={{ marginBottom: 10 }}>
                    {i18n(`${i18nForthisPage}form.label.sumForJob`)}
                  </Typography>
                  <Grid container spacing={2}>
                    {lstSumPointForJob
                      .filter((f) => !f.IsDelete)
                      .map((item, indx) => {
                        return (
                          <Grid item xs={12} key={`point_job_${indx}`}>
                            <Grid container spacing={2}>
                              <Grid item sx={{ flexGrow: 1 }}>
                                <Input
                                  name={`sum_job_${item.nID}`}
                                  label={
                                    <Typography sx={{ fontWeight: 400 }} component="label">
                                      {i18n(
                                        `${i18nForthisPage}form.label.pointForJobDetail`
                                      )}
                                    </Typography>
                                  }
                                  fullWidth
                                  required
                                  small
                                  onChange={(val) => (item.sValue = val)}
                                  maxLength={50}
                                />
                              </Grid>
                              {lstSumPointForJob.filter((f) => !f.IsDelete)
                                .length > 3 &&
                                (
                                  <Grid item>
                                    <BtnCancel
                                      onClick={() => onDeleteSumPoint(item.nID)}
                                      IsDisabled={false}
                                      isCircleWithOutText
                                    />
                                  </Grid>
                                )}
                            </Grid>
                          </Grid>
                        );
                      })}
                    <Grid item xs={12}>
                      <Button
                        startIcon={<Add />}
                        onClick={() => AddSumPointForJob()}
                        variant="contained"
                        sx={{ borderRadius: "20px", }}
                      >
                        {i18n(`${i18nForthisPage}button.addSumPointForJob`)}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
                {/* Job description */}
                <Grid item xs={12}>
                  {lstJobFunction
                    .filter((f) => !f.IsDelete)
                    .map((item, indx) => {
                    
                        return (
                          <Stack
                            sx={{
                              marginTop: "0px !important",
                              border: "1px #eeeeee solid",
                              backgroundColor: "rgba(255,255,255,0.75)",
                              borderRadius: "5px",
                              p: 2,
                              flex: 1,
                            }}
                            spacing={2}
                            direction="column"
                            key={`job_description_${item.nID}`}
                          >
                            <Grid container spacing={2}>
                              <Grid item sx={{ flexGrow: 1 }}>
                                <Grid container spacing={2}>
                                  <Grid item xs={12}>
                                    <AutoCompleteSelect
                                      label={i18n(
                                        `${i18nForthisPage}form.label.jobFunction`
                                      )}
                                      fullWidth
                                      name={`jobfunction_${item.nID}`}
                                      options={
                                        objOptionSelect.lstOptJobFunction
                                      }
                                      required={true}
                                      onChange={(value) => {
                                        item.value.nJobFunctionID = value;
                                        form.setValue(
                                          `jobdetail_${item.nID}`,
                                          ""
                                        );
                                      }}
                                      customValue={{ key: "value" }}
                                      disabled={isDisabled}
                                    />
                                  </Grid>
                                  <Grid item xs={12}>
                                    <AutoCompleteSelect
                                      label={i18n(
                                        `${i18nForthisPage}form.label.jobDetail`
                                      )}
                                      fullWidth
                                      name={`jobdetail_${item.nID}`}
                                      options={objOptionSelect.lstOptJobDetail.filter(
                                        (f) =>
                                          f.parentValue ===
                                          form.watch(`jobfunction_${item.nID}`)
                                      )}
                                      required={true}
                                      onChange={(value) =>
                                        (item.value.nJobDetailID = value)
                                      }
                                      customValue={{ key: "value" }}
                                      disabled={isDisabled}
                                    />
                                  </Grid>
                                </Grid>
                              </Grid>
                              {lstJobFunction.filter((f) => !f.IsDelete)
                                .length > 1 &&
                                // !isDisabled && 
                                (
                                  <Grid item sx={{ alignSelf: "center" }}>
                                    <BtnCancel
                                      onClick={() => onDeleteJobDesc(item.nID)}
                                      IsDisabled={false}
                                      isCircleWithOutText
                                    />
                                  </Grid>
                                )}
                            </Grid>
                          </Stack>
                        );
                    
                    })}
                  <Grid
                    item
                    xs={12}
                    style={{ marginTop: 10 }}
                  // hidden={isDisabled}
                  >
                    {lstJobFunction.filter((f) => !f.IsDelete).length >= 3 ? (
                      <></>
                    ) : (
                      <Button
                        startIcon={<Add />}
                        onClick={AddJobFunction}
                        variant="contained"
                        sx={{ borderRadius: "20px", }}
                      >
                        {i18n(`${i18nForthisPage}button.addJobFunction`)}
                      </Button>
                    )}
                  </Grid>
                </Grid>
                {/* Industry */}
                <Grid item xs={12}>
                  <AutoCompleteSelect
                    label={i18n(`${i18nForthisPage}form.label.industry`)}
                    fullWidth
                    name={"sIndustryID"}
                    options={objOptionSelect.lstOptIndustry}
                    required={true}
                    customValue={{ key: "value" }}
                  />
                </Grid>
                {/* Post Date */}
                <Grid container item xs={12} spacing={2}>
                  <Grid item >
                    <DatePickerFormItem
                      name={"dPostDate"}
                      label={i18n(`${i18nForthisPage}form.label.postDate`)}
                      required
                      small
                      minDate={moment(Date.now())}
                      onChange={(e) => {
                        let dExpire = moment(e).add("29", "days");
                        form.setValue("dExpireDate", dExpire);
                      }}
                      disabled={ShowEdit ? false : isDisabled}
                    />
                  </Grid>
                  <Grid item>
                    <DatePickerFormItem
                      name={"dExpireDate"}
                      label={i18n(`${i18nForthisPage}form.label.expireDate`)}
                      small
                      disabled
                    />
                  </Grid>
                </Grid>
                {/* Work Location */}
                <Grid item xs={12} >
                  <Typography>{i18n(`${i18nForthisPage}form.label.workLocation`)}</Typography>
                </Grid>
                {/* Address */}
                <Grid item md={12} xs={12}>
                  <Grid item container spacing={2} xs={12}>
                    <Grid item md={4} xs={12}>
                      <AutoCompleteSelect
                        required
                        fullWidth
                        name={"nCountryID"}
                        label={i18n(`${i18nForValidate}Country`)} //country
                        options={rawCountry}
                        onChange={(v) => {
                          if (v && v.value !== "519") {
                            setIsInter(true);
                            form.clearErrors("nRegionID");
                            form.clearErrors("nProvinceID");
                            form.setValue("nRegionID", 0);
                            form.setValue("nProvinceID", 0);
                          } else {
                            setIsInter(false);
                          }
                        }}
                      />
                    </Grid>
                    {IsInter ? null : (
                      <>
                        <Grid item md={4} xs={12}>
                          <AutoCompleteSelect
                            fullWidth
                            required
                            name={"nRegionID"}
                            label={i18n(`${i18nForValidate}Region`)} //region
                            options={rawRegion.filter((f) => f.value != 1779)}
                            onChange={(v) => {
                              if (v && v.value !== "nRegionID") {
                                form.clearErrors("nProvinceID");
                                form.setValue("nProvinceID", 0);
                              } else {
                                form.clearErrors("nProvinceID");
                                form.setValue('nProvinceID', v ? v.value : 0);
                              }
                              setProvinceParent(v ? v.value : 0);
                            }}
                          // disabled={ShowEdit ? false : isDisabled}
                          />
                        </Grid>
                        <Grid item md={4} xs={12}>
                          <AutoCompleteSelect
                            fullWidth
                            required
                            name={"nProvinceID"}
                            label={i18n(`${i18nForValidate}Province`)} //province
                            options={rawProvince.filter(
                              (f) => f.sParent == ProvinceParent
                            )}
                            onChange={(v) => {
                              form.setValue("nProvinceID", v ? v : null);
                            }}
                          />
                        </Grid>
                      </>
                    )}
                  </Grid>
                </Grid>

                {/* Work Traveling */}
                <Grid item xs={12}>
                  <AutoCompleteSelect
                    label={i18n(`${i18nForthisPage}form.label.workTraveiling`)}
                    fullWidth
                    name={"sTravelingID"}
                    options={objOptionSelect.lstOptTraveling}
                    customValue={{ key: "value" }}
                  // disabled={isDisabled}
                  // disabled={ShowEdit ? false : isDisabled}
                  />
                </Grid>

                {/* frequency */}
                <Grid item xs={12}>
                  <AutoCompleteSelect
                    fullWidth
                    // disabled={ShowEdit ? false : isDisabled}
                    name={"nFrequencyID"}
                    label={i18n(`${i18nForValidate}Frequency`)} //frequency
                    options={FrequencyData}
                    onChange={(v) => {
                      form.setValue("nFrequencyID", v ? v : null);
                    }}
                  />
                </Grid>


                {/* Employment Type */}
                <Grid item xs={12} id="employmentType">
                  <Typography>
                    {i18n(`${i18nForthisPage}form.label.employmentType`)}
                  </Typography>

                  <Grid container spacing={2}>
                    {objOptionSelect.lstOptEmploymentType &&
                      objOptionSelect.lstOptEmploymentType.map(
                        (item, index) => (
                          <Grid item xs={6} sm={4} md={3} lg={2} key={index}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    form.watch("lstEmploymentType") &&
                                    form
                                      .watch("lstEmploymentType")
                                      .some((s) => s === item.value)
                                  }
                                  onChange={(e) => {
                                    let lstValue =
                                      form.getValues("lstEmploymentType");
                                    if (e.target.checked) {
                                      form.setValue("lstEmploymentType", [
                                        ...lstValue,
                                        e.target.value,
                                      ]);
                                    } else {
                                      form.setValue("lstEmploymentType", [
                                        ...lstValue.filter(
                                          (w) => w !== e.target.value
                                        ),
                                      ]);
                                    }
                                  }}
                                  value={item.value}
                                />
                              }
                              label={item.label}
                            // disabled={isDisabled}
                            // disabled={ShowEdit ? false : isDisabled}
                            />
                          </Grid>
                        )
                      )}
                  </Grid>
                  {form.formState.isSubmitted &&
                    (!form.watch("lstEmploymentType") ||
                      form.watch("lstEmploymentType").length <= 0) && (
                      <span className="text-[12px] text-[red] mx-[14px]">
                        {/* {`Please specify data more than one item`} */}
                        {i18n(`${i18nForValidate}employmentType`)}
                      </span>
                    )}
                </Grid>
                {/* Salary info */}
                <Grid item xs={12}>
                  <Typography style={{ marginBottom: 10 }}>
                    {i18n(`${i18nForthisPage}form.label.salaryInfo`)}
                  </Typography>
                  <AutoCompleteSelect
                    label={i18n(`${i18nForthisPage}form.label.salaryType`)}
                    fullWidth
                    name={"sSalaryTypeID"}
                    options={objOptionSelect.lstOptSaralyInfo}
                    required={true}
                    customValue={{ key: "value" }}
                  // disabled={isDisabled}
                  // disabled={ShowEdit ? false : isDisabled}
                  />
                </Grid>
                {/* Salary range */}
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item lg={2} md={6} xs={12}>
                      <InputNumber
                        name="sSalaryStart"
                        // type="number"
                        label={
                          <Typography sx={{ fontWeight: 400 }} component="label">
                            {i18n(`${i18nForthisPage}form.label.salaryStart`)}
                          </Typography>
                        }
                        fullWidth
                        required
                        small
                      // disabled={ShowEdit ? false : isDisabled}
                      />
                    </Grid>
                    <Grid item lg={2} md={6} xs={12}>
                      <InputNumber
                        name="sSalaryEnd"
                        label={
                          <Typography sx={{ fontWeight: 400 }} component="label">
                            {i18n(`${i18nForthisPage}form.label.salaryEnd`)}
                          </Typography>
                        }
                        fullWidth
                        required
                        small
                      // disabled={isDisabled}
                      // disabled={ShowEdit ? false : isDisabled}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                {/* other information */}
                <Grid item xs={12}>
                  <MultiSelect
                    name={"sOtherInfo"}
                    valueData={form.watch('sOtherInfo') || []}
                    options={objOptionSelect.lstOptOtherInfo.filter(f => !lstCheck.filter((f) => f.ischeck).map(m => m.nOtherInfo_ID).includes(f.nOtherInfo_ID))}
                    label={i18n(`${i18nForthisPage}form.label.otherInfomation`)}
                    notSetMargin={true}
                  // disabled={ShowEdit ? false : isDisabled}
                  />
                </Grid>
                {/* Time work */}
                <Grid item xs={12}>
                  <Typography>
                    {i18n(`${i18nForthisPage}form.label.timeWork`)}
                  </Typography>

                  <Grid container>
                    <Grid item xs={12}>
                      <Grid container spacing={2}>
                        <Grid item lg={2} md={6} sm={6}>
                          <FormControlLabel
                            value="1"
                            control={<Radio />}
                            label={i18n(
                              `${i18nForthisPage}form.label.timeWorkMF`
                            )}
                            labelPlacement="end"
                            checked={sTimeWorkID === "1"}
                            onChange={() => {
                              form.setValue("sTimeWorkOther", "");
                              setTimeWorkID("1");
                            }}
                          // disabled={isDisabled}
                          // disabled={ShowEdit ? false : isDisabled}
                          />
                        </Grid>
                        <Grid item lg={2} md={6} sm={6}>
                          <FormControlLabel
                            value="2"
                            control={<Radio />}
                            label={i18n(
                              `${i18nForthisPage}form.label.timeWorkMS`
                            )}
                            labelPlacement="end"
                            checked={sTimeWorkID === "2"}
                            onChange={() => {
                              form.setValue("sTimeWorkOther", "");
                              setTimeWorkID("2");
                            }}
                          // disabled={isDisabled}
                          // disabled={ShowEdit ? false : isDisabled}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container spacing={2}>
                        <Grid item lg={2} md={6} sm={6}>
                          <FormControlLabel
                            value="3"
                            control={<Radio />}
                            label={i18n(
                              `${i18nForthisPage}form.label.timeWorkShift`
                            )}
                            labelPlacement="end"
                            checked={sTimeWorkID === "3"}
                            onChange={() => {
                              form.setValue("sTimeWorkOther", "");
                              setTimeWorkID("3");
                            }}
                          // disabled={isDisabled}
                          // disabled={ShowEdit ? false : isDisabled}
                          />
                        </Grid>
                        {sTimeWorkID === "3" && (
                          <Grid item lg={2} md={6} sm={6}>
                            <Input
                              name="sTimeWorkOther"
                              label={
                                <>
                                  {i18n(
                                    `${i18nForthisPage}form.label.timeWorkShiftOther`
                                  )}
                                </>
                              }
                              fullWidth
                              required
                              small
                            // disabled={isDisabled}
                            // disabled={ShowEdit ? false : isDisabled}
                            />
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container spacing={2}>
                        <Grid item lg={2} md={6} sm={6}>
                          <FormControlLabel
                            value="4"
                            control={<Radio />}
                            label={i18n(
                              `${i18nForthisPage}form.label.timeWorkOther`
                            )}
                            labelPlacement="end"
                            checked={sTimeWorkID === "4"}
                            onChange={() => {
                              form.setValue("sTimeWorkOther", "");
                              setTimeWorkID("4");
                            }}
                          // disabled={isDisabled}
                          // disabled={ShowEdit ? false : isDisabled}
                          />
                        </Grid>
                        {sTimeWorkID === "4" && (
                          <Grid item lg={3} md={6} sm={6}>
                            <Input
                              name="sTimeWorkOther"
                              label={
                                <>
                                  {i18n(
                                    `${i18nForthisPage}form.label.timeWorkOtherTxtBox`
                                  )}
                                </>
                              }
                              fullWidth
                              required
                              small
                            // disabled={isDisabled}
                            // disabled={ShowEdit ? false : isDisabled}
                            />
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                {/* Benefits */}
                <Grid item xs={12}>
                  <Typography>
                    {i18n(`${i18nForthisPage}form.label.benefits`)}
                  </Typography>

                  <Grid container>
                    {objOptionSelect.lstOptBenefit &&
                      objOptionSelect.lstOptBenefit.map((item, index) => (
                        <Grid
                          item
                          key={index}
                          md={4}
                          xs={12}
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={
                                  form.watch("lstBenifits") &&
                                  form
                                    .watch("lstBenifits")
                                    .some((s) => s === item.value)
                                }
                                onChange={(e) => {
                                  let lstValue = form.getValues("lstBenifits");
                                  if (e.target.checked) {
                                    form.setValue("lstBenifits", [
                                      ...lstValue,
                                      e.target.value,
                                    ]);
                                  } else {
                                    form.setValue("lstBenifits", [
                                      ...lstValue.filter(
                                        (w) => w !== e.target.value
                                      ),
                                    ]);
                                  }
                                }}
                                value={item.value}
                              />
                            }
                            label={item.label}
                          // disabled={isDisabled}
                          // disabled={ShowEdit ? false : isDisabled}
                          />
                        </Grid>
                      ))}
                    {form.getValues("lstBenifits") && form.getValues("lstBenifits").includes("1780") ? (
                      <Grid
                        item
                        sx={{
                          position: "relative",
                          marginLeft: "74%",
                          marginTop: "-3.5%",
                        }}
                      >
                        <Input
                          name={"sBenefitDesc"}
                          label={i18n(
                            `${i18nForthisPage}form.label.OtherBenefits`
                          )}
                          fullWidth
                          small
                        />
                      </Grid>
                    ) : (
                      <></>
                    )}
                  </Grid>
                  {/*Benefits is require */}
                  {/* {form.formState.isSubmitted &&
                    (!form.watch("lstBenifits") ||
                      form.watch("lstBenifits").length <= 0) && (
                      <span className="text-[12px] text-[red] mx-[14px]">
                    
                        {i18n(`${i18nForValidate}Benefit`)}
                      </span>
                    )} */}
                </Grid>

                {/* Career Level */}
                <Grid item xs={12}>
                  <MultiSelect
                    name={"sCareerID"}
                    valueData={form.watch('sCareerID') || []}
                    options={objOptionSelect.lstOptCareerLevel.filter(f => !lstCheck.filter((f) => f.ischeck).map(m => m.nCareer_Level_ID).includes(f.nCareer_Level_ID))}
                    label={i18n(`${i18nForthisPage}form.label.careerLevel`)}
                    required={true}
                    notSetMargin={true}
                  // disabled={ShowEdit ? false : isDisabled}
                  />
                </Grid>

                {/* Minimum Education Level */}
                <Grid item xs={12}>
                  <MultiSelect
                    name={"sEducationLevelID"}
                    valueData={form.watch('sEducationLevelID') || []}
                    options={objOptionSelect.lstOptEducationLevel.filter(f => !lstCheck.filter((f) => f.ischeck).map(m => m.nMin_Education_ID).includes(f.nMin_Education_ID))}
                    label={i18n(`${i18nForthisPage}form.label.minimumEducation`)}
                    notSetMargin={true}
                    required={true}
                  // disabled={ShowEdit ? false : isDisabled}
                  />
                </Grid>
                {/* Minumum Year of Exp */}
                <Grid item xs={12}>
                  <Typography style={{ marginBottom: 10 }}>
                    {i18n(`${i18nForthisPage}form.label.minimumYearExp`)}
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item lg={2} md={6} xs={12}>
                      <AutoCompleteSelect
                        label={i18n(
                          `${i18nForthisPage}form.label.minumumYearExpStart`
                        )}
                        fullWidth
                        name={"sYearExpStart"}
                        options={lstTempYear}
                        required={true}
                        customValue={{ key: "value" }}
                        onChange={(v) => {
                          form.setValue("sYearExpStart", v ? v : "")
                        }}
                      // disabled={isDisabled}
                      // disabled={ShowEdit ? false : isDisabled}
                      />
                    </Grid>
                    <Grid item lg={2} md={6} xs={12}>
                      <AutoCompleteSelect
                        label={i18n(
                          `${i18nForthisPage}form.label.minumumYearExpEnd`
                        )}
                        fullWidth
                        name={"sYearExpEnd"}
                        options={lstTempYear}
                        required={true}
                        customValue={{ key: "value" }}
                        onChange={(v) => {
                          form.setValue("sYearExpEnd", v ? v : "")
                        }}
                      // disabled={isDisabled}
                      // disabled={ShowEdit ? false : isDisabled}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                {/* Select Tag */}
                <Grid item xs={12}>
                  <Autocomplete
                    multiple
                    options={objOptionSelect.lstOptTag}
                    value={lstTag}
                    freeSolo
                    fullWidth
                    size="small"
                    renderTags={(value: readonly string[], getTagProps) =>
                      value.map((option: string, index: number) => (
                        <Chip
                          variant="outlined"
                          label={option}
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    onChange={(e, value) => setTag([...value])}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={i18n(`${i18nForthisPage}form.label.tag`)}
                      />
                    )}
                  // disabled={isDisabled}
                  // disabled={ShowEdit ? false : isDisabled}
                  />
                  <FormHelperText>
                    {i18n(`${i18nForthisPage}form.label.helpTag`)}
                    {/* พิมพ์แท็กที่ต้องการใช้จากนั้นกด Enter */}
                  </FormHelperText>
                </Grid>
                {/* Job seeker allow */}
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item lg={4} md={6} sm={12} xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={isJobSeekerAllow}
                            onChange={(e) =>
                              setIsJobSeekerAllow(e.target.checked)
                            }
                            value={0}
                          />
                        }
                        label={i18n(
                          `${i18nForthisPage}form.label.jobSeekerAllow`
                        )}
                      // disabled={isDisabled}
                      // disabled={ShowEdit ? false : isDisabled}
                      />
                    </Grid>
                    <Grid
                      item
                      lg={4}
                      md={6}
                      sm={12}
                      xs={12}
                      hidden={!isJobSeekerAllow}
                    >
                      <AutoCompleteSelect
                        label={i18n(`${i18nForthisPage}form.label.country`)}
                        fullWidth
                        name={"sCountryID"}
                        options={objOptionSelect.lstOptCountry}
                        required={true}
                        customValue={{ key: "value" }}
                      // disabled={isDisabled}
                      // disabled={ShowEdit ? false : isDisabled}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                {/* Select register specific data in web */}
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isURL}
                        onChange={(e) => setIsURL(e.target.checked)}
                        value={0}
                      />
                    }
                    label={i18n(`${i18nForthisPage}form.label.selectRegister`)}
                  // disabled={isDisabled}
                  // disabled={ShowEdit ? false : isDisabled}
                  />
                </Grid>
                {/* URL */}
                <Grid item xs={12} hidden={!isURL}>
                  <Input
                    name="sURL"
                    label={i18n(`${i18nForthisPage}form.label.url`)}
                    small
                  // disabled={isDisabled}
                  // disabled={ShowEdit ? false : isDisabled}
                  />
                </Grid>
              </Grid>
            </Grid>

            {/* Button */}
            <Grid item xs={12} hidden={ShowEdit ? false : isCancelStatus ? false : isDisabled}>
              <Grid container spacing={1} sx={{ justifyContent: "flex-end" }}>
                {isCancelStatus ?
                  <>
                    <Grid item sx={{ mr: "2%" }}>
                      <BtnBack
                        txt={i18n(`${i18nForthisPage}button.Back`)}
                        onClick={() => history("/JobPostList")}
                      />
                    </Grid>

                    <Grid item>
                      <BtnSaveDraft
                        txt={i18n(`common.duplicateButt`)}
                        onClick={form.handleSubmit((values) => onSubmit(values, 1, 1))}
                      />
                    </Grid>
                  </>
                  : ShowEdit ? //status == 3 / รอประกาศ
                    <>
                      <Grid item sx={{ justifyContent: "flex-start" }}>
                        <BtnBack
                          txt={i18n(`${i18nForthisPage}button.Back`)}
                          onClick={() => history("/JobPostList")}
                        />
                      </Grid>
                      <Grid item>
                        <BtnPreview onClick={() => Preview()} />
                      </Grid>
                      <Grid item>
                        <BtnSaveDraft
                          txt={i18n(`common.duplicateButt`)}
                          onClick={form.handleSubmit((values) => onSubmit(values, 1, 1))}
                        />
                      </Grid>
                      <Grid item>
                        <BtnSubmit
                          txt={i18n(`${i18nForthisPage}button.publish`)}
                          onClick={form.handleSubmit((values) => onSubmit(values, 2, 0))}
                        />
                      </Grid>
                    </>
                    :
                    <>
                      <Grid item sx={{ justifyContent: "flex-start" }}>
                        <BtnBack
                          txt={i18n(`${i18nForthisPage}button.Back`)}
                          onClick={() => history("/JobPostList")}
                        />
                      </Grid>
                      <Grid item>
                        <BtnSaveDraft
                          txt={i18n(`${i18nForthisPage}button.draft`)}
                          onClick={() => onSubmit(form.getValues(), 1, 0)}
                        />
                      </Grid>
                      <Grid item>
                        <BtnPreview onClick={() => Preview()} />
                      </Grid>
                      <Grid item>
                        <BtnSaveDraft
                          txt={i18n(`common.duplicateButt`)}
                          onClick={form.handleSubmit((values) => onSubmit(values, 1, 1))}
                        />
                      </Grid>
                      <Grid item>
                        <BtnSubmit
                          txt={i18n(`${i18nForthisPage}button.publish`)}
                          onClick={form.handleSubmit((values) => onSubmit(values, 2, 0))}
                        />
                      </Grid>
                    </>
                }
              </Grid>
            </Grid>

            {/* when status id == 2 */}
            {showFrequency ? (
              <Grid container spacing={1} sx={{ justifyContent: "flex-end", mt: 5 }}>
                <Grid item hidden={ShowEdit ? isDisabled : false} sx={{ justifyContent: "flex-start" }}>
                  <BtnBack
                    txt={i18n(`${i18nForthisPage}button.Back`)}
                    onClick={() => history("/JobPostList")}
                  />
                </Grid>
                <Grid item>
                  <BtnSaveDraft
                    txt={i18n(`common.duplicateButt`)}
                    onClick={form.handleSubmit((values) => onSubmit(values, 1, 1))}
                  />
                </Grid>
                <Grid item hidden={ShowEdit ? isDisabled : false}>
                  <BtnCancel
                    txt={i18n(`${i18nForthisPage}button.Deactivate`)}
                    onClick={form.handleSubmit((values) => onDeactivate(values, 4))}
                  />
                </Grid>
                <Grid item>
                  <BtnApprove
                    txt={i18n(`${i18nForthisPage}button.BoostPost`)}
                    onClick={form.handleSubmit((values) => onSubmit(values, 2, 2))}
                  />
                </Grid>
                <Grid item>
                  <BtnSubmit
                    txt={i18n(`${i18nForthisPage}button.publish`)}
                    onClick={form.handleSubmit((values) => onSubmit(values, 2, 0))}
                  />
                </Grid>
              </Grid>
            ) : (null)}

            {isDeleteStatus ? (
              <Grid container spacing={1} sx={{ justifyContent: "flex-end", mt: 5 }}>
                <Grid item hidden={ShowEdit ? isDisabled : false} sx={{ justifyContent: "flex-start" }}>
                  <BtnBack
                    txt={i18n(`${i18nForthisPage}button.Back`)}
                    onClick={() => history("/JobPostList")}
                  />
                </Grid>
                <Grid item>
                  <BtnSaveDraft
                    txt={i18n(`common.duplicateButt`)}
                    onClick={form.handleSubmit((values) => onSubmit(values, 1, 1))}
                  />
                </Grid>
              </Grid>
            ) : null}
          </Grid>
        </FormProvider>
      )}
      <Dialog
        fullScreen
        open={isOpenDialogPreview}
        onClose={() => setOpenDialogPreview(false)}
      >
        <AppBar sx={{ position: "relative", marginBottom: "3rem" }}>
          <Toolbar>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              PREVIEW
            </Typography>
            <IconButton
              edge="end"
              color="inherit"
              onClick={() => setOpenDialogPreview(false)}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Grid container>
          <Grid item sx={{ mx: "3rem" }}>
            <DetailJob jobPost={jobPostPreview} />
            <DetailCompany lstCompanyContent={companyDetail.lstContent} isViewJob={false} />
            <Grid item sx={{ justifyContent: "end", my: "3%" }}>
              <BtnBack
                txt={i18n(`${i18nForthisPage}button.Back`)}
                onClick={() => setOpenDialogPreview(false)}
              />
            </Grid>
          </Grid>
        </Grid>
      </Dialog>
    </>
  );
};

export default JobPostModify;

export const RegexpPassword = () => {
  let regexp1 = "((?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,}))"; //พิมพ์ใหญ่และตัวเลขและอักขระพิเศษ
  let regexp2 = "((?=.*[A-Z])(?=.*[0-9])(?=.*[a-z])(?=.{8,}))";
  let regexp3 = "((?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,}))";
  let regexp4 = "((?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,}))";
  let regexp5 = "((?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.{8,}))";
  let regexp6 = "((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,}))"; //พิมพ์เล็กและพิมพ์ใหญ่และตัวเลขและอักขระพิเศษ
  let regexpEmail = "(?=.*[@])"; //must have @
  // let egexp = "^(" + regexp1 + "|" + regexp2 + "|" + regexp3 + "|" + regexp4 + "|" + regexp5 + "|" + regexp6 + ")"
  let egexp = "^(" + regexpEmail + ")";
  return egexp;
};