import React, { useEffect, useState } from "react";
import { useForm, FormProvider, useFormContext } from "react-hook-form";
import {
  Grid,
  Card,
  Typography,
  Box,
  InputAdornment,
  TextField,
  Button,
  FormControl,
  Autocomplete,
  Chip,
  Hidden,
  Tooltip,
} from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Search, ArrowRightAlt } from "@mui/icons-material";
import "./styles.css";
import FooterFront from "layout/CommonLayout/Layout_Font/Footer_Front";
import { AxiosPost, AxiosGet } from "service/CommonFunction/TS_function";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FnBlock_UI } from "service/SystemFunction/SystemFunction";
import parse from "html-react-parser";
import PicSlide from "./PicSlide";
import DialogPreview from "components/DialogPreview/DialogPreview";
import { MainHome } from "styles_font";
import LeadingCompany from "./LeadingCompany";
import MultiSelectGroupForm from "components/Common/ElementInForm/MultiSelectGroupForm";
import moment from "moment";
import { styled } from '@mui/material/styles';

//Image
import ImageResume from "assets/images/JobDesign-Register_1.png";
import ImageAddPosition from "assets/images/JobDesign-Register_2.png";
import ImageJobSeeker from "assets/images/JobSeeker.png";

//i18n
import { getLanguage, i18n } from "i18n";
import JobTab from "components/SearchJob/JobTab";
import secureLocalStorage from "react-secure-storage";
import { DialogActionCreators } from "store/redux/DialogAlert";
import { DateTimePicker } from "@mui/x-date-pickers";
import Cookies from 'js-cookie';

const i18nForthisPage = "home";

export default function Home() {
  const sLang = secureLocalStorage.getItem("language") || "th";
 
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { BlockUI, UnBlockUI } = FnBlock_UI();

  const [initialValues] = useState(() => {
    return {
      sInputWhat: "",
      arrLocationID: [],
      arrJobFunctionID: [],
    };
  });

  const schema = yup.object().shape({});
  const formResolver = yupResolver(schema);
  const form = useForm({
    resolver: formResolver,
    shouldUnregister: false,
    shouldFocusError: true,
    mode: "all",
    defaultValues: initialValues as any,
  });

  //State
  const [arrLastedJobs, setArrLastedJobs] = useState([]);
  const [lstBanner, setLstBanner] = useState<any>([]);
  const [lstPopPosition, setLstPopPosition] = useState([]);
  const [lstLeadingCompany, setLstLeadingCompany] = useState([]);
  const [isShowLeadingCompany, setIsShowLeadingCompany] = useState(false);
  const [isPathEqualRoute, setIsPathEqualRoute] = useState(false);
  const lang = secureLocalStorage.getItem("language").toString().toUpperCase() || "TH";
  moment.locale(lang);
  //Search Job
  const [objOptionSearch, setOptionSearch] = useState({
    lstOptJobFunction: [],
    lstOptJobTree: [],
    lstOptLocationTree: [],
    // lstOptWorkLocation: [],
  });

  const language = secureLocalStorage.getItem("language").toString();

  const stylesCustom = {
    boxSearch: {
      marginBottom: "2em",
      borderRadius: "1em",
    },
    cardSeach: {
      borderRadius: "1em",
      padding: "1em",
    },
    boxBanner: {
      marginBottom: "2em",
      borderRadius: "1em",
    },
    cardBanner: {
      borderRadius: "1em",
      overflow: "hidden",
      width: "100%",
    },
    btnFind: {
      // width: "100%",
      // height: "100%",
      width: "150px",
      height: "39px",
      marginTop: "30px",
      borderRadius: "20vw",
    },
    inputText: {
      width: "100%",
      marginTop: "10px",
    },
  };

  //margin of lstBanner2 & lstBanner3 
  const Root = styled('div')(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
      marginLeft: '8%',
    }
  }));
  
  const getInitData = () => {
  
    const param = {
      sLang: sLang,
    };

    BlockUI();
    ////AxiosPost(`HomeFront/InitialDataHome`, param, (res) => {
    AxiosPost("HomeFront/InitialDataHome2", param, (res) => {
      UnBlockUI();
      if (res.StatusCode === 200) {
        setLstBanner({
          lstBanner1: res.Data.lstBanner1,
          lstBanner2: res.Data.lstBanner2,
          lstBanner3: res.Data.lstBanner3,
          lstBanner4: res.Data.lstBanner4,
          lstBanner5: res.Data.lstBanner5,
        });
        setArrLastedJobs(res.Data.lstLastedJob);
        setLstLeadingCompany(res.Data.lstLeadingCompany);
      }
    },
      (err) => {
        if (!err.response) {
          navigate("/error");
        }
      }
    );

    let lang = "";
    let isTH = (getLanguage().id === "th");
    isTH ? lang = "th" : lang = "en"
    AxiosGet(`SearchJob/GetOptionCriteria`, { lang }, (res) => {
      setOptionSearch({
        ...res,
        lstOptSalaryEnd: res.lstOptSalaryEnd.map((item) => ({
          ...item,
          value: item.isAbove ? item.value + 1 : item.value,
        })),
      });
    });
  };

  const onSubmit = (values) => {
    let objSearch = {
      sSearch: values.sInputWhat || "",
      arrLocationID: values.arrLocationID || [],
      arrJobFunctionID: values.arrJobFunctionID || [],
    };
    navigate("/SearchJob", {
      state: {
        objSearchFromHome: objSearch,
      },
    });
  };

  //check menu path
  useEffect(() => {
    let path = "";
    AxiosGet("MenuBPS/SearchData_FrontMenuBPS", {}, (res) => {
      if (res.lstMenu && res.lstMenu.length > 0) {
        res.lstMenu.forEach(item => {
          if (item.sUrl === "/") {
            path = "/";
          }
        });

        if (path == "/") {
          navigate("/");
        } else {
          navigate(res.lstMenu[0].sUrl);
        }
      }
    }, (e) => {
      dispatch(DialogActionCreators.OpenDialogWarning(e.Message) as any);
    })
  }, [])

  useEffect(() => {
    document.getElementById("scroll_Top").scrollIntoView({
      behavior: "smooth",
    })
  }, []);

  useEffect(() => {
    PopPosition();
    getInitData();
  }, []);

  const PopPosition = () => {
    AxiosGet(`SearchJob/GetPopPosition`, null, (res) => {
      setLstPopPosition(res.lstData);
    });
  };

  return (
    <>
      {/* Leading Companies All */}
      <Box sx={{ mx: "3%", display: "row" }} >
        <DialogPreview
          Title={i18n(`${i18nForthisPage}.leadingCompany`)}
          open={isShowLeadingCompany}
          handleClose={() => setIsShowLeadingCompany(false)}
          Content={() => (
            <Box sx={MainHome}>
              <LeadingCompany lstLeadingCompany={lstLeadingCompany} />
            </Box>
          )}
        />
        <Grid container spacing={2} style={stylesCustom.boxSearch}>
          <Grid item md={7} sm={9} xs={12}>
            <Typography
              className="title1"
              sx={{ lineHeight: 1 }}
            >
              {i18n(`${i18nForthisPage}.title1`)}
            </Typography>
            <Typography className="title2" >
              {i18n(`${i18nForthisPage}.title2`)}
            </Typography>
            {/* <Box sx={{ height: "15px" }}></Box> */}
            <Card style={stylesCustom.cardSeach}>
              <Grid container spacing={1}>
                <FormProvider {...form}>
                  <Grid item xs={12} sm={12} md={12} lg={4.3}>
                    <Typography variant="h6">
                      {i18n(`${i18nForthisPage}.what`)}
                    </Typography>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <TextField
                        name="sInputWhat"
                        {...form.register("sInputWhat")}
                        size="small"
                        placeholder={i18n(`${i18nForthisPage}.placeholderWhat`)}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <Search />
                            </InputAdornment>
                          ),
                        }}
                        variant="standard"
                        style={stylesCustom.inputText}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={4.7}>
                    <Typography variant="h6">
                      {i18n(`${i18nForthisPage}.where`)}
                    </Typography>
                    <MultiSelectGroupForm
                      name={"arrLocationID"}
                      fullWidth
                      isOptionTwoLanguage
                      options={objOptionSearch.lstOptLocationTree ?? []}
                      label={i18n(`${i18nForthisPage}.placeholderWhere`)}
                      selectAllLabel={i18n(`${i18nForthisPage}.selectAll`)}
                      isBorderRadius
                      isNotShowLabel
                      shrink
                    />
                  </Grid>
                  <Hidden lgDown>
                    <Grid item xs={12} sm={12} md={12} lg={3}>
                      <Button
                        size="small"
                        variant="contained"
                        style={stylesCustom.btnFind}
                        onClick={form.handleSubmit(onSubmit)}
                      >
                        <Typography fontSize={"20px"} sx={{ fontSize: "16px" }}>
                          {i18n(`${i18nForthisPage}.btnFindJob`)}
                        </Typography>
                      </Button>
                    </Grid>
                  </Hidden>
                  <Grid item xs={12}>
                    <Typography variant="h6">
                      {i18n(`${i18nForthisPage}.function`)}
                    </Typography>
                    <MultiSelectGroupForm
                      name={"arrJobFunctionID"}
                      fullWidth
                      isOptionTwoLanguage
                      options={objOptionSearch.lstOptJobTree ?? []}
                      label={i18n(`${i18nForthisPage}.function`)}
                      selectAllLabel={i18n(`${i18nForthisPage}.selectAll`)}
                      isBorderRadius
                      isNotShowLabel
                      shrink
                    />
                  </Grid>
                  <Hidden lgUp>
                    <Grid item xs={12} sm={12} md={3}>
                      <Button
                        size="small"
                        variant="contained"
                        style={stylesCustom.btnFind}
                        onClick={form.handleSubmit(onSubmit)}
                      >
                        <Typography variant="button" sx={{ fontSize: "16px" }}>
                          {i18n(`${i18nForthisPage}.btnFindJob`)}
                        </Typography>
                      </Button>
                    </Grid>
                  </Hidden>
                </FormProvider>
              </Grid>
            </Card>
            {/* <Typography
              variant="caption"
              className="text-white"
              style={{ paddingLeft: "1em" }}
            >
              <b>Popular : </b>Designer Developer Web IOS PHP Senior Engineer
            </Typography> */}
          </Grid>
          <Grid item md={5} sm={3} xs={12}></Grid>
        </Grid>

        {/* Banner 1 */}
        {/* phone size */}
        <Hidden smUp>
          <Box sx={{ marginTop: "-1em", marginBottom: "10px", width: "100%" }}>
            {lstBanner.lstBanner1 && lstBanner.lstBanner1.isActive && (
              <PicSlide lstBanner={lstBanner.lstBanner1.lstBanner}
                height={"30vw"}
              />
            )}
          </Box>
        </Hidden>
        {/* normal size */}
        <Hidden smDown>
          <Box sx={{ marginTop: "-1em", marginBottom: "10px", width: "100%" }}>
            {lstBanner.lstBanner1 && lstBanner.lstBanner1.isActive && (
              <PicSlide lstBanner={lstBanner.lstBanner1.lstBanner}
                // height={"16vw"}
                height={"18.5vw"}
              />
            )}
          </Box>
        </Hidden>


        <Grid container>
          <Grid item md={9} xs={12} className="fade-bg-color">
            {/* <Grid container className="fade-bg-color" sx={{ width: "78%" }}> */}
            <Grid container spacing={2}>
              {/* Lasted Jobs */}
              <Grid item md={12} xs={12}>
                <Box
                  sx={{
                    color: "white",
                    bgcolor: "#1976D2",
                    width: "100%",
                    borderRadius: "10px",
                    textAlign: "center",
                    height: "40px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <Typography>{i18n(`${i18nForthisPage}.lastedJob`)}</Typography>
                </Box>
                <div style={{ height: "20px" }}></div>
                <Grid container spacing={2}>
                  {arrLastedJobs.map((item, index) => (
                    <Grid key={index} item container md={6} sm={12}>
                      <Grid item xs={12}>
                        <Box
                          sx={{
                            height: "150px",
                            borderRadius: "10px",
                            boxShadow: "0px 0px 7px 2px #C9C9C9",
                            p: "15px",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            // navigate("/JobDetail?sID=" + item.sJobPostID); 
                            navigate(`/JobDetail?sID=${item.sJobPostID}`);
                          }}
                        >
                          <Grid
                            container
                            sx={{
                              height: "100%",
                            }}
                          >
                            <Grid item xs={12}>
                              <Typography
                                className="ellipsis-label"
                                sx={{ fontWeight: 400, color: "#295697" }}
                              >
                                {item.sPosition}
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              xs={9}
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                flexDirection: "column",
                              }}
                            >
                              <Typography
                                className="ellipsis-label"
                                sx={{
                                  fontSize: 14,
                                  fontWeight: 400,
                                  color: "#000",
                                }}
                              >
                                {item.sCompany}
                              </Typography>
                              <Typography
                                className="ellipsis-label"
                                sx={{
                                  fontSize: 14,
                                  fontWeight: 400,
                                  color: "#000",
                                }}
                              >
                                {item.sRegion}
                              </Typography>
                              <Typography
                                className="ellipsis-label"
                                sx={{
                                  fontSize: 13,
                                  fontWeight: 500,
                                  color: "#C91643",
                                }}
                              >
                                {item.sSalary}
                              </Typography>
                              {item.sJobSeekerId ?
                                <Typography
                                  className="ellipsis-label"
                                  sx={{
                                    fontSize: 12,
                                    fontWeight: 400,
                                    color: "#000",
                                  }}
                                >
                                  {(item.sStartDate)} to {item.sEndDate}
                                </Typography>
                                :
                                <Typography
                                  className="ellipsis-label"
                                  sx={{
                                    fontSize: 12,
                                    fontWeight: 400,
                                    color: "#000",
                                  }}
                                >
                                  {/* {
                                    item.nPostDate > 24 ? item.nPostDate + i18n(`${i18nForthisPage}.postdateCount`)
                                      : moment(item.dPostDate).fromNow()
                                  } */}
                                  {
                                    item.nUpdateDate > 24 ? item.nUpdateDate + i18n(`${i18nForthisPage}.postdateCount`)
                                      : moment(item.dUpdateDate).fromNow()
                                  }
                                </Typography>
                              }
                            </Grid>
                            <Grid item xs={3} sx={{ pl: "20px" }}>
                              {item.sURLImage && (
                                <Tooltip
                                  title={i18n(
                                    `${i18nForthisPage}.btnViewCompany`
                                  )}
                                >
                                  <>
                                    <Hidden smDown>
                                      <img
                                        style={{
                                          maxWidth: "55px",
                                          maxHeight: "80px",
                                        }}
                                        alt="company"
                                        src={item.sURLImage}
                                      />
                                    </Hidden>
                                    <Hidden smUp>
                                      <img
                                        style={{
                                          minWidth: "8vw",
                                          // maxWidth: "14vw",
                                          maxHeight: "14vw",
                                        }}
                                        alt="company"
                                        src={item.sURLImage}
                                      />
                                    </Hidden>
                                  </>
                                </Tooltip>
                              )}
                            </Grid>
                          </Grid>
                        </Box>
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          {/* Banner Side Right */}
          {/* phone size */}
          <Hidden smUp>
            {/* <Grid xs={3}></Grid> */}
            <Grid item md={3} xs={12}>
              <Root>
                {lstBanner.lstBanner2 && lstBanner.lstBanner2.isActive && (
                  <PicSlide
                    lstBanner={lstBanner.lstBanner2.lstBanner}
                    width={"100%"}
                    height={"40vw"}
                  />
                )}
                {lstBanner.lstBanner3 && lstBanner.lstBanner3.isActive && (
                  <PicSlide
                    lstBanner={lstBanner.lstBanner3.lstBanner}
                    width={"100%"}
                    height={"30vw"}
                  />
                )}
              </Root>
            </Grid>
            {/* <Grid xs={3}></Grid> */}
          </Hidden>

          {/* normal size */}
          <Hidden smDown>
            <Grid item md={3} xs={6}>
              <Root>
                {lstBanner.lstBanner2 && lstBanner.lstBanner2.isActive && (
                  <PicSlide
                    lstBanner={lstBanner.lstBanner2.lstBanner}
                    // height="510px"
                    width={"100%"}
                    height={"33vw"}
                  />
                )}

                {lstBanner.lstBanner3 && lstBanner.lstBanner3.isActive && (
                  <PicSlide
                    lstBanner={lstBanner.lstBanner3.lstBanner}
                    width={"100%"}
                    height={"24.5vw"}
                  />
                )}
              </Root>
            </Grid>
          </Hidden>
        </Grid>

        <Grid container>
          {/* phone size */}
          <Hidden smUp>
            <Grid item md={12} xs={12}>
              <Box
                sx={{
                  paddingTop: "10px",
                  marginBottom: "10px",
                }}
              >
                {lstBanner.lstBanner4 && lstBanner.lstBanner4.isActive && (
                  <PicSlide
                    lstBanner={lstBanner.lstBanner4.lstBanner}
                    height={"25vw"}
                  />
                )}
              </Box>
            </Grid>
          </Hidden>

          {/* normal size */}
          <Hidden smDown>
            <Grid item md={12} xs={12}>
              <Box
                sx={{
                  // width: "100%",
                  // width: "102.5%",
                  // paddingTop: "2em",
                  paddingTop: "10px",
                  marginBottom: "10px",
                }}
              >
                {lstBanner.lstBanner4 && lstBanner.lstBanner4.isActive && (
                  <PicSlide
                    lstBanner={lstBanner.lstBanner4.lstBanner}
                    // width={"100%"}
                    // height={"220px"}
                    height={"14vw"}
                  // height={"15vw"}
                  />
                )}
              </Box>
            </Grid>
          </Hidden>

          {/* Popular Positions Title */}
          <Grid container sx={{ width: "300%" }}>
            <Grid item xs={6}>
              <Box
                className="popular-position-title"
                sx={{
                  color: "white",
                  bgcolor: "#0065ff",
                  width: "100%",
                  borderRadius: "20px 0 0 20px",
                  textAlign: "left",
                  height: "40px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <Typography className="popular-position-title">
                  <span>
                    <ArrowRightAlt />
                  </span>
                  &nbsp; {i18n(`${i18nForthisPage}.popPosition`)}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box
                sx={{
                  color: "white",
                  bgcolor: "#262346",
                  width: "100%",
                  borderRadius: "0 20px 20px 0",
                  height: "40px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  pr: 2,
                }}
              >
              </Box>
            </Grid>
          </Grid>

          {/* Popular List */}
          <Grid container>
            {lstPopPosition != null && lstPopPosition.length > 0 ? lstPopPosition.map((item, index) => {
              if (index < 10) {
                return (
                  <Grid key={item.nNumber} item md={6} xs={12}>
                    <Grid container flexDirection={"row"} >
                      <Box
                        sx={{
                          color: "white",
                          bgcolor: "#262346",
                          width: "50px",
                          height: "50px",
                          borderRadius: "50%",
                          // mx: "0.5%",
                          mr: 1,
                          mt: 1,
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          textAlign: "center",
                        }}
                      >
                        <Typography className="ellipsis-label">
                          {item.nNumber}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          color: "#262346",
                          bgcolor: "white",
                          // width: "calc(100% - 62px)", // w/ number: mx = 0.5%
                          width: "calc(100% - 58px)", // w/ number no mx
                          // width: "92%",
                          height: "50px",
                          borderRadius: "50px",
                          mt: 1,
                          pl: 3,
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          textAlign: "left",
                          boxShadow: "0px 0px 7px 2px #C9C9C9",
                        }}
                      >
                        <Typography className="ellipsis-label">
                          {getLanguage().id === "th" ?
                            item.sPositionTH
                            :
                            item.sPositionEN
                          }
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                );
              }
            }) : null}
          </Grid>

          {/* Banner Popular*/}
          {/* phone size */}
          <Hidden smUp>
            <Grid item xs={2}></Grid>
            <Grid item xs={8}>
              <Box sx={{ paddingTop: "2em", marginBottom: "10px", width: "100%", }}>
                {lstBanner.lstBanner5 && lstBanner.lstBanner5.isActive && (
                  <PicSlide
                    lstBanner={lstBanner.lstBanner5.lstBanner}
                    height={"23vw"}
                  />
                )}
              </Box>
            </Grid>
            <Grid item xs={2}></Grid>
          </Hidden>
          {/* normal size */}
          <Hidden smDown>
            <Grid item md={2} xs={12}></Grid>
            <Grid item md={8} xs={12}>
              <Box sx={{ paddingTop: "2em", marginBottom: "10px", width: "100%", }}>
                {lstBanner.lstBanner5 && lstBanner.lstBanner5.isActive && (
                  <PicSlide
                    lstBanner={lstBanner.lstBanner5.lstBanner}
                    // width={"100%"}
                    height={"15vw"}
                  // height={"220px"}
                  />
                )}
              </Box>
            </Grid>
            <Grid item md={2} xs={12}></Grid>
          </Hidden>


          {/* Leading Companies Title */}
          <Grid container justifyContent={"center"}>
            <Grid item xs={6}>
              <Box
                sx={{
                  color: "white",
                  bgcolor: "#0065ff",
                  width: "100%",
                  borderRadius: "20px 0 0 20px",
                  textAlign: "left",
                  height: "40px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  pl: 2,
                  whiteSpace: "nowrap"
                }}
              >
                <Typography>
                  <span>
                    <ArrowRightAlt />
                  </span>
                  &nbsp; {i18n(`${i18nForthisPage}.leadingCompany`)}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box
                sx={{
                  color: "white",
                  bgcolor: "#262346",
                  // width: "105%",
                  borderRadius: "0 20px 20px 0",
                  textAlign: "right",
                  height: "40px",
                  // marginRight: "-15%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  pr: 2,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "end",
                    margin: "10px"
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: 13,
                      ":hover": {
                        textDecoration: "underline",
                        color: "#0366d6",
                        cursor: "pointer",
                      },
                    }}
                    onClick={() => setIsShowLeadingCompany(true)}
                  >
                    {i18n(`${i18nForthisPage}.viewAll`)}
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Grid item md={12} xs={12}>
            {/* Leading Companies List */}
            <LeadingCompany lstLeadingCompany={lstLeadingCompany.slice(0, 10)} />
          </Grid>
        </Grid>
      </Box >

      {/* ฝากประวัติ */}
      < Grid container >
        <Grid item md={6} xs={12}>
          <Box
            className="cover-image"
            sx={{
              backgroundImage: `url(${ImageResume})`,
              width: "100%",
              height: "350px",
              padding: "5% 10% 3% 10%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <Typography className="add-resume-title">
              {parse(i18n(`${i18nForthisPage}.boxResume`))}
            </Typography>
            <Box
              sx={{
                width: "220px",
                height: "40px",
                color: "white",
                bgcolor: "#0065ff",
                textAlign: "left",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                borderRadius: "20px",
                pl: 2,
                ":hover": {
                  bgcolor: "#0251C8",
                },
                cursor: "pointer",
              }}
              onClick={() => {
                navigate("/Guest/Register");
              }}
            >
              <Typography>
                <span>
                  <ArrowRightAlt />
                </span>
                &nbsp; {i18n(`${i18nForthisPage}.btnResume`)}
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item md={6} xs={12}>
          <Box
            className="cover-image"
            sx={{
              backgroundImage: `url(${ImageAddPosition})`,
              width: "100%",
              height: "350px",
              padding: "5% 10% 3% 10%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
            onClick={() => {
              navigate("/RegisterEmployer");
            }}
          >
            <Typography className="add-resume-title">
              {parse(i18n(`${i18nForthisPage}.boxAddPosition`))}
            </Typography>
            <Box
              sx={{
                width: "220px",
                height: "40px",
                color: "white",
                bgcolor: "#e83965",
                textAlign: "left",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                borderRadius: "20px",
                pl: 2,
                ":hover": {
                  bgcolor: "#CB355B",
                },
                cursor: "pointer",
              }}
            >
              <Typography>
                <span>
                  <ArrowRightAlt />
                </span>
                &nbsp; {i18n(`${i18nForthisPage}.btnAddPosition`)}
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid >

      {/* Job Seeker */}
      {/* <Box
        className="cover-image job-seeker-box"
        sx={{
          backgroundImage: `url(${ImageJobSeeker})`,
          width: "100%",
          height: "260px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Grid container spacing={2} sx={{ textAlign: "center" }}>
          <Grid item md={6} xs={12}>
            <Typography className="job-seeker-title">
              {i18n(`${i18nForthisPage}.boxSeekerTitle`)}
            </Typography>
            <Typography className="job-seeker-desc">
              {parse(i18n(`${i18nForthisPage}.boxSeekerDesc`))}
            </Typography>
          </Grid>
          <Box style={{ height: "7px" }}></Box>
          <Grid item md={6} xs={12}>
            <Typography className="job-seeker-title">
              {i18n(`${i18nForthisPage}.boxEmployerTitle`)}
            </Typography>

            <Typography className="job-seeker-desc">
              {i18n(`${i18nForthisPage}.boxEmployerDesc`)}
            </Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid
            item
            container
            xs={12}
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                width: "200px",
                height: "40px",
                color: "#0065ff",
                bgcolor: "white",
                textAlign: "center",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",

                ":hover": {
                  bgcolor: "#E7E7E7",
                },
                cursor: "pointer",
                borderRadius: "20px",

              }}
              onClick={() => {
                navigate("/Guest/Register");
              }}
            >
              <Typography align="center"
                sx={{ fontSize: 18, fontWeight: 700 }}>
                {i18n(`${i18nForthisPage}.btnRegister`)}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box> */}

      {/* <Grid sx={{ position: "fixed" }}>
        <CookieCondition />
      </Grid> */}

      {/* Footer */}
      <FooterFront />
    </>
  );
}

const AutoCompleteSelectForSearch = (props) => {
  const { register, setValue, watch } = useFormContext();

  return (
    <FormControl
      sx={{
        width: "100%",
        "label.MuiInputLabel-shrink": {
          top: "0!important",
        },
        "label.MuiInputLabel-formControl > span": {
          color: "red",
        },
      }}
    >
      <Autocomplete
        {...props}
        disableCloseOnSelect
        multiple
        renderOption={(props, option: any) => {
          return (
            <li {...props} key={option.value}>
              {option.label}
            </li>
          );
        }}
        value={
          props.options.filter(
            (f) => watch(props.name).indexOf(f.value) > -1
          ) || []
        }
        fullWidth
        size={"small"}
        {...register(props.name)}
        onChange={(e, v) => {
          let value = v ? v.map((item) => item.value) : [];
          setValue(props.name, value, { shouldValidate: true });
          props.onChange && props.onChange(value);
        }}
        getOptionLabel={(itemOption: any) => {
          return `${itemOption.label}`;
        }}
        isOptionEqualToValue={(option, value) => option.value === value.value}
        renderInput={(params) => (
          <TextField
            sx={{
              ".MuiInputBase-sizeSmall": {
                borderRadius: "50px",
              },
            }}
            placeholder={
              watch(props.name) &&
              watch(props.name).length === 0 &&
              props.label + " " + i18n(`${i18nForthisPage}.selectAll`)
            }
            {...params}
          />
        )}
        renderTags={(tagValue, getTagProps) => {
          if (tagValue != undefined) {
            return (
              <React.Fragment>
                {tagValue.slice(0, 1).map((option: any, index) => (
                  <Chip
                    key={option.value}
                    size={"small"}
                    {...getTagProps({ index })}
                    label={option.label}
                  />
                ))}
                {tagValue.length > 1 ? (
                  <Chip size={"small"} label={"+" + (tagValue.length - 1)} />
                ) : (
                  <React.Fragment></React.Fragment>
                )}
              </React.Fragment>
            );
          }
        }}
      />
    </FormControl>
  );
};