import { Card, Grid, Typography } from "@mui/material";
import DoneAllIcon from '@mui/icons-material/DoneAll';
import { i18n } from "i18n";
import { RiDonutChartLine } from "react-icons/ri";

const CardComplete = ({isComPlete}) => {
    const i18nField = "entities.SeekerProfile";
    return (
        <>
            {isComPlete ? (
                <Card
                    className="container-CardComplete"
                    sx={{
                        position: 'relative',
                        backgroundColor: "#9febaf",
                        borderRadius: "30px",
                        padding: '3px !important',
                        alignItems: "center",
                        alignContent: "center",
                        height: "30px"
                    }}
                >
                    <Grid item xs={12} md={12} >
                        <Grid container alignItems={"center"} justifyContent={"center"} spacing={3.5}>
                            <Grid item md={1} xs={2} >
                                <DoneAllIcon style={{ fontSize: '1.3em', color: '#3fa255' }} />
                            </Grid>
                            <Grid item >
                                <Typography className='card-title-Profile'>{i18n(`${i18nField}.Complete`)}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Card>
            ) : (
                <Card
                    className="container-CardComplete"
                    sx={{
                        position: 'relative',
                        backgroundColor: "#ffcbcb",
                        borderRadius: "30px",
                        padding: '3px !important',
                        alignItems: "center",
                        alignContent: "center",
                        height: "30px"
                    }}
                >
                    <Grid item xs={12} >
                        <Grid container alignItems={"center"} justifyContent={"center"} spacing={3}>
                            <Grid item xs={1} >
                                <RiDonutChartLine style={{ fontSize: '1.3em', color: '#dc4747', transform: "rotate(207deg)" }} />
                            </Grid>
                            <Grid item >
                                <Typography className='card-title-ProfileNo'>{i18n(`${i18nField}.InComplete`)}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Card>
            )}
        </>

    );

}
export default CardComplete